import * as React from "react";
import { useState, useEffect } from "react";
import { GridToolbar } from "@mui/x-data-grid";
import {
  PrimaryButton,
  PrimaryDataGrid,
  PrimarySwitch,
  SmallPrimaryAvatar,
  SmallSizeChip,
  DownloadButton,
  StyledGridBox,
  CompanyTypography,
  StyledOutlinedInput,
  CompanySizeChip,
  ClosedJobChip,
  ActiveJobChip,
  PendingJobChip,
} from "assets/Mui/globalTheme";
import InputAdornment from "@mui/material/InputAdornment";
import JobSearchIcon from "assets/images/icons/JobSearchIcon";
import TablePopper from "components/table/header/popper";
import { IndustryOperators } from "components/table/operators/industryOperators";
import { LocationOperators } from "components/table/operators/locationOperators";
import LoaderIcon from "utils/icons/loaderIcon";

import {
  getAllCompanies,
  getAllCompaniesWithStatus,
} from "helpers/services/companies/company";
import { Link, useHistory } from "react-router-dom";
import { StyledGridOverlay } from "assets/Mui/globalTheme";
import CustomNoRowsOverlay from "common/data/NoRowsFoundDatagrid/NoRowsFound";
import SkillViewModal from "helpers/Modals/SkillViewModal";
import { Pagination, IconButton, Stack } from "@mui/material";
import SearchParamHook from "hooks/searchParams";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { StageSearchInput } from "assets/Mui/globalTheme";
import { useDebounce } from "hooks/useDebounce";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  TE,
  SP,
  MODERATOR,
  RC,
  RS,
  ACM,
  ZA,
  CA,
  CS,
  BVC,
  BVS,
} from "common/constants/userModules";
import { parseNumberInput } from "hooks/customPhoneNumberParse";
import downloadCsv from "download-csv";
import { socket } from "Sockets/socket";
import CompanySizeIcon from "assets/images/icons/companySize";
import ClosedIcon from "assets/images/icons/closedIcon";
import TickIcon from "assets/images/icons/Tick";
import DoubleTickIcon from "assets/images/icons/totalJobs";
import GlobalTableTools from "hooks/GlobalTableTools";

export default function CompanyTable() {

  const searchParamsHook = SearchParamHook();


  const { search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);

  const searchTextParam = searchParamsHook.get("searchParam") || "";
  const currentPage = searchParamsHook.get("page");

  const role = JSON.parse(localStorage.getItem("authUser")).role;

  const [screeningPartners, setScreeningPartners] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [openSkillModal, setOpenSkillModal] = React.useState(false);
  const [modalName, setModalName] = React.useState("");
  const loginStore = JSON.parse(localStorage.getItem("authUser"));

  const [page, setPage] = React.useState(Number(currentPage || 1));
  const [totalCount, setTotalCount] = React.useState("");
  const [totalRecords, setTotalRecords] = React.useState("");
  const rowsPerPage = 20;
 

  useEffect(() => {
    if (!currentPage) {
      searchParams.set("page", 1);
      history.push({
        search: searchParams.toString(),
      });
    }
    setPage(Number(currentPage || 1));
    initialFetch(page, rowsPerPage, searchTextParam);
  }, []);

  React.useEffect(() => {
    searchParams.set("page", page);
    history.push({
      search: searchParams.toString(),
    });
    initialFetch(page, rowsPerPage, searchTextParam);
  }, [page]);

  React.useEffect(() => {
    // if (searchTextParam) {
      searchParams.set("page", 1);
      history.push({
        search: searchParams.toString(),
      });
      setPage(1);
      initialFetch(page, rowsPerPage, searchTextParam);
    // }
  }, [searchTextParam]);


  async function initialFetch(page, rowsPerPage, searchText) {
    setLoading(true);
    try {
      let { data } = await getAllCompaniesWithStatus(
        page,
        rowsPerPage,
        searchText
      );
      const companies = data?.data_payload?.filter(item => {
        return item.company_user_details.usertype === "COMPANY";
      });

      setTotalCount(data?.total_page_size);
      setTotalRecords(data?.overall_count);

      const sorted = await companies.filter(item => {
        if (item.related_to_admin === false) return item;
      });
      const result = sorted?.map(item => ({
        //TODO if logo is coming without url change it else okay
        id: item?.id,
        image: item?.company_image_details,
        name: item?.company_name,
        company_id: item?.company_user_details?.id,
        location: item?.location,
        email: item?.company_user_details?.email,
        phone_number: parseNumberInput(
          item?.company_user_details?.phone_number
        ),
        // functional_area: item?.functional_area_details
        //   ?.map(item => item?.name)
        //   ?.join(","),
        // industry: item?.industry_details?.map(item => item?.name)?.join(","),
        employees_count: item?.size_of_company,
        about: item?.about ? item?.about : "-",
        // active_job_openings: item?.active_job_openings,
        total_job_posts: item?.company_jobs_overall_count,
        closed_job_posts: item?.company_closed_jobs_count,
        active_job_openings: item?.company_job_openings_count,
        pending_job_openings: item?.company_pending_jobs_count,

        // onboarded_staff: item?.onboarded_staff,
        // no_of_staff: item?.no_of_staff,
        // acm: item?.acm?item.acm:"N/A",
        // acm_email: item?.account_manager_details?.email ? item?.account_manager_details?.email : "N/A",
        acm_email: `${item?.account_manager_details?.length > 0
          ? item?.account_manager_details?.map(item => item?.email)?.join(",")
          : "N/A"
          }`,
        moderator_email: `${item?.moderator_details?.length > 0
          ? item?.moderator_details?.map(item => item?.email)?.join(",")
          : "N/A"
          }`,
        te_email: `${item?.evaluator_details?.length > 0
          ? item?.evaluator_details?.map(item => item?.email)?.join(",")
          : "N/A"
          }`,
        sp_email: `${item?.screening_partner_details?.length > 0
          ? item?.screening_partner_details
            ?.map(item => item?.email)
            ?.join(",")
          : "N/A"
          }`,
        rc_email: `${item?.rc_details?.length > 0
          ? item?.rc_details?.map(item => item?.email)?.join(",")
          : "N/A"
          }`,
        bgv_email: `${item?.bgv_details?.length > 0
          ? item?.bgv_details?.map(item => item?.email)?.join(",")
          : "N/A"
          }`,
      }));
      setScreeningPartners(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
    // setScreeningPartners(result);
  }

  function handleCellClick(params) {
    const field = params.field;

    if (field.trim() === "acm_email") {
      if (params.row.acm_email !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.acm_email.split(","));
        setOpenSkillModal(!openSkillModal);
      }
      return;
    }
    if (field === "moderator_email") {
      if (params.row.moderator_email !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.moderator_email.split(","));
        setOpenSkillModal(!openSkillModal);
      }
      return;
    }
    if (field === "te_email") {
      if (params.row.te_email !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.te_email.split(","));
        setOpenSkillModal(!openSkillModal);
      }
      return;
    }
    if (field === "sp_email") {
      if (params.row.sp_email !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.sp_email.split(","));
        setOpenSkillModal(!openSkillModal);
      }
      return;
    }
    if (field === "rc_email") {
      if (params.row.rc_email !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.rc_email.split(","));
        setOpenSkillModal(!openSkillModal);
      }
      return;
    }
    if (field === "bgv_email") {
      if (params.row.bgv_email !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.bgv_email.split(","));
        setOpenSkillModal(!openSkillModal);
      }
      return;
    } else {
      if ([ZA].includes(loginStore.role))
        history.push(`../company/${params?.row?.company_id}`);
    }
  }

  const handleCloseModal = () => {
    setOpenSkillModal(!openSkillModal);
  };

  const columns = [
    {
      field: "image",
      headerName: "Company",
      filterable: false,
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: params => {
        return (
          <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
            <SmallPrimaryAvatar
              imgProps={{ loading: "lazy" }}
              src={params?.formattedValue}
            >
              {params?.row?.name?.charAt(0)?.toUpperCase()}
            </SmallPrimaryAvatar>
            <p>{params?.row?.name}</p>
          </div>
        );
      },
    },
    // {
    //   field: "name",
    //   headerName: "Company",
    //   headerClassName: 'super-app-theme--header',
    //   width: 200,
    // },
    {
      field: "phone_number",
      headerClassName: "super-app-theme--header",
      headerName: "Phone Number",
      width: 300,
    },
    {
      field: "email",
      headerClassName: "super-app-theme--header",
      headerName: "Email",
      width: 300,
    },
    {
      field: "location",
      headerClassName: "super-app-theme--header",
      headerName: "Location",
      filterOperators: LocationOperators,
      width: 400,
    },
    // { field: "industry",headerName:"Industry", filterOperators: IndustryOperators, width: 400 },
    // { field: "functional_area", width: 200 },
    {
      field: "employees_count",
      headerClassName: "super-app-theme--header",
      headerName: "Company Size",
      width: 200,

      renderCell: params => (
        <div style={{
          display: "flex", alignItems: "center",
          //  gap: "8px"
        }}>
          {params.value && params.value !== "N/A" ? (
            <>
              <CompanySizeChip
                label={<><CompanySizeIcon />{" "}{params.value}</>}
                sx={{ margin: "0.5px" }}
                size="small"
              />
            </>
          ) : (
            <span>-</span>
          )}
        </div>
      ),
    },
    {
      field: "about",
      headerClassName: "super-app-theme--header",
      headerName: "About",
      width: 400,
    },
    {
      field: "active_job_openings",
      headerClassName: "super-app-theme--header",
      headerName: "Active Jobs",
      width: 200,
      renderCell: params => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {params.value && params.value !== "N/A" ? (
            <>
              <ActiveJobChip

                label={<><TickIcon />{" "}{params.value}</>}
                style={{ margin: "1px" }}
                size="small"
              />
            </>
          ) : (
            <span>-</span>
          )}
        </div>
      ),
    },
    {
      field: "pending_job_openings",
      headerClassName: "super-app-theme--header",
      headerName: "Pending Jobs",
      width: 200,
      renderCell: params => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {params.value && params.value !== "N/A" ? (
            <>
              <CompanySizeChip
                label={params.value}
                style={{ margin: "1px" }}
                size="small"
              />
            </>
          ) : (
            <span>-</span>
          )}
        </div>
      ),
    },
    {
      field: "total_job_posts",
      headerClassName: "super-app-theme--header",
      headerName: "Total Jobs",
      width: 200,
      renderCell: params => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {params.value && params.value !== "N/A" ? (
            <>
              <PendingJobChip

                label={<><DoubleTickIcon />{" "}{params.value}</>}
                style={{ margin: "1px" }}
                size="small"
              />
            </>
          ) : (
            <span>-</span>
          )}
        </div>
      ),
    },
    {
      field: "closed_job_posts",
      headerClassName: "super-app-theme--header",
      headerName: "Closed Jobs",
      width: 200,
      renderCell: params => (
        <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
          {params.value && params.value !== "N/A" ? (
            <>
              <ClosedJobChip
                // icon={<ClosedIcon />}
                // label={params.value}
                label={<><ClosedIcon />{" "}{params.value}</>}
                style={{ margin: "1px" }}
                size="small"
                sx={{
                  '& .MuiChip-icon': {
                    marginLeft: '3px', // Adjust the alignment of the icon
                  },
                }}
              />
            </>
          ) : (
            <span>-</span>
          )}
        </div>
      ),
    },
    // { field: "onboarded_staff", width: 200 },
    // { field: "no_of_staff", width: 200 },
    // { field: "acm_email", headerName: "Account Manager", width: 300 },
    [ZA, ACM].includes(role)
      ? {
        field: "acm_email",
        width: 400,
        headerClassName: "super-app-theme--header",
        headerName: "Account Manager",
        // renderCell: params => (
        //   <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        //     {params.value && params.value !== "N/A" ? (
        //       <>
        //         {params.value.split(",").slice(0, 1)?.map((each) => <CompanySizeChip label={each} style={{ margin: "1px" }} size="small" />)}
        //       </>
        //     ) : (
        //       <span>N/A</span>
        //     )}
        //   </div>
        // ),

        renderCell: params => {
          const values =
            params.value && params.value !== "N/A"
              ? params.value.split(",")
              : [];
          const firstValue = values.slice(0, 1);
          const remainingCount = values.length - 1;

          return (
            <div
              style={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
              {firstValue.map((each, index) => (
                <CompanySizeChip
                  key={index}
                  label={each}
                  style={{ margin: "1px" }}
                  size="small"
                />
              ))}
              {remainingCount > 0 && (
                <CompanySizeChip
                  label={`+${remainingCount}`}
                  style={{ margin: "1px" }}
                  size="small"
                />
              )}
              {values.length === 0 && <span>-</span>}
            </div>
          );
        },
      }
      : "",

    [ZA, ACM].includes(role)
      ? {
        field: "sp_email",
        width: 400,
        headerClassName: "super-app-theme--header",
        headerName: "Screening Partners",
        // renderCell: params => (
        //   <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        //     {params.value && params.value !== "N/A" ? (
        //       <>
        //         {params.value.split(",").slice(0, 2)?.map((each) => <CompanySizeChip label={each} style={{ margin: "1px" }} size="small" />)}
        //       </>
        //     ) : (
        //       <span>N/A</span>
        //     )}
        //   </div>
        // ),

        renderCell: params => {
          const values =
            params.value && params.value !== "N/A"
              ? params.value.split(",")
              : [];
          const firstTwoValues = values.slice(0, 1);
          const remainingCount = values.length - 1;

          return (
            <div
              style={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
              {firstTwoValues.map((each, index) => (
                <CompanySizeChip
                  key={index}
                  label={each}
                  style={{ margin: "1px" }}
                  size="small"
                />
              ))}
              {remainingCount > 0 && (
                <CompanySizeChip
                  label={`+${remainingCount}`}
                  style={{ margin: "1px" }}
                  size="small"
                />
              )}
              {values.length === 0 && <span>-</span>}
            </div>
          );
        },
      }
      : "",

    [ZA, ACM].includes(role)
      ? {
        field: "te_email",
        width: 400,
        headerClassName: "super-app-theme--header",
        headerName: "Technical Evaluators",
        // renderCell: params => (

        //   <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        //     {params.value && params.value !== "N/A" ? (
        //       <>
        //         {params.value.split(",").slice(0, 2)?.map((each) => <CompanySizeChip label={each} style={{ margin: "1px" }} size="small" />)}
        //       </>
        //     ) : (
        //       <span>N/A</span>
        //     )}
        //   </div>
        // ),

        renderCell: params => {
          const values =
            params.value && params.value !== "N/A"
              ? params.value.split(",")
              : [];
          const firstTwoValues = values.slice(0, 1);
          const remainingCount = values.length - 1;

          return (
            <div
              style={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
              {firstTwoValues.map((each, index) => (
                <CompanySizeChip
                  key={index}
                  label={each}
                  style={{ margin: "1px" }}
                  size="small"
                />
              ))}
              {remainingCount > 0 && (
                <CompanySizeChip
                  label={`+${remainingCount}`}
                  style={{ margin: "1px" }}
                  size="small"
                />
              )}
              {values.length === 0 && <span>-</span>}
            </div>
          );
        },
      }
      : "",

    [ZA, ACM].includes(role)
      ? {
        field: "moderator_email",
        width: 400,
        headerClassName: "super-app-theme--header",
        headerName: "Moderators",
        //  renderCell: params => (
        //    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        //      {params.value && params.value !== "N/A" ? (
        //        <>
        //          {params.value.split(",").slice(0, 2)?.map((each) => <CompanySizeChip label={each} style={{ margin: "1px" }} size="small" />)}
        //        </>
        //      ) : (
        //        <span>N/A</span>
        //      )}
        //    </div>
        //  ),

        renderCell: params => {
          const values =
            params.value && params.value !== "N/A"
              ? params.value.split(",")
              : [];
          const firstTwoValues = values.slice(0, 1);
          const remainingCount = values.length - 1;

          return (
            <div
              style={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
              {firstTwoValues.map((each, index) => (
                <CompanySizeChip
                  key={index}
                  label={each}
                  style={{ margin: "1px" }}
                  size="small"
                />
              ))}
              {remainingCount > 0 && (
                <CompanySizeChip
                  label={`+${remainingCount}`}
                  style={{ margin: "1px" }}
                  size="small"
                />
              )}
              {values.length === 0 && <span>-</span>}
            </div>
          );
        },
      }
      : "",

    [ZA, ACM].includes(role)
      ? {
        field: "rc_email",
        width: 400,
        headerClassName: "super-app-theme--header",
        headerName: "Recruitment Partners",
        // renderCell: params => (
        //   <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        //     {params.value && params.value !== "N/A" ? (
        //       <>
        //         {params.value.split(",").slice(0, 2)?.map((each) => <CompanySizeChip label={each} style={{ margin: "1px" }} size="small" />)}
        //       </>
        //     ) : (
        //       <span>N/A</span>
        //     )}
        //   </div>
        // ),

        renderCell: params => {
          const values =
            params.value && params.value !== "N/A"
              ? params.value.split(",")
              : [];
          const firstTwoValues = values.slice(0, 1);
          const remainingCount = values.length - 1;

          return (
            <div
              style={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
              {firstTwoValues.map((each, index) => (
                <CompanySizeChip
                  key={index}
                  label={each}
                  style={{ margin: "1px" }}
                  size="small"
                />
              ))}
              {remainingCount > 0 && (
                <CompanySizeChip
                  label={`+${remainingCount}`}
                  style={{ margin: "1px" }}
                  size="small"
                />
              )}
              {values.length === 0 && <span>-</span>}
            </div>
          );
        },
      }
      : "",
    [ZA, ACM].includes(role)
      ? {
        field: "bgv_email",
        width: 400,
        headerClassName: "super-app-theme--header",
        headerName: "Background Partners",
        // renderCell: params => (
        //   <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        //     {params.value && params.value !== "N/A" ? (
        //       <>
        //         {params.value.split(",").slice(0, 2)?.map((each) => <CompanySizeChip label={each} style={{ margin: "1px" }} size="small" />)}
        //       </>
        //     ) : (
        //       <span>N/A</span>
        //     )}
        //   </div>
        // ),

        renderCell: params => {
          const values =
            params.value && params.value !== "N/A"
              ? params.value.split(",")
              : [];
          const firstTwoValues = values.slice(0, 1);
          const remainingCount = values.length - 1;

          return (
            <div
              style={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
              {firstTwoValues.map((each, index) => (
                <CompanySizeChip
                  key={index}
                  label={each}
                  style={{ margin: "1px" }}
                  size="small"
                />
              ))}
              {remainingCount > 0 && (
                <CompanySizeChip
                  label={`+${remainingCount}`}
                  style={{ margin: "1px" }}
                  size="small"
                />
              )}
              {values.length === 0 && <span>-</span>}
            </div>
          );
        },
      }
      : "",
  ];

  const filteredColumns = columns.filter(Boolean);

  /* Need to optimize  this download function calling  & initial fetch due to the less time am not optimized will optimize later */

  const handleDownload = async () => {
    setLoading(true);
    let result;
    try {
      let { data } = await getAllCompaniesWithStatus(
        page,
        totalRecords,
        searchText
      );
      const companies = data?.data_payload?.filter(item => {
        return item.company_user_details.usertype === "COMPANY";
      });

      const sorted = await companies.filter(item => {
        if (item.related_to_admin === false) return item;
      });
      result = sorted?.map(item => ({
        //TODO if logo is coming without url change it else okay
        id: item?.id,
        image: item?.company_image_details,
        name: item?.company_name,
        company_id: item?.company_user_details?.id,
        location: item?.location,
        email: item?.company_user_details?.email,
        phone_number: parseNumberInput(
          item?.company_user_details?.phone_number
        ),
        // functional_area: item?.functional_area_details
        //   ?.map(item => item?.name)
        //   ?.join(","),
        // industry: item?.industry_details?.map(item => item?.name)?.join(","),
        employees_count: item?.size_of_company,
        about: item?.about ? item?.about : "-",
        // active_job_openings: item?.active_job_openings,
        total_job_posts: item?.company_jobs_overall_count,
        closed_job_posts: item?.company_closed_jobs_count,
        active_job_openings: item?.company_job_openings_count,
        pending_job_openings: item?.company_pending_jobs_count,
        // onboarded_staff: item?.onboarded_staff,
        // no_of_staff: item?.no_of_staff,
        // acm: item?.acm?item.acm:"N/A",
        // acm_email: item?.account_manager_details?.email ? item?.account_manager_details?.email : "N/A",
        acm_email: `${item?.account_manager_details?.length > 0
          ? item?.account_manager_details?.map(item => item?.email)?.join(",")
          : "-"
          }`,
        moderator_email: `${item?.moderator_details?.length > 0
          ? item?.moderator_details?.map(item => item?.email)?.join(",")
          : "-"
          }`,
        te_email: `${item?.evaluator_details?.length > 0
          ? item?.evaluator_details?.map(item => item?.email)?.join(",")
          : "-"
          }`,
        sp_email: `${item?.screening_partner_details?.length > 0
          ? item?.screening_partner_details
            ?.map(item => item?.email)
            ?.join(",")
          : "-"
          }`,
        rc_email: `${item?.rc_details?.length > 0
          ? item?.rc_details?.map(item => item?.email)?.join(",")
          : "-"
          }`,
        bgv_email: `${item?.bgv_details?.length > 0
          ? item?.bgv_details?.map(item => item?.email)?.join(",")
          : "-"
          }`,
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }

    downloadCsv(result, filteredColumns, "Companies List");
  };


  /*Page refresh based on socket */

  const handleListnerEvent = data => {
    if (data?.type === "COMPANY") {
      initialFetch(page, rowsPerPage, searchTextParam);
    }
  };

  useEffect(() => {
    const handleReconnect = () => {
      // Re-register the event listener after reconnection if needed
      socket.on("listnerEvent", handleListnerEvent);
    };

    socket.on("listnerEvent", handleListnerEvent);

    // Attach a listener for socket reconnection
    socket.on("reconnect", handleReconnect);

    return () => {
      socket.off("listnerEvent", handleListnerEvent);
      socket.off("reconnect", handleReconnect);
    };
  }, []);

  return (
    <div style={{ padding: "10px", height: "83vh" }}>
      <GlobalTableTools handleDownload={handleDownload} tableHeading={"Companies"} />
      <PrimaryDataGrid
        loading={loading}
        onCellClick={handleCellClick}
        disableRowSelectionOnClick
        onSelectionModelChange={event => event.stopPropagation()}
        checkboxSelection={false}
        columns={filteredColumns}
        rows={screeningPartners}

        slots={{
          loadIcon: LoaderIcon,
          baseButton: PrimaryButton,
          basePopper: TablePopper,
          baseSwitch: PrimarySwitch,
          noRowsOverlay: CustomNoRowsOverlay,
          pagination: props => (
            <Pagination
              {...props}
              // color="primary"
              // count={Math.ceil(rowsData.length / rowsPerPage)}
              // count={totalCount}
              count={totalCount}
              page={page}
              rowsPerPage={rowsPerPage}
              onChange={(_, newPage) => setPage(newPage)}
              showFirstButton
              showLastButton
              sx={{
                "& .MuiPaginationItem-root": {
                  "&:hover": {
                    backgroundColor: "#cde2e7",
                    color: "#066a4c",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "#024430",
                    color: "#ffe7bb",
                    "&:hover": {
                      backgroundColor: "#cde2e7",
                      color: "#066a4c",
                    },
                  },
                },
              }}
            />
          ),
        }}
        slotProps={{
          baseButton: { style: { width: "100px" } },
          panel: {
            style: {
              right: "0px !important",
              transform: "none !important",
              inset: "none !important",
              top: "138px !important",
            },
          },
          filterPanel: {
            style: {
              right: "0px !important",
              transform: "none !important",
              inset: "none !important",
              top: "138px !important",
            },
          },
          toolbar: { style: {} },
          preferencesPanel: {
            style: {
              right: "0px !important",
              transform: "none !important",
              inset: "none !important",
              top: "138px !important",
            },
          },
        }}
        sx={{
          "& .super-app-theme--header": {
            backgroundColor: "rgba(250, 250, 250, 1)",
          },
        }}
      />

      {openSkillModal && (
        <SkillViewModal
          rowdata={selectedRowData}
          closeModalFunc={handleCloseModal}
          openModal={openSkillModal}
          headerName={modalName}
        />
      )}
    </div>
  );
}