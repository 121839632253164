import * as React from "react";
import { useEffect, useState, useRef } from "react";
import {
  ListItemButton,
  ListItemText,
  Box,
  Stack,
  IconButton,
} from "@mui/material";
import {
  ExtraSmallTypography,
  NotificationPopUp,
  GrayDivider,
  StyledListItemButton,
  StyledProfilePopText,
  BlackTypography,
  NotificationBox,
  NotificationHeader,
  StyledListCard,
  GreyTypography,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
import LogoutModel from "./logout";
import { useSelector } from "react-redux";
import { ACM, CS, MODERATOR } from "common/constants/userModules";
import CircularProgress from '@mui/material/CircularProgress';


import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import Divider from "@mui/material/Divider";
import { getAllNotifications } from "helpers/services/Notifications/NotificationApi";
import { NavigateBefore } from "@mui/icons-material";
import { debounce } from "lodash";
import { socket } from "Sockets/socket";
import { useHistory } from "react-router-dom";

export default function NotificationPopOver({
  handleBellClose,
  notificationEl,
  viewModel,
  // notificationsData,
  socketCall,
}) {
  const user = useSelector(state => state?.Login);
  const open = Boolean(notificationEl);
  const history = useHistory();

  const role = JSON.parse(localStorage.getItem("authUser")).role;
  const id = open ? "profile-popover" : undefined;

  const [page, setPage] = useState(1); // Set the initial page
  const [loading, setLoading] = useState(false);

  const [pageSize, setPageSize] = useState(1000);
  const [hasMore, setHasMore] = useState(true);
  const search = "";
  const notificationRef = useRef(null);

  const [notificationsData, setNotificationData] = React.useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    style: {
      maxHeight: ITEM_HEIGHT * 5.7 + ITEM_PADDING_TOP,
      width: 250,
      background: "white",
      marginTop: "10px",
      fontSize: "14px",
      padding: "10px 0px",
      borderRadius: "10px",
      boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
    },
  };

  const fetchNotifications = async () => {
    setLoading(true);
    let notifyData;
    if (open) {
      notifyData = await getAllNotifications(page, pageSize, search);
    }

    // setNotificationData(prevData => [...prevData, ...notifyData?.jobPostTweakData]);
    if (notifyData) {
      setLoading(false);
    }
    setNotificationData([...notifyData?.jobPostTweakData]);
    // setNotificationData(notifyData);
  };

  useEffect(() => {
    if (open) {
      fetchNotifications();
    }
    socketCall();
  }, [open]);

  useEffect(() => {
    fetchNotifications();
  }, [page]);

  const handleListnerEvent = data => {
    if (data?.type === "notification") {
      fetchNotifications();
      socketCall();
    }
  };

  useEffect(() => {
    const handleReconnect = () => {
      // Re-register the event listener after reconnection if needed
      socket.on("listnerEvent", handleListnerEvent);
    };

    socket.on("listnerEvent", handleListnerEvent);

    // Attach a listener for socket reconnection
    socket.on("reconnect", handleReconnect);

    return () => {
      socket.off("listnerEvent", handleListnerEvent);
      socket.off("reconnect", handleReconnect);
    };
  }, []);

  /* Infinite scroll */

  const handleScroll = () => {
    const container = notificationRef.current;
    if (!container || loading) return;

    const scrollTop = container.scrollTop;
    const clientHeight = container.clientHeight;
    const scrollHeight = container.scrollHeight;

    // console.log(scrollTop,clientHeight,scrollHeight,"params")
    // console.log(scrollTop + clientHeight >= (scrollHeight - 100))

    if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  const debouncedHandleScroll = debounce(handleScroll, 200);

  useEffect(() => {
    const container = notificationRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [loading, hasMore, page]);

  const handleSettingsClick = () => {
    history.push("/profile-settings");

    // Close the notification popup
    handleBellClose();
  };

  return (
    <>
      <NotificationPopUp
        id={id}
        open={open}
        // PaperProps={MenuProps}
        anchorEl={notificationEl}
        onClose={handleBellClose}
        // anchorOrigin={{
        //   vertical: "bottom",
        //   horizontal: "center",
        // }}

        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <NotificationHeader>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <BlackTypography varient="h5">All Notifications</BlackTypography>
            <Link
              //  to="../profile-settings"
              onClick={handleSettingsClick}
            >
              {/* <FeatherIcon
                icon="settings"
                size="16"
                style={{ color: "#024430" }}
              /> */}
            </Link>
          </Stack>
        </NotificationHeader>
     

        <NotificationBox
          ref={notificationRef}
          sx={{ height: "100%", overflowY: "scroll" }}
        >
          {loading ? (
            // Loader component
         <CircularProgress color="success" />
          ) : (
            <>
              {notificationsData?.length > 0 ? (
                <>
                  <Box sx={{ marginTop: "37px", padding: "20px" }}>
                    {notificationsData?.map((each, i) => (
                      <>
                        <StyledListCard
                          onClick={() => {
                            if (
                              each?.info?.refresh_type === "chats." ||
                              each?.info?.redirect_type === "chats."
                            ) {
                              //Need stage order in this case

                              history.push(
                                `/joblist/jobs/user-job-post?job_id=${
                                  each?.info?.job_post_details?.id
                                }&user_id=${
                                  JSON.parse(localStorage.getItem("authUser"))
                                    ?.id
                                }&user_job_post_id=${
                                  each?.info?.user_job_post_id?.id
                                }&stage_order=${
                                  each?.info?.user_job_post_id
                                    ?.job_post_pipeline_stage?.order ?? 1
                                }&tab=4`
                              );
                            } else if (
                              each?.info?.refresh_type === "pending_jobs." ||
                              each?.info?.redirect_type === "pending_jobs."
                            ) {
                              //Need job name in this case
                              history.push(`/joblist/pending`);
                            } else if (
                              each?.info?.refresh_type === "active_jobs." ||
                              each?.info?.redirect_type === "active_jobs."
                            ) {
                              //Need job name in this case
                              history.push(`/joblist/approved`);
                            } else if (
                              each?.info?.refresh_type === "closed_jobs." ||
                              each?.info?.redirect_type === "closed_jobs."
                            ) {
                              //Need job name in this case
                              history.push(`/joblist/closed`);
                            } else if (
                              each?.info?.refresh_type === "companies" ||
                              each?.info?.redirect_type === "companies"
                            ) {
                              history.push(`/company-list`);
                            } else if (
                              each?.info?.refresh_type === "jobs." ||
                              each?.info?.redirect_type === "jobs."
                            ) {
                              history.push(`/joblist/approved`);
                            } else if (
                              each?.info?.redirect_type === "SCREENING_PARTNER"
                            ) {
                              history.push(`/screening-partner-list`);
                            } else if (
                              each?.info?.redirect_type === "COMPANY"
                            ) {
                              history.push(`/company-list`);
                            } else if (
                              each?.info?.redirect_type ===
                              "BACKGROUND_VERIFICATION"
                            ) {
                              history.push(`/background-evaluators-list`);
                            } else if (
                              each?.info?.redirect_type === "REFERAL_PARTNER"
                            ) {
                              history.push(`/referal-partner-list`);
                            } else if (
                              each?.info?.redirect_type ===
                              "RECRUITMENT_COMPANY"
                            ) {
                              history.push(`/recruiting-company-list`);
                            } else if (
                              each?.info?.redirect_type === "EVALUATOR"
                            ) {
                              history.push(`/technical-evaluator-list`);
                            }
                          }}
                        >
                          <ListItem key={i} sx={{ padding: 0 }}>
                            <ListItemAvatar>
                              <Avatar
                                src={each.image}
                                style={{ border: "1px solid #EFF7FF" }}
                              ></Avatar>
                            </ListItemAvatar>

                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "flex-start",
                              }}
                            >
                              {each?.role && each?.title ? (
                                <>
                                  <ListItemText
                                    primary={`${each.role}`}
                                    secondary={`${each.title}`}
                                    primaryTypographyProps={{
                                      style: {
                                        fontFamily: "Inter",
                                        fontSize: "14px",
                                        fontWeight: 600,
                                        letterSpacing: "-1%",
                                      },
                                    }}
                                    secondaryTypographyProps={{
                                      style: {
                                        fontFamily: "Inter",
                                        fontSize: "13px",
                                        fontWeight: 500,
                                        letterSpacing: "-1%",
                                        color: "rgba(0, 0, 0, 0.53)",
                                      },
                                    }}
                                  />
                                </>
                              ) : (
                                ""
                              )}

                              <ListItemText
                                secondary={`${each.time}`}
                                className="time_stamp"
                                secondaryTypographyProps={{
                                  style: {
                                    fontFamily: "Inter",
                                    fontSize: "13px",
                                    fontWeight: "500",
                                    letterSpacing: "-1%",
                                    color: "rgba(0, 0, 0, 0.53)",
                                    opacity: "60%",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  },
                                }}
                              />
                            </div>
                          </ListItem>
                          {each?.message && (
                            <p
                              className="notification_msg "
                              style={{ fontSize: "13px", marginBottom: 0 }}
                            >
                              {each.message}
                            </p>
                          )}
                          <p
                            className="notification_msg "
                            style={{ fontSize: "13px" }}
                          >
                            {each?.timeDelay}
                          </p>
                        </StyledListCard>
                      </>
                    ))}
                  </Box>
                </>
              ) : (
                <GreyTypography
                  variant="h2"
                  component="h2"
                  style={{
                    fontSize: "20px",
                    marginTop: "50%",
                    textAlign: "center",
                  }}
                >
                  No notifications
                </GreyTypography>
              )}
            </>
          )}
        </NotificationBox>
      </NotificationPopUp>
    </>
  );
}
