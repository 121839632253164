import {
  Autocomplete,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import {
  BlackTypography,
  GreyTypography,
  MenuProps,
  PrimaryContrastedSmallToggleButton,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  PrimaryToggleButtonGroup,
  autocompleteProps,
  PrimaryCurrencyInputs,
  MultipleSelectInputs,
} from "assets/Mui/globalTheme";
import { useFormContext, Controller } from "react-hook-form";
import cc from "currency-codes";
import GoogleLocation from "components/Common/Forms/location";
import getSymbolFromCurrency from "currency-symbol-map";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { formatNumberWithCommas } from "helpers/functions/formatAmount";
export default function BasicDetails() {
  const {
    register,
    getValues,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();
  const job_type = [
    { name: "Full Time", value: "Full_Time" },
    // { name: "Part Time", value: "Part_Time" },
    { name: "Contract", value: "Contract" },
    // { name: "Freelance", value: "Freelance" },
  ];
  const work_mode = [
    { name: "On Site", value: "On_site" },
    { name: "Hybrid", value: "Hybrid" },
    { name: "Remote", value: "Remote" },
  ];

  const citizen_ship = [
    { name: "H1B", value: "H1B" },
    { name: "H4EAD", value: "H4EAD" },
    { name: "Citizen or GC", value: "Citizen or GC" },
  ];

  const payouts = [
    { name: "Bi Weekly", value: "Bi_Weekly" },
    // { name: "Annual", value: "Annual" },
    { name: "Monthly", value: "Monthly" },
    // { name: "Hourly", value: "Hourly" },
  ];
  const min_exp = [
    { name: "0", value: "0" },
    { name: "1", value: "1" },
    { name: "2", value: "2" },
    { name: "3", value: "3" },
    { name: "4", value: "4" },
    { name: "5", value: "5" },
    { name: "6", value: "6" },
    { name: "7", value: "7" },
    { name: "8", value: "8" },
    { name: "9", value: "9" },
    { name: "10+", value: "10" },
  ];
  const min_exp_months = [
    { name: "0", value: "0" },
    { name: "1", value: "1" },
    { name: "2", value: "2" },
    { name: "3", value: "3" },
    { name: "4", value: "4" },
    { name: "5", value: "5" },
    { name: "6", value: "6" },
    { name: "7", value: "7" },
    { name: "8", value: "8" },
    { name: "9", value: "9" },
    { name: "10", value: "10" },
    { name: "11", value: "11" },
  ];
  const asia = [
    { name: "Dubai (UTC +4)" },
    { name: "New Delhi (UTC +5)" },
    { name: "China (UTC +6)" },
    { name: "Singapore (UTC +7)" },
  ];
  const aus = [
    { name: "Australian EDT [UTC+1]" },
    { name: "Australian CDT [UTC+10:30]" },
  ];
  const eur = [
    { name: "Easter European [UTC +2]" },
    { name: "Easter EST [UTC+3]" },
    { name: "Greenwich Mean [UTC +0]" },
    { name: "Further EET [UTC+3]" },
  ];
  const hours_per_week = [
    { value: "more_than_30", name: "more than 30" },
    { value: "less_than_30", name: "less than 30" },
    { value: "flexible_hours", name: "flexible hours" },
  ];
  // const project_duration = [
  //   { value: "less_than_month", name: "less than month" },
  //   { value: "1_3_months", name: "1-3 months" },
  //   { value: "3_6_months", name: "3-6 months" },
  //   { value: "more_than_6_months", name: "more than 6 months" },
  // ];
  const project_duration = [
    { value: "6_months", name: "6 months" },
    { value: "6_12_months", name: "6-12 months" },
    { value: "1_2_years", name: "1-2 years" },
    { value: "more_than_2_years", name: "2+ years" },
    { value: "long_term", name: "Long Term" },
  ];
  const us = [{ name: "Eastern Daylight [UTC+2-4]" }];

  /*Scroll to top */
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  /*Scroll to top */

  const currencies = cc.codes()?.map(item => item);
  function handleTimeZone(_, newValue) {
    if (newValue.length) setValue("time_zone", newValue);
  }

  const handleInputChange = e => {
    const rawValue = e.target.value.replace(/,/g, "");
    const formattedValue = formatNumberWithCommas(rawValue);
    setValue("min_sal", formattedValue);
  };

  const handleInputMaxChange = e => {
    const rawValue = e.target.value.replace(/,/g, "");
    const formattedValue = formatNumberWithCommas(rawValue);
    setValue("max_sal", formattedValue);
  };

  // const formatNumberWithCommas = number => {
  //   if (!isNaN(number)) {
  //     return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   }
  //   return "";
  // };
  console.log("getValues", getValues());
  return (
    <Stack spacing={4}>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <BlackTypography
            sx={{ fontWeight: "bold" }}
            variant="h3"
            component="h3"
          >
            Enter below Details
          </BlackTypography>
        </Grid>
        <Grid item xs={12} md={4.5} lg={3}>
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="job_title-outlined"
              error={errors.job_title}
            >
              Job Title*
            </PrimaryNormalInputLabel>
            <PrimaryNormalInputs
              fullWidth
              placeholder="Enter your job title"
              {...register("job_title", { required: true, pattern: /\S/ })}
              id="job_title-outlined"
              autoFocus
              label="job_title address"
              type={"text"}
              error={errors.job_title}
              variant="outlined"
            />
            <PrimaryNormalFormHelperText error={errors.job_title}>
              {errors?.job_title ? "Job title is a required field" : ""}
            </PrimaryNormalFormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4.5} lg={3}>
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="folloups-job-type"
              error={errors.job_type}
            >
              Select A Job Type*
            </PrimaryNormalInputLabel>
            <Select
              labelId="folloups-job-type"
              id="folloups-job-type"
              value={getValues("job_type")}
              {...register("job_type", {
                required: true,
                onChange: e => {
                  const jobType = e.target.value;
                  if (jobType === "Contract") {
                    setValue("pay_type", "per_hour");
                  } else {
                    setValue("pay_type", "per_annum");
                  }
                },
              })}
              input={
                <PrimaryNormalInputs
                  placeholder="Select A Job Type"
                  label="folloups-job-type"
                  error={errors?.job_type && watch("job_type") == ""}
                  {...register("job_type", { required: true })}
                />
              }
              MenuProps={MenuProps}
              // style={{ height: '18px' }}
            >
              {job_type?.map(item => {
                return (
                  <MenuItem
                    key={item?.value}
                    value={item?.value} // style={getStyles(name, personName, theme)}
                  >
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
            <PrimaryNormalFormHelperText error={errors.job_type}>
              {errors?.job_type && watch("job_type") == ""
                ? "Job type is a required field"
                : ""}
            </PrimaryNormalFormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="work-mode"
              error={errors.work_mode}
            >
              Select A Work Mode*
            </PrimaryNormalInputLabel>
            <Select
              labelId="work-mode"
              id="work-mode"
              placeholder="Select A work Mode"
              value={getValues("work_mode")}
              {...register("work_mode", { required: true })}
              input={
                <PrimaryNormalInputs
                  label="work-mode"
                  placeholder="Select A work Mode"
                  error={errors?.work_mode && watch("work_mode") == ""}
                  {...register("work_mode", { required: true })}
                />
              }
              MenuProps={MenuProps}
            >
              {work_mode?.map(item => {
                return (
                  <MenuItem
                    key={item?.value}
                    value={item?.value} // style={getStyles(name, personName, theme)}
                  >
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
            <PrimaryNormalFormHelperText error={errors.work_mode}>
              {errors?.work_mode && watch("work_mode") == ""
                ? "Work mode is a required field"
                : ""}
            </PrimaryNormalFormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <FormControl sx={{ width: "100%" }}>
            <PrimaryNormalInputLabel
              error={errors?.payout}
              shrink
              sx={{
                left: "-12px",
                top: "10px",
              }}
              id="signup-user-type"
            >
              Payout*
            </PrimaryNormalInputLabel>
            <Select
              labelId="payout"
              value={watch("payout")}
              // labelId="selct-user-type"
              id="demo-multiple-name"
              {...register("payout", { required: true })}
              input={
                <PrimaryNormalInputs
                  label="payout-label"
                  error={errors?.payout && watch("payout") == ""}
                  {...register("payout", { required: true })}
                />
              }
              MenuProps={MenuProps}
            >
              {payouts?.map(item => {
                return (
                  <MenuItem
                    key={item?.value}
                    value={item?.value} // style={getStyles(name, personName, theme)}
                  >
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
            <PrimaryNormalFormHelperText error={errors.payout}>
              {errors.payout && watch("payout") == ""
                ? "Payout is a required Field"
                : ""}
            </PrimaryNormalFormHelperText>
          </FormControl>
        </Grid>

        {/*Citizen ship dropdown details*/}
        <Grid item xs={12} md={4.5} lg={3}>
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
              Select A Citizenship
            </PrimaryNormalInputLabel>
            <Controller
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="folloups-job-type"
                  id="folloups-job-type"
                  multiple
                  input={
                    <PrimaryNormalInputs
                      placeholder="Select A Citizenship"
                      label="folloups-job-type"
                    />
                  }
                  MenuProps={MenuProps}
                >
                  {citizen_ship.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
              name="citizenship_status"
              control={control}
              defaultValue={[]}
            />
            <PrimaryNormalFormHelperText error={errors.citizen_ship}>
              {errors?.citizen_ship ? "Citizenship is a required field" : ""}
            </PrimaryNormalFormHelperText>
          </FormControl>
        </Grid>

        <>
          <Grid
            item
            xs={12}
            md={4}
            lg={3}
            style={{
              display: "none",
              // watch("job_type") !== "Full_Time" && watch("job_type") !== ""
              //   ? "block"
              //   : "none",
            }}
          >
            <FormControl sx={{ width: "100%" }} variant="standard">
              <PrimaryNormalInputLabel
                shrink
                htmlFor="hours-per-week"
                error={errors.hours_per_week}
              >
                Hours Per Week
              </PrimaryNormalInputLabel>
              <Select
                labelId="hours-per-week"
                id="hours-per-week"
                value={getValues("hours_per_week")}
                {...register("hours_per_week", {
                  required: false,
                })}
                input={
                  <PrimaryNormalInputs
                    label="hours-per-week"
                    error={errors?.hours_per_week}
                    {...register("hours_per_week", {
                      required: false,
                    })}
                  />
                }
                MenuProps={MenuProps}
              >
                {hours_per_week?.map(item => {
                  return (
                    <MenuItem
                      key={item?.value}
                      value={item?.value} // style={getStyles(name, personName, theme)}
                    >
                      {item?.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <PrimaryNormalFormHelperText error={errors.work_mode}>
                {errors?.work_mode ? "required field" : ""}
              </PrimaryNormalFormHelperText>
            </FormControl>
          </Grid>
          {watch("job_type") !== "Full_Time" && watch("job_type") !== "" ? (
            <Grid item xs={12} md={4} lg={3}>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <PrimaryNormalInputLabel
                  shrink
                  htmlFor="project-duration"
                  error={errors.project_duration}
                >
                  Project Duration
                </PrimaryNormalInputLabel>
                <Select
                  labelId="project-duration"
                  id="project-duration"
                  value={watch("project_duration")}
                  // value={watch('project_duration')}
                  {...register("project_duration", {
                    required: watch("job_type") === "Contract" ? true : false,
                  })}
                  input={
                    <PrimaryNormalInputs
                      label="project-duration"
                      error={errors?.project_duration}
                      {...register("project_duration", {
                        required:
                          watch("job_type") === "Contract" ? true : false,
                      })}
                    />
                  }
                  MenuProps={MenuProps}
                >
                  {project_duration?.map(item => {
                    return (
                      <MenuItem
                        key={item?.value}
                        value={item?.value} // style={getStyles(name, personName, theme)}
                      >
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <PrimaryNormalFormHelperText error={errors.project_duration}>
                  {errors?.project_duration
                    ? "Project duration is a required field"
                    : ""}
                </PrimaryNormalFormHelperText>
              </FormControl>
            </Grid>
          ) : null}
        </>

        <Grid item xs={12}>
          <BlackTypography
            sx={{ fontWeight: "bold" }}
            variant="h3"
            component="h3"
          >
            {watch("job_type") !== "Contract" ? "Salary Details" : "Pay"}
          </BlackTypography>
        </Grid>
        <Grid item xs={12} md={2} lg={2}>
          <Autocomplete
            componentsProps={{
              paper: {
                style: {
                  borderRadius: "8px",
                  width: "100%",
                  boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                },
              },
            }}
            popupIcon={<FeatherIcon icon="chevron-down" size={14} />}
            value={watch("currency")}
            // onChange={(event, newValue) => {

            //   setValue("currency", newValue?.toUpperCase());
            // }}

            inputValue={watch("currency")}
            onInputChange={(event, newInputValue) => {
              // const currencyCheck = currencies?.includes(newInputValue)
              const currencyCheck = currencies?.includes(
                newInputValue.toUpperCase()
              );
              setValue("currency", newInputValue?.toUpperCase());
              setTimeout(() => {
                if (currencyCheck) {
                  setValue("currency", newInputValue?.toUpperCase());
                } else {
                  setValue("currency", "");
                }
              }, 800);
            }}
            {...register("currency", { required: true })}
            id="controllable-states-demo"
            options={currencies}
            // getOptionSelected={(option, value) => option === value}
            // getOptionLabel={option => option}
            renderInput={params => (
              <FormControl variant="standard">
                <PrimaryNormalInputLabel
                  shrink
                  htmlFor="folloups-job-type"
                  error={errors.currency}
                >
                  Currency*
                </PrimaryNormalInputLabel>
                {/* <PrimaryNormalInputs
                  ref={params.InputProps.ref}
                  {...params}
                  label="Controllable"
                  SelectProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <ArrowDropDownIcon />
                      </InputAdornment>
                    ),
                  }}
                /> */}
                <PrimaryCurrencyInputs
                  ref={params.InputProps.ref}
                  {...params}
                  // label="Controllable"
                />
              </FormControl>
            )}
          />
          <PrimaryNormalFormHelperText error={errors.currency}>
            {errors?.currency ? "Currency is a required field" : ""}
          </PrimaryNormalFormHelperText>
        </Grid>
        <Grid item xs={12} md={2} lg={3}>
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="min-sal-outlined"
              error={errors.min_sal}
            >
              Minimum Salary*
            </PrimaryNormalInputLabel>
            <PrimaryNormalInputs
              fullWidth
              placeholder="2000000"
              // {...register("min_sal", {
              //   required: true,
              //   max: Number(watch("max_sal")),
              // })}

              {...register("min_sal", {
                required: true,
                validate: {
                  positive: value => {
                    const numericValue = Number(value?.replace(/,/g, ""));
                    return numericValue > 0;
                  },
                  lessThanMax: value => {
                    const numericValue = Number(value?.replace(/,/g, ""));
                    const maxSal = Number(watch("max_sal")?.replace(/,/g, ""));
                    return !maxSal || numericValue <= maxSal;
                  },
                },
              })}
              id="min-sal-outlined"
              autoFocus
              labelId="min-sal-outlined"
              label="Minimum Salary"
              // type={"number"}
              type={"text"}
              onInput={handleInputChange}
              // error={
              //   errors.min_sal ||
              //   Number(watch("min_sal")) > Number(watch("max_sal")) ||
              //   Number(watch("min_sal")) < 0 ||
              //   Number(watch("max_sal")) < 0
              // }
              // value={watch("min_sal")}
              error={
                errors.min_sal ||
                (Number(watch("min_sal")?.replace(/,/g, "")) >
                  Number(watch("max_sal")?.replace(/,/g, "")) &&
                  !!watch("max_sal")) // Check for max_sal value too
              }
              // onInput={e => {
              //   const value = parseInt(e.target.value)
              //   e.target.value = isNaN(value) || value < 0 ? "" : value;
              // }}

              endAdornment={
                <InputAdornment
                  sx={{
                    background: "white",
                    position: "absolute",
                    right: "10px",
                  }}
                  position="end"
                >
                  {getSymbolFromCurrency(watch("currency"))}
                </InputAdornment>
              }
              variant="outlined"
            />

            <PrimaryNormalFormHelperText error={errors.min_sal}>
              {errors?.min_sal ? "Minimum salary is a required field" : ""}
            </PrimaryNormalFormHelperText>
          </FormControl>
        </Grid>
        {/* <Grid
          item
          xs={12}
          md={1}
          lg={1}
          sx={{ placeItems: "center", display: "grid" }}
        >
          <GreyTypography mt={2.5} variant="h6" component="h6">
            Upto
          </GreyTypography>
        </Grid> */}
        <Grid item xs={12} md={2} lg={3}>
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="max-sal-outlined"
              error={errors.max_sal}
            >
              Maximum Salary*
            </PrimaryNormalInputLabel>
            <PrimaryNormalInputs
              fullWidth
              placeholder="3000000"
              // {...register("max_sal", {
              //   required: true,
              //   min: Number(watch("min_sal")),
              // })}
              {...register("max_sal", {
                required: true,
                validate: {
                  positive: value => {
                    const numericValue = Number(value?.replace(/,/g, ""));
                    return numericValue > 0;
                  },
                  greaterThanMin: value => {
                    const numericValue = Number(value?.replace(/,/g, ""));
                    const minSal = Number(watch("min_sal")?.replace(/,/g, ""));
                    return !minSal || numericValue >= minSal;
                  },
                },
              })}
              id="max-sal-outlined"
              autoFocus
              labelId="max-sal-outlined"
              label="Minimum Salary"
              // type={"number"}
              // error={
              //   errors.max_sal || Number(watch("min_sal")) > watch("max_sal")
              // }
              // value={watch("max_sal")}
              error={
                errors.max_sal ||
                Number(watch("min_sal")?.replace(/,/g, "")) >
                  Number(watch("max_sal")?.replace(/,/g, ""))
              }
              type={"text"}
              onInput={handleInputMaxChange}
              // onInput={e => {
              //   const value = parseInt(e.target.value);
              //   e.target.value = isNaN(value) || value < 0 ? "" : value;
              // }}
              variant="outlined"
              endAdornment={
                <InputAdornment
                  sx={{
                    background: "white",
                    position: "absolute",
                    right: "10px",
                  }}
                  position="end"
                >
                  {getSymbolFromCurrency(watch("currency"))}
                </InputAdornment>
              }
            />
            <PrimaryNormalFormHelperText error={errors.max_sal}>
              {errors?.max_sal ? "Maximum salary is a required field" : ""}
            </PrimaryNormalFormHelperText>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={2}
          sx={{ justifyItems: "start", alignItems: "center", display: "grid" }}
        >
          <GreyTypography
            sx={{ fontSize: "16px !important" }}
            mt={2.5}
            variant="h6"
            component="h6"
          >
            {watch("job_type") !== "Contract" ? (
              "Per Annum"
            ) : (
              <FormControl sx={{ width: "100%" }}>
                <Select
                  labelId="pay_type"
                  value={watch("pay_type")}
                  // labelId="selct-user-type"
                  id="demo-multiple-name"
                  {...register("pay_type", { required: true })}
                  input={
                    <PrimaryNormalInputs
                      label="Payment Type"
                      error={errors?.pay_type && watch("pay_type") == ""}
                      {...register("pay_type", { required: false })}
                    />
                  }
                  MenuProps={MenuProps}
                >
                  <MenuItem value="per_hour">Per Hour</MenuItem>
                  <MenuItem value="per_month">Per Month</MenuItem>
                </Select>
              </FormControl>
            )}
          </GreyTypography>
        </Grid>

        <Grid item xs={12}>
          <BlackTypography
            sx={{ fontWeight: "bold" }}
            variant="h3"
            component="h3"
          >
            Experience Required*
          </BlackTypography>
        </Grid>
        <Grid item xs={12} md={2} lg={3}>
          <FormControl sx={{ width: "100%" }}>
            <PrimaryNormalInputLabel
              error={errors?.min_exp}
              shrink
              sx={{
                left: "-12px",
                top: "10px",
              }}
              id="signup-user-type"
            >
              Min years
            </PrimaryNormalInputLabel>
            <Select
              labelId="min_exp"
              name="min_exp"
              value={watch("min_exp")}
              displayEmpty
              // labelId="selct-user-type"
              id="demo-multiple-name"
              {...register("min_exp", {
                required: true,
                // validate: value =>
                //   value !== "10+"
                //     ? watch("max_exp") !== "10+"
                //       ? Number(value) <= Number(watch("max_exp"))
                //       : true
                //     : true,

                validate: value =>
                  value !== "10+"
                    ? watch("max_exp") !== "10+"
                      ? Number(watch("max_exp")) * 12 +
                          Number(watch("max_exp_months")) >
                        Number(watch("min_exp")) * 12 +
                          Number(watch("min_exp_months"))
                      : true
                    : true,
              })}
              input={
                <PrimaryNormalInputs
                  label="min_exp-label"
                  error={
                    errors?.min_exp || watch("min_exp") !== "10+"
                      ? Number(watch("min_exp")) > Number(watch("max_exp"))
                      : false
                  }
                  {...register("min_exp", { required: true })}
                />
              }
              MenuProps={MenuProps}
            >
              {min_exp?.map(item => {
                return (
                  <MenuItem
                    key={item?.value}
                    value={item?.value} // style={getStyles(name, personName, theme)}
                  >
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
            <PrimaryNormalFormHelperText error={errors.min_exp}>
              {errors.min_exp &&
              Number(watch("min_exp")) > Number(watch("max_exp"))
                ? "Minimum exp should be less than maximum exp"
                : errors.min_exp && !Boolean(watch("min_exp"))
                ? "Minimum exprience is a required Field"
                : ""}
            </PrimaryNormalFormHelperText>
          </FormControl>
        </Grid>

        {watch("min_exp") !== "10+" ? (
          <>
            <Grid item xs={12} md={2} lg={3}>
              <FormControl sx={{ width: "100%" }}>
                <PrimaryNormalInputLabel
                  error={errors?.min_exp}
                  shrink
                  sx={{
                    left: "-12px",
                    top: "10px",
                  }}
                  id="signup-user-type"
                >
                  Months
                </PrimaryNormalInputLabel>
                <Select
                  labelId="min_exp_months"
                  name="min_exp_months"
                  value={watch("min_exp_months")}
                  // labelId="selct-user-type"
                  id="demo-multiple-name"
                  {...register("min_exp_months", {
                    required: true,

                    validate: value =>
                      value
                        ? //  Number(watch("max_exp"))
                          (Number(watch("max_exp").match(/^\d+/)) ||
                            Number(getValues("max_exp").match(/^\d+/))) *
                            12 +
                            (Number(watch("max_exp_months")) || 0) >=
                          Number(watch("min_exp")) * 12 +
                            Number(watch("min_exp_months"))
                        : false,
                  })}
                  input={
                    <PrimaryNormalInputs
                      label="min_exp-label"
                      error={
                        errors?.min_exp_months ||
                        Number(watch("min_exp")) * 12 +
                          Number(watch("min_exp_months")) >
                          // Number(watch("max_exp"))
                          (Number(watch("max_exp").match(/^\d+/)) ||
                            Number(getValues("max_exp").match(/^\d+/))) *
                            12 +
                            Number(watch("max_exp_months"))
                      }
                      {...register("min_exp_months", { required: false })}
                    />
                  }
                  MenuProps={MenuProps}
                >
                  {min_exp_months?.map(item => {
                    return (
                      <MenuItem
                        key={item?.value}
                        value={item?.value} // style={getStyles(name, personName, theme)}
                      >
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                {/* <PrimaryNormalFormHelperText error={errors.min_exp}>
              {errors.min_exp ? "minimum exprience is a required Field" : ""}
            </PrimaryNormalFormHelperText> */}
              </FormControl>
            </Grid>
            {/* <Grid
              item
              xs={12}
              md={1}
              sx={{ placeItems: "center", display: "grid" }}
            >
              <GreyTypography mt={2.5} variant="h6" component="h6">
                Upto
              </GreyTypography>
            </Grid> */}
            <Grid item xs={12} md={2} lg={3}>
              <FormControl sx={{ width: "100%" }}>
                <PrimaryNormalInputLabel
                  error={errors?.max_exp}
                  shrink
                  sx={{
                    left: "-12px",
                    top: "10px",
                  }}
                  id="signup-user-type"
                >
                  Max years
                </PrimaryNormalInputLabel>
                <Select
                  labelId="max_exp"
                  // labelId="selct-user-type"
                  value={watch("max_exp")}
                  name="max_exp"
                  displayEmpty
                  id="demo-multiple-name"
                  {...register("max_exp", {
                    required: true,

                    // validate:value =>
                    // value !== "10+"?
                    // Number(watch("max_exp")) * 12 +
                    //             Number(watch("max_exp_months")) >
                    //           Number(watch("min_exp")) * 12 +
                    //             Number(watch("min_exp_months")):true

                    validate: value =>
                      value !== "10+"
                        ? Number(watch("max_exp")) * 12 +
                            Number(watch("max_exp_months")) >
                          Number(watch("min_exp")) * 12 +
                            Number(watch("min_exp_months"))
                        : false,
                  })}
                  input={
                    <PrimaryNormalInputs
                      label="max_exp-label"
                      // error={
                      //   errors?.max_exp ||
                      //   Number(watch("min_exp")) > Number(watch("max_exp"))
                      // }
                      error={
                        errors?.max_exp &&
                        Number(watch("max_exp")) * 12 +
                          Number(watch("max_exp_months")) <=
                          Number(watch("min_exp")) * 12 +
                            Number(watch("min_exp_months"))
                      }
                      {...register("max_exp", { required: true })}
                    />
                  }
                  MenuProps={MenuProps}
                >
                  {min_exp?.map(item => {
                    return (
                      <MenuItem
                        key={item?.value}
                        value={item?.value} // style={getStyles(name, personName, theme)}
                      >
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <PrimaryNormalFormHelperText error={errors.max_exp}>
                  {errors.max_exp &&
                  Number(watch("max_exp")) < Number(watch("min_exp"))
                    ? "Maximum exp should be greater than minimum exp"
                    : errors.max_exp && !Boolean(watch("max_exp"))
                    ? "Maximum experience is a required Field"
                    : ""}
                </PrimaryNormalFormHelperText>
              </FormControl>
            </Grid>

            {watch("max_exp") !== "10+" ? (
              <Grid item xs={12} md={2} lg={3}>
                <FormControl sx={{ width: "100%" }}>
                  <PrimaryNormalInputLabel
                    error={errors?.min_exp}
                    shrink
                    sx={{
                      left: "-12px",
                      top: "10px",
                    }}
                    id="signup-user-type"
                  >
                    Months
                  </PrimaryNormalInputLabel>
                  <Select
                    labelId="max_exp_months"
                    name="max_exp_months"
                    value={watch("max_exp_months")}
                    // labelId="selct-user-type"
                    id="demo-multiple-name"
                    {...register("max_exp_months", {
                      required: true,

                      // validate: value =>
                      //   value
                      //     ? watch("min_exp_months") <= Number(value)
                      //     : false,

                      validate: value =>
                        value
                          ? Number(watch("min_exp")) * 12 +
                              Number(watch("min_exp_months")) <=
                            Number(watch("max_exp")) * 12 +
                              Number(watch("max_exp_months"))
                          : false,
                    })}
                    input={
                      <PrimaryNormalInputs
                        label="min_exp-label"
                        error={
                          errors?.max_exp_months ||
                          Number(watch("min_exp")) * 12 +
                            Number(watch("min_exp_months")) >
                            Number(watch("max_exp")) * 12 +
                              Number(watch("max_exp_months"))
                        }
                        {...register("max_exp_months", { required: false })}
                      />
                    }
                    MenuProps={MenuProps}
                  >
                    {min_exp_months?.map(item => {
                      return (
                        <MenuItem
                          key={item?.value}
                          value={item?.value} // style={getStyles(name, personName, theme)}
                        >
                          {item?.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {/* <PrimaryNormalFormHelperText error={errors.min_exp}>
              {errors.min_exp ? "minimum exprience is a required Field" : ""}
            </PrimaryNormalFormHelperText> */}
                </FormControl>
              </Grid>
            ) : null}
          </>
        ) : null}

        {watch("work_mode") === "Remote" ? (
          <>
            <Grid item xs={12} lg={12} md={12}>
              <BlackTypography
                sx={{ fontWeight: "bold" }}
                variant="h3"
                component="h3"
              >
                Location
              </BlackTypography>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <Stack spacing={2}>
                  {/* <PrimaryNormalInputLabel
                    // shrink
                    htmlFor="folloups-location-outlined"
                    style={{ marginTop: "2px !important " }}
                  >
                    Location
                  </PrimaryNormalInputLabel> */}
                  <PrimaryNormalInputs
                    sx={{ mt: "25px !important" }}
                    readOnly
                    defaultValue={getValues("work_mode")}
                  />
                </Stack>
              </FormControl>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} lg={12} md={12}>
              <BlackTypography
                sx={{ fontWeight: "bold" }}
                variant="h3"
                component="h3"
              >
                Location
              </BlackTypography>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <Stack spacing={2}>
                  {/* <PrimaryNormalInputLabel
                  shrink
                  htmlFor="folloups-location-outlined"
                >
                  Location
                </PrimaryNormalInputLabel> */}
                  <GoogleLocation
                    updatingContent={"location"}
                    state={"state"}
                    country={"country"}
                    city={"city"}
                    requireStatus={watch("work_mode") === "Remote"}
                  />
                </Stack>
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>
    </Stack>
  );
}
