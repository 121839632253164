import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import MaskedInput from "string-mask";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import {
  Button,
  CardActionArea,
  CardActions,
  Grid,
  Stack,
  IconButton,
} from "@mui/material";
import {
  BlackTypography,
  CustomAvatar,
  StyledTootlTip,
  MenuButton,
  MiddleDivider,
  PrimaryChip,
  DateChip,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { deepOrange, grey, teal } from "@mui/material/colors";
import { useDrag } from "react-dnd";
import { Link, useParams } from "react-router-dom";
import { SP, TE, RS, RC, CA, CS, ZA, ACM } from "common/constants/userModules";
import CardPopup from "./CardPopUp";
import { parseNumberInput } from "hooks/customPhoneNumberParse";
import PersonIcon from "@mui/icons-material/Person";
import {
  roleRestrictions,
  roleRestrictionsForHash,
} from "common/constants/roleRestrictions";
import JobCardInfo from "pages/jobCardInfo";
import SearchParamHook from "hooks/searchParams";
import { useHistory, useLocation } from "react-router-dom";

const applyTags = {
  self: "A",
  sourced: "s",
  refer: "R",
};

const popperProps = {
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [100, 0], // Adjust the values to reduce the distance
      },
    },
  ],
};

export default function UserJobCard({
  user_data,
  order,
  stage_name,
  authList,
  role,
  disableStatus
}) {
  const searchParamsHook = SearchParamHook();
  const { search } = useLocation();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const { id } = useParams();
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: "KANBAN",
    // canDrag:false,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
    // canDrag:stage_name == "Sourced" && user_data?.created_by_user_type !== 'REFERAL_PARTNER' ? true:false,
    item: {
      id: order,
      card_id: user_data?.id,
      owner_type: user_data?.created_by_user_type,
      // evaluation:user_data?.screening_partner_details?.evalution
    },
  }));
  const sp = user_data?.screening_partner_details
    ? Object.keys(user_data?.screening_partner_details)?.length
      ? user_data.screening_partner_details.first_name?.charAt(0) +
        user_data.screening_partner_details.last_name?.charAt(0)
      : SP
    : SP;
  const te = user_data?.evaluator_details
    ? Object.keys(user_data?.evaluator_details)?.length
      ? user_data.evaluator_details.first_name?.charAt(0) +
        user_data.evaluator_details.last_name?.charAt(0)
      : TE
    : TE;
  const me = user_data?.moderator_details
    ? Object.keys(user_data?.moderator_details)?.length
      ? user_data.moderator_details.first_name?.charAt(0) +
        user_data.moderator_details.last_name?.charAt(0)
      : "ME"
    : "ME";
  function getTimeDiff() {
    const comingDate = user_data?.createdAt;
    const currentDate = new Date();
    const targetDate = new Date(comingDate);
    const differenceInMilliseconds =
      currentDate.getTime() - targetDate.getTime();

    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
    const differenceInMinutes = Math.floor(differenceInSeconds / 60);
    const differenceInHours = Math.floor(differenceInMinutes / 60);
    const differenceInDays = Math.floor(differenceInHours / 24);

    if (differenceInMinutes > 60 && differenceInHours < 24) {
      let value = differenceInHours.toString() + " Hours ago";
      return value;
    } else if (differenceInMinutes < 60) {
      let value = differenceInMinutes.toString() + " Minutes ago";
      return value;
    } else {
      let value = differenceInDays.toString() + " days ago";
      return value;
    }
  }
  const tagName =
    user_data?.user_job_post_details?.created_by_details?.first_name +
    " " +
    user_data?.user_job_post_details?.created_by_details?.last_name;

  const appliedUserName = user_data?.user_detail_data
    ? user_data?.user_detail_data?.first_name +
      " " +
      user_data?.user_detail_data?.last_name
    : "Self Applied";

  /*For sourced profiles chat selection */
  let isClickable;
  const sourcedUser = user_data?.created_by_user_type;
  if (
    stage_name === "Sourced" &&
    [RC, RS].includes(role) &&
    sourcedUser === "RECRUITMENT_COMPANY"
  ) {
    isClickable = true;
  } else if ([ZA, ACM, SP].includes(role)) {
    isClickable = true;
  } else if (
    stage_name === "Sourced" &&
    [CA, CS].includes(role) &&
    sourcedUser === "COMPANY"
  ) {
    isClickable = true;
  }
  /*For sourced profiles chat selection */

  const mask = new MaskedInput("AA*****AA", { A: /[A-Za-z]/ });

  const hashedNames = data => {
    const stageHashCheck = roleRestrictionsForHash[role]?.includes(order);
    const sourcedProfileByType = user_data?.created_by_user_type;

    if (stageHashCheck) {
      if (
        sourcedProfileByType === "RECRUITMENT_COMPANY" &&
        [CS, CA].includes(role)
      ) {
        const hashedOne = mask.apply(data);
        return hashedOne;
      } else if (
        sourcedProfileByType === "COMPANY" &&
        [RS, RC].includes(role)
      ) {
        const hashedOne = mask.apply(data);
        return hashedOne;
      } else {
        return data;
      }
    } else {
      const hashedOne = mask.apply(data);
      return hashedOne;
    }

    // if (stageHashCheck) {
    //   return data;
    // } else {
    //   const hashedOne = mask.apply(data);
    //   return hashedOne;
    // }
  };

  return (
    <div
      ref={drag}
      style={{
        border: "1px solid rgba(0, 0, 0, 0.08)",
        background: "rgba(255, 255, 255, 1)",
        marginBottom: "10px",
        padding: "10px",
        // borderRadius: "14px",
        borderRadius: "8px",
        width: "100%",
        boxShadow: "none",
        // "&:hover": {
        //   background: "rgba(255,255,255,0.5)",
        // },
      }}
      className="job_user_card_information"
      sx={{ maxWidth: 345 }}
    >
      <Stack spacing={1}>
        <div
          className="d-flex align-items-center justify-content-between"
          //  style={{marginTop:"-8px"}}
        >
          <Link
            onClick={() => {
              const evalDetails = {
                moderator: user_data?.moderator_details?.id,
                screening: user_data?.screening_partner_details?.id,
                technical: user_data?.evaluator_details?.id,
              };

              const searchParams = new URLSearchParams(search);
              searchParams.set("readOnlyStatus", disableStatus);


              localStorage.setItem(
                "evaluation_info",
                JSON.stringify(evalDetails)
              );
            }}
            // to={`/joblist/jobs/user-job-post?job_id=${id}&user_id=${user_data?.user_id}&user_job_post_id=${user_data?.id}&stage_order=${order}`}
            to={
              stage_name === "Sourced" && isClickable
                ? `/joblist/jobs/user-job-post?job_id=${id}&user_id=${user_data?.user_id}&user_job_post_id=${user_data?.id}&stage_order=${order}&readOnlyStatus=${disableStatus}`
                : stage_name !== "Sourced"
                ? `/joblist/jobs/user-job-post?job_id=${id}&user_id=${user_data?.user_id}&user_job_post_id=${user_data?.id}&stage_order=${order}&readOnlyStatus=${disableStatus}`
                : "#"
            }
          >
            <BlackTypography variant="h4">
              {/* <FeatherIcon icon="user" size="14" />{" "} */}
              <PersonIcon size="14" />
              {user_data?.user_detail_data?.first_name}{" "}
              {user_data?.user_detail_data?.last_name}
            </BlackTypography>
          </Link>

          <div className="d-flex align-items-center ">
            {/* <IconButton
              className="align-self-end"
              onClick={e => {
                setAnchorEl(e.currentTarget);
              }}
            > */}
            {/* <FeatherIcon icon="more-vertical" size="14" /> */}
            <button
              style={{
                background: "#ffffff",
                border: "0.8px solid grey",
                borderRadius: "29px",
                height: "27.2px",
                textAlign: "center",
              }}
              onClick={e => {
                setAnchorEl(e.currentTarget);
              }}
            >
              <FeatherIcon
                icon="more-horizontal"
                size="14"
                // style={{ marginTop: "-15px" }}
              />
            </button>
            {/* </IconButton> */}

            <CardPopup
              handleClose={() => setAnchorEl(null)}
              anchorEl={anchorEl}
              path={`/joblist/jobs/user-job-post?job_id=${id}&user_id=${user_data?.user_id}&user_job_post_id=${user_data?.id}&stage_order=${order}`}
            />
          </div>
        </div>

        {/* <Stack alignItems="center" direction="row" spacing={1}>
        <FeatherIcon icon="map-pin" size="14" />
        <Typography variant="p" sx={{ fontSize: "13px" }}>
          {user_data?.location}
        </Typography>
      </Stack> */}

        <Stack alignItems="center" direction="row" spacing={1}>
          <FeatherIcon icon="briefcase" size="14" />
          <Typography
            variant="p"
            sx={{ fontSize: "14px", color: "rgba(108, 108, 108, 1)" }}
          >
            {user_data?.user_detail_data?.current_postition || "N/A"}
          </Typography>
        </Stack>

        <Stack alignItems="center" direction="row" spacing={1}>
          <FeatherIcon icon="phone" size="14" />
          <Typography
            variant="p"
            sx={{ fontSize: "14px", color: "rgba(108, 108, 108, 1)" }}
          >
            {hashedNames(
              parseNumberInput(user_data?.user_detail_data?.phone_number)
            ) || "N/A"}
          </Typography>
        </Stack>

        <Stack alignItems="center" direction="row" spacing={1}>
          <FeatherIcon icon="mail" size="14" />
          <Typography
            variant="p"
            sx={{ fontSize: "14px", color: "rgba(108, 108, 108, 1)" }}
          >
            {hashedNames(user_data?.user_detail_data?.email)}
          </Typography>
        </Stack>

        {stage_name !== "Sourced" && (
          <Stack alignItems="center" direction="row" spacing={1}>
            <FeatherIcon icon="dollar-sign" size="14" />
            <JobCardInfo userData={user_data} />
          </Stack>
        )}
      </Stack>
      <MiddleDivider />
      <Stack direction="row" justifyContent={"space-between"}>
        <AvatarGroup total={3}>
          <StyledTootlTip
            title={
              sp !== SP
                ? "screened by " +
                  user_data.screening_partner_details?.first_name +
                  " " +
                  user_data.screening_partner_details?.last_name
                : "screening not yet finalized"
            }
            placement="bottom-end"
            PopperProps={popperProps}
          >
            <CustomAvatar
              bgColor="#B2D1C8"
              bgHeight="24px"
              bgWidth="24px"
              // color="black"
              sx={{ color: "black" }}
            >
              {sp}
            </CustomAvatar>
          </StyledTootlTip>
          <StyledTootlTip
            title={
              te !== TE
                ? "evaluated by " +
                  user_data.evaluator_details?.first_name +
                  " " +
                  user_data.evaluator_details?.last_name
                : "evaluation not yet finalized"
            }
          >
            <CustomAvatar
              bgColor={"#ABDEF9"}
              sx={{ color: "black" }}
              bgHeight="24px"
              bgWidth="24px"
            >
              {te}
            </CustomAvatar>
          </StyledTootlTip>

          <StyledTootlTip
            title={
              me !== "ME"
                ? "moderated by " +
                  user_data.moderator_details?.first_name +
                  " " +
                  user_data.moderator_details?.last_name
                : "moderation not yet finalized"
            }
          >
            <CustomAvatar
              bgColor={"#687075"}
              color="#ffffff"
              bgHeight="24px"
              bgWidth="24px"
            >
              {me}
            </CustomAvatar>
          </StyledTootlTip>
        </AvatarGroup>

        <div className="d-flex align-items-end gap-2 justify-content-end">
          <DateChip label={`${getTimeDiff()}`} size="small" />
          {user_data?.job_apply_type !== "refer" ? (
            <StyledTootlTip placement="top" title={appliedUserName}>
              <CustomAvatar bgColor={"#044B36"} bgHeight="24px" bgWidth="24px">
                {applyTags[user_data?.job_apply_type]}
              </CustomAvatar>
            </StyledTootlTip>
          ) : (
            <StyledTootlTip placement="top" title={tagName}>
              <CustomAvatar bgColor={"#044B36"} bgHeight="24px" bgWidth="24px">
                {applyTags[user_data?.job_apply_type]}
              </CustomAvatar>
            </StyledTootlTip>
          )}
        </div>
      </Stack>
    </div>
  );
}
