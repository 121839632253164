import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SIGNUP_PARTNER,
  SOCIAL_LOGIN,
  IS_ADMIN,
} from "./actionTypes";

export const loginUser = (user, history, location,loadCB, rememberMe) => {
  return {
    type: LOGIN_USER,
    payload: { user, history, location,loadCB, rememberMe },
  };
};
export const signupPartner = (user, history) => {
  return {
    type: SIGNUP_PARTNER,
    payload: { user, history },
  };
};
export const isAdmin = data => {
  return {
    type: IS_ADMIN,
    payload: data,
  };
};

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  };
};
