const { Row, Container, Col, CardBody, Card } = require("reactstrap");
import { updateSingleAdminStaffDetails } from "helpers/services/adminStaff/adminStaff";
import {
  FormControlLabel,
  FormGroup,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  createIndustries,
  deleteIndustries,
  getAllIndustries,
  updateIndustries,
} from "helpers/services/jobAttributes/industries";
import { StageSearchInput, GreyTypography,PrimaryDataGrid,
  CompanyTypography,
  StyledOutlinedInput,

} from "assets/Mui/globalTheme";
import InputAdornment from "@mui/material/InputAdornment";
import JobSearchIcon from "assets/images/icons/JobSearchIcon";

import BubbleLoader from "common/data/NoRowsFoundDatagrid/BubbleLoader";
import React, {useState, useEffect} from 'react';
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress, Stack,Pagination } from "@mui/material";
import CommonModel from "../../components/Common/models/createModel";
import { PrimaryButton, PrimarySwitch } from "assets/Mui/globalTheme";
import { toast } from "react-hot-toast";
import CustomPagination from "components/Pagination/globalPagination";
import { useParams,useHistory } from "react-router-dom";
import SearchParamHook from "hooks/searchParams";
import { useLocation } from "react-router-dom";
import { useDebounce } from "hooks/useDebounce";
import CustomNoRowsOverlay from "common/data/NoRowsFoundDatagrid/NoRowsFound";
import GlobalTableTools from "hooks/GlobalTableTools";

import LoaderIcon from "utils/icons/loaderIcon";
import TablePopper from "components/table/header/popper";

const Industries = () => {
  const searchParamsHook = SearchParamHook();
  const { search } = useLocation();
  const [viewAddModal, setViewAddModal] = React.useState(false);

  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [purpose, setPurpose] = useState('');
  const [index, setIndex] = useState(-1);
  const [id, setId] = useState('');
  const [active, setActive] = useState(false);
  const [name, setName] = useState('');
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openSearch, setOpenSearch] = useState(true);
  const [searchRole, setSearchRole] = useState('');
  // const [page, setPage] = useState(1);
  const currentPage = searchParamsHook.get('page')
  const searchTextParam = searchParamsHook.get('searchParam') ?? ""
  const [page, setPage] = React.useState(currentPage ?? 1);
  const [searchText, setSearchText] = useState(searchTextParam ?? "");
  const [totalCount, setTotalCount] = React.useState("");
  const rowsPerPage = 20;


  const searchParams = SearchParamHook()


  const toggle = (purpose = '', name = '', active = '', id = '', index) => {
    setPurpose(purpose);
    setName(name);
    setIndex(index);
    setId(id);
    setActive(active);
    setIsOpen(!isOpen);
    setViewAddModal(!viewAddModal);
  };

  const handleDelete = (index, id, name) => {
    deleteIndustries(id, name);
    let result = [...data];
    result[index].is_active = !result[index].is_active;
    // result[index].status = !result[index].status;
    setData(result);
    toast.error('Disabled');
  };

  const handleRestore = (item, index) => {
    const sample = {
      // name: item.name,
      name:item.industries,
      is_active: !item.is_active,
     
    };
    updateIndustries(sample, item.id);
    let result = [...data];

    result[index].is_active = !result[index].is_active;
    // result[index].status = !result[index].status;
    setData(result);
    toast.success('Enabled');
  };




  const fetchData = async () => {
    setIsLoading(true);
    const currentPage = searchParamsHook.get("page") ?? page
    const data = await getAllIndustriesList(currentPage, rowsPerPage,searchTextParam);

    const result =
    data?.data_payload?.length > 0 &&
    data?.data_payload?.map((item, index) => ({
      id: item?.id,
    
      industries: `${
        item?.name
      }`,

    
      is_active: item.is_active,
    }));


    setData(result)
    // setData(data?.data_payload);
    setTotalCount(data?.total_page_size)
    setIsLoading(false);
  };

  useEffect(() => {

    fetchData();
  }, []);


  // const handleAction = (item, index) => {
  //   item.is_active ? handleDelete(index, item.id, item.name) : handleRestore(item, index);
  // };

  const handleAction = (item, index) => {
    item.is_active ? handleDelete(index, item.id, item.industries) : handleRestore(item, index);
  };

  const updateFunc = (sample, type, index, id) => {
    let formatData = {
      industries:sample.name,
      is_active:sample.is_active,
      id:sample.id
    }
    let editedId = id ? id : id;
    if (type === 'Create') {
      createIndustries(sample);
      setData([...data, sample]);
      fetchData();
    } else {
      updateIndustries(sample, editedId);
      let result = [...data];
      result[index] = formatData;
      setData(result);
      fetchData();
    }
  };

 
  const handleSearchOpen = () => {
    setOpenSearch(!openSearch);
  };

  const handleSearch = (e) => {
    setSearchRole(e.target.value);
  };

  const searchFilterData = (data) => {
    const searchWord = searchRole.toLowerCase();
    const searchPattern = data.filter((item) => {
      const roleName = item.name.toLowerCase();
      return roleName.includes(searchWord);
    });
    return searchPattern;
  };

  const getAllIndustriesList = async (page, rowsPerPage,searchTextParam) => {
    const data = await getAllIndustries(page, rowsPerPage,searchTextParam);
    return data;
  };

  /* For pagination purpose in progress */

  // useEffect(()=>{

  //   const searchParams = new URLSearchParams(search);
  //   searchParams.set("searchParam", searchText);
  //   history.push({
  //     search: searchParams.toString(),
  //   });

  //   const fetchData = async () => {
  //     setIsLoading(true);
  //     const currentPage = searchParamsHook.get("page") ?? page
  //     const data = await getAllIndustriesList(currentPage, rowsPerPage,searchTextParam);
  //     setData(data?.data_payload);
  //     setTotalCount(data?.total_page_size)
  //     setIsLoading(false);
  //   };

  //   fetchData();
  // },[searchParams.get("page"), searchParamsHook.get("searchParam")])


  const debouncedQuery = useDebounce(searchText, 500);


  useEffect(() => {
    if(debouncedQuery){
      const searchParams = new URLSearchParams(search);
      searchParams.set("searchParam", debouncedQuery);
      searchParams.set("page",1)
      history.push({
        search: searchParams.toString(),
      });
    }
    else {
      const searchParams = new URLSearchParams(search);
      searchParams.set("searchParam", debouncedQuery);
      // setPage(1)
      history.push({
        search: searchParams.toString(),
      });
    }
 

  }, [debouncedQuery]);

  /* seacrh */

  /* For pagination purpose in progress */

  const handleRecallIndustries = () => {
    // Recall industries logic
  };


  


  /*Columns Creation@@ */


  React.useEffect(() => {
    const searchParams = new URLSearchParams(search);
    searchParams.set("page", page);
    history.push({
      search: searchParams.toString(),
    });
  
    fetchData();
  }, [page, searchParamsHook.get("searchParam")]);

  const columns = [
  
    { field: "industries", headerName: "Industry",  headerClassName: "super-app-theme--header", width: 300 },
    {
      field: "is_active",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: (params) => {
        const rowIndex = data.findIndex(row => row.id === params.row.id);

        return(
          <PrimarySwitch
          sx={{ m: 1 }}
          checked={params.row.is_active}
          onChange={async (e) => {
            handleAction(params.row, rowIndex);
          }}
        />
        )
      
      },
    },
 
    {
      field: "Action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      width: 600,
    
      renderCell: params => {
      
        const index = data.findIndex(row => row.id === params.row.id);
        return (
          <PrimaryButton
          sx={{ width: '100px' }}
          onClick={() => toggle('Edit', params.row.industries, params.row.is_active, params.row.id, index)}
        >
          Edit
        </PrimaryButton>
        );
      },
    },



    
  ];


  const filteredColumns = columns.filter(Boolean);




  // return (
  //   <>
  //     <CommonModel
  //       isOpen={isOpen}
  //       toggle={toggle}
  //       headTag={'Industries'}
  //       purpose={purpose}
  //       index={index}
  //       id={id}
  //       title={name}
  //       active={active}
  //       update={updateFunc}
  //     />
  //     <div md={6} style={{ padding: '40px' }} className="header_jobs_sticky">
  //       <PrimaryButton sx={{ width: '200px' }} onClick={() => toggle('Create')}>
  //         Create Industries
  //       </PrimaryButton>
  //       <Stack alignItems={'center'} direction={'row'} spacing={1.6}>
  //         {openSearch ? (
  //           <SearchIcon onClick={handleSearchOpen} />
  //         ) : (
  //           <StageSearchInput
  //             placeholder="search..."
  //             value={searchText}
  //             onChange={(e) => {
  //               e.stopPropagation();
  //               setSearchText(e.target.value);
  //             }}
  //             endAdornment={
  //               <IconButton edge="end"
  //               onClick={() => {
  //                 handleSearchOpen();
  //                 setSearchText("");
  //               }}
  //                >
  //                 <ClearIcon />
  //               </IconButton>
  //             }
  //           />
  //         )}
  //       </Stack>
  //     </div>
  //     <Row style={{ marginLeft: '20px',marginTop:"10px"}}>
  //       <div className="table_cont">
  //         <div className="table-responsive">
  //           <Card style={{ maxWidth: '80vw' }}>
  //             <CardBody
  //             style={{ height: 'calc(100vh - 270px)', overflowY: 'scroll' }}
  //              >
  //               <Table className="table mb-0">
  //                 <TableHead>
  //                   <TableRow>
  //                     <TableCell>Industries</TableCell>
  //                     <TableCell>Status</TableCell>
  //                     <TableCell>Action</TableCell>
  //                   </TableRow>
  //                 </TableHead>
  //                 <tbody>
  //                   {isLoading ? (
  //                     <BubbleLoader />
  //                   ) : (
  //                     data?.length > 0?     
  //                  data?.map((item, index) => (
  //                       <TableRow key={item.name + index}>
  //                         <TableCell>{item.name}</TableCell>

  //                         <TableCell className="active_td">
  //                           <FormGroup>
  //                             <FormControlLabel
  //                               control={
  //                                 <PrimarySwitch
  //                                   sx={{ m: 1 }}
  //                                   checked={item.is_active}
  //                                   onChange={async (e) => {
  //                                     handleAction(item, index);
  //                                   }}
  //                                 />
  //                               }
  //                             />
  //                           </FormGroup>
  //                         </TableCell>
  //                         <TableCell className="col_btn">
  //                           <PrimaryButton
  //                             sx={{ width: '100px' }}
  //                             onClick={() => toggle('Edit', item.name, item.active, item.id, index)}
  //                           >
  //                             Edit
  //                           </PrimaryButton>
  //                         </TableCell>
  //                       </TableRow>
  //                     ))
                      
  //                     :
                      
  //                     (<TableRow>
  //                     <TableCell colSpan={5} align="center">
  //                       <CustomNoRowsOverlay />
  //                     </TableCell>
  //                   </TableRow>)       
  //                   )}
  //                 </tbody>
  //               </Table>
  //             </CardBody>
  //           </Card>
  //         </div>
  //       </div>
  //       <CustomPagination count={totalCount} page={page} setPage={setPage} />
  //     </Row>
  //   </>
  // )


  return (


  <>     <CommonModel
        // isOpen={isOpen}
        toggle={toggle}
        isOpen={viewAddModal}
        viewAddModal={viewAddModal}
        setViewAddModal={setViewAddModal}
        headTag={'Industries'}
        purpose={purpose}
        index={index}
        id={id}
        title={name}
        active={active}
        update={updateFunc}
      />


    <div
    style={{
      padding: "10px",
      height: "91vh",
      display: "flex",
      flexDirection: "column",
    }}
  >
    
      <GlobalTableTools  tableHeading={"Industries"}
       toggel={true}
       toggleFunc={toggle}
       isJobAttributes={true}
       setViewAddModal={setViewAddModal} 
       downloadOption={false}
        />
    <PrimaryDataGrid
     
      fileName="Industries"
      // onCellClick={handleCellClick}
      loading={isLoading}
     

      columns={filteredColumns}
      rows={data}
      slots={{
        loadIcon: LoaderIcon,
        baseButton: PrimaryButton,
        basePopper: TablePopper,
        baseSwitch: PrimarySwitch,
    
        // toolbar:CustomToolbar,
        noRowsOverlay: CustomNoRowsOverlay,
        pagination: props => (
          <Pagination
            {...props}
            // color="primary"
            // count={Math.ceil(rowsData.length / rowsPerPage)}
            count={totalCount}
            page={page}
            rowsPerPage={rowsPerPage}
            onChange={(_, newPage) => setPage(newPage)}
            showFirstButton
            showLastButton
            sx={{
              "& .MuiPaginationItem-root": {
                "&:hover": {
                  backgroundColor: "#cde2e7",
                  color: "#066a4c",
                },
                "&.Mui-selected": {
                  backgroundColor: "#024430",
                  color: "#ffe7bb",
                  "&:hover": {
                    backgroundColor: "#cde2e7",
                    color: "#066a4c",
                  },
                },
              },
            }}
          />
        ),
      }}
      slotProps={{
        baseButton: { style: { width: "100px" } },
        panel: {
          style: {
            right: "0px !important",
            transform: "none !important",
            inset: "none !important",
            top: "138px !important",
          },
        },
        filterPanel: {
          style: {
            right: "0px !important",
            transform: "none !important",
            inset: "none !important",
            top: "138px !important",
          },
        },
        toolbar: { style: {} },
        preferencesPanel: {
          style: {
            right: "0px !important",
            transform: "none !important",
            inset: "none !important",
            top: "138px !important",
          },
        },
      }}
      sx={{
        "& .super-app-theme--header": {
          backgroundColor: "rgba(250, 250, 250, 1)",
        },
      }}
    />
   
  </div>


  </>

  )




}

export default Industries;