// const { Row, Container } = require("reactstrap");
// import { getAllEducationalQualifications } from "helpers/services/jobAttributes/educationalQualifications";
// import React from "react";
// import "../../assets/css/jobattr.css";
// import Qualifications from "./educations.js/qualifications";
// import Specialization from "./educations.js/specialization";
// import { Tabs } from "@mui/material";
// import { SolidTab, StyledLoginTabsObject } from "assets/Mui/globalTheme";

// class DegreeLevels extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isOpen: false,
//       active: false,
//       index: -1,
//       default: false,
//       id:"",
//       name: "",
//       navSelected: "0",
//       data: [],
//     };
//     this.toggle = this.toggle.bind(this);
//     this.handleDelete = this.handleDelete.bind(this);
//     this.updateFunc = this.updateFunc.bind(this);
//     this.handleNavBar = this.handleNavBar.bind(this);
//     this.handleAction = this.handleAction.bind(this);
//     this.handlRestore = this.handlRestore.bind(this);
//   }
//   async componentDidMount() {
//     const data = await getAllEducationalQualifications();
//     this.setState({ data });
//   }

//   toggle(purpose = "", name = "", active = "", id = "", index) {
//     this.setState({
//       purpose: purpose,
//       name: name,
//       index,
//       id,
//       active: active,
//       isOpen: !this.state.isOpen,
//     });
//   }
//   handleDelete(index, id) {
//     let result = this.state.data;
//     result[index].is_active = !result[index].is_active;
//     this.setState({
//       data: result,
//     });
//   }
//   handlRestore(item, index) {
//     const sample = {
//       name: item.name,
//       is_active: !item.is_active,
//     };
//     let result = this.state.data;
//     result[index].is_active = !result[index].is_active;
//     this.setState({
//       data: result,
//     });
//   }
//   handleAction(item, index) {
//     item.is_active
//       ? this.handleDelete(index, item.id)
//       : this.handlRestore(item, index);
//   }
//   updateFunc(sample, type, index) {
//     if (type === "Create") {
//       this.setState({
//         data: [...this.state?.data, sample],
//       });
//     } else {
//       let result = this.state.data;
//       result[index] = sample;
//       this.setState({
//         data: result,
//       });
//     }
//   }

//   handleNavBar(e) {
//     this.setState({
//       navSelected: e,
//     });
//   }
//   NavSelection(data) {
//     switch (data) {
//       case "0":
//         return <Qualifications handleNavBar={this.handleNavBar} />;
//       case "1":
//         return (
//           <Specialization
//             handleNavBar={this.handleNavBar}
//             navBar={this.state.navSelected}
//           />
//         );
//     }
//   }
//   render() {
//     const navBar = [
//       {
//         name: "Educational Qualifications",
//       },
//       {
//         name: "Specializations",
//       },
//     ];

//     return (
//       <div className="page-content">
//         <Container fluid>
//           <Row>
//             <div style={{ width: "500px", maxWidth: "500px" }}>
//               <Tabs
//                 sx={{
//                   height: "20px",
//                   width: "500px",
//                   borderRadius: "8px",
//                   border: "0.5px solid rgba(0,0,0,0.5)",
//                 }}
//                 value={this.state.navSelected}
//                 TabIndicatorProps={StyledLoginTabsObject}
//                 onChange={(_, index) => this.handleNavBar(index.toString())}
//                 centered
//               >
//                 <SolidTab
//                   sx={{ width: "50% !important" }}
//                   value="0"
//                   label="Educational Qualifications"
//                 />
//                 <SolidTab
//                   sx={{ width: "50% !important" }}
//                   value="1"
//                   label="Speicializations"
//                 />
//               </Tabs>
//             </div>
//             {this.NavSelection(this.state.navSelected)}
//           </Row>
//         </Container>
//       </div>
//     );
//   }
// }

// export default DegreeLevels;


import React, { useState, useEffect } from 'react';
import { Row, Container } from 'reactstrap';
import { getAllEducationalQualifications } from 'helpers/services/jobAttributes/educationalQualifications';
import '../../assets/css/jobattr.css';
import Qualifications from './educations.js/qualifications';
import Specialization from './educations.js/specialization';
import { Tabs } from '@mui/material';
import { SolidTab, StyledLoginTabsObject,Solid2Tab } from 'assets/Mui/globalTheme';
import { useParams,useHistory } from "react-router-dom";
import SearchParamHook from "hooks/searchParams";
import { useLocation } from "react-router-dom";
import { useDebounce } from "hooks/useDebounce";
import CustomNoRowsOverlay from "common/data/NoRowsFoundDatagrid/NoRowsFound";
import CustomPagination from 'components/Pagination/globalPagination';


const DegreeLevels = () => {
 
  const searchParamsHook = SearchParamHook();
  const { search } = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  const searchTextParam = searchParamsHook.get('searchParam')
  const currentPage = searchParamsHook.get('page')
  const [page, setPage] = React.useState(currentPage ?? 1);
  const [searchText, setSearchText] = useState(searchTextParam ?? "");
  
  const [totalCount, setTotalCount] = React.useState("");
  const rowsPerPage = 20;
  const searchParams = SearchParamHook()


  const [isOpen, setIsOpen] = useState(false);
  const [purpose, setPurpose] = useState('');
  const [name, setName] = useState('');
  const [id, setId] = useState('');
  const [active, setActive] = useState(false);
  const [index, setIndex] = useState(-1);
  const [data, setData] = useState([]);
  const [navSelected, setNavSelected] = useState('0');

  useEffect(() => {
    const fetchData = async () => {
      const result = await getAllEducationalQualifications(page, rowsPerPage,searchText);
      setData(result?.data_payload);
      setTotalCount(data?.total_page_size)
    };

    fetchData();
  }, []);



  const toggle = (purpose = '', name = '', active = '', id = '', index) => {
    setPurpose(purpose);
    setName(name);
    setId(id);
    setActive(active);
    setIndex(index);
    setIsOpen(!isOpen);
  };

  const handleDelete = (index, id) => {
    let result = [...data];
    result[index].is_active = !result[index].is_active;
    setData(result);
  };

  const handleRestore = (item, index) => {
    const sample = {
      name: item.name,
      is_active: !item.is_active,
    };
    let result = [...data];
    result[index].is_active = !result[index].is_active;
    setData(result);
  };

  const handleAction = (item, index) => {
    item.is_active ? handleDelete(index, item.id) : handleRestore(item, index);
  };

  const updateFunc = (sample, type, index) => {
    if (type === 'Create') {
      setData([...data, sample]);
    } else {
      let result = [...data];
      result[index] = sample;
      setData(result);
    }
  };

  const handleNavBar = (e) => {
    setNavSelected(e);
  };

  const navSelection = (data) => {
    switch (data) {
      case '0':
        return <Qualifications handleNavBar={handleNavBar} />;
      case '1':
        return <Specialization handleNavBar={handleNavBar} navBar={navSelected} />;
      default:
        return null;
    }
  };

  const navBarItems = [
    {
      name: 'Educational Qualifications',
    },
    {
      name: 'Specializations',
    },
  ];

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <div style={{ width: '500px', maxWidth: '500px' }}>
            <Tabs
              sx={{
                height: '20px',
                width: '500px',
                borderRadius: '8px',
                border: '0.5px solid rgba(0,0,0,0.5)',
              }}
              value={navSelected}
              TabIndicatorProps={StyledLoginTabsObject}
              onChange={(_, index) => handleNavBar(index.toString())}
              centered
            >
              {navBarItems.map((item, index) => (
                <Solid2Tab
                  key={index}
                  sx={{ width: '50% !important' }}
                  value={index.toString()}
                  label={item.name}
                />
              ))}
            </Tabs>
          </div>
          {navSelection(navSelected)}
        </Row>
    
      </Container>
    </div>
  );
};

export default DegreeLevels;

