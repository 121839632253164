import { Tabs } from "@mui/material";
import {
  PrimaryTab,
  StyledTabScrollButtonObject,
  StyledTabsObject,
} from "assets/Mui/globalTheme";
import { useState } from "react";

export default function CompanyViewTabs({ selectedNav, setSelectedNav }) {
  const nav = ["Overview", "jobs",];
  function handleNavChange(id) {
    setSelectedNav(id.toString());
  }
  return (
    <Tabs
      sx={{ borderBottom: "0.5px solid rgba(0,0,0,0.1)" }}
      value={selectedNav}
      onChange={(_, id) => handleNavChange(id)}
      textColor="primary"
      TabIndicatorProps={StyledTabsObject}
      TabScrollButtonProps={StyledTabScrollButtonObject}
      indicatorColor="primary"
      aria-label="secondary tabs example"
    >
      {nav.map((item, index) => {
        return (
          <PrimaryTab
            disableRipple={true}
            sx={{ fontWeight: "500", height: "10px" }}
            value={index?.toString()}
            iconPosition="start"
            key={index}
            label={item}
          />
        );
      })}
    </Tabs>
  );
}
