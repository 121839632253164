import { FormControl, MenuItem, Paper, Select, Stack } from "@mui/material";
import {
  PrimaryButton,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  SecondaryFilledButton,
} from "assets/Mui/globalTheme";
import GoogleLocation from "components/Common/Forms/location";
import ErrorHandlingToaster from "helpers/Toaster/error";
import { getAllFunctionalArea } from "helpers/services/jobAttributes/functionalArea";
import { getAllIndustries } from "helpers/services/jobAttributes/industries";
import { getAllJobLevlsWithoutFunctionalArea } from "helpers/services/jobAttributes/jobLevels";
import { updateScreeningPartner } from "helpers/services/screeningPartner/screeningPartner";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";

export default function ScreeningFollowUps() {
  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      industry: [],
      functional_area: [],
      job_level: [],
    },
  });
  const [jobLevel, setJobLevel] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [functionalAreas, setFunctionalAreas] = useState([]);

  useEffect(() => {
    initialFetch();
    getFunctionalAreas();
    getJobLevels();
  }, []);
  async function getJobLevels() {
    const data = await getAllJobLevlsWithoutFunctionalArea(1, 20, "");
    setJobLevel(data?.data?.data_payload?.map(item => ({ name: item.name, value: item.id })));
  }
  async function initialFetch() {
    const data = await getAllIndustries(1, 30, "");
    setIndustries(data?.data_payload?.filter(item => item.is_active)?.map(item => ({ name: item.name, value: item.id })));
  }
  async function getFunctionalAreas() {
    const data = await getAllFunctionalArea(1, 20, "");
    setFunctionalAreas(
      data?.data?.data_payload?.map(item => ({ name: item.name, value: item.id }))
    );
  }
  function handleIndustryChange(e) {
    alert(e.target.value);
    setValue(
      "industry",
      typeof e.target.value === "string" ? value?.split(",") : value
    );
  }
  function handlefunctionalAreaChange(e) {
    setValue(
      "functional_area",
      typeof e.target.value === "string" ? value?.split(",") : value
    );
  }
  function handleJobTypeChange(e) {
    setValue(
      "job_type",
      typeof e.target.value === "string" ? value?.split(",") : value
    );
  }
  const history = useHistory();
  const sizes = [
    { name: "1-50", value: "1-50" },
    { name: "51-100", value: "51-100" },
    { name: "101-150", value: "101-150" },
  ];
  const work_mode = [
    { name: "Full Time", value: "Full_Time" },
    { name: "Part Time", value: "Part_Time" },
    // { name: "Contract", value: "Contract" },
    { name: "Freelance", value: "Freelance" },
  ];

  const gender = [
    { name: "Male", value: "male" },
    { name: "Female", value: "female" },
    { name: "prefer Not To say", value: "prefer_not_to_say" },
  ];
 
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        borderRadius: "10px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
      },
    },
  };
  function handleBack() {
    localStorage.removeItem("authUser");
    localStorage.removeItem("email");
    localStorage.removeItem("first_name");
    history.push("/login");
  }
  const location = useLocation();
  useMemo(() => {
    if (location?.state?.from !== "/signup") {
      history.push("/login");
    }
  }, [location]);
  async function handleScreenignUpdate(data) {
    // data.job_role = data.job_level;
    data.industry = [data.industry]
    data.functional_area = [data.functional_area]
    delete data?.job_level;
    
    const result = await updateScreeningPartner({
      ...data,
      is_active: true,
      email: localStorage.getItem("email"),
      first_name: localStorage.getItem("first_name"),
      last_name: localStorage.getItem("last_name"),
    });
    ErrorHandlingToaster(result);
    if (result?.status === 200) {
      localStorage.removeItem("email");
      localStorage.removeItem("first_name");
      localStorage.removeItem("last_name");
      localStorage.removeItem("authUser");
      history.push("/login");
    }
  }
  return (
    <Paper sx={{ padding: "40px 50px", height: "100vh" }}>
      <FormProvider
        register={register}
        watch={watch}
        getValue={getValues}
        setValue={setValue}
        handleSubmit={handleSubmit}
        formState={{ errors: errors }}
      >
        <form onSubmit={handleSubmit(handleScreenignUpdate)}>
          <Stack spacing={2}>
            <Stack direction="row" spacing={4} justifyContent={"space-between"}>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <PrimaryNormalInputLabel
                  shrink
                  htmlFor="folloups-functional-area-outlined"
                  error={errors.functional_area}
                >
                  Categories
                </PrimaryNormalInputLabel>
                <Select
                  // multiple
                  displayEmpty
                  onChange={handlefunctionalAreaChange}
                  labelId="folloups-functional-area-outlined"
                  id="folloups-functional-area-outlined"
                  value={watch("functional_area")}
                  {...register("functional_area", { required: true })}
                  input={
                    <PrimaryNormalInputs
                      label="folloups-functional-area-outlined"
                      error={errors?.functional_area}
                      {...register("functional_area", { required: true })}
                    />
                  }
                  MenuProps={MenuProps}
                >
                  {functionalAreas?.map(item => {
                    return (
                      <MenuItem
                        key={item?.value}
                        value={item?.value} // style={getStyles(name, personName, theme)}
                      >
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <PrimaryNormalFormHelperText error={errors.functional_area}>
                  {errors?.functional_area
                    ? "categories is a required field"
                    : ""}
                </PrimaryNormalFormHelperText>
              </FormControl>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <Stack spacing={2}>
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="folloups-location-outlined"
                  >
                    Location
                  </PrimaryNormalInputLabel>
                  <GoogleLocation updatingContent={"location"} />
                </Stack>
              </FormControl>

              <FormControl sx={{ width: "100%" }} variant="standard">
                <PrimaryNormalInputLabel
                  shrink
                  htmlFor="folloups-industry-outlined"
                >
                  industry
                </PrimaryNormalInputLabel>
                <Select
                  // multiple
                  displayEmpty
                  labelId="folloups-industry-outlined"
                  id="folloups-industry-outlined"
                  value={watch("industry")}
                  onChange={handleIndustryChange}
                  {...register("industry", { required: true })}
                  input={
                    <PrimaryNormalInputs
                      label="folloups-industry-outlined"
                      error={errors?.industry}
                      {...register("industry", { required: true })}
                    />
                  }
                  MenuProps={MenuProps}
                >
                  {industries?.map(item => {
                    return (
                      <MenuItem
                        key={item?.value}
                        value={item?.value} // style={getStyles(name, personName, theme)}
                      >
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <PrimaryNormalFormHelperText error={errors.industry}>
                  {errors?.industry
                    ? "industry is a required field"
                    : ""}
                </PrimaryNormalFormHelperText>
              </FormControl>
            </Stack>
            <FormControl sx={{ width: "100%" }} variant="standard">
              <PrimaryNormalInputLabel
                shrink
                htmlFor="folloups-about-outlined"
                error={errors.about}
              >
                About
              </PrimaryNormalInputLabel>
              <PrimaryNormalInputs
                fullWidth
                multiline
                placeholder="Enter about yourself"
                {...register("about", { required: true })}
                id="login-about-outlined"
                autoFocus
                label="about address"
                type={"text"}
                error={errors.about}
                variant="outlined"
                rows={4}
              />
              <PrimaryNormalFormHelperText error={errors.about}>
                {errors?.about
                  ? "about is a required field"
                  : ""}
              </PrimaryNormalFormHelperText>
            </FormControl>
            <Stack direction="row" spacing={4} justifyContent={"space-between"}>
              {/* <FormControl sx={{ width: "100%" }} variant="standard">
                <PrimaryNormalInputLabel
                  shrink
                  htmlFor="folloups-job-level-outlined"
                  error={errors.job_level}
                >
                  Job Type
                </PrimaryNormalInputLabel>
                <Select
                  multiple
                  onChange={handleJobTypeChange}
                  labelId="folloups-job-level-outlined"
                  id="folloups-job-level-outlined"
                  value={watch("job_level")}
                  {...register("job_level", { required: false })}
                  input={
                    <PrimaryNormalInputs
                      label="folloups-job-level-outlined"
                      error={errors?.job_level}
                      {...register("job_level", { required: false })}
                    />
                  }
                  MenuProps={MenuProps}
                >
                  {jobLevel?.map(item => {
                    return (
                      <MenuItem
                        key={item?.value}
                        value={item?.value} // style={getStyles(name, personName, theme)}
                      >
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <PrimaryNormalFormHelperText error={errors.job_type}>
                  {errors?.job_type
                    ? "job Type is a required field"
                    : "select your Job Type."}
                </PrimaryNormalFormHelperText>
              </FormControl> */}
              {/* <FormControl sx={{ width: "100%" }} variant="standard">
                <PrimaryNormalInputLabel
                  shrink
                  htmlFor="folloups-work_mode-outlined"
                  error={errors.work_mode}
                >
                  work_mode
                </PrimaryNormalInputLabel>
                <Select
                  labelId="folloups-work_mode-outlined"
                  id="folloups-work_mode-outlined"
                  value={getValues("work_mode")}
                  {...register("work_mode", { required: false })}
                  input={
                    <PrimaryNormalInputs
                      label="folloups-work_mode-outlined"
                      error={errors?.work_mode}
                      {...register("work_mode", { required: false })}
                    />
                  }
                  MenuProps={MenuProps}
                >
                  {work_mode?.map(item => {
                    return (
                      <MenuItem
                        key={item?.value}
                        value={item?.value} // style={getStyles(name, personName, theme)}
                      >
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <PrimaryNormalFormHelperText error={errors.work_mode}>
                  {errors?.work_mode
                    ? "work mode is a required field"
                    : "Select your work mode."}
                </PrimaryNormalFormHelperText>
              </FormControl> */}
              <FormControl sx={{ width: "100%" }} variant="standard">
                <PrimaryNormalInputLabel
                  shrink
                  htmlFor="folloups-gender-outlined"
                >
                  gender
                </PrimaryNormalInputLabel>
                <Select
                  labelId="folloups-gender-outlined"
                  id="folloups-gender-outlined"
                  {...register("gender", { required: true })}
                  value={watch("gender")}
                  displayEmpty
                  input={
                    <PrimaryNormalInputs
                      label="folloups-gender-outlined"
                      error={errors?.gender}
                      {...register("gender", { required: true })}
                    />
                  }
                  MenuProps={MenuProps}
                >
                  {gender?.map(item => {
                    return (
                      <MenuItem
                        key={item?.value}
                        value={item?.value} // style={getStyles(name, personName, theme)}
                      >
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <PrimaryNormalFormHelperText error={errors.gender}>
                  {errors?.gender
                    ? "gender is a required field"
                    : ""}
                </PrimaryNormalFormHelperText>
              </FormControl>
            </Stack>
          </Stack>
          <Stack
            sx={{ position: "absolute", bottom: "5px", right: "80px" }}
            direction="row"
            alignItems={"center"}
            justifyContent={"flex-end"}
            spacing={2}
          >
            {/* <SecondaryFilledButton
              sx={{ width: "100px", color: "#D60D0D" }}
              type={"button"}
              onClick={handleBack}
            >
              back
            </SecondaryFilledButton> */}
            <PrimaryButton sx={{ width: "100px" }} type="submit">
              Submit
            </PrimaryButton>
          </Stack>
        </form>
      </FormProvider>
    </Paper>
  );
}
