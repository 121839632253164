import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  BlackTypography,
  PrimaryTypography,
  GrayDivider,PrimaryChip,
  CompanySizeChip,
  SkillsChip
} from "assets/Mui/globalTheme";
import { useForm } from "react-hook-form";
import { FormControl, Grid, Stack } from "@mui/material";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function SkillViewModal({
  rowdata,
  closeModalFunc,
  openModal,
  headerName,
  dynamicStyles,
  chipColor
}) {
  
  return (
    <div>
      <Modal
        open={openModal}
        onClose={() => {
            closeModalFunc();
        //   reset();
        }}
        aria-labelledby="edit-profile-modal-certificate"
        aria-describedby="edit-profile-modal-description"
      >
        <Box sx={style}>
          <PrimaryTypography variant="h2" sx={{ marginBottom: "10px" }}>
            {headerName.replaceAll("_"," ")}
          </PrimaryTypography>
          <GrayDivider />
          {/* {rowdata?.map((each,i)=><CompanySizeChip label={each} sx={{marginTop:"15px",marginRight:"5px"}} key={i}></CompanySizeChip>)} */}
          {rowdata?.map((each,i)=>{
            if(dynamicStyles){
              return (
                <SkillsChip label={each} sx={{marginTop:"15px",marginRight:"5px"}} key={i}></SkillsChip>
              )
            }else if(chipColor){
              return (
                <PrimaryChip label={each} sx={{marginTop:"15px",marginRight:"5px"}} key={i}></PrimaryChip>
              )
            }
            else {
              return (
                <CompanySizeChip label={each} sx={{marginTop:"15px",marginRight:"5px"}} key={i}></CompanySizeChip>
              )
            }
          })}

        </Box>
      </Modal>
    </div>
  );
}
