import React, { useEffect, useState } from "react";
import { Grid, Stack, Box, InputAdornment } from "@mui/material";
import {
  DownloadButton,
  CompanyTypography,
  StyledOutlinedInput,
  PrimaryButton,
} from "assets/Mui/globalTheme";
import JobSearchIcon from "assets/images/icons/JobSearchIcon";
import { useDebounce } from "hooks/useDebounce";
import { useLocation, useHistory } from "react-router-dom";
import SearchParamHook from "hooks/searchParams";
import DownloadIcon from "@mui/icons-material/Download";
import { CA, CS, ZA } from "common/constants/userModules";
import AddIcon from "@mui/icons-material/Add";

const GlobalTableTools = ({
  id,
  placeholder,
  name,
  sx,
  tableHeading,
  handleDownload,
  toggel,
  setViewAddModal,
  toggleFunc,
  isJobAttributes,
  downloadOption,
}) => {
  const { search } = useLocation();
  const history = useHistory();

  const searchParamsHook = SearchParamHook();

  const [queryString, setQueryString] = useState(
    searchParamsHook.get("searchParam") ?? ""
  );

  const debouncedQuery = useDebounce(queryString, 500);
  const loginStore = JSON.parse(localStorage.getItem("authUser"));
  const role = loginStore?.role;

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    searchParams.set("searchParam", queryString);
    history.push({
      search: searchParams.toString(),
    });
  }, [debouncedQuery]);

  return (
    <Grid
      container
      sx={[
        {
          marginBottom: "10px",
        },
        sx,
      ]}
    >
      <Grid item sm={5} xs={12}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          sx={{
            height: "100%",
          }}
        >
          <CompanyTypography variant="h1" component="h1">
            {tableHeading ? tableHeading : "Table"}
          </CompanyTypography>
        </Stack>
      </Grid>
      <Grid item sm={7} xs={12}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <Box>
            <StyledOutlinedInput
              value={queryString}
              id={id || "sm-search"}
              name={name || "sm-name"}
              placeholder={placeholder || "Search.."}
              onChange={e => {
                e.preventDefault();
                e.stopPropagation();
                setQueryString(e.target.value);
              }}
              startAdornment={
                <InputAdornment position="start">
                  <JobSearchIcon />
                </InputAdornment>
              }
            />
          </Box>

          {toggel && (
            <Box>
              {["ZA"].includes(loginStore.role) && (
                <PrimaryButton
                  startIcon={<AddIcon />}
                  sx={{
                    width: "max-content !important",
                    ml: "auto !important",
                  }}
                  onClick={() => {
                    setViewAddModal(true);
                    if (isJobAttributes) {
                      toggleFunc("Create");
                    }
                  }}
                >
                  Add New
                </PrimaryButton>
              )}
            </Box>
          )}

          {isJobAttributes ? (
            ""
          ) : (
            <Box>
              <DownloadButton
                size="medium"
                startIcon={<DownloadIcon />}
                onClick={handleDownload && handleDownload}
                sx={{ color: "#ffffff" }}
              >
                Download
              </DownloadButton>
            </Box>
          )}

        </Stack>
      </Grid>
    </Grid>
  );
};

export default GlobalTableTools;
