import React, { useState, useEffect } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  FormGroup,
  FormControlLabel,
  Box,
  CircularProgress,
  Stack,
  IconButton,
  Pagination
} from '@mui/material';
const { Row, Container, Col } = require("reactstrap");
import { toast } from 'react-hot-toast';
import BubbleLoader from 'common/data/NoRowsFoundDatagrid/BubbleLoader';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import {
  PrimaryButton,
  PrimarySwitch,
  StageSearchInput,
  PrimaryDataGrid
} from 'assets/Mui/globalTheme';
import CommonModel from '../../components/Common/models/createModel';
import {
  createKeySkills,
  deleteKeySkill,
  getAllKeySkills,
  updateKeySkills,
} from 'helpers/services/jobAttributes/keyskills';
import CustomPagination from 'components/Pagination/globalPagination';
import { useParams,useHistory } from "react-router-dom";
import SearchParamHook from "hooks/searchParams";
import { useLocation } from "react-router-dom";
import { useDebounce } from "hooks/useDebounce";
import CustomNoRowsOverlay from "common/data/NoRowsFoundDatagrid/NoRowsFound";
import LoaderIcon from "utils/icons/loaderIcon";
import TablePopper from "components/table/header/popper";
import GlobalTableTools from "hooks/GlobalTableTools";

const KeySkills = () => {

  const [viewAddModal, setViewAddModal] = React.useState(false);
  const searchParamsHook = SearchParamHook();
  const { search } = useLocation();
  const history = useHistory();
  const searchTextParam = searchParamsHook.get('searchParam')
  const currentPage = searchParamsHook.get('page')
  const [page, setPage] = React.useState(currentPage ?? 1);
  const [searchText, setSearchText] = useState(searchTextParam ?? "");
  const [totalCount, setTotalCount] = React.useState("");
  const rowsPerPage = 20;


  const [isOpen, setIsOpen] = useState(false);
  const [purpose, setPurpose] = useState('');
  const [name, setName] = useState('');
  const [id, setId] = useState('');
  const [active, setActive] = useState(false);
  const [index, setIndex] = useState(-1);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openSearch, setOpenSearch] = useState(true);
  const [searchRole, setSearchRole] = useState('');



  const toggle = (purpose = '', name = '', id = '', active = '', index) => {
    setPurpose(purpose);
    setName(name);
    setId(id);
    setActive(active);
    setIndex(index);
    setIsOpen(!isOpen);
    setViewAddModal(!viewAddModal);
  };

  const handleAction = async (item, index) => {
    await updateKeySkills({ name: item.industries, is_active: !item.is_active }, item.id);
    let result = [...data];
    result[index].is_active = !result[index].is_active;
    setData(result);

    if (result[index].is_active) {
      toast.success('Enabled');
    } else {
      toast.error('Disabled');
    }
  };

  const handleDelete = (index, id) => {
    deleteKeySkill(id);
    let result = [...data];
    result.splice(index, 1);
    setData(result);
    toast.error('Disabled');
  };

  const updateFunc = async (sample, type, index, id) => {
    let editedId = id ? id : id;
    if (type === 'Create') {
      await createKeySkills(sample);
      // setData([...data, sample]);
      fetchData()
    } else {
      await updateKeySkills(sample, editedId);
      let result = [...data];
      result[index] = sample;
      fetchData()
      // setData(result);
    }
  };

  const handleSearchOpen = () => {
    setOpenSearch(!openSearch);
    setSearchRole('');
  };

  const handleSearch = (e) => {
    setSearchRole(e.target.value);
  };

 

  const fetchData = async () => {
    setIsLoading(true);
    const currentPage = searchParamsHook.get("page") ?? page
    const items = await getAllKeySkills(currentPage, rowsPerPage,searchTextParam);

    const result =
    items?.data_payload?.length > 0 &&
    items?.data_payload?.map((item, index) => ({
      id: item?.id,
    
      industries: `${
        item?.name
      }`,

    
      is_active: item.is_active,
    }));

    // setData(items?.data_payload);
    setData(result)
    setTotalCount(items?.total_page_size)
    setIsLoading(false);
  };


  useEffect(() => {
 

    fetchData();
  }, []);





useEffect(()=>{

  const searchParams = new URLSearchParams(search);
  searchParams.set("searchParam", searchText); 
  history.push({
    search: searchParams.toString(),
  });

  // const fetchData = async () => {
  //   setIsLoading(true);
  //   const currentPage = searchParamsHook.get("page") ?? page
  //   const items = await getAllKeySkills(currentPage, rowsPerPage,searchText);
  //   setData(items?.data_payload);
  //     setTotalCount(items?.total_page_size)
  //     setIsLoading(false);
  // };

  fetchData();
},[searchParamsHook.get("page"), searchParamsHook.get("searchParam")])



const debouncedQuery = useDebounce(searchText, 500);

useEffect(() => {
  if(debouncedQuery){
    const searchParams = new URLSearchParams(search);
    searchParams.set("searchParam", debouncedQuery);
    searchParams.set("page",1)
    history.push({
      search: searchParams.toString(),
    });
  }
  else {
    const searchParams = new URLSearchParams(search);
    searchParams.set("searchParam", debouncedQuery);
    // setPage(1)
    history.push({
      search: searchParams.toString(),
    });
  }


}, [debouncedQuery]);




  /*Columns Creation@@ */


  React.useEffect(() => {
    const searchParams = new URLSearchParams(search);
    searchParams.set("page", page);
    history.push({
      search: searchParams.toString(),
    });
  
    fetchData();
  }, [page, searchParamsHook.get("searchParam")]);

  const columns = [
  
    { field: "industries", headerName: "Skills",  headerClassName: "super-app-theme--header", width: 300 },
    {
      field: "is_active",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: (params) => {
        const rowIndex = data.findIndex(row => row.id === params.row.id);

        return(
          <PrimarySwitch
          sx={{ m: 1 }}
          checked={params.row.is_active}
          onChange={async (e) => {
            handleAction(params.row, rowIndex);
          }}
        />
        )
      
      },
    },
 
   

    {
      field: "Action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      width: 600,
    
      renderCell: params => {
      
        const index = data.findIndex(row => row.id === params.row.id);
        return (
          <PrimaryButton
          sx={{ width: '100px' }}
          onClick={() => toggle('Edit', params.row.industries, params.row.id, params.row.is_active, index)}
        >
          Edit
        </PrimaryButton>
        );
      },
    },



    
  ];


  const filteredColumns = columns.filter(Boolean);




  // return (
  //   <Container fluid>
  //     <CommonModel
  //       isOpen={isOpen}
  //       toggle={toggle}
  //       headTag={'Key Skills'}
  //       purpose={purpose}
  //       index={index}
  //       title={name}
  //       active={active}
  //       update={updateFunc}
  //       id={id}
  //     />
  //     <div className="header_jobs_sticky" md={6} style={{ padding: '40px' }}>
  //       <PrimaryButton sx={{ width: '200px' }} onClick={() => toggle('Create')}>
  //         Create Key Skills
  //       </PrimaryButton>
  //       <Stack alignItems={'center'} direction={'row'} spacing={1.6}>
  //         {openSearch ? (
  //           <SearchIcon onClick={handleSearchOpen} />
  //         ) : (
  //           <StageSearchInput
  //             placeholder="search..."
  //             // onChange={(e) => handleSearch(e)}
  //             value={searchText}
  //             onChange={(e) => {
  //               e.stopPropagation();
  //               setSearchText(e.target.value);
  //             }}
  //             endAdornment={
  //               <IconButton edge="end" 
  //               // onClick={handleSearchOpen}
  //               onClick={() => {
  //                 handleSearchOpen();
  //                 setSearchText("");
  //               }}
  //               >
  //                 <ClearIcon />
  //               </IconButton>
  //             }
  //           />
  //         )}
  //       </Stack>
  //     </div>
  //     <Row style={{ marginTop: '10px', marginLeft: '20px',}}>
  //       <div className="table_cont">
  //         <div className="table-responsive">
  //           <Table className="table mb-0">
  //             <TableHead>
  //               <TableRow>
  //                 <th>Key Skills</th>
  //                 <th>status</th>
  //                 <th>Action</th>
  //               </TableRow>
  //             </TableHead>
  //             <tbody>
  //               {isLoading ? (
  //                 <BubbleLoader />
  //               ) : (
             
  //                 data?.length > 0?
  //                 data?.map((item, index) => (
  //                   <TableRow key={item.name + index}>
  //                     <TableCell>{item.name}</TableCell>
  //                     <TableCell>
  //                       <FormGroup>
  //                         <FormControlLabel
  //                           control={
  //                             <PrimarySwitch
  //                               sx={{ m: 1 }}
  //                               checked={item.is_active}
  //                               onChange={async (e) => {
  //                                 handleAction(item, index);
  //                               }}
  //                             />
  //                           }
  //                         />
  //                       </FormGroup>
  //                     </TableCell>
  //                     <TableCell className="col_btn">
  //                       <PrimaryButton
  //                         sx={{ width: '100px' }}
  //                         onClick={() => toggle('Edit', item.name, item.id, item.is_active, index)}
  //                       >
  //                         Edit
  //                       </PrimaryButton>
  //                     </TableCell>
  //                   </TableRow>
  //                 ))
                  
  //                 :
  //                 (<TableRow>
  //                   <TableCell colSpan={5} align="center">
  //                     <CustomNoRowsOverlay />
  //                   </TableCell>
  //                 </TableRow>)

  //               )}
  //             </tbody>
  //           </Table>
  //         </div>
  //       </div>
  //       <CustomPagination count={totalCount} page={page} setPage={setPage} />
  //     </Row>
  //   </Container>
  // );


  return (
    <>     <CommonModel
        // isOpen={isOpen}
        toggle={toggle}
        isOpen={viewAddModal}
        viewAddModal={viewAddModal}
        setViewAddModal={setViewAddModal}
        headTag={'Keyskills'}
        purpose={purpose}
        index={index}
        id={id}
        title={name}
        active={active}
        update={updateFunc}
      />


    <div
    style={{
      padding: "10px",
      height: "91vh",
      display: "flex",
      flexDirection: "column",
    }}
  >
    
      <GlobalTableTools  tableHeading={"Keyskills"}
       toggel={true}
       toggleFunc={toggle}
       isJobAttributes={true}
       setViewAddModal={setViewAddModal} 
       downloadOption={false}
        />
    <PrimaryDataGrid
     
      fileName="Industries"
      // onCellClick={handleCellClick}
      loading={isLoading}
     

      columns={filteredColumns}
      rows={data}
      slots={{
        loadIcon: LoaderIcon,
        baseButton: PrimaryButton,
        basePopper: TablePopper,
        baseSwitch: PrimarySwitch,
    
        // toolbar:CustomToolbar,
        noRowsOverlay: CustomNoRowsOverlay,
        pagination: props => (
          <Pagination
            {...props}
            // color="primary"
            // count={Math.ceil(rowsData.length / rowsPerPage)}
            count={totalCount}
            page={page}
            rowsPerPage={rowsPerPage}
            onChange={(_, newPage) => setPage(newPage)}
            showFirstButton
            showLastButton
            sx={{
              "& .MuiPaginationItem-root": {
                "&:hover": {
                  backgroundColor: "#cde2e7",
                  color: "#066a4c",
                },
                "&.Mui-selected": {
                  backgroundColor: "#024430",
                  color: "#ffe7bb",
                  "&:hover": {
                    backgroundColor: "#cde2e7",
                    color: "#066a4c",
                  },
                },
              },
            }}
          />
        ),
      }}
      slotProps={{
        baseButton: { style: { width: "100px" } },
        panel: {
          style: {
            right: "0px !important",
            transform: "none !important",
            inset: "none !important",
            top: "138px !important",
          },
        },
        filterPanel: {
          style: {
            right: "0px !important",
            transform: "none !important",
            inset: "none !important",
            top: "138px !important",
          },
        },
        toolbar: { style: {} },
        preferencesPanel: {
          style: {
            right: "0px !important",
            transform: "none !important",
            inset: "none !important",
            top: "138px !important",
          },
        },
      }}
      sx={{
        "& .super-app-theme--header": {
          backgroundColor: "rgba(250, 250, 250, 1)",
        },
      }}
    />
   
  </div>


  </>
  )

};

export default KeySkills;
