import {
  Checkbox,
  Chip,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Stack,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import React from "react";
import {
  BlackTypography,
  MenuProps,
  MultipleSelectInputs,
  PrimaryAutoComplete,
  PrimaryChip,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  PrimaryPopUp,
  PrimaryTypography,
  SecondaryFilledChip,
  autocompleteProps,
  StyledTootlTip,
} from "assets/Mui/globalTheme";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Controller, useFormContext } from "react-hook-form";
import dayjs from "dayjs";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { useEffect, useRef, useState } from "react";
import { getAllFunctionalArea } from "helpers/services/jobAttributes/functionalArea";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {
  getAllEducationalQualifications,
  getAllEducationalSpecialization,
  getEducationalSpecializationWithQualification,
} from "helpers/services/jobAttributes/educationalQualifications";
import {
  createKeySkills,
  getAllKeySkills,
  getAllKeySkillsWithStatus,
} from "helpers/services/jobAttributes/keyskills";
import {
  createJobLevel,
  createJobLevelForEveryone,
  getAllJobLevelsBasedonFunctionalArea,
  getAllJobLevlsWithoutFunctionalArea,
} from "helpers/services/jobAttributes/jobLevels";
import { getAllActualJobLevel } from "helpers/services/jobAttributes/actualJobLevel";
import { v4 as uuid } from "uuid";
import { Cascader } from "antd";
import "antd/dist/antd.css";
import { makeStyles } from "@mui/styles";
import SearchParamHook from "hooks/searchParams";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";

const useStyles = makeStyles({
  cascade: {
    "& .ant-select-selector": {
      borderColor: "rgba(0,0,0,.5) !important",
      minHeight: "50px",
      borderRadius: "8px !important",
    },
  },
  error: {
    "& .ant-select-selector": {
      borderColor: "red !important",
      minHeight: "50px",
      borderRadius: "8px !important",
    },
  },
});
export default function JobDescriptionDetails() {
  const {
    register,
    getValues,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();
  const classes = useStyles(errors);

  const [page, setPage] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState("");
  const [searchText, setSearchText] = useState("");
  const rowsPerPage = 1000;
  const searchParamsHook = SearchParamHook();

  /* Individual pages */
  const [categoriesPage, setCategoriesPage] = useState(1);
  const [totalCatCount, setTotalCatCount] = useState();
  const [catHasMore, setCatHasMore] = useState(true);

  const [loading, setLoading] = useState(false);
  const popperRef = useRef(null);

  /* Char limit 150 validation for editors start here */
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty() // You can initialize with an empty editor state
  );

  const [editorStateRequirements, setEditorStateRequirements] = useState(
    EditorState.createEmpty() // You can initialize with an empty editor state
  );

  const onEditorStateChange = newEditorState => {
    setEditorState(newEditorState);

    const contentState = newEditorState.getCurrentContent();
    const characterCount = contentState.getPlainText().length;

    handleEditorData(newEditorState, count);
    // if (characterCount >= 150) {
    //   handleEditorData(newEditorState);
    // }
  };

  const onEditorStateReqChange = newEditorState => {
    setEditorStateRequirements(newEditorState);

    const contentState = newEditorState.getCurrentContent();
    const characterCount = contentState.getPlainText().length;

    if (characterCount >= 150) {
      handleRequirementData(newEditorState);
    }
  };

  // const handleEditorData = (e,count) => {

  //   setValue("job_description", e);
  //   setValue(
  //     "job_desc",
  //     draftToHtml(convertToRaw(watch("job_description").getCurrentContent()))
  //   );
  // };

  /* Char limit 150 validation for editors End here */

  // useEffect(() => {
  //   const jobPageElement = document.getElementById('jobpage');

  //   if (jobPageElement) {
  //     console.log('Scrolling to the top');
  //     jobPageElement.scrollTop = 0;
  //   } else {
  //     console.warn('Element with ID "jobpage" not found.');
  //   }
  // }, [jobPageElement]);

  /*Scroll to top */

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleEditorData = e => {
    setValue("job_description", e);
    setValue(
      "job_desc",
      draftToHtml(convertToRaw(watch("job_description").getCurrentContent()))
    );
  };
  const handleRequirementData = e => {
    setValue("job_requirement", e);
    setValue(
      "requirements",
      draftToHtml(convertToRaw(watch("job_requirement").getCurrentContent()))
    );
  };

  const [SpecializationAnchor, setSpecializationAnchor] = useState(null);
  const qualificationRef = useRef(null);
  const handleSpecPopOver = event => {
    setSpecializationAnchor(qualificationRef.current);
  };

  const handleClose = () => {
    setSpecializationAnchor(null);
  };

  const open = Boolean(SpecializationAnchor);
  const id = open ? "simple-popover" : undefined;

  const no_of_vacancies = [
    { name: "1", value: "1" },
    { name: "2", value: "2" },
    { name: "3", value: "3" },
    { name: "4", value: "4" },
    { name: "5", value: "5" },
    { name: "6", value: "6" },
    { name: "7", value: "7" },
    { name: "8", value: "8" },
    { name: "9", value: "9" },
    { name: "10+", value: "10+" },
  ];
  const [functionalAreas, setFunctionalAreas] = useState([]);
  const [qualifications, setQualifications] = useState([]);
  const [keySkills, setKeySkills] = useState([]);
  const [jobLevel, setJobLevel] = useState([]);
  const [actualJobLevel, setActualJobLevel] = useState([]);
  // const [specs,setSpecs] = useState([])
  const [options, setOptions] = useState([]);

  // const searchParams =  SearchParamHook()
  const funcId = localStorage.getItem("func_id");
  useEffect(() => {
    getFunctionalAreas();
    getQualifications();
    getKeySKills();
    getAllJobActualJLs();
    // getQualifySpecs();
    dataCaller();
  }, []);
  useEffect(() => {
    if (funcId) {
      getAllJobLevels(funcId);
    }
  }, [funcId]);
  async function dataCaller() {
    const currentPage = searchParamsHook.get("page") ?? page;
    const data = await getAllEducationalQualifications(
      currentPage,
      rowsPerPage,
      searchText
    );
    const items = await data?.data_payload
      ?.filter(vals => vals.is_active && vals?.specs?.length > 0)
      .map(iterator => {
        const { id, name, specs } = iterator;
        if (specs?.length)
          return {
            value: id,
            label: name,
            children: [...specs]
              .filter(vals => vals.is_active)
              .map(e => ({
                label: e.name,
                value: e.id,
              })),
            // isLeaf: false,
          };
        return {
          value: id,
          label: name,
          children: [],
          disabled: true,
        };
      });
    setOptions(items);
  }
  const [skillLoader, setSkillLoader] = useState(true);
  async function getKeySKills() {
    const currentPage = searchParamsHook.get("page") ?? page;
    const { data, status } = await getAllKeySkillsWithStatus(
      currentPage,
      rowsPerPage,
      searchText
    );
    setKeySkills(
      data?.data_payload?.map(item => ({
        name: item?.name,
        value: item?.id,
        isActive: item?.is_active,
        id: item?.id,
      }))
    );
    if (status === 200) {
      setSkillLoader(false);
    }
  }
  async function getQualifications() {
    const currentPage = searchParamsHook.get("page") ?? page;
    const data = await getAllEducationalQualifications(
      currentPage,
      rowsPerPage,
      searchText
    );
    setQualifications(
      data?.data_payload?.map(item => ({
        name: item.name,
        value: item.id,
        isActive: item?.is_active,
      }))
    );
  }

  // async function getQualifySpecs(){
  //   const x =  await getAllEducationalSpecialization();
  //   setSpecs(x)
  // }

  async function getFunctionalAreas(categoriesPageScroll) {
    setLoading(true);
    const currentPage = searchParamsHook.get("page") ?? page;
    const currentCatPage = categoriesPageScroll ?? categoriesPage
    const data = await getAllFunctionalArea(
      currentPage,
      rowsPerPage,
      searchText
    );
    // if (data?.data?.data_payload?.length === 0) {
    //   setLoading(false);
    // }

    // if (data?.data?.data_payload?.length >= 5) {
    //   setCatHasMore(true);
    // } else {
    //   setCatHasMore(false);
    // }
    setFunctionalAreas(
      data?.data?.data_payload?.map(item => ({
        name: item.name,
        value: item.id,
        isActive: item.is_active,
      }))
    );

    // const formatedCatData = data?.data?.data_payload?.map(item => ({
    //   name: item.name,
    //   value: item.id,
    //   isActive: item.is_active,
    // }));

    // setFunctionalAreas(prevData => [...prevData,...formatedCatData]);

    // setTotalCatCount(data?.data?.total_page_size);
    // setTotalCount(data?.data?.total_page_size)
  }
  async function getAllJobLevels(id) {
    const data = await getAllJobLevelsBasedonFunctionalArea(id);
    setJobLevel(
      data?.map(item => ({
        name: item.name,
        value: item.id,
        isActive: item?.is_active,
      }))
    );
  }
  async function getAllJobActualJLs() {
    const currentPage = searchParamsHook.get("page") ?? page;
    const { data } = await getAllActualJobLevel(
      currentPage,
      rowsPerPage,
      searchText
    );
    setActualJobLevel(
      data?.data_payload?.map(item => ({
        name: item.name,
        value: item.id,
        isActive: item?.is_active,
      }))
    );
    // setTotalCount(data?.total_page_size);
  }
  const filter = createFilterOptions();
  const jobRoleFilter = createFilterOptions();

  const { SHOW_CHILD } = Cascader;
  // async function onClickRenderChildren(selectedOption){
  //   const item = selectedOption[selectedOption.length-1]
  //   item.loading = true
  //   const fetchChildList = await getEducationalSpecializationWithQualification(item.value)
  //   const childItems = fetchChildList.filter(vals=>vals.is_active).map(each=>({
  //     value:each.id,
  //     label:each.name
  //   }))
  //   item.loading = false
  //   item.children = childItems
  //   setOptions([...options])
  //   // setLoadingOptions([...options]);
  // }


  const handleMenuScroll = e => {
    const { target } = e;
    const { current } = popperRef;

    // Adjust the threshold as needed
    const threshold = 50; // You can adjust this value

    // Check if scrolled to the bottom with the threshold
    if (catHasMore) {
   
      setCategoriesPage(categoriesPage + 1);
      getFunctionalAreas(categoriesPage + 1);
    }
  };


  return (
    <Stack spacing={4} sx={{ margin: "20px 0px" }} id="jobpage">
      <input
        type="text"
        style={{ display: "none" }}
        {...register("job_role", { required: true })}
      />
      <input
        type="text"
        style={{ display: "none" }}
        {...register("job_level_id", { required: true })}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={4}>
          <Controller
            render={props => {
              return (
                <PrimaryAutoComplete
                  sx={{ width: "100%"}}
                  {...props}
                  // disableCloseOnSelect
                  componentsProps={autocompleteProps}
                  multiple={false}
                  options={functionalAreas}
                  // renderTags={(tagValue, getTagProps) =>
                  //   tagValue.map((option, index) => (
                  //     <SecondaryFilledChip
                  //       deleteIcon={<FeatherIcon icon={"x"} size="14" />}
                  //       label={option.name}
                  //       {...getTagProps({ index })}
                  //     />
                  //   ))
                  // }
                  renderOption={(props, option, { selected }) =>
                    option.isActive ? (
                    
                      <li {...props} key={props.id}>
                        <Checkbox
                          icon={<FeatherIcon icon="square" size="14" />}
                          checkedIcon={
                            <FeatherIcon icon="check-square" size="14" />
                          }
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    ) : null
                  }
                  getOptionLabel={option => option?.name || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderInput={params => (
                    <FormControl variant="standard" sx={{ width: "100%" }}>
                      <PrimaryNormalInputLabel
                        shrink
                        htmlFor="functional-outlined"
                        error={errors.functional_area}
                      >
                        Categories*
                      </PrimaryNormalInputLabel>
                      <MultipleSelectInputs
                        sx={{
                          ...(errors.functional_area && {
                            borderColor: "red",
                          }),

                        }}
                        variant="standard"
                        ref={params.InputProps.ref}
                        {...params}
                      />
                    </FormControl>
                  )}
                  value={watch("functional_area")}
                  onChange={(_, data, reason) => {
               
                    if (reason === "clear") {
                      setValue("job_role", null);
                      setValue("functional_area", null);
                      setJobLevel([]);
                    }
                    if (data?.value) {
                      getAllJobLevels(data.value);
                      // searchParams.set("func_id",data.value)
                      localStorage.setItem("func_id", data.value);
                      props.field.onChange(data);
                    }
                  }}
                />

              );
            }}
            name="functional_area"
            rules={{ required: true }}
            control={control}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <PrimaryAutoComplete
            componentsProps={autocompleteProps}
            single
            error={errors.job_role}
            value={watch("job_role")}
            onChange={async (_, value, reason) => {
              if (reason === "clear") {
                // setValue("functional_area", null);
              }
              if (value?.inputValue) {
                const { data } = await createJobLevelForEveryone({
                  name: value.inputValue,
                  functional_area_id: getValues("functional_area")?.value,
                });

                setValue("job_role", {
                  name: data.job_level_res.name,
                  value: data.job_level_res.id,
                  isActive: data.is_active,
                });
                await getAllJobLevels(data.job_level_res.functional_area_id);
              } else {
                setValue("job_role", value);
              }
            }}
            // disableCloseOnSelect
            id="multiple-job-roles"
            options={jobLevel}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <SecondaryFilledChip
                  deleteIcon={<FeatherIcon icon={"x"} size="14" />}
                  label={option.name}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderOption={(props, option, { selected }) =>
              option?.isActive ? (
                <li {...props} key={props.id}>
                  <Checkbox
                    icon={<FeatherIcon icon="square" size="14" />}
                    checkedIcon={<FeatherIcon icon="check-square" size="14" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              ) : null
            }
            filterOptions={(options, params) => {
              const filtered = jobRoleFilter(options, params);
              const { inputValue } = params;
              const isExisting = options.some(
                option => inputValue === option.name
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push({
                  inputValue,
                  name: `${inputValue}`,
                  isActive: true,
                  id: uuid(),
                });
              }

              return filtered;
            }}
            getOptionLabel={option => option?.name || ""}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            // isOptionEqualToValue={(option,value)=>{console.log(option,value,"v");}}
            renderInput={params => {
              return (
                <FormControl variant="standard" sx={{ width: "100%" }}>
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="job-role-outlined"
                    error={errors.job_role}
                  >
                    Job Role*
                  </PrimaryNormalInputLabel>
                  <MultipleSelectInputs
                    sx={{
                      ...(errors.job_role  && watch("job_role") == "" && { borderColor: "red" }),
                    }}
                    variant="standard"
                    ref={params.InputProps.ref}
                    {...params}
                  />
                </FormControl>
              );
            }}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={4}>
          <PrimaryAutoComplete
            componentsProps={autocompleteProps}
            single
            error={errors.job_level_id}
            value={watch("job_level_id")}
            // onChange={(e, value) => {
            //   setValue("job_level_id", value);
            // }}

            onChange={async (_, value, reason) => {
              if (reason === "clear") {
                setValue("functional_area", null);
              }
              if (value?.inputValue) {
                const { data } = await createJobLevelForEveryone({
                  name: value.inputValue,
                  functional_area_id: getValues("functional_area")?.value,
                });
            
                setValue("job_level_id", {
                  name: data.job_level_res.name,
                  value: data.job_level_res.id,
                  isActive: data.is_active,
                });
                await getAllJobLevels(data?.job_level_res?.functional_area_id);
              } else {
                setValue("job_level_id", value);
              }
            }}
            // disableCloseOnSelect
            id="multiple-job-roles"
            options={actualJobLevel}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <SecondaryFilledChip
                  deleteIcon={<FeatherIcon icon={"x"} size="14" />}
                  label={option.name}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderOption={(props, option, { selected }) =>
              option?.isActive ? (
                <li {...props}>
                  <Checkbox
                    icon={<FeatherIcon icon="square" size="14" />}
                    checkedIcon={<FeatherIcon icon="check-square" size="14" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              ) : null
            }
            filterOptions={(options, params) => {
              const filtered = jobRoleFilter(options, params);
              const { inputValue } = params;
              const isExisting = options.some(
                option => inputValue === option.name
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push({
                  inputValue,
                  name: `${inputValue}`,
                  isActive: true,
                  id: uuid(),
                });
              }

              return filtered;
            }}
            getOptionLabel={option => option?.name || ""}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            // isOptionEqualToValue={(option,value)=>{console.log(option,value,"v");}}
            renderInput={params => {
              return (
                <FormControl variant="standard" sx={{ width: "100%" }}>
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="job-role-outlined"
                    error={errors.job_level_id}
                  >
                    Experience Level*
                  </PrimaryNormalInputLabel>
                  <MultipleSelectInputs
                    sx={{
                      ...(errors.job_level_id && watch("job_level_id") == "" && { borderColor: "red" }),
                    }}
                    variant="standard"
                    ref={params.InputProps.ref}
                    {...params}
                  />
                </FormControl>
              );
            }}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Controller
            render={props => {
              return (
                <>
                  <BlackTypography
                    sx={{ color: "rgba(69, 76, 82, 1) !important" }}
                    variant="h4"
                  >
                    Educational Qualifications*
                  </BlackTypography>
                  <Cascader
                    style={{
                      width: "100%",
                    }}
                    className={
                      !errors.educational_qualifications
                        ? classes.cascade
                        : classes.error
                    }
                    {...props.field}
                    options={options}
                    value={watch("educational_qualifications")}
                    showCheckedStrategy={SHOW_CHILD}
                    // loadData={onClickRenderChildren}
                    onChange={value => {
                      props.field.onChange(value);
                    }}
                    multiple
                    allowClear
                    // changeOnSelect
                    // displayRender={(_,s)=>s[1]?.label || s[1]?.value}
                    tagRender={(label, _, value) => (
                      <SecondaryFilledChip
                        style={{ marginRight: "4px" }}
                        onDelete={label.onClose}
                        label={label.label}
                        deleteIcon={<FeatherIcon icon={"x"} size="14" />}
                      />
                    )}
                  />
                </>
              );
            }}
            name="educational_qualifications"
            control={control}
            rules={{ required: true }}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Controller
            render={props => {
              return (
                <PrimaryAutoComplete
                  sx={{ width: "100%" }}
                  {...props}
                  disableCloseOnSelect
                  componentsProps={autocompleteProps}
                  multiple={true}
                  loading={skillLoader}
                  loaderText="Fetching Skills"
                  options={keySkills}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <SecondaryFilledChip
                        deleteIcon={<FeatherIcon icon={"x"} size="14" />}
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderOption={(props, option, { selected }) =>
                    option?.isActive ? (
                      <li {...props} key={props.id}>
                        <Checkbox
                          icon={<FeatherIcon icon="square" size="14" />}
                          checkedIcon={
                            <FeatherIcon icon="check-square" size="14" />
                          }
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    ) : null
                  }
                  getOptionLabel={option => option?.name || ""}
                  renderInput={params => (
                    <FormControl variant="standard" sx={{ width: "100%" }}>
                      <PrimaryNormalInputLabel
                        shrink
                        htmlFor="key-skills-outlined"
                        error={errors.key_skills}
                      >
                        Required Skills (select all that applies)*
                      </PrimaryNormalInputLabel>
                      <MultipleSelectInputs
                        sx={{
                          ...(errors.key_skills && { borderColor: "red" }),
                        }}
                        variant="standard"
                        ref={params.InputProps.ref}
                        {...params}
                      />
                    </FormControl>
                  )}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some(
                      option => inputValue === option.name
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        isActive: true,
                        id: uuid(),
                        name: `${inputValue}`,
                      });
                    }
                    return filtered;
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={getValues("key_skills")}
                  onChange={async (_, data) => {
              
                    const lastIndex = data?.length - 1;
                    if (data && data[lastIndex]?.inputValue) {
                      const { data: skill } = await createKeySkills({
                        name: data[lastIndex]?.inputValue,
                        is_active: true,
                      });
                      await getKeySKills();
                      props.field.onChange([
                        ...getValues("key_skills"),
                        {
                          name: skill.key_skill_res.name,
                          value: skill.key_skill_res.id,
                        },
                      ]);
                    } else {
                      props.field.onChange(data);
                    }
                  }}
                />
              );
            }}
            name="key_skills"
            control={control}
            rules={{ required: true }}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <FormControl sx={{ width: "100%" }}>
            <StyledTootlTip placement="top" title="Number of Vacancies">
              <PrimaryNormalInputLabel
                error={Boolean(watch("no_of_vaancies"))}
                shrink
                sx={{
                  left: "-12px",
                  top: "10px",
                }}
                id="signup-user-type"
              >
                Number of Vacancies*
              </PrimaryNormalInputLabel>
            </StyledTootlTip>
            {/* <Select
              labelId="no_of_vacancies"
              id="demo-multiple-name"
              defaultValue={watch("no_of_vacancies") || "1"}
              {...register("no_of_vacancies", { required: false })}
              input={
                <PrimaryNormalInputs
                  label="no_of_vacancies"
                  error={Boolean(watch("no_of_vaancies"))}
                  {...register("no_of_vacancies", { required: false })}
                />
              }
              MenuProps={MenuProps}
            >
              {no_of_vacancies?.map(item => {
                return (
                  <MenuItem
                    key={item?.value}
                    value={item?.value} // style={getStyles(name, personName, theme)}
                  >
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select> */}


            <PrimaryNormalInputs
              fullWidth
              placeholder="Enter No of vacancies"
              {...register("no_of_vacancies", { required: true,
                validate: (value) => Number(value) > 0 || "Please enter a positive number greater than 0"
              })}
              id="job_title-outlined"
              autoFocus
              label="job_title address"
              type={"number"}
              error={errors.no_of_vacancies}
              variant="outlined"
            />


            <PrimaryNormalFormHelperText error={errors.no_of_vacancies}>
              {/* {Boolean(watch("no_of_vaancies"))
                ? "No of vacancies is a required Field"
                : ""} */}
                {errors.no_of_vacancies && errors.no_of_vacancies.message}
            </PrimaryNormalFormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={4} md={12} lg={6}>
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="application-deadline-outlined"
            >
              Application Deadline *
            </PrimaryNormalInputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {/* Other form elements */}
              <Controller
                control={control}
                defaultValue={null}
                name="application_deadline"
                render={({ field }) => (
                  <MobileDateTimePicker
                    disablePast={true}
                    label="Application Deadline"
                    value={watch("application_deadline")}
                    onChange={newValue => {
                      const selectedTime = dayjs(newValue);

                      // Check if the user selected a specific time
                      if (selectedTime.isValid()) {
                        setValue("application_deadline", selectedTime);
                      } else {
                        // If not, set the time to the end of the day
                        const endOfDay = dayjs().endOf("day");
                        setValue("application_deadline", endOfDay);
                      }
                    }}
                    shouldDisableTime={() => false} // Allow time selection
                    renderInput={params => (
                      <PrimaryNormalInputs
                        {...params}
                        error={errors?.application_deadline}
                        {...register("application_deadline", {
                          required: true,
                        })}
                      />
                    )}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>

        <BlackTypography
          variant="h4"
          sx={{
            marginLeft: "24px",
            color: "rgba(69, 76, 82, 1) !important",
            marginTop: "24px",
          }}
        >
          Job Description*
        </BlackTypography>
        <input
          type="text"
          style={{ display: "none" }}
          {...register("job_description", {
            required: true,
            pattern: /\S/,
            // minLength: "150",
            // maxLength: "2000",
          })}
        />
        <Grid item sx={{ paddingTop: "0px !important" }} xs={12}>
          <div
            style={{
              ...(errors?.job_description
                ? {
                    border: "0.5px solid red",
                    borderRadius: "8px",
                    // padding: "10px",
                    minHeight: "300px",
                  }
                : {
                    border: "0.5px solid gray",
                    borderRadius: "8px",
                    // padding: "10px",
                    minHeight: "300px",
                  }),
            }}
          >
            <Editor
              // defaultEditorState={deafaultValue}
              editorState={watch("job_description")}
              onEditorStateChange={handleEditorData}
              toolbar={{
                backgroundColor: "#cde2e7",
             
              }}
              // onEditorStateChange={onEditorStateChange}
              // handleChange={v => {
              //   if (v.length > 150) {
              //     setValue('job_description', v);
              //   }
              // }}
            />
          </div>
          {/* <PrimaryNormalFormHelperText error={errors.job_description}>
            {errors?.job_description
              ? "about is a required field with minimun 150 chars"
              : "describe about your company so that we can find better profiles for you."}
          </PrimaryNormalFormHelperText> */}
        </Grid>
        <BlackTypography
          variant="h4"
          sx={{
            marginLeft: "24px",
            marginTop: "24px",
            color: "rgba(69, 76, 82, 1) !important",
          }}
        >
          Job Requirements*
        </BlackTypography>
        <input
          type="text"
          style={{ display: "none" }}
          {...register("job_requirement", { required: true, pattern: /\S/ })}
        />
        <Grid item sx={{ paddingTop: "0px !important" }} xs={12}>
          <div
            style={{
              ...(errors?.job_requirement
                ? {
                    border: "0.5px solid red",
                    borderRadius: "8px",
                    // padding: "10px",
                    minHeight: "300px",
                  }
                : {
                    border: "0.5px solid gray",
                    borderRadius: "8px",
                    // padding: "10px",
                    minHeight: "300px",
                  }),
            }}
          >
            <Editor
        
              // defaultEditorState={deafaultValue}
              editorState={watch("job_requirement")}
              onEditorStateChange={handleRequirementData}
              // onEditorStateChange={onEditorStateReqChange}
            />
          </div>
        </Grid>

        {/* <Grid item xs={12} md={2.58}>
          <FormControl sx={{ width: "100%" }}>
            <PrimaryNormalInputLabel
              error={Boolean(watch("no_of_vaancies"))}
              shrink
              sx={{
                left: "-12px",
                top: "10px",
              }}
              id="signup-user-type"
            >
              Number of Vacancies
            </PrimaryNormalInputLabel>
            <Select
              labelId="no_of_vacancies"
              id="demo-multiple-name"
              defaultValue={watch("no_of_vacancies") || "1"}
              {...register("no_of_vacancies", { required: false })}
              input={
                <PrimaryNormalInputs
                  label="no_of_vacancies"
                  error={Boolean(watch("no_of_vaancies"))}
                  {...register("no_of_vacancies", { required: false })}
                />
              }
              MenuProps={MenuProps}
            >
              {no_of_vacancies?.map(item => {
                return (
                  <MenuItem
                    key={item?.value}
                    value={item?.value} // style={getStyles(name, personName, theme)}
                  >
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
            <PrimaryNormalFormHelperText error={errors.no_of_vacancies}>
              {Boolean(watch("no_of_vaancies"))
                ? "No of Vacancies is a required Field"
                : ""}
            </PrimaryNormalFormHelperText>
          </FormControl>
        </Grid> */}

        {/* <Grid item xs={12} md={12}>
          <Controller
            render={props => {
              return (
                <PrimaryAutoComplete
                  sx={{ width: "100%" }}
                  {...props}
                  disableCloseOnSelect
                  componentsProps={autocompleteProps}
                  multiple={true}
                  options={qualifications}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <SecondaryFilledChip
                        deleteIcon={<FeatherIcon icon={"x"} size="14" />}
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderOption={(props, option, { selected }) => (
                    option?.isActive ?
                    <li {...props}>
                      <Checkbox
                        icon={<FeatherIcon icon="square" size="14" />}
                        checkedIcon={
                          <FeatherIcon icon="check-square" size="14" />
                        }
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li> : null
                  )}
                  getOptionLabel={option => option?.name || ""}
                  renderInput={params => (
                    <FormControl variant="standard" sx={{ width: "100%" }}>
                      <PrimaryNormalInputLabel
                        shrink
                        htmlFor="education-outlined"
                        error={errors.educational_qualifications}
                      >
                        Educational Qualifications
                      </PrimaryNormalInputLabel>
                      <MultipleSelectInputs
                        sx={{
                          ...(errors.educational_qualifications && {
                            borderColor: "red",
                          }),
                        }}
                        variant="standard"
                        ref={params.InputProps.ref}
                        {...params}
                      />
                    </FormControl>
                  )}
                  value={getValues("educational_qualifications")}
                  onChange={(_, data) => {
                    props.field.onChange(data);
                  }}
                />
              );
            }}
            name="educational_qualifications"
            control={control}
          />
        </Grid> */}
      </Grid>
    </Stack>
  );
}
