import { Grid, ImageList, ImageListItem, Skeleton, Stack,Modal, Box, IconButton } from "@mui/material";
import {
  BlackTypography,
  GreyTypography,
  PrimaryAvatar,
} from "assets/Mui/globalTheme";
import UseJobPostChatUserDetails from "./chatUserDetails";
// import moment from "moment";
import {makeStyles} from "@mui/styles";
import { Swiper, SwiperSlide,useSwiper } from 'swiper/react';
import { Navigation } from 'swiper'
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import { useState,useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { imageURL } from "common/constants/commonURLS";
import { getCandidateChatAttachments } from "helpers/services/chats/evalautorCharts";
import imageNotFound from "../../../../assets/images/noImageGreen.png"


const useStyles = makeStyles({
  swiper:{
    "& .swiper_arrws":{
      position:'fixed',
      top:'44%',
      zIndex:2,
      "& .css-78trlr-MuiButtonBase-root-MuiIconButton-root":{
        color:'#fff !important',
        background:'#081753 !important',
        "&:hover":{
          background:'#b5bfe4 !important',
          color:'#081753 !important'
        }
      }
    },
    "& .slidePrev-btn":{
      left:"-22px",
    },
    "& .slideNext-btn":{
      right:"-22px"
    },
    "& .slidePrev-btn.swiper-button-disabled,.slideNext-btn.swiper-button-disabled":{
      "& .css-78trlr-MuiButtonBase-root-MuiIconButton-root":{
        pointerEvents:'none',
        opacity:0.1
      }
    }
  },
  imageGrid:{
    height:600,
    display:'grid',
    overflowY:'scroll',
    cursor:'pointer',
    gridTemplateColumns:'repeat(3,100px)',
    gap:4,
    gridAutoRows:'100px',
    "& img":{
      width:"100%",
      height:"100%"
    }
  }
})

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  borderRadius:'12px'
  // p: "8px",
};

export default function ChatUserDetails({media,candidate}) {
   const classes = useStyles()
  const [viewMedia,setViewMedia] = useState(false)
  return (
    <Stack height={"100%"} spacing={2}>
      <Stack
        sx={{ borderBottom: "1px solid #ddd", paddingBlockEnd: "8px" }}
        spacing={1}
        alignItems={"center"}
      >
        <PrimaryAvatar
          src={imageURL + candidate?.data_payload?.profile_image}
          alt={`candidate logo`}
        >
          {candidate?.data_payload?.first_name?.[0]}
        </PrimaryAvatar>
        <BlackTypography
          sx={{ textTransform: "capitalize" }}
          variant="h4"
          component="h4"
        >
          {candidate?.data_payload?.first_name} {candidate?.data_payload?.last_name}
        </BlackTypography>
        {/* <GreyTypography variant="h6" component="h6">
          Last Seen on 29-Dec-2023
        </GreyTypography> */}
      </Stack>
      <UseJobPostChatUserDetails
        type="email"
        result={candidate?.data_payload?.email}
        sx={{ textTransform: "lowercase !important" }}
      />
      <UseJobPostChatUserDetails type="phone" result={candidate?.data_payload?.phone_number} />
      {/* <UseJobPostChatUserDetails type="Last Visited" result="8 August 2023" /> */}
      <Stack spacing={1}>
        <BlackTypography variant="h6" component="h6">
          Attachement
        </BlackTypography>
      </Stack>

      {media?.length > 0 ? (
        <div
          onClick={() => {
            if (media.length) setViewMedia(true);
          }}
          className={classes.imageGrid}
        >
          {media?.map(item => (
            <LazyLoadImage
              key={item.id}
              src={imageURL + item.pic}
              // srcSet={`${item.img}?w=80&h=80&fit=crop&auto=format&dpr=2 2x`}
              alt={item.id}
              loading="lazy"
              effect="blur"
            />
          ))}
        </div>
      ) : (
        <img
          style={{ alignSelf: "center", width: "150px", height: "120px",marginTop:0}}
          src={imageNotFound}
          alt="/not-found"
        />
      )}

      {/* <ImageList sx={{height:450}} cols={3} rowHeight={80}>
        {itemData.map(item => (
          <ImageListItem key={item.img}>
            <img
              src={`${item.img}?w=80&h=80&fit=crop&auto=format`}
              srcSet={`${item.img}?w=80&h=80&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList> */}
      {/* <Skeleton variant="rectangular" width={110} height={58} /> */}
      {viewMedia && (
        <Modal open={viewMedia} onClose={() => setViewMedia(false)}>
          <Box sx={style}>
            {/* <IconButton sx={{position:'absolute',right:'6px',top:0,color:'#081753',background:'#b5bfe4',zIndex:3,":hover":{background:'#081753',color:'#fff'}}}>
              <FeatherIcon icon={'x'} size={14} />
            </IconButton> */}
            <Swiper
              // ref={swiperRef}
              className={classes.swiper}
              // spaceBetween={16}
              navigation={{
                enabled: true,
                nextEl: ".slideNext-btn",
                prevEl: ".slidePrev-btn",
              }}
              modules={[Navigation]}
            >
              {media?.map((item) => (
                <SwiperSlide
                  key={item.id}
                  style={{ width: "100%", height: "300px" }}
                >
                  <LazyLoadImage
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                    loading="lazy"
                    effect="blur"
                    height={"100%"}
                    width={"100%"}
                    src={imageURL + item.pic}
                    alt={item.id}
                  />
                </SwiperSlide>
              ))}
              <SwiperButtons />
            </Swiper>
            {/* <Carousel itemPadding={[5,5]} easing="ease" tiltEasing="ease" pagination={false} itemsToShow={4}>
          </Carousel> */}
          </Box>
        </Modal>
      )}
    </Stack>
  );
}

function SwiperButtons() {
  const swiper = useSwiper();
  return (
    <>
      <div onClick={() => {swiper.navigation.prevEl}} className="slidePrev-btn swiper_arrws ">
        <IconButton >
          <FeatherIcon icon={"chevron-left"} size={16} />
        </IconButton>
      </div>
      <div onClick={() => {swiper.navigation.nextEl}} className="slideNext-btn swiper_arrws ">
        <IconButton >
          <FeatherIcon icon={"chevron-right"} size={16} />
        </IconButton>
      </div>
    </>
  );
}