const { Container, Col, Row } = require("reactstrap");
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import noData from "../../assets/images/zepul/no_jobs.svg";
import { CloseJobModel } from "pages/Job/JobList/CloseJobModel";
import { deleteSingleJob, getSingleJob } from "helpers/services/jobs/jobs";
import { connect } from "react-redux";
// import { ACM } from "common/constants/userModules";
import JobDetails from "pages/jobs/navFunctions/jobDetails";
import { Grid, Skeleton, Stack } from "@mui/material";
import JobPostedCompanyAvatar from "components/jobCard/JobPostedCompanyAvatar";
import JobCardInformation from "components/jobCard/jobCardInformations";
import PendingCardButtons from "components/jobCard/pendingCardButtons";
import { useLocation } from "react-router-dom";
import {
  GreyTypography,
  StyledCard,
  StyledCardContent,
} from "assets/Mui/globalTheme";
import GlobalLoader from "components/loader";
import { ACM, CA, CS, ZA } from "common/constants/userModules";
import { getAllPendingJobs, getAllPendingJobsBasedOnCompany, getSingleJobDetails, getAllJobsBasedOnCompany } from "helpers/services/jobs/jobs";
import ErrorHandlingToaster from "helpers/Toaster/error";
import SearchParamHook from "hooks/searchParams";
import CustomPagination from "components/Pagination/globalPagination";
import { socket } from "Sockets/socket";

// function Pending({ jobs, load, setUpdate,update }) {
function Pending({ fetchCompanyJobs, filterCompanyJobs,setPages }) {
  const activePathDetails = fetchCompanyJobs?.activePath
  const companyId = fetchCompanyJobs?.companyId

  const role = JSON.parse(localStorage.getItem("authUser"))?.role;
  const login = {
    ...JSON.parse(localStorage.getItem("authUser")),
  };
  let history = useHistory();
  // const [tableData, setTableData] = useState([]);
  const [jobDetails, setJobDetails] = useState(false);
  const [singleJobDetails, setSingleJobDetails] = useState({});
  // const [viewDetails, setViewDetails] = useState(tableData[0]);
  const [modelData, setModelData] = useState({
    modal: false,
    details: {},
  });

  // const [viewDetailsModal, setViewDetailsModal] = useState({
  //   modal: false,
  //   details: {},
  // });

  // const [viewCandidateDetailsModal, setCandidateDetailsModal] = useState({
  //   modal: false,
  //   details: {},
  // });

  const handleClose = confirm => { };
  const handleViewMore = async id => {
    const data = await getSingleJob(id);
    setJobDetails(true);
    setSingleJobDetails(data);
  };
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const searchItems = event => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const results = tableData?.filter(item =>
      item.job_title.toLowerCase().includes(searchTerm)
    );
    setSearchResults(results);
  }, [searchTerm]);

  function randcolor() {
    function c() {
      return Math.floor(Math.random() * 220 + 36).toString(16);
    }
    return "#" + c() + c() + c();
  }


  /*Individual Api with pagination start here */

  const [jobs, setJobs] = useState([]);
  const [load, setLoad] = useState(false);
  const [filterSearch, setFilterSearch] = useState("");
  const [update, setUpdate] = useState(false);
  const [listLoad, setListLoad] = useState(false)
  const searchParams = SearchParamHook();
  const [isMount, setIsMount] = useState(false);

  const [page, setPage] = React.useState(searchParams.get("page") ?? 1)
  const [totalCount, setTotalCount] = React.useState(0)
  const rowsPerPage = 12;

  const { search } = useLocation()


  useEffect(() => {
    if (!isMount) {
      setIsMount(true);
      return;
    }
    dataCaller();
  }, [update]);

  useEffect(() => {
    if (filterCompanyJobs || !filterCompanyJobs) {
      dataCaller();
    }

  }, [filterCompanyJobs])

  const tableData = jobs?.filter(
    data => data.is_job_post_verified !== true && data.is_active === true
  );


  async function dataCaller() {

    setLoad(true);
    try {
      const searchValue = searchParams.get("searchParam") ?? ""
      // const { data, status } = [CS].includes(login.role) || fetchCompanyJobs?.companyId ?
      //   await getAllJobsBasedOnCompany(companyId, activePathDetails, page, rowsPerPage, searchValue)
      //   : await getAllPendingJobs(page, rowsPerPage, searchValue);

      const {data, status} = await getAllPendingJobs(page,rowsPerPage,searchValue,searchParams.get('companyParam'))

      if (status === 200) {
        setJobs(data?.data_payload);
        setLoad(false)
        setTotalCount(data?.total_page_size)
      }
      ErrorHandlingToaster({ data, status });
    } catch (error) {
      setLoad(false)
    }
  }
  /*Individual Api with pagination start here */

  /*search filter functionality */

  useEffect(async () => {
    const currentPath = window.location.pathname;

    let pathStatus
    if (currentPath === "/joblist/approved") {
      pathStatus = "active"
    } else if (currentPath === "/joblist/pending") {
      pathStatus = "pending"
    } else if (currentPath === "/joblist/closed") {
      pathStatus = "closed"
    }

    if (pathStatus === "pending" && searchParams.get("page") || searchParams.get("searchParam")) {
      dataCaller();
    }

  }, [searchParams.get("page"), searchParams.get("searchParam")])



  const reCall = () => {
    dataCaller()
  }


  /*Page refresh will happen based on socket listener */

const handleListnerEvent = data => {
  if (['pending_jobs.','active_jobs.','closed_jobs.'].includes(data?.type)) {
    dataCaller();
  }
};

useEffect(() => {
  const handleReconnect = () => {
    // Re-register the event listener after reconnection if needed
    socket.on("listnerEvent", handleListnerEvent);
  };

  socket.on("listnerEvent", handleListnerEvent);

  // Attach a listener for socket reconnection
  socket.on("reconnect", handleReconnect);

  return () => {

    socket.off("listnerEvent", handleListnerEvent);
    socket.off("reconnect", handleReconnect);
  };
}, []);

  return (
    <>
      {!load ? (
        <>
          <JobDetails
            isOpen={jobDetails}
            toggle={() => {
              setJobDetails(false);
            }}
            data={singleJobDetails}
          />
          <Container
            fluid
            style={{ paddingLeft: "15px", paddingRight: "15px", height: "80vh", }}
          >
            <div className="row row-gap-1" style={{ height: "90%", overflowY: "scroll" }}>
              {tableData?.length > 0 ? (
                tableData.map(item => (
                  <Col xs={12} md={6} lg={4} className="mb-4" key={item.id}>
                    <StyledCard>
                      <StyledCardContent>
                        <Stack direction="column" spacing={1}>
                          <JobPostedCompanyAvatar item={item} />

                          <JobCardInformation
                            tableData={tableData}
                            setUpdate={setUpdate}
                            // setTableData={setTableData}
                            item={item}
                          />
                          {role === ACM ? (
                            <PendingCardButtons
                              tableData={tableData}
                              item={item}
                              setUpdate={setUpdate}
                              reCall={reCall}
                            // setTableData={setTableData}
                            // handleViewMore={handleViewMore}
                            />
                          ) : (
                            ""
                          )}
                        </Stack>
                      </StyledCardContent>
                    </StyledCard>
                  </Col>
                ))
              ) : (
                <div className="no_data_show">
                  <div>
                    <img src={noData} className="lg_img"></img>
                    <GreyTypography variant="h6">
                      Pending Bucket is Empty
                    </GreyTypography>
                  </div>
                </div>
              )}
            </div>

            {modelData ? (
              <CloseJobModel
                setModelData={doc => setModelData(doc)}
                show={modelData.modal}
                onHide={() => {
                  setModelData({ modal: false, details: {} });
                }}
                params={modelData}
                onSubmit={async () => {
                  const { status } = await deleteSingleJob(
                    modelData.details.id
                  );
                  if (status === 200) {
                    setUpdate(pre => !pre);
                    // const index = tableData.findIndex(
                    //   ({ id }) => id === modelData.details.id
                    // );
                    // const data = [...tableData];
                    // data.splice(index, 1);
                    // setTableData(data);
                  }
                  handleClose();
                }}
              />
            ) : null}



            {tableData?.length > 0 ? (
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  // marginBottom: "50px",
                }}
              >
                <CustomPagination
                  count={totalCount}
                  page={page}
                  setPage={setPage}
                  setPages={setPages}
                />
              </div>
            ) : (
              ""
            )}


          </Container>
        </>
      ) : (
        <GlobalLoader />
      )}
    </>
  );
}

export default connect(null, null)(Pending);
