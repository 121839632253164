import { POST, PUT } from "common/constants/methods";
import callApi from "helpers/callApi";
// !getting company related things
async function Id() {
  return await JSON.parse(localStorage.getItem("authUser"))?.id;
}

async function companyId() {
  return await JSON.parse(localStorage.getItem("authUser"))?.company_id;
}

export const createTechicalEvaluator = async body => {
  const reqBody = {
    email: body.email,
    first_name: body.first_name,
    last_name: body.last_name,
    phone_number: body.phone_number,
    user_password: body.password,
    usertype: body.userType,
  };

  return await callApi(
    "/technical_evaluator_authorise/register",
    reqBody,
    POST
  );
};
export const createTE = async body => {
  const reqBody = {
    ...body,
    email: body.email,
    first_name: body.first_name,
    last_name: body.last_name,
    phone_number: body.phone_number,
    user_password: body.user_password,
    usertype: body.userType,
  };
  delete reqBody.userType
 
  return await callApi(
    "/technical_evaluator_authorise/register",
    reqBody,
    POST
  );
};

export const updateTechnicalEvaluator = async body => {
  return await callApi(
    `/technical_evaluator_authorise/${await Id()}`,
    body,
    PUT
  );
};

// export const getAllTechnicalPartners = async ({role,page,rowsPerPage}) => {
//   const pageNum = page
//   const records = rowsPerPage

//   if(["ZA"].includes(role)){
//     // return await callApi(`referral_partner_authorise/evaluators_list?offset={offset}&&limit={page}`);
//     return await callApi(`referral_partner_authorise/evaluators_list?pageNo=${page}&&pageSize=${rowsPerPage}`);
//   }else {
//     return await callApi(`referral_partner_authorise/evaluators_list?company_id=${await companyId()}&&pageNo=${page}&&pageSize=${rowsPerPage}`);
//   }

// };

export const getAllTechnicalPartners = async (role,page,rowsPerPage,searchText) => {
  const roles = role;
  const pageNum = page;
  const records = rowsPerPage;
  if (["ZA"].includes(role)) {
    // Use string interpolation to insert actual values
    return await callApi(`referral_partner_authorise/evaluators_list?pageNo=${pageNum}&pageSize=${records}&searchParam=${searchText}`);
  } else {
    const companyIdValue = await companyId();
    // Use string interpolation to insert actual values
    return await callApi(`referral_partner_authorise/evaluators_list?company_id=${companyIdValue}&pageNo=${page}&pageSize=${rowsPerPage}&searchParam=${searchText}`);
  }
};

