import { Stack,Avatar } from "@mui/material";
import {
  SecondaryFilledBox,
  TimeTypography,
} from "assets/Mui/globalTheme";
import { imageURL } from "common/constants/commonURLS";
import moment from "moment";
import { useSelector } from "react-redux";

export default function RecivedMessage({ message, time }) {
  const { candidate } = useSelector(state => state.Candidates);
  return (
    <Stack
      sx={{
        maxWidth: "50% !important",
        marginRight: "auto",
        marginBottom: "10px",
      }}
      direction={'row'}
      spacing={0.5}
      // alignItems={'center'}
    >
      <Avatar
        src={imageURL + candidate?.profile_image}
        alt={`${candidate?.first_name}${candidate?.last_name}`}
        sizes="small"
        sx={{width:'20px',height:'20px',mt:'6px',bgcolor:"rgba(2, 68, 48,0.4)"}}
      >
        {candidate?.first_name[0]}
      </Avatar>
      <Stack>
        <SecondaryFilledBox
          sx={{
            minWidth: "auto",
            maxWidth: "300px !important",
            borderTopLeftRadius: "0px !important",
            wordBreak: "break-word",
          }}
        >
          {message}
        </SecondaryFilledBox>
        <Stack sx={{ marginRight: "auto" }}>
          <TimeTypography variant="h6" component="h6">
            {moment(time).format("LT")}
          </TimeTypography>
        </Stack>
      </Stack>
    </Stack>
  );
}
