import { CircularProgress, Grid } from "@mui/material";
import CandidateInformation from "./CandidateInformation";
import CandidateExperience from "./WorkExperience";
import AdditionalInformation from "./additonalInformation";
import CandidateSkills from "./skills";
import SearchParamHook from "hooks/searchParams";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect, useState } from "react";
import EducationInformation from "./educationalInformation";
import WorkAuthInformation from "./workAuthInformation";
import CandidateLanguages from "./languages";
import { viewSingleUserDetailsInKanbanBoard } from "helpers/services/referalPartner";
import { useDispatch } from "react-redux";
import { socket } from "Sockets/socket";
import { useHistory, useLocation } from "react-router-dom";

export default function VerifiedSummary() {
  const searchParams = SearchParamHook();
  const history = useHistory();
  const { search } = useLocation();
  const profileReadStatus = searchParams.get('readOnlyStatus')
  const userJobPostId = searchParams.get('user_job_post_id')
  const isVerify = searchParams.get('is_verified_summary')

  const [isVerified, setIsVerified] = useState(false)

  const dispatch = useDispatch();
  const [candidateDetails, setCandidateDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const userId = searchParams.get("user_id");
  const jobPostId = searchParams.get("job_id");

  // const { id: jobPostId } = useParams();
  useEffect(() => {
   
    const userId = searchParams.get("user_id");
    const jobPostId = searchParams.get("job_id");
    if (userId && jobPostId) {
      fetchUserJobPostDetail(userId, jobPostId);
    }
  }, [searchParams]);

  async function fetchUserJobPostDetail(userId,jobPostId) {
    console.log(userId,"hhhh")
  console.log(jobPostId,"kkk")
    const { data } = await viewSingleUserDetailsInKanbanBoard(
      userId,
      jobPostId,
      userJobPostId
    );
    dispatch({ type: "ADD_CANDIDATES", payload: data?.data_payload });
    setCandidateDetails(data?.data_payload?.user_job_post_details);
    setIsVerified(data?.data_payload?.user_job_post_details?.is_verified_summary)
    const searchParams = new URLSearchParams(search);
    searchParams.set("is_verified_summary", data?.data_payload?.user_job_post_details?.is_verified_summary);
    history.push({
      search: searchParams.toString(),
    });
    setLoading(false);
  }

/* Implementing read only to SP start here */
  let userDetails = JSON.parse(localStorage.getItem("authUser"))
  const role = userDetails?.role
  let read 
  if(role === "SP"){
    // read = false
    read=profileReadStatus === "false"?false:true
  } else {
    read = true
  }

/* Implementing read only to SP End here */


const handleListnerEvent = data => {
  if (data?.type === "profile") {
    const userId = searchParams.get("user_id");
    const jobPostId = searchParams.get("job_id");
    if (userId && jobPostId) {
      fetchUserJobPostDetail(userId, jobPostId);
    }
  }
};

useEffect(() => {
  const handleReconnect = () => {
    // Re-register the event listener after reconnection if needed
    socket.on("listnerEvent", handleListnerEvent);
  };

  socket.on("listnerEvent", handleListnerEvent);

  // Attach a listener for socket reconnection
  socket.on("reconnect", handleReconnect);

  return () => {

    socket.off("listnerEvent", handleListnerEvent);
    socket.off("reconnect", handleReconnect);
  };
}, []);

  return (
    <>
      {loading ? (
        <div style={{ display: "grid", placeItems: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={6} spacing={2}>
            <CandidateInformation
              candidateDetails={candidateDetails}
              setCandidateDetails={setCandidateDetails}
              readOnly={read}
              refetch={()=>fetchUserJobPostDetail(userId,jobPostId)}
              isVerified={isVerified}
              isVerify={isVerify}
            />
            <CandidateExperience
              candidateDetails={candidateDetails}
              setCandidateDetails={setCandidateDetails}
              readOnly={read}
              isVerify={isVerify}
            />
             <WorkAuthInformation
              candidateDetails={candidateDetails}
              setCandidateDetails={setCandidateDetails}
              fetchUserJobPostDetails={fetchUserJobPostDetail}
              readOnly={read}
              isVerify={isVerify}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <CandidateSkills
              candidateDetails={candidateDetails}
              setCandidateDetails={setCandidateDetails}
              readOnly={read}
              isVerify={isVerify}
            />
            {/* <CandidateLanguages /// not required
          candidateDetails={candidateDetails}
          setCandidateDetails={setCandidateDetails}
        /> */}
            <AdditionalInformation
              candidateDetails={candidateDetails}
              setCandidateDetails={setCandidateDetails}
              readOnly={read}
              isVerify={isVerify}
            />
            <EducationInformation
              candidateDetails={candidateDetails}
              setCandidateDetails={setCandidateDetails}
              readOnly={read}
              isVerify={isVerify}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}
