import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  BlackTypography,
  PrimaryButton,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  SecondaryFilledButton,
  PrimaryCheckbox,
  PrimaryOutlinedButton
} from "assets/Mui/globalTheme";
import { useForm } from "react-hook-form";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import { FormControl, Grid, Stack } from "@mui/material";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  width: "50%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
  height: 500,
  overflow: "auto",
};
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import htmlToDraft from "html-to-draftjs";
import UploadModel from "components/Common/models/uploadModel";
import { getPreSignedURL } from "helpers/services/pre_signed_urls/upload";
import { toast } from "react-hot-toast";
import { imageURL } from "common/constants/commonURLS";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
export default function AddEditEducation({
  type,
  open,
  currentData,
  toggleModel,
  updateOrEditWorExperience,
}) {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const [checked, setChecked] = React.useState(false);
  // const [exactEdit,setExactEdit] = React.useState(null)
  const [model, setModel] = React.useState(false);

  const handleChange = event => {
    setChecked(event.target.checked);
  };
  function JobDescription(data) {
    const contentBlock = htmlToDraft(data);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        return editorState
      }
  }

  React.useEffect(() => {
    if (type?.includes("update")) {
      setValue("certificate", currentData.certificate);
      setValue("institute", currentData.institute);
      setValue("start_date", currentData.start_date);
      setValue("end_date", currentData.end_date);
      setValue("desc",(currentData.desc));
      setValue("specialization", currentData.specialization)
      // setExactEdit(currentData?.desc)
      setValue("institute_image",currentData?.institute_image)
      setChecked(currentData.is_currently_pursuing || false)
    }
  }, [type]);

  const handleEditorData = e => {
    setValue("description", e);
    setValue(
      "desc",
      draftToHtml(convertToRaw(watch("description").getCurrentContent()))
    );
  };

  function imageModel() {
    setModel(!model);
    // initialFetch();
  }

  const handleImageUpload = async data => {
    const url = await getPreSignedURL(data);
    toast.success("uploaded");
    setValue("institute_image", imageURL+url);
    // setCompanyImageUrl(!companyImageUrl);
    setTimeout(() => {
      imageModel();
    }, 1000);
  };

  return (
    <>
        {model && (
          <UploadModel
            isOpen={model}
            toggle={imageModel}
            handleImage={handleImageUpload}
          />
        )}    
      <div>
        <Modal
          open={open}
          onClose={() => {
            toggleModel();
            if(!type?.includes("update"))
            reset();
          }}
          aria-labelledby="edit-profile-modal-certificate"
          aria-describedby="edit-profile-modal-description"
        >
          <Box sx={style}>
            <BlackTypography variant="h2" sx={{ marginBottom: "10px" }}>
              {type === "create" ? "Create" : "Edit"} Education
            </BlackTypography>
            <form onSubmit={handleSubmit((data)=>updateOrEditWorExperience(data,checked))}>
              <Grid container spacing={2}>
                <Grid item md={12} lg={12}>
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    <PrimaryNormalInputLabel
                      shrink
                      htmlFor="work-exp-certificate-outlined"
                      error={errors.titile}
                    >
                      Degree
                    </PrimaryNormalInputLabel>
                    <PrimaryNormalInputs
                      fullWidth
                      placeholder="Enter your certificate"
                      {...register("certificate", { required: true })}
                      id="wor-exp-certificate-outlined"
                      helperText={errors?.certificate ? "email is required" : ""}
                      autoFocus
                      label="Job certificate"
                      type={"text"}
                      name="certificate"
                      error={errors.certificate}
                      variant="outlined"
                    />
                    <PrimaryNormalFormHelperText error={errors.certificate}>
                      {errors.certificate ? "certificate is required Field" : ""}
                    </PrimaryNormalFormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={12} lg={12}>
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    <PrimaryNormalInputLabel
                      shrink
                      htmlFor="work-exp-institute-outlined"
                      error={errors.institute}
                    >
                      Institute
                    </PrimaryNormalInputLabel>
                    <PrimaryNormalInputs
                      fullWidth
                      placeholder="Enter your institute"
                      {...register("institute", { required: true })}
                      id="wor-exp-institute-outlined"
                      helperText={errors?.institute ? "email is required" : ""}
                      autoFocus
                      label="Job institute"
                      type={"text"}
                      name="institute"
                      error={errors.institute}
                      variant="outlined"
                    />
                    <PrimaryNormalFormHelperText error={errors.institute}>
                      {errors.institute ? "institute is required Field" : ""}
                    </PrimaryNormalFormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={12} lg={12} sm={12}>
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    {!watch("institute_image") ? (
                      <PrimaryOutlinedButton
                        sx={{ maxWidth: "220px" }}
                        onClick={() => {
                          imageModel();
                        }}
                        startIcon={<FeatherIcon icon="edit" size="14" />}
                      >
                        Institute Image
                      </PrimaryOutlinedButton>
                    ) : (
                        <>
                          <PrimaryNormalInputs
                            fullWidth
                            placeholder="Enter your employer"
                            {...register("institute_image", { required: true })}
                            id="wor-exp-employer-outlined"
                            // helperText={errors?.employer ? "email is required" : ""}
                            autoFocus
                            value={watch("institute_image")}  
                            label="Institute Image"
                            type={"text"}
                            name="employer"
                            readOnly
                            // error={errors.employer}
                            variant="outlined"
                          />
                        <PrimaryOutlinedButton
                        sx={{ maxWidth: "220px",marginBlockStart:"12px" }}
                        onClick={() => {
                          imageModel();
                        }}
                        startIcon={<FeatherIcon icon="edit" size="14" />}
                      >
                        upload image
                      </PrimaryOutlinedButton>
                        </>
                    )}
                  </FormControl>
              </Grid>
                <Grid item md={12} lg={12}>
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    <PrimaryNormalInputLabel
                      shrink
                      htmlFor="work-exp-certificate-outlined"
                      error={errors.titile}
                    >
                      Specialization
                    </PrimaryNormalInputLabel>
                    <PrimaryNormalInputs
                      fullWidth
                      placeholder="Enter your certificate"
                      {...register("specialization", { required: true })}
                      id="wor-exp-certificate-outlined"
                      helperText={
                        errors?.specialization ? "specialization is required" : ""
                      }
                      autoFocus
                      label="specialization"
                      type={"text"}
                      name="specialization"
                      error={errors.specialization}
                      variant="outlined"
                    />
                    <PrimaryNormalFormHelperText error={errors.specialization}>
                      {errors.specialization
                        ? "specialization is required Field"
                        : ""}
                    </PrimaryNormalFormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={12} lg={12}>
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    <PrimaryNormalInputLabel
                      shrink
                      htmlFor="work-exp-start_date-outlined"
                      error={errors.titile}
                    >
                      Start Date
                    </PrimaryNormalInputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        disableFuture={true}
                        label="start Date"
                        value={watch("start_date")}
                        onChange={newValue => {
                          setValue("start_date", newValue);
                        }}
                        renderInput={params => (
                          <PrimaryNormalInputs
                            {...params}
                            error={errors?.start_date}
                            {...register("start_date", { required: true })}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>

                <Grid item md={12} lg={12}>
                  <Stack
                    direction="row"
                    justifyContent=""
                    alignItems="center"
                    spacing={1}
                  >
                    <PrimaryCheckbox
                      checked={checked}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <BlackTypography>
                      I am currently pursuing now*
                    </BlackTypography>
                  </Stack>
                </Grid>

                <Grid item md={12} lg={12}>
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    <PrimaryNormalInputLabel
                      shrink
                      htmlFor="work-exp-end_date-outlined"
                      error={errors.titile}
                    >
                      {checked?"Expected End Date":"End Date"}
                    </PrimaryNormalInputLabel>
                    {checked?  <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        label="end Date"
                        value={watch("expect_end_date")}
                        onChange={newValue => {
                          setValue("expect_end_date", newValue);
                        }}
                        renderInput={params => (
                          <PrimaryNormalInputs
                            {...params}
                            error={errors?.expect_end_date}
                            {...register("expect_end_date", { required: true })}
                          />
                        )}
                      />
                    </LocalizationProvider>:<LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        label="start Date"
                        value={watch("end_date")}
                        onChange={newValue => {
                          setValue("end_date", newValue);
                        }}
                        renderInput={params => (
                          <PrimaryNormalInputs
                            {...params}
                            error={errors?.end_date}
                            {...register("end_date", { required: true })}
                          />
                        )}
                      />
                    </LocalizationProvider>}
                  
                  </FormControl>
                </Grid>
                <Grid item md={12} lg={12}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                    <PrimaryNormalInputLabel
                      shrink
                      htmlFor="folloups-about-outlined"
                      error={errors.desc}
                    >
                      Description
                    </PrimaryNormalInputLabel>
                    <PrimaryNormalInputs
                      fullWidth
                      multiline
                      placeholder="Enter about your company"
                      {...register("desc", { required: true})}
                      id="login-about-outlined"
                      autoFocus
                      // label="about address"
                      type={"text"}
                      error={errors.desc}
                      variant="outlined"
                      rows={3}
                      value={watch("desc")}
                    />

                    {/* <div
                      style={{
                        ...(errors?.description
                          ? {
                              border: "0.5px solid red",
                              borderRadius: "8px",
                              padding: "10px",
                              minHeight: "250px",
                            }
                          : {
                              border: "0.5px solid gray",
                              borderRadius: "8px",
                              padding: "10px",
                              minHeight: "250px",
                            }),
                        marginTop: "23px",
                      }}
                    >
                      <Editor
                        editorState={watch("description")}
                        onEditorStateChange={handleEditorData}
                        sx={{ width: "100%" }}
                      />
                    </div> */}
                  </FormControl>
                </Grid>
                {/* <Grid item md={12} lg={12}>
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    <PrimaryNormalInputLabel
                      shrink
                      htmlFor="folloups-about-outlined"
                      error={errors.about}
                    >
                      Description
                    </PrimaryNormalInputLabel>
                    <div
                      style={{
                        ...(errors?.description
                          ? {
                              border: "0.5px solid red",
                              borderRadius: "8px",
                              padding: "10px",
                              minHeight: "250px",
                            }
                          : {
                              border: "0.5px solid gray",
                              borderRadius: "8px",
                              padding: "10px",
                              minHeight: "250px",
                            }),
                        marginTop: "23px",
                      }}
                    >
                      <Editor
                        editorState={watch("description")}
                        onEditorStateChange={handleEditorData}
                      />
                    </div>

                  </FormControl>
                </Grid> */}
                <Grid item xs={7}></Grid>
                <Grid item xs={5}>
                  <Stack spacing={1} direction="row">
                    <SecondaryFilledButton
                      onClick={() => {
                        toggleModel();
                      }}
                    >
                      cancel
                    </SecondaryFilledButton>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                  </Stack>{" "}
                </Grid>
              </Grid>
            </form>
          </Box>
        </Modal>
      </div>
    </>
  );
}
