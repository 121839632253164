import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { imageURL } from "common/constants/commonURLS";
import getSymbolFromCurrency from "currency-symbol-map";
import { useSelector } from "react-redux";
import {
  BlackTypography,
  PrimaryChip,
  GreyTypography,
} from "assets/Mui/globalTheme";

import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import copy from "copy-to-clipboard";

import JobLocationIcon from "assets/images/icons/JobLocationIcon";
import JobBookIcon from "assets/images/icons/JobBookIcon";
import JobClockIcon from "assets/images/icons/JobClockIcon";
import JobUsersIcon from "assets/images/icons/JobUsersIcon";
import JobBagIcon from "assets/images/icons/JobBagIcon";

import {
  Stack,
  Grid,
  Menu,
  MenuItem,
  Button,
  CircularProgress,
  Typography,
  Divider,
  Box,
  Avatar,
  Skeleton,
  Tooltip,
  Chip,
  IconButton,
  Checkbox,
  Card,
  CardContent,
} from "@mui/material";
import moment from "moment";
import JobInfoDescription from "./JobInfoDescription";
import { formatDistance, isBefore } from "date-fns";

function JobDetailsDescription({ jobDetails }) {
  const abbreviateNumber = number => {
    const scaledNumber = number / 1000;
    if (Number.parseFloat(scaledNumber).toFixed(0) > 0) {
      return `${Number.parseFloat(scaledNumber).toFixed(0)}k`;
    } else {
      return `${Number.parseFloat(scaledNumber).toFixed(0)}`;
    }
    // return `${scaledNumber.toFixed(0)}k`;
  };

  let token = localStorage.getItem("token");
  let userId = localStorage.getItem("user_id");

  const formattedDate = (deadLine, currentDate) => {
    const formattedDeadline = formatDistance(deadLine, currentDate);

    const isExpired = isBefore(deadLine, currentDate);
    if (isExpired) {
      let status = `has expired.`;
      return status;
    } else {
      let status = `${formattedDeadline} to apply`;
      return status;
    }
  };

  return (
    <React.Fragment>
      {jobDetails &&
        [jobDetails].map((item, index) => (
          <Stack
            sx={{
              width: "100%",
              height: "fit-content",
              minHeight: "100%",
              border: "0.8px solid #DEDEDE",
              borderRadius: "16px",
              padding: "24px",
              gap: "10px",
              background: "#fff",
              boxShadow: "0px 2px 54px -35px #0000008C",
              marginTop: "10px",
              marginLeft: "10px",
            }}
            // key={item?.id}
          >
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              alignContent={"flex-start"}
              sx={{ borderBottom: "1px solid #E9E9E9" }}
            >
              <Stack
                direction="row"
                spacing={1}
                alignContent={"flex-start"}
                sx={{
                  // display: "-webkit-box",
                  WebkitBoxOrient: "horizontal",
                  overflow: "hidden",
                  width: "100%",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  textOverflow: "ellipsis",
                }}
              >
                <Box
                  sx={{
                    height: "44px",
                    width: "44px",
                    borderRadius: "50%",
                    border: "0.5px solid rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <Avatar
                    src={`${imageURL}${item?.company_details?.company_image}`}
                    style={{
                      borderRadius: "50%",
                      borderColor: "rgba(0, 0, 0, 0.25)",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    {item?.company_details?.company_name?.charAt(0)}
                  </Avatar>
                </Box>
                <Box
                  sx={{
                    // flexShrink: 0,
                    overflow: "hidden",
                    textOverflow: "ellipsis",

                    cursor: "pointer",
                    textTransform: "capitalize",
                    fontSize: "15px",
                    fontWeight: "600",
                    color: "#121212",
                    fontFamily: "Inter,sans-serif",
                  }}
                  fontWeight="md"
                  textColor="primary.plainColor"
                  mb={0.5}
                >
                  <Typography
                  // noWrap
                  // onClick={() =>
                  //     navigate(
                  //         `/job?id=${item?.id}&userId=${userId ?? ""}`
                  //     )
                  // }
                  >
                    {item?.job_title?.length <= 20
                      ? item?.job_title
                      : item?.job_title}
                  </Typography>
                  <Typography
                    noWrap
                    fontWeight="md"
                    textColor="primary.plainColor"
                    mb={0.5}
                    sx={{
                      cursor: "pointer",
                      textTransform: "capitalize",
                      fontSize: "13px",
                      fontWeight: "400",
                      color: "#121212",
                      opacity: "50%",
                      fontFamily: "Inter,sans-serif",
                    }}
                    // onClick={() =>
                    //     navigate(
                    //         `/employer-single-list?id=${item?.company_user_id}`
                    //     )
                    // }
                  >
                    {item?.company_details?.company_name}
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row">
                <Box>
                  {/* <Typography
                    noWrap
                    fontWeight="md"
                    textColor="primary.plainColor"
                    mb={0.5}
                    sx={{
                      cursor: "pointer",
                      textTransform: "capitalize",
                      fontSize: "18px",
                      fontWeight: "Bold",
                      color: "#D48531",
                      // opacity: "50%",
                      fontFamily: "Inter,sans-serif",
                    }}
                  >
                    {getSymbolFromCurrency(item?.currency)
                      ? getSymbolFromCurrency(item?.currency)
                      : ""}{" "}
                    {item?.max_sal <= 1000
                      ? item?.max_sal
                      : abbreviateNumber(
                          (item.max_sal * item.commission_rate) / 100
                        )}
                  </Typography>
                  <Typography
                    noWrap
                    fontWeight="md"
                    textColor="primary.plainColor"
                    mb={0.5}
                    sx={{
                      cursor: "pointer",
                      textTransform: "capitalize",
                      fontSize: "13px",
                      fontWeight: "500",
                      color: "#121212",
                      fontFamily: "Inter,sans-serif",
                    }}
                  >
                    Earn Upto
                  </Typography> */}
                </Box>
              </Stack>
            </Stack>
            <Stack
              sx={{
                gap: "10px",
                "& hr": {
                  height: "22px",
                },
              }}
            >
              <Stack
                spacing={1}
                sx={{
                  WebkitBoxOrient: "horizontal",
                  overflow: "hidden",
                  width: "100%",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  textOverflow: "ellipsis",
                }}
                direction="row"
              >
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{
                    flexShrink: 0,
                    overflow: "hidden",
                    gap: "5px",
                    textOverflow: "ellipsis",
                  }}
                >
                  <JobBagIcon />
                  <Typography
                    noWrap
                    sx={{ fontSize: "14px", fontWeight: "500" }}
                  >
                    {`${item?.min_exp}${
                      item?.min_exp_months > 0 ? `.${item?.min_exp_months}` : ""
                    }`}
                    -
                    {`${item?.max_exp}${
                      item?.max_exp_months > 0 ? `.${item?.max_exp_months}` : ""
                    }`}
                    Yrs
                  </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{
                    flexShrink: 0,
                    overflow: "hidden",
                    gap: "5px",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Typography
                    noWrap
                    sx={{ fontSize: "14px", fontWeight: "500" }}
                  >
                    {item?.job_type?.replace("_", " ")}
                  </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                  sx={{
                    flexShrink: 0,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Typography
                    noWrap
                    sx={{ fontSize: "14px", fontWeight: "500" }}
                  >
                    <span style={{ fontSize: "15px", fontWeight: "600" }}>
                      {" "}
                      {getSymbolFromCurrency(item?.currency)
                        ? getSymbolFromCurrency(item?.currency)
                        : ""}{" "}
                    </span>
                    {item?.min_sal_by_am <= 1000
                      ? item?.min_sal_by_am
                      : `${Math.round(item?.min_sal_by_am / 1000, 2)}k`}
                    -
                    {item?.max_sal_by_am <= 1000
                      ? item?.max_sal
                      : `${Math.round(item?.max_sal_by_am / 1000, 2)}k`}
                    {/* {`/${item?.payout}`} */}
                  </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                  sx={{
                    // flex: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    gap: "5px",
                  }}
                >
                  <JobLocationIcon />
                  <Typography
                    noWrap
                    sx={{ fontSize: "14px", fontWeight: "500" }}
                  >
                    {item?.location}
                  </Typography>
                </Stack>
              </Stack>

              <Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                  sx={{
                    // flex: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    gap: "5px",
                  }}
                >
                  <JobBookIcon />
                  <Typography
                    noWrap
                    sx={{ fontSize: "14px", fontWeight: "500" }}
                  >
                    {item?.educational_qualification_details?.map(
                      (elem, index) => (
                        <span key={index}>
                          {elem?.education_qualification_spec_id?.name}/
                          {elem?.education_qualification_id?.name}.{" "}
                        </span>
                      )
                    )}
                  </Typography>
                </Stack>
              </Stack>
              <Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignContent="center"
                  spacing={1}
                  sx={{
                    // flex: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Typography noWrap>
                    {item?.key_skills_details?.map(each => (
                      <>
                        &#x2022;{" "}
                        <span
                          style={{
                            backgroundColor: "#E9F0EE",
                            borderRadius: "4px",
                            fontSize: "14px",
                            padding: "3px",
                          }}
                        >
                          {each?.name}
                        </span>{" "}
                      </>
                    ))}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                borderBottom: "0.8px dashed #BABABA",
                paddingBottom: "15px",
              }}
            >
              <Stack
                spacing={1}
                sx={{
                  WebkitBoxOrient: "horizontal",
                  overflow: "hidden",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  textOverflow: "ellipsis",
                }}
                direction="row"
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  sx={{
                    flexShrink: 0,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    gap: "5px",
                  }}
                >
                  <JobClockIcon />
                  <Typography
                    noWrap
                    sx={{ fontSize: "13.5px", fontWeight: "500" }}
                  >
                    {/* {Math.floor(
                                        Math.abs(
                                            new Date() -
                                            new Date(
                                                item?.createdAt?.slice(0, 10)
                                                    .toLocaleString()
                                                    .replace(/-/g, "/")
                                            )
                                        ) /
                                        (1000 * 3600 * 24)
                                    )} days ago */}
                    {formatDistance(new Date(item.createdAt), new Date(), {
                      addSuffix: true,
                    })}{" "}
                  </Typography>
                </Stack>
                <span>&#x2022; </span>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  sx={{
                    flexShrink: 0,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    gap: "5px",
                  }}
                >
                  <JobUsersIcon />
                  <Typography
                    noWrap
                    sx={{ fontSize: "13.5px", fontWeight: "500" }}
                  >
                    {" "}
                    {`${item?.applicants_count}`} Applicants
                  </Typography>
                </Stack>
                <span>&#x2022; </span>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  sx={{
                    // flex: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Typography
                    noWrap
                    sx={{ fontSize: "13.5px", fontWeight: "500" }}
                  >
                    {formattedDate(
                      new Date(item.application_deadline),
                      new Date()
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack>
              <JobInfoDescription jobDetails={jobDetails} />
            </Stack>
            <Stack pt={2}>
              <Typography
                sx={{
                  paddingBottom: "12px",
                  color: "#000000",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                About Company
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                {item?.company_details?.about ?? "N/A"}
              </Typography>
            </Stack>

            <Stack pt={2}>
              <Typography
                sx={{
                  paddingBottom: "12px",
                  color: "#000000",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                Job Insights
              </Typography>

              <div
                style={{
                  marginBlock: "12px",
                  display: "grid",
                  gridTemplateColumns: "repeat(2,1fr)",
                  paddingInline: "10px",
                  gap: "6px",
                }}
              >
                <BlackTypography
                  sx={{ fontWeight: "500 !important" }}
                  variant="h6"
                >
                  Experience level
                </BlackTypography>
                <GreyTypography variant="h6">
                  {jobDetails?.new_job_level?.name ?? "N/A"}
                </GreyTypography>
                <BlackTypography
                  sx={{ fontWeight: "500 !important" }}
                  variant="h6"
                >
                  Job Type
                </BlackTypography>
                <GreyTypography variant="h6">
                  {jobDetails?.job_type?.replace("_", " ")}
                </GreyTypography>

                <BlackTypography
                  sx={{ fontWeight: "500 !important" }}
                  variant="h6"
                >
                  Vacancies
                </BlackTypography>
                <GreyTypography variant="h6">
                  {jobDetails?.no_of_vacancies ?? "N/A"}
                </GreyTypography>
                <BlackTypography
                  sx={{ fontWeight: "500 !important" }}
                  variant="h6"
                >
                  Work Mode
                </BlackTypography>
                <GreyTypography variant="h6">
                  {jobDetails?.work_mode?.replace("_", " ")}
                </GreyTypography>
                <BlackTypography
                  sx={{ fontWeight: "500 !important" }}
                  variant="h6"
                >
                  Location
                </BlackTypography>
                <GreyTypography variant="h6">
                  {jobDetails?.location ?? "N/A"}
                </GreyTypography>

                <BlackTypography
                  sx={{ fontWeight: "500 !important" }}
                  variant="h6"
                >
                  Payout
                </BlackTypography>
                <GreyTypography variant="h6">
                  {jobDetails?.payout?.replace("_", "")}
                </GreyTypography>

                <BlackTypography
                  sx={{ fontWeight: "500 !important" }}
                  variant="h6"
                >
                  Applied
                </BlackTypography>
                <GreyTypography variant="h6">
                  {jobDetails?.applicants_count ?? "N/A"}
                </GreyTypography>

                <BlackTypography
                  sx={{ fontWeight: "500 !important" }}
                  variant="h6"
                >
                  Citizenship Status
                </BlackTypography>
                {/* <GreyTypography variant="h6">{jobPostInfo?.citizenship_status[0]}</GreyTypography> */}
                {/* <GreyTypography variant="h6">{jobPostInfo?.citizenship_status?.map((each)=>each)}</GreyTypography> */}

                <GreyTypography variant="h6">
                  {Array.isArray(jobDetails?.citizenship_status)
                    ? jobDetails.citizenship_status.join(", ")
                    : "N/A"}
                </GreyTypography>

                {jobDetails?.job_post_commission && (
                  <>
                    {jobDetails?.job_post_commission?.map(each => (
                      <>
                        <BlackTypography
                          sx={{ fontWeight: "500 !important" }}
                          variant="h6"
                        >
                          Commission/{each?.payout.replace("_", " ")}
                        </BlackTypography>
                        <GreyTypography variant="h6">
                          {each?.flat_rate
                            ? `${getSymbolFromCurrency(jobDetails?.currency)} ${
                                each?.flat_rate
                              }`
                            : "N/A"}
                        </GreyTypography>
                      </>
                    ))}
                  </>
                )}

                <BlackTypography
                  sx={{ fontWeight: "500 !important" }}
                  variant="h6"
                >
                  Application Deadline
                </BlackTypography>
                <GreyTypography variant="h6">
                  {moment(jobDetails?.application_deadline).format("ll")}
                </GreyTypography>
              </div>
            </Stack>
          </Stack>
        ))}
    </React.Fragment>
  );
}

export default JobDetailsDescription;
