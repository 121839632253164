const { Row, Container, Col, CardBody, Card } = require("reactstrap");
import { updateSingleAdminStaffDetails } from "helpers/services/adminStaff/adminStaff";
import {
  FormControlLabel,
  FormGroup,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  createIndustries,
  deleteIndustries,
  getAllIndustries,
  updateIndustries,
} from "helpers/services/jobAttributes/industries";
import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import CommonModel from "../../components/Common/models/createModel";
import { PrimaryButton, PrimarySwitch,PrimaryDataGrid } from "assets/Mui/globalTheme";
import {
  createActualJobLevels,
  getAllActualJobLevel,
  updateActualJobLevel,
} from "helpers/services/jobAttributes/actualJobLevel";
import BubbleLoader from "common/data/NoRowsFoundDatagrid/BubbleLoader";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress, Stack,Pagination } from "@mui/material";
import { StageSearchInput, GreyTypography } from "assets/Mui/globalTheme";
import CustomPagination from "components/Pagination/globalPagination";
import SearchParamHook from "hooks/searchParams";
import { useLocation, useHistory } from "react-router-dom";
import { useDebounce } from "hooks/useDebounce";
import CustomNoRowsOverlay from "common/data/NoRowsFoundDatagrid/NoRowsFound";
import GlobalTableTools from "hooks/GlobalTableTools";

import LoaderIcon from "utils/icons/loaderIcon";
import TablePopper from "components/table/header/popper";


const JobLevel = () => {
  const [state, setState] = useState({
    isOpen: false,
    viewAddModal:false,
    active: false,
    index: -1,
    default: false,
    name: "",
    id: "",
    data: [],
    isLoading: true,
    openSearch: true,
    searchRole: "",
  });

  

  const searchParamsHook = SearchParamHook();
  const { search } = useLocation();
  const history = useHistory();
  const currentPage = searchParamsHook.get('page')
  const searchTextParam = searchParamsHook.get('searchParam')
  const [page, setPage] = React.useState(currentPage ?? 1);
  const [searchText, setSearchText] = useState(searchTextParam ?? "");
  const [totalCount, setTotalCount] = React.useState("");
  const rowsPerPage = 20;

  const toggle = (purpose = "", name = "", active = "", id = "", index) => {
    setState(prevState => ({
      ...prevState,
      purpose,
      name,
      index,
      id,
      active,
      isOpen: !prevState.isOpen,
    }));

    setViewAddModal(!viewAddModal);

  };

  const [viewAddModal, setViewAddModal] = React.useState(false);

  const handleDelete = (index, id, name) => {
    updateActualJobLevel({ is_active: false, name }, id);
    const result = state.data.map(each =>
      each.id === id ? { ...each, is_active: !each.is_active } : { ...each }
    );

    setState(prevState => ({ ...prevState, data: result }));
    // Other logic...
  };

  const handlRestore = (item, index) => {
    const sample = {
      name: item.industries,
      is_active: !item.is_active,
    };
    updateActualJobLevel(sample, item.id);

    const result = state.data.map(each =>
      each.id === item.id
        ? { ...each, is_active: !each.is_active }
        : { ...each }
    );

    setState(prevState => ({ ...prevState, data: result }));
    // Other logic...
  };

  const handleAction = (item, index) => {
    item.is_active
      ? handleDelete(index, item.id, item.industries)
      : handlRestore(item, index);
  };

  const updateFunc = async (sample, type, index, id) => {
    let editedId;
    if (id) {
      editedId = id;
    } else {
      editedId = state.id;
    }

    if (type === "Create") {
      await createActualJobLevels(sample);
      // setState(prevState => ({ ...prevState, data: [...state?.data, sample] }));
      // toast.success("Created");
      const currentPage = searchParamsHook.get("page") ?? page;
      const { data } = await getAllActualJobLevel(
        currentPage,
        rowsPerPage,
        searchText
      );
      const joblevelsData = data?.data_payload;

      const result =
      data?.data_payload?.length > 0 &&
      data?.data_payload?.map((item, index) => ({
        id: item?.id,
      
        industries: `${
          item?.name
        }`,
  
      
        is_active: item.is_active,
      }));

      setState(prevState => ({ ...prevState, data: result }));
    } else {
      const changeData = {
        name: sample?.name,
        is_active: sample?.is_Active,
      };
      await updateActualJobLevel(changeData, editedId);
      let result = state.data;
      const filterOne = result?.map(each =>
        each.id === editedId
          ? { ...each, name: sample.name, is_active: sample.is_active }
          : { ...each }
      );
      const currentPage = searchParamsHook.get("page") ?? page;  
      // setState(prevState => ({ ...prevState, data: filterOne }));
      const { data } = await getAllActualJobLevel(
        currentPage,
        rowsPerPage,
        searchText
      );
      // setState((prevState) => ({ ...prevState, data }));
      const joblevelsData = data?.data_payload;
      const resultR =
      data?.data_payload?.length > 0 &&
      data?.data_payload?.map((item, index) => ({
        id: item?.id,
      
        industries: `${
          item?.name
        }`,
  
      
        is_active: item.is_active,
      }));
      setState(prevState => ({ ...prevState, data: resultR}));
    }
  };

  const handleSearchOpen = () => {
    setState(prevState => ({
      ...prevState,
      openSearch: !prevState.openSearch,
      searchRole: "",
    }));
  };

  const handleSearch = e => {
    setState(prevState => ({ ...prevState, searchRole: e.target.value }));
  };

  const fetchData = async (page, rowsPerPage, searchText) => {
    try {
      setState(prevState => ({ ...prevState, isLoading: true }));
      const currentPage = searchParamsHook.get("page") ?? page;
      const searchTextParam = searchText ?? ''; // Ensure searchTextParam is defined
  
      const { data } = await getAllActualJobLevel(currentPage, rowsPerPage, searchTextParam);
  
      const joblevelsData = data?.data_payload;
  
      const result =
        joblevelsData?.length > 0 &&
        joblevelsData.map((item) => ({
          id: item?.id,
          industries: item?.name ?? '',
          is_active: item.is_active,
        }));
  
      setState(prevState => ({
        ...prevState,
        data: result || [],
        isLoading: false,
      }));
  
      setTotalCount(data?.total_page_size || 0);
    } catch (error) {
      console.error("Error fetching data:", error);
      setState(prevState => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };
  

  useEffect(() => {
  

    fetchData(page, rowsPerPage, searchText);
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    searchParams.set("searchParam", searchText);
    history.push({
      search: searchParams.toString(),
    });

    // const fetchData = async () => {
    //   setState(prevState => ({ ...prevState, isLoading: true }));
    //   const currentPage = searchParamsHook.get("page") ?? page;
    //   const { data } = await getAllActualJobLevel(
    //     currentPage,
    //     rowsPerPage,
    //     searchText
    //   );
    //   const joblevelsData = data?.data_payload;
    //   setState(prevState => ({
    //     ...prevState,
    //     data: joblevelsData,
    //     isLoading: false,
    //   }));
    //   setTotalCount(data?.total_page_size);
    //   setState(prevState => ({ ...prevState, isLoading: false }));
    // };

    fetchData();
  }, [searchParamsHook.get("page"), searchParamsHook.get("searchParam")]);

  const debouncedQuery = useDebounce(searchText, 500);
  useEffect(() => {
    if(debouncedQuery){
      const searchParams = new URLSearchParams(search);
      searchParams.set("searchParam", debouncedQuery);
      searchParams.set("page",1)
      history.push({
        search: searchParams.toString(),
      });
    }
    else {
      const searchParams = new URLSearchParams(search);
      searchParams.set("searchParam", debouncedQuery);
      // setPage(1)
      history.push({
        search: searchParams.toString(),
      });
    }
 

  }, [debouncedQuery]);



    /*Columns Creation@@ */


    React.useEffect(() => {
      const searchParams = new URLSearchParams(search);
      searchParams.set("page", page);
      history.push({
        search: searchParams.toString(),
      });
    
      fetchData();
    }, [page, searchParamsHook.get("searchParam")]);
  
    const columns = [
    
      { field: "industries", headerName: "Experience levels",  headerClassName: "super-app-theme--header", width: 300 },
      {
        field: "is_active",
        headerName: "Status",
        headerClassName: "super-app-theme--header",
        width: 200,
        renderCell: (params) => {
          const rowIndex = state.data.findIndex(row => row.id === params.row.id);
  
          return(
            <PrimarySwitch
            sx={{ m: 1 }}
            checked={params.row.is_active}
            onChange={async (e) => {
              handleAction(params.row, rowIndex);
            }}
          />
          )
        
        },
      },
   
     
  
      {
        field: "Action",
        headerName: "Action",
        headerClassName: "super-app-theme--header",
        width: 600,
      
        renderCell: params => {
        
          const index = state.data.findIndex(row => row.id === params.row.id);
          return (
            <PrimaryButton
            sx={{ width: '100px' }}
            onClick={() => toggle('Edit', params.row.industries, params.row.is_active, params.row.id, index)}
          >
            Edit
          </PrimaryButton>
          );
        },
      },
  
  
  
      
    ];
  
  
    const filteredColumns = columns.filter(Boolean);




  // return (
  //   <>
  //     <CommonModel
  //       isOpen={state.isOpen}
  //       toggle={toggle}
  //       headTag={"Job Levels"}
  //       purpose={state.purpose}
  //       index={state.index}
  //       title={state.name}
  //       active={state.active}
  //       update={updateFunc}
  //       id={state.id}
  //     />
  //     <div md={6} style={{ padding: "40px" }} className="header_jobs_sticky">
  //       <PrimaryButton sx={{ width: "200px" }} onClick={() => toggle("Create")}>
  //         Create Job Levels
  //       </PrimaryButton>
  //       <Stack alignItems={"center"} direction={"row"} spacing={1.6}>
  //         {state.openSearch ? (
  //           <SearchIcon onClick={handleSearchOpen} />
  //         ) : (
  //           <StageSearchInput
  //             placeholder="search..."
  //             value={searchText}
  //             // onChange={(e) => handleSearch(e)}
  //             onChange={e => {
  //               e.stopPropagation();
  //               setSearchText(e.target.value);
  //             }}
  //             endAdornment={
  //               <IconButton
  //                 edge="end"
  //                 // onClick={handleSearchOpen}
  //                 onClick={() => {
  //                   handleSearchOpen();
  //                   setSearchText("");
  //                 }}
  //               >
  //                 <ClearIcon />
  //               </IconButton>
  //             }
  //           />
  //         )}
  //       </Stack>
  //     </div>
  //     <Row style={{ marginLeft: "20px",marginTop:"8px" }}>
  //       <div className="table_cont">
  //         <div className="table-responsive">
  //           <Card style={{ maxWidth: "80vw" }}>
  //             <CardBody
  //               style={{ height: "calc(100vh - 270px)", overflowY: "scroll" }}
  //             >
  //               <Table className="table mb-0">
  //                 <TableHead>
  //                   <TableRow>
  //                     <TableCell>Job Levels</TableCell>
  //                     <TableCell>Status</TableCell>
  //                     <TableCell>Action</TableCell>
  //                   </TableRow>
  //                 </TableHead>
  //                 <tbody>
  //                   {state.isLoading ? (
  //                     <BubbleLoader />
  //                   ) : // state?.data?.map((item, index) => (
  //                   //   <TableRow key={item.name + index}>
  //                   //     <TableCell>{item.name}</TableCell>

  //                   //     <TableCell className="active_td">
  //                   //       <FormGroup>
  //                   //         <FormControlLabel
  //                   //           control={
  //                   //             <PrimarySwitch
  //                   //               sx={{ m: 1 }}
  //                   //               checked={item.is_active}
  //                   //               onChange={async (e) => {
  //                   //                 handleAction(item, index);
  //                   //               }}
  //                   //             />
  //                   //           }
  //                   //         />
  //                   //       </FormGroup>
  //                   //     </TableCell>
  //                   //     <TableCell className="col_btn">
  //                   //       <PrimaryButton
  //                   //         sx={{ width: '100px' }}
  //                   //         onClick={() => toggle('Edit', item.name, item.active, item.id, index)}
  //                   //       >
  //                   //         Edit
  //                   //       </PrimaryButton>
  //                   //     </TableCell>
  //                   //   </TableRow>
  //                   // ))

  //                   state?.data?.length > 0 ? (
  //                     state?.data?.map((item, index) => (
  //                       <TableRow key={item.name + index}>
  //                         <TableCell>{item.name}</TableCell>

  //                         <TableCell className="active_td">
  //                           <FormGroup>
  //                             <FormControlLabel
  //                               control={
  //                                 <PrimarySwitch
  //                                   sx={{ m: 1 }}
  //                                   checked={item.is_active}
  //                                   onChange={async e => {
  //                                     handleAction(item, index);
  //                                   }}
  //                                 />
  //                               }
  //                             />
  //                           </FormGroup>
  //                         </TableCell>
  //                         <TableCell className="col_btn">
  //                           <PrimaryButton
  //                             sx={{ width: "100px" }}
  //                             onClick={() =>
  //                               toggle(
  //                                 "Edit",
  //                                 item.name,
  //                                 item.active,
  //                                 item.id,
  //                                 index
  //                               )
  //                             }
  //                           >
  //                             Edit
  //                           </PrimaryButton>
  //                         </TableCell>
  //                       </TableRow>
  //                     ))
  //                   ) : (
  //                     <TableRow>
  //                       <TableCell colSpan={5} align="center">
  //                         <CustomNoRowsOverlay />
  //                       </TableCell>
  //                     </TableRow>
  //                   )}
  //                 </tbody>
  //               </Table>
  //             </CardBody>
  //           </Card>
  //         </div>
  //       </div>
  //       <CustomPagination count={totalCount} page={page} setPage={setPage} />
  //     </Row>
  //   </>
  // );


  return(
    <>     <CommonModel
        // isOpen={isOpen}
        toggle={toggle}
        isOpen={viewAddModal}
        viewAddModal={viewAddModal}
        setViewAddModal={setViewAddModal}
        headTag={'Experience levels'}
        purpose={state.purpose}
        index={state.index}
        id={state.id}
        title={state.name}
        active={state.active}
        update={updateFunc}
      />


    <div
    style={{
      padding: "10px",
      height: "91vh",
      display: "flex",
      flexDirection: "column",
    }}
  >
    
      <GlobalTableTools  tableHeading={"Experience levels"}
       toggel={true}
       toggleFunc={toggle}
       isJobAttributes={true}
       setViewAddModal={setViewAddModal} 
       downloadOption={false}
        />
    <PrimaryDataGrid
     
      fileName="Experience levels"
      // onCellClick={handleCellClick}
      loading={state.isLoading}
     

      columns={filteredColumns}
      rows={state.data}
      slots={{
        loadIcon: LoaderIcon,
        baseButton: PrimaryButton,
        basePopper: TablePopper,
        baseSwitch: PrimarySwitch,
    
        // toolbar:CustomToolbar,
        noRowsOverlay: CustomNoRowsOverlay,
        pagination: props => (
          <Pagination
            {...props}
            // color="primary"
            // count={Math.ceil(rowsData.length / rowsPerPage)}
            count={totalCount}
            page={page}
            rowsPerPage={rowsPerPage}
            onChange={(_, newPage) => setPage(newPage)}
            showFirstButton
            showLastButton
            sx={{
              "& .MuiPaginationItem-root": {
                "&:hover": {
                  backgroundColor: "#cde2e7",
                  color: "#066a4c",
                },
                "&.Mui-selected": {
                  backgroundColor: "#024430",
                  color: "#ffe7bb",
                  "&:hover": {
                    backgroundColor: "#cde2e7",
                    color: "#066a4c",
                  },
                },
              },
            }}
          />
        ),
      }}
      slotProps={{
        baseButton: { style: { width: "100px" } },
        panel: {
          style: {
            right: "0px !important",
            transform: "none !important",
            inset: "none !important",
            top: "138px !important",
          },
        },
        filterPanel: {
          style: {
            right: "0px !important",
            transform: "none !important",
            inset: "none !important",
            top: "138px !important",
          },
        },
        toolbar: { style: {} },
        preferencesPanel: {
          style: {
            right: "0px !important",
            transform: "none !important",
            inset: "none !important",
            top: "138px !important",
          },
        },
      }}
      sx={{
        "& .super-app-theme--header": {
          backgroundColor: "rgba(250, 250, 250, 1)",
        },
      }}
    />
   
  </div>


  </>
  )

};

export default JobLevel;
