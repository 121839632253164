import React, { useState, useEffect } from 'react';
import {
  Row,
  Container,
  Col,
  Card,
  CardBody,
} from 'reactstrap';
import { Table, TableCell, TableHead, TableRow } from "@mui/material";
import {
  getAllEducationalQualifications,
  getAllEducationalSpecialization,
  updateEducationalSpecialization,
} from 'helpers/services/jobAttributes/educationalQualifications';
import { FormControlLabel, FormGroup } from '@mui/material';
import { PrimaryButton, PrimarySwitch,PrimaryDataGrid } from 'assets/Mui/globalTheme';
import CreateEducationalQualification from './createModel.js/create';
import { toast } from 'react-hot-toast';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { CircularProgress, Stack,Pagination } from '@mui/material';
import { StageSearchInput, GreyTypography } from 'assets/Mui/globalTheme';
import SearchIcon from '@mui/icons-material/Search';

import { useParams,useHistory } from "react-router-dom";
import SearchParamHook from "hooks/searchParams";
import { useLocation } from "react-router-dom";
import { useDebounce } from "hooks/useDebounce";
import CustomNoRowsOverlay from "common/data/NoRowsFoundDatagrid/NoRowsFound";
import CustomPagination from 'components/Pagination/globalPagination';
import GlobalTableTools from "hooks/GlobalTableTools";
import LoaderIcon from "utils/icons/loaderIcon";
import TablePopper from "components/table/header/popper";

const Specialization = () => {


  const searchParamsHook = SearchParamHook();
  const { search } = useLocation();
  const history = useHistory();
  const [viewAddModal, setViewAddModal] = React.useState(false);

  const currentPage = searchParamsHook.get('page')
  const searchTextParam = searchParamsHook.get('searchParam')
  const [page, setPage] = React.useState(currentPage ?? 1);
  const [searchText, setSearchText] = useState(searchTextParam ?? "");

  const [totalCount, setTotalCount] = React.useState("");
  const rowsPerPage = 20;
  const searchParams = SearchParamHook()
  const [isLoading, setIsLoading] = useState(true);


  const [isOpen, setIsOpen] = useState(false);
  const [qualification, setQualification] = useState([]);
  const [data, setData] = useState([]);
  const [purpose, setPurpose] = useState('');
  const [name, setName] = useState('');
  const [id, setId] = useState('');
  const [index, setIndex] = useState(-1);
  const [educationalQualificationId, setEducationalQualificationId] = useState('');
  const [openSearch, setOpenSearch] = useState(true);
  const [searchRole, setSearchRole] = useState('');



  const toggle = (
    purpose = '',
    name = '',
    id = '',
    index = '',
    educationalQualificationId = ''
  ) => {
    setPurpose(purpose);
    setName(name);
    setId(id);
    setIndex(index);
    setIsOpen(!isOpen);
    setEducationalQualificationId(educationalQualificationId);
    setViewAddModal(!viewAddModal);
  };

  const handleAction = (item, index) => {
    let result = [...data];
    updateEducationalSpecialization(result[index].id, {
      educational_qualification_id: result[index].educational_qualification_id,
      name: result[index].industries,
      is_active: !result[index].is_active,
    });

    const filterOne = result?.map((each) => {
      if (each.id === item.id) {
        return {
          ...each,
          is_active: !each.is_active,
        };
      } else {
        return {
          ...each,
        };
      }
    });

    const statusCheck = result?.filter((each) => {
      if (each?.id === item.id) {
        return {
          ...each,
          is_active: !item.is_active,
        };
      }
    });

    setData(filterOne);

    if (statusCheck && statusCheck[0]?.is_active) {
      toast.error('disabled');
    } else {
      toast.success('enabled');
    }
  };

  /* Search toggle effect */
  const handleSearchOpen = () => {
    setOpenSearch(!openSearch);
    setSearchRole('');
  };

  const handleSearch = (e) => {
    setSearchRole(e.target.value);
  };

  const searchFilterData = (data) => {
    const searchWord = searchRole?.toLowerCase();
    const searchPattern = data?.filter((item) => {
      const roleName = item?.name?.toLowerCase();
      const categoryName = item?.job_attribute_functional_area?.name?.toLowerCase();

      if (roleName?.includes(searchWord) || categoryName?.includes(searchWord)) {
        return item;
      }
    });
    return searchPattern;
  };

  // const filteredData = searchFilterData(data);



  // const fetchData = async () => {
  //   const qualificationData = await getAllEducationalQualifications(page, rowsPerPage,searchText);
  //   const items = qualificationData?.data_payload?.map((item) => {
  //     return { value: item.id, label: item.name };
  //   });
  //   const specializationData = await getAllEducationalSpecialization(page, rowsPerPage,searchText);
  //   setData(specializationData?.data_payload);
  //   setTotalCount(specializationData?.total_page_size)
  //   setQualification(items);
  // };


   const fetchData = async () => {
    setIsLoading(true);
    const currentPage = searchParamsHook.get("page") ?? page
    const qualificationData = await getAllEducationalQualifications(page, rowsPerPage,searchTextParam);
    const items = qualificationData?.data_payload?.map((item) => {
      return { value: item.id, label: item.name };
    });
    const specializationData = await getAllEducationalSpecialization(page, rowsPerPage,searchTextParam);

    const result =
    specializationData?.data_payload?.length > 0 &&
    specializationData?.data_payload?.map((item, index) => ({
      id: item?.id,
    
      industries: `${
        item?.name
      }`,

      qualification:`${item?.job_attribute_educational_qualification?.name}`,
      educational_qualification_id:`${item?.job_attribute_educational_qualification?.id}`,
    
      is_active: item.is_active,
    }));

    // setData(specializationData?.data_payload);
    setData(result)
    setQualification(items);
    setTotalCount(specializationData?.total_page_size)
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);



 


  useEffect(()=>{

    const searchParams = new URLSearchParams(search);
    searchParams.set("searchParam", searchText);
    history.push({
      search: searchParams.toString(),
    });

   

    fetchData();
  },[searchParamsHook.get("page"), searchParamsHook.get("searchParam")])


  const debouncedQuery = useDebounce(searchText, 500);


useEffect(() => {
  if(debouncedQuery){
    const searchParams = new URLSearchParams(search);
    searchParams.set("searchParam", debouncedQuery);
    searchParams.set("page",1)
    history.push({
      search: searchParams.toString(),
    });
  }
  else {
    const searchParams = new URLSearchParams(search);
    searchParams.set("searchParam", debouncedQuery);
    // setPage(1)
    history.push({
      search: searchParams.toString(),
    });
  }


}, [debouncedQuery]);



  /*Columns Creation@@ */


  React.useEffect(() => {
    const searchParams = new URLSearchParams(search);
    searchParams.set("page", page);
    history.push({
      search: searchParams.toString(),
    });
  
    fetchData();
  }, [page, searchParamsHook.get("searchParam")]);

  const columns = [
  
    { field: "industries", headerName: "Specialization",  headerClassName: "super-app-theme--header", width: 300 },
    { field: "qualification", headerName: "Qualification",  headerClassName: "super-app-theme--header", width: 300 },
    {
      field: "is_active",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: (params) => {
        const rowIndex = data.findIndex(row => row.id === params.row.id);

        return(
          <PrimarySwitch
          sx={{ m: 1 }}
          checked={params.row.is_active}
          onChange={async (e) => {
            handleAction(params.row, rowIndex);
          }}
        />
        )
      
      },
    },
 
   

    {
      field: "Action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      width: 600,
    
      renderCell: params => {

        const index = data.findIndex(row => row.id === params.row.id);
        return (
          <PrimaryButton
          sx={{ width: '100px' }}
          onClick={() => toggle(
            'Edit', params.row.industries,params.row.id, index,params.row.educational_qualification_id

            // "Edit",
            // params.row.industries,
            // params.row.id,
            // index,
            // params.row.job_attribute_educational_qualification.educational_qualification_id

            )}
        >
          Edit
        </PrimaryButton>
        );
      },
    },



    
  ];


  const filteredColumns = columns.filter(Boolean);




  // return (
  //   <Container fluid>
  //     <CreateEducationalQualification
  //       isOpen={isOpen}
  //       toggle={toggle}
  //       qualification={qualification}
  //       specData={data}
  //       purpose={purpose}
  //       name={name}
  //       id={id}
  //       updated={fetchData}
  //       index={index}
  //       educational_qualification_id={educationalQualificationId}
  //     />

  //     {/* end */}
  //     <Row>
  //       <Row className="align-items-center ">
  //         <Col md={12} lg={12} style={{ padding: '40px' }} className="header_jobs_sticky">
  //           <PrimaryButton
  //             sx={{
  //               width: '245px',
  //             }}
  //             onClick={() => toggle('Create')}
  //           >
  //             Create Educational Specialization
  //           </PrimaryButton>

  //           <Stack alignItems={'center'} direction={'row'} spacing={1.6}>
  //             {openSearch ? (
  //               <SearchIcon onClick={handleSearchOpen} />
  //             ) : (
  //               <StageSearchInput
  //                 placeholder="search..."
  //                 // onChange={(e) => handleSearch(e)}
  //                 value={searchText}
  //                 onChange={(e) => {
  //                   e.stopPropagation();
  //                   setSearchText(e.target.value);
  //                 }}
  //                 endAdornment={
  //                   <IconButton edge="end" 
  //                   // onClick={handleSearchOpen}
  //                   onClick={() => {
  //                     handleSearchOpen();
  //                     setSearchText("");
  //                   }}
  //                   >
  //                     <ClearIcon />
  //                   </IconButton>
  //                 }
  //               />
  //             )}
  //           </Stack>
  //         </Col>
  //       </Row>
  //       <div className="table_cont">
  //         <div className="table-responsive">
  //           <Card style={{ maxWidth: '80vw' }}>
  //             <CardBody>
  //               <Table className="table mb-0">
  //                 <TableHead>
  //                   <TableRow>
  //                     <TableCell>Specialization</TableCell>
  //                     <TableCell>Qualification</TableCell>
  //                     <TableCell>status</TableCell>
  //                     <TableCell>Action</TableCell>
  //                   </TableRow>
  //                 </TableHead>
  //                 <tbody>
  //                   {
  //                   data?.length > 0?
  //                   data?.map((item, index) => (
  //                     <TableRow key={item.name + index}>
  //                       <TableCell>{item.name}</TableCell>
  //                       <TableCell>{item?.job_attribute_educational_qualification?.name}</TableCell>

  //                       <TableCell className="active_td">
  //                         <FormGroup>
  //                           <FormControlLabel
  //                             control={
  //                               <PrimarySwitch
  //                                 sx={{ m: 1 }}
  //                                 checked={item.is_active}
  //                                 onChange={async (e) => {
  //                                   handleAction(item, index);
  //                                 }}
  //                               />
  //                             }
  //                           />
  //                         </FormGroup>
  //                       </TableCell>
  //                       <TableCell className="col_btn">
  //                         <PrimaryButton
  //                           sx={{ width: '100px' }}
  //                           onClick={() =>
  //                             toggle(
  //                               'Edit',
  //                               item.name,
  //                               item.id,
  //                               index,
  //                               item.educational_qualification_id
  //                             )
  //                           }
  //                         >
  //                           Edit
  //                         </PrimaryButton>
  //                       </TableCell>
  //                     </TableRow>
  //                   ))
  //                 :
  //                 (<TableRow>
  //                   <TableCell colSpan={5} align="center">
  //                     <CustomNoRowsOverlay />
  //                   </TableCell>
  //                 </TableRow>)
  //                 }
  //                 </tbody>
  //               </Table>
  //             </CardBody>
  //           </Card>
  //         </div>
  //       </div>
  //       <CustomPagination count={totalCount} page={page} setPage={setPage} />
  //     </Row>
  //   </Container>
  // );


  return (
    <>     
    <CreateEducationalQualification
        // isOpen={isOpen}
        toggle={toggle}
        isOpen={viewAddModal}
        viewAddModal={viewAddModal}
        setViewAddModal={setViewAddModal}
        headTag={'Specializations'}
        purpose={purpose}
        index={index}
        id={id}
        // title={name}
        specData={data}
        qualification={qualification}
       
        name={name}
        // update={updateFunc}
        updated={fetchData}
        educational_qualification_id={educationalQualificationId}
      />



    <div
    style={{
      padding: "10px",
      height: "91vh",
      display: "flex",
      flexDirection: "column",
    }}
  >
    
      <GlobalTableTools  tableHeading={"Specializations"}
       toggel={true}
       toggleFunc={toggle}
       isJobAttributes={true}
       setViewAddModal={setViewAddModal} 
       downloadOption={false}
        />
    <PrimaryDataGrid
     
      fileName="Specializations"
      // onCellClick={handleCellClick}
      loading={isLoading}
     

      columns={filteredColumns}
      rows={data}
      slots={{
        loadIcon: LoaderIcon,
        baseButton: PrimaryButton,
        basePopper: TablePopper,
        baseSwitch: PrimarySwitch,
    
        // toolbar:CustomToolbar,
        noRowsOverlay: CustomNoRowsOverlay,
        pagination: props => (
          <Pagination
            {...props}
            // color="primary"
            // count={Math.ceil(rowsData.length / rowsPerPage)}
            count={totalCount}
            page={page}
            rowsPerPage={rowsPerPage}
            onChange={(_, newPage) => setPage(newPage)}
            showFirstButton
            showLastButton
            sx={{
              "& .MuiPaginationItem-root": {
                "&:hover": {
                  backgroundColor: "#cde2e7",
                  color: "#066a4c",
                },
                "&.Mui-selected": {
                  backgroundColor: "#024430",
                  color: "#ffe7bb",
                  "&:hover": {
                    backgroundColor: "#cde2e7",
                    color: "#066a4c",
                  },
                },
              },
            }}
          />
        ),
      }}
      slotProps={{
        baseButton: { style: { width: "100px" } },
        panel: {
          style: {
            right: "0px !important",
            transform: "none !important",
            inset: "none !important",
            top: "138px !important",
          },
        },
        filterPanel: {
          style: {
            right: "0px !important",
            transform: "none !important",
            inset: "none !important",
            top: "138px !important",
          },
        },
        toolbar: { style: {} },
        preferencesPanel: {
          style: {
            right: "0px !important",
            transform: "none !important",
            inset: "none !important",
            top: "138px !important",
          },
        },
      }}
      sx={{
        "& .super-app-theme--header": {
          backgroundColor: "rgba(250, 250, 250, 1)",
        },
      }}
    />
   
  </div>


  </>
  )


};

export default Specialization;
