export default function DoubleTickIcon() {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.13281 8.66699L4.48376 10.5478C5.0353 10.989 5.83524 10.9235 6.30775 10.3985L11.4661 4.66699M6.13281 8.66699L8.48376 10.5478C9.0353 10.989 9.83524 10.9235 10.3077 10.3985L15.4661 4.66699" stroke="#302E7C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        
        
        
    );
}
