import React, { useState, useEffect } from "react";
import { Row, Container, Col, Card, CardBody } from "reactstrap";
import { Table, TableCell, TableHead, TableRow } from "@mui/material";
import {
  createEducationalQualifications,
  getAllEducationalQualifications,
  updateEducationalQualification,
} from "helpers/services/jobAttributes/educationalQualifications";
import { FormControlLabel, FormGroup, Pagination } from "@mui/material";
import {
  PrimaryButton,
  PrimarySwitch,
  PrimaryDataGrid,
} from "assets/Mui/globalTheme";
import CreateModel from "components/Common/models/createModel";
import { toast } from "react-hot-toast";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress, Stack } from "@mui/material";
import { StageSearchInput, GreyTypography } from "assets/Mui/globalTheme";
import SearchIcon from "@mui/icons-material/Search";

import { useParams, useHistory } from "react-router-dom";
import SearchParamHook from "hooks/searchParams";
import { useLocation } from "react-router-dom";
import { useDebounce } from "hooks/useDebounce";
import CustomNoRowsOverlay from "common/data/NoRowsFoundDatagrid/NoRowsFound";
import CustomPagination from "components/Pagination/globalPagination";
import SpecializationModal from "helpers/Modals/specializationsModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GlobalTableTools from "hooks/GlobalTableTools";
import LoaderIcon from "utils/icons/loaderIcon";
import TablePopper from "components/table/header/popper";

const Qualifications = () => {
  const searchParamsHook = SearchParamHook();
  const { search } = useLocation();
  const history = useHistory();
  const [viewAddModal, setViewAddModal] = React.useState(false);

  const [openSkillModal, setOpenSkillModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [modalName, setModalName] = useState("");

  const currentPage = searchParamsHook.get("page");
  const searchTextParam = searchParamsHook.get("searchParam");
  const [page, setPage] = React.useState(currentPage ?? 1);
  const [searchText, setSearchText] = useState(searchTextParam ?? "");
  const [totalCount, setTotalCount] = React.useState("");
  const rowsPerPage = 20;
  const searchParams = SearchParamHook();
  const [isLoading, setIsLoading] = useState(true);

  const [isOpen, setIsOpen] = useState(false);
  const [purpose, setPurpose] = useState("");
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [active, setActive] = useState(false);
  const [index, setIndex] = useState(-1);
  const [data, setData] = useState([]);
  const [openSearch, setOpenSearch] = useState(true);
  const [searchRole, setSearchRole] = useState("");

  const fetchData = async () => {
    setIsLoading(true)
    const result = await getAllEducationalQualifications(
      page,
      rowsPerPage,
      searchTextParam
    );

    const resultR =
      result?.data_payload?.length > 0 &&
      result?.data_payload?.map((item, index) => ({
        id: item?.id,

        industries: `${item?.name}`,

        specialization:
          item?.specs?.length > 0
            ? item.specs.map(spec => ({ name: spec.name }))
            : "N/A",

        is_active: item.is_active,
      }));

    // setData(result?.data_payload);
    // setTotalCount(data?.total_page_size);
    setData(resultR);
    if(resultR){
      setIsLoading(false)
    }
    setTotalCount(result?.total_page_size);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggle = (purpose = "", name = "", active = "", id = "", index) => {
    setPurpose(purpose);
    setName(name);
    setId(id);
    setActive(active);
    setIndex(index);
    setIsOpen(!isOpen);
    setViewAddModal(!viewAddModal);
  };

  const handleAction = (item, index) => {
    let result = [...data];
    updateEducationalQualification(item.id, {
      is_active: !item.is_active,
      name: item.industries,
    });

    const filterOne = result?.map(each => {
      if (each.id === item.id) {
        return {
          ...each,
          is_active: !each.is_active,
        };
      } else {
        return {
          ...each,
        };
      }
    });

    const statusCheck = result?.filter(each => {
      if (each?.id === item.id) {
        return {
          ...each,
          is_active: !item.is_active,
        };
      }
    });

    if (statusCheck && statusCheck[0]?.is_active) {
      toast.error("disabled");
    } else {
      toast.success("enabled");
    }

    setData(filterOne);
  };

  const updateFunc = async (sample, type, index, id) => {
    let editedId = id || id === 0 ? id : id;
    if (type === "Create") {
      await createEducationalQualifications(sample);
      // setData([...data, sample]);
      fetchData();
    } else {
      const changeData = {
        name: sample?.name,
        is_active: sample?.is_Active,
      };

      await updateEducationalQualification(editedId, changeData);
      let result = [...data];

      const filterOne = result?.map(each => {
        if (each.id === editedId) {
          return {
            ...each,
            name: sample.name,
            is_active: sample.is_active,
          };
        } else {
          return {
            ...each,
          };
        }
      });

      fetchData();
      // setData(filterOne);
    }
  };

  /* Search toggle effect */
  const handleSearchOpen = () => {
    setOpenSearch(!openSearch);
    setSearchRole("");
  };

  const handleSearch = e => {
    setSearchRole(e.target.value);
  };

  // const searchFilterData = data => {
  //   const searchWord = searchRole?.toLowerCase();
  //   const searchPattern = data?.filter(item => {
  //     const roleName = item?.name?.toLowerCase();
  //     const categoryName =
  //       item?.job_attribute_functional_area?.name?.toLowerCase();

  //     if (
  //       roleName?.includes(searchWord) ||
  //       categoryName?.includes(searchWord)
  //     ) {
  //       return item;
  //     }
  //   });
  //   return searchPattern;
  // };

  /* Search toggle effect end */

  // const filteredData = searchFilterData(data);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    searchParams.set("searchParam", searchText);
    history.push({
      search: searchParams.toString(),
    });

    // const fetchData = async () => {
    //   setIsLoading(true);
    //   const currentPage = searchParamsHook.get("page") ?? page;
    //   const data = await getAllEducationalQualifications(
    //     currentPage,
    //     rowsPerPage,
    //     searchText
    //   );
    //   setData(data?.data_payload);
    //   setTotalCount(data?.total_page_size);
    //   setIsLoading(false);
    // };

    fetchData();
  }, [searchParamsHook.get("page"), searchParamsHook.get("searchParam")]);

  const debouncedQuery = useDebounce(searchText, 500);

  useEffect(() => {
    if (debouncedQuery) {
      const searchParams = new URLSearchParams(search);
      searchParams.set("searchParam", debouncedQuery);
      searchParams.set("page", 1);
      history.push({
        search: searchParams.toString(),
      });
    } else {
      const searchParams = new URLSearchParams(search);
      searchParams.set("searchParam", debouncedQuery);
      // setPage(1)
      history.push({
        search: searchParams.toString(),
      });
    }
  }, [debouncedQuery]);

  const handleCellClick = specData => {
    // setSelectedData(specData?.item?.specs);
    setSelectedData(specData);

    setModalName("Specializations");
    setOpenSkillModal(!openSkillModal);
  };

  const handleCloseModal = () => {
    setOpenSkillModal(!openSkillModal);
  };

  /*Columns Creation@@ */

  React.useEffect(() => {
    const searchParams = new URLSearchParams(search);
    searchParams.set("page", page);
    history.push({
      search: searchParams.toString(),
    });

    fetchData();
  }, [page, searchParamsHook.get("searchParam")]);

  const columns = [
    {
      field: "industries",
      headerName: "Qualification",
      headerClassName: "super-app-theme--header",
      width: 300,
    },
    {
      field: "specialization",
      headerName: "Specialization",
      headerClassName: "super-app-theme--header",
      width: 300,
      renderCell: params => {
        const rowIndex = data.findIndex(row => row.id === params.row.id);
        return params.row.specialization === "N/A" ? (
          "N/A"
        ) : (
          <VisibilityIcon
          onClick={() => params.row.specialization?.length > 0 && handleCellClick(params.row.specialization)}

          />
        );
      },
    },
    {
      field: "is_active",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: params => {
        const rowIndex = data.findIndex(row => row.id === params.row.id);

        return (
          <PrimarySwitch
            sx={{ m: 1 }}
            checked={params.row.is_active}
            onChange={async e => {
              handleAction(params.row, rowIndex);
            }}
          />
        );
      },
    },

    {
      field: "Action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      width: 600,

      renderCell: params => {
        const index = data.findIndex(row => row.id === params.row.id);
        return (
          <PrimaryButton
            sx={{ width: "100px" }}
            onClick={() =>
              toggle(
                "Edit",
                params.row.industries,
                params.row.active,
                params.row.id,
                index
              )
            }
          >
            Edit
          </PrimaryButton>
        );
      },
    },
  ];

  const filteredColumns = columns.filter(Boolean);

  // return (
  //   <Container fluid>
  //     <CreateModel
  //       isOpen={isOpen}
  //       toggle={toggle}
  //       headTag={"Educational Qualification"}
  //       purpose={purpose}
  //       index={index}
  //       title={name}
  //       active={active}
  //       update={updateFunc}
  //       id={id}
  //     />
  //     <Row>
  //       <Row className="align-items-center ">
  //         <Col
  //           md={12}
  //           lg={12}
  //           style={{ padding: "40px" }}
  //           className="header_jobs_sticky"
  //         >
  //           <PrimaryButton
  //             sx={{ width: "244px" }}
  //             onClick={() => toggle("Create")}
  //           >
  //             Create Educational Qualifications
  //           </PrimaryButton>

  //           <Stack alignItems={"center"} direction={"row"} spacing={1.6}>
  //             {openSearch ? (
  //               <SearchIcon onClick={handleSearchOpen} />
  //             ) : (
  //               <StageSearchInput
  //                 placeholder="search..."
  //                 // onChange={(e) => handleSearch(e)}
  //                 value={searchText}
  //                 onChange={e => {
  //                   e.stopPropagation();
  //                   setSearchText(e.target.value);
  //                 }}
  //                 endAdornment={
  //                   <IconButton
  //                     edge="end"
  //                     // onClick={handleSearchOpen}
  //                     onClick={() => {
  //                       handleSearchOpen();
  //                       setSearchText("");
  //                     }}
  //                   >
  //                     <ClearIcon />
  //                   </IconButton>
  //                 }
  //               />
  //             )}
  //           </Stack>
  //         </Col>
  //       </Row>
  //       <div className="table_cont">
  //         <div className="table-responsive">
  //           <Card style={{ maxWidth: "80vw" }}>
  //             <CardBody>
  //               <Table className="table mb-0">
  //                 <TableHead>
  //                   <TableRow>
  //                     <TableCell>Qualification</TableCell>
  //                     <TableCell>Specialization</TableCell>
  //                     <TableCell>status</TableCell>
  //                     <TableCell>Action</TableCell>
  //                   </TableRow>
  //                 </TableHead>
  //                 <tbody>
  //                   {
  //                   data?.length > 0?
  //                   data?.map((item, index) => (
  //                     <TableRow
  //                       // key={item.name + index}
  //                       key={item.id}
  //                     >
  //                       <TableCell>{item.name}</TableCell>
  //                       <TableCell
  //                         onClick={() =>
  //                           item?.specs?.length > 0 && handleCellClick({ item })
  //                         }
  //                       >
  //                         {item?.specs?.length > 0 ? <VisibilityIcon /> : "N/A"}
  //                       </TableCell>

  //                       <TableCell className="active_td">
  //                         <FormGroup>
  //                           <FormControlLabel
  //                             control={
  //                               <PrimarySwitch
  //                                 sx={{ m: 1 }}
  //                                 checked={item.is_active}
  //                                 onChange={async e => {
  //                                   handleAction(item, index);
  //                                 }}
  //                               />
  //                             }
  //                           />
  //                         </FormGroup>
  //                       </TableCell>
  //                       <TableCell className="col_btn">
  //                         <PrimaryButton
  //                           sx={{ width: "100px" }}
  //                           onClick={() =>
  //                             toggle(
  //                               "Edit",
  //                               item.name,
  //                               item.active,
  //                               item.id,
  //                               index
  //                             )
  //                           }
  //                         >
  //                           Edit
  //                         </PrimaryButton>
  //                       </TableCell>
  //                     </TableRow>
  //                   )):
  //                   (<TableRow>
  //                     <TableCell colSpan={5} align="center">
  //                       <CustomNoRowsOverlay />
  //                     </TableCell>
  //                   </TableRow>)
  //                   }
  //                 </tbody>
  //               </Table>
  //             </CardBody>
  //           </Card>
  //         </div>
  //       </div>
  //       <CustomPagination count={totalCount} page={page} setPage={setPage} />
  //     </Row>

  //     {openSkillModal && (
  //       <SpecializationModal
  //         rowdata={selectedData}
  //         closeModalFunc={handleCloseModal}
  //         openModal={openSkillModal}
  //         headerName={modalName}
  //       />
  //     )}
  //   </Container>
  // );

  return (
    <>
      <CreateModel
        // isOpen={isOpen}
        toggle={toggle}
        isOpen={viewAddModal}
        viewAddModal={viewAddModal}
        setViewAddModal={setViewAddModal}
        headTag={"Educational Qualification"}
        purpose={purpose}
        index={index}
        id={id}
        title={name}
        active={active}
        update={updateFunc}
      />

      <div
        style={{
          padding: "10px",
          height: "91vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <GlobalTableTools
          tableHeading={"Qualifications"}
          toggel={true}
          toggleFunc={toggle}
          isJobAttributes={true}
          setViewAddModal={setViewAddModal}
          downloadOption={false}
        />
        <PrimaryDataGrid
          fileName="Educational Qualifications"
          // onCellClick={handleCellClick}
          loading={isLoading}
          columns={filteredColumns}
          rows={data}
          slots={{
            loadIcon: LoaderIcon,
            baseButton: PrimaryButton,
            basePopper: TablePopper,
            baseSwitch: PrimarySwitch,

            // toolbar:CustomToolbar,
            noRowsOverlay: CustomNoRowsOverlay,
            pagination: props => (
              <Pagination
                {...props}
                // color="primary"
                // count={Math.ceil(rowsData.length / rowsPerPage)}
                count={totalCount}
                page={page}
                rowsPerPage={rowsPerPage}
                onChange={(_, newPage) => setPage(newPage)}
                showFirstButton
                showLastButton
                sx={{
                  "& .MuiPaginationItem-root": {
                    "&:hover": {
                      backgroundColor: "#cde2e7",
                      color: "#066a4c",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "#024430",
                      color: "#ffe7bb",
                      "&:hover": {
                        backgroundColor: "#cde2e7",
                        color: "#066a4c",
                      },
                    },
                  },
                }}
              />
            ),
          }}
          slotProps={{
            baseButton: { style: { width: "100px" } },
            panel: {
              style: {
                right: "0px !important",
                transform: "none !important",
                inset: "none !important",
                top: "138px !important",
              },
            },
            filterPanel: {
              style: {
                right: "0px !important",
                transform: "none !important",
                inset: "none !important",
                top: "138px !important",
              },
            },
            toolbar: { style: {} },
            preferencesPanel: {
              style: {
                right: "0px !important",
                transform: "none !important",
                inset: "none !important",
                top: "138px !important",
              },
            },
          }}
          sx={{
            "& .super-app-theme--header": {
              backgroundColor: "rgba(250, 250, 250, 1)",
            },
          }}
        />
      </div>

      {openSkillModal && (
        <SpecializationModal
          rowdata={selectedData}
          closeModalFunc={handleCloseModal}
          openModal={openSkillModal}
          headerName={modalName}
        />
      )}
    </>
  );
};

export default Qualifications;
