import React from "react";
import { Redirect } from "react-router-dom";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Assigned from "../pages/Networks/assignedJobs/index";
import ImportResumes from "pages/jobs/navFunctions/importResumes";
import InterviewForm from "pages/interviewIndex";
import Dashboard from "../pages/Dashboard/index";
import WholeJobs from "../pages/Job/Lists/index";
import TechnicalView from "../pages/technical/view";
import DegreeLevels from "../pages/JobAttributes/educationalQualification";
import FunctionalArea from "../pages/JobAttributes/functionalArea";
import Industries from "../pages/JobAttributes/industries";
import SkillAttribute from "pages/JobAttributes/skills";
import JobRole from "pages/JobAttributes/jobRole";
import JobLevel from "pages/JobAttributes/jobLevel";
import Cities from "../pages/Location/cities";
import Countries from "../pages/Location/country";
import States from "../pages/Location/state";
import AccountManagerList from "../pages/AccountManagerList";
import AllBlogs from "../pages/Blogs/index";
import CreateBlog from "../pages/Blogs/createBlog";
import AllCategories from "../pages/Blogs/allCategory";
import CreateCategories from "../pages/Blogs/createCategory";
import CompanyView from "../pages/Company/companyViewIndex";
import Chat from "../pages/chatIndex";
import Calendar from "../pages/Calendar/index";
import InvoicesList from "../pages/Invoice";
import CreateInvoice from "../pages/Invoice/create";
import GridBlogs from "../pages/Blogs/BlogGrid";
import invoicesDetail from "../pages/Invoice/invoices-detail";
import BvcList from "../pages/bvc/list";
import CreateCandidate from "../pages/Candidates/create";
import TalentPoolContents from "../pages/talentPoolContents";
import TalentPool from "../pages/TalentPool";
import QuestionBank from "../pages/Questions";
import CreateForm from "../pages/Questions/CreateForm";
import BooleanQuery from "../pages/BooleanQuery";
import Email from "pages/Email";
import Reports from "pages/Reports";
import Settings from "pages/Settings";
import Candy from "../pages/Candy/list";
import partnerLogin from "pages/Authentication/partnerLogin";
import partnerSignup from "pages/Authentication/partnerSignup";
import ScreenedProfiles from "pages/screenedApplicants";
import NonAuthLayout from "components/NonAuthLayout";
import JobEdit from "pages/Job/JobEdit/JobEdit";
import JobEditing from "pages/Job/JobEdit/index";
import applicantsofSingleJob from "pages/Networks/expressedIntrest/applicantsofSingleJob";
import JobDetailsList from "pages/moderatedApplicants";
import Leaderboard from "pages/Leaderboard/Leaderboard";
import MyEarning from "pages/MyEarnning/MyEarnning";
import CreateJob from "../pages/Job/create";
import { ACM, CA, CS, RC, ZM,TE } from "common/constants/userModules";
import companyJobList from "pages/Job/Lists/companyJobList";
// import ScreeningPartnerProfile from "pages/screeningPartner/View";
// import ReferalPartnerProfile from "pages/ReferalPartners/View";
import BackgroundEvaluatorProfile from "../pages/technical/view";
import ResetPasssword from "pages/Authentication/resetPassword";
import EvaluationForms from "pages/EvaluationForms";
import Commissions from "pages/commissions";
import Kanban from "pages/kanbanIndex";
import UserJobPost from "pages/userJobPostIndex";
import CompanyFollowUps from "pages/FollowUps/company";
import ScreeningFollowUps from "pages/FollowUps/Screening";
import ScheduleInterview from "pages/interviewIndex";
import JobPosting from "pages/jobPosting";
import ReferalPartnerTable from "pages/ReferalPartnerList";
import ScreeningPartnerTable from "pages/ScreeningPartnerList";
import CompanyTable from "pages/Company/companyList";
import TechnicalEvaluatorTable from "pages/TechnicalEvaluatorList";
import BgvTable from "pages/bgvlist";
import SearchIndex from "pages/searchIndex";
import { Payments } from "@mui/icons-material";
import ComingSoon from "pages/comingSoon";
import CommonUserAssignPage from "pages/commUserAssigns";
import RecrutingCompanyTable from "pages/RecruitingCompanyList";
import ChatInfo from "pages/chatInfo";
import AdminStaffTable from "../pages/AccountManagerList";

export let partnerProtectdRoutes = [];

export const SPROUTES = [
  { path: "/company/:id", Component: CompanyView },
  { path: "/joblist/jobs/schedule-interview", Component: ScheduleInterview },
  { path: "/dashboard", Component: Dashboard },
  { path: "/job-openings", Component: Assigned },
  { path: "/screened-applicants", Component: ScreenedProfiles },
  // { path: "/screened-applicants", Component: ComingSoon },
  { path: "/screened-applicants/:id", Component: applicantsofSingleJob },
  // { path: "/leaderboard", Component: Leaderboard },
  { path: "/leaderboard", Component: ComingSoon },
  { path: "/earnings", Component: MyEarning },
  // { path: "/earnings", Component: ComingSoon },
  {
    path: "/joblist/jobs/user-job-post",
    Component: UserJobPost,
  },
  { path: "/joblist/jobs/:id", Component: Kanban },
  { path: "/joblist/:id", Component: WholeJobs },
  // { path: "/chat", Component: Chat },
  { path: "/chat", Component: ComingSoon },
  // { path: "/emails", Component: Email },
  { path: "/emails", Component: ComingSoon },
  { path: "/profile-settings", Component: Settings },
  { path: "/search", Component: SearchIndex },

  { path: "/calendar", Component: Calendar },
  { path: "/company-list", Component: CompanyTable },
  { path: "/", exact: true, Component: () => <Redirect to="/dashboard" /> },
];
export const RCROUTES = [
  { path: "/company/:id", Component: CompanyView },
  {
    path: "/joblist/jobs/user-job-post",
    Component: UserJobPost,
  },
  { path: "/search", Component: SearchIndex },

  { path: "/joblist/jobs/:id", Component: Kanban },
  { path: "/dashboard", Component: Dashboard },
  { path: "/joblist/:id", Component: WholeJobs },
  { path: "/screened-applicants", Component: ScreenedProfiles },
  // { path: "/screened-applicants", Component: ComingSoon },
  { path: "/screened-applicants/:id", Component: applicantsofSingleJob },
  // { path: "/leaderboard", Component: Leaderboard },
  { path: "/leaderboard", Component: ComingSoon },
  { path: "/earnings", Component: MyEarning },
  // { path: "/earnings", Component: ComingSoon },

  { path: "/interview-form", Component: InterviewForm },
  // { path: "/chat", Component: Chat },
  { path: "/chat", Component: ComingSoon },
  // { path: "/emails", Component: Email },
  { path: "/emails", Component: ComingSoon },
  { path: "/profile-settings", Component: Settings },
  { path: "/calendar", Component: Calendar },
  { path: "/company-list", Component: CompanyTable },
  { path: "/", exact: true, Component: () => <Redirect to="/dashboard" /> },
];
export const TEROUTES = [
  { path: "/dashboard", Component: Dashboard },
  {
    path: "/joblist/jobs/user-job-post",
    Component: UserJobPost,
  },
  { path: "/joblist/jobs/:id", Component: Kanban },
  { path: "/joblist/:id", Component: WholeJobs },
  { path: "/screened-applicants", Component: ScreenedProfiles },
  // { path: "/screened-applicants", Component: ComingSoon },
  { path: "/screened-applicants/:id", Component: applicantsofSingleJob },
  // { path: "/leaderboard", Component: Leaderboard },
  { path: "/leaderboard", Component: ComingSoon },
  { path: "/interview-form", Component: InterviewForm },
  { path: "/earnings", Component: MyEarning },
  // { path: "/earnings", Component: ComingSoon },
  { path: "/search", Component: SearchIndex },

  { path: "/chat", Component: Chat },
  {path:"/chat/:id",Component: ChatInfo},
  // { path: "/chat", Component: ComingSoon },
  // { path: "/emails", Component: Email },
  { path: "/emails", Component: ComingSoon },
  { path: "/profile-settings", Component: Settings },
  { path: "/company/:id", Component: CompanyView },

  { path: "/calendar", Component: Calendar },
  { path: "/company-list", Component: CompanyTable },
  { path: "/", exact: true, Component: () => <Redirect to="/dashboard" /> },
  
];
export const ACMROUTES = [
  { path: "/dashboard", Component: Dashboard },
  {
    path: "/joblist/jobs/user-job-post",
    Component: UserJobPost,
  },
  { path: "/joblist/jobs/:id", Component: Kanban },
  { path: "/joblist/:id", Component: WholeJobs },
  { path: "/company-list", Component: CompanyTable },
  // { path: "/chat", Component: Chat },
  { path: "/chat", Component: ComingSoon },
  { path: "/company/:id", Component: CompanyView },
  { path: "/calendar", Component: Calendar },
  { path: "/profile-settings", Component: Settings },
  { path: "/search", Component: SearchIndex },

  { path: "/", exact: true, Component: () => <Redirect to="/dashboard" /> },
];
export const ZMROUTES = [
  { path: "/dashboard", Component: Dashboard },
  {
    path: "/joblist/jobs/user-job-post",
    Component: UserJobPost,
  },
  { path: "/joblist/jobs/:id", Component: Kanban },
  { path: "/joblist/:id", Component: WholeJobs },
  { path: "/moderated-applicants", Component: JobDetailsList },
  // { path: "/moderated-applicants", Component: ComingSoon },
  // { path: "/chat", Component: Chat },
  { path: "/chat", Component: ComingSoon },
  { path: "/company/:id", Component: CompanyView },
  { path: "/search", Component: SearchIndex },
  { path: "/background-verified-applicants", Component: ScreenedProfiles },

  // { path: "/emails", Component: Email },
  { path: "/emails", Component: ComingSoon },
  { path: "/profile-settings", Component: Settings },
  { path: "/calendar", Component: Calendar },
  { path: "/company-list", Component: CompanyTable },
  { path: "/", exact: true, Component: () => <Redirect to="/dashboard" /> },
];

const CSROUTES = [
  { path: "/dashboard", Component: Dashboard },
  {
    path: "/joblist/jobs/user-job-post",
    Component: UserJobPost,
  },
  { path: "/search", Component: SearchIndex },
  { path: "/profile-settings", Component: Settings },
  // { path: "/job-edit/:id", Component: JobEdit },
  { path: "/job-edit/:id", Component: JobEditing },
  // { path: "/chat", Component: Chat },
  { path: "/post-job", Component: JobPosting },
  { path: "/chat", Component: ComingSoon },
  { path: "/company/:id", Component: CompanyView },
  { path: "/joblist/jobs/:id", Component: Kanban },
  { path: "/joblist/:id", Component: WholeJobs },
  { path: "/interview-form", Component: InterviewForm },
  { path: "/referal-partner-list", Component: ReferalPartnerTable },
  { path: "/screening-partner-list", Component: ScreeningPartnerTable },
  { path: "/technical-evaluator-list", Component: TechnicalEvaluatorTable },
  { path: "/recruiting-company-list", Component: RecrutingCompanyTable },
  { path: "/background-evaluators-list", Component: BgvTable },
  { path: "/account-manager-list", Component:AdminStaffTable },
  { path: "/moderator-list", Component: AdminStaffTable },

  ,
];
const COMPANYROUTES = [
  { path: "/dashboard", Component: Dashboard },
  {
    path: "/kanban/:id",
    Component: Kanban,
  },
  { path: "/joblist/jobs/schedule-interview", Component: ScheduleInterview },
  {
    path: "/joblist/jobs/user-job-post",
    Component: UserJobPost,
  },
  { path: "/post-job", Component: JobPosting },
  { path: "/joblist/jobs/:id", Component: Kanban },
  { path: "/joblist/:id", Component: WholeJobs },
  // { path: "/job-edit/:id", Component: JobEdit },
  { path: "/job-edit/:id", Component:JobEditing  },
  { path: "/company/:id", Component: CompanyView },
  { path: "/profile-settings", Component: Settings },
  // { path: "/chat", Component: Chat },
  { path: "/chat", Component: ComingSoon },
  { path: "/emails", Component: Email },
  { path: "/payments", Component: ComingSoon },
  { path: "/calendar", Component: Calendar },
  { path: "/invoice-list", Component: InvoicesList },
  { path: "/invoice-create", Component: CreateInvoice },
  { path: "/invoice-detail", Component: invoicesDetail },
  { path: "/invoice-detail/:id", Component: invoicesDetail },
  { path: "/", exact: true, Component: () => <Redirect to="/dashboard" /> },
  { path: "/referal-partner-list", Component: ReferalPartnerTable },
  { path: "/screening-partner-list", Component: ScreeningPartnerTable },
  { path: "/technical-evaluator-list", Component: TechnicalEvaluatorTable },
  { path: "/recruiting-company-list", Component: RecrutingCompanyTable },
  { path: "/background-evaluators-list", Component: BgvTable },
  { path: "/account-manager-list", Component:AdminStaffTable },
  { path: "/moderator-list", Component: AdminStaffTable },
  
];

export const identifyUser = userType => {
  switch (userType) {
    case "SP":
      return SPROUTES;
    case "BVC":
      return ZMROUTES;
    case TE:
      return TEROUTES;
    case RC:
      return RCROUTES;
    case CA:
      return COMPANYROUTES;
    case ACM:
      return ACMROUTES;
    case CS:
      return CSROUTES;
    case ZM:
      return ZMROUTES;
    default:
      return authProtectedRoutes;
  }
};

const authProtectedRoutes = [
  { path: "/emails", Component: Email },
  { path: "/calendar", Component: Calendar },
  { path: "/dashboard", Component: Dashboard },
  { path: "/profile-settings", Component: Settings },
  { path: "/interview-form", Component: InterviewForm },
  { path: "/import-resumes", Component: ImportResumes },
  { path: "/company-list", Component: CompanyTable },
  // { path: "/job-edit/:id", Component: JobEdit },
  { path: "/job-edit/:id", Component: JobEditing },
  { path: "/company-job-list/:id", Component: companyJobList },
  { path: "/company/:id", Component: CompanyView },
  { path: "/joblist/jobs/schedule-interview", Component: ScheduleInterview },
  { path: "/joblist/jobs/user-job-post", Component: UserJobPost },
  { path: "/joblist/jobs/:id", Component: Kanban },
  { path: "/technical-evaluators/:id", Component: TechnicalView },
  { path: "/evaluation-forms", Component: EvaluationForms },
  { path: "/account-manager-list", Component: AccountManagerList },
  { path: "/joblist/:id", Component: WholeJobs },
  { path: "/job-create", Component: CreateJob },
  { path: "/commissions", Component: Commissions },
  { path: "/post-job", Component: JobPosting },
  { path: "/common-user/:id", Component: CommonUserAssignPage },
  { path: "/job-attributes/degree-levels", Component: DegreeLevels },
  { path: "/job-attributes/categories", Component: FunctionalArea },
  { path: "/job-attributes/industries", Component: Industries },
  { path: "/job-attributes/skills", Component: SkillAttribute },
  { path: "/job-attributes/job-role", Component: JobRole },
  { path: "/job-attributes/job-level", Component: JobLevel },

  { path: "/location/cities", Component: Cities },
  { path: "/location/countries", Component: Countries },
  { path: "/location/states", Component: States },
  { path: "/blog/all-blogs", Component: AllBlogs },
  { path: "/blog/create-blogs", Component: CreateBlog },
  { path: "/blog/all-categories", Component: AllCategories },
  { path: "/blog/grid-blogs", Component: GridBlogs },
  { path: "/blog/create-categories", Component: CreateCategories },
  { path: "/referal-partner-list", Component: ReferalPartnerTable },
  // { path: "/referal-partner/:id", Component: ReferalPartnerProfile },
  // { path: "/referalpartner-create", Component: ReferalPartnerCreate },
  { path: "/search", Component: SearchIndex },
  { path: "/screening-partner-list", Component: ScreeningPartnerTable },
  { path: "/recruiting-company-list", Component: RecrutingCompanyTable },
  // { path: "/screening-partner/:id", Component: ScreeningPartnerProfile },
  { path: "/technical-evaluator-list", Component: TechnicalEvaluatorTable },
  { path: "/account-manager-list", Component:AdminStaffTable },
  { path: "/moderator-list", Component: AdminStaffTable },
  { path: "/background-evaluators-list", Component: BgvTable },
  { path: "/background-evaluator/:id", Component: BackgroundEvaluatorProfile },
  { path: "/bvc-list", Component: BvcList },
  { path: "/candidate-create", Component: CreateCandidate },
  // { path: "/network/create-template", Component: CreateTemplate },
  // { path: "/talent-pool", Component: TalentPool },
  { path: "/talent-pool", Component: ComingSoon },
  { path: "/talent-pool/:id", Component: TalentPoolContents },
  // { path: "/questions", Component: QuestionBank },
  { path: "/questions", Component: ComingSoon },
  { path: "/questions/create", Component: CreateForm },
  // { path: "/boolean-query", Component: BooleanQuery },
  { path: "/boolean-query", Component: ComingSoon },
  // { path: "/reports", Component: Reports },
  { path: "/reports", Component: ComingSoon },
  { path: "/payments", Component: ComingSoon },
  { path: "/candies", Component: Candy },
  { path: "/", exact: true, Component: () => <Redirect to="/dashboard" /> },
  { path: "/screened-applicants", Component: ScreenedProfiles },
  { path: "/leaderboard", Component: ComingSoon },
];

const publicRoutes = [
  { path: "/logout", Component: Logout },

  { path: "/login", Component: partnerLogin },
  { path: "/company-create", Component: CompanyFollowUps },
  {
    path: "/screening-partner-create",
    Component: ScreeningFollowUps,
    layout: NonAuthLayout,
  },
  { path: "/signup", Component: partnerSignup },
  { path: "/forgot-password", Component: ForgetPwd },
  { path: "/reset-password", Component: ResetPasssword },
];

export { authProtectedRoutes, publicRoutes };
