// import FileViewer from "react-file-viewer";

// import {
//   Avatar,
//   Button,
//   Card,
//   CardActionArea,
//   CardContent,
//   CircularProgress,
//   IconButton,
//   InputBase,
//   Modal,
//   Stack,
// } from "@mui/material";
// import {
//   BorderCard,
//   BorderedCardActionArea,
//   PrimaryButton,
//   PrimaryIconButton,
//   GreyTypography,
//   PrimaryAvatar,
//   BlackTypography,
//   GrayDivider,
//   PrimaryTypography,
// } from "assets/Mui/globalTheme";
// import { imageURL } from "common/constants/commonURLS";
// import FeatherIcon from "feather-icons-react/build/FeatherIcon";
// import { getPreSignedURL } from "helpers/services/pre_signed_urls/upload";
// import { viewSingleUserDetailsInKanbanBoard } from "helpers/services/referalPartner";
// import { updateUserJobPost } from "helpers/userJobPost/updateUserJobPost";
// import SearchParamHook from "hooks/searchParams";
// import React, { useEffect } from "react";
// import { useState } from "react";
// import Dropzone, { useDropzone } from "react-dropzone";
// import { useDispatch, useSelector } from "react-redux";
// import { saveAs } from "file-saver";
// import { toast } from "react-hot-toast";
// import ViewResume from "./viewResume";
// import ResumeTable from "./resumeTable";
// import dataNotFound from "../../../../assets/images/noDataGreen.png";
// import { socket } from "Sockets/socket";

// export default function UserJobPostResume() {
//   const [selectedFiles, setSelectedFiles] = React.useState([]);
//   const user = useSelector(state => state?.Login);
//   const [userJobPostId, setUserJobPostId] = useState(null);
//   const dispatch = useDispatch();
//   const loading = useSelector(state => state.Loading.loading);
//   const [viewResume,setViewResume] = useState(null)
//   const [resumeUrl, setResumeUrl] = useState(null)
//   const searchParams = SearchParamHook();

//   function formatBytes(bytes, decimals = 2) {
//     if (bytes === 0) return "0 Bytes";
//     const k = 1024;
//     const dm = decimals < 0 ? 0 : decimals;
//     const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
//     const i = Math.floor(Math.log(bytes) / Math.log(k));
//     return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
//   }
//   function onDrop(files) {
//     if (files.length === 1) {
//     }

    

//     files.map(file =>
//       Object.assign(file, {
//         preview: URL.createObjectURL(file),
//         formattedSize: formatBytes(file.size),
//         crop: false,
//       })
//     );

//     dispatch({ type: "START_LOADING" });
//     files?.map(async file => {
//       const url = new Promise((resolve, reject) => {
//         const uploadedData = getPreSignedURL([file]);
//         if (uploadedData) resolve(uploadedData);
//         else reject("error");
//       });
//       url.then(urlData => {
//         const modifyUrlData = {
//           fileName:urlData,
//           uploadedUser:user?.email
//         }
       
//         updateUserJobPost(userId, { resumes: [...selectedFiles,modifyUrlData] },userJobPostId,"resume");
//         setSelectedFiles(pre=>[urlData,...pre]);

//         dispatch({ type: "STOP_LOADING" });
//         toast.success("Uploaded successfully");


//       }).catch((e)=>{
//         dispatch({ type: "STOP_LOADING" });
//         console.error(e);
//         toast.error("Sorry you can't upload");
//       })
//     })
//     dispatch({ type: "STOP_LOADING" });
//   }
//   function typeValidator(file){
//     const valid = /\.(pdf|doc)$/i.test(file.name)
//     if(!valid && file.type.split("/").pop().includes("vnd.openxmlformats-officedocument.wordprocessingml.document")){
//       toast.error('Sorry invalid type')
//       return null
//     }
//   }
//   const { acceptedFiles, fileRejections, getRootProps, getInputProps,open } =
//     useDropzone({
//       onDrop,
//       noClick:true,
//       accept: {
//         "application/pdf": [],
//         "application/msword": [],
       
//       },
//       validator:typeValidator
//     });

//   const userId = searchParams.get("user_id");


//   useEffect(() => {
//     const userJobPostId = searchParams.get("user_job_post_id");
//     const jobPostId = searchParams.get("job_id");
//     if (userId && jobPostId) {
//       fetchUserJobPostDetails(userId, jobPostId);
//     }
//     if (userJobPostId) setUserJobPostId(userJobPostId);
//   }, [searchParams]);



//   async function fetchUserJobPostDetails(userId, jobPostId) {
//     const { data } = await viewSingleUserDetailsInKanbanBoard(
//       userId,
//       jobPostId
//     );
  
//     if(data?.resume_url){
//       // setResumeUrl(data?.resume_url)
//     }
//     if (data?.data_payload?.user_job_post_details?.resumes?.length > 0)
//       setSelectedFiles(data?.data_payload?.user_job_post_details?.resumes);
//       // setResumeUrl(data?.user_job_post_details?.resumes[0])
//   }
//   if (loading) {  
//     return (
//       <div style={{ display: "grid", placeItems: "center" }}>
//         <CircularProgress />
//       </div>
//     );
//   }



//   const handleListnerEvent = data => {
//     if (data?.type === "resume") {
//       const userJobPostId = searchParams.get("user_job_post_id");
//     const jobPostId = searchParams.get("job_id");
//     if (userId && jobPostId) {
//       fetchUserJobPostDetails(userId, jobPostId);
//     }
//     if (userJobPostId) setUserJobPostId(userJobPostId);
//     }
//   };

//   useEffect(() => {
//     const handleReconnect = () => {
//       // Re-register the event listener after reconnection if needed
//       socket.on("listnerEvent", handleListnerEvent);
//     };

//     socket.on("listnerEvent", handleListnerEvent);

//     // Attach a listener for socket reconnection
//     socket.on("reconnect", handleReconnect);

//     return () => {
//       console.log("Removing event listener");
//       socket.off("listnerEvent", handleListnerEvent);
//       socket.off("reconnect", handleReconnect);
//     };
//   }, []);

//   return (
//     <>
//       <div {...getRootProps()} style={{marginBottom:'10px',display:"flex" ,justifyContent:'flex-end' }}>
//         <input
//               {...getInputProps()}
//               accept="application/*"
//               style={{display:"none" }}
//         />
//         <PrimaryButton onClick={open} sx={{width:'min-content !important'}} startIcon={( <FeatherIcon icon="upload-cloud" size="20" />)}>Upload</PrimaryButton>
        
//       </div>
//       {selectedFiles?.length ?
//        <ResumeTable setViewResume={setViewResume} setResumeUrl={setResumeUrl} resumes={selectedFiles} />
//         :
//         <div style={{ textAlign: "center" }}>
        
//           <img
//             style={{
//               width: "350px",
//               height: "350px",
//               marginTop: 0,
//             }}
//             src={dataNotFound}
//             alt="/not-found"
//           />
//         </div>        
//        }
//       {Boolean(viewResume) && <ViewResume open={viewResume} file={selectedFiles[0]} resumeUrl={resumeUrl} toggleModel={() => setViewResume(null)} />}
//     </>
//   );
// }





import FileViewer from "react-file-viewer";
import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  IconButton,
  InputBase,
  Modal,
  Stack,
} from "@mui/material";
import {
  BorderCard,
  BorderedCardActionArea,
  PrimaryButton,
  PrimaryIconButton,
  GreyTypography,
  PrimaryAvatar,
  BlackTypography,
  GrayDivider,
  PrimaryTypography,
} from "assets/Mui/globalTheme";
import { imageURL } from "common/constants/commonURLS";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { getPreSignedURL } from "helpers/services/pre_signed_urls/upload";
import { viewSingleUserDetailsInKanbanBoard } from "helpers/services/referalPartner";
import { updateUserJobPost } from "helpers/userJobPost/updateUserJobPost";
import SearchParamHook from "hooks/searchParams";
import React, { useEffect } from "react";
import { useState } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { saveAs } from "file-saver";
import { toast } from "react-hot-toast";
import ViewResume from "./viewResume";
import ResumeTable from "./resumeTable";
import dataNotFound from "../../../../assets/images/noDataGreen.png";
import { socket } from "Sockets/socket";

export default function UserJobPostResume() {
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [jobSeekerApply, setJobSeekerApply] = React.useState('')
  const user = useSelector(state => state?.Login);
  const [userJobPostId, setUserJobPostId] = useState(null);
  const dispatch = useDispatch();
  const loading = useSelector(state => state.Loading.loading);
  const [viewResume, setViewResume] = useState(null);
  const [resumeUrl, setResumeUrl] = useState(null);
  const searchParams = SearchParamHook();
  const profileReadStatus = searchParams.get('readOnlyStatus')
  const userId = searchParams.get("user_id");

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  async function onDrop(files) {
    if (files.length === 1) {
      // Handling for single file upload
    }

    dispatch({ type: "START_LOADING" });

    try {
      const urls = await Promise.all(files.map(file => getPreSignedURL([file])));
      const modifiedUrls = urls.map(url => ({ fileName: url, uploadedUser: user?.email }));
      
      dispatch({ type: "STOP_LOADING" });
  
      // Update user job post with the uploaded resumes
      updateUserJobPost(userId, { resumes: [...selectedFiles, ...modifiedUrls] }, userJobPostId, "resume");
      toast.success("Uploaded successfully");
      const jobPostId = searchParams.get("job_id");
      fetchUserJobPostDetails(userId, jobPostId);
    } catch (error) {
      dispatch({ type: "STOP_LOADING" });
      console.error(error);
      // toast.error("Sorry, an error occurred while uploading.");
    }
  }

  // Function to validate file type
  function typeValidator(file) {
    const valid = /\.(pdf|doc)$/i.test(file.name);
    if (!valid && file.type.split("/").pop().includes("vnd.openxmlformats-officedocument.wordprocessingml.document")) {
      toast.error('Sorry, invalid file type');
      return null;
    }
  }

  // Dropzone configuration
  const { acceptedFiles, fileRejections, getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    accept: {
      "application/pdf": [],
      "application/msword": [],
    },
    validator: typeValidator
  });

  // Fetch user job post details on component mount
  useEffect(() => {
    const userJobPostId = searchParams.get("user_job_post_id");
    const jobPostId = searchParams.get("job_id");
    if (userId && jobPostId) {
      fetchUserJobPostDetails(userId, jobPostId);
    }
    if (userJobPostId) setUserJobPostId(userJobPostId);
  }, [searchParams]);

  // Fetch user job post details
  async function fetchUserJobPostDetails(userId, jobPostId) {
    try {
      const data  = await viewSingleUserDetailsInKanbanBoard(userId, jobPostId);
      // if (data?.resume_url) {
      //   // setResumeUrl(data?.resume_url)
      // }

      if (data?.data?.data_payload?.user_job_post_details?.resumes?.length > 0) {
        const filterAppliedUser = data?.data?.data_payload?.user_job_post_details?.resumes?.filter(each => {
          if (!each?.uploadedUser) {
            setJobSeekerApply(data?.data?.data_payload?.user_job_post_details?.email);
            return false; // Resume doesn't have an uploadedUser
          }
          return true; // Resume has an uploadedUser
        });
      
        setSelectedFiles(data?.data?.data_payload?.user_job_post_details?.resumes);
        dispatch({ type: "STOP_LOADING" });
      }
      
     
    } catch (error) {
      console.error(error);
      // Handle error
    }
  }

  // Listen for socket events
  useEffect(() => {
    const handleListnerEvent = data => {
      if (data?.type === "resume") {
        const userJobPostId = searchParams.get("user_job_post_id");
        const jobPostId = searchParams.get("job_id");
        if (userId && jobPostId) {
          fetchUserJobPostDetails(userId, jobPostId);
        }
        if (userJobPostId) setUserJobPostId(userJobPostId);
      }
    };

    const handleReconnect = () => {
      socket.on("listnerEvent", handleListnerEvent);
    };

    socket.on("listnerEvent", handleListnerEvent);
    socket.on("reconnect", handleReconnect);

    return () => {
  
      socket.off("listnerEvent", handleListnerEvent);
      socket.off("reconnect", handleReconnect);
    };
  }, []);

  if (loading) {
    return (
      <div style={{ display: "grid", placeItems: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div {...getRootProps()} style={{ marginBottom: '10px', display: "flex", justifyContent: 'flex-end' }}>
        <input
          {...getInputProps()}
          accept="application/*"
          style={{ display: "none" }}
        />
        <PrimaryButton onClick={open}
          disabled={profileReadStatus === "false"?false:true}
         sx={{ width: 'min-content !important' }} startIcon={(<FeatherIcon icon="upload-cloud" size="20" />)}>Upload</PrimaryButton>
      </div>
      {selectedFiles?.length ?
        <ResumeTable setViewResume={setViewResume} setResumeUrl={setResumeUrl} resumes={selectedFiles} email={jobSeekerApply} />
        :
        <div style={{ textAlign: "center" }}>
          <img
            style={{
              width: "350px",
              height: "350px",
              marginTop: 0,
            }}
            src={dataNotFound}
            alt="/not-found"
          />
        </div>
      }
      {/* {Boolean(viewResume) && <ViewResume open={viewResume} file={selectedFiles[0]} resumeUrl={resumeUrl} toggleModel={() => setViewResume(null)} />} */}
      {Boolean(viewResume) && <ViewResume open={Boolean(viewResume)} file={viewResume?.fileName || viewResume} resumeUrl={resumeUrl} toggleModel={() => setViewResume(null)} />}
    </>
  );
}
