import * as React from "react";
import {
  Box,
  Modal,
  TextField,
  Rating,
  Tooltip,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
} from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import {
  BlackTypography,
  MultipleSelectInputs,
  PrimaryAutoComplete,
  PrimaryButton,
  PrimaryNormalInputLabel,
  SecondaryFilledButton,
  SecondaryFilledChip,
  autocompleteProps,
  CustomSlider,
  StyledRating
} from "assets/Mui/globalTheme";
import {
  profileRatingSubmission,
  profileRatingStatus,
} from "helpers/services/screeningPartner/screeningPartner";
import { Controller, useForm } from "react-hook-form";
import {
  Checkbox,
  FormControl,
  Grid,
  Stack,
  createFilterOptions,
} from "@mui/material";
import { v4 as uuid } from "uuid";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};
import {
  createKeySkills,
  getAllKeySkillsWithStatus,
} from "helpers/services/jobAttributes/keyskills";
import SearchParamHook from "hooks/searchParams";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { makeStyles } from "@mui/styles";
import { toast } from "react-hot-toast";

const useStyles = makeStyles({
  label: {
    "& label": {
      marginBottom: "0px !important",
    },
    "&.css-iljtu2-MuiRating-root": {
      border: props => (props.rateError ? "1px solid #ff5630" : "none"),
    },
  },
});

const feedbacks = [
  { label: "Exact Fit", value: "Exact Fit" },
  { label: "Near Fit", value: "Near Fit" },
  { label: "Not a Match", value: "Not a Match" },
];

const popperProps = {
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [1, -10], // Adjust the values to reduce the distance
      },
    },
  ],
};

const labels = {
  0.5: "0.5/10",
  1: "1/10+",
  1.5: "1.5/10",
  2: "2/10",
  2.5: "2.5/10",
  3: "3/10",
  3.5: "3.5/10",
  4: "4/10",
  4.5: "4.5/10",
  5: "5/10",
  5.5:"5.5/10",
  6:"6/10",
  6.5:"6.5/10",
  7:"7/10",
  7.5:"7.5/10",
  8:"8/10",
  8.5:"8.5/10",
  9:"9/10",
  9.5:"9.5/10",
  10:"10/10"
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

export default function ProfileRatings({
  // updateHandler,
  open,
  toggleModel,
  userJobPostId,
  role,
  setMoveCandidate,
  moveCandidate,
}) {
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      status: "",
      ratings: "",
    },
  });
  //   const [rateError,setRateError] = React.useState(false)
  const searchParams = SearchParamHook();
  const stageOrder = searchParams.get("stage_order");
  const filter = createFilterOptions();
  //   const rateError = Boolean(!rating.value)
  // const rateError = Boolean(!rating.value)

  const [value, setValue] = React.useState(0);
  const [hover, setHover] = React.useState(-1);

  const classes = useStyles();

  const stageWiseRoles = {
    SP: ["0", "1"],
    TE: ["2"],
    MODERATOR: ["3"],
  };

  async function onSubimission(data) {
    const { comment, key_skills } = data;

    let updatedData = {
      // profile_rating: data?.ratings,
      profile_rating: parseInt(value),
      profile_status: data?.status,
      comment: data?.comment,
      user_job_post_id: userJobPostId,
      evaluator_type: role,
      stage_order: stageOrder,
    };

    const ratingResult = await profileRatingSubmission(updatedData);
    if (ratingResult.status == 201) {
      toast.success("Updated successfully!");
      setMoveCandidate(!moveCandidate);
      toggleModel();
    }
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          toggleModel();
          reset();
        }}
        aria-labelledby="edit-profile-modal-title"
        aria-describedby="edit-profile-modal-description"
      >
        <Box sx={style}>
          <BlackTypography variant="h2" sx={{ marginBottom: "20px" }}>
            Rate the profile
          </BlackTypography>
          <form onSubmit={handleSubmit(onSubimission)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {/* <ReactSelect
                  isMulti={true}
                  options={allKeySkills}
                  onChange={data => {
                    setKeySkills(data);
                  }}
                  value={keySkills}
                /> */}
                {/* <TextField
                  label="Ratings"
                  type="number"
                  value={watch("ratings")}
                  // multiline
                  {...register("ratings", {
                    min: {
                      value: 0,
                      message: "It should not be less than 0",
                    },
                    max: {
                      value: 10,
                      message: "It cannot exceed 10",
                    },
                    valueAsNumber: true,
                    required: {
                      value: true,
                      message: "cannot be empty",
                    },
                  })}
                  placeholder="Rate the candidate out of 10"
                  error={errors.ratings}
                  helperText={errors?.ratings?.message}
                  sx={{ marginBottom: "16px" }}
                /> */}

                {/* <CustomSlider              
                  thumb__hover_color="#044B36"
                  backgrounds="#044B36"
                  aria-label="Custom marks"
                  value={watch("ratings")}

                  {...register("ratings", {
                    min: {
                      value: 0,
                      message: "It should not be less than 0",
                    },
                    max: {
                      value: 10,
                      message: "It cannot exceed 10",
                    },
                    valueAsNumber: true,
                    required: {
                      value: true,
                      message: "cannot be empty",
                    },
                  })}
                  max={10}
                  min={0}
                  step={1}
                  valueLabelDisplay="auto"
                /> */}

                <Box
                  sx={{
                    width: 200,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <StyledRating
                    name="hover-feedback"
                    value={value}
                    precision={0.5}
                    getLabelText={getLabelText}
                    max={10}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                    onChangeActive={(event, newHover) => {
                      setHover(newHover);
                    }}
                    // {...register("ratings", {
                    //   min: {
                    //     value: 0,
                    //     message: "It should not be less than 0",
                    //   },
                    //   max: {
                    //     value: 10,
                    //     message: "It cannot exceed 10",
                    //   },
                    //   valueAsNumber: true,
                    //   required: {
                    //     value: true,
                    //     message: "cannot be empty",
                    //   },
                    // })}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                  />
                  {value !== null && (
                    <Box sx={{ ml: 4 }}>
                      {labels[hover !== -1 ? hover : value]}
                    </Box>
                  )}
                </Box>

                <TextField
                  sx={{ width: "100%" }}
                  label="Comment"
                  value={watch("comment")}
                  placeholder="Comment"
                  {...register("comment", { required: true })}
                  error={errors?.comment}
                  multiline
                  rows={2.5}
                />
              </Grid>

              {/* <Grid item xs={2}>
                <TextField
                  label="Ratein"
                  type="number"
                  value="/10"
              
                  // {...register("ratings", {
                  //   min: {
                  //     value: 0,
                  //     message:"It should not be less than 0"
                  //   },
                  //   max: {
                  //     value: 10,
                  //     message:"It cannot exceed 10"
                  //   },
                  //   valueAsNumber: true,
                  //   required: {
                  //     value: true,
                  //     message:'cannot be empty'
                  //   }
                  // })}
                  placeholder="Rating"
                  error={errors.ratings}
                  helperText={errors?.ratings?.message}
                  sx={{ marginBottom: "16px" }}
                />
              </Grid> */}

              <Grid item xs={12}>
                <Controller
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "cannot be empty",
                    },
                  }}
                  name="status"
                  render={props => (
                    <FormControl>
                      <FormLabel
                        sx={{ color: "#066a4c" }}
                        error={errors.status}
                        id="demo-radio-buttons-group-label"
                      >
                        Status
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        // defaultValue="female"
                        {...props.field}
                        value={watch("status")}
                        name="radio-buttons-group"
                      >
                        {feedbacks.map(status => (
                          <FormControlLabel
                            value={status.value}
                            control={<Radio />}
                            label={status.label}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={7}></Grid>
              <Grid item xs={5}>
                <Stack spacing={1} direction="row">
                  <SecondaryFilledButton
                    onClick={() => {
                      toggleModel();
                    }}
                  >
                    cancel
                  </SecondaryFilledButton>
                  <PrimaryButton type="submit">Submit</PrimaryButton>
                </Stack>{" "}
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
