import parsePhoneNumber from 'libphonenumber-js';
import { parsePhoneNumberFromString } from "libphonenumber-js";

export const parseNumberInput = (number)=>{

    const phoneNumberObj = parsePhoneNumber(number ?? "");
    if (phoneNumberObj) {
      const formattedPhoneNumber = phoneNumberObj.formatInternational('International');
      return formattedPhoneNumber
 
    }else {
      return number
    }
  };


  export const validatePhoneNumber = (value) => {
    let error;
    if (!value) {
      error = "Phone number is required";
    } else {
      const phoneNumber = parsePhoneNumberFromString(value);

      if (!phoneNumber || !phoneNumber.isValid()) {
        error = "Invalid phone number";
      }
    }
    return error;
  };