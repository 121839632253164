import React, { useEffect, useState } from "react";
import { Link, useParams, withRouter } from "react-router-dom";
import { viewSingleCompanyDetails } from "helpers/services/companies/company";
import { imageURL } from "common/constants/commonURLS";
import { Avatar, Box, CardActionArea, CardContent, Stack } from "@mui/material";
import { blue } from "@mui/material/colors";
import { BorderCard, GreyTypography } from "assets/Mui/globalTheme";
import { perks } from "common/constants/perks";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import TextDivider from "utils/textDividerPrimary";
import { ZA } from "common/constants/userModules";
import { parseNumberInput } from "hooks/customPhoneNumberParse";
export default function CommonProfile({ userInfo, setUserInfo }) {
  const [company, setCompany] = useState({});
  const [selectedPerks, setSelectedPerks] = useState([]);
  const [model, setModel] = useState(false);
  const role = JSON.parse(localStorage.getItem('authUser')).role
  useEffect(() => {
    setCompany(userInfo);
    const data = perks?.filter(item => {
      return userInfo?.perks?.includes(item?.name);
    });
    setSelectedPerks(data);
  }, [userInfo]);
  function toggleModel() {
    setModel(!model);
  }

  return (
    <Stack spacing={2} sx={{ padding: "10px 0px" }}>
      <TextDivider type="first name" />
      <BorderCard>
        <CardActionArea>
          <CardContent>
            <GreyTypography variant="h6" component="h6">
              {company?.first_name}
            </GreyTypography>
          </CardContent>
        </CardActionArea>
      </BorderCard>

      <TextDivider type="last name" />
      <BorderCard>
        <CardActionArea>
          <CardContent>
            <GreyTypography variant="h6" component="h6">
              {company?.last_name}
            </GreyTypography>
          </CardContent>
        </CardActionArea>
      </BorderCard>

      <TextDivider type="email" />
      <BorderCard>
        <CardActionArea>
          <CardContent>
            <GreyTypography
              sx={{ textTransform: "lowercase !important" }}
              variant="h6"
              component="h6"
            >
              {company?.email}
            </GreyTypography>
          </CardContent>
        </CardActionArea>
      </BorderCard>

      {![ZA].includes(role) ? (
        <>
          <TextDivider type="mobile" />
          <BorderCard>
            <CardActionArea>
              <CardContent>
                <GreyTypography variant="h6" component="h6">
                  {parseNumberInput(company?.phone_number)}
                </GreyTypography>
              </CardContent>
            </CardActionArea>
          </BorderCard>
        </>
      ) : null}
    </Stack>
  );
}
