import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CompanyForm from "./company/companyForm";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};
export default function EditProfile({ open, toggleModel, user }) {

  return (
    <div>
      <Modal
        open={open}
        onClose={toggleModel}
        aria-labelledby="edit-profile-modal-title"
        aria-describedby="edit-profile-modal-description"
      >
        <Box sx={style}>
          <CompanyForm user={user} toggleModel={toggleModel} />
        </Box>
      </Modal>
    </div>
  );
}
