import * as React from "react";
import {useState,useEffect} from 'react';
import { GridToolbar } from "@mui/x-data-grid";
import { imageURL } from "common/constants/commonURLS";
import {
  MenuProps,
  PrimaryButton,
  PrimaryButtonForTable,
  PrimaryDataGrid,
  PrimaryNormalInputs,
  PrimarySwitch,
  SmallPrimaryAvatar,
  SmallSizeChip,
  DownloadButton,
  CompanyTypography,
  StyledOutlinedInput,
  CompanySizeChip,
  ApproveChip,
  SkillsChip
} from "assets/Mui/globalTheme";
import InputAdornment from "@mui/material/InputAdornment";
import JobSearchIcon from "assets/images/icons/JobSearchIcon";
import AddIcon from '@mui/icons-material/Add';
import TablePopper from "components/table/header/popper";
import { getAllScreeningPartnerWithStatus } from "helpers/services/screeningPartner/screeningPartner";
import { getAllRecruitmentUsersList } from "helpers/services/RecrutementCompany/recrutement";
import { Box, FormControl, MenuItem, Select,Pagination } from "@mui/material";
import { getAllIndustries } from "helpers/services/jobAttributes/industries";
import IndustryInput from "components/table/header/industrySelect";
import { IndustryOperators } from "components/table/operators/industryOperators";
import { LocationOperators } from "components/table/operators/locationOperators";
import LoaderIcon from "utils/icons/loaderIcon";
import { GenderOperators } from "components/table/operators/genderOperators";
import { WorkModeOperators } from "components/table/operators/worModeOperators";
import { useHistory,useLocation } from "react-router-dom";
import { PrimaryChip, HoldChip } from "assets/Mui/globalTheme";
import SkillViewModal from "helpers/Modals/SkillViewModal";
import InfoIcon from "@mui/icons-material/Info";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { StyledGridOverlay } from "assets/Mui/globalTheme";
import CustomNoRowsOverlay from "common/data/NoRowsFoundDatagrid/NoRowsFound";
import { CA, CS, ZA } from "common/constants/userModules";
import CompanyTypeModel from "./addCompanyType";
import { CircularProgress, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { StageSearchInput } from "assets/Mui/globalTheme";
import { useDebounce } from "hooks/useDebounce";
import SearchParamHook from "hooks/searchParams";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import DownloadIcon from "@mui/icons-material/Download";
import downloadCsv from 'download-csv';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import { parseNumberInput } from "hooks/customPhoneNumberParse";
import { socket } from "Sockets/socket";
import GlobalTableTools from "hooks/GlobalTableTools";

export default function RecrutingCompanyTable() {

  const [openSearch, setOpenSearch] = useState(true)

  const searchParamsHook = SearchParamHook()

  const [screeningPartners, setScreeningPartners] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [openSkillModal, setOpenSkillModal] = React.useState(false);
  const [modalName, setModalName] = React.useState("");
  const loginStore = JSON.parse(localStorage.getItem("authUser"));
  const role = loginStore?.role
  const [viewAddModal, setViewAddModal] = React.useState(false)
  const [update,setUpdate] = React.useState(false)
  const [refetch,setRefetch] = React.useState(false)
  const currentPage = searchParamsHook.get('page')
  const searchTextParam = searchParamsHook.get('searchParam')
  const [page, setPage] = React.useState(currentPage ?? 1);
  const [searchText, setSearchText] = useState(searchTextParam ?? "");
  const [totalCount,setTotalCount] = React.useState("")
  const rowsPerPage = 20;
  const offset = (page - 1) * rowsPerPage;
  const {search} = useLocation()
  const history = useHistory();
  const [totalRecords, setTotalRecords] = React.useState('')

  React.useEffect(() => {
    initialFetch(role,page,rowsPerPage,searchText);
  }, [update]);

  React.useEffect(()=>{
    const searchParams = new URLSearchParams(search)
    searchParams.set("page",page)
    history.push({
      search: searchParams.toString(),
    });
    initialFetch(role,page,rowsPerPage,searchTextParam);
  },[page,searchParamsHook.get("searchParam")])

  
     /* seacrh */

     useEffect(() => {
 
      const searchParams = new URLSearchParams(search);
      setSearchText(searchParams.get("searchParam") ?? "");
      setPage(searchParamsHook.get("page") ?? 1);
    }, []);
  
    const debouncedQuery = useDebounce(searchText, 500);
  
    useEffect(() => {
      if(debouncedQuery){
        const searchParams = new URLSearchParams(search);
        searchParams.set("searchParam", debouncedQuery);
        setPage(1)
        history.push({
          search: searchParams.toString(),
        });
      }
      else {
        const searchParams = new URLSearchParams(search);
        searchParams.set("searchParam", debouncedQuery);
        // setPage(1)
        history.push({
          search: searchParams.toString(),
        });
      }
   
  
    }, [debouncedQuery]);
  
    /* seacrh */
  
    const handleSearchOpen = ()=>{
      setOpenSearch(!openSearch)
    }


  async function initialFetch(role,page,rowsPerPage,searchText) {
    setLoading(true);
    const { data, status } = await getAllRecruitmentUsersList(role,page,rowsPerPage,searchText);
    // setTotalCount(Math.ceil((data?.count)/rowsPerPage))
    setTotalCount(data?.total_page_size)
    setTotalRecords(data?.overall_count)
    const result = data?.data_payload?.length > 0 && data?.data_payload?.map((item, index) => ({
      id: item?.id,
      email: `${item?.email}`,
      image: `${imageURL}${item?.image}`,
      phone_number: `${parseNumberInput(item?.phone_number)}`,
      gender: `${item?.gender?item.gender?.replaceAll("_"," "):"-"}`,
      location: `${item?.location?item.location:"-"}`,
      industry: `${
        item?.industry_details?.length > 0
          ? item?.industry_details?.map(item => item?.name)?.join(",")
          : "-"
      }`,

      Categories: `${
        item?.functional_area_details?.length > 0
          ? item?.functional_area_details?.map(item => item?.name)?.join(",")
          : "N/A"
        }`,
      companies_assigned:item?.assigned_company_details?.length > 0
        ? item?.assigned_company_details?.map(item => item?.company_name)?.join(",")
        : "N/A" ,
      skills: `${
          item?.skill_details?.length > 0
            ? item?.skill_details?.map(item => item?.name)?.join(",")
            : "N/A"
        }`,    
    //   experience: `${item?.experience}`,
    //   work_mode: `${item?.work_mode}`,
    //   candidate_screened: `${item?.candidate_screened}`,
    //   candidate_onboarded: `${item?.candidate_onboarded}`,
    //   total_earnings: `${item?.total_earnings}`,
    //   available_earnings: `${item?.available_earnings}`,
    //   pending_earnings: `${item?.pending_earnings}`,
    //   highest_education: `${item?.highest_education}`,
    //   jobs_screened: item?.get_screened_job_post_counts?.count,
      name: `${item?.first_name} ${item?.last_name}`,
      Status: `${item.user_approval}`,
    }));
    if (status === 200) {
      setLoading(false);
      // setTotalCount(data?.count)
    }
    setScreeningPartners(result);
  }

  const handleCloseModal = () => {
    setOpenSkillModal(!openSkillModal);
  };
  
  const handleCellClick = params => {
    const field = params.field;

    if (field === "skills") {
      if (params.row.skills !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.skills.split(","));
        setOpenSkillModal(!openSkillModal);
      }
    } else if (field === "Categories") {
      if (params.row.Categories !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.Categories.split(","));
        setOpenSkillModal(!openSkillModal);
      }
    }else if (field === "companies_assigned") {
      if (params.row.companies_assigned !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.companies_assigned.split(","));
        setOpenSkillModal(!openSkillModal);
      }
    }
    else {
      if(!["skills","Categories","companies_assigned"].includes(field) && ![CA,CS].includes(loginStore.role))
        history.push(`/common-user/${params.row.id}`);

      // history.push(`/common-user/${params.row.id}`);
    }
  };


  const handleDownload = async()=>{
    const { data, status } = await getAllRecruitmentUsersList(
      role,
      page,
      totalRecords,
      searchText
    );

    const result = data?.data_payload?.length > 0 && data?.data_payload?.map((item, index) => ({
      id: item?.id,
      email: `${item?.email}`,
      image: `${imageURL}${item?.image}`,
      phone_number: `${item?.phone_number}`,
      gender: `${item?.gender?item.gender:"N/A"}`,
      location: `${item?.location?item.location:"N/A"}`,
      industry: `${
        item?.industry_details?.length > 0
          ? item?.industry_details?.map(item => item?.name)?.join(",")
          : "N/A"
      }`,

      Categories: `${
        item?.functional_area_details?.length > 0
          ? item?.functional_area_details?.map(item => item?.name)?.join(",")
          : "N/A"
        }`,
      companies_assigned:item?.assigned_company_details?.length > 0
        ? item?.assigned_company_details?.map(item => item?.company_name)?.join(",")
        : "N/A" ,
      skills: `${
          item?.skill_details?.length > 0
            ? item?.skill_details?.map(item => item?.name)?.join(",")
            : "N/A"
        }`,    
    //   experience: `${item?.experience}`,
    //   work_mode: `${item?.work_mode}`,
    //   candidate_screened: `${item?.candidate_screened}`,
    //   candidate_onboarded: `${item?.candidate_onboarded}`,
    //   total_earnings: `${item?.total_earnings}`,
    //   available_earnings: `${item?.available_earnings}`,
    //   pending_earnings: `${item?.pending_earnings}`,
    //   highest_education: `${item?.highest_education}`,
    //   jobs_screened: item?.get_screened_job_post_counts?.count,
      name: `${item?.company_name}`,
      Status: `${item.user_approval}`,
    }));
  

   const columns=[
      {
        field: "image",
        headerName: "Image",
        filterable: false,
        disableColumnMenu:true,
        renderCell: params => {
          return (
            <SmallPrimaryAvatar
              imgProps={{ loading: "lazy" }}
              src={params?.formattedValue}
            >
              {params?.row?.name?.charAt(0)?.toUpperCase()}
            </SmallPrimaryAvatar>
          );
        },
      },
      { field: "name", headerName: "Name",width: 200 },
      { field: "phone_number", headerName: "Phone Number",width: 300 },
      { field: "email",headerName: "Email", width: 300 },
      {
        field: "Status",
        headerName: "Status",
        width: 200,
        renderCell: params => {
          if (params.formattedValue !== "Approve") {
            return <HoldChip label={params?.formattedValue} size="small"/>;
          }
          return <PrimaryChip label={params?.formattedValue} size="small"/>;
        },
      },
      { field: "gender",headerName: "Gender", filterOperators: GenderOperators },
      { field: "location",headerName: "Location", filterOperators: LocationOperators, width: 400 },
      { field: "industry",headerName: "Industry", filterOperators: IndustryOperators, width: 400 },
      {
        field: "Categories",
        headerName: "Categories",
        width: 400,
        renderCell: paramss => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {paramss.value && paramss.value !== "N/A" ? (
              <>
                {/* <span>
                  {paramss.value.split(",").slice(0, 2).join(",")}
                </span>
                {paramss.value.split(",").length > 1 && (
                  <InfoOutlinedIcon
                    style={{
                      marginLeft: 5,
                      cursor: "pointer",
                      opacity: "50%",
                    }}
                  />
                )} */}
                   {paramss.value.split(",").slice(0,2)?.map((each)=><SmallSizeChip label={each} style={{margin:"1px"}} size="small"/>)}
              </>
            ) : (
              <span>N/A</span>
            )}
          </div>
        ),
      },
      ["ZA"].includes(role) ? (
      {
        field: "companies_assigned",
        width: 400,
        headerName:"Companies Assigned",
        renderCell: params => (
          <div style={{ display: "flex", alignItems: "center",gap:"8px" }}>
            {params.value && params.value !== "N/A" ? (
              <>
                   {params.value.split(",").slice(0,2)?.map((each)=><SmallSizeChip label={each} style={{margin:"1px"}} size="small"/>)}
              </>
            ) : (
              <span>N/A</span>
            )}
          </div>
        ),
      }
      ) : "",

      {
        field: "skills",
        headerName: "Skills",
        width: 400,
        renderCell: params => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {params.value && params.value !== "N/A" ? (
              <>
                {/* <span>{params.value.split(",").slice(0, 2).join(",")}</span>
                {params.value.split(",").length > 1 && (
                  <InfoOutlinedIcon
                    style={{
                      marginLeft: 5,
                      cursor: "pointer",
                      opacity: "50%",
                    }}
                  />
                )} */}
                 {params.value.split(",").slice(0,2)?.map((each)=><SmallSizeChip label={each} style={{margin:"1px"}} size="small"/>)}
              </>
            ) : (
              <span>N/A</span>
            )}
          </div>
        ),
      },          
   
    ]
  
    downloadCsv(result, columns,'Recruitment company');
  }

  function CustomToolbar(){
    return (
      <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <CompanyTypography variant="h1" component="h1">
         Recruitment Company
        </CompanyTypography>
        <div style={{ justifyContent: "flex-end", display: "flex" }}>
          <GridToolbarContainer>

          <StyledOutlinedInput
              placeholder="search..."
              value={searchText}
              onChange={e => handleChange(e)}
              // onChange={e => {
              //   e.stopPropagation();
              //   setSearchText(e.target.value);
              // }}
              startAdornment={
                <InputAdornment position="start">
                  <JobSearchIcon />
                </InputAdornment>
              }
            />

          {[ZA].includes(loginStore.role) && (
          <PrimaryButton
          startIcon={<AddIcon />}
            sx={{ width: "max-content !important", ml: "auto !important" }}
            onClick={() => {
              setViewAddModal(true);
            }}
          >
            Add New
          </PrimaryButton>
        )}

           
            {/* <GlobalSearch /> */}

            <GridToolbarColumnsButton sx={{ color: "#ffffff" }} />
            <GridToolbarFilterButton  sx={{ color: "#ffffff" }} />

            <DownloadButton
              size="medium"
              startIcon={<DownloadIcon />}
              onClick={handleDownload}
              sx={{ color: "#ffffff" }}
            >
              Download
            </DownloadButton>
          </GridToolbarContainer>
        </div>
      </div>
    </>
    )
}


const handleListnerEvent = data => {
  if (data?.type === "RECRUITMENT_COMPANY") {
    initialFetch(role, page, rowsPerPage, searchText);
  }
};

useEffect(() => {
  const handleReconnect = () => {
    // Re-register the event listener after reconnection if needed
    socket.on("listnerEvent", handleListnerEvent);
  };

  socket.on("listnerEvent", handleListnerEvent);

  // Attach a listener for socket reconnection
  socket.on("reconnect", handleReconnect);

  return () => {

    socket.off("listnerEvent", handleListnerEvent);
    socket.off("reconnect", handleReconnect);
  };
}, []);

  return (
    <div style={{ padding: "10px", height: "91vh",display:'flex',flexDirection:'column'  }}>

    {/* <Stack direction="row" alignItems="center" spacing={1.6}>
      {openSearch?( <SearchIcon onClick={handleSearchOpen} />):
     
      (<StageSearchInput
        placeholder="search..."
        // onChange={(e) => handleSearch(e)}
        value={searchText}
        onChange={(e) => {
          e.stopPropagation();
          setSearchText(e.target.value);
        }}
        endAdornment={
          <IconButton edge="end" 
          onClick={()=>{
            handleSearchOpen()
            setSearchText("")
          }}
          >
            <ClearIcon />
          </IconButton>
        }
      />)
}
      {[ZA].includes(loginStore.role) && (
        <PrimaryButton
          sx={{ width: "max-content !important", ml: "auto !important" }}
          onClick={() => {
            setViewAddModal(true);
          }}
        >
          Add
        </PrimaryButton>
      )}
    </Stack> */}


<GlobalTableTools handleDownload={handleDownload} tableHeading={"Recruitment Company"} toggel={true} setViewAddModal={setViewAddModal}  />
      <PrimaryDataGrid
        // onCellClick={event => {
        //   history.push(`/common-user/${event?.row?.id}`);
        // }}
        loading={loading}
        onCellClick={handleCellClick}
        // checkboxSelection={true}
        columns={[
          {
            field: "image",
            headerName: "Name",
            width:200,
            filterable: false,
            disableColumnMenu:true,
            headerClassName: "super-app-theme--header",
            // renderCell: params => {
            //   return (
            //     <SmallPrimaryAvatar
            //       imgProps={{ loading: "lazy" }}
            //       src={params?.formattedValue}
            //     >
            //       {params?.row?.name?.charAt(0)?.toUpperCase()}
            //     </SmallPrimaryAvatar>
            //   );
            // },
            renderCell: params => {
              return (
                <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                  <SmallPrimaryAvatar
                    imgProps={{ loading: "lazy" }}
                    src={params?.formattedValue}
                  >
                    {params?.row?.name?.charAt(0)?.toUpperCase()}
                  </SmallPrimaryAvatar>
                  <p>{params?.row?.name}</p>
                </div>
              );
            },
          },
          // { field: "name", headerName: "Name", headerClassName: "super-app-theme--header",width: 200 },
          { field: "phone_number", headerName: "Phone Number", headerClassName: "super-app-theme--header",width: 300 },
          { field: "email",headerName: "Email",  headerClassName: "super-app-theme--header",width: 300 },
          {
            field: "Status",
            headerName: "Status",
            headerClassName: "super-app-theme--header",
            width: 200,
            renderCell: params => {
              if (params.formattedValue !== "Approve") {
                return <HoldChip label={params?.formattedValue} size="small"/>;
              }
              return <ApproveChip label={params?.formattedValue} size="small"/>;
            },
          },
          { field: "gender",headerName: "Gender", headerClassName: "super-app-theme--header", filterOperators: GenderOperators },
          { field: "location",headerName: "Location", headerClassName: "super-app-theme--header", filterOperators: LocationOperators, width: 400 },
          { field: "industry",headerName: "Industry", headerClassName: "super-app-theme--header", filterOperators: IndustryOperators, width: 400 },
          {
            field: "Categories",
            headerName: "Categories",
            headerClassName: "super-app-theme--header",
            width: 400,
            // renderCell: paramss => (
            //   <div style={{ display: "flex", alignItems: "center" }}>
            //     {paramss.value && paramss.value !== "N/A" ? (
            //       <>
                  
            //            {paramss.value.split(",").slice(0,2)?.map((each)=><SmallSizeChip label={each} style={{margin:"1px"}} size="small"/>)}
            //       </>
            //     ) : (
            //       <span>N/A</span>
            //     )}
            //   </div>
            // ),

            renderCell: params => {
              const values =
                params.value && params.value !== "N/A"
                  ? params.value.split(",")
                  : [];
              const firstValue = values.slice(0, 1);
              const remainingCount = values.length - 1;
      
              return (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  {firstValue.map((each, index) => (
                    <CompanySizeChip
                      key={index}
                      label={each}
                      style={{ margin: "1px" }}
                      size="small"
                    />
                  ))}
                  {remainingCount > 0 && (
                    <CompanySizeChip
                      label={`+${remainingCount}`}
                      style={{ margin: "1px" }}
                      size="small"
                    />
                  )}
                  {values.length === 0 && <span>-</span>}
                </div>
              );
            },
          },
          ["ZA"].includes(role) ? (
          {
            field: "companies_assigned",
            width: 400,
            headerName:"Companies Assigned",
            headerClassName: "super-app-theme--header",
            // renderCell: params => (
            //   <div style={{ display: "flex", alignItems: "center",gap:"8px" }}>
            //     {params.value && params.value !== "N/A" ? (
            //       <>
            //            {params.value.split(",").slice(0,2)?.map((each)=><SmallSizeChip label={each} style={{margin:"1px"}} size="small"/>)}
            //       </>
            //     ) : (
            //       <span>N/A</span>
            //     )}
            //   </div>
            // ),

            renderCell: params => {
              const values =
                params.value && params.value !== "N/A"
                  ? params.value.split(",")
                  : [];
              const firstValue = values.slice(0, 1);
              const remainingCount = values.length - 1;
      
              return (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  {firstValue.map((each, index) => (
                    <SmallSizeChip
                      key={index}
                      label={each}
                      style={{ margin: "1px" }}
                      size="small"
                    />
                  ))}
                  {remainingCount > 0 && (
                    <SmallSizeChip
                      label={`+${remainingCount}`}
                      style={{ margin: "1px" }}
                      size="small"
                    />
                  )}
                  {values.length === 0 && <span>-</span>}
                </div>
              );
            },

          }
          ) : "",

          {
            field: "skills",
            headerName: "Skills",
            headerClassName: "super-app-theme--header",
            width: 400,
            // renderCell: params => (
            //   <div style={{ display: "flex", alignItems: "center" }}>
            //     {params.value && params.value !== "N/A" ? (
            //       <>
            //          {params.value.split(",").slice(0,2)?.map((each)=><SmallSizeChip label={each} style={{margin:"1px"}} size="small"/>)}
            //       </>
            //     ) : (
            //       <span>N/A</span>
            //     )}
            //   </div>
            // ),

            renderCell: params => {
              const values =
                params.value && params.value !== "N/A"
                  ? params.value.split(",")
                  : [];
              const firstValue = values.slice(0, 1);
              const remainingCount = values.length - 1;
        
              return (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  {firstValue.map((each, index) => (
                    <SkillsChip
                      key={index}
                      label={each}
                      style={{ margin: "1px" }}
                      size="small"
                    />
                  ))}
                  {remainingCount > 0 && (
                    <SkillsChip
                      label={`+${remainingCount}`}
                      style={{ margin: "1px" }}
                      size="small"
                    />
                  )}
                  {values.length === 0 && <span>-</span>}
                </div>
              );
            },

          },          
        //   { field: "Categories", width: 400 },
        //   { field: "experience" },
        //   {
        //     field: "work_mode",
        //     filterOperators: WorkModeOperators,
        //     width: 200,
        //   },
        //   { field: "candidate_screened", width: 200 },
        //   { field: "candidate_onboarded", width: 200 },
        //   { field: "total_earnings", width: 200 },
        //   { field: "available_earnings", width: 200 },
        //   { field: "pending_earnings", width: 200 },
        //   { field: "highest_education", width: 200 },
        //   { field: "Bank_account_details" },
        ]}
        rows={screeningPartners}
        slots={{
          loadIcon: LoaderIcon,
          baseButton: PrimaryButton,
          basePopper: TablePopper,
          baseSwitch: PrimarySwitch,
   
          // toolbar:CustomToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
          pagination: props => (
            <Pagination
              {...props}
              // color="primary"
              // count={Math.ceil(rowsData.length / rowsPerPage)}
              count={totalCount}
              page={page}
              rowsPerPage={rowsPerPage}
              onChange={(_, newPage) => setPage(newPage)}
              showFirstButton
              showLastButton
              sx={{
                "& .MuiPaginationItem-root": {
                  "&:hover": {
                    backgroundColor: "#cde2e7",
                    color: "#066a4c",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "#024430",
                    color: "#ffe7bb",
                    "&:hover": {
                      backgroundColor: "#cde2e7",
                      color: "#066a4c",
                    },
                  },
                },
              }}
            />
          ),
        }}
        slotProps={{
          baseButton: { style: { width: "100px" } },
          panel: {
            style: {
              right: "0px !important",
              transform: "none !important",
              inset: "none !important",
              top: "138px !important",
            },
          },
          filterPanel: {
            style: {
              right: "0px !important",
              transform: "none !important",
              inset: "none !important",
              top: "138px !important",
            },
          },
          toolbar: { style: {} },
          preferencesPanel: {
            style: {
              right: "0px !important",
              transform: "none !important",
              inset: "none !important",
              top: "138px !important",
            },
          },
        }}
        sx={{
          "& .super-app-theme--header": {
            backgroundColor: "rgba(250, 250, 250, 1)",
          },
        }}
      />
        {openSkillModal && (
        <SkillViewModal
          rowdata={selectedRowData}
          closeModalFunc={handleCloseModal}
          openModal={openSkillModal}
          headerName={modalName}
          chipColor={"primarychip"}
        />
      )}
      {viewAddModal && (
        <CompanyTypeModel isOpen={viewAddModal} updated={()=>setUpdate(pre=>!pre)} toggle={()=>setViewAddModal(false)} />
      )}
    </div>
  );
}
