import OtpInput from "react18-input-otp";
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  GreyTypography,
  PrimaryButton,
  PrimaryTypography,
} from "assets/Mui/globalTheme";
import ErrorHandlingToaster from "helpers/Toaster/error";
import { handleResendOTP, verifyMobileOTP,verifyMobileOTPStaff } from "helpers/services/otpVerifiy/verify";
import { updateCommonUser } from "helpers/services/common";
import { toast } from "react-hot-toast";
import { createAdminStaff, updateAdminStaff } from "helpers/services/adminStaff/adminStaff";
import { createCompanyStaff, updateCompanyStaff } from "helpers/services/companies/company";
import { createBackgroundVerificationCompanyStaff, updateBackgroundVerificationCompanyStaff } from "helpers/services/BackgroundVerifications/backgroundVerifications";
import { createRecrutementCompanyStaff } from "helpers/services/RecrutementCompany/recrutement";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  backdropFilter: blur("4px"),
  p: 4,
};

export default function StaffOtp({
  isOpen,
  toggle,
  phone_number,
  reUpdate,
  userData,
  backendData,
  handleClose,
  clearField,
  isApprove,
  purpose,
  id,
  fetchData
}) {

  const role = JSON.parse(localStorage.getItem("authUser")).role
  const [otp, setOtp] = React.useState("");
  const [min, setMin] = React.useState(5);
  const [sec, setSec] = React.useState(0);
  const [hasError, setHassError] = React.useState(false);
  const [otpStatus, setOtpStatus] = React.useState(200);
  const handleOTP = async () => {
    // const res = await verifyMobileOTP(otp);
    const data = {
      otp:otp,
      email:userData?.email,
      user_password:userData?.user_password
    }
    const res = await verifyMobileOTPStaff(data)
    const { status } = res
    if(status){
      fetchData()
    }
    let success;
    if (status === 200) {
      if (purpose === "EDIT") {
        // delete userData.user_password;
        // switch (role) {
        //   case "ZA":
        //    success = await updateAdminStaff({ ...userData,isApprove }, id);
        //     break;
        //   case "CA":
        //    success = await updateCompanyStaff({ ...userData }, id);
        //     break;
  
        //   case "BVC":
        //   success =  await updateBackgroundVerificationCompanyStaff({ ...userData }, id);
        //     break;
        //   default:
        //     return null;
        // }

      } else {

        // switch (role) {
        //   case "ZA":
        //    success = await createAdminStaff({ ...userData, usertype: backendData,isApprove });
        //     break;
        //   case "CA":
        //    success = await createCompanyStaff({ ...userData, usertype: backendData });
        //     break;
        //   case "BVC":
        //   success =  await createBackgroundVerificationCompanyStaff({
        //       ...userData,
        //       usertype: backendData,
        //     });
        //     break;
        //   case "RC":
        //    success = await createRecrutementCompanyStaff({
        //       ...userData,
        //       usertype: backendData,
        //     });
        //     break;
        //   default:
        //     return null;
        // }

        reUpdate();
      }
      // setHassError(false);
      if([201,200].includes(success?.status))
         toast.success("updated successfully")
      handleClose();
      clearField();
      toggle();
    } else {
      handleClose();
      clearField();
      reUpdate();
      toggle();      
    }
  };
  const handleChange = otp => setOtp(otp);




  React.useEffect(() => {
    let intervalId;
  
    // Start the timer only if the modal is open and the timer is not already running
    if (isOpen && min >= 0 && sec >= 0) {
      intervalId = setInterval(() => {
        if (min === 0 && sec === 0) {
          clearInterval(intervalId);
          return;
        }
  
        if (sec > 0) {
          setSec(sec - 1);
        } else {
          setMin(min => min - 1);
          setSec(59);
        }
      }, 1000);
    } else {
      // Clear the interval when the modal is closed
      clearInterval(intervalId);
    }
  
    return () => clearInterval(intervalId);
  }, [sec, min, isOpen]);

  
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={toggle}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="modal-body">
            <div className="otp_parent">
              <div className="phone_no">
                <span className="enter_otp">Verify OTP</span>
              </div>
              <PrimaryTypography variant="h3" className="otp_imp">
                {/* {min}:{sec} */}
                {`${min.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}`}
              </PrimaryTypography>
              <GreyTypography variant="h6" style={{ textAlign: "center" }}>
                We have send you a one time password in this mobile number
              </GreyTypography>
              <PrimaryTypography variant="h4" style={{ textAlign: "center" }}>
                {phone_number}
              </PrimaryTypography>
              <div className="otp_columns">
                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  shouldAutoFocus
                  isInputNum={true}
                  inputStyle="otp_input_items"
                  errorStyle={"otp_input_items_error"}
                  hasErrored={hasError}
                  focusStyle={"otp_input_items_focus"}
                  separator={<span> </span>}
                  onSubmit={handleOTP}
                />
              </div>
              <div className="help_otp">
                {otpStatus !== 400 ? (
                  <button
                    onClick={async () => {
                      const { status } = await handleResendOTP(userData?.email);
                      setOtpStatus(status);
                      if (status === 400) {
                        toast.error("Otp Limit Expired");
                      }
                      else if(status === 201){
                        toast.success("Otp sent successfully!")
                      }
                    }}
                    // style={{"&:disabled":{opacity:0.2,border:"none"}}}
                    // disabled={!(min===0 && sec===0)}
                    className="btn"
                    to="/login"
                  >
                    <span className="info_txt">Don&rsquo;t Received Otp?</span>
                    <span>Resend OTP</span>
                  </button>
                ) : (
                  ""
                )}
                <PrimaryButton
                  // className={` ${otp.length === 6 ? "" : ""}`}
                  disabled={otp.length === 6 ? false : true}
                  type="submit"
                  onClick={handleOTP}
                >
                  Verify OTP
                </PrimaryButton>
                <p className="info_txt">You only have 3 tries In a day</p>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
