import React, { useEffect, useMemo, useState } from "react";
import { Grid, Stack, Typography, Box, Divider, Button, InputAdornment, IconButton, FormControlLabel, Select, MenuItem, FormControl, Radio, CircularProgress } from "@mui/material";
import GoogleIcon from '../../../assets/images/icons/GoogleIcon';
import AppleLogoIcon from '../../../assets/images/icons/AppleLogoIcon';
import { Link, useHistory, useLocation, withRouter, useNavigate } from "react-router-dom";
import "../../../assets/css/auth/signup.css";
import OtpModel from "components/Common/models/otpModel";
import { convertRoleForApi } from "helpers/roleFinder";
import { createCompanies } from "helpers/services/companies/company";
import { createScreenPartner } from "helpers/services/screeningPartner/screeningPartner";
import { verifyOTP } from "helpers/services/otpVerifiy/verify";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { CA, SP } from "common/constants/userModules";
import { createTechicalEvaluator } from "helpers/services/TechincalEvator/technicalEvaluator";
import { creaetBackgroundVerifications } from "helpers/services/BackgroundVerifications/backgroundVerifications";
import { creteRecrutementCompany } from "helpers/services/RecrutementCompany/recrutement";
import { getOtpFunction } from "helpers/services/companies/company";
import { useForm, Controller } from "react-hook-form";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {
    PrimaryTypography,
    GreyTypography,
    PrimaryNormalInputLabel,
    PrimaryNormalInputs,
    PrimaryNormalFormHelperText,
    PrimaryButton,
    LargeSemiboldTypography,
    StyledLoginTabsObject,
    SolidTab,
    PrimaryTooltip,
} from "assets/Mui/globalTheme";
import { validatePhoneNumber } from "hooks/customPhoneNumberParse";
// import MuiPhoneNumber from "material-ui-phone-number";
import ErrorHandlingToaster from "helpers/Toaster/error";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
            width: 250,
            borderRadius: "10px",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
        },
    },
};


function RegisterAuthForm() {


    const [showPassword, setShowPassword] = useState(false);
    const [cShowPasswrod, setCShowPassword] = useState(false);
    const [userType, setUserType] = useState("CA");
    const [type, setType] = useState("CA");
    const { search, pathname } = useLocation();
    const history = useHistory();

    const [submittedData, setSubmittedData] = useState("")

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    useEffect(() => {
        const user_type = searchParams.get("user_type");
        const otp_form = searchParams.get("otp_form");
        if (otp_form) {
            // setOtpModel(true);
        } else {
            setOtpModel(false);
        }
        setValue("register_type", user_type);
        if (user_type !== null) setUserType(user_type);
        else {
            setUserType("CA");
            // searchParams.set("user_type", "CA");
            // history.push({ search: searchParams.toString() });
        }
    }, [searchParams]);
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [otpModel, setOtpModel] = useState("");

    async function handleData(data) {
        data['email'] = data['email'].toLowerCase()
        // setOtpModel(true);
   
        if (validatePhoneNumber(data?.phone_number)) {
            setError('phone_number', {
                type: "custom",
                message: validatePhoneNumber(data?.phone_number),
              })
            return;
        }

        let items;
        localStorage.setItem("email", data.email);
        localStorage.setItem("first_name", data.first_name);
        localStorage.setItem("company_name", data?.company_name);
        localStorage.setItem("last_name", data.last_name);
        setEmail(data?.email);
        setPhoneNumber(data?.phone_number);
        setType(data?.user_type);

        /* This is for OTP verifying testing in progress */

        // const getOtpMethod = await getOtpFunction(phoneNumber)
        // const {data, status} = getOtpMethod 
        // if(status == 200){
        //   setOtpModel(true);
        // }


        /* This is for OTP verifying testing in progress */


        async function findRegisterApis() {
            switch (data?.user_type) {
                case "CA":
                    return await createCompanies({
                        ...data,
                        userType: convertRoleForApi(data?.user_type),
                    });
                case "SP":
                    return await createScreenPartner({
                        ...data,
                        userType: convertRoleForApi(data.user_type),
                    });
                case "RC":
                    return await creteRecrutementCompany({
                        ...data,
                        userType: "RECRUITMENT_COMPANY",
                    });
                case "TE":
                    return await createTechicalEvaluator({
                        ...data,
                        userType: convertRoleForApi(data.user_type),
                    });
                case "BVC":
                    return await creaetBackgroundVerifications({
                        ...data,
                        userType: "BACKGROUND_VERIFICATION",
                    });
                default:
                    return await createCompanies({
                        ...data,
                        userType: convertRoleForApi("CA"),
                    });
            }
        }
        items = await findRegisterApis();
        if (items === undefined) {
            setOtpModel(false)
        } else {
            setOtpModel(true)
        }
        if (items?.status !== 201) {
            ErrorHandlingToaster(items);
            return;
        }


        // searchParams.set("otp_form", true);
        // history.push({ search: searchParams.toString() });

     
        // setOtpModel(true);
    }


    function handleShowCPassword() {
        setCShowPassword(!cShowPasswrod);
    }
    function handleShowPassword() {
        setShowPassword(!showPassword);
    }
    function handlePartnerTabChange(_, data) {
        if (data?.toLowerCase() === "candidate") {
            window.open("//www.joinee.com/login", "_self");
            return;
        }
        setUserType(data);
        searchParams.set("user_type", data);
        setValue("register_type", data);
        history.replace({ search: searchParams.toString() });
    }
    function storeAuthUser(data) {
        localStorage.setItem("authUser", JSON.stringify(data));
    }
    async function handleLogin(otp) {
        const { data, status } = await verifyOTP(getValues("email"), otp);
        if (status !== 201) return status;
        const type = watch("user_type");
        switch (type) {
            case CA:
                storeAuthUser({
                    token: data.access,
                    role: CA,
                    company_id: data.id,
                });
                history.push("/company-create", {
                    from: pathname,
                });
                break;
            case SP:
                storeAuthUser({
                    token: data.access,
                    role: SP,
                    screeningPartner_id: data.id,
                });
                history.push("/screening-partner-create", {
                    from: pathname,
                });
                break;
            case "RC":
                storeAuthUser({
                    token: data.access,
                    role: "RC",
                    recrutement_company_id: data.company_id,
                });
                history.push("/login", {
                    from: pathname,
                });
                break;
            case "TE":
                storeAuthUser({
                    token: data.access,
                    role: "TE",
                    technical_eval_id: data.id,
                });
                history.push("/login", {
                    from: pathname,
                });
                break;
            case "BVC":
                storeAuthUser({
                    token: data.access,
                    role: "BVC",
                    background_verification_id: data.id,
                });
                history.push("/login", {});
                break;
            default:
                storeAuthUser({
                    token: data.access,
                    role: CA,
                    company_id: data.id,
                });
                history.push("/company-create", {
                    from: pathname,
                });
                break;
        }
    }

    const interviewer = [
        { name: "Screening Partner", value: "SP" },
        // { name: "Account Manager", value: "ACM" },
        // { name: "Moderator", value: "ZM" },
        { name: "Technical Evaluator", value: "TE" },
        { name: "Background Verification Company", value: "BVC" },
        { name: "Recruitment Company", value: "RC" },
    ];
    function handleOtpModel() {
        setOtpModel(!otpModel);
    }
    function getEmail() {
        return getValues("email");
    }

  
    // const isValidInput = (value) => /^[A-Za-z][A-Za-z0-9\s]*$/.test(value);
    const isValidInput = (value) => /^[A-Za-z0-9.\s]*$/.test(value);



    return (
        <React.Fragment>
            <OtpModel
                phone_number={phoneNumber}
                isOpen={otpModel}
                toggle={handleOtpModel}
                handleLogin={handleLogin}
                email={getEmail()}
            />
            <Box
                sx={{
                    minWidth: "300px",
                    width: "60%",
                    '@media (max-width:1200px)': {
                        width: "70%",
                    },
                    '@media (max-width: 900px)': {
                        width: "90%",
                    },
                    '@media (max-width: 600px)': {
                        width: "95%",
                    },
                }}
            >
                <form onSubmit={handleSubmit(handleData)}>
                    <Stack
                        sx={{
                            width: "100%",
                            gap: "35px",
                        }}
                    >
                        <Stack>
                            <Typography
                                sx={{
                                    fontWeight: "600",
                                    fontSize: "30px",
                                    textAlign: "center",
                                }}
                            >Sign Up to Joinee</Typography>
                            <Typography
                                sx={{
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    textAlign: "center",
                                    color: "#737373"
                                }}
                            >Sign up here</Typography>
                        </Stack>
                        <Stack
                            direction="column"
                            spacing={2}
                        >
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={3}
                            >
                                <Button component="label" variant="text" startIcon={<GoogleIcon />}
                                    sx={{
                                        border: "1px solid #DFE1E6",
                                        color: "#000",
                                        borderRadius: "8px",
                                        width: "50%"
                                    }}
                                >
                                    <Box sx={{
                                        textTransform: "none",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                    }}>
                                        Sign up with Google
                                    </Box>
                                </Button>
                                <Button component="label" variant="text" startIcon={<AppleLogoIcon />}
                                    sx={{
                                        border: "1px solid #DFE1E6",
                                        color: "#000",
                                        borderRadius: "8px",
                                        width: "50%"
                                    }}
                                >
                                    <Box sx={{
                                        textTransform: "none",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                    }}>
                                        Sign up with Apple
                                    </Box>
                                </Button>
                            </Stack>
                            <Stack>
                                <Divider sx={{ fontSize: "11px" }}>Or with email</Divider>
                            </Stack>
                            <Stack
                                direction="column"
                                spacing={1.5}
                            >
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Box
                                        sx={{
                                            width: "50%"
                                        }}
                                    >
                                        <FormControl sx={{ width: "100%" }} variant="standard">
                                            <PrimaryNormalInputLabel
                                                shrink
                                                htmlFor="login-first-name-outlined"
                                                error={errors.first_name}
                                            >
                                                First Name
                                            </PrimaryNormalInputLabel>
                                            <PrimaryNormalInputs
                                                fullWidth
                                                placeholder="Enter your First name"
                                                {...register("first_name", {
                                                    required: true,
                                                    pattern: /\S/,
                                                    validate: (value) => isValidInput(value) || 'Please enter a valid alphanumeric value.',
                                                })}
                                                id="login-first-name-outlined"
                                                autoFocus
                                                label="First Name"
                                                type={"text"}
                                                error={errors.first_name}
                                                variant="outlined"
                                            />
                                            <PrimaryNormalFormHelperText error={errors.first_name}>
                                                {errors?.first_name?.type === "required" ? "First name is required" : errors?.first_name?.message ? errors?.first_name?.message : null}
                                            </PrimaryNormalFormHelperText>
                                        </FormControl>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: "50%"
                                        }}
                                    >
                                        <FormControl sx={{ width: "100%" }} variant="standard">
                                            <PrimaryNormalInputLabel
                                                shrink
                                                htmlFor="login-last-name-outlined"
                                                error={errors.last_name}
                                            >
                                                Last name
                                            </PrimaryNormalInputLabel>
                                            <PrimaryNormalInputs
                                                fullWidth
                                                placeholder="Enter your Last name"
                                                {...register("last_name", {
                                                    required: true,
                                                    pattern: /\S/,
                                                    validate: (value) => isValidInput(value) || 'Please enter a valid alphanumeric value.',
                                                })}
                                                id="login-last-name-outlined"
                                                label="email address"
                                                type={"text"}
                                                error={errors.last_name}
                                                variant="outlined"
                                            />
                                            <PrimaryNormalFormHelperText error={errors.last_name}>
                                                {errors?.last_name?.type === "required" ? "Last name is required" : errors?.last_name?.message ? errors?.last_name?.message : null}
                                            </PrimaryNormalFormHelperText>
                                        </FormControl>
                                    </Box>
                                </Stack>
                                <Box>
                                    <FormControl sx={{ width: "100%" }} variant="standard">
                                        <PrimaryNormalInputLabel
                                            shrink
                                            htmlFor="login-email-outlined"
                                            error={errors.email}
                                        >
                                            Email
                                        </PrimaryNormalInputLabel>
                                        <PrimaryNormalInputs
                                            fullWidth
                                            placeholder="Enter your email"
                                            {...register("email", {
                                                required: true,
                                                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            })}
                                            id="login-email-outlined"
                                            label="email address"
                                            type={"text"}
                                            error={errors.email}
                                            variant="outlined"
                                        />
                                        <PrimaryNormalFormHelperText error={errors.email}>
                                            {errors?.email?.type === "required" ? "Email is required" : errors?.email?.type === "pattern" ? "Invalid email" : null}
                                        </PrimaryNormalFormHelperText>
                                    </FormControl>
                                </Box>
                                <Box>
                                    {userType === "CA" ? (
                                        <FormControl sx={{ width: "100%" }} variant="standard">
                                            <PrimaryNormalInputLabel
                                                shrink
                                                htmlFor="login-company-name-2-outlined"
                                                error={errors.company_name}
                                            >
                                                Company Name
                                            </PrimaryNormalInputLabel>
                                            <PrimaryNormalInputs
                                                fullWidth
                                                placeholder="Enter your company name"
                                                {...register("company_name", {
                                                    required: true,
                                                    pattern: /\S/,
                                                    validate: (value) => isValidInput(value) || 'Please enter a valid alphanumeric value.',
                                                })}
                                                id="login-company-name-2-outlined"
                                                label="email address"
                                                type={"text"}
                                                error={errors.company_name}
                                                variant="outlined"
                                            />
                                            <PrimaryNormalFormHelperText
                                                error={errors.company_name}
                                            >
                                                {errors?.company_name?.type === "required" ? "Company name is required" : errors?.company_name?.message ? errors?.company_name?.message : null}
                                            </PrimaryNormalFormHelperText>
                                        </FormControl>
                                    ) : (
                                        <FormControl sx={{ width: "100%" }}>
                                            <PrimaryNormalInputLabel
                                                error={errors?.user_type}
                                                shrink
                                                sx={{
                                                    left: "-12px",
                                                    top: "10px",
                                                }}
                                                id="signup-user-type"
                                            >
                                                Describe Yourself
                                            </PrimaryNormalInputLabel>
                                            <Select
                                                labelId="signup-user-type"
                                                // labelId="selct-user-type"
                                                id="demo-multiple-name"
                                                {...register("user_type", { required: true, pattern: /\S/ })}
                                                input={
                                                    <PrimaryNormalInputs
                                                        label="signup-user-type"
                                                        error={errors?.user_type}
                                                        {...register("user_type", { required: true })}
                                                    />
                                                }
                                                MenuProps={MenuProps}
                                            >
                                                {interviewer?.map(item => {
                                                    return (
                                                        <MenuItem
                                                            key={item?.value}
                                                            value={item?.value} // style={getStyles(name, personName, theme)}
                                                        >
                                                            {item?.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            <PrimaryNormalFormHelperText error={errors.user_type}>
                                                {errors?.user_type?.type === "required" ? "User type is required" : errors?.user_type?.message ? errors?.user_type?.message : null}
                                            </PrimaryNormalFormHelperText>
                                        </FormControl>
                                    )}
                                </Box>
                                <Box>
                                    <FormControl sx={{ width: "100%" }} variant="standard">
                                        <PrimaryNormalInputLabel
                                            shrink
                                            htmlFor="signup-phone-number"
                                            error={errors.phone_number}
                                        >
                                            Phone Number*
                                        </PrimaryNormalInputLabel>
                                    </FormControl>
                                    <PhoneInput
                                        id="signup-phone-number"
                                        labelId="signup-phone-number"
                                        international
                                        {...register("phone_number", {
                                            required: true,

                                            validate: (num) => validatePhoneNumber(num)
                                        })}
                                        className={`input_phone ${errors?.phone_number ? "error_phone" : ""} `}
                                        name="phone Number"
                                        error={errors.phone_number}
                                        onChange={phone => {
                                            setValue("phone_number", phone);
                                            clearErrors("phone_number");
                                        }}
                                        label="signup-phone-number"
                                        value={getValues("phone_number")}
                                        defaultCountry="IN"
                                        initialValueFormat="national"
                                        placeholder="Enter phone number"
                                    />
                                    <PrimaryNormalFormHelperText error={errors.phone_number}>
                                        {errors?.phone_number?.type === "required"  ? "Phone number is required" : errors?.phone_number?.message ? errors?.phone_number?.message : null}
                                    </PrimaryNormalFormHelperText>
                                </Box>
                                <Box>
                                    {watch("user_type") === "RC" || watch("user_type") === "BVC" ? (
                                        <FormControl sx={{ width: "100%" }} variant="standard">
                                            <PrimaryNormalInputLabel
                                                shrink
                                                htmlFor="login-company-name-outlined"
                                                error={errors.company_name}
                                            >
                                                Company Name*
                                            </PrimaryNormalInputLabel>
                                            <PrimaryNormalInputs
                                                fullWidth
                                                placeholder="Enter your company"
                                                {...register("company_name", { required: true, pattern: /\S/, validate: (value) => isValidInput(value) || 'Please enter a valid alphanumeric value.', })}
                                                id="login-company-name-outlined"
                                                label="Company name"
                                                type={"text"}
                                                error={errors.company_name}
                                                variant="outlined"
                                            />
                                            <PrimaryNormalFormHelperText error={errors.company_name}>
                                                     {errors?.company_name?.type === "required" ? "Company name is required" : errors?.company_name?.message ? errors?.company_name?.message : null}
                                            </PrimaryNormalFormHelperText>
                                        </FormControl>
                                    ) : (
                                        ""
                                    )}
                                </Box>
                                <Box>
                                    <FormControl sx={{ width: "100%" }}>
                                        <PrimaryNormalInputLabel
                                            shrink
                                            sx={{ transform: "scale(0.75)" }}
                                            htmlFor="login-password-outlined"
                                            error={errors.password}
                                        >
                                            Password*
                                        </PrimaryNormalInputLabel>
                                        <PrimaryNormalInputs
                                            {...register("password", {
                                                required: true,
                                                validate: (value) => !/\s/.test(value),
                                                minLength: {
                                                    value: 5,
                                                    message: "Password must be at least 5 characters",
                                                },
                                                maxLength: {
                                                    value: 15,
                                                    message: "Password must not exceed 15 characters",
                                                },
                                              

                                                pattern: {
                                                    value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{5,10}$/,
                                                    message: "Password must contain at least one letter, one number, and one special character. No spaces allowed.",
                                                },

                                            })}
                                            id="login-password-outlined"
                                            label="Password"
                                            placeholder="Enter your password"
                                            type={showPassword ? "text" : "password"}
                                            error={errors.password}
                                            endAdornment={
                                                <InputAdornment
                                                    sx={{
                                                        position: "absolute",
                                                        right: "10px",
                                                    }}
                                                    position="end"
                                                    onClick={handleShowPassword}
                                                >
                                                    {!showPassword ? (
                                                        <FeatherIcon icon="eye-off" size={"14"} />
                                                    ) : (
                                                        <FeatherIcon icon="eye" size={"14"} />
                                                    )}{" "}
                                                </InputAdornment>
                                            }
                                            variant="outlined"
                                        />
                                        <PrimaryNormalFormHelperText error={errors.password}
                                        sx={{
                                            marginLeft: "0px !important"
                                        }}
                                        >
                                            {errors?.password?.type === "required" ? "Password is required" : errors?.password?.message ? errors?.password?.message : null}
                                        </PrimaryNormalFormHelperText>

                                    </FormControl>
                                </Box>
                            </Stack>
                        </Stack>
                        <Stack>
                            <PrimaryButton type="submit">Sign Up</PrimaryButton>
                        </Stack>
                        <Stack>
                            <Typography
                                sx={{
                                    fontSize: "15px",
                                    fontWeight: "400",
                                    color: "#737373",
                                    textAlign: "center"
                                }}
                            >
                                Already have an account?
                                <Link to="../login"
                                    style={{
                                        fontWeight: "700",
                                        color: "#000"
                                    }}
                                > Sign In
                                </Link>
                            </Typography>

                            <Typography
                                sx={{
                                    fontSize: "15px",
                                    fontWeight: "400",
                                    color: "#737373",
                                    textAlign: "center"
                                }}
                            >
                                By clicking
                                <Link to="/signup"
                                    style={{
                                        fontWeight: "700",
                                        color: "#000"
                                    }}
                                > "Sign Up"
                                </Link> you agree to
                                <Link to="/"
                                    style={{
                                        fontWeight: "700",
                                        color: "#000"
                                    }}
                                > Terms and Conditions
                                </Link>
                            </Typography>

                        </Stack>
                    </Stack>
                </form>
            </Box>
        </React.Fragment>
    )
}

export default RegisterAuthForm