import React, { useRef, useState, useEffect } from 'react';
import { Grid, Stack, Typography, Box, Divider, Button, InputAdornment, CircularProgress, IconButton, FormControlLabel, Radio, FormControl, circularProgressClasses, colors} from "@mui/material";
import GoogleIcon from '../../../assets/images/icons/GoogleIcon';
import AppleLogoIcon from '../../../assets/images/icons/AppleLogoIcon';
import { Link, useLocation,Redirect, withRouter } from "react-router-dom";
import { loginUser } from "../../../store/actions";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

import {
    SearchPrimaryInput,
    GreyTypography,
    PrimaryButton,
    PrimaryNormalFormHelperText,
    PrimaryNormalInputLabel,
    PrimaryNormalInputs,
    LargeSemiboldTypography,
    PrimaryTypography,
  } from "assets/Mui/globalTheme";
import { useDispatch, useSelector } from "react-redux";

function LoginAuthForm() {
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();
    const { search } = useLocation();

    const history = useHistory();
    const loginDispatch = useDispatch();
    const location = useLocation();
    const selector = useSelector(state => state?.Login);

    useEffect(() => {
        const userCreds = JSON.parse(localStorage.getItem("user_creds"));
        if (userCreds) {
            setValue("email", userCreds?.email);
            setValue("password", userCreds?.password);
            setRememberMe(true);
        }
    }, [])



    async function handleData(data) {
        setLoading(true);
        loginDispatch(loginUser(data, history, location, setLoading, rememberMe));
    }

    // useEffect(() => {
    //   if (selector?.loading === false) {
    //     setLoading(false);
    //   }
    // }, [selector?.loading]);

    function handleShowPassword() {
        setShowPassword(!showPassword);
    }
  

    if (
        JSON.parse(localStorage.getItem("authUser"))?.token !== "" &&
        JSON.parse(localStorage.getItem("authUser"))?.token !== null &&
        JSON.parse(localStorage.getItem("authUser"))?.token !== undefined
    ) {
        return (<Redirect to="/" />)
    }


    return (
        <React.Fragment>
            <Box
                sx={{
                    minWidth: "300px",
                    width: "60%",
                    '@media (max-width:1200px)': {
                        width: "70%",
                    },
                    '@media (max-width: 900px)': {
                        width: "90%",
                    },
                    '@media (max-width: 600px)': {
                        width: "95%",
                    },
                }}
            >
                <form onSubmit={handleSubmit(handleData)}>
                    <Stack
                        sx={{
                            width: "100%",
                            gap: "35px",
                        }}
                    >
                        <Stack>
                            <Typography
                                sx={{
                                    fontWeight: "600",
                                    fontSize: "30px",
                                    textAlign: "center",
                                }}
                            >Sign in to Joinee</Typography>
                            <Typography
                                sx={{
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    textAlign: "center",
                                    color: "#737373"
                                }}
                            >Sign in here</Typography>
                        </Stack>
                        <Stack
                            direction="column"
                            spacing={2}
                        >
                            {/* <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={3}
                            >
                                <Button component="label" variant="text" startIcon={<GoogleIcon />}
                                    sx={{
                                        border: "1px solid #DFE1E6",
                                        color: "#000",
                                        borderRadius: "8px",
                                        width: "50%"
                                    }}
                                >
                                    <Box sx={{
                                        textTransform: "none",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                    }}>
                                        Sign In with Google
                                    </Box>
                                </Button>
                                <Button component="label" variant="text" startIcon={<AppleLogoIcon />}
                                    sx={{
                                        border: "1px solid #DFE1E6",
                                        color: "#000",
                                        borderRadius: "8px",
                                        width: "50%"
                                    }}
                                >
                                    <Box sx={{
                                        textTransform: "none",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                    }}>
                                        Sign In with Apple
                                    </Box>
                                </Button>
                            </Stack> */}
                            {/* <Stack>
                                <Divider sx={{ fontSize: "11px" }}>Or with email</Divider>
                            </Stack> */}
                            <Stack
                                direction="column"
                                spacing={1.5}
                            >
                                <Box>
                                    <FormControl sx={{ width: "100%" }} variant="standard">
                                        <PrimaryNormalInputLabel
                                            shrink
                                            htmlFor="login-email-outlined"
                                            error={errors.email}
                                        >
                                            Email
                                        </PrimaryNormalInputLabel>
                                        <PrimaryNormalInputs
                                            fullWidth
                                            placeholder="Enter your email"
                                            {...register("email", {
                                                required: true,
                                                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            })}
                                            id="login-email-outlined"
                                            autoFocus
                                            label="email address"
                                            type={"text"}
                                            name="email"
                                            error={errors.email}
                                            variant="outlined"
                                        />
                                        <PrimaryNormalFormHelperText error={errors.email}>
                                        {errors?.email?.type === "required" ? "Email is required" : errors?.email?.type === "pattern" ? "Invalid email" : null}
                                        </PrimaryNormalFormHelperText>
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl sx={{ width: "100%" }}>
                                        <PrimaryNormalInputLabel
                                            shrink
                                            sx={{ transform: "scale(0.75)" }}
                                            htmlFor="login-password-outlined"
                                            error={errors.password}
                                        >
                                            Password
                                        </PrimaryNormalInputLabel>
                                        <PrimaryNormalInputs
                                            {...register("password", { required: true })}
                                            id="login-password-outlined"
                                            label="Password"
                                            placeholder="Enter your password"
                                            type={showPassword ? "text" : "password"}
                                            error={errors.password}
                                            name="password"
                                            endAdornment={
                                                <InputAdornment
                                                    sx={{
                                                        position: "absolute",
                                                        right: "10px",
                                                    }}
                                                    position="end"
                                                    onClick={handleShowPassword}
                                                >
                                                    {!showPassword ? (
                                                        <FeatherIcon icon="eye-off" size={"14"} />
                                                    ) : (
                                                        <FeatherIcon icon="eye" size={"14"} />
                                                    )}{" "}
                                                </InputAdornment>
                                            }
                                            variant="outlined"
                                        />
                                          <PrimaryNormalFormHelperText error={errors.password}
                                        sx={{
                                            marginLeft: "0px !important"
                                        }}
                                        >
                                            {errors?.password?.type === "required" ? "Password is required" : errors?.password?.message ? errors?.password?.message : null}
                                        </PrimaryNormalFormHelperText>
                                    </FormControl>
                                </Box>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={3}
                                >
                                    <Box>
                                        <FormControlLabel
                                            sx={{
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: 17,
                                                    color: "var(--app-button-color1)"
                                                },
                                                '& .MuiFormControlLabel-label': {
                                                    fontSize: "13px",
                                                    fontWeight: "700"
                                                }

                                            }}
                                            control={
                                                <Radio
                                                    checked={rememberMe}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setRememberMe(!rememberMe)
                                                    }}
                                                    value={rememberMe}
                                                />
                                            }
                                            label="Remember me"
                                        />
                                    </Box>
                                    <Box>
                                        <Link to="../forgot-password">
                                            <span
                                                style={{
                                                    fontSize: "12px",
                                                    fontWeight: "700",
                                                    color: "#000"
                                                }}
                                            >Forgot Password?</span>
                                        </Link>
                                    </Box>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack>

                            {loading ? (
                                <PrimaryButton sx={{ position: "relative" }}>
                                    <CircularProgress
                                        variant="determinate"
                                        sx={{
                                            color: colors.grey[300],
                                        }}
                                        size={30}
                                        thickness={4}
                                        value={100}
                                    />
                                    <CircularProgress
                                        variant="indeterminate"
                                        color="primary"
                                        disableShrink
                                        sx={{
                                            animationDuration: "550ms",
                                            position: "absolute",
                                            // left: 0,
                                            [`& .${circularProgressClasses.circle}`]: {
                                                strokeLinecap: "round",
                                            },
                                        }}
                                        size={30}
                                        thickness={4}
                                    />
                                </PrimaryButton>
                            ) : (
                                <PrimaryButton type="submit">Login</PrimaryButton>
                            )}
                        </Stack>
                        <Stack>
                            <Typography
                                sx={{
                                    fontSize: "15px",
                                    fontWeight: "400",
                                    color: "#737373",
                                    textAlign: "center"
                                }}
                            >
                                Don’t have an account? 
                                <br /> Sign up as
                                <Link to="../signup?user_type=CA"
                                    style={{
                                        fontWeight: "700",
                                        color: "#000"
                                    }}
                                > Company 
                                </Link> 
                                {" "}or
                                <Link to="../signup?user_type=PL"
                                    style={{
                                        fontWeight: "700",
                                        color: "#000"
                                    }}
                                > Partner
                                </Link>
                            </Typography>
                        </Stack>
                    </Stack>
                </form>
            </Box>
        </React.Fragment>
    )
}

export default LoginAuthForm