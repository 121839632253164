import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  progressBarContainer: {
    width: 70,
    height: 70,
    position: "relative",
    textAlign:"center"
  },
  progressBar: {
    width: 70,
    height: 70,
    borderRadius: "50%",
    border: "2px solid #ccc",
  },

  rating: {
    fontSize:"11px",
    fontWeight:700,
    top:"65%",
    marginBottom:"3px"
  },
  ratingText: {
    fontSize:"9px",
    fontWeight:500,
    textAlign:"center",
    top:"65%",

  },

//   ratingText: {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     fontSize: 12,
//     fontWeight: "bold",
//     zIndex: 1, // Ensure the text is above the progress bar
//   },
});

const CircularProgressBar = ({ rating,status }) => {
  const percentage = (rating / 10) * 100; // Assuming ratings are out of 10

  return (
    <View style={styles.progressBarContainer}>
      <View style={styles.progressBar}>
        <View
        //   style={[
        //     styles.progressFill,
        //     {
        //       transform: `rotate(${percentage * 1.8}deg)`,
        //       backgroundColor: percentage >= 50 ? "#000" : "transparent",
        //     },
        //   ]}
        > 
        <Text style={styles.rating}>{`${rating}`}</Text>
        <Text style={styles.ratingText}>{status}</Text>
        </View>
    
      </View>
    </View>
  );
};

export default CircularProgressBar;
