import { IconButton, Input, Rating, Stack, Tooltip, TextField, Modal, Box } from "@mui/material";
import {
  BlackTypography,
  GreyTypography,
  PrimaryButton,
  PrimaryNormalInputs,
  PrimaryTypography,
  SuccessTextButton,
} from "assets/Mui/globalTheme";
import { makeStyles } from '@mui/styles';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ActionModal from "./commentQuestionModal";
import { MODERATOR, SP, TE } from "common/constants/userModules";

const useStyles = makeStyles({
  label: {
    "& label": {
      marginBottom: "0px !important"
    },
  }
})

const feedbacks = [
  "Poor",
  "Not Good",
  "Below Average",
  "Average",
  "Good",
  "Excellent"
]

const popperProps = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [1, -10], // Adjust the values to reduce the distance
      },
    },
  ],
};

export function SkillEvaluation({ readOnly, skill, updateSkillHandler, deleteSkillHandler }) {
  const {
    register,
    setValue,
    watch,
    setError,
    handleSubmit,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      readComment: skill?.comment || "",
      commentReadOnly: skill?.comment || ''
    }
  });
  const [comments, setcomments] = useState(false);
  const [rating, setRating] = useState({
    value: skill?.rating || 0,
    hover: 0
  })
  const classes = useStyles()
  const onSubmit = data => {
    const { readComment } = data
    setValue('commentReadOnly', readComment)
    if (Object.values(data) === null || Object.values(data) === "") return;
    updateSkillHandler(skill?.skillInfo?.id, readComment, rating.value, skill.evaluateId)
    setcomments(false);
  };
  function handleStars(_, val) {
    const comment = getValues("commentReadOnly") || skill?.comment
    if (!Boolean(comment)) {
      setError('commentReadOnly')
      return;
    }
    setRating((pre) => ({
      ...pre,
      value: val
    }))
    updateSkillHandler(skill?.skillInfo?.id, comment, val, skill.evaluateId)
  }

  function handleTrash() {
    deleteSkillHandler(skill?.evaluateId)
  }


  const isValidInput = (value) => /^[A-Za-z\s]+$/.test(value);
  return (
    <>

      <Stack spacing={1}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <BlackTypography
            noWrap
            sx={{ textOverflow: "ellipsis", maxWidth: "250px !important" }}
            variant="h3"
          >
            {skill?.skillInfo?.name}
          </BlackTypography>

        </Stack>
        <Stack direction="row" spacing={1} justifyContent="space-between" alignItems={"center"}>
          <Stack sx={{ width: "100%" }}
            direction="row"
            alignItems="center"
            spacing={2}
          >
            <Box sx={{ width: "100%" }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                {!comments ? (
                  <PrimaryNormalInputs
                    sx={{ width: "100%" }}
                    {...register("commentReadOnly", { required: true      
                    })}
                    type="text"
                    inputProps={{
                      style: {
                        padding: "8px",
                      },
                    }}
                    value={getValues("readComment") || skill?.comment}
                    // defaultValue={skill?.comment || ""}
                    error={errors.commentReadOnly}
                    placeholder="Enter your comments here"
                    onClick={() => {
                      if (!readOnly) {
                        setcomments(true);
                        setValue(
                          "readComment",
                          getValues("commentReadOnly") || skill?.comment
                        );
                      }
                    }}
                    readOnly={readOnly}
                  />
                ) : (
                  <Stack direction="row" spacing={"1"}>
                    <Input
                      fullWidth
                      placeholder={"Enter Comment"}
                      {...register("readComment", { required: true,
                        // pattern: /\S/,
                        // validate: (value) => isValidInput(value) || 'Please enter a valid comment.',
                      })}
                      id="login-first-name-outlined"
                      autoFocus
                      error={errors.readComment}
                      variant="outlined"
                      // value={getValues("readComment") || skill?.comment}
                      defaultValue={skill?.comment || ""}
                    />
                    <IconButton
                      onClick={() => {
                        clearErrors("readComment");
                        // setValue()
                        setcomments(false);
                      }}
                      color="error"
                    >
                      <FeatherIcon icon="x" size="14" />
                    </IconButton>
                    <IconButton type="submit" color="success">
                      <FeatherIcon icon="check" size="14" />
                    </IconButton>
                  </Stack>
                )}
              </form>
            </Box>

            <Box sx={{ minWidth: "100px" }}>
              <Tooltip
                PopperProps={popperProps}
                title={feedbacks[rating.hover] || ""}
              >
                <Rating
                  className={classes.label}
                  onChange={handleStars}
                  onChangeActive={(_, hover) =>
                    setRating(pre => ({
                      ...pre,
                      hover,
                    }))
                  }
                  getLabelText={value => {
                    return `${value} Star${value !== 1 ? "s" : ""}, ${feedbacks[value]
                      }`;
                  }}
                  // precision={0.5}
                  value={rating.value}
                  size="medium"
                  readOnly={readOnly}
                />
              </Tooltip>
            </Box>
          </Stack>
          <Stack sx={{ minWidth: "80px" }} direction="row" justifyContent="flex-end" pb={0.5} pr={2}>
            <IconButton disabled={readOnly} onClick={handleTrash}>
              <FeatherIcon icon="trash" size="15" />
            </IconButton>
          </Stack>
        </Stack>

      </Stack>

    </>
  );
}

export function QuestionEvaluation({
  question,
  updateQuestionHandler,
  deleteQuestionHandler,
  readOnly,
}) {
  const [viewActions, setViewActions] = useState(false)
  function handleTrash() {
    deleteQuestionHandler(question?.evaluateId);
  }
  function handleClose() {
    setViewActions(false)
  }
  function handleOpen(e) {
    e.stopPropagation()
    setViewActions(true)
  }

  return (
    <>
      {question?.view && (
        <Stack spacing={1}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <BlackTypography
              // noWrap
              // sx={{ maxWidth: "250px !important" }}
              variant="h3"
              gutterBottom
            >
              {question?.questionName}
            </BlackTypography>
          </Stack>

          <Stack direction="row" spacing={3} justifyContent="space-between" alignItems={"center"}>
            <Stack sx={{ width: "100%" }}
              direction="row"
              alignItems="center"
              spacing={2}
            >
              <Box sx={{ width: "100%" }}>
                <TextField
                  sx={{ width: "100%" }}
                  label="Answer"
                  value={question?.answer || ""}
                  placeholder="update"
                  InputProps={{
                    readOnly: true,
                  }}
                  multiline
                  rows={2.5}
                />
              </Box>
              <Box sx={{ minWidth: "100px" }}>

                <Tooltip PopperProps={popperProps} title="ratings">
                  <Rating
                    // precision={0.5}
                    value={question?.rating}
                    size="medium"
                    readOnly={true}
                  />

                </Tooltip>

              </Box>
            </Stack>
            <Stack sx={{ minWidth: "80px" }} direction="row" justifyContent="flex-end" pb={0.5} pr={2}>
              <Tooltip PopperProps={popperProps} title="comment">
                <IconButton disabled={readOnly} onClick={handleOpen}>
                  <FeatherIcon
                    fill={question?.comment ? "currentcolor" : "transparent"}
                    icon="message-square"
                    size="14"
                  />
                </IconButton>
              </Tooltip>
              <Tooltip PopperProps={popperProps} title="delete">
                <IconButton disabled={readOnly} onClick={handleTrash}>
                  <FeatherIcon icon="trash" size="14" />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
          {viewActions && (
            <ActionModal
              question={question}
              readOnly={readOnly}
              open={viewActions}
              updateQuestionHandler={updateQuestionHandler}
              handleClose={handleClose}
            />
          )}
        </Stack>
      )}
    </>
  );
}


