import React, { useState, useEffect } from "react";
import {
  ButtonGroup,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import {
  BlackTypography,
  PrimaryAvatar,
  PrimaryButton,
  PrimaryContrastedToggleButton,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  PrimaryToggleButton,
  PrimaryToggleButtonGroup,
  PrimaryTypography,
  SecondaryFilledButton,
  SecondaryToggleButtonGroup,
} from "assets/Mui/globalTheme";
import { useForm } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useHistory } from "react-router-dom";
import SearchParamHook from "hooks/searchParams";
import { getSingleJobDetails } from "helpers/services/jobs/jobs";

export default function ScheduleInterviewForm() {
  const history = useHistory();
  const [timeSlots, setTimeSlots] = useState("11:00");
  const [duration, setDuration] = useState("15");
  const searchParams = SearchParamHook();
  const jobPostId = searchParams.get("job_id");
  const [jobPostInfo, setJobPostInfo] = useState(null);

  useEffect(() => {
    (async function () {
      const res = await getSingleJobDetails(jobPostId);
      const { data } = res;
      setJobPostInfo(data);
    })();
  }, []);

  useEffect(() => {
    setValue("job_title", jobPostInfo?.job_title);
    setValue(
      "candidate_name",
      jobPostInfo?.recently_applied_candidate_details?.[0]?.first_name
    );
  }, [jobPostInfo]);

  function changeTimeSlots(_, value) {
    setTimeSlots(value);
  }
  function ChangeDuration(_, value) {
    setDuration(value);
  }
  const {
    register,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
    getValues,
  } = useForm({});
  const interview = [
    {
      name: "Zoom",
      value: "zoom",
      image:
        "https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/2f/5f/87/2f5f8790-1297-edf8-ef2a-c88cb6dd44af/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/1200x630wa.png",
    },
    {
      name: "Google Meet",
      value: "google_meet",
      image: "https://cdn.arstechnica.net/wp-content/uploads/2020/04/27.jpg",
    },
    { name: "Custom", value: "custom", image: "" },
  ];

  const interviewPanel = [
    {
      name: "Dileep Kumar",
      value: "Dileep Kumar",
    },
    {
      name: "Dinesh",
      value: "Dinesh",
    },
    { name: "Karthik", value: "Karthik"},
  ];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        borderRadius: "10px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
      },
    },
  };
  function handleCancel() {
    history.goBack();
  }

  const onSubimission = (data) => {
    // e.preventDefault()
 
  };

  return (
    <form onSubmit={handleSubmit(onSubimission)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="job-title-outlined"
              error={errors.job_title}
            >
              Job Name
            </PrimaryNormalInputLabel>
            <PrimaryNormalInputs
              fullWidth
              placeholder="Enter your Job Title"
              {...register("job_title", { required: true })}
              id="job-title-outlined"
              helperText={errors?.job_title ? "Frist Name is a required" : ""}
              autoFocus
              label="Job  Title"
              type={"text"}
              error={errors.job_title}
              variant="outlined"
            />
            <PrimaryNormalFormHelperText error={errors.job_title}>
              {errors.job_title ? "Job Title is a required Field" : ""}
            </PrimaryNormalFormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="candidate-name-outlined"
              error={errors.candidate_name}
            >
              Candidate Name
            </PrimaryNormalInputLabel>
            <PrimaryNormalInputs
              fullWidth
              placeholder="Enter your Job Title"
              {...register("candidate_name", { required: true })}
              id="candidate-name-outlined"
              helperText={
                errors?.candidate_name ? "Frist Name is a required" : ""
              }
              autoFocus
              label="Job  Title"
              type={"text"}
              error={errors.candidate_name}
              variant="outlined"
            />
            <PrimaryNormalFormHelperText error={errors.candidate_name}>
              {errors.candidate_name ? "Job Title is a required Field" : ""}
            </PrimaryNormalFormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl sx={{ width: "100%" }}>
            <PrimaryNormalInputLabel
              error={errors?.interview_type}
              shrink
              sx={{
                left: "-12px",
                top: "10px",
              }}
              id="interview-type-input"
            >
              Interview Type
            </PrimaryNormalInputLabel>
            <Select
              labelId="interview-type-input"
              // labelId="selct-user-type"
              id="demo-multiple-name"
              {...register("interview_type", { required: true })}
              input={
                <PrimaryNormalInputs
                  label="interview-type-input"
                  error={errors?.interview_type}
                  {...register("interview_type", { required: true })}
                />
              }
              MenuProps={MenuProps}
            >
              {interview?.map(item => {
                return (
                  <MenuItem key={item?.value} value={item?.value}>
                    <Stack alignItems={"center"} direction="row" spacing={1}>
                      <PrimaryAvatar
                        sx={{
                          height: "18px !important",
                          width: "18px !important",
                        }}
                        src={item?.image}
                      >
                        {item?.name?.charAt("0")}
                      </PrimaryAvatar>
                      <>{item?.name}</>
                    </Stack>
                  </MenuItem>
                );
              })}
            </Select>
            <PrimaryNormalFormHelperText error={errors.interview_type}>
              {errors.interview_type ? "User Type is a required Field" : ""}
            </PrimaryNormalFormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl sx={{ width: "100%" }}>
            <PrimaryNormalInputLabel
              error={errors?.interview_type}
              shrink
              sx={{
                left: "-12px",
                top: "10px",
              }}
              id="add-interviewer-input"
            >
              Add Interviewer
            </PrimaryNormalInputLabel>
            <Select
              labelId="add-interviewer-input"
              // labelId="selct-user-type"
              id="demo-multiple-name"
              {...register("interviewer", { required: true })}
              input={
                <PrimaryNormalInputs
                  label="add-interviewer-input"
                  error={errors?.interviewer}
                  {...register("interviewer", { required: true })}
                />
              }
              MenuProps={MenuProps}
            >
              {interviewPanel?.map(item => {
                return (
                  <MenuItem key={item?.value} value={item?.value}>
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
            <PrimaryNormalFormHelperText error={errors.interviewer}>
              {errors.interviewer ? "User Type is a required Field" : ""}
            </PrimaryNormalFormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction={"row"} spacing={2}>
            <div>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <PrimaryNormalInputLabel
                  shrink
                  htmlFor="interview-duration-outlined"
                >
                  Date
                </PrimaryNormalInputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDateTimePicker
                    // disableFuture={true}
                    label="Date"
                    value={watch("date")}
                    onChange={newValue => {
                      setValue("date", newValue);
                    }}
                    renderInput={params => (
                      <PrimaryNormalInputs
                        {...params}
                        error={errors?.date}
                        {...register("date", { required: true })}
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            </div>
            <FormControl variant="standard">
              <PrimaryNormalInputLabel
                shrink
                htmlFor="interview-duration-outlined"
              >
                Interview Duration
              </PrimaryNormalInputLabel>
              <SecondaryToggleButtonGroup
                sx={{ marginTop: "24px" }}
                color="primary"
                value={duration}
                exclusive
                onChange={ChangeDuration}
                aria-label="Platform"
              >
                <PrimaryToggleButton value="30">30 MIN</PrimaryToggleButton>
                <PrimaryToggleButton value="45">45 MIN</PrimaryToggleButton>
                <PrimaryToggleButton value="60">60 MIN</PrimaryToggleButton>
              </SecondaryToggleButtonGroup>
            </FormControl>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="candidate-name-outlined"
              // error={errors.candidate_name}
            >
              Time Slots
            </PrimaryNormalInputLabel>
            <PrimaryToggleButtonGroup
              sx={{ marginTop: "22px" }}
              color="primary"
              value={timeSlots}
              exclusive
              onChange={changeTimeSlots}
              aria-label="Platform"
            >
              <PrimaryContrastedToggleButton value="11:00">
                11:00 AM
              </PrimaryContrastedToggleButton>
              <PrimaryContrastedToggleButton value="30">
                11:30 AM
              </PrimaryContrastedToggleButton>
              <PrimaryContrastedToggleButton value="45">
                12:00 AM
              </PrimaryContrastedToggleButton>
            </PrimaryToggleButtonGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Stack
        direction="row"
        spacing={2}
        sx={{ marginTop: "40px" }}
        justifyContent={"flex-end"}
        alignItems={"center"}
      >
        <SecondaryFilledButton
          onClick={handleCancel}
          sx={{ width: "100px", color: "red" }}
        >
          Cancel
        </SecondaryFilledButton>
        <PrimaryButton type="submit" sx={{ width: "200px" }}>Schedule& Notify</PrimaryButton>
      </Stack>
    </form>
  );
}
