import {
  GreyTypography,
  StyledPaperForKanban,
  StyledParagraph,
  StageSearchInput,
  StageButton,
  AddButton,
  MenuButton,
  CountBadge,
} from "assets/Mui/globalTheme";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { useDrop } from "react-dnd";
import UserJobCard from "./userCard";
import Overlay from "./overlay";
import { deepOrange, grey, red, teal } from "@mui/material/colors";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import colorCodes from "./colorcodes";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import KanbanPopup from "./popup";
import AddPeople from "./AddPeople";
import {
  ACM,
  ZA,
  SP,
  CS,
  CA,
  MODERATOR,
  BVC,
  BVS,
  TE,
  RC,
  RS,
} from "common/constants/userModules";
import {
  pipelineStages,
  roleRestrictions,
  stageACM,
  stageBG,
  stageCompany,
  stageModerator,
  stageSP,
  stageTE,
  stageZA,
} from "common/constants/roleRestrictions";
import JobSearchIcon from "assets/images/icons/JobSearchIcon";

export default function DropTargets({
  newItem,
  index,
  checkDropLocation,
  handleDrop,
  cardsList,
  jobDetails,
  setUpdated,
  updated,
  fetchKanban,
}) {
  const [color, setColor] = useState("");
  const [search, setSearch] = useState("");
  const [openSearch, setOpenSearch] = useState(true);
  const { role } = useSelector(state => state.Login);
  const [anchorEl, setAnchorEl] = useState(null);
  const [addPeopleModel, setAddPeopleModel] = useState(false);
  function handleAddPeople() {
    setAddPeopleModel(!addPeopleModel);
  }
  useEffect(() => {
    setSearchedList(cardsList);
    // setSearchedList([...cardsList]);
  }, [newItem?.user_job_posts_list?.length]);
  // const [searchedList, setSearchedList] = useState([...cardsList]);
  const [searchedList, setSearchedList] = useState(cardsList);
  const user = useSelector(state => state.Login);
  useEffect(() => {
    colorCodes(newItem?.stage_order, user.role);
  }, [newItem?.stage_order]);
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: "KANBAN",
      drop: item => handleDrop(newItem?.stage_order, newItem?.stage_id, item),
      canDrop: item =>
        checkDropLocation(newItem?.stage_order, newItem?.stage_id, item),
      collect: monitor => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [newItem?.stage_order]
  );

  /*Testing Search functionality code start here*/
  const handleSearch = event => {
    const value = event.target.value;
    setSearch(value);
    if (value.trim() !== "") {
      const filteredCards = newItem?.user_job_posts_list?.filter(item => {
        const firstName = item?.first_name?.toLowerCase() || "";
        const lastName = item?.last_name?.toLowerCase() || "";
        const location = item?.location?.toLowerCase() || "";
        const email = item?.email?.toLowerCase() || "";

        return (
          firstName.includes(value.toLowerCase()) ||
          lastName.includes(value.toLowerCase()) ||
          location.includes(value.toLowerCase()) ||
          email.includes(value.toLowerCase())
        );
      });
      setSearchedList(filteredCards);
    } else {
      setSearchedList(newItem?.user_job_posts_list);
    }
  };
  /*Testing Search functionality code End here*/

  /* Search toggle effect */

  const handleSearchOpen = () => {
    setOpenSearch(!openSearch);
  };

  const handleSearchClose = () => {
    setSearch("");
    setSearchedList(newItem?.user_job_posts_list);
    setOpenSearch(!openSearch);
  };
  /* Search toggle effect end */

  const stage = newItem?.stage_order;

  let AuthStages;
  if (role === ZA) {
    AuthStages = stageZA(stage);
  }
  if ([CS, CA].includes(role)) {
    AuthStages = stageCompany(stage);
  }
  if (role === SP) {
    AuthStages = stageSP(stage);
  }
  if (role === MODERATOR) {
    AuthStages = stageModerator(stage);
  }
  if (role === TE) {
    AuthStages = stageTE(stage);
  }
  if (role === ACM) {
    AuthStages = stageACM(stage);
  }
  if ([BVC, BVS].includes(role)) {
    AuthStages = stageBG(stage);
  }

  return (
    <div
      key={newItem.stage_id}
      className=""
      // style={{cursor:newItem?.isDisabled?"not-allowed":"auto"}}
    >
      <StyledPaperForKanban
        sx={{
          ...(isOver && canDrop && { background: "rgba(8,23,83,0.3)" }),
          ...(isOver && !canDrop && { background: red[50] }),
          ...(!isOver && canDrop && { background: teal[50] }),
          "::-webkit-scrollbar": {
            display: "none",
          },
          // opacity: newItem?.isDisabled ? 0.4 : 1, // Reduce opacity if disabled
          // pointerEvents: newItem?.isDisabled ? "none" : "auto", // Disable pointer events if disabled
        }}
        ref={drop}
        key={index}
        elevation={0}
      >
        {newItem?.stage_name == "Sourced" ? (
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ marginBottom: "8px" }}
            spacing={2}
          >
            {openSearch ? (
              <StageButton>
                {" "}
                #{newItem?.stage_name}-{newItem?.user_job_posts_list?.length} ✍️
              </StageButton>
            ) : (
              ""
            )}

            {newItem?.stage_name == "Sourced" &&
            [CA, CS, RC, RS].includes(role) ? (
              <AddButton
                sx={{ height: "28px" }}
                onClick={e => {
                  setAnchorEl(e.currentTarget);
                }}
              >
                +
              </AddButton>
            ) : (
              ""
            )}

            <KanbanPopup
              handlePeople={handleAddPeople}
              handleClose={() => setAnchorEl(null)}
              anchorEl={anchorEl}
              jobDetails={jobDetails}
            />

            <AddPeople
              handleClose={handleAddPeople}
              open={addPeopleModel}
              setUpdated={setUpdated}
              updated={updated}
              fetchKanban={fetchKanban}
              companyId={jobDetails?.company_details?.id}
            ></AddPeople>

            {openSearch ? (
              // <SearchIcon
              //   onClick={handleSearchOpen}
              //   sx={{

              //     alignSelf: "end",
              //     margin: "2px",
              //   }}
              // />
              <button
                onClick={handleSearchOpen}
                style={{
                  backgroundColor: "#FFFFFF",
                  border: "0.5px solid #00000033",
                  padding: "7px",
                  borderRadius: "7px",
                }}
              >
                <JobSearchIcon />
              </button>
            ) : (
              <StageSearchInput
                placeholder="search..."
                sx={{
                  display: newItem?.isDisabled ? "none" : "flex",
                  height: "22px",
                  paddingTop: "5px",
                }}
                onChange={handleSearch}
                endAdornment={
                  // <IconButton edge="end" onClick={handleSearchOpen}>
                  <IconButton edge="end" onClick={handleSearchClose}>
                    <ClearIcon />
                  </IconButton>
                }
              />
            )}
          </Stack>
        ) : (
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ marginBottom: "8px" }}
            spacing={2}
          >
            {openSearch ? (
              <StageButton>
                {" "}
                #{newItem?.stage_name}-{newItem?.user_job_posts_list?.length} ✍️
              </StageButton>
            ) : (
              ""
            )}

            {openSearch ? (
              // <SearchIcon
              //   onClick={handleSearchOpen}
              //   sx={{
               
              //     alignSelf: "end",
              //     margin: "2px",
              //   }}
              // />
              <button
              onClick={handleSearchOpen}
              style={{
                backgroundColor: "#FFFFFF",
                border: "0.5px solid #00000033",
                padding: "7px",
                borderRadius: "7px",
              }}
            >
              <JobSearchIcon />
            </button>
            ) : (
              <StageSearchInput
                placeholder="search..."
                sx={{
                  display: newItem?.isDisabled ? "none" : "flex",
                  height: "22px",
                  marginTop: "8px",
                  marginLeft: "auto",
                }}
                onChange={handleSearch}
                endAdornment={
                  // <IconButton edge="end" onClick={handleSearchOpen}>
                  <IconButton edge="end" onClick={handleSearchClose}>
                    <ClearIcon />
                  </IconButton>
                }
              />
            )}
          </Stack>
        )}

        {searchedList?.map(data => (
          <UserJobCard
            key={data?.id}
            order={newItem?.stage_order}
            stage_name={newItem?.stage_name}
            user_data={data}
            authList={AuthStages}
            role={role}
            disableStatus={newItem?.isDisabled}
          />
        ))}
      </StyledPaperForKanban>
    </div>
  );
}
