import { DELETE, POST, PUT } from "common/constants/methods";
import callApi from "helpers/callApi";
// import SearchParamHook from "hooks/searchParams";

// const companyName = SearchParamHook.get('companyParam')


const userLoginId = JSON.parse(localStorage.getItem("authUser"))

export async function CreateJobPost(data) {

  return await callApi("company/job_post", data, POST);
}

export async function getJobsBasedOnCompany(id,page, pageCount, companyName="Joinee",searchText) {
  return await callApi(`user_job_post_update/company_job_posts/${id}?pageNo=${page}&pageSize=${pageCount}&companyName=${companyName}`);
}
export async function getAllJobs(page, pageCount, searchText) {
  return await callApi(`company/jobs/active?pageNo=${page}&pageSize=${pageCount}&searchParam=${searchText}`);
  // return await callApi(`company/job_post`);
}

export async function getAllActiveJobs(page, pageCount, searchText,param='') {
  let companySearch = param ?? ""
  return await callApi(`company/jobs/active?pageNo=${page}&pageSize=${pageCount}&searchParam=${searchText}&companyParam=${companySearch}`);
  // return await callApi(`company/job_post`);
}

export async function getAllJobsBasedOnCompany(id, pathStatus, page = 1, pageCount = 20, searchText,param='') {
  return await callApi(`company/job_post/${pathStatus}?company_id=${id}&pageNo=${page}&pageSize=${pageCount}&searchParam=${searchText}`);
  // return await callApi(`company/job_post/${pathStatus}?company_id=${id}&pageNo=${page}&pageSize=${pageCount}&searchParam=${searchText}&companyParam=${param}`);
}

/*Pending job for all users */
export async function getAllPendingJobs(page, pageCount, searchText,param='') {
  let companySearch = param ?? ""
  return await callApi(`company/jobs/pending?pageNo=${page}&pageSize=${pageCount}&searchParam=${searchText}&companyParam=${companySearch}`);
  // return await callApi(`company/job_post`);
}
export async function getAllPendingJobsBasedOnCompany(id, page, pageCount) {
  // return await callApi(`company/job_post/pending?company_id=${id}&pageNo=${page}&pageSize=${pageCount}`); 
}
/*Pending job for all users */

/*Closed job for all users */
export async function getAllClosedJobs(page, pageCount, searchText,param='') {
  let companySearch = param ?? ""
  return await callApi(`company/jobs/closed?pageNo=${page}&pageSize=${pageCount}&searchParam=${searchText}&companyParam=${companySearch}`);
  // return await callApi(`company/job_post`);
}
export async function getAllClosedJobsBasedOnCompany(id, page, pageCount) {
  // return await callApi(`company/job_post/closed?company_id=${id}&pageNo=${page}&pageSize=${pageCount}`); 
}
/*Closed job for all users */

export async function getSingleJob(id, userId) {
  const data = await callApi(`company/job_post/${id}?user_id=${userId}`);
  const items = data?.data?.data_payload;
  return items;
}
export async function getSingleJobDetails(id) {
  const userLoginId = JSON.parse(localStorage.getItem("authUser"))
  const userLoggedId = userLoginId?.id
  return await callApi(`company/job_post/${id}?user_id=${userLoggedId}`);
}

export async function deleteSingleJob(id) {
  const data = await callApi(`company/job_post/${id}`, {}, DELETE);
  return data;
}
export async function deleteJobPost() {
  const items = await getAllJobs();
  const ids = items.map(async item => {
    const data = await callApi(`company/job_post/${item.id}`, {}, DELETE);
    // return data.data;
  });
}
export async function updateJobPost(id, data) {
  return await callApi(`company/job_post/${id}`, data, PUT);
}
export const applyForJobPost = async (data, id, companyId) => {
  return await callApi(
    `referral_partner_job_posts/refer_now_from_admin_panel`,
    {
      ...data,
      job_post_id: id,
      company_id: companyId,
      job_apply_type: "sourced",
      about_this_job: "no_preview",
      address: "no_preivew",
    },
    POST
  );
};
