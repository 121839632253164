import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import {
  PrimaryAutoComplete,
  BlackTypography,
  PrimaryNormalInputs,
  autocompleteProps,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useFormContext } from "react-hook-form";
const GOOGLE_MAPS_API_KEY = "AIzaSyDk52HPcdIZWt2glyeWGUyn9hiqAsGMJvk";
function loadScript(src, position, id) {
  if (!position) {
    return;
  }
  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function GoogleLocation({
  updatingContent = "location",
  state = "state",
  country = "country",
  city = "city",
  setAdditionals = false,
  requireStatus,
}) {
  const {
    register,
    formState: { errors },
    setValue: formSetValue,
    watch,
  } = useFormContext();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);
  React.useEffect(() => {
    if (watch(updatingContent) !== null || watch(updatingContent) !== undefined)
      setValue({ description: watch(updatingContent) });
    else {
      setValue({ desctiption: "" });
    }
  }, [watch(updatingContent)]);
  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }
    loaded.current = true;
  }
  // const fetch = React.useMemo(
  //   () =>
  //     debounce((request, callback) => {
  //       autocompleteService.current.getPlacePredictions(request, callback);
  //     }, 400),
  //   []
  // );

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        const countries = ["us", "in"]; // Array of country codes for USA and India
        let results = [];

        const handleResults = (country, predictions) => {
          if (predictions) {
            results = [...results, ...predictions];
          }

          // Once all countries have been processed, call the callback with combined results
          if (country === countries[countries.length - 1]) {
            callback(results);
          }
        };

        // Fetch predictions for each country code
        countries.forEach(country => {
          if (autocompleteService.current) {
            const requestWithRestrictions = {
              ...request,
              componentRestrictions: { country },
            };
            autocompleteService.current.getPlacePredictions(
              requestWithRestrictions,
              predictions => handleResults(country, predictions)
            );
          }
        });
      }, 400),
    []
  );
  React.useEffect(() => {
    let active = true;
    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }
    fetch({ input: inputValue }, results => {
      if (active) {
        let newOptions = [];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);
  return (
    <>
      <PrimaryAutoComplete
        componentsProps={autocompleteProps}
        id="google-map-demo"
        placeholder="Add a location"
        getOptionLabel={option =>
          typeof option === "string" ? option : option.description
        }
        filterOptions={x => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        noOptionsText="No locations"
        onChange={(event, newValue) => {
          const geoLocation = newValue;

          const terms = geoLocation.terms || [];

          const countryCode = terms[terms.length - 1].value;
          const stateCode = terms[terms.length - 2].value;
          const cityCode = terms[terms.length - 2].value;

          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          if (setAdditionals) {
            const locationKeys = newValue?.terms?.map(
              location => location.value
            );
            formSetValue("state", locationKeys[0]);
            // formSetValue("city", locationKeys[1]);
            formSetValue("country", locationKeys[2]);
          }
          formSetValue(updatingContent, newValue?.description || "");
          formSetValue(state, stateCode || "");
          formSetValue(country, countryCode || "");
          formSetValue(city, cityCode || "");
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={params => (
          <PrimaryNormalInputs
            ref={params.InputProps.ref}
            placeholder="add a location"
            sx={{ marginTop: "8px" }}
            {...params}
            error={
              errors[updatingContent] && watch("location") == "" ? true : false
            }
            value={value}
            onChange={event => {
              // setValue(event.target.value);
            }}
            label="Add a location"
            fullWidth
          />
        )}
        // renderOption={(props, option) => {

        //   const matches =
        //     option?.structured_formatting?.main_text_matched_substrings || [];
        //   const parts = parse(
        //     option?.structured_formatting?.main_text,
        //     matches?.map(match => [match.offset, match.offset + match.length])
        //   );
        //   return (
        //     <li {...props}>
        //       <Grid container alignItems="center">
        //         <Grid item sx={{ display: "flex", width: 44 }}>
        //           <FeatherIcon icon="map-pin" size="16" />
        //         </Grid>
        //         <Grid
        //           item
        //           sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
        //         >
        //           {parts.map((part, index) => (
        //             <Box
        //               key={index}
        //               component="span"
        //               sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
        //             >
        //               {part.text}
        //             </Box>
        //           ))}

        //           <BlackTypography variant="h5">
        //             {option.structured_formatting?.secondary_text}
        //           </BlackTypography>
        //         </Grid>
        //       </Grid>
        //     </li>
        //   );
        // }}

        renderOption={(props, option) => {
          const matches =
            option?.structured_formatting?.main_text_matched_substrings || [];
          const parts = parse(
            option?.structured_formatting?.main_text,
            matches?.map(match => [match.offset, match.offset + match.length])
          );

          // Check if the option is a city
          const isCity = option.types.includes("locality");

          if (isCity) {
            return (
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid item sx={{ display: "flex", width: 44 }}>
                    <FeatherIcon icon="map-pin" size="16" />
                  </Grid>
                  <Grid
                    item
                    sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                  >
                    {parts.map((part, index) => (
                      <Box
                        key={index}
                        component="span"
                        sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                      >
                        {part.text}
                      </Box>
                    ))}

                    <BlackTypography variant="h5">
                      {option.structured_formatting?.secondary_text}
                    </BlackTypography>
                  </Grid>
                </Grid>
              </li>
            );
          } else {
            return null; // Do not render the option if it's not a city
          }
        }}
      />
      <input
        style={{ display: "none" }}
        type="text"
        // {...register(updatingContent, { required: true })}
        {...register(updatingContent, { required: !requireStatus })}
      />
    </>
  );
}
