import { DELETE, POST, PUT } from "common/constants/methods";
import callApi from "helpers/callApi";

export async function getAllIndustries(page=1, rowsPerPage=30,searchText="") {
  const data = await callApi(`job_attributes/industries?pageSize=${rowsPerPage}&pageNo=${page}&searchParam=${searchText}`);
  return data.data;
}

export async function createIndustries(incomingData) {
  const data = { name: incomingData.name, country: "INDIA" };
  await callApi("job_attributes/industries", data, POST);
}
export async function deleteIndustries(id, name) {
  if (id === undefined) return;

  const data = await callApi(
    `job_attributes/industries/${id}`,
    { name },
    DELETE
  );
}

export async function updateIndustries(incomingData, id) {
  if (id === undefined) return;

  const result = {
    name: incomingData.name,
    country: "INDIA",
    is_active: incomingData?.is_active,
  };
  const data = await callApi(`job_attributes/industries/${id}`, result, PUT);
  return data.data;
}
