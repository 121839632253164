import SearchParamHook from "hooks/searchParams";
import UserJobPostEvaluationTabs from "./tabs";
import { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import ScreeningEvaluation from "./screening";
import {
  deleteScreenEvaluationQuestions,
  deleteScreenEvaluationSkill,
  getScreenEvaluationQuestions,
  getScreenEvaluationSkills,
  postScreenEvaluationQuestions,
  postScreenEvaluationSkills,
  updateScreenEvaluationQuestions,
  updateScreenEvaluationSkills,
} from "helpers/services/jobAttributes/screenEvaluators";
import TimerHook from '../../../../hooks/TimerHook';
import { TE, SP, MODERATOR } from "common/constants/userModules";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import ErrorHandlingToaster from "helpers/Toaster/error";
import { handlePendingEvaluatedCount } from "store/Evaluations/actions";
import { useDispatch } from "react-redux";
import { socket } from "Sockets/socket";

function roleByType(type){
  let defaultTab;
  switch(type){
    case SP :
      defaultTab = "screening"
      break
    case TE:
      defaultTab =  "technical"
      break
    default:
      defaultTab = "moderate"  
  }
  return defaultTab
}


export default function UserJobPostEvaluations() {
  const searchParams = SearchParamHook();
  const role = JSON.parse(localStorage.getItem("authUser")).role;
  const [evaluation, setEvaluation] = useState('screening');
  const [skills, setSkills] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [evlId,setEvlId] = useState("")
  const evaluatorId = JSON.parse(localStorage.getItem("authUser")).id;
  const userJobPostId = searchParams.get("user_job_post_id");
  const jobPostId = searchParams.get("job_id");
  const stage = searchParams?.get("stage_name");
  const { candidate } = useSelector(state => state.Candidates);


   /*Pending count getting and dispatch from reducer Start here */
  //  const dispatch = useDispatch()
  //  const pendingCountState = useSelector((state)=>state.pendingCountReducer)
   
 /*Pending count getting and dispatch from reducer End here */




  const moderatorEvalId =
    role !== MODERATOR
      ? candidate?.user_job_post_details?.moderator_evalution_details
      : evaluatorId;
  const screeningEvalId =
    role !== SP
      ? candidate?.user_job_post_details?.screening_evalution_details
      : evaluatorId;
  const technicalEvalId =
    role !== TE
      ? candidate?.user_job_post_details?.technical_evalution_details
      : evaluatorId;
  const [evaluId, setEvaluId] = useState("");
  const [evaluType, setEvaluType] = useState("");
  useEffect(() => {
    let evalId = evaluatorId;
    let evalType;
    if (evaluation) {
      switch (evaluation) {
        case "screening":
          evalType = SP;
          evalId = screeningEvalId;
          break;
        case "technical":
          evalType = TE;
          evalId = technicalEvalId;
          break;
        default:
          evalType = MODERATOR;
          evalId = moderatorEvalId;
      }
    }
    setEvaluId(evalId);
    setEvaluType(evalType);
    if (evalId === ""){
      setQuestions([]);
      setSkills([]);
    }
  }, [evaluation]);

  async function fetchScreenEvaluationSkills(evalId, evalType) {
    setLoading(true);

    try {
      const res = await getScreenEvaluationSkills(
        userJobPostId,
        evalId,
        evalType
      );
      // const { rows } = res
      const updatedList = res?.map(item => {
        let comment;
        let rating;
        switch (evalType) {
          case SP:
            comment = item?.sp_comment;
            rating = item?.sp_rating;
            break;
          case TE:
            comment = item?.evaluator_comment;
            rating = item?.evaluator_rating;
            break;
          default:
            comment = item?.moderator_comment;
            rating = item?.moderator_rating;
            break;
        }
        return {
          evaluateId: item.id,
          skillInfo:
            item?.skill_details?.length > 0 ? item.skill_details[0] : {name:""},
          comment,
          rating,
        };
      });
      // ErrorHandlingToaster(res)

      
      const sortedEvaluations = updatedList.sort((a, b) => {
        const updatedAtA = new Date(a.skillInfo.updatedAt);
        const updatedAtB = new Date(b.skillInfo.updatedAt);
        return updatedAtB - updatedAtA;
      });
      
     

      setSkills(updatedList);
      setLoading(false);
    } catch (err) {
      // console.error(err);
      toast.error(err.message || "Something went wrong !!")
      setLoading(false);
    }
  }

  async function fetchScreenEvaluationQuestions(evalId, evalType) {
    try {
      setLoading(true);
      const res = await getScreenEvaluationQuestions(
        userJobPostId,
        jobPostId,
        evalId,
        evalType
      );
      // const {data} = res
      const tweakData = res?.map(item => {
        let comment;
        let rating;
        switch (evalType) {
          case SP:
            comment = item?.sp_comment;
            rating = item?.sp_rating;
            break;
          case TE:
            comment = item?.evaluator_comment;
            rating = item?.evaluator_rating;
            break;
          default:
            comment = item?.moderator_comment;
            rating = item?.moderator_rating;
            break;
        }

        return {
          candidateJobId: item?.user_job_post_id,
          evaluateId: item?.id,
          answer: item?.answer,
          comment,
          rating,
          view: item?.is_active,
          questionName: item?.job_post_question?.length ? item?.job_post_question[0]  : "",
        };
      });

      // ErrorHandlingToaster(res)
      setQuestions(tweakData);
      setLoading(false);
      // setTimeout(() => {
      // }, 1000);
    } catch (error) {
      setLoading(false);
      // console.error(error);
      toast.error(error.message || "Something went wrong!!")
      // setSkills([])
    }

  }
  async function updateSkillHandler(skillId, comment, rating, evaluateId) {
    const reqObject = {
      skillId,
      comment,
      rating,
      userJobPostId,
    };
    const res = await updateScreenEvaluationSkills(
      evaluateId,
      reqObject
    );
    const { data, status } = res

    /*Pending Evaluated Questions Count start here */
      // let count = {
      //   totalSkillsCount:10,
      //   pendingSkillCount:5
      // }

      // dispatch(handlePendingEvaluatedCount(count))
    /*Pending Evaluated Questions Count start here */



    if(status === 200 || status === 201){
      toast.success("updated successfully")
      return;
    }
    ErrorHandlingToaster(res)
  }

  async function createSkillHandler(skillId, comment, rating, cb) {
    const reqObject = {
      skillId,
      comment,
      rating,
      userJobPostId,
    };
    try {
      const res = await postScreenEvaluationSkills(reqObject);

      if (res.status === 200 || res.status === 201) {
        toast.success("Added Successfully");

        fetchScreenEvaluationSkills(evaluId, evaluType);
        cb();
      } else {
        toast.error(res.status + ": Something went wrong");
        cb();
      }
    } catch (err) {
      console.error(err);
      toast.error(err.message || "Something went wrong !!")
    }
  }

  async function deleteSkillHandler(evaluateId) {
    const res = await deleteScreenEvaluationSkill(evaluateId);
    const { data, status } = res
    if (status === 200 || status === 201) {
      toast.success("Deleted Successfully");
      fetchScreenEvaluationSkills(evaluId, evaluType);
    }else{
    ErrorHandlingToaster(res)

    }
  }

  async function updateQuestionHandler(data) {
    const { answer, questionName, comment, rating, evaluateId } = data;
    const reqObj = {
      answer,
      questionName,
      userJobPostId,
      comment,
      rating,
    };

    const res = await updateScreenEvaluationQuestions(evaluateId, reqObj);
    if (res.status === 200 || res.status === 201) {
      toast.success("successfully updated");
      fetchScreenEvaluationQuestions(evaluId, evaluType);
      fetchScreenEvaluationSkills(evaluId, evaluType)
    }else{
    ErrorHandlingToaster(res)

    }
  }

  async function createQuestionHandler(data,cb) {
    const { answer, questionName, comment, rating } = data;
    const reqObj = {
      answer,
      questionName,
      userJobPostId,
      comment,
      rating,
    };

    const res = await postScreenEvaluationQuestions(reqObj);
    if (res.status === 200 || res.status === 201) {
      toast.success("successfully updated");
      fetchScreenEvaluationQuestions(evaluId, evaluType);
      fetchScreenEvaluationSkills(evaluId, evaluType)
      cb();
    }else {
      toast.error(res.status + ": Something went wrong");
      cb();
    }
  }

  async function deleteQuestionHandler(evaluateId) {
    const res= await deleteScreenEvaluationQuestions(evaluateId);

    const { data, status } = res
    if (status === 200 || status === 201) {
      toast.success("successfully deleted");
      fetchScreenEvaluationQuestions(evaluId, evaluType);
    }else{
    ErrorHandlingToaster(res)

    }
  }
 
  function FindEvaluationPage() {
    switch (evaluation) {
      case "screening":
        return (
          <ScreeningEvaluation
            type={SP}
            loading={loading}
            questionData={questions}
            skillData={skills}
            createSkillHandler={createSkillHandler}
            createQuestionHandler={createQuestionHandler}
            updateQuestionHandler={updateQuestionHandler}
            deleteQuestionHandler={deleteQuestionHandler}
            deleteSkillHandler={deleteSkillHandler}
            updateSkillHandler={updateSkillHandler}
            // pendingCountState={pendingCountState}
          />
        );
      case "technical":
        return (
          <ScreeningEvaluation
            type={TE}
            loading={loading}
            questionData={questions}
            skillData={skills}
            createSkillHandler={createSkillHandler}
            createQuestionHandler={createQuestionHandler}
            updateQuestionHandler={updateQuestionHandler}
            deleteQuestionHandler={deleteQuestionHandler}
            deleteSkillHandler={deleteSkillHandler}
            updateSkillHandler={updateSkillHandler}
            // pendingCountState={pendingCountState}
          />
        );
      case "moderate":
        return (
          <ScreeningEvaluation
            type={MODERATOR}
            loading={loading}
            questionData={questions}
            skillData={skills}
            createSkillHandler={createSkillHandler}
            createQuestionHandler={createQuestionHandler}
            updateQuestionHandler={updateQuestionHandler}
            deleteQuestionHandler={deleteQuestionHandler}
            deleteSkillHandler={deleteSkillHandler}
            updateSkillHandler={updateSkillHandler}
            // pendingCountState={pendingCountState}
          />
        );
      default:
        return null
    }
  }

 

  return (
    <Stack spacing={2}>
      {/* <TimerHook /> */}
      <UserJobPostEvaluationTabs
        evaluation={evaluation}
        setEvaluId={setEvaluId}
        setEvaluation={setEvaluation}
        setEvaluType={setEvaluType}
        fetchSkills={fetchScreenEvaluationSkills}
        fetchQuestions={fetchScreenEvaluationQuestions}
      />
      <FindEvaluationPage />
    </Stack>
  );
}
