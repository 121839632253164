import { Stack, Typography, Tooltip, Tabs, Box, ToggleButton, ToggleButtonGroup, Button } from "@mui/material";

import { SkillEvaluation, QuestionEvaluation } from "../Results";
import { PrimaryChip, deleteIconContainer, PrimarySaveButton } from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { CircularProgress } from "@mui/material";
import AddASkill from "./newSkillModal";
import { useState } from "react";
import AddAQuestion from "./newQuestionModal";
import noDataFound from "../../../../../assets/images/zepul/shiyftEmpty.png";
import SearchParamHook from "hooks/searchParams";
import { useDispatch, useSelector } from "react-redux";
import { TE, SP, MODERATOR } from "common/constants/userModules";
import AddIcon from '@mui/icons-material/Add';

function evaluationId(role, candidate) {
  let evalId;
  switch (role) {
    case SP:
      evalId = candidate?.screening_partner_id;
      break;
    case TE:
      evalId = candidate?.evaluation_id;
      break;
    default:
      evalId = candidate?.moderator_id;
  }
  return evalId;
}

export default function ScreeningEvaluation({
  loading,
  skillData,
  updateSkillHandler,
  deleteSkillHandler,
  questionData,
  createSkillHandler,
  updateQuestionHandler,
  deleteQuestionHandler,
  createQuestionHandler,
  // readOnly,
  type,
  // pendingCountState
}) {
  const searchParams = SearchParamHook();
  const [skillModal, setSkillModal] = useState(false);
  const [questionModal, setQuestionModal] = useState(false);
  const { candidate } = useSelector(state => state.Candidates);
  const stage = searchParams?.get("stage_name");
  const noEntry = ["On boarded", "Rejected"].includes(stage);
  const role = JSON.parse(localStorage.getItem("authUser")).role;
  const logId = JSON.parse(localStorage.getItem("authUser")).id;
  const evaluatorId = evaluationId(role, candidate?.user_job_post_details);
  const readOnly = role === type;
  const [evaluationTab, setEvaluationTab] = useState("skills");
 

  // let pendingSkillCount = pendingCountState?.pendingSkillCount;


  function handleTypeChange(_, data) {
    // searchParams?.set("evaluation_type", data);
    // setType(data);
    setEvaluationTab(data);
    // history.replace({ search: searchParams.toString() });
  }
  return (
    <Box
      sx={{
        border: '1px solid #ccc',
        borderRadius: "10px",
        padding: "15px"
      }}
      mb={2}
    >
      <Stack mb={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack>
          <ToggleButtonGroup
            value={evaluationTab}
            exclusive
            onChange={handleTypeChange}
            sx={{
              border: "none",
              borderRadius: "10px",
            }}
            size="small"
          >
            <ToggleButton value="skills"
              sx={{
                border: "none",
                borderRadius: "10px !important",
                fontSize: "11px",
                marginRight: "5px",
                "&.Mui-selected": {
                  backgroundColor: "#cde2e7 !important",
                  color: "#024430 !important",
                  borderRadius: "10px !important",
                }
              }}
            >
              Skills
            </ToggleButton>
            <ToggleButton value="questions"
              sx={{
                border: "none",
                borderRadius: "10px !important",
                fontSize: "11px",
                marginLeft: "5px",
                "&.Mui-selected": {
                  backgroundColor: "#cde2e7 !important",
                  color: "#024430 !important",
                  borderRadius: "10px !important",
                }
              }}
            >
              Questions
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
        >
          {evaluationTab === "skills" ? (
            <>
              {noEntry && readOnly ? (
                <Tooltip title={"Sorry it's in Rejection Stage"}>
                  <PrimarySaveButton variant="contained" size="small" startIcon={<AddIcon />}
                    sx={{
                      padding: "5px 10px",
                      boxShadow: "none"
                    }}
                    onClick={() => setSkillModal(true)}
                  >
                    Skills
                  </PrimarySaveButton>
                </Tooltip>
              ) : evaluatorId === null && readOnly ? (
                readOnly && (
                  <PrimarySaveButton variant="contained" size="small" startIcon={<AddIcon />}
                    sx={{
                      padding: "5px 10px",
                      boxShadow: "none"
                    }}
                    onClick={() => setSkillModal(true)}
                  >
                    Skills
                  </PrimarySaveButton>
                )
              ) : evaluatorId !== logId && readOnly ? (
                <Tooltip title={"Evaluation in progress"}>
                  <PrimarySaveButton variant="contained" size="small" startIcon={<AddIcon />}
                    sx={{
                      padding: "5px 10px",
                      boxShadow: "none"
                    }}
                    onClick={() => setSkillModal(true)}
                  >
                    Skills
                  </PrimarySaveButton>
                </Tooltip>
              ) : (
                readOnly && (
                  <PrimarySaveButton variant="contained" size="small" startIcon={<AddIcon />}
                    sx={{
                      padding: "5px 10px",
                      boxShadow: "none"
                    }}
                    onClick={() => setSkillModal(true)}
                  >
                    Skills
                  </PrimarySaveButton>
                )
              )}

              {/* {readOnly && <PrimaryChip
            color="primary"
            variant="filled"
            label="Add Skill"
            onClick={()=>setSkillModal(true)}
            icon={
              <div style={deleteIconContainer}>
                <FeatherIcon icon="plus" size="14" />
              </div>
            }
          />} */}

              {/* {((!noEntry && role === type && (evaluatorId !== null || evaluatorId !== logId)&& (evaluatorId == null || evaluatorId == logId))) && 
          <Tooltip title={"Evaluation in progress"}>
            <div>
              <PrimaryChip
              color="primary"
              variant="filled"
              label="Add Skill"
              disabled
              onClick={()=>setSkillModal(true)}
              icon={
                <div style={deleteIconContainer}>
                  <FeatherIcon icon="plus" size="14" />
                </div>
              }
            />
            </div>
          </Tooltip>
          } */}
            </>


          ) : evaluationTab === "questions" ? (


            <>
              {/* {readOnly && <PrimaryChip
            color="primary"
            variant="filled"
            label="Add Question"
            onClick={()=>setQuestionModal(true)}
            icon={
              <div style={deleteIconContainer}>
                <FeatherIcon icon="plus" size="14" />
              </div>
            }
          />} */}
              {noEntry && readOnly ? (
                <Tooltip title={"Sorry it's in Rejection Stage"}>
                  <PrimarySaveButton variant="contained" size="small" startIcon={<AddIcon />}
                    sx={{
                      padding: "5px 10px",
                      boxShadow: "none"
                    }}
                    onClick={() => setQuestionModal(true)}
                  >
                    Questions
                  </PrimarySaveButton>
                </Tooltip>
              ) : evaluatorId === null && readOnly ? (
                readOnly && (
                  <PrimarySaveButton variant="contained" size="small" startIcon={<AddIcon />}
                    sx={{
                      padding: "5px 10px",
                      boxShadow: "none"
                    }}
                    onClick={() => setQuestionModal(true)}
                  >
                    Questions
                  </PrimarySaveButton>
                )
              ) : evaluatorId !== logId && readOnly ? (
                <Tooltip title={"Evaluation in progress"}>
                  <PrimarySaveButton variant="contained" size="small" startIcon={<AddIcon />}
                    sx={{
                      padding: "5px 10px",
                      boxShadow: "none"
                    }}
                    onClick={() => setQuestionModal(true)}
                  >
                    Questions
                  </PrimarySaveButton>
                </Tooltip>
              ) : (
                readOnly && (
                  <PrimarySaveButton variant="contained" size="small" startIcon={<AddIcon />}
                    sx={{
                      padding: "5px 10px",
                      boxShadow: "none"
                    }}
                    onClick={() => setQuestionModal(true)}
                  >
                    Questions
                  </PrimarySaveButton>
                )
              )}
              {/* {((!noEntry && role === type && evaluateId !== logId && evaluateId !==null)) &&
          <Tooltip title={"Evaluation in progress"}>
            <div>

              <PrimaryChip
              color="primary"
              variant="filled"
              label="Add Question"
              disabled
              onClick={()=>setQuestionModal(true)}
              icon={
                <div style={deleteIconContainer}>
                  <FeatherIcon icon="plus" size="14" />
                </div>
              }
            />
            </div>
          </Tooltip>
          } */}
            </>

          ) : null}

        </Stack>
      </Stack>

      <Stack direction={"column"} spacing={1}>
        {evaluationTab === "skills" ? (
          <Stack
            style={{ overflowY: "scroll", height: "calc(100vh - 370px)" }}
            flex={1}
            spacing={1}
          >
            <Stack
              paddingRight={1}
              direction={"row"}
              justifyContent={"space-between"}

            >

              {/*
           {pendingSkillCount > 0 ? (
            <Typography>{`Skills ${pendingSkillCount}Left`}</Typography>
          ) : (
            <Typography>Skills</Typography>
          )}
          */}




            </Stack>
            {skillModal && (
              <AddASkill
                open={skillModal}
                updateHandler={createSkillHandler}
                toggleModel={() => setSkillModal(!skillModal)}
              />
            )}
            {loading ? (
              <div style={{ display: "grid", placeItems: "center" }}>
                <CircularProgress />
              </div>
            ) : skillData?.length > 0 ? (
              skillData?.map((data, index) => {
                return (
                  // <div style={{height:"500px",overflowY:"scroll"}}>
                  <SkillEvaluation
                    key={data.evaluateId}
                    updateSkillHandler={updateSkillHandler}
                    deleteSkillHandler={deleteSkillHandler}
                    skill={data}
                    readOnly={
                      noEntry
                        ? noEntry
                        : evaluatorId === null && readOnly
                          ? !readOnly
                          : evaluatorId !== logId && readOnly
                            ? readOnly
                            : !readOnly
                    }
                  />
                  // </div>
                );
              })
            ) : (
              <img
                style={{ alignSelf: "center" }}
                src={noDataFound}
                alt="no_data"
                width={150}
                height={150}
              />
            )}
          </Stack>
        ) : evaluationTab === "questions" ? (
          <Stack
            style={{ overflowY: "scroll", height: "calc(100vh - 370px)" }}
            flex={1}
            spacing={1}
          >
            <Stack
              paddingRight={1}
              direction={"row"}
              justifyContent={"space-between"}
            >


            </Stack>
            {questionModal && (
              <AddAQuestion
                open={questionModal}
                updateHandler={createQuestionHandler}
                toggleModel={() => setQuestionModal(!questionModal)}
              />
            )}
            {loading ? (
              <div style={{ display: "grid", placeItems: "center" }}>
                <CircularProgress />
              </div>
            ) : questionData?.length > 0 ? (
              questionData?.map(data => {
                return (
                  <QuestionEvaluation
                    readOnly={
                      noEntry
                        ? noEntry
                        : evaluatorId === null && readOnly
                          ? !readOnly
                          : evaluatorId !== logId && readOnly
                            ? readOnly
                            : !readOnly
                    }
                    key={data.evaluateId}
                    evType={type}
                    question={data}
                    updateQuestionHandler={updateQuestionHandler}
                    deleteQuestionHandler={deleteQuestionHandler}
                  />
                );
              })
            ) : (
              <img
                style={{ alignSelf: "center" }}
                src={noDataFound}
                alt="no_data"
                width={150}
                height={150}
              />
            )}
          </Stack>
        ) : null}
      </Stack>
    </Box>
  );
}
