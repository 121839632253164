import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  Svg,
  Polygon,
  StyleSheet,
  Image
} from "@react-pdf/renderer";
// import { Image } from "react-native";
import Star from "../../../../assets/images/Star 1.svg";
import CircularProgressBar from "./CircularProgressBar";
import CommentImage from "../../../../assets/images/comment.svg";

const styles = StyleSheet.create({
  title: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
    fontSize: 14,
  },
  subTitle: {
    fontSize: 12,
    marginBottom: 10,
    color: "rgba(0, 0, 0, 1)",
  },
  nodata: {
    textAlign: "center",
    fontSize: "12px",
    color: "#000000",
  },
  cardContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between", // Distribute cards evenly in each row
    backgroundColor: "rgba(217, 217, 217, 0.24)",
    border: "1px solid",
    borderRadius: "4px",
    height: "auto",
    padding: 6,
    marginBottom: "10px",
  },
  card: {
    width: "32%", // Adjust the width to fit three cards per row
    border: "0.5px solid #ffffff",
    borderRadius: "4px",
    marginBottom: 10,
    padding: 4,
    backgroundColor: "rgba(255, 255, 255, 1)",
    minHeight: "94px",
    maxHeight: "95px",
    textOverflow: "ellipsis",
  },
  graphCard: {
    width: "40%", // Adjust the width to fit three cards per row
    border: "0.5px solid #ffffff",
    borderRadius: "4px",
    marginBottom: 10,
    padding: 4,
    backgroundColor: "rgba(255, 255, 255, 1)",
    minHeight: "94px",
    maxHeight: "95px",
    textOverflow: "ellipsis",
  },
  cardMain: {
    display: "flex",
    flexDirection: "column",
    padding: 2,
    height: "90%",
  },
  profileCardMain: {
    display: "flex",
    flexDirection: "row",
    padding: 2,
    height: "90%",
  },
  profileDetailsSection: {
    display: "flex",
    flexDirection: "column",
    width: "30%",
  },
  profileDetailsColSection: {
    display: "flex",
    flexDirection: "column",
    wordWrap: "break-word",
    width: "95%",
    paddingLeft: "15%",
  },
  profileDetailsRowSection: {
    display: "flex",
    flexDirection: "row",
    padding: "8px",
  },
  jobTitle: {
    color: "rgba(0, 0, 0, 1)",
    fontSize: "10px",
    fontWeight: 400,
  },
  role: {
    fontSize: "10px",
    color: "rgba(0, 0, 0, 1)",
    fontWeight: 500,
    wordWrap: "break-word",
    marginleft: "100px",
  },

  cardHeader1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  horizontal: {
    borderBottom: "0.5px solid rgba(0, 0, 0, 0.08)",
    marginTop: 2, // Adjust the margin as needed
    marginBottom: 3,
    // borderWidth:"1px",
    width: "100%",
    color: "rgba(0, 0, 0, 0.08)",
  },
  skillName: {
    color: "rgba(0, 0, 0, 1)",
    fontSize: "10px",
  },

  commentHead: {
    color: "rgba(0, 0, 0, 0.8)",
    fontSize: "9px",
    paddingBottom: "5px",
  },

  commentsName: {
    color: "rgba(0, 0, 0, 0.6)",
    // opacity:"60%",
    fontSize: "9px",
    wordWrap: "break-word",
  },
  button: {
    backgroundColor: "rgba(246, 247, 249, 1)",
    border: "0.5px solid rgba(221, 221, 221, 1)",
    fontSize: "8px",
    borderRadius: "4px",
    padding: "2px",
  },
  star: {
    fontSize: 16, // Adjust the size as needed
    color: "#FFD700", // Yellow color for the star
  },

  //   skillName: {
  //     fontSize: 16,
  //     color: "#044B36",
  //     marginBottom: 8,
  //   },
  ratingContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  ratingText: {
    fontSize: 16,
    color: "#FFD700", // Yellow color for stars
    marginRight: 4,
  },
  comments: {
    fontSize: 15,
    opacity: 0.6,
  },
});

export default function PdfScoreCard({
  jobTitle,
  candidate,
  score,
  screening,
  moderator,
  evaluator,
  jobPostInfo,
  evaluatorQues,
  moderatorQues,
  screeningQues,
}) {
  function Star() {
    return (
      <>
        <Text style={styles.star}>&#9733;</Text>
      </>
    );
  }

  return (
    <Document>
      <Page style={{ padding: 16 }} size="A4">
        <View style={styles.title}>
          <Text>Score Card</Text>
        </View>

        <View style={styles.cardContainer}>
          <View style={styles.profileCardMain}>
            <View style={styles.profileDetailsSection}>
              <View style={styles.profileDetailsRowSection}>
                <Text style={styles.jobTitle}>Job title {"   "}</Text>
                <Text style={styles.role}>
                  :{jobTitle}
                  {"   "}
                </Text>
              </View>

              <View style={styles.profileDetailsRowSection}>
                <Text style={styles.jobTitle}>Company </Text>
                <Text style={styles.role}>
                  :{jobPostInfo?.company_details?.company_name}
                  {"   "}
                </Text>
              </View>

              <View style={styles.profileDetailsRowSection}>
                <Text style={styles.jobTitle}>Applicant </Text>
                <Text style={styles.role}>
                  :{`${candidate?.first_name} ${candidate?.last_name}`}
                </Text>
              </View>
            </View>

            <View style={styles.profileDetailsSection}>
              <View style={styles.profileDetailsRowSection}>
                <Text style={styles.jobTitle}>Screening Partner </Text>
                <Text style={styles.role}>:{score && score[0]?.userType}</Text>
              </View>

              <View style={styles.profileDetailsRowSection}>
                <Text style={styles.jobTitle}>Technical Partner </Text>
                <Text style={styles.role}>: {score && score[1]?.userType}</Text>
              </View>

              <View style={styles.profileDetailsRowSection}>
                <Text style={styles.jobTitle}>Moderator {"           "}</Text>
                <Text style={styles.role}>: {score && score[2]?.userType}</Text>
              </View>
            </View>

            <View
              style={{
                ...styles.graphCard,
                backgroundColor: "transparent",
                border: 0,
              }}
            >
              {/* <View style={styles.profileDetailsRowSection}>
                <CircularProgressBar rating={9.5} />
                <View style={styles.profileDetailsColSection}>
                  <Text style={{ ...styles.skillName, marginBottom: "6px" }}>
                    Overall Rating
                  </Text>
                  <Text style={styles.commentsName}>
                    This is the average rating taken from all the three
                    evaluator’s
                  </Text>
                </View>
              </View> */}
            </View>
          </View>
        </View>

        <Text style={styles.subTitle}>Overall Profile Rating</Text>
        <View style={styles.cardContainer}>
          {score?.length > 0 ? (
            score?.map((data, id) => (
              <View style={styles.graphCard} key={id}>
                <View style={styles.profileDetailsRowSection}>
                  <CircularProgressBar
                    rating={`${data?.formattedText}`}
                    status={`${data?.status}`}
                  />
                  <View style={styles.profileDetailsColSection}>
                    <Text style={{ ...styles.skillName, marginBottom: "6px" }}>
                      {/* Overall Rating */}
                      {data?.userType}
                    </Text>
                    <Text style={styles.commentsName}>
                      {/* This is the average rating taken from all the three
                    evaluator’s */}
                      {data?.comment}
                    </Text>
                  </View>
                </View>
              </View>
            ))
          ) : (
            <Text>No data</Text>
          )}
        </View>

        <Text style={styles.subTitle}>
          Screening evaluation( Skill Rating )
        </Text>
        <View style={styles.cardContainer}>
          {screening?.length > 0 ? (
            screening?.map((data, id) => (
              <View key={id} style={styles.card}>
                <View style={styles.cardMain}>
                  <View style={styles.cardHeader1}>
                    <Text style={styles.skillName}>
                      {data?.skill_details && data.skill_details[0]?.name
                        ? data.skill_details[0].name
                        : ""}
                      {/* {(data?.job_post_question?.length > 0
                      ? data.job_post_question[0]
                      : "") ||
                      (data?.skill_details?.length > 0
                        ? data.skill_details[0]?.name
                        : "")} */}
                    </Text>

                    <Text style={styles.button}>
                      {data?.sp_rating ? `${data.sp_rating}/5` : "0/5"}
                    </Text>
                  </View>
                  <Text style={styles.horizontal}></Text>
                  <Text style={styles.commentHead}>#comments</Text>
                  <Text style={styles.commentsName}>
               
                    {data?.sp_comment?data.sp_comment:"null"}
                  </Text>

                  {/* <View>
                    <Image
                      source={{
                        uri: "https://api.time.com/wp-content/uploads/2015/04/google-sign.jpg",
                      }}
                      style={{ height: 100, width: 100 }}
                    />
                  </View> */}


                </View>
              </View>
            ))
          ) : (
            <Text style={styles.nodata}>No data</Text>
          )}
        </View>

        <Text style={styles.subTitle}>
          Screening evaluation( Questions Rating )
        </Text>
        <View style={styles.cardContainer}>
          {screeningQues?.length > 0 ? (
            screeningQues?.map((data, id) => (
              <View key={id} style={styles.card}>
                <View style={styles.cardMain}>
                  <View style={styles.cardHeader1}>
                    <Text style={styles.skillName}>
                      {" "}
                      {(data?.job_post_question?.length > 0
                        ? data.job_post_question[0]
                        : "") ||
                        (data?.skill_details?.length > 0
                          ? data.skill_details[0]?.name
                          : "")}
                    </Text>

                    <Text style={styles.button}>
                      {data?.sp_rating ? `${data.sp_rating}/5` : "0/5"}
                      {/* 3.5/10 {" "} <Image src={Star} style={styles.star} /> */}
                    </Text>
                  </View>
                  <Text style={styles.horizontal}></Text>
                  <Text style={styles.commentHead}>#comments</Text>
                  <Text style={styles.commentsName}>
                    {`${data?.sp_comment}`}
                  </Text>
                </View>
              </View>
            ))
          ) : (
            <Text style={styles.nodata}>No data</Text>
          )}
        </View>

        <Text style={styles.subTitle}>
          Technical evaluation( Skill Rating )
        </Text>
        <View style={styles.cardContainer}>
          {evaluator?.length > 0 ? (
            evaluator?.map((data, id) => (
              <View key={id} style={styles.card}>
                <View style={styles.cardMain}>
                  <View style={styles.cardHeader1}>
                    <Text style={styles.skillName}>
                      {data?.skill_details && data.skill_details[0]?.name
                        ? data.skill_details[0].name
                        : ""}
                    </Text>

                    <Text style={styles.button}>
                      {data?.evaluator_rating
                        ? `${data.evaluator_rating}/5`
                        : "0/5"}
                    </Text>
                  </View>
                  <Text style={styles.horizontal}></Text>
                  <Text style={styles.commentHead}>#comments</Text>
                  <Text style={styles.commentsName}>
                    {data?.evaluator_comment}
                  </Text>
                </View>
              </View>
            ))
          ) : (
            <Text style={styles.nodata}>No data</Text>
          )}
        </View>

        <Text style={styles.subTitle}>
          Technical evaluation( Questions Rating )
        </Text>
        <View style={styles.cardContainer}>
          {evaluatorQues?.length > 0 ? (
            evaluatorQues?.map((data, id) => (
              <View key={id} style={styles.card}>
                <View style={styles.cardMain}>
                  <View style={styles.cardHeader1}>
                    <Text style={styles.skillName}>
                      {" "}
                      {(data?.job_post_question?.length > 0
                        ? data.job_post_question[0]
                        : "") ||
                        (data?.skill_details?.length > 0
                          ? data.skill_details[0]?.name
                          : "")}
                    </Text>

                    <Text style={styles.button}>
                      {data?.evaluator_rating
                        ? `${data.evaluator_rating}/5`
                        : "0/5"}
                    </Text>
                  </View>
                  <Text style={styles.horizontal}></Text>
                  <Text style={styles.commentHead}>#comments</Text>
                  <Text style={styles.commentsName}>
                    {data?.evaluator_comment}
                  </Text>
                </View>
              </View>
            ))
          ) : (
            <Text style={styles.nodata}>No data</Text>
          )}
        </View>

        <Text style={styles.subTitle}>
          Moderator evaluation( Skill Rating )
        </Text>
        <View style={styles.cardContainer}>
          {moderator?.length > 0 ? (
            moderator?.map((data, id) => (
              <View key={id} style={styles.card}>
                <View style={styles.cardMain}>
                  <View style={styles.cardHeader1}>
                    <Text style={styles.skillName}>
                      {data?.skill_details && data.skill_details[0]?.name
                        ? data.skill_details[0].name
                        : ""}
                    </Text>

                    <Text style={styles.button}>
                      {data?.moderator_rating
                        ? `${data.moderator_rating}/5`
                        : "0/5"}
                    </Text>
                  </View>
                  <Text style={styles.horizontal}></Text>
                  <Text style={styles.commentHead}>#comments</Text>
                  <Text style={styles.commentsName}>
                    {data?.moderator_comment}
                  </Text>
                </View>
              </View>
            ))
          ) : (
            <Text style={styles.nodata}>No data</Text>
          )}
        </View>

        <Text style={styles.subTitle}>
          Moderator evaluation( Questions Rating )
        </Text>
        <View style={styles.cardContainer}>
          {moderatorQues?.length > 0 ? (
            moderatorQues?.map((data, id) => (
              <View key={id} style={styles.card}>
                <View style={styles.cardMain}>
                  <View style={styles.cardHeader1}>
                    <Text style={styles.skillName}>
                      {(data?.job_post_question?.length > 0
                        ? data.job_post_question[0]
                        : "") ||
                        (data?.skill_details?.length > 0
                          ? data.skill_details[0]?.name
                          : "")}
                    </Text>

                    <Text style={styles.button}>
                      {data?.moderator_rating
                        ? `${data.moderator_rating}/5`
                        : "0/5"}
                    </Text>
                  </View>
                  <Text style={styles.horizontal}></Text>
                  <Text style={styles.commentHead}>#comments</Text>
                  <Text style={styles.commentsName}>
                    {data?.moderator_comment}
                  </Text>
                </View>
              </View>
            ))
          ) : (
            <Text style={styles.nodata}>No data</Text>
          )}
        </View>
      </Page>
    </Document>
  );
}
