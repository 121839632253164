import { DELETE, POST, PUT } from "common/constants/methods";
import callApi from "helpers/callApi";
import { toast } from "react-hot-toast";

export async function updateCommissions(mockData,jobId) {
   const response =  await callApi(`commissions/${jobId}`,mockData,PUT);
   return response
  
  }

  export async function getCommissionById(jobId) {
    const response =  await callApi(`commissions/job_post/${jobId}`);
    return response
   
   }