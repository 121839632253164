import React, { useEffect } from "react";
import { Col, Row, Alert, Input, Container } from "reactstrap";
import Slider from "@mui/material/Slider";
import "antd/dist/antd.css";
import { AvField, AvForm } from "availity-reactstrap-validation";
import "./HireInDay.css";
import { styled } from "@mui/material/styles";
import { Tooltip } from "@mui/material";
import { connect } from "react-redux";
import { updateJob } from "store/jobs/actions";
import { Button } from "reactstrap";
import createjobModel from "helpers/models/createJobModel";
import { CreateJobPost } from "helpers/services/jobs/jobs";
import { toast } from "react-hot-toast";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import ErrorHandlingToaster from "helpers/Toaster/error";

const PrettoSlider = styled(Slider)({
  color: "#024430",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "#ffe7bb",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#cde2e7",
    color:"#024430",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const HireInDay = ({
  updateJob,
  max_sal,
  commission_rate_type,
  commission_rate,
  history,
  forward,
  forwardItem,
  data,
  edit = false,
}) => {
  const [value, setValue] = React.useState(12);
  useEffect(() => {
    setValue(commission_rate);
  }, [commission_rate]);

  const handleSliderChange = (event, newValue) => {
    updateJob({
      commission_rate: newValue,
      commission_rate_type: "Percentage",
    });
    setValue(newValue);
  };

  const handleInputChange = event => {
    updateJob({
      commission_rate: event.target.value,
      commission_rate_type: "Percentage",
    });
    setValue(event.target.value === "" ? "" : Number(event.target.value));
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <Container fluid>
        <Row style={{ marginTop: "100px" }}>
          <Col xs={12} id="job-pre-header-cont">
            <div>
              <h5>Hire in Days, not Months.</h5>
            </div>
            <div className="p-2  d-flex">
              <div id="primium-right">
                <i className="fas fa-crown" id="primium-icon"></i>
              </div>
              <div id="premium">
                <h6>Premium</h6>
              </div>

              <div id="primeum-right">
                <div></div>
                <div></div>
              </div>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-lg p-3"
                style={{
                  borderRadius: "50px",
                  width: "60px",
                  color: "white",
                  left: "0px",
                  backgroundColor: "#cde2e7",
                }}
              >
                <i
                  className="fas fa-share-alt"
                  style={{
                    color: "white",
                  }}
                >
                  {" "}
                </i>
              </button>
            </div>
          </Col>
          <p>
            Commit a Referral Fee to help you close this position in just Days.
          </p>
          <Col xs={12}>
            <div id="hiring-day-cont">
              <div>
                <div>
                  <div className="form-group mb-3 ">
                    <Input
                      type="number"
                      invalid={
                        commission_rate_type === "Flat" &&
                        commission_rate === "0"
                          ? true
                          : false
                      }
                      value={
                        commission_rate_type === "Flat"
                          ? commission_rate
                          : (Number(max_sal) * Number(commission_rate)) / 100
                      }
                      onChange={e => {
                        if (e.target.value === "0") {
                          return;
                        }

                        updateJob({
                          commission_rate: e.target.value,
                          commission_rate_type: "Flat",
                        });
                      }}
                      placeholder="0.00"
                      className="form-control"
                      style={{
                        width: "150px",
                        fontSize: "25px",
                        marginRight: "20px",
                      }}
                    />
                  </div>
                </div>
                <h5 className="p-3">OR</h5>
                <div className="d-flex">
                  <Alert
                    color="success"
                    className="p-1"
                    style={{
                      height: "30px",
                      width: "100px",
                      textAlign: "center",
                    }}
                  >
                    Suggested
                  </Alert>
                  <span className="p-1" style={{ paddingTop: "20px" }}>
                    8.33%
                  </span>
                  <Tooltip title="Reset" placement="top">
                    <span
                      className="mdi mdi-reload p-1"
                      style={{ paddingTop: "20px", cursor: "pointer" }}
                      onClick={() => {
                        setValue(15);
                        updateJob({
                          commission_rate: 15,
                          commission_rate_type: "Percentage",
                        });
                      }}
                    ></span>
                  </Tooltip>
                </div>
                <div className="d-flex">
                  <div>
                    <div className="form-group mb-3 ">
                      <Input
                        name="Refferal"
                        // disabled={true}
                        invalid={
                          commission_rate_type === "Percentage" &&
                          commission_rate <= "11.9"
                            ? true
                            : false
                        }
                        // errorMessage="Minimium referral fee is 12 required"
                        validate={{ required: { value: true } }}
                        type="number"
                        onChange={handleInputChange}
                        defaultValue="12"
                        value={
                          commission_rate_type === "Percentage"
                            ? commission_rate
                            : Math.round(
                                (Number(commission_rate) / Number(max_sal)) *
                                  100
                              )
                        }
                        placeholder="0.00"
                        className="form-control"
                        style={{
                          width: "150px",
                          fontSize: "25px",
                          marginRight: "20px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex">
                    <span
                      style={{
                        height: "58px",
                        width: "60px",
                        background: "#f6f6f6",
                        padding: "14px",
                        borderRadius: "5px",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                    >
                      %
                    </span>
                    <span
                      className=""
                      style={{ paddingTop: "20px", marginLeft: "5px" }}
                    >
                      of annual salary{" "}
                    </span>
                    <span
                      className=""
                      style={{ paddingTop: "21px", marginLeft: "5px" }}
                    >
                      <span className="mdi mdi-comment-question-outline"></span>
                    </span>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>
                    {commission_rate_type == "Percentage" ? value + "%" : ""}{" "}
                  </span>
                  <span>100%</span>
                </div>
                <div style={{ width: "100%", margin: "auto" }}>
                  <PrettoSlider
                    valueLabelDisplay="auto"
                    aria-label="pretto slider"
                    defaultValue={12}
                    min={6}
                    max={40}
                    value={
                      commission_rate_type == "Percentage"
                        ? Number(value)
                        : Math.round(
                            (Number(commission_rate) / Number(max_sal)) * 100
                          )
                    }
                    onChange={handleSliderChange}
                  />
                </div>
                <div>
                  <p>
                    <span>
                      <i className="fas fa-info-circle"></i>{" "}
                    </span>
                    <span>Pay only on Successful Hire</span>
                  </p>
                  <p>
                    <span>
                      <i className="fas fa-info-circle"></i>
                      {"  "}
                    </span>
                    <span
                      className="mdi mdi-currency-gbp"
                      style={{ color: "#024430" }}
                    >
                      500 {"  "}
                    </span>
                    <span>
                      refundable deposit is collected now, refunded when job is
                      closed.
                    </span>
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <i className="fas fa-check"></i>
                  </div>
                  <div>
                    <p>
                      <span>100% Risk-Free,</span> as you pay only after a
                      successful hire.
                    </p>
                  </div>
                </div>
                <div>
                  <div>
                    <i className="fas fa-check"></i>
                  </div>
                  <div>
                    <p>
                      <span>500%</span> Faster responses as <span>2000+</span>{" "}
                      Partners and <span>600,000+</span> Candidates will source
                      talents for you.
                    </p>
                  </div>
                </div>
                <div>
                  <div>
                    <i className="fas fa-check"></i>
                  </div>
                  <div>
                    <p>
                      <span>Save 100+ hours</span> as we do pre-screening
                      following your process, coordinate interviews, and more.
                    </p>
                  </div>
                </div>
                <div>
                  <div>
                    <i className="fas fa-check"></i>
                  </div>
                  <div>
                    <p>
                      Marketing via Outbound Calls, Text/SMS, WhatsApp, Social
                      Media, etc.
                    </p>
                  </div>
                </div>
                <p
                  style={{
                    paddingLeft: "10px",
                    color: "gray",
                    fontSize: "12px",
                  }}
                >
                  Trusted by the world's leading companies
                </p>
                <img
                  src="https://optimhire.com/assets/img/trusted-imgs-min.png"
                  alt=""
                  style={{ width: "80%", marginLeft: "10px" }}
                />
              </div>
            </div>
          </Col>
        </Row>
        {/* <div className="d-flex justify-content-between mt-5 w-full mb-2 "> */}
        {!edit ? (
          <Row>
            <Col xs={8}>
              <Button
                type="primary"
                className="danger"
                style={{
                  background: "red !important",
                  outline: "none",
                  border: "none",
                }}
                onClick={() => {
                  if (forwardItem < 1) return;
                  forward((Number(forwardItem) - 1).toString());
                }}
              >
                cancel
              </Button>
            </Col>
            <Col xs={4}>
              <Button
                className="mx-4"
                color="primary"
                onClick={() => {
                  // forward((Number(forwardItem) + 1).toString(), true);
                }}
              >
                Go Premium
              </Button>
              <Button
                onClick={async () => {
                  const result = await CreateJobPost(createjobModel(data));
                  ErrorHandlingToaster(result);
                  // toast.success("Processing complete!");
                  if (result.status === 201) history.push("/");
                }}
              >
                Keep this job Free
              </Button>
            </Col>
          </Row>
        ) : (
          ""
        )}
        {/* </div> */}
      </Container>
    </div>
  );
};
const mapStateToProps = ({ Jobs }) => ({
  commission_rate: Jobs.commission_rate,
  commission_rate_type: Jobs.commission_rate_type,
  max_sal: Jobs.max_sal,
  data: Jobs,
});

const mapDispatchToProps = dispatch => ({
  updateJob: e => dispatch(updateJob(e)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HireInDay)
);
