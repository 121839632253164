import { withRouter } from "react-router-dom";
import React, { useCallback, useState } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import CropModel from "./cropModel";
const { Row, Col, Card, Form } = require("reactstrap");
import { centerCrop, makeAspectCrop, Crop, PixelCrop } from "react-image-crop";
import { toast } from "react-hot-toast";
import {
  Box,
  CircularProgress,
  Modal,
  Stack,
  circularProgressClasses,
  colors,
} from "@mui/material";
import {
  BlackTypography,
  BorderedCardActionArea,
  GreyTypography,
  PrimaryButton,
  PrimaryOutlinedButton,
  PrimaryOutlinedOpacitedButton,
  PrimaryTypography,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
export default function UploadMultipleModel({ isOpen, toggle, handleImage }) {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([])
  const onDrop = useCallback(acceptedFiles => {
    setFiles([...files,...acceptedFiles])
  },[files])
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: {
        "image/jpeg": [],
        "image/png": [],
      },
      multiple: true,
      onDrop
    });

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    background: "white",
    padding: "10px",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal
      open={isOpen}
      onClose={() => {
        toggle();
        setLoading(false);
      }}
      aria-labelledby="edit-profile-modal-title"
      aria-describedby="edit-profile-modal-description"
    >
      <Box style={style}>
        <div className="modal-header my-3">
          <PrimaryTypography variant="h2">Image Upload</PrimaryTypography>
        </div>
        <div className="modal-body">
          <Form>
            <div className="dropzone">
              <BorderedCardActionArea
                className="dz-message needsclick"
                sx={{ width: "100%" }}
                {...getRootProps()}
              >
                <Stack spacing={2} alignItems={"center"}>
                  <input
                    {...getInputProps()}
                    accept="image/png, image/gif, image/jpeg"
                  />
                  <FeatherIcon icon="upload-cloud" size="40" />
                  <BlackTypography variant="h6" component="h6">
                    Drop your Images here or
                  </BlackTypography>
                  <GreyTypography variant="h6" component="h6">
                    Supported File types (20) MB
                  </GreyTypography>{" "}
                </Stack>
              </BorderedCardActionArea>
            </div>
            <div
              className="dropzone-previews mt-3"
              style={{
                overflowY: "scroll",
                maxHeight: "40vh",
              }}
              id="file-previews"
            >
              {files.map((f, i) => {
                return (
                  <Card
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    key={i + "-file"}
                  >
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          {files[0].type.startsWith("image/") ? (
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={f.name}
                              src={URL.createObjectURL(f)}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col>
                          <p className="text-muted font-weight-bold">
                            {f.name}
                          </p>
                          <p className="mb-0">
                            <strong>{formatBytes(f.size)}</strong>
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                );
              })}
            </div>
          </Form>
        </div>
        <div className="modal-footer my-2">
          {loading ? (
            <PrimaryButton sx={{ position: "relative", width: "150px" }}>
              <CircularProgress
                variant="determinate"
                sx={{
                  color: colors.grey[300],
                }}
                size={30}
                thickness={4}
                value={100}
              />
              <CircularProgress
                variant="indeterminate"
                color="primary"
                disableShrink
                sx={{
                  animationDuration: "550ms",
                  position: "absolute",
                  // left: 0,
                  [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: "round",
                  },
                }}
                size={30}
                thickness={4}
              />
            </PrimaryButton>
          ) : (
              <Stack direction={"row"} spacing={1}>
                <PrimaryOutlinedOpacitedButton
              sx={{
                width: "150px",
              }}
              onClick={() => {
                setFiles([])
                toggle()
                // setLoading(false);
              }}
            >
              Cancel
            </PrimaryOutlinedOpacitedButton>
                
            <PrimaryButton
              sx={{
                width: "150px",
              }}
              onClick={async () => {
                setLoading(true);
                if (files.length === 0) {
                  toast.error("Please select a file");
                  setLoading(false);
                  return;
                }

                if (files[0].type.startsWith("image/")) {
                }
               await handleImage(files);
                setFiles([])
                setLoading(false);
              }}
            >
              Confirm
            </PrimaryButton>
              </Stack>
          )}
        </div>
      </Box>
    </Modal>
  );
}
