import { POST } from "common/constants/methods"
import callApi from "helpers/callApi"

const endPoint= {
    send:'chats/send_message',
    get:'chats/get_chats_based_on_userjobpost',
    getMedia:'chats/get_attachments'
}

export async function getEvalauateMessages({userJobPostId,receiverId,jobPostId,page,pageSize,filterSearch}){
    const res = await callApi(`${endPoint.get}?user_job_post_id=${userJobPostId}&job_post_id=${jobPostId}&receiver_id=${receiverId}&pageNo=${page}&pageSize=${pageSize}&searchParam=${filterSearch}`)
    return res
}

export async function sendEvaluateMessage(data){
    let type = 'text'//text
    const isExtension = /\.(jpe?g|png|jpeg|gif|pdf|docx|json)$/i.test(
        data.message
      );
    if(isExtension){
        const isImage = /\.(jpe?g|png|jpeg|gif)$/i.test(
            data.message
          );
        type = isImage ? 'image' : data.message.split(".").pop()
    }
    const reqObj = {
        receiver_id:data.receiverId,
        message:data.message,
        user_job_post_id:data.userJobPostId,
        job_post_id:data.jobPostId,
        message_type:type
    }
    const res = await callApi(endPoint.send,reqObj,POST);
    return res
}

export async function getCandidateChatAttachments(data){
    const {channelId,type} = data
    const res = await callApi(`${endPoint.getMedia}?channel_id=${channelId}&type=${type}`)
    return res
}