import { Grid, Skeleton, Stack } from "@mui/material";

export default function GlobalLoader() {
  return (
    <Grid container spacing={4}>
      {Array.from(Array(10).keys())?.map((item,index) => (
        <Grid key={index} item md={4} xs={12}>
          <Stack spacing={1} alignItems={"flex-start"}>
            <Skeleton variant="rounded" width={280} height={150} />
            <Skeleton width={"70%"} />
            <Skeleton width="60%" />
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
}
