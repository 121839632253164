const { default: callApi } = require("helpers/callApi");

export const getAllEarningsDetails = async(id,type)=>{
    return await callApi(`admin_dashboard/get_admin_dashboard_details?type=${type}&id=${id}`)
}

/* in progress */
export const getAllIndividualEarnings = async(role,page,pageSize,search)=>{
    // return await callApi(`earnings?role=${role}&pageNo={page}&pageSize=${pageSize}`)
    return await callApi(`common/get_earnings?pageNo=${page}&pageSize=${pageSize}&searchParam=${search}&type=`)
}
/* in progress */