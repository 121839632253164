const { Container, Col, Row, Card } = require("reactstrap");
import React, { useEffect, useMemo } from "react";
import "../../assets/css/settings.css";
import Integrations from "./Integrations";
// import Staff from "./Staff";
import Staff from './DummyStaff';
import "./SettingCss/Profile.css";
import CompanyProfile from "../../components/profile/company/CompanyProfile";
import ZepulAdminProfile from "./ZepulAdminProfile";
import ZepulProfileEditModel from "./EditProfile/ZepulEditProfile";
import { findLoggedInUser } from "helpers/services/CommonUser/findUserbasedOnType";
import { imageURL } from "common/constants/commonURLS";
import {
  Avatar,
  Button,
  CircularProgress,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import PasswordChange from "../../components/profile/common/password";
import NotificationSettings from "../../components/profile/common/NotificationSettings";
import Generals from "../../components/profile/common/phone";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  PrimaryButton,
  PrimaryOutlinedButton,
  PrimaryTab,
  PrimaryTypography,
  StyledTabScrollButtonObject,
  StyledTabsObject,
} from "assets/Mui/globalTheme";
import EditProfile from "components/profile/editProfile";
import { blue, grey, indigo } from "@mui/material/colors";
import ScreeningPartnerProfile from "components/profile/screeningPartner/partnerProfile";
import { loginSuccess } from "store/actions";
import MobileNumber from "./mobileNumber";
import CommonProfile from "./Profile";
import { MODERATOR,RS,BVS,RC } from "common/constants/userModules";
import PartnerEarnings from "components/profile/common/PartnerEarnings";
import { parseNumberInput } from "hooks/customPhoneNumberParse";

export default function ProfileSettings() {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const [model, setModel] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [selectedNav, setSelectedNav] = useState("0");
  const [userNav, setUserNav] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.Loading.loading);
  const user = useSelector(state => state.Login);



  useEffect(() => {
    const tab = searchParams.get("tab");
    if (tab !== null) setSelectedNav(tab);
  }, [searchParams]);
  function handleNavChange(id) {
    searchParams.set("tab", id.toString());
    history.push({
      search: searchParams.toString(),
    });
    setSelectedNav(id.toString());
  }
  // useEffect(() => {
  //   // initialFetch();
  //   setUserNav(NavRoles(user?.role));
  // }, [user?.role]);

  useEffect(() => {
    setUserNav(NavRoles(user?.role));
    initialFetch();

  }, []);


  async function initialFetch() {
    dispatch({ type: "START_LOADING" });
    const getUserDetails = await findLoggedInUser();
    dispatch(loginSuccess(getUserDetails))
    setUserDetails(getUserDetails);
    dispatch({ type: "STOP_LOADING" });
  }

  const handleRecallingFunction = () => {
    initialFetch()
  }
  function toggleModel() {
    setModel(!model);
    // initialFetch();
  }
  function closeModel() {
    setModel("");
  }
  function NavRoles(role) {
    switch (role) {
      case "CA":
        return [
          {
            icon: "user",
            name: "Profile",
          },
          {
            icon: "users",
            name: "Staff",
          },
          {
            icon: "lock",
            name: "password",
          },
          {
            icon: "bell",
            name: "Notifications",
          },
          {
            icon: "smartphone",
            name: "Update Mobile Number",
          },
          // {
          //   icon: "settings",
          //   name: "General",
          // },
          // {
          //   icon: "link",
          //   name: "Integrations",
          // },
        ];
      case "ZA":
        return [
          {
            icon: "user",
            name: "Profile",
          },
          // {
          //   icon: "users",
          //   name: "Staff",
          // },
          {
            icon: "lock",
            name: "password",
          },
          {
            icon: "bell",
            name: "Notifications",
          },
          {
            icon: "smartphone",
            name: "Update Mobile Number",
          },
          // {
          //   icon: "settings",
          //   name: "General",
          // },
          // {
          //   icon: "link",
          //   name: "Integrations",
          // },
        ];
      case "BVC":
        return [
          {
            icon: "users",
            name: "Staff",
          },
          {
            icon: "lock",
            name: "password",
          },
          {
            icon: "smartphone",
            name: "Update Mobile Number",
          },
          {
            icon: "bell",
            name: "Notifications",
          },
          // {
          //   icon: "settings",
          //   name: "General",
          // },
          // {
          //   icon: "link",
          //   name: "Integrations",
          // },
        ];
        case BVS:
          return [
            // {
            //   icon: "users",
            //   name: "Staff",
            // },
            {
              icon: "lock",
              name: "password",
            },
            {
              icon: "smartphone",
              name: "Update Mobile Number",
            },
            {
              icon: "bell",
              name: "Notifications",
            },
            // {
            //   icon: "settings",
            //   name: "General",
            // },
            // {
            //   icon: "link",
            //   name: "Integrations",
            // },
          ];
      case "TE":
        return [
          {
            icon: "user",
            name: "Profile",
          },
          {
            icon: "lock",
            name: "password",
          },
          {
            icon: "bell",
            name: "Notifications",
          },
          {
            icon: "smartphone",
            name: "Update Mobile Number",
          },

          {
            icon: "dollar-sign",
            name: "Earnings",
          },

          // {
          //   icon: "settings",
          //   name: "General",
          // },
          // {
          //   icon: "link",
          //   name: "Integrations",
          // },
        ];
      case "SP":
        return [
          {
            icon: "user",
            name: "Profile",
          },

          {
            icon: "lock",
            name: "password",
          },
          {
            icon: "bell",
            name: "Notifications",
          },
          {
            icon: "smartphone",
            name: "Update Mobile Number",
          },

          {
            icon: "dollar-sign",
            name: "Earnings",
          },

          // {
          //   icon: "settings",
          //   name: "General",
          // },
          // {
          //   icon: "link",
          //   name: "Integrations",
          // },
        ];
      case "RC":
        return [
          {
            icon: "users",
            name: "Staff",
          },
          {
            icon: "lock",
            name: "password",
          },
          {
            icon: "smartphone",
            name: "Update Mobile Number",
          },
          {
            icon: "bell",
            name: "Notifications",
          },
          // {
          //   icon: "settings",
          //   name: "General",
          // },
        ];
      case RS:
          return [
            // {
            //   icon: "users",
            //   name: "Staff",
            // },
            {
              icon: "lock",
              name: "password",
            },
            {
              icon: "smartphone",
              name: "Update Mobile Number",
            },
            {
              icon: "bell",
              name: "Notifications",
            },
            // {
            //   icon: "settings",
            //   name: "General",
            // },
          ];
        case "CS":
          return [
            {
              icon: "user",
              name: "Profile",
            },
  
            {
              icon: "lock",
              name: "password",
            },
            {
              icon: "bell",
              name: "Notifications",
            },
            {
              icon: "smartphone",
              name: "Update Mobile Number",
            },
            // {
            //   icon: "settings",
            //   name: "General",
            // },
            // {
            //   icon: "link",
            //   name: "Integrations",
            // },
          ];
        case "ACM":
          return [
            {
              icon: "user",
              name: "Profile",
            },
  
            {
              icon: "lock",
              name: "password",
            },
            {
              icon: "bell",
              name: "Notifications",
            },
            {
              icon: "smartphone",
              name: "Update Mobile Number",
            },
            // {
            //   icon: "settings",
            //   name: "General",
            // },
            // {
            //   icon: "link",
            //   name: "Integrations",
            // },
          ];
        case MODERATOR:
          return [
            {
              icon: "user",
              name: "Profile",
            },
  
            {
              icon: "lock",
              name: "password",
            },
            {
              icon: "bell",
              name: "Notifications",
            },
            {
              icon: "smartphone",
              name: "Update Mobile Number",
            },
            {
              icon: "dollar-sign",
              name: "Earnings",
            },
            // {
            //   icon: "settings",
            //   name: "General",
            // },
            // {
            //   icon: "link",
            //   name: "Integrations",
            // },
          ];
      default:
        return [];
    }
  }
  function navSelectZA(id) {
    switch (id) {
      // case "0":
      //   return <ZepulAdminProfile />;
      // case "1":
      //   return <Staff />;
      // case "2":
      //   return <PasswordChange />;
      // case "3":
      //   return <NotificationSettings />;
      // case "4":
      //   return <Generals />;
      // case "5":
      //   return <Integrations />;

      case "0":
        return <CommonProfile setUserInfo={setUserDetails} userInfo={userDetails} />;
      // case "1":
      //   return <Staff />;
      case "1":
        return <PasswordChange />;
      case "2":
        return <NotificationSettings />;
      case "3":
        return <MobileNumber currentNumber={userDetails.phone_number} reUpdate={initialFetch} />;
      // case "3":
      //   return <Generals />;
      // case "4":
      //   return <Integrations />;
    }
  }
  function navSelectCA(id) {
    switch (id) {
      case "0":
        return (
          <CompanyProfile userInfo={userDetails} setUserInfo={setUserDetails} recall={handleRecallingFunction}/>
        );

      case "1":
        return <Staff />;
      case "2":
        return <PasswordChange />;
      case "4":
        return <MobileNumber currentNumber={userDetails.phone_number} reUpdate={initialFetch} />;
      case "3":
        return <NotificationSettings />;
      // case "3":
      //   return <Generals />;
      // case "4":
      //   return <Integrations />;
      default:
        return <></>;
    }
  }
  function navSelectCommon(id) {
    switch (id) {
      case "0":
        return (
          <CommonProfile userInfo={userDetails} setUserInfo={setUserDetails} />
        );

      // case "1":
      //   return <Staff />;

      //   case "4":
      //     return <MobileNumber currentNumber={userDetails.phone_number} reUpdate={initialFetch} />;
      case "2":
        return <NotificationSettings />;
      case "3":
        return <MobileNumber currentNumber={userDetails.phone_number} reUpdate={initialFetch} />;
      case "1":
        return <PasswordChange />;

      case "4":
        return <PartnerEarnings />

      // case "3":
      //   return <Generals />;
      // case "4":
      //   return <Integrations />;
      default:
        return <></>;
    }
  }
  function navSelectBVC(id) {
    switch (id) {
      // case "3":
      //   return <Integrations />;
      case "0":
        return <Staff />;
      case "1":
        return <PasswordChange />;
      case "2":
        return <MobileNumber currentNumber={userDetails.phone_number} reUpdate={initialFetch} />;
      case "3":
          return <NotificationSettings />;
      // case "2":
      //   return <Generals />;
    }
  }

  function navSelectBVS(id) {
    switch (id) {
      // case "3":
      //   return <Integrations />;
      // case "0":
      //   return <Staff />;
      case "0":
        return <PasswordChange />;
      case "1":
        return <MobileNumber currentNumber={userDetails.phone_number} reUpdate={initialFetch} />;
      case "2":
          return <NotificationSettings />;
      // case "2":
      //   return <Generals />;
    }
  }

  function navSelectTE(id) {
    switch (id) {
      // case "2":
      //   return <Integrations />;
      //   case "3":
      //     return <Staff />;
      case "0":
        return <CommonProfile setUserInfo={setUserDetails}
          userInfo={userDetails} />;
      case "1":
        return <PasswordChange />;
      case "2":
        return <NotificationSettings />;
      case "3":
        return <MobileNumber currentNumber={userDetails.phone_number} reUpdate={initialFetch} />;
      case "4":
        return <PartnerEarnings />
      // case "1":
      //   return <Generals />;
    }
  }
  function navSelectSP(id) {
    switch (id) {
      case "0":
        return (
          <ScreeningPartnerProfile
            setUserInfo={setUserDetails}
            userInfo={userDetails}
          />
        );
      case "2":
        return <NotificationSettings />;
      case "1":
        return <PasswordChange />;
      // case "2":
      //   return <Generals />;
      case "3":
        return <MobileNumber currentNumber={userDetails.phone_number} reUpdate={initialFetch} />;
      case "4":
        return <PartnerEarnings />

    }
  }
  function navSelectRC(id) {
    switch (id) {
      case "0":
        return <Staff />;
      case "1":
        return <PasswordChange />;
      // case "2":
      //   return <Generals />;
      case "2":
        return <MobileNumber currentNumber={userDetails.phone_number} reUpdate={initialFetch} />;
      
      case "3":
          return <NotificationSettings />;

    }
  }

  function navSelectRS(id) {
    switch (id) {
      // case "0":
      //   return <Staff />;
      case "0":
        return <PasswordChange />;
      // case "2":
      //   return <Generals />;
      case "1":
        return <MobileNumber currentNumber={userDetails.phone_number} reUpdate={initialFetch} />;
      
      case "2":
          return <NotificationSettings />;

    }
  }

  function NavItems(id, role) {
    switch (role) {
      case "RC":
        return navSelectRC(id);
      case RS:
        return navSelectRS(id);
      case "ZA":
        return navSelectZA(id);
      case "CA":
        return navSelectCA(id);
      case "SP":
        return navSelectSP(id);
      case "BVC":
        return navSelectBVC(id);
      case BVS:
          return navSelectBVS(id);

      case "TE":
        return navSelectTE(id);
      case "CS":
        return navSelectCommon(id);
      case "ACM":
        return navSelectCommon(id);
      case MODERATOR:
        return navSelectCommon(id);

      default:
        return <></>;
    }
  }
  const { profile_image, first_name, last_name, phone_number, email } =
    userDetails;

  if (loading) {
    return (
      <div
        style={{
          display: "grid",
          height: "100vh",
          width: "80vw",
          placeItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className="" style={{ width: "100%" }}>
      {model && <EditProfile open={model} toggleModel={toggleModel} user={userDetails} />}

      {model && <ZepulProfileEditModel isOpen={model === "ZA"} toggle={closeModel} />}

      <Container fluid>
        <Row>
          <Col xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              sx={{ padding: "10px" }}
              justifyContent="space-between"
            >
              <div className="d-flex align-items-center gap-2">
                <Avatar
                  variant="circular"
                  sx={{
                    bgcolor: grey["A200"],
                    height: 94,
                    width: 94,
                    color: "black",
                  }}
                  src={`${imageURL}${user?.image}`}
                  size="lg"
                >
                  {first_name?.charAt(0)}
                </Avatar>
                <Stack spacing={1}>
                  <PrimaryTypography variant="h4">
                    {first_name} {last_name}
                  </PrimaryTypography>

                  <p>{email}</p>
                  <p>{parseNumberInput(phone_number)}</p>
                </Stack>
              </div>
              <PrimaryOutlinedButton
                sx={{ maxWidth: "220px" }}
                onClick={toggleModel}
                startIcon={<FeatherIcon icon="edit" size="14" />}
              >
                Edit Profile
              </PrimaryOutlinedButton>
            </Stack>
            <Tabs
              sx={{ borderBottom: "0.5px solid rgba(0,0,0,0.1)" }}
              value={selectedNav}
              onChange={(_, id) => handleNavChange(id)}
              textColor="primary"
              TabIndicatorProps={StyledTabsObject}
              TabScrollButtonProps={StyledTabScrollButtonObject}
              indicatorColor="primary"
              aria-label="secondary tabs example"
            >
              {userNav.map(({ icon, name }, index) => {
                return (
                  <PrimaryTab
                    disableRipple={true}
                    sx={{ fontWeight: "500", height: "10px" }}
                    value={index?.toString()}
                    icon={<FeatherIcon icon={icon} size="14" />}
                    iconPosition="start"
                    key={index}
                    label={name}
                  />
                );
              })}
            </Tabs>
            {NavItems(selectedNav, user.role)}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
