import React, { Component, lazy, useEffect, useState } from "react";
import titleCaller from "components/Common/title";
import "../../assets/css/auth/forget_password.css";
import { FormControl, Stack, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import ForgetPasswordImage from "../../assets/images/zepul/forget_password.svg";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { forgetPassword } from "helpers/services/auth";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { toast } from "react-hot-toast";
import {
  PrimaryButton,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
} from "assets/Mui/globalTheme";
export default function ForgetPasswordPage() {
  const [email, setEmail] = useState("");
  useEffect(() => {
    titleCaller("Joinee");
  }, []);
  // await forgetPassword(this.state.email);
  const {
    register,
    handleSubmit,
    watch,
    unregister,
    formState: { errors },
    setValue,
  } = useForm();
  async function handleData(data) {
    const { status, data: response } = await forgetPassword(data?.email);
    if (status === 200) {
      toast.success("password reset link is sent to your email");
    } else {
      typeof response === "string"
        ? toast.error(response)
        : toast.success(response?.data);
    }
  }
  return (
    <React.Fragment>
      <div className="forget_password_container">
        <Form
          className="forget_password_elem_container"
          onSubmit={handleSubmit(handleData)}
        >
          <img
            src={ForgetPasswordImage}
            alt="forget_password"
            className="forget_password_image"
          />
          <Typography variant="h1" component="h1">
            Forgot your password?
          </Typography>
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="login-last-name-outlined"
              error={errors.email}
            > 
              Email
            </PrimaryNormalInputLabel>
            <PrimaryNormalInputs
              autoFocus
              {...register("email", { required: true })}
              label="login-last-name-outlined"
              error={errors?.email}
              variant="outlined"
              id="login-last-name-outlined"
            />
            <PrimaryNormalFormHelperText error={errors.email}>
              {errors.email ? "Email is a required Field" : ""}
            </PrimaryNormalFormHelperText>
          </FormControl>
          <Typography sx={{color:"#066a4c"}} textAlign={"center"} level="h6">
            Please enter the email address associated with your account and We
            will email you a link to reset your password.
          </Typography>
          <PrimaryButton type="submit">Send Request</PrimaryButton>
          <Stack direction="row" alignItems="center">
            <FeatherIcon  icon="chevron-left" size="14" />
            <Link className="link_navigation" to="/login">
              Return to Sign In
            </Link>
          </Stack>
        </Form>
      </div>
    </React.Fragment>
  );
}
