import { ADMIN } from "common/constants/common-words";
import { ACM, CS, MODERATOR } from "common/constants/userModules";
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import TryOutlinedIcon from '@mui/icons-material/TryOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined';
import InterpreterModeOutlinedIcon from '@mui/icons-material/InterpreterModeOutlined';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import ShiyftCategories from "../../assets/images/icons/shiyft icons_category.svg"
import ShiyftRole from "../../assets/images/icons/shiyft icons_job role.svg"
import ShiyftLevel from "../../assets/images/icons/shiyft icons_job level.svg"
import ShiyftIndustries from "../../assets/images/icons/shiyft icons_intustries.svg"
import ShiyftSkills from "../../assets/images/icons/shiyft icons_key skills.svg"
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

export function contentFinder(role) {
  switch (role) {
    case "SP":
      return [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: "bx bx-home-circle",
        },
        {
          name: "Companies",
          link: "/company-list",
          icon: "bx bx-buildings",
        },
        // {
        //   name: "Profile", link: "/profile-settings?tab=0", icon: (
        //   <FeatherIcon icon={"user"} />
        // ) },
        {
          name: "Jobs",
          link: "/joblist/approved",
          icon: "bx bxs-briefcase",
        },
        {
          name: "Screened Applicants",
          link: "/screened-applicants",
          icon: "bx bx-select-multiple",
        },
       
        // { name: "chat", link: "/chat", icon: "bx bx-chat" },

        // { name: "Earnings", link: "/earnings", icon: "bx bx-dollar" },
        // { name: "calendar", link: "/calendar", icon: "bx bx-calendar-alt" },
        // {
        //   name: "Leader Board",
        //   link: "/leaderboard",
        //   icon: "bx bx-bar-chart",
        // },
        { name: "profile", link: "/profile-settings", icon: "bx bx-user" },
        { name: "logout", link: "/logout", icon: "bx bx-log-out" },
      ];
    case "RC":
      return [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: "bx bx-home-circle",
        },
        {
          name: "Companies",
          link: "/company-list",
          icon: "bx bx-buildings",
        },
        {
          name: "Jobs",
          link: "/joblist/approved",
          icon: "bx bxs-briefcase",
        },
        // {
        //   name: "Screened Applicants",
        //   link: "/screened-applicants",
        //   icon: "bx bx-select-multiple",
        // },
      
        // { name: "Earnings", link: "/earnings", icon: "bx bx-dollar" },
        // { name: "chat", link: "/chat", icon: "bx bx-chat" },
        // {
        //   name: "Leader Board",
        //   link: "/leaderboard",
        //   icon: "mdi mdi-lightbulb-on",
        //   icon:"bx bx-bar-chart"
        // },
        // { name: "email", link: "/emails", icon: "bx bx-envelope" },
        { name: "settings", link: "/profile-settings", icon: "bx bx-cog" },
      ];
    case "RS":
      return [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: "bx bx-home-circle",
        },
        {
          name: "Companies",
          link: "/company-list",
          icon: "bx bx-buildings",
        },
        {
          name: "Jobs",
          link: "/joblist/approved",
          icon: "bx bxs-briefcase",
        },
        // {
        //   name: "Screened Applicants",
        //   link: "/screened-applicants",
        //   icon: "bx bx-select-multiple",
        // },
      
        // { name: "Earnings", link: "/earnings", icon: "bx bx-dollar" },
        // { name: "chat", link: "/chat", icon: "bx bx-chat" },
        // {
        //   name: "Leader Board",
        //   link: "/leaderboard",
        //   icon: "mdi mdi-lightbulb-on",
        //   icon:"bx bx-bar-chart"
        // },
        // { name: "email", link: "/emails", icon: "bx bx-envelope" },
        { name: "settings", link: "/profile-settings", icon: "bx bx-cog" },
      ];
    case "TE":
      return [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: "bx bx-home-circle",
        },
        {
          name: "Companies",
          link: "/company-list",
          icon: "bx bx-buildings",
        },
        {
          name: "Profile", link: "/profile-settings?tab=0", icon: (
          <FeatherIcon icon={"user"} />
        ) },
        {
          name: "Jobs",
          link: "/joblist/approved",
          icon: "bx bxs-briefcase",
        },
        {
          name: "Evaluated",
          link: "/screened-applicants",
          icon: "bx bx-select-multiple",
        },
       
        // { name: "Earnings", link: "/earnings", icon: "bx bx-dollar" },
        // { name: "chat", link: "/chat", icon: "bx bx-chat" },
        // {
        //   name: "Leader Board",
        //   link: "/leaderboard",
        //   icon: "bx bx-bar-chart",
        // },
        // { name: "email", link: "/emails", icon: "bx bx-envelope" },
        { name: "settings", link: "/profile-settings?tab=2", icon: "bx bx-cog" },
      ];
    case ACM:
      return [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: "bx bx-home-circle",
        },
        { name: "companies", link: "/company-list", icon: "bx bx-building" },
        {
          name: "Jobs",
          link: "/joblist/approved",
          icon: "bx bxs-briefcase",
        },
        // { name: "calendar", link: "/calendar", icon: "bx bx-calendar-alt" },
        // { name: "chat", link: "/chat", icon: "bx bx-chat" },
        { name: "profile", link: "/profile-settings", icon: "bx bx-user" },
        { name: "logout", link: "/logout", icon: "bx bx-log-out" },
      ];
    case MODERATOR:
      return [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: "bx bx-home-circle",
        },
        {
          name: "Companies",
          link: "/company-list",
          icon: "bx bx-buildings",
        },
        {
          name: "Jobs",
          link: "/joblist/approved",
          icon: "bx bxs-briefcase",
        },
        {
          name: "Moderated Applicants",
          // link: "/moderated-applicants",
          link: "/screened-applicants",
          icon: "bx bx-select-multiple",
        },
        // { name: "calendar", link: "/calendar", icon: "bx bx-calendar-alt" },
        // { name: "chat", link: "/chat", icon: "bx bx-chat" },
        // {
        //   name: "Leader Board",
        //   link: "/leaderboard",
        //   icon: "bx bx-bar-chart",
        // },
        { name: "profile", link: "/profile-settings", icon: "bx bx-user" },
        { name: "logout", link: "/logout", icon: "bx bx-log-out" },
      ];
    case "BVC":
      return [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: "bx bx-home-circle",
        },
        {
          name: "Companies",
          link: "/company-list",
          icon: "bx bx-buildings",
        },
        {
          name: "Jobs",
          link: "/joblist/approved",
          icon: "bx bxs-briefcase",
        },
        {
          name: "Verified Applicants",
          link: "/background-verified-applicants",
          icon: "bx bx-select-multiple",
        },
        // {
        //   name: "Staff",
        //   link: "/profile-settings",
        //   icon: "bx bx-select-multiple",
        // },
      
        // { name: "chat", link: "/chat", icon: "bx bx-chat" },

        // { name: "Earnings", link: "/earnings", icon: "bx bx-dollar" },
        // { name: "calendar", link: "/calendar", icon: "bx bx-calendar-alt" },
        // {
        //   name: "Leader Board",
        //   link: "/leaderboard",
        //   icon: "bx bx-bar-chart",
        // },
       
        { name: "profile", link: "/profile-settings", icon: "bx bx-user" },
        { name: "logout", link: "/logout", icon: "bx bx-log-out" },
      ];
    case "BVS":
      return [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: "bx bx-home-circle",
        },
        {
          name: "Companies",
          link: "/company-list",
          icon: "bx bx-buildings",
        },
        {
          name: "Jobs",
          link: "/joblist/approved",
          icon: "bx bxs-briefcase",
        },
        {
          name: "Verified Applicants",
          link: "/background-verified-applicants",
          icon: "bx bx-select-multiple",
        },
      
        // { name: "chat", link: "/chat", icon: "bx bx-chat" },

        // { name: "Earnings", link: "/earnings", icon: "bx bx-dollar" },
        // { name: "calendar", link: "/calendar", icon: "bx bx-calendar-alt" },
        // {
        //   name: "Leader Board",
        //   link: "/leaderboard",
        //   icon: "bx bx-bar-chart",
        // },
        { name: "profile", link: "/profile-settings", icon: "bx bx-user" },
        { name: "logout", link: "/logout", icon: "bx bx-log-out" },
      ];
    case "TE":
      return [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: "bx bx-home-circle",
        },
        {
          name: "Companies",
          link: "/company-list",
          icon: "bx bx-buildings",
        },
        {
          name: "Profile", link: "/profile-settings?tab=0", icon: (
          <FeatherIcon icon={"user"} />
        ) },
        {
          name: "Jobs",
          link: "/job-openings",
          icon: "bx bxs-briefcase",
        },
        {
          name: "Evaluated-applicants",
          link: "/evaluated-applicants",
          icon: "bx bx-select-multiple",
        },
     
        // { name: "chat", link: "/chat", icon: "bx bx-chat" },
      
        { name: "Earnings", link: "/earnings", icon: "bx bx-dollar" },
        { name: "calendar", link: "/calendar", icon: "bx bx-calendar-alt" },
        {
          name: "Leader Board",
          link: "/leaderboard",
          icon: "bx bx-bar-chart",
        },
        { name: "profile", link: "/profile-settings", icon: "bx bx-cog" },
        { name: "logout", link: "/logout", icon: "bx bx-log-out" },
      ];
    case CS:
      return [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: "bx bx-home-circle",
        },
        {
          name: "Profile", link: "/profile-settings?tab=0", icon: (
          <FeatherIcon icon={"user"} />
        ) },
        {
          name: "Jobs",
          link: "/joblist/approved",
          icon: "bx bx-briefcase",
        },
        { name: "Post a Job", link: "/post-job", icon: "bx bx-cloud-upload" },
        // { name: "Applicants", link: "/referal-partner-list", icon: "bx bx-phone" },

        {
          name: "Partners",
          logo: (
            <HandshakeOutlinedIcon
              style={{ color: "white", fontSize: "20px" }}
            />
          ),
          isMulti: true,
          clicked: false,
          items: [
            // {
            //   name: "Applicantsr",
            //   link: "/referal-partner-list",
            //   // icon: "bx bx-phone",
            //   logo: (
            //     <PersonAddAltIcon
            //       style={{ color: "white", fontSize: "20px" }}
            //     />
            //   ),
             
            // },
            // {
            //   name: "Screening Partners",
            //   link: "/screening-partner-list",
            //   // icon: "bx bx-help-circle",
            //   logo: (
            //     <ImageSearchOutlinedIcon
            //       style={{ color: "white", fontSize: "20px" }}
            //     />
            //   ),
            // },
            // {
            //   name: "Techincal Evaluators",
            //   link: "/technical-evaluator-list",
            //   // icon: "bx bx-help-circle",
            //   logo: (
            //     <InterpreterModeOutlinedIcon
            //       style={{ color: "white", fontSize: "20px" }}
            //     />
            //   ),
            // },
            // {
            //   name: "Moderators",
            //   link: "/moderator-list",
            //   // icon: "bx bx-git-compare",
            //   logo: (
            //     <FeatherIcon size={20} icon="file" />
            //   )
            // },
            // {
            //   name: "Recruitment Company",
            //   link: "/recruiting-company-list",
            //   icon: "bx bx-buildings",
            // },
            // {
            //   name: "BGV Partners",
            //   link: "/background-evaluators-list",
            //   icon: "bx bx-git-compare",
            // },
            {
              name: "Account Managers",
              link: "/account-manager-list",
              // icon: "bx bx-git-compare",
              logo: (
                <FeatherIcon size={20} icon="folder" />
              )
            },
           
          ],
        },

        // { name: "calendar", link: "/calendar", icon: "bx bx-calendar-alt" },
        // { name: "chat", link: "/chat", icon: "bx bx-chat" },
        // { name: "company", link: "/company", icon: "bx bx-building" },
        // { name: "profile", link: "/profile-settings", icon: "bx bx-user" },
        {
          name: "logout",
          link: "/logout",
          icon: "bx bx-log-out",
        },
      ];
    case "CA":
      return [
        { name: "Dashboard", link: "/dashboard", icon: "bx bxs-briefcase" },
        {
          name: "Jobs",
          link: "/joblist/approved",
          icon: "bx bx-briefcase",
        },
        { name: "Post a Job", link: "/post-job", icon: "bx bx-cloud-upload" },
        // { name: "Applicants", link: "/referal-partner-list", icon: "bx bx-phone" },
        { name: "Profile", link: "/profile-settings?tab=0", icon: "bx bx-user" },

        {
          name: "Partners",
          logo: (
            <HandshakeOutlinedIcon
              style={{ color: "white", fontSize: "20px" }}
            />
          ),
          isMulti: true,
          clicked: false,
          items: [
            // {
            //   name: "Applicants",
            //   link: "/referal-partner-list",
            //   // icon: "bx bx-phone",
            //   logo: (
            //     <PersonAddAltIcon
            //       style={{ color: "white", fontSize: "20px" }}
            //     />
            //   ),
            // },
            // {
            //   name: "Screening Partners",
            //   link: "/screening-partner-list",
            //   // icon: "bx bx-help-circle",
            //   logo: (
            //     <ImageSearchOutlinedIcon
            //       style={{ color: "white", fontSize: "20px" }}
            //     />
            //   ),
            // },
            // {
            //   name: "Techincal Evaluators",
            //   link: "/technical-evaluator-list",
            //   // icon: "bx bx-help-circle",
            //   logo: (
            //     <InterpreterModeOutlinedIcon
            //       style={{ color: "white", fontSize: "20px" }}
            //     />
            //   ),
            // },
            // {
            //   name: "Moderators",
            //   link: "/moderator-list",
            //   // icon: "bx bx-git-compare",
            //   logo: (
            //     <FeatherIcon size={20} icon="file" />
            //   )
            // },
            // {
            //   name: "Recruitment Company",
            //   link: "/recruiting-company-list",
            //   icon: "bx bx-buildings",
            // },
            // {
            //   name: "BGV Partners",
            //   link: "/background-evaluators-list",
            //   icon: "bx bx-git-compare",
            // },
            {
              name: "Account Managers",
              link: "/account-manager-list",
              // icon: "bx bx-git-compare",
              logo: (
                <FeatherIcon size={20} icon="folder" />
              )
            },
          
          ],
        },

        // { name: "calendar", link: "/calendar", icon: "bx bx-calendar-alt" },
        // { name: "chat", link: "/chat", icon: "bx bx-chat" },
        // {
        //   name: "Integrations",
        //   link: "/profile-settings?tab=1",
        //   icon: "bx bxl-zoom",
        // },
        // {
        //   name: "payments",
        //   link: "/payments",
        //   icon: "bx bx-dollar-circle",
        // },
        {
          name: "logout",
          link: "/logout",
          icon: "bx bx-log-out",
        },
      ];
    case ADMIN:
      return [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: "bx bx-home-circle",
        },
        {
          name: "Companies",
          link: "/company-list",
          icon: "bx bx-buildings",
        },
        {
          name: "Profile", link: "/profile-settings?tab=0", icon: (
          <FeatherIcon icon={"user"} />
        ) },
        {
          name: "Partners",
          logo: (
            <HandshakeOutlinedIcon
              style={{ color: "white", fontSize: "20px" }}
            />
          ),
          isMulti: true,
          clicked: false,
          items: [
            {
              name: "Referal Partner",
              link: "/referal-partner-list",
              icon: "bx bx-phone",
            },
            {
              name: "Screening Partners",
              link: "/screening-partner-list",
              // icon: "bx bx-help-circle",
              logo: (
                <ImageSearchOutlinedIcon
                  style={{ color: "white", fontSize: "20px" }}
                />
              ),
            },
            {
              name: "Techincal Evaluators",
              link: "/technical-evaluator-list",
              // icon: "bx bx-help-circle",
              logo: (
                <InterpreterModeOutlinedIcon
                  style={{ color: "white", fontSize: "20px" }}
                />
              ),
            },
            {
              name: "Moderators",
              link: "/moderator-list",
              // icon: "bx bx-git-compare",
              logo: (
                <FeatherIcon size={20} icon="file" />
              )
            },
            {
              name: "Recruitment Company",
              link: "/recruiting-company-list",
              icon: "bx bx-buildings",
            },
            {
              name: "BGV Partners",
              link: "/background-evaluators-list",
              icon: "bx bx-git-compare",
            },
            {
              name: "Account Managers",
              link: "/account-manager-list",
              // icon: "bx bx-git-compare",
              logo: (
                <FeatherIcon size={20} icon="folder" />
              )
            },
           
          ],
        },

        // {
        //   name: "Referal Partner",
        //   link: "/referal-partner-list",
        //   icon: "bx bx-phone",
        // },
        // {
        //   name: "Screening Partners",
        //   link: "/screening-partner-list",
        //   icon: "bx bx-help-circle",
        // },

        // {
        //   name: "Account Managers",
        //   link: "/account-manager-list",
        //   icon: "bx bx-help-circle",
        // },
        // {
        //   name: "Techincal Evaluators",
        //   link: "/technical-evaluator-list",
        //   icon: "bx bx-help-circle",
        // },
        // {
        //   name: "BGV Partners",
        //   link: "/background-evaluators-list",
        //   icon: "bx bx-git-compare",
        // },

        // {
        //   name: "Evaluation Forms",
        //   link: "/evaluation-forms",
        //   icon: "bx bx-git-compare",
        // },
        // {
        //   name: "Commissions",
        //   link: "/commissions",
        //   icon: "bx bx-git-compare",
        // },

        {
          name: "Jobs",
          link: "/joblist/approved",
          icon: "bx bxs-briefcase",
        },
        {
          name: "Job Attributes",
          logo: (
            <TryOutlinedIcon style={{ color: "white", fontSize: "20px" }} />
          ),
          isMulti: true,
          clicked: false,
          items: [
            {
              name: "Industries",
              img: (
                <svg
                  id="Layer_1"
                  className="size_color"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 500 500"
                >
                  <g id="_OUTLINE" data-name=" OUTLINE">
                    <path
                      fill="#024430"
                      d="m218.92,312.16l8.63,3.58,5.34,22.26h33.65l5.34-22.26,8.63-3.58,19.58,12,23.79-23.79-12-19.58,3.58-8.63,22.26-5.34v-33.65l-22.26-5.34-3.58-8.63,12-19.58-23.79-23.79-19.58,12-8.63-3.58-5.34-22.26h-33.65l-5.34,22.26-8.63,3.58-19.58-12-23.79,23.79,12,19.58-3.58,8.63-22.26,5.34v33.65l22.26,5.34,3.58,8.63-12,19.58,23.79,23.79,19.58-12Zm-23.29-53.66l-17.92-4.3v-8.41l17.92-4.3,9.82-23.7-9.67-15.78,5.95-5.95,15.78,9.67,23.7-9.82,4.3-17.92h8.41l4.3,17.92,23.7,9.82,15.78-9.67,5.95,5.95-9.67,15.78,9.82,23.7,17.92,4.3v8.41l-17.92,4.3-9.82,23.7,9.67,15.78-5.95,5.95-15.78-9.67-23.7,9.82-4.3,17.92h-8.41l-4.3-17.92-23.7-9.82-15.78,9.67-5.95-5.95,9.67-15.78-9.82-23.7Z"
                    />
                    <path
                      fill="#024430"
                      d="m249.71,282c17.67,0,32-14.33,32-32s-14.33-32-32-32-32,14.33-32,32c.02,17.66,14.34,31.98,32,32Zm0-48c8.84,0,16,7.16,16,16s-7.16,16-16,16-16-7.16-16-16c.01-8.83,7.17-15.99,16-16Z"
                    />
                    <path
                      fill="#024430"
                      d="m441.71,341.36l-64-36.57-64,36.57v92.64h128v-92.64Zm-48,44.64h-32v-16h32v16Zm-32,16h32v16h-32v-16Zm64,16h-16v-64h-64v64h-16v-67.36l48-27.43,48,27.43v67.36Z"
                    />
                    <path
                      fill="#024430"
                      d="m121.71,234h24v-16h-24c-53.02,0-96-42.98-96-96S68.69,26,121.71,26s96,42.98,96,96v24h16v-24c0-61.86-50.14-112-112-112S9.71,60.14,9.71,122s50.14,112,112,112Z"
                    />
                    <path
                      fill="#024430"
                      d="m281.71,122c0-53.02,42.98-96,96-96s96,42.98,96,96-42.98,96-96,96h-24v16h24c61.86,0,112-50.14,112-112S439.57,10,377.71,10s-112,50.14-112,112v24h16v-24Z"
                    />
                    <path
                      fill="#024430"
                      d="m217.71,378c0,53.02-42.98,96-96,96s-96-42.98-96-96,42.98-96,96-96h24v-16h-24c-61.86,0-112,50.14-112,112s50.14,112,112,112,112-50.14,112-112v-24h-16v24Z"
                    />
                    <path
                      fill="#024430"
                      d="m377.71,266h-24v16h24c53.02,0,96,42.98,96,96s-42.98,96-96,96-96-42.98-96-96v-24h-16v24c0,61.86,50.14,112,112,112s112-50.14,112-112-50.14-112-112-112Z"
                    />
                    <path
                      fill="#024430"
                      d="m65.71,394v32c.02,13.25,10.75,23.98,24,24h64c13.25-.02,23.98-10.75,24-24v-32c-.02-7.55-3.57-14.66-9.6-19.2l-20.99-15.74,4.95-14.83c1.09-3.26,1.64-6.68,1.64-10.12v-4.11c0-17.67-14.33-32-32-32s-32,14.33-32,32v4.11c0,3.44.55,6.86,1.64,10.12l4.95,14.83-20.99,15.74c-6.03,4.54-9.58,11.65-9.6,19.2Zm16,0c0-2.52,1.19-4.88,3.2-6.4l30.21-22.66-8.59-25.77c-.54-1.63-.82-3.34-.82-5.06v-4.11c0-8.84,7.16-16,16-16s16,7.16,16,16v4.11c0,1.72-.28,3.43-.82,5.06l-8.59,25.78,30.21,22.66c2.01,1.51,3.19,3.88,3.2,6.4v32c0,4.42-3.58,7.99-8,8h-64c-4.42,0-7.99-3.58-8-8v-32Z"
                    />
                    <path
                      fill="#024430"
                      d="m441.71,154v-64c-.02-13.25-10.75-23.98-24-24h-80c-13.25.02-23.98,10.75-24,24v64c.02,13.25,10.75,23.98,24,24h80c13.25-.02,23.98-10.75,24-24Zm-80-72h16v32h-16v-32Zm-32,72v-64c0-4.42,3.58-7.99,8-8h8v48h48v-48h24c4.42,0,7.99,3.58,8,8v64c0,4.42-3.58,7.99-8,8h-80c-4.42,0-7.99-3.58-8-8Z"
                    />
                    <path
                      fill="#024430"
                      d="m89.71,50v34.23l-24,8v61.77h-8v16h128v-16h-8v-83.1l-16,5.33v-26.23h-16v31.57l-16,5.33v-16l-24,8v-28.9h-16Zm72,43.1v60.9h-32v-50.23l32-10.67Zm-48,60.9h-32v-50.23l32-10.67v60.9Z"
                    />
                  </g>
                </svg>
              ),
              link: "/job-attributes/industries",
            },
            {
              name: "Categories",
              img: (
                <svg
                  className="size_color"
                  id="Layer_1"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 500 500"
                >
                  <path
                    fill="#024430"
                    d="m217.58,30.49H45.71c-5.97,0-10.8,4.83-10.8,10.8v171.87c0,5.97,4.83,10.8,10.8,10.8h171.87c5.97,0,10.8-4.83,10.8-10.8V41.29c0-5.97-4.83-10.8-10.8-10.8Zm-10.8,171.87H56.5V52.08h150.28v150.28Z"
                  />
                  <path
                    fill="#024430"
                    d="m454.29,30.49h-171.87c-5.97,0-10.8,4.83-10.8,10.8v171.87c0,5.97,4.83,10.8,10.8,10.8h171.87c5.97,0,10.8-4.83,10.8-10.8V41.29c0-5.97-4.83-10.8-10.8-10.8Zm-10.8,171.87h-150.28V52.08h150.28v150.28Z"
                  />
                  <path
                    fill="#024430"
                    d="m217.58,276.05H45.71c-5.97,0-10.8,4.83-10.8,10.8v171.87c0,5.97,4.83,10.8,10.8,10.8h171.87c5.97,0,10.8-4.83,10.8-10.8v-171.87c0-5.97-4.83-10.8-10.8-10.8Zm-10.8,171.87H56.5v-150.28h150.28v150.28Z"
                  />
                  <path
                    fill="#024430"
                    d="m368.35,276.05c-53.34,0-96.73,43.4-96.73,96.73s43.39,96.73,96.73,96.73,96.73-43.39,96.73-96.73-43.4-96.73-96.73-96.73h0Zm0,171.87c-41.43,0-75.14-33.71-75.14-75.14s33.71-75.14,75.14-75.14,75.14,33.71,75.14,75.14-33.71,75.14-75.14,75.14h0Z"
                  />
                </svg>
              ),
              link: "/job-attributes/categories",
            },
            {
              name: "Job Role",
              img: (
                <svg
                  id="Layer_1"
                  className="size_color"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 500 500"
                >
                  <path
                    fill="#024430"
                    d="m159.2,397.83c2.33,4.74,2.85,44.74,38.49,44.74h104.62c35.79,0,36.06-39.83,38.48-44.74,8.17-34.75-15.75-68.52-50.83-73.01-26.51-3.39-53.41-3.39-79.92,0-35.18,4.5-58.97,38.37-50.83,73.01h0Zm52.61-59.11c25.25-3.25,51.08-3.25,76.36,0,26.47,3.38,45.34,28.86,38.97,55.9-3.01,6.85-1.79,33.94-24.84,33.94h-104.62c-23.04,0-21.84-27.11-24.85-33.94-6.41-27.18,12.66-52.53,38.97-55.9h0Z"
                  />
                  <path
                    fill="#024430"
                    d="m197.35,254.29c0,29.01,23.61,52.62,52.62,52.62s52.68-23.61,52.68-52.62-23.64-52.68-52.68-52.68-52.62,23.64-52.62,52.68Zm91.29,0c0,21.29-17.35,38.61-38.67,38.61s-38.61-17.32-38.61-38.61,17.32-38.67,38.61-38.67c21.32,0,38.67,17.35,38.67,38.67Z"
                  />
                  <path
                    fill="#024430"
                    d="m440.05,365.88c-16.27-2.08-32.75-2.08-49.01,0-22.97,2.95-38.36,25.06-33.07,47.51,1.59,3.16,1.59,29.18,25.95,29.18h63.24c23.66,0,24.96-27.2,25.95-29.18,5.3-22.51-10.15-44.58-33.07-47.52h0Zm19.43,44.3h0c-1.99,4.81-.97,18.38-12.31,18.38h-63.24c-11.38,0-10.43-13.84-12.31-18.38-3.48-14.74,6.84-28.56,21.21-30.4,14.84-1.9,30.07-1.97,45.45,0,14.37,1.84,24.69,15.67,21.21,30.4h0Z"
                  />
                  <path
                    fill="#024430"
                    d="m415.53,344.58c19.09,0,34.61-15.51,34.61-34.57s-15.52-34.61-34.61-34.61-34.58,15.53-34.58,34.61,15.52,34.57,34.58,34.57h0Zm0-55.18c11.36,0,20.6,9.24,20.6,20.6s-9.24,20.56-20.6,20.56-20.57-9.22-20.57-20.56c0-11.36,9.23-20.6,20.57-20.6Z"
                  />
                  <path
                    fill="#024430"
                    d="m26.88,413.39c1.62,3.22,1.59,29.18,25.95,29.18h63.24c23.66,0,24.96-27.2,25.96-29.18h0c5.28-22.45-10.11-44.57-33.08-47.52-16.25-2.08-32.74-2.08-49.01,0-22.86,2.93-38.37,24.93-33.07,47.52Zm34.85-33.62c15.04-1.92,30.4-1.93,45.45,0,14.38,1.84,24.68,15.67,21.21,30.4-1.97,4.76-1,18.38-12.32,18.38h-63.24c-11.35,0-10.41-13.78-12.31-18.37-3.47-14.76,6.84-28.57,21.21-30.41Z"
                  />
                  <path
                    fill="#024430"
                    d="m84.44,344.58c19.09,0,34.61-15.51,34.61-34.57s-15.52-34.61-34.61-34.61-34.58,15.53-34.58,34.61,15.52,34.57,34.58,34.57h0Zm0-55.18c11.36,0,20.6,9.24,20.6,20.6s-9.24,20.56-20.6,20.56-20.57-9.22-20.57-20.56,9.23-20.6,20.57-20.6Z"
                  />
                  <g>
                    <path
                      fill="#024430"
                      d="m242.89,77.58c-17.45,0-31.65,14.2-31.65,31.65s14.2,31.65,31.65,31.65,31.65-14.2,31.65-31.65-14.2-31.65-31.65-31.65Zm0,56.2c-13.53,0-24.55-11.01-24.55-24.55s11.01-24.55,24.55-24.55,24.55,11.01,24.55,24.55-11.01,24.55-24.55,24.55Z"
                    />
                    <path
                      fill="#024430"
                      d="m285.86,133.87c2.11-3.67,3.74-7.6,4.84-11.67h7.66c3.84,0,6.96-3.12,6.96-6.96v-12.01c0-3.84-3.12-6.96-6.96-6.96h-7.66c-1.1-4.08-2.73-8-4.84-11.67l5.42-5.42c2.71-2.71,2.71-7.13,0-9.84l-8.49-8.49c-1.31-1.31-3.06-2.04-4.92-2.04s-3.61.72-4.92,2.04l-5.42,5.42c-3.67-2.11-7.6-3.74-11.67-4.84v-7.66c0-3.84-3.12-6.96-6.96-6.96h-12.01c-3.84,0-6.96,3.12-6.96,6.96v7.66c-4.07,1.1-8,2.73-11.67,4.84l-5.42-5.42c-2.71-2.71-7.13-2.71-9.84,0l-8.49,8.49c-2.71,2.71-2.71,7.13,0,9.84l5.42,5.42c-2.11,3.67-3.74,7.6-4.84,11.67h-7.66c-3.84,0-6.96,3.12-6.96,6.96v12.01c0,3.84,3.12,6.96,6.96,6.96h7.66c1.1,4.08,2.73,8,4.84,11.67l-5.42,5.42c-2.71,2.71-2.71,7.13,0,9.84l8.49,8.49c2.71,2.71,7.13,2.71,9.84,0l5.42-5.42c3.67,2.11,7.6,3.74,11.67,4.84v7.66c0,3.84,3.12,6.96,6.96,6.96h12.01c3.84,0,6.96-3.12,6.96-6.96v-7.66c4.07-1.1,8-2.73,11.67-4.84l5.42,5.42c1.31,1.31,3.06,2.04,4.92,2.04s3.61-.72,4.92-2.04l8.49-8.49c2.71-2.71,2.71-7.13,0-9.84l-5.42-5.42Zm-7.99,18.62l-5.4-5.4c-2.22-2.22-5.68-2.67-8.42-1.1-3.19,1.84-6.59,3.25-10.13,4.2-3.05.82-5.18,3.58-5.18,6.72v7.64h-11.71v-7.64c0-3.14-2.13-5.9-5.18-6.72-3.54-.95-6.95-2.36-10.13-4.2-2.73-1.58-6.19-1.13-8.42,1.1l-5.4,5.4-8.28-8.28,5.4-5.4c2.22-2.22,2.67-5.68,1.1-8.42-1.84-3.18-3.25-6.59-4.2-10.13-.82-3.05-3.58-5.17-6.72-5.17h-7.63v-11.71h7.64c3.14,0,5.9-2.13,6.72-5.18.95-3.54,2.36-6.95,4.2-10.13,1.58-2.73,1.13-6.19-1.1-8.42l-5.4-5.4,8.28-8.28,5.4,5.4c2.22,2.22,5.68,2.67,8.41,1.1,3.19-1.84,6.6-3.25,10.13-4.2,3.05-.82,5.18-3.58,5.18-6.72v-7.64h11.71v7.64c0,3.14,2.13,5.9,5.18,6.72,3.54.95,6.95,2.36,10.13,4.2,2.73,1.58,6.19,1.13,8.42-1.1l5.4-5.4,8.28,8.28-5.4,5.4c-2.22,2.22-2.67,5.68-1.1,8.42,1.84,3.18,3.25,6.59,4.2,10.13.82,3.05,3.58,5.18,6.72,5.18h7.64v11.71h-7.64c-3.14,0-5.9,2.13-6.72,5.18-.95,3.54-2.36,6.95-4.2,10.13-1.58,2.73-1.13,6.19,1.1,8.41l5.4,5.4-8.28,8.28Z"
                    />
                  </g>
                </svg>
              ),
              link: "/job-attributes/job-role",
            },
            {
              name: "Experience level",
              img: (
                <svg
                  id="Layer_1"
                  className="size_color"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 500 500"
                >
                  <g id="_19_Value_Stocka" data-name="19 Value Stocka">
                    <g>
                      <path
                        fill="#024430"
                        d="m159.2,397.83c2.33,4.74,2.85,44.74,38.49,44.74h104.62c35.79,0,36.06-39.83,38.48-44.74,8.17-34.75-15.75-68.52-50.83-73.01-26.51-3.39-53.41-3.39-79.92,0-35.18,4.5-58.97,38.37-50.83,73.01h0Zm52.61-59.11c25.25-3.25,51.08-3.25,76.36,0,26.47,3.38,45.34,28.86,38.97,55.9-3.01,6.85-1.79,33.94-24.84,33.94h-104.62c-23.04,0-21.84-27.11-24.85-33.94-6.41-27.18,12.66-52.53,38.97-55.9h0Z"
                      />
                      <path
                        fill="#024430"
                        d="m197.35,254.29c0,29.01,23.61,52.62,52.62,52.62s52.68-23.61,52.68-52.62-23.64-52.68-52.68-52.68-52.62,23.64-52.62,52.68Zm91.29,0c0,21.29-17.35,38.61-38.67,38.61s-38.61-17.32-38.61-38.61,17.32-38.67,38.61-38.67c21.32,0,38.67,17.35,38.67,38.67Z"
                      />
                      <path
                        fill="#024430"
                        d="m440.05,365.88c-16.27-2.08-32.75-2.08-49.01,0-22.97,2.95-38.36,25.06-33.07,47.51,1.59,3.16,1.59,29.18,25.95,29.18h63.24c23.66,0,24.96-27.2,25.95-29.18,5.3-22.51-10.15-44.58-33.07-47.52h0Zm19.43,44.3h0c-1.99,4.81-.97,18.38-12.31,18.38h-63.24c-11.38,0-10.43-13.84-12.31-18.38-3.48-14.74,6.84-28.56,21.21-30.4,14.84-1.9,30.07-1.97,45.45,0,14.37,1.84,24.69,15.67,21.21,30.4h0Z"
                      />
                      <path
                        fill="#024430"
                        d="m415.53,344.58c19.09,0,34.61-15.51,34.61-34.57s-15.52-34.61-34.61-34.61-34.58,15.53-34.58,34.61,15.52,34.57,34.58,34.57h0Zm0-55.18c11.36,0,20.6,9.24,20.6,20.6s-9.24,20.56-20.6,20.56-20.57-9.22-20.57-20.56c0-11.36,9.23-20.6,20.57-20.6Z"
                      />
                      <path
                        fill="#024430"
                        d="m26.88,413.39c1.62,3.22,1.59,29.18,25.95,29.18h63.24c23.66,0,24.96-27.2,25.96-29.18h0c5.28-22.45-10.11-44.57-33.08-47.52-16.25-2.08-32.74-2.08-49.01,0-22.86,2.93-38.37,24.93-33.07,47.52Zm34.85-33.62c15.04-1.92,30.4-1.93,45.45,0,14.38,1.84,24.68,15.67,21.21,30.4-1.97,4.76-1,18.38-12.32,18.38h-63.24c-11.35,0-10.41-13.78-12.31-18.37-3.47-14.76,6.84-28.57,21.21-30.41Z"
                      />
                      <path
                        fill="#024430"
                        d="m84.44,344.58c19.09,0,34.61-15.51,34.61-34.57s-15.52-34.61-34.61-34.61-34.58,15.53-34.58,34.61,15.52,34.57,34.58,34.57h0Zm0-55.18c11.36,0,20.6,9.24,20.6,20.6s-9.24,20.56-20.6,20.56-20.57-9.22-20.57-20.56,9.23-20.6,20.57-20.6Z"
                      />
                      <path
                        fill="#024430"
                        d="m320.02,82.11l-28.51-34.87c-1.33-1.63-3.32-2.57-5.43-2.57-1.1,0-77.46,0-72.17,0-2.1,0-4.09.94-5.43,2.57l-28.51,34.87c-2.07,2.53-1.95,6.48-.02,8.87l64.26,79.78s.01.02.02.03c.7.57,2.29,3.02,5.76,3.02,0,0,0,0,.01,0,0,0,0,0,0,0,.02,0,.04-.01.05-.01,2-.02,4.3-1.23,5.4-2.6l64.54-80.21c1.96-2.44,2.04-6.35-.01-8.87h0Zm-70.02,58.78l-13.29-47.3h26.57l-13.28,47.3Zm49.82-61.35h-21.99l-5.95-20.86h10.9l17.05,20.86Zm-42.52-20.86l5.95,20.86h-26.5l5.95-20.86h14.6Zm-40.07,0h10.9l-5.95,20.86h-21.99l17.05-20.86Zm4.92,34.91l11.84,42.13-33.94-42.13h22.1Zm43.83,42.2l11.84-42.2h22.11l-33.96,42.2Z"
                      />
                    </g>
                  </g>
                </svg>
              ),
              link: "/job-attributes/job-level",
            },
            {
              name: "Key Skills",
              img: (
                <svg
                  id="Layer_1"
                  className="size_color"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 500 500"
                >
                  <g id="outline">
                    <path
                      fill="#024430"
                      d="m250,131.57c-21.26-.02-42.14,5.71-60.41,16.59-3.3,1.98-4.36,6.26-2.38,9.56,1.97,3.28,6.23,4.36,9.52,2.4,14.1-8.31,29.97-13.16,46.3-14.15v34.36c0,3.84,3.11,6.96,6.96,6.97h13.94c3.85,0,6.97,3.12,6.97,6.97s-3.12,6.97-6.97,6.97h-13.93c-3.84,0-6.96,3.11-6.97,6.96h0v34.83h-27.86v-6.97c0-11.54-9.36-20.9-20.9-20.9s-20.9,9.36-20.9,20.9v6.97l-27.4.02c.99-16.34,5.84-32.22,14.15-46.32,1.96-3.31.87-7.58-2.43-9.54-3.29-1.96-7.55-.88-9.52,2.4-32.86,55.3-15.47,126.74,39.13,160.75v11.11c0,3.84,3.11,6.96,6.96,6.97h6.97v13.96h-6.97c-3.85,0-6.97,3.12-6.97,6.97s3.12,6.97,6.97,6.97h6.97v20.9c0,3.84,3.11,6.96,6.96,6.97h7.68c3.85,18.84,22.24,31,41.09,27.16,13.68-2.79,24.37-13.48,27.16-27.16h7.67c3.84,0,6.96-3.11,6.97-6.96h0v-20.9h6.97c3.85,0,6.97-3.12,6.97-6.97s-3.12-6.97-6.97-6.97h-6.97v-13.96h6.97c3.84,0,6.96-3.11,6.97-6.96h0v-11.12c55.46-34.58,72.38-107.57,37.8-163.03-21.63-34.68-59.62-55.75-100.49-55.72Zm0,306.5c-8.85-.01-16.74-5.59-19.71-13.93h39.41c-2.96,8.34-10.85,13.92-19.71,13.93Zm34.83-27.86h-69.65v-13.93h69.65v13.93Zm-69.65-27.86v-13.93h69.65v13.93h-69.65Zm41.79-167.2h6.97c11.54,0,20.9-9.36,20.9-20.9s-9.36-20.9-20.9-20.9h-6.97v-27.51c52.14,3.53,93.64,45.05,97.16,97.18l-34.47-.02h0c-3.85,0-6.96,3.12-6.97,6.97v13.93c0,3.85-3.12,6.97-6.97,6.97s-6.97-3.12-6.97-6.97v-13.93c0-3.84-3.11-6.96-6.96-6.97h-34.83v-27.86Zm-110.98,41.81l34.37-.02c3.84,0,6.96-3.12,6.96-6.97v-13.93c0-3.85,3.12-6.97,6.97-6.97s6.97,3.12,6.97,6.97v13.93c0,3.84,3.11,6.96,6.96,6.97h34.83v27.86h-6.97c-11.54,0-20.9,9.36-20.9,20.9s9.36,20.9,20.9,20.9h6.97v27.86h-41.79v-8.08c0-2.49-1.33-4.79-3.48-6.03-30.04-17.46-49.44-48.71-51.77-83.38Zm156.25,83.38c-2.16,1.24-3.48,3.54-3.48,6.03v8.08h-41.79v-34.83c0-3.84-3.11-6.96-6.96-6.97h-13.94c-3.85,0-6.97-3.12-6.97-6.97s3.12-6.97,6.97-6.97h13.93c3.84,0,6.96-3.11,6.97-6.96h0v-34.83h27.86v6.97c0,11.54,9.36,20.9,20.9,20.9s20.9-9.36,20.9-20.9v-6.96l27.39.02c-2.33,34.67-21.73,65.92-51.77,83.38Z"
                    />
                    <path
                      fill="#024430"
                      d="m103.73,249.98c0-3.84-3.11-6.96-6.96-6.97h-41.8c-3.85,0-6.97,3.12-6.97,6.97s3.12,6.97,6.97,6.97h41.79c3.84,0,6.96-3.11,6.97-6.96h0Z"
                    />
                    <path
                      fill="#024430"
                      d="m120.77,167.33l-36.19-20.9c-3.33-1.92-7.59-.78-9.52,2.55-1.92,3.33-.78,7.59,2.55,9.52l36.19,20.9c3.33,1.92,7.59.78,9.52-2.55,1.92-3.33.78-7.59-2.55-9.52h0Z"
                    />
                    <path
                      fill="#024430"
                      d="m167.35,120.76c1.92,3.33,6.18,4.47,9.52,2.55,3.33-1.92,4.47-6.18,2.55-9.52h0l-20.9-36.19c-1.92-3.33-6.18-4.47-9.52-2.55-3.33,1.92-4.47,6.18-2.55,9.52l20.9,36.19Z"
                    />
                    <path
                      fill="#024430"
                      d="m250,103.71c3.84,0,6.96-3.11,6.97-6.96h0v-41.8c0-3.85-3.12-6.97-6.97-6.97s-6.97,3.12-6.97,6.97v41.79c0,3.84,3.11,6.96,6.96,6.97h0Z"
                    />
                    <path
                      fill="#024430"
                      d="m323.14,123.31c3.33,1.92,7.59.78,9.52-2.55l20.9-36.19c1.92-3.33.78-7.59-2.55-9.52-3.33-1.92-7.59-.78-9.52,2.55l-20.9,36.19c-1.92,3.33-.78,7.59,2.55,9.52,0,0,0,0,0,0Z"
                    />
                    <path
                      fill="#024430"
                      d="m382.72,180.33c1.22,0,2.42-.32,3.48-.93l36.19-20.9c3.33-1.92,4.47-6.18,2.55-9.52-1.92-3.33-6.18-4.47-9.52-2.55l-36.19,20.9c-3.33,1.92-4.48,6.18-2.55,9.51,1.25,2.16,3.55,3.49,6.04,3.49Z"
                    />
                    <path
                      fill="#024430"
                      d="m445.03,243.02h-41.79c-3.85,0-6.97,3.12-6.97,6.97s3.12,6.97,6.97,6.97h41.79c3.85,0,6.97-3.12,6.97-6.97s-3.12-6.97-6.97-6.97Z"
                    />
                    <path
                      fill="#024430"
                      d="m422.39,341.46l-36.19-20.9c-3.33-1.92-7.59-.78-9.52,2.55-1.92,3.33-.78,7.59,2.55,9.52l36.19,20.9c3.33,1.92,7.59.78,9.52-2.55,1.92-3.33.78-7.59-2.55-9.52h0Z"
                    />
                    <path
                      fill="#024430"
                      d="m113.81,320.57l-36.19,20.9c-3.33,1.92-4.47,6.18-2.55,9.52,1.92,3.33,6.18,4.47,9.52,2.55l36.19-20.9c3.33-1.92,4.47-6.18,2.55-9.52-1.92-3.33-6.18-4.47-9.52-2.55Z"
                    />
                    <path
                      fill="#024430"
                      d="m166.27,166.25c-2.72,2.72-2.72,7.13,0,9.85,2.72,2.72,7.13,2.72,9.85,0,2.72-2.72,2.72-7.13,0-9.85-2.72-2.72-7.13-2.72-9.85,0h0Z"
                    />
                  </g>
                </svg>
              ),
              link: "/job-attributes/skills",
            },

            {
              name: "Educational Qualifications",
              img: (
                <svg
                  id="Layer_1"
                  className="size_color"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 500 500"
                >
                  <path
                    fill="#024430"
                    d="m449.61,178.43l-165.07-80.64c-14.23-7.12-38.42-7.12-52.65,0L63.02,178.43c-11.86,5.69-13.76,13.76-13.76,18.02s1.9,11.86,13.76,18.02l13.76,6.64v75.89c-8.54,3.32-14.7,11.86-14.7,21.35s6.17,18.02,14.23,21.35l-21.35,67.83h59.77l-21.35-67.83c8.54-3.32,14.23-11.86,14.23-21.35,0-9.96-6.17-18.02-14.7-21.35v-67.83l25.14,12.33v98.66c0,1.42.47,2.85,1.42,4.27,2.37,2.85,46.49,63.09,136.61,63.09s134.24-60.72,136.14-63.09c.95-1.42,1.42-2.85,1.42-4.27v-98.19l55.97-27.51c11.86-5.69,13.76-13.76,13.76-18.02-.47-4.27-1.9-12.33-13.76-18.02Zm-71.15,159.38c-7.59,9.49-48.38,55.02-122.38,55.02s-114.79-45.54-122.38-55.02v-89.65l98.19,46.96c7.12,3.32,16.6,5.22,26.09,5.22,9.96,0,19.45-1.9,26.56-5.69l93.92-46.01v89.18Zm64.51-136.61l-56.92,27.99c-2.37,0-4.27.95-5.22,2.85l-102.93,49.81c-9.96,5.22-29.41,5.22-39.84,0l-126.65-60.72,145.62-17.08c4.27-.47,7.12-4.27,6.64-8.06-.47-4.27-4.27-7.12-8.06-6.64l-168.86,19.92-17.08-8.06c-4.27-1.9-5.22-4.27-5.22-4.27,0-.47.95-2.37,5.22-4.27l168.86-81.11c5.22-2.37,12.33-3.79,19.45-3.79,7.59,0,15.18,1.42,19.92,3.79l165.07,80.64c4.27,1.9,5.22,3.79,5.22,4.74,0,0-1.42,2.37-5.22,4.27Z"
                  />
                </svg>
              ),
              link: "/job-attributes/degree-levels",
            },
          ],
        },
        // {
        //   name: "Blogs",
        //   logo:<AssignmentOutlinedIcon style={{ color: 'white',fontSize:"20px"}}/>,
        //   isMulti: true,
        //   clicked: false,
        //   items: [
        //     {
        //       name: "All Blogs",
        //       icon: "bx bx-list-ul",
        //       link: "/blog/all-blogs",
        //     },
        //     {
        //       name: "Blog Grid",
        //       icon: "bx bx-grid-alt",
        //       link: "/blog/grid-blogs",
        //     },
        //     {
        //       name: "Create Blogs",
        //       icon: "bx bxs-message-square-add",
        //       link: "/blog/create-blogs",
        //     },
        //     {
        //       name: "All Categories",
        //       icon: "bx bx-category-alt",
        //       link: "/blog/all-categories",
        //     },
        //     {
        //       name: "Create Categoies",
        //       icon: "bx bxs-category",
        //       link: "/blog/create-categories",
        //     },
        //   ],
        // },

        // {
        //   name: "Talent Pool",
        //   link: "/talent-pool",
        //   icon: "bx bx-user-check",
        // },
        // {
        //   name: "Question Bank",
        //   link: "/questions",
        //   icon: "bx bx-question-mark",
        // },
        // {
        //   name: "Boolean Query Builder",
        //   link: "/boolean-query",
        //   icon: "bx bx-filter",
        // },
        // {
        //   name: "Reports",
        //   link: "/reports",
        //   icon: "bx bx-bar-chart",
        // },
        // {
        //   name: "Calendar",
        //   link: "/calendar",
        //   icon: "bx bx-calendar-alt",
        // },
        // {
        //   name: "Payments",
        //   link: "/payments",
        //   icon: "bx bx-dollar-circle",
        // },
        {
          name: "Setings",
          link: "/profile-settings?tab=2",
          icon: "bx bx-cog",
        },
      ];
  }
}
