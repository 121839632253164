import * as React from "react";
import { GridToolbar } from "@mui/x-data-grid";
import { imageURL } from "common/constants/commonURLS";
import {
  MenuProps,
  PrimaryButton,
  PrimaryButtonForTable,
  PrimaryDataGrid,
  PrimaryNormalInputs,
  PrimarySwitch,
  SmallPrimaryAvatar,
  PrimaryChip
} from "assets/Mui/globalTheme";
import TablePopper from "components/table/header/popper";
import {
  getAllScreenedApplicantsForUser,
  getAllScreeningPartnerWithStatus,
} from "helpers/services/screeningPartner/screeningPartner";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { getAllIndustries } from "helpers/services/jobAttributes/industries";
import IndustryInput from "components/table/header/industrySelect";
import { IndustryOperators } from "components/table/operators/industryOperators";
import { LocationOperators } from "components/table/operators/locationOperators";
import LoaderIcon from "utils/icons/loaderIcon";
import { GenderOperators } from "components/table/operators/genderOperators";
import { WorkModeOperators } from "components/table/operators/worModeOperators";
import { useDispatch, useSelector } from "react-redux";
import { getAllEarningsDetails } from "helpers/services/Earnings/MyEarnings";
import CustomNoRowsOverlay from "common/data/NoRowsFoundDatagrid/NoRowsFound";

export default function MyEarningsTable() {
  const [ScreenedApplicants, setScreenedApplicants] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const selector = useSelector(state => state?.Login);
  
  let type
  if(selector?.usertype == "EVALUATOR"){
    type = "Evaluator"
  }else if (selector?.usertype == "SCREENING_PARTNER"){
    type = "Sp"
  }else {
    type = "Moderator"
  }
  React.useEffect(() => {
    initialFetch();
  }, []);

  async function initialFetch() {
    setLoading(true);
    // const {data,status}= await getAllEarningsDetails(selector.id,type);
    const result = data?.message?.rows?.map((item, index) => ({
      id: index + 1,
      name: `${item?.user_job_post?.first_name} ${item?.user_job_post?.last_name}`,
      Company: item?.company_details?.company?.company_name,
      date_referred: `${item?.user_job_post?.phone_number?item?.user_job_post?.phone_number:"N/A"}`,
      ctc: `${item?.user_job_post?.email}`,
      status: `${item?.user_job_post?.job_post_pipeline_stage?.stage_name}`,
    }));
    if (data?.message?.rows) {
      setLoading(false);
    }
    // setScreenedApplicants(result);
  }

  return (
    <div style={{ padding: "10px", height: "91vh" }}>
      <PrimaryDataGrid
        loading={loading}
        checkboxSelection={true}
        columns={[
          { field: "name",  headerName: "Name", width: 200 },
          { field: "Company",  headerName: "Company", width: 300 },
          { field: "date_referred",   headerName: "Date Referred", width: 300 },
          { field: "ctc",  headerName: "CTC",width:300 },
          {
            field: "Status",
            headerName: "Status",
            width: 400,
          },
        //   {
        //     field: "Status",
        //     headerName: "Status",
        //     width: 200,
        //     renderCell: params => {
     
        //       return (
        //         <PrimaryChip label={params?.row?.status} size="small"/>
        //       );
        //     },
        //   },
        ]}
        rows={ScreenedApplicants}
        slots={{
          loadIcon: LoaderIcon,
          baseButton: PrimaryButton,
          basePopper: TablePopper,
          baseSwitch: PrimarySwitch,
          toolbar: GridToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          baseButton: { style: { width: "100px" } },
          panel: {
            style: {
              right: "0px !important",
              transform: "none !important",
              inset: "none !important",
              top: "138px !important",
            },
          },
          filterPanel: {
            style: {
              right: "0px !important",
              transform: "none !important",
              inset: "none !important",
              top: "138px !important",
            },
          },
          toolbar: { style: {} },
          preferencesPanel: {
            style: {
              right: "0px !important",
              transform: "none !important",
              inset: "none !important",
              top: "138px !important",
            },
          },
        }}
      />
    </div>
  );
}
