  import axios from "axios";
import { toast } from "react-hot-toast";
// const API_URL = "http://192.168.29.162:3001/api";
// const API_URL = "http://localhost:8082/api";
// const API_URL = "https://services.joinee.com/api";
// const API_URL = "https://qaservices.joinee.com/api"

//REACT_APP_BASE_URL_QA
//REACT_APP_BASE_URL_PROD

// const API_URL = `${process.env.REACT_APP_BASE_URL_QA}/api/`;

// const axiosApi = axios.create({
//   baseURL: API_URL,
// });


const baseURL =
  process.env.REACT_APP_ENV === "qa"
    ? process.env.REACT_APP_BASE_URL_QA
    : process.env.REACT_APP_BASE_URL_PROD;

const axiosApi = axios.create({
  baseURL: `${baseURL}/api/`,
  // baseURL: API_URL,
});



function listner() {
  return JSON.parse(localStorage.getItem("authUser"))?.token;
  return store.getState()?.Login?.data?.access;
}
export default async function callApi(
  url,
  data = {},
  method = "GET",
  config = {}
) {
  let newURl = url;
  if (url?.startsWith("/")) {
    newURl = url?.replace("/", "");
  }
  return await axiosApi({
    method: method,
    url: newURl,
    data,
    headers: {
      Authorization: "Bearer " + listner(),
      ...config,
    },
    // ...config,
  })
    .then(res => {
      if (res.status === 401) {
        localStorage.removeItem("authUser");
      }
      if (res.status === 200 || res.status === 201) {
        // if (res?.data?.message?.length > 0) toast.success(res?.data?.message);
      }
      return { data: res.data, status: res.status };
    })
    .catch(error => {
      if (error?.response?.status === 400) {
        // toast.error(error.response?.data?.return_message)
        if(error.response?.data?.return_message){
          toast.error(error.response?.data?.return_message)
        }else if(error.response?.return_message){
          toast.error(error.response?.return_message)
        }
      }
      if (error.response.status === 422) {
        toast.error(error.response?.data)
      }
      if (!error.response?.data?.message) {
      
        if (error.response?.data?.data)
          toast.error(error.response.data.data);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error)
        }
        // throw new Error(error.response.data?.data)
      } else {
 
        toast.error(error.response?.data.message);
      } if (error.response.status === 500) {
        toast.error("Internal Server Error");
      }
      if (error.response.status === 401) {
        localStorage.removeItem("authUser");
        window.location.href = "/login";
      }
    });

}
