import { POST } from "common/constants/methods";
import callApi from "helpers/callApi";

export async function verifyOTP(email, otp) {
  const otpResult = await callApi(
    "/company_authorise/verify_otp",
    { email, otp },
    POST
  );
  return otpResult;
}

export async function handleResendOTP(email) {
  return await callApi(
    "/company_authorise/resend_otp",
    {
      email,
    },
    POST
  );
}

export async function verifyMobileOTP(otp) {
  return await callApi("/common/verify_otp", { otp }, "PUT");
}

export async function verifyMobileOTPStaff(data) {
  return await callApi("/company_staff/verify_otp", data, "POST");

  
}



export async function verifyReferalMobileOTP(otp, email) {
  return await callApi("/company_authorise/verify_otp", { otp, email }, "POST");
}

export async function sendOtp(type, number, cur_mobile) {
  return await callApi(`/common/send_otp`, {
    update_type: type,
    phone_number: number,
    old_phone_number: cur_mobile
  }, "PUT")
}

