import { combineReducers } from "redux";
import Layout from "./layout/reducer";
import Login from "./auth/login/reducer";

import { Jobs } from "./jobs/reducer";
import invoices from "./invoices/reducer";
import tasks from "./tasks/reducer";
import ScreeningPartner from "./screeningPartner/reducer";
import Company from "./Company/reducer";
import Screening from "./screening/reducer";
import Notes from "./notes/reducer";
import Candidates from "./candidates/reducer";
import Loading from "./loading/reducer";
import Modal from "./modals/reducer";
import JobAttributes from "./JobAttributes/reducer";
import Customs from "./Customs/reducer";
import CommonUser from "./commonUser/reducer";
import pendingCountReducer from './Evaluations/reducer';
import NotificationReducer from './Notifications/reducer';


const rootReducer = combineReducers({
  Layout,
  Login,
  Jobs,
  Company,
  invoices,
  tasks,
  ScreeningPartner,
  Screening,
  Notes,
  Candidates,
  Loading,
  Modal,
  CommonUser,
  JobAttributes,
  Customs,
  // Chat,
  NotificationReducer, //this is assumption of notifications reducer.
  pendingCountReducer //this is assumption of evaluations restrictions.
});

export default rootReducer;
