import { Box, Grid, IconButton, Slider, Stack, Tooltip } from "@mui/material";
import {
  CustomSlider,
  GreyTypography,
  SuccessSlider,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

export default function EvaluationSlider({
  evalution_name,
  color,
  thumb__hover_color,
  score,
}) {
  const marks = Array.from({ length: 10 }, (_, i) => ({
    value: i + 1,
    label: i + 1,
  }));

  return (
    <Stack>
      {/* <Grid container>
        <Grid item xs={3}>
          <GreyTypography>{evalution_name}</GreyTypography>
         
        </Grid>
        <Grid item xs={4}>
          <Tooltip title="it's an avaerage score of a candidate skills">
            <IconButton>
              <FeatherIcon icon="info" size="14" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid> */}
      <Box sx={{ width: "90%",padding:"12px"}}>
        <CustomSlider
        //   thumb__hover_color={thumb__hover_color}
        //   backgrounds={color}
        thumb__hover_color="#044B36"
        backgrounds="#044B36"
          aria-label="Custom marks"
          value={score?.toFixed(2)}
        // value={2}
          max={5}
          min={0}
          step={1}
          valueLabelDisplay="auto"
          marks={marks}
        //   marks={marks}
          // disabled 
        />
      </Box>
    </Stack>
  );
}
