import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  BlackTypography,
  PrimaryButton,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  PrimaryTypography,
  SecondaryFilledButton,
} from "assets/Mui/globalTheme";
import { useForm } from "react-hook-form";
import { CircularProgress, FormControl, Grid, Stack } from "@mui/material";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  createAdminStaff,
  getSingleAdminStaffDetails,
  updateAdminStaff,
  

} from "helpers/services/adminStaff/adminStaff";
import { createRcCompanyStaff } from "helpers/services/companies/company";
import {
  createCompanyStaff,
  getSingleCompanyStaffDetails,
  updateCompanyStaff,
} from "helpers/services/companies/company";
import {
  createBackgroundVerificationCompanyStaff,
  getSingleBackgroundVerificationStaffDetails,
  updateBackgroundVerificationCompanyStaff,
} from "helpers/services/BackgroundVerifications/backgroundVerifications";
import {
  getSingleRecrutementStaffDetails,
} from "helpers/services/RecrutementCompany/recrutement";
import { toast } from "react-hot-toast";
import PhoneInput,{isValidPhoneNumber,formatPhoneNumberIntl} from "react-phone-number-input";
import { sendOtp } from "helpers/services/otpVerifiy/verify";
import StaffOtp from "./staffOtp";
import { parseNumberInput } from "hooks/customPhoneNumberParse";
import ParseCountryCode from "hooks/ParseCountryCode";


import { createRecrutementCompanyStaff } from "helpers/services/RecrutementCompany/recrutement";

export default function StaffModel({
  purpose,
  role,
  backendData,
  id,
  headTag,
  isOpen,
  toggle,
  updated,
  isApprove,
  fetchData
}) {
  const [viewOtp, setViewOtp] = React.useState(false)
  const [load, setLoad] = React.useState(false)
  const [tempNum,setTempNum] = React.useState("")
  let countryCode
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    setError,
    formState: { errors },
  } = useForm();
  React.useEffect(() => {
    if (purpose === "EDIT") fillEditFieldValues();
  }, [purpose]);






  const userData = {
    first_name: watch("first_name"),
    last_name: watch("last_name"),
    email: watch("email")?.toLowerCase(),
    mobile: watch("mobile"),
    phone_number:watch('phone_number'),
    user_password:watch("user_password")
  }



  async function fillEditFieldValues() {
    if (role === "RC") {
      const { data: currentData } = await getSingleRecrutementStaffDetails(id);
      setValue("first_name", currentData.first_name);
      setValue("last_name", currentData.last_name);
      setValue("email", currentData.email);
      countryCode = ParseCountryCode(currentData?.phone_number)
      setValue("phone_number",(currentData?.phone_number).slice(1))

    } else if (role === "ZA") {
      const currentData = await getSingleAdminStaffDetails(id);
      // const num = formatPhoneNumberIntl(currentData?.phone_number)
      setValue("first_name", currentData.first_name);
      setValue("last_name", currentData.last_name);
      setValue("email", currentData.email?.toLowerCase());
      setValue("mobile",currentData?.phone_number),

      countryCode = ParseCountryCode(currentData?.phone_number)
      setValue("phone_number",(currentData?.phone_number).slice(1))

      setTempNum(currentData?.phone_number)
    } else if (role === "CA") {
      const currentData = await getSingleCompanyStaffDetails(id);
      // const num = formatPhoneNumberIntl(currentData?.phone_number)
      setValue("first_name", currentData.first_name);
      setValue("last_name", currentData.last_name);
      setValue("email", currentData.email?.toLowerCase());
      setValue("mobile",currentData?.phone_number),
      countryCode = ParseCountryCode(currentData?.phone_number)
      setValue("phone_number",(currentData?.phone_number).slice(1))
      setTempNum(currentData?.phone_number)
    } else {
      const { data: currentData } =
        await getSingleBackgroundVerificationStaffDetails(id);
      setValue("first_name", currentData.first_name);
      setValue("last_name", currentData.last_name);
      setValue("email", currentData.email?.toLowerCase());
      setValue("mobile",currentData?.phone_number)
      countryCode = ParseCountryCode(currentData?.phone_number)
      setValue("phone_number",(currentData?.phone_number).slice(1))
      setTempNum(currentData?.phone_number)
    }
  }

  async function SendOtpHandle(number) {
    try {
      setLoad(true)
      const res =  await sendOtp("mobile",number)
      const { status } = res
   
      if (status === 200 && viewOtp) {
        setViewOtp(true)     
        setLoad(false)
      }        
    } catch (error) {        
      setLoad(false)
    }
  }


  const updateOrEditStaff = async data => {
    if (!isValidPhoneNumber(data.phone_number)) {
      setError("phone_number", { type: "custom", message: "invalid number" });
      return;
    }
    if (purpose === "EDIT") {
      try {
        if (data.phone_number === tempNum) {
          // delete data.user_password;
          delete data.mobile
          switch (role) {
            case "ZA":
              await updateAdminStaff({ ...data, isApprove }, id);
              break;
            case "CA":
              await updateCompanyStaff({ ...data }, id);
              break;
            case "BVC":
              await updateBackgroundVerificationCompanyStaff({ ...data }, id);
              break;
            default:
              return null;
          }
          updated();
          toggle();
        } else {
        
          setLoad(true);
          const res = await sendOtp("mobile", data.phone_number);
          const { status } = res;
     
          if (status === 200) {
            setViewOtp(true);
            setLoad(false);
          }
        }
      } catch (error) {
        setLoad(false);
      }
    } else {
      try {
        setLoad(true);
        let registerStatus;
        // Add phone_number to userData
        const userDataWithPhone = { ...userData, phone_number: data.phone_number };
       
        switch (role) {
          case "ZA":
            registerStatus = await createAdminStaff({ ...userDataWithPhone, usertype: backendData, isApprove });
            break;
          case "CA":
            registerStatus = await createCompanyStaff({ ...userDataWithPhone, usertype: backendData });
            break;
          case "BVC":
            registerStatus = await createRcCompanyStaff({ ...userDataWithPhone, usertype: backendData });
            break;
          case "RC":
    
            registerStatus = await createRcCompanyStaff({ ...userDataWithPhone, usertype: backendData });
            break;
          default:
            return null;
        }
        const { status } = registerStatus;
        if (status === 201) {
          setViewOtp(true);
          setLoad(false);
        }
      } catch (error) {
        setLoad(false);
      }
    }
  };


  return (
    <div>
      <Modal
        open={isOpen}
        onClose={() => {
          toggle();
          reset();
          setLoad(false);
        }}
        aria-labelledby="edit-profile-modal-first_name"
        aria-describedby="edit-profile-modal-description"
      >
        <Box sx={style}>
          {!load ?
            <>
          <BlackTypography variant="h2" sx={{ marginBottom: "10px" }}>
            {headTag}{" "}
          </BlackTypography>
          <form autoComplete={"off"} onSubmit={handleSubmit(updateOrEditStaff)}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="work-exp-first-name-outlined"
                    error={errors.first_name}
                  >
                    First name*
                  </PrimaryNormalInputLabel>
                  <PrimaryNormalInputs
                    fullWidth
                    placeholder="First Name"
                    {...register("first_name", { required: true,pattern: /\S/})}
                    id="wor-exp-first-name-outlined"
                    helperText={
                      errors?.first_name ? "First  name is required" : ""
                    }
                    autoFocus
                    label="Job first_name"
                    type={"text"}
                    name="first_name"
                    error={errors.first_name}
                    variant="outlined"
                  />
                  <PrimaryNormalFormHelperText error={errors.first_name}>
                    {errors.first_name ? "First name is required Field" : ""}
                  </PrimaryNormalFormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="work-exp-last-name-outlined"
                    error={errors.last_name}
                  >
                    Last name*
                  </PrimaryNormalInputLabel>
                  <PrimaryNormalInputs
                    fullWidth
                    placeholder="Last Name"
                    {...register("last_name", { required: true,pattern: /\S/})}
                    id="wor-exp-last-name-outlined"
                    helperText={
                      errors?.last_name ? "Last  name is required" : ""
                    }
                    autoFocus
                    label=" last name"
                    type={"text"}
                    name="last_name"
                    error={errors.last_name}
                    variant="outlined"
                  />
                  <PrimaryNormalFormHelperText error={errors.last_name}>
                    {errors.last_name ? "Last name is required Field" : ""}
                  </PrimaryNormalFormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="work-exp-email-outlined"
                    error={errors.email}
                  >
                    Email*
                  </PrimaryNormalInputLabel>
                  <PrimaryNormalInputs
                    fullWidth
                    disabled={purpose === "EDIT"?true:false}
                    placeholder="Enter your email"
                    {...register("email", { required: true ,pattern: /\S/ })}
                    id="wor-exp-email-outlined"
                    helperText={errors?.email ? "Email is required" : ""}
                    autoFocus
                    label=" email"
                    type={"email"}
                    name="email"
                    error={errors.email}
                    variant="outlined"
                  />
                  <PrimaryNormalFormHelperText error={errors.email}>
                    {errors.email ? "Email is required Field" : ""}
                  </PrimaryNormalFormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                <PrimaryTypography 
                sx={{ fontWeight: "500 !important" ,color:"rgba(69, 76, 82, 1) !important",fontSize:"13px !important"}}
                variant="h5"
            >
              Number*
          </PrimaryTypography>
                  <PhoneInput
              id="signup-phone-number"
              labelId="signup-phone-number"
                        international={true}
                        useNationalFormatForDefaultCountryValue={true}
              withCountryCallingCode={true}
              {...register("phone_number", {
                required: true,
                minLength: 10,
              })}
              className={`input_phone ${
                errors?.confirm_mobile ? "error_phone" : ""
                } `}
              style={{marginTop:"20px !important"}}
              name="phone Number"
              error={errors.confirm_mobile}
              onChange={phone => {
                setValue("phone_number", phone);
              }}
              label="signup-phone-number"
              value={watch("phone_number")}
              // defaultCountry="IN"
              defaultCountry={countryCode || "IN"}
              initialValueFormat="national"
              placeholder="Enter phone number"
            />
                  {errors.phone_number ?<PrimaryNormalFormHelperText error={errors.phone_number}>
                    {errors.phone_number.message || "mobile number is required Field"}
                  </PrimaryNormalFormHelperText> : null}
                </FormControl>
              </Grid>
              {purpose !== "EDIT" && (
                <Grid item md={12}>
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    <PrimaryNormalInputLabel
                      shrink
                      htmlFor="user-password-outlined"
                      error={errors.user_password}
                    >
                      password*
                    </PrimaryNormalInputLabel>
                    <PrimaryNormalInputs
                      fullWidth
                      placeholder="Enter your password"
                      {...register("user_password", {
                        required: true,
                        validate: (value) => !/\s/.test(value),
                        minLength: {
                            value: 5,
                            message: "Password must be at least 5 characters",
                        },
                        maxLength: {
                            value: 15,
                            message: "Password must not exceed 15 characters",
                        },
                      

                        pattern: {
                            value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{5,10}$/,
                            message: "Password must contain at least one letter, one number, and one special character. No spaces allowed.",
                        },
                      })}
                      id="user-password-outlined"
                      helperText={
                        errors?.user_password ? "Password is required" : ""
                      }
                      autoFocus
                      label=" user_password"
                      type={"password"}
                      name="user_password"
                      error={errors.user_password}
                      variant="outlined"
                    />
                    <PrimaryNormalFormHelperText error={errors.user_password}>
                      {/* {errors.user_password ? "Password is required Field" : ""} */}
                      {errors?.password?.type === "required" ? "Password is required" : errors?.password?.message ? errors?.password?.message : null}
                    </PrimaryNormalFormHelperText>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={7}></Grid>
              <Grid item xs={5}>
                <Stack spacing={1} direction="row">
                  <SecondaryFilledButton
                    onClick={() => {
                      toggle();
                      reset();
                    }}
                  >
                    cancel
                  </SecondaryFilledButton>
                  <PrimaryButton type="submit">Submit</PrimaryButton>
                </Stack>{" "}
              </Grid>
            </Grid>
          </form>            
            </>
            :
            <div style={{marginLeft:"45%"}}>
              <CircularProgress size={40} />
            </div>
          }
        </Box>
      </Modal>
      {viewOtp ? <StaffOtp id={id} purpose={purpose} isOpen={viewOtp} toggle={() => setViewOtp(false)} userData={userData} backendData={backendData} isApprove={isApprove} handleClose={toggle} reUpdate={updated} clearField={ reset} fetchData={fetchData} /> : null}
    </div>
  );
}
