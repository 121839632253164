import checkMouseDown from "hooks/mouseDown";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Picker from "emoji-picker-react";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { IconButton, Stack, InputBase } from "@mui/material";
import { BorderCard } from "assets/Mui/globalTheme";
import AttachmentPreview from "./AttachmentPreview";
import moment from "moment";
import { getPreSignedURL } from "helpers/services/pre_signed_urls/upload";
import { getPreSignedURLForMultipleUpload } from "helpers/services/pre_signed_urls/multipleUpload";
import SearchParamHook from "hooks/searchParams";

export default function ChatInput({setMsgs,handleSendMessage}) {
  const messageRef = useRef(null);
  const searchParams = SearchParamHook();
  const profileReadStatus = searchParams.get('readOnlyStatus')
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm();
  const [attachments, setAttachments] = useState([]);
  const attachmentRef = useRef(null);
  const [showEmoji, setShowEmoji] = useState(false);
  const [attachmentModel, setAttachmentModel] = useState(false);
  const [imgPreview,setImagePreview] = useState([])
  function handleAttachmentPreview() {
    if (attachmentModel) {
      setAttachments([]);
    }
    setAttachmentModel(!attachmentModel);
  }
  const pickerRef = useRef(null);
  function handelAttachmentInputClick() {
    attachmentRef?.current.click();
  }
  async function handleAttachementFiles() {
    let resultantArray = [];
    if (attachmentRef.current.files.length > 0) {
      let files = attachmentRef.current.files;
      if (files) {
       const img=  await Array.prototype.forEach.call(files, readAndPreview);
        setAttachments(resultantArray);
        setImagePreview(files)
        // await getPreSignedURL()
      //  const ass = await getPreSignedURLForMultipleUpload([...resultantArray.map(item=>item.preview)])
    
      }

      function readAndPreview(file) {
        if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
          resultantArray.push({
            name: file.name,
            type: file.type,
            preview: URL.createObjectURL(file),
            size: formatBytes(file.size),
          });
        } else {
          resultantArray.push({
            name: file.name,
            type: file.type,
            preview: null,
            size: formatBytes(file.size),
          });
        }
      }
      handleAttachmentPreview();
    }
  }
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  useEffect(() => {
    if (messageRef.current) {
      messageRef.current.focus();
      messageRef.current.value = "";
    }
  }, [messageRef.current]);
  const onEmojiClick = event => {
    if (messageRef.current) {
      messageRef.current.focus();
    }
    setValue("message", getValues("message") + event.emoji);
    // setShowEmoji(false);
  };
  checkMouseDown({ pickerRef, setShowPicker: setShowEmoji });
  const onSubmit = data => {
    handleSendMessage(data.message)
    setValue('message','')
  };

  return (
    <>
      <AttachmentPreview
        handleAttachmentModel={handleAttachmentPreview}
        attachmentModel={attachmentModel}
        attachment={attachments}
        handleSendMessage={handleSendMessage}
        imagePreviews={imgPreview}
      />
      {showEmoji && (
        <div
          ref={pickerRef}
          style={{
            zIndex: 1000,
            position: "absolute",
            bottom: "10px",
            right: "10px",
          }}
        >
          <Picker width={300} height={400} onEmojiClick={onEmojiClick} />
        </div>
      )}
      <div
        style={{
          position: "absolute",
          bottom: "10px",
          width: "98%",
          left: "10px",
        }}
      >
        <BorderCard sx={{ padding: "4px",height:'70px' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <InputBase
                ref={messageRef}
                {...register("message", { required: true })}
                type="text"
                placeholder="Enter message"
                disabled={profileReadStatus === "false"?false:true}
                // style={{cursor:profileReadStatus?"not-allowed":"auto"}}
              />

              <Stack sx={{marginTop:'0 !important'}} direction="row" spacing={1} justifyContent={"flex-end"}>
                <IconButton
                  disabled={profileReadStatus === "false"?false:true}
                onClick={handelAttachmentInputClick}>
                  <FeatherIcon icon="paperclip" size="14" 
                    
                  />
                </IconButton>

                <IconButton
                      disabled={profileReadStatus === "false"?false:true}
                type="button" onClick={() => setShowEmoji(true)}>
                  <FeatherIcon icon="meh" size="14"
                 
                  />
                </IconButton>
                <IconButton type="submit"
                disabled={profileReadStatus === "false"?false:true}
                >
                  <FeatherIcon icon="send" size="14"
                 
                  />
                </IconButton>
              </Stack>
            </Stack>
          </form>
        </BorderCard>
        <form style={{ display: "none" }}>
          <input
            // multiple={true}
            ref={attachmentRef}
            type="file"
            onChange={handleAttachementFiles}
          />
        </form>
      </div>
    </>
  );
}
