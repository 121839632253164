import { FormControl, Stack } from "@mui/material";
import {
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
} from "assets/Mui/globalTheme";
import { useFormContext } from "react-hook-form";

export default function SecondCompanyFollowUps() {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={4} justifyContent={"space-between"}>
        <FormControl sx={{ width: "100%" }} variant="standard">
          <PrimaryNormalInputLabel
            shrink
            htmlFor="login-glassdoor-outlined"
            error={errors.glassdoors_url}
          >
            glassdoor
          </PrimaryNormalInputLabel>
          <PrimaryNormalInputs
            fullWidth
            placeholder="Enter glassdoorurl of your company"
            {...register("glassdoors_url", {
              required: false,
              pattern: {
                value:
                  /^(https?:\/\/)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(:[0-9]+)?(\/[^\s]*)?$/,
                message: "Invalid URL format",
              },
              maxLength: {
                value: 75,
                message: "Website URL must not exceed 75 characters",
              },
            })}
            id="login-glassdoor-outlined"
            autoFocus
            label="glassdoor address"
            type={"text"}
            error={errors.glassdoors_url}
            variant="outlined"
          />
          <PrimaryNormalFormHelperText error={errors.glassdoors_url}>
            {errors?.glassdoors_url ? "Enter valid url" : "GlassdoorUrl is an optional field"}
          </PrimaryNormalFormHelperText>
        </FormControl>
        <FormControl sx={{ width: "100%" }} variant="standard">
          <PrimaryNormalInputLabel
            shrink
            htmlFor="login-ambitionbox-outlined"
            error={errors.ambition_box_url}
          >
            ambitionbox
          </PrimaryNormalInputLabel>
          <PrimaryNormalInputs
            fullWidth
            placeholder="Enter ambitionboxurl of your company"
            {...register("ambition_box_url", {
              required: false,
              pattern: {
                value:
                  /^(https?:\/\/)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(:[0-9]+)?(\/[^\s]*)?$/,
                message: "Invalid URL format",
              },
              maxLength: {
                value: 75,
                message: "Website URL must not exceed 75 characters",
              },
            })}
            id="login-ambitionbox-outlined"
            autoFocus
            label="ambitionbox address"
            type={"text"}
            error={errors.ambition_box_url}
            variant="outlined"
          />
          <PrimaryNormalFormHelperText error={errors.ambition_box_url}>
            {errors?.ambition_box_url ? "Enter valid url" : "AmbitionboxUrl is an optional field"}
          </PrimaryNormalFormHelperText>
        </FormControl>
        <FormControl sx={{ width: "100%" }} variant="standard">
          <PrimaryNormalInputLabel
            shrink
            htmlFor="login-comparably-outlined"
            error={errors.comparably_url}
          >
            comparably
          </PrimaryNormalInputLabel>
          <PrimaryNormalInputs
            fullWidth
            placeholder="Enter comparablyurl of your company"
            {...register("comparably_url", {
              required: false,
              pattern: {
                value:
                  /^(https?:\/\/)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(:[0-9]+)?(\/[^\s]*)?$/,
                message: "Invalid URL format",
              },
              maxLength: {
                value: 75,
                message: "Website URL must not exceed 75 characters",
              },
            })}
            id="login-comparably-outlined"
            autoFocus
            label="comparably address"
            type={"text"}
            error={errors.comparably_url}
            variant="outlined"
          />
          <PrimaryNormalFormHelperText error={errors.comparably_url}>
            {errors?.comparably_url ? "Enter valid url" : "ComparablyUrl is an optional field"}
          </PrimaryNormalFormHelperText>
        </FormControl>
      </Stack>
      <Stack direction={"row"} spacing={4} justifyContent={"space-between"}>
        <FormControl sx={{ width: "100%" }} variant="standard">
          <PrimaryNormalInputLabel
            shrink
            htmlFor="login-CarrerBliss-outlined"
            error={errors.careerbliss_url}
          >
            CarrerBliss
          </PrimaryNormalInputLabel>
          <PrimaryNormalInputs
            fullWidth
            placeholder="Enter Carrerblissurl of your company"
            {...register("careerbliss_url", {
              required: false,
              pattern: {
                value:
                  /^(https?:\/\/)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(:[0-9]+)?(\/[^\s]*)?$/,
                message: "Invalid URL format",
              },
              maxLength: {
                value: 75,
                message: "Website URL must not exceed 75 characters",
              },
            })}
            id="login-CarrerBliss-outlined"
            autoFocus
            label="CarrerBliss address"
            type={"text"}
            error={errors.careerbliss_url}
            variant="outlined"
          />
          <PrimaryNormalFormHelperText error={errors.careerbliss_url}>
            {errors?.careerbliss_url ? "Enter valid url" : "CarrerblissUrl is an optional field"}
          </PrimaryNormalFormHelperText>
        </FormControl>
        <FormControl sx={{ width: "100%" }} variant="standard">
          <PrimaryNormalInputLabel
            shrink
            htmlFor="login-payscale-outlined"
            error={errors.payscale_url}
          >
            payscale
          </PrimaryNormalInputLabel>
          <PrimaryNormalInputs
            fullWidth
            placeholder="Enter payscaleurl of your company"
            {...register("payscale_url", {
              required: false,
              pattern: {
                value:
                  /^(https?:\/\/)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(:[0-9]+)?(\/[^\s]*)?$/,
                message: "Invalid URL format",
              },
              maxLength: {
                value: 75,
                message: "Website URL must not exceed 75 characters",
              },
            })}
            id="login-payscale-outlined"
            autoFocus
            label="payscale address"
            type={"text"}
            error={errors.payscale_url}
            variant="outlined"
          />
          <PrimaryNormalFormHelperText error={errors.payscale_url}>
            {errors?.payscale_url ? "Enter valid url" : "PayscaleUrl is an optional field"}
          </PrimaryNormalFormHelperText>
        </FormControl>
        <FormControl sx={{ width: "100%" }} variant="standard">
          <PrimaryNormalInputLabel
            shrink
            htmlFor="login-google_reviews-outlined"
            error={errors.twitter_url}
          >
            Twitter
          </PrimaryNormalInputLabel>
          <PrimaryNormalInputs
            fullWidth
            placeholder="Enter twitterurl of your company"
            {...register("twitter_url", {
              required: false,
              pattern: {
                value:
                  /^(https?:\/\/)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(:[0-9]+)?(\/[^\s]*)?$/,
                message: "Invalid URL format",
              },
              maxLength: {
                value: 75,
                message: "Website URL must not exceed 75 characters",
              },
            })}
            id="login-twitter-outlined"
            autoFocus
            label="twitter address"
            type={"text"}
            error={errors.twitter_url}
            variant="outlined"
          />
          <PrimaryNormalFormHelperText error={errors.twitter_url}>
            {errors?.twitter_url ? "Enter valid url" : "TwitterUrl is an optional field"}
          </PrimaryNormalFormHelperText>
        </FormControl>
        <FormControl sx={{ width: "100%" }} variant="standard">
          <PrimaryNormalInputLabel
            shrink
            htmlFor="login-twitter-outlined"
            error={errors.google_reviews}
          >
            Google Reviews
          </PrimaryNormalInputLabel>
          <PrimaryNormalInputs
            fullWidth
            placeholder="Enter googlereviewsurl of your company"
            {...register("google_reviews", {
              required: false,
              pattern: {
                value:
                  /^(https?:\/\/)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(:[0-9]+)?(\/[^\s]*)?$/,
                message: "Invalid URL format",
              },
              maxLength: {
                value: 75,
                message: "Website URL must not exceed 75 characters",
              },
            })}
            id="login-google_reviews-outlined"
            autoFocus
            label="google_reviews address"
            type={"text"}
            error={errors.google_reviews}
            variant="outlined"
          />
          <PrimaryNormalFormHelperText error={errors.google_reviews}>
            {errors?.google_reviews ? "Enter valid url" : "GooglereviewsUrl is an optional field"}
          </PrimaryNormalFormHelperText>
        </FormControl>
      </Stack>
    </Stack>
  );
}
