import React, { useEffect, useState } from "react";
import "../../assets/css/login/login.css";
import { Grid } from "@mui/material";
 
import AuthLeftPannel from "./Components/AuthLeftPannel";
import LoginAuthRightPannel from "./Components/LoginAuthRightPannel";

export default function PartnerLogin() {
 
  return (
    <React.Fragment>
       <Grid container
                sx={{
                    minHeight: "100vh",
                    margin: "auto",
                }}
            >
                {/* Auth Left Section */}
                <Grid item xs={12} sm={5}
                    sx={{
                        padding: "5px",
                        "@media (max-width: 600px)": {
                            display: "none"
                        },
                    }}
                >
                    <AuthLeftPannel />
                </Grid>

                {/* Auth Right Section */}
                <Grid item xs={12} sm={7} sx={{ padding: "5px" }}>
                    <LoginAuthRightPannel />
                </Grid>
            </Grid>
    </React.Fragment>
  );
}
