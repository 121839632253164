/*
Sourced - 0
Applied - 1
Screened - 2
Technical Evaluation(Internal) - 3
Moderated - 4
Ready For Submission - 5
Pre-Evaluated - 6
Submitted - 7
Shortlisted - 8
Technical Evaluation (Company) - 9
Technical Evaluation (Internal) - 10
Evaluated - 11
Offered - 12
BGV (Internal) -13
BGV (External) - 14
Verified - 15
On-Boarded - 16
Rejected - 17
*/

export const pipelineStages = [
  "Sourced",
  "Applied",
  "Screened",
  "Technical Evaluation(Internal)",
  "Moderated",
  "Ready For Submission",
  "Pre-Evaluated",
  "Submitted",
  "Shortlisted",
  "Technical Evaluation (Company)",
  "Technical Evaluation (Internal)",
  "Evaluated",
  "Offered",
  "BGV (Internal)",
  "BGV (External)",
  "Verified",
  "On-Boarded",
  "Rejected"
]

export const roleRestrictions = {
  CA: [0,2,7,8,9,10,11,12,13,14,15,16],
  CS: [0,2,7,8,9,10,11,12,13,14,15,16],
  ACM: [
    0,
    2,
    4,
    5,
    6,
    7,
    8,
    12,
    13,
    14,
    16
  ],
  TE: [2,3,10,11,16],
  SP: [0,1,2,16],
  MODERATOR: [3,4,16],
  // RC: ["Sourced", "Applied", "Moderated", "Rejected"],
  // RS: ["Sourced", "Applied", "Moderated", "Rejected"],
  // BVC: ["BGV", "Verified", "Rejected"],
  // BVS: ["BGV", "Verified", "Rejected"],
  ZA: [0,2,7,8,16],
};


export const roleRestrictionsForHash = {
  CA: [0,7,8,9,10,11,12,13,14,15,16],
  CS: [0,7,8,9,10,11,12,13,14,15,16],
  ACM: [
    0,
    2,
    4,
    5,
    6,
    7,
    8,
    12,
    13,
    14,
    16
  ],
  TE: [2,3,10,11,16],
  SP: [0,1,2,16],
  MODERATOR: [3,4,16],
  RC:[0,17],
  RS:[0,17],
  BVC:[13,14,15,16,17],
  BVS:[13,14,15,16,17],
  // RC: ["Sourced", "Applied", "Moderated", "Rejected"],
  // RS: ["Sourced", "Applied", "Moderated", "Rejected"],
  // BVC: ["BGV", "Verified", "Rejected"],
  // BVS: ["BGV", "Verified", "Rejected"],
  ZA: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17],
}

export const sizes = [
  // { name: "1-50", value: "1-50" },
  // { name: "50-100", value: "50-100" },
  // { name: "100-200", value: "100-200" },

  { name: "1-50", value: "1-50" },
  { name: "51-200", value: "51-200" },
  { name: "201-500", value: "201-500" },
  { name: "501-1000", value: "501-1000" },
  { name: "1001-5000", value: "1001-5000" },
  { name: "5001-10000", value: "5001-10000" },
  { name: "10000+", value: "10000+" },
];

export const companytypes = [
  { name: 'Startup', value: 'Startup' },
  { name: 'MSME', value: 'MSME' },
  { name: 'SME', value: 'SME' },
  { name: 'Enterprise', value: 'Enterprise' },
  { name: 'INC', value: 'INC' },
  { name: 'LLC', value: 'LLC' },
  { name: 'S Corp', value: 'S Corp' },
  { name: 'C Corp', value: 'C Corp' },
  { name: 'Public', value: 'Public' },
  { name: 'Private Limited', value: 'Private Limited' },
  { name: 'Seed', value: 'Seed' },
  { name: 'Angel', value: 'Angel' },
  { name: 'Venture', value: 'Venture' },
  { name: 'Series A', value: 'Series A' },
  { name: 'Series B', value: 'Series B' },
  { name: 'Series C', value: 'Series C' },
  { name: 'Series D', value: 'Series D' },
  { name: 'Series E', value: 'Series E' },
  { name: 'Series F', value: 'Series F' },
  { name: 'Debt Financing', value: 'Debt Financing' },
  { name: 'Equity Crowdfunding', value: 'Equity Crowdfunding' },
  { name: 'Convertible Note', value: 'Convertible Note' },
  { name: 'Private Equity', value: 'Private Equity' },
  { name: 'Other', value: 'Other' }
];

export function stageZA(stage) {
  switch (stage) {
    case 0:
      return [2, 8, 17]  
    case 7:
      return [8, 17]
    case 8:
      return[17]
    default:
      return [];
  }
}

export function stageCompany(stage) {
  switch (stage) {
    case 0:
      return [2,8,9,10,12,14,15,16,17]
    case 7:
      return [8,9,10,12,14,15,16,17]
    case 8:
      return [9,10,12,14,15,16,17]
    case 9:
      return [10, 12, 14, 15, 16, 17]
    case 11:
      return [12,14,15,16,17]
    case 12:
      return [13,17]
    case 13:
      return [15,16,17]
    case 14:
      return [15,16,17]
    case 15:
      return [16,17]
    default:
      return [];
  }
}

export function stageSP(stage) {
  switch (stage) {
    case 0:
      return [2,17]
    case 1:
      return [2,17]
  
    default:
      return []
  }

}

export function stageTE(stage) {
  switch (stage) {
    case 2:
      return [3,17]
    case 10:
      return [11,17]  
    default:
      return []
  }
}

export function stageModerator(stage) {
  switch (stage) {
    case 3:
      return [4,17]
    default:
      return []
  }
}

export function stageACM(stage) {
  switch (stage) {
    case 0:
      return [2,5,7,17]
    case 4:
      return [5,7,17]
    case 5:
      return [7,17]
    case 12:
      return [14,17]
    default:
      return []
  }
}

export function stageBG(stage) {
  switch (stage) {
    case 13:
      return [15,17]
    case 14:
      return [15,17]
    default:
      return []
  }
}

