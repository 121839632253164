import { useMemo, useState,useEffect,useRef } from "react";
import CompanyStepper from "../../../components/FollowUps/Company/stepper";
import FirstCompanyFollowUps from "../../../components/FollowUps/Company/steps/FirstFollowup";
import { FormProvider, useForm } from "react-hook-form";
import { PrimaryButton, SecondaryFilledButton } from "assets/Mui/globalTheme";
import SecondCompanyFollowUps from "../../../components/FollowUps/Company/steps/SecondFollowup";
import { Paper, Stack } from "@mui/material";
import ThirdFollowUps from "../../../components/FollowUps/Company/steps/thirdFollowup";
import { useHistory, useLocation } from "react-router-dom";
import { updateCompanies } from "helpers/services/companies/company";
import ErrorHandlingToaster from "helpers/Toaster/error";
import SocketConnection from "hooks/sockets";
import { toast } from "react-hot-toast";

export default function CompanyFollowUps() {
  const location = useLocation();
  const history = useHistory();
  const formRef = useRef(null);
  useMemo(() => {
    if (location?.state?.from !== "/signup") {
      history.push("/");
    }
  }, [location]);
  const [activeStep, setActiveStep] = useState(0);
  const [move, setMove] = useState(false)

  // useEffect(() => {

  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // }, [move]);

  const handleStatus = ()=>{
    setMove(true)
  }

  function findstep(active) {
    switch (active) {
      case 0:
        return <FirstCompanyFollowUps setMoveStatus={handleStatus}/>;
      case 1:
        return <SecondCompanyFollowUps />;
      case 2:
        return <ThirdFollowUps />;
    }
  }

  const methods = useForm({
    defaultValues: {
      culture_media_images: [],
      industry: [],
    },
  });
  function handleBack() {
    if (activeStep > 0) setActiveStep(activeStep - 1);
  }
  const onSubmit = async data => {
    if (activeStep === 2) {
      const updatedData = await updateCompanies({
        ...data,
        industry: [data?.industry],
        is_active: true,
        first_name: localStorage.getItem("first_name"),
        last_name: localStorage.getItem("last_name"),
        culture_media_video_url: "youtube.com?v=123",
        update_type_to_send_email: null,
      });
      ErrorHandlingToaster(updatedData);

      if (updatedData?.status === 200) {
        toast.success("Company is Registered Successfully");
        localStorage.clear();
        history.push("/login");
      }
      return;
    }

    setActiveStep(activeStep + 1);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        minHeight: "100vh",
        padding: "20px 40px",
      }}
    >
      <div style={{ width: "50%", margin: "auto", marginBottom: "40px" }}>
        <CompanyStepper activeStep={activeStep} />
      </div>
      <FormProvider {...methods}>
        <form
          // style={{ padding: "0px 50px", overflow: "scroll", height: "72vh" }}
          style={{ padding: "0px 50px",  height: "72vh" }}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {findstep(activeStep)}
          <Stack
            // sx={{ position: "absolute", bottom: "5px", right: "80px" }}
            direction="row"
            alignItems={"center"}
            justifyContent={"flex-end"}
            spacing={2}
            my={2}
          >
            {activeStep !== 0 && (
              <SecondaryFilledButton
                sx={{ width: "100px", color: "#D60D0D" }}
                type="button"
                onClick={handleBack}
              >
                back
              </SecondaryFilledButton>
            )}

            <PrimaryButton sx={{ width: "100px" }} type="submit">
              {activeStep === 2 ? "Submit" : "Next"}
            </PrimaryButton>
          </Stack>{" "}
        </form>
      </FormProvider>
    </Paper>
  );
}
