import OtpInput from "react18-input-otp";
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  GreyTypography,
  PrimaryButton,
  PrimaryTypography,
} from "assets/Mui/globalTheme";
import ErrorHandlingToaster from "helpers/Toaster/error";
import { handleResendOTP } from "helpers/services/otpVerifiy/verify";
import { useHistory,useNavigation } from "react-router-dom";
import { parseNumberInput } from "hooks/customPhoneNumberParse";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  backdropFilter: blur("4px"),
  p: 4,
};

export default function BasicModal({
  isOpen,
  toggle,
  handleLogin,
  phone_number,
  email,
}) {
  const [otp, setOtp] = React.useState("");
  const [min, setMin] = React.useState(5);
  const [sec, setSec] = React.useState(0);
  const [hasError, setHassError] = React.useState(false);
  const [otpStatus, setOtpStatus] = React.useState(200);
  const [hitCount, setHitCount] = React.useState(0)
  const history = useHistory();
  const handleOTP = async () => {
    const { status, data } = await handleLogin(otp);
    if (!status) {
      setHassError(true);
      ErrorHandlingToaster({ status, data });
      return;
    }
    setHassError(false);
    toggle();
    // history.push("/login")
  };
  const handleChange = otp => setOtp(otp);
 





  React.useEffect(() => {
    let intervalId;
  
    // Start the timer only if the modal is open and the timer is not already running
    if (isOpen && min >= 0 && sec >= 0) {
      intervalId = setInterval(() => {
        if (min === 0 && sec === 0) {
          clearInterval(intervalId);
          return;
        }
  
        if (sec > 0) {
          setSec(sec - 1);
        } else {
          setMin(min => min - 1);
          setSec(59);
        }
      }, 1000);
    } else {
      // Clear the interval when the modal is closed
      clearInterval(intervalId);
    }
  
    return () => clearInterval(intervalId);
  }, [sec, min, isOpen]);
  
  
  
  React.useEffect(() => {
    if (isOpen) {
      setMin(5);
      setSec(0);
    }
  }, [isOpen]);




  

  React.useEffect(()=>{
    if(hitCount == 3){
      setTimeout(()=>{
        setHitCount(0)
        toggle()
      },1000)
    
    }
  },[hitCount])

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="modal-body">
            <div className="otp_parent">
              <div className="phone_no">
                <span className="enter_otp">Enter OTP</span>
              </div>
              <PrimaryTypography variant="h3" className="otp_imp">
                {/* {min}:{sec} */}
                {`${min.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}`}
              </PrimaryTypography>
              <GreyTypography variant="h6" style={{ textAlign: "center" }}>
                We have send you a one time password in this mobile number
              </GreyTypography>
              <PrimaryTypography variant="h4" style={{ textAlign: "center" }}>
                {parseNumberInput(phone_number)}
              </PrimaryTypography>
              <div className="otp_columns">
                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  shouldAutoFocus
                  isInputNum={true}
                  inputStyle="otp_input_items"
                  errorStyle={"otp_input_items_error"}
                  hasErrored={hasError}
                  focusStyle={"otp_input_items_focus"}
                  separator={<span> </span>}
                  onSubmit={handleOTP}
                />
              </div>
              <div className="help_otp">
                {otpStatus !== 400 ? (
                  <button
                    onClick={async () => {
                      if(hitCount == 3){
                        setHitCount(hitCount+1)
                      }else if(hitCount < 3) {
                        setHitCount(hitCount+1)
                      }
                      const { status } = await handleResendOTP(email);
                      setMin(5)
                      setSec(0)
                      setOtpStatus(status);
                
                   
                      if (status === 400) {
                        toast.error("Otp Limit Expired");
                   
                      }
                      else if(status === 201){
                        toast.success("Otp sent successfully!")
                      }
                    }}
                    className="btn"
                    to="/login"
                  >
                    <span className="info_txt">Didn&rsquo;t Received Otp?{" "}</span>
                    <span>Resend OTP</span>
                  </button>
                ) : (
                  ""
                )}
                <PrimaryButton
                  // className={` ${otp.length === 6 ? "" : ""}`}
                  disabled={otp.length === 6 ? false : true}
                  type="submit"
                  onClick={handleOTP}
                >
                  Verify OTP
                </PrimaryButton>
                <p className="info_txt">You only have 3 tries In a day</p>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
