import { CircularProgress, Grid, Stack } from "@mui/material";
import {
  BorderCard,
  GrayDivider,
  GreyTypography,
  PrimaryAvatar,
  PrimaryDivider,
  PrimaryTypography,
} from "assets/Mui/globalTheme";
import { Link } from "react-router-dom";
import TimeLineHeader from "./header";
import { useEffect, useState } from "react";
import { getTimeLineDetails } from "helpers/services/kanban/getKanbanDetails";
import SearchParamHook from "hooks/searchParams";
import { imageURL } from "common/constants/commonURLS";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { socket } from "Sockets/socket";

export default function TimeLine() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.Loading.loading);
  const searchParams = SearchParamHook();
  useEffect(() => {
    dispatch({ type: "START_LOADING" });

    const userJobPostId = searchParams.get("user_job_post_id");
    if (userJobPostId) fetchTimeLineDetails(userJobPostId);
  }, [searchParams]);
  const [timelineDetails, setTimeLineDetails] = useState([]);
  async function fetchTimeLineDetails(userJobPostId) {
    const { data, status } = await getTimeLineDetails(userJobPostId);
    if (status === 200) {
      // data?.data?.splice(0, 1);
      const sortedData = data?.data_payload?.sort((a, b) => {
        const dateA = new Date(a.updatedAt);
        const dateB = new Date(b.updatedAt);
        return dateB - dateA; 
      });
      setTimeLineDetails(sortedData);
      // setTimeLineDetails(data?.data);
      dispatch({ type: "STOP_LOADING" });
    }
  }
  if (loading) {
    return (
      <div style={{ display: "grid", placeItems: "center" }}>
        <CircularProgress />
      </div>
    );
  }


  // const handleListnerEvent = (socketData) => {
  //   if (socketData?.type === "timeline") {
  //     const userJobPostId = searchParams.get("user_job_post_id");
  //     if (userJobPostId) fetchTimeLineDetails(userJobPostId);
  //   }
  // };

  // useEffect(() => {
  //   const handleReconnect = () => {
  //     // Re-register the event listener after reconnection if needed
  //     socket.on("listnerEvent", handleListnerEvent);
  //   };

  //   socket.on("listnerEvent", handleListnerEvent);

  //   // Attach a listener for socket reconnection
  //   socket.on("reconnect", handleReconnect);

  //   return () => {
  //     console.log("Removing event listener");
  //     socket.off("listnerEvent", handleListnerEvent);
  //     socket.off("reconnect", handleReconnect);
  //   };
  // }, []);

  

  return (
    <>
      <BorderCard sx={{ padding: "20px" }}>
        <TimeLineHeader timeLineCount={timelineDetails?.length} />
        {timelineDetails?.map((item, index) => (
          <div key={item?.id}>
            <Grid container alignItems={"center"} justifyContent={"flex-start"}>
              <Grid item xs={12} md={1}>
                <PrimaryAvatar src={`${imageURL}${item?.user?.image}`}>
                  {item?.user?.user_name?.charAt("0")}
                </PrimaryAvatar>
              </Grid>
              <Grid item xs={12} md={7} sx={{ textAlign: "start" }}>
                <GreyTypography variant="h6">
                  <Stack spacing={1}>
                    <PrimaryTypography variant="h5">
                      {item?.user?.name}
                    </PrimaryTypography>{" "}
                    updated the candidate{" "}
                    {index !== 0 ? (
                      <>
                        <PrimaryTypography variant="h5">
                          old stage:{item.old_value}{" "}
                        </PrimaryTypography>
                        <PrimaryTypography variant="h5">
                          new stage:{item?.new_value}{" "}
                        </PrimaryTypography>
                      </>
                    ) : null}
                    <PrimaryTypography variant="h5">
                      description:{item?.description}{" "}
                    </PrimaryTypography>
                  </Stack>
                </GreyTypography>
              </Grid>
              <Grid item xs={12} md={4}>
                <PrimaryTypography variant="h5">
                  {item?.user?.user_name}
                </PrimaryTypography>
                <PrimaryTypography variant="h6">
                  {moment(item?.updatedAt)?.format(
                    "dddd, MMMM Do YYYY, h:mm:ss a"
                  )}
                </PrimaryTypography>
              </Grid>
            </Grid>
            <GrayDivider sx={{ margin: "10px 0px" }} />
          </div>
        ))}
      </BorderCard>
    </>
  );
}
