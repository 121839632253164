import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row, Container, Button } from "reactstrap";
import SelectJobType from "../JopPost/JobTypeSelection/JobTypesSelection";
import { useParams } from "react-router";
import { getSingleJob, updateJobPost } from "helpers/services/jobs/jobs";
import { connect } from "react-redux";
import { deleteJob, updateJob } from "store/jobs/actions";
import JobTitle from "../JopPost/JobTitle";
import JobLocation from "../JopPost/Location/JobLocation";
import JobAdditionDetails from "../JopPost/Step2/JobAdditionDetails";
import HireInDay from "../JopPost/HireInDay/HireInDay";
import JobSkills from "../JopPost/Skills/Skills";
import JobPostQuestion from "../JopPost/JobPostQuestion";
import createjobModel from "helpers/models/createJobModel";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import "./JobEdit.css";
import {
  ContentState,
  convertFromHTML,
  EditorState,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { toast } from "react-hot-toast";

const JobEdit = ({ updateJob, data, history }) => {
  const { id } = useParams();
  const [error, setError] = useState({
    time_zone: false,
    job_title: false,
    currency: false,
    min_sal: false,
    max_sal: false,
  });
  const [jobJD, setJobJD] = useState("");
  const [jobReq,setJobReq] = useState('')
  useEffect(() => {
    let mounted = true;
    async function dataFetcher() {
      await deleteJob();
      const data = await getSingleJob(id);
      setJobJD(data.job_desc);
      setJobReq(data.requirements)
      // setJobDetails(data);
      const questions = data.job_screening_questions_details.map(
        item => item.question
      );
      const deadline = date => {
        const value = new Date(date);
        const year = value.getFullYear();
        const month = value.getMonth() + 1;
        const day = value.getDate();
        return `${year}-${month}-${day < 10 ? `0${day}` : day}T00:00`;
      };
      const job_roles_parse = item => {
        const job_roles = item.map(item => item.id);
        return job_roles;
      };
      const key_skills = item => {
        const skills = item.map(item => ({ value: item.id, label: item.name,isActive:item.is_active }));
        return skills;
      };
      const findEducationalQualfificationAndSpec = item => {
        return item.educational_qualification_details.map(data => {
          return {
            education_qualification_id: data?.education_qualification_id?.id,
            education_qualification_spec_id:
              data?.education_qualification_spec_id?.id,
            // education_qualification_spec_name:data?.education_qualification_spec_id?.name
          };
        });
      };
      updateJob({
        job_title: data.job_title,
        job_type: data.job_type,
        min_exp: data.min_exp,
        max_exp: data.max_exp,
        min_exp_months: data.min_exp_months,
        max_exp_months: data.max_exp_months,
        location: data.location,
        work_mode: data.work_mode,
        job_desc: data.job_desc,
        requirements:data.requirements,
        functional_areas: [data.functional_area_details[0].id],
        job_roles: job_roles_parse(data.job_roles_details),
        no_of_vacancies: data.no_of_vacancies,
        currency: data.currency,
        application_deadline: data.application_deadline,
        time_zone: data.time_zone,
        min_sal: data.min_sal,
        key_skills: key_skills(data.key_skills_details),
        max_sal: data.max_sal,
        commission_rate_type: data.commission_rate_type,
        commission_rate: data.commission_rate,
        educational_qualifications: findEducationalQualfificationAndSpec(data),
        attachement: "s3_url",
        screening_questions: [...questions],
        hours_per_week: data.hours_per_week,
        project_duration: data.project_duration,
      });
    }
    mounted ? dataFetcher() : null;
    return () => {
      mounted = false;
    };
  }, []);
  const defaultJobDesc = jobJD;
  const defaultJobRequirements =jobReq
  const [editorData, setEditorData] = useState(() => EditorState.createEmpty());
  const [editorRequirementData, setEditorRequirementData] = useState(() => EditorState.createEmpty());
  const handleEditorData = e => {
    setEditorData(e);
    updateJob({
      job_desc: draftToHtml(convertToRaw(editorData.getCurrentContent())),
    });
  };
  const handleRequirementData = e => {
    setEditorRequirementData(e);
    updateJob({
      requirements: draftToHtml(convertToRaw(editorRequirementData.getCurrentContent())),
    });
  };
  useEffect(() => {
    if (defaultJobDesc !== "") {
      const contentBlock = htmlToDraft(defaultJobDesc);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorData(editorState);
      }
    }
  }, [defaultJobDesc]);
  useEffect(() => {
    if (Boolean(defaultJobRequirements)) {
      const contentBlock = htmlToDraft(defaultJobRequirements);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorRequirementData(editorState);
      }
    }
  }, [defaultJobRequirements]);
  return (
    <div>
      <Container fluid id="jobpostmain-container">
        <Row id="jobpost-row-cont">
          <Col xs={12} style={{ width: "99%", margin: "auto" }}>
            <h3 style={{ textAlign: "center", padding: "5px",color:"#024430" }}>
              Let's Edit your Job posting
            </h3>
          </Col>
          <Col xs={12}>
            <Row style={{ width: "99%", margin: "auto",color:"#024430" }}>
              <Card>
                <CardBody className="bottom_div_margin_top">
                  <JobTitle error={error} />
                  <SelectJobType error={error} />
                  <JobLocation error={error} />
                  <JobAdditionDetails error={error} edit={true} />
                  <div>
                    <h5>Description</h5>
                    <Editor
                      // defaultEditorState={deafaultValue}
                      editorState={editorData}
                      onEditorStateChange={handleEditorData}
                    />
                  {defaultJobRequirements && <>
                      <h5>Requirements</h5>
                      <Editor
                        // defaultEditorState={deafaultValue}
                        editorState={editorRequirementData}
                        onEditorStateChange={handleRequirementData}
                      />                    
                    </>}
                    <JobSkills error={error} />
                    <JobPostQuestion />
                  </div>
                  <HireInDay error={error} edit={true} />
                  <div className="align-content-center align-item-center">
                    <button
                      outline
                      color="primary"
                      className="job-edit-submit-btn"
                      onClick={async () => {
                        const { status, data: errors } = await updateJobPost(
                          id,
                          createjobModel(data)
                        );
                        if ([200,201].includes(status)) {
                          toast.success(errors?.message);
                        }else{
                          toast.error(errors?.message);
                          return;
                        }
                        history.push("/joblist/pending");
                      }}
                      style={{ margin: "auto" }}
                    >
                      Submit
                    </button>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
const mapStateToProps = ({ Jobs }) => ({
  data: Jobs,
});

const mapDispatchToProps = dispatch => ({
  updateJob: e => dispatch(updateJob(e)),
  deleteJob: () => dispatch(deleteJob()),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JobEdit)
);
