import { Tabs } from "@mui/material";
import {
  PrimaryTab,
  StyledTabScrollButtonObject,
  StyledTabsObject,
} from "assets/Mui/globalTheme";
import SearchParamHook from "hooks/searchParams";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { TE,SP,MODERATOR,RC,RS,ACM,ZA,CA,CS,BVC,BVS} from "common/constants/userModules";

function getNavTabs(role){
  const tabs = [
    { name: "Summary" },
    { name: "Verified Summary" },
    { name: "Resume" },
    { name: "Evaluations" },
    { name: "Scorecard" },
    { name: "Chat" },
    { name: "Notes" },
    { name: "Timeline" },
    // { name: "Attachments" },

  ]
  if(![TE,SP,MODERATOR,ACM,ZA,CA,CS,BVC,BVS,RC,RS].includes(role)){
    tabs.splice(2,1)
  }else{
    // tabs.pop()
  }
  return tabs
}

export default function UserJobTabs() {
  const role = useSelector(state => state.Login.role);
  const [navigators, setNavigators] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const history = useHistory();
  const searchParams = SearchParamHook();
  useEffect(() => {
    if (searchParams?.get("tab")) {
      setSelectedTab(Number(searchParams.get("tab")));
    }
  }, [searchParams]);
  useEffect(() => {
    setNavigators(getNavTabs(role));    
  }, [role]);
  async function handleNavChange(id) {
    setSelectedTab(id);
    searchParams.set("tab", id);
    history.replace({ search: searchParams.toString() });
  }
  return (
    <Tabs
      value={selectedTab}
      sx={{
        borderBottom: "0.5px solid rgba(0,0,0,0.1)",
      }}
      onChange={(e, id) => handleNavChange(id)}
      textColor="primary"
      TabIndicatorProps={StyledTabsObject}
      TabScrollButtonProps={StyledTabScrollButtonObject}
      indicatorColor="primary"
      aria-label="user job post details tabs"
    >
      {navigators.map(({ name }, index) => {
        return (
          <PrimaryTab
            disableRipple={true}
            sx={{ fontWeight: "500", height: "10px"}}
            value={index}
            iconPosition="start"
            key={index}
            label={name}
          />
        );
      })}
    </Tabs>
  );
}
