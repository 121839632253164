import { PUT } from "common/constants/methods";
import callApi from "helpers/callApi";

export const getPipeLineStagesBasedOnPostId = async id => {
  return await callApi(`get_applicants_in_kanban?job_post_id=${id}`);
};
export const updateFromPipeline = async(id, stageId, fromId) => {

  return await callApi(
    `get_applicants_in_kanban/${id}`,
    { stage_id: stageId, old_stage_id: fromId },
    PUT
  );
};

export const updateFromPipelineModel = async(id, data) => {

  return await callApi(
    `get_applicants_in_kanban/${id}`,
    data,
    PUT
  );
};

export const getTimeLineDetails = async id => {
  return await callApi(`get_applicants_in_kanban/timelines/${id}`);
};
export const updateUserDetailsInKanban = async (data, id) => {
  return await callApi(
    `get_applicants_in_kanban/update_job_applicant_details/${id}`,
    data,
    PUT
  );
};

export const getAllStages = async userJobPostID => {
  return await callApi(`user_job_post_update?id=${userJobPostID}`);
};
