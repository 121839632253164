import callApi from "helpers/callApi";

export const forgetPassword = async email => {
  return await callApi(`/common/forgotpassword/${email}`);
};
export const resetPassword = async data => {
 return await callApi(
    `/common/resetpassword`,
    { password: data?.password },
    "PATCH",
    {
      token: data?.token,
    }
  );
};
