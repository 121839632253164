import { Grid, Stack } from "@mui/material";
import ChatUserDetails from "./chatUser";
import ChatDetailedPage from "../../../Chat/chatDetailedPage";
import SearchParamHook from "hooks/searchParams";
import SocketConnection from "hooks/sockets";
import { useState,useEffect } from "react";
import { viewSingleUserDetailsInKanbanBoard } from "helpers/services/referalPartner";
import { getCandidateChatAttachments } from "helpers/services/chats/evalautorCharts";

export default function UserJobPostChat({profileReadingStatus}) {
  const { lastMessage,connectionStatus } = SocketConnection();
  const searchParams = SearchParamHook()
  const [candidate,setCandidate] = useState(null)
  const userId = searchParams.get("user_id");
  const jobPostId = searchParams.get("job_id");
  const [media,setMedia] = useState([])
  useEffect(()=>{
    getImages()
  },[lastMessage])

  useEffect(()=>{},[connectionStatus])
  async function getImages(){
    const { data:userData } = await viewSingleUserDetailsInKanbanBoard(
      userId,
      jobPostId
    );
   
    const type = 'image'
    const ExtractData = {
      channelId:userData?.data_payload?.user_job_post_details?.channel_details_id?.id,
      type
    }
    if(userData?.data_payload?.user_job_post_details?.channel_details_id?.id){
      const res = await getCandidateChatAttachments(ExtractData)
      const {data:result} = res?.data
      const modifiedData = res?.data?.data_payload?.map((item)=>({
        id:item.id,
        type:item.message_type,
        pic:item.message
      }))
      setMedia(modifiedData)
      setCandidate(userData)
    }
  
  }
  return (
    <Grid container direction="row">
      <Grid item xs={12} md={8}>
        <ChatDetailedPage getImages={getImages}  />
      </Grid>
      <Grid height={'59vh'} item xs={12} md={4}>
        <ChatUserDetails media={media} candidate={candidate} />
      </Grid>
    </Grid>
  );
}
