import { POST, PUT } from "common/constants/methods";
import callApi from "helpers/callApi";

export async function getAllEducationalQualifications(page, rowsPerPage,searchText) {
  const data = await callApi(`job_attributes/educational_qualification?pageSize=${rowsPerPage}&pageNo=${page}&searchParam=${searchText}`);
  const items = data.data;
  return items;
}
export async function createEducationalQualifications(incomingData) {
  const data = {
    name: incomingData.name,
    country: "INDIA",
  };
  const result = await callApi(
    "job_attributes/educational_qualification",
    data,
    POST
  );
  return result;
}
export async function getAllEducationalSpecialization(page, rowsPerPage,searchText) {
  const data = await callApi(`job_attributes/educational_qualification_specs?pageSize=${rowsPerPage}&pageNo=${page}&searchParam=${searchText}`);
  return data.data;
}

export async function updateEducationSpecialization(incomingData) {
 
  const specId = incomingData?.specId
  const data = {
    educational_qualification_id: incomingData.id,
    name: incomingData.name,
  };
  const result = await callApi(
    `job_attributes/educational_qualification_specs/${specId}`,
    data,
    PUT
  );
  return result.data;
}

export async function createEducationalSpecialization(incomingData) {
  const data = {
    educational_qualification_id: incomingData.id,
    name: incomingData.name,
  };
  const result = await callApi(
    "job_attributes/educational_qualification_specs",
    data,
    POST
  );
  return result.data;
}

export async function getEducationalSpecializationWithQualification(id) {
  if (id === undefined) return;
  const data = await callApi(
    `job_attributes/educational_qualification_specs/get_list_based_on_ed_ql_id?educational_qualification_id=${id}`
  );
  return data.data;
}

export async function updateEducationalSpecialization(id, incomingData) {
  if (id === undefined) return;
  const data = {
    ...incomingData,
  };
  const result = await callApi(
    `job_attributes/educational_qualification_specs/${id}`,
    data,
    PUT
  );
  return result.data;
}
export async function updateEducationalQualification(id, incomingData) {
  if (id === undefined) return;
  const data = {
    is_active: incomingData.is_active,
    ...incomingData,
  };
  const result = await callApi(
    `job_attributes/educational_qualification/${id}`,
    data,
    PUT
  );
  return result.data;
}
