import { Chip, Divider, Stack } from "@mui/material";

import { Modal } from "@mui/material";
import React from "react";

import {
  GrayDivider,
  PrimaryChip,
  SecondaryFilledChip,
  StyledSecondaryTypography,
  StyledTootlTip,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Tooltip } from "@mui/material";

export default function JobCardInfo({ userData }) {


  const salary =
    userData?.user_detail_data?.current_salary?.[0]?.current_salary !== null &&
    userData?.user_detail_data?.current_salary?.[0]?.current_salary !==
      undefined
      ? userData?.user_detail_data?.current_salary?.[0]?.current_salary >= 1000
        ? `${
            userData?.user_detail_data?.current_salary?.[0]?.current_salary /
            1000
          }k`
        : userData?.user_detail_data?.current_salary?.[0]?.current_salary
      : "N/A";

  return (
    <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
      <Stack direction="row" spacing={1}>
        <div className="d-flex align-items-center gap-1">
          <StyledTootlTip position="top" title={salary}>
            {/* Sal: */}
            <StyledSecondaryTypography
              variant="h6"
              component="h6"
              sx={{ fontSize: "14px", color: "rgba(108, 108, 108, 1)" }}
            >
              {/* {userData?.user_detail_data?.current_salary?.[0].current_salary !== (null || undefined) ?userData?.user_detail_data?.current_salary?.[0].current_salary: "N/A"} */}

              {salary}
            </StyledSecondaryTypography>
          </StyledTootlTip>
        </div>
        <GrayDivider orientation="vertical" flexItem />
        <div className="d-flex align-items-center gap-1">
          <StyledTootlTip
            position="top"
            title={
              userData?.user_detail_data?.current_company
                ? userData?.user_detail_data?.current_company
                : ""
            }
          >
            <StyledSecondaryTypography
              variant="h6"
              component="h6"
              sx={{
                width: "auto",
                maxWidth: "60px",
                fontSize: "14px",
                color: "rgba(108, 108, 108, 1)",
              }}
            >
              {userData?.user_detail_data?.current_company?userData?.user_detail_data?.current_company?.slice(0,8): "N/A"}
            
            </StyledSecondaryTypography>
          </StyledTootlTip>
        </div>
        <GrayDivider orientation="vertical" flexItem />
        <div className="d-flex align-items-center gap-1">
          <Tooltip
            placement="top"
            title={`${
              (userData?.user_detail_data?.notice_period &&
                userData?.user_detail_data?.notice_period) ||
              0
            } Notice`}
          >
            <StyledSecondaryTypography
              variant="h6"
              component="h6"
              sx={{
                width: "auto",
                maxWidth: "60px",
                fontSize: "14px",
                color: "rgba(108, 108, 108, 1)",
              }}
            >
              {userData?.user_detail_data?.notice_period
                ? `${userData?.user_detail_data?.notice_period} Notice`
                : "N/A"}
            </StyledSecondaryTypography>
          </Tooltip>
        </div>

        <GrayDivider orientation="vertical" flexItem />
        <div className="d-flex align-items-center gap-1">
          <Tooltip
            placement="top"
            title={
              userData?.user_detail_data?.experience
                ? userData?.user_detail_data?.experience
                : ""
            }
          >
            <StyledSecondaryTypography
              variant="h6"
              component="h6"
              sx={{
                width: "auto",
                maxWidth: "60px",
                fontSize: "14px",
                color: "rgba(108, 108, 108, 1)",
              }}
            >
              {userData?.user_detail_data?.experience
                ? userData?.user_detail_data?.experience
                : "N/A"}
            </StyledSecondaryTypography>
          </Tooltip>
        </div>
      </Stack>
    </Stack>
  );
}
