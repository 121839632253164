import { useState } from "react";
import UserJobPostNotesHeader from "./header";
import UserJobPostNoteSection from "./notes";
import {v4 as uuid} from "uuid"
import { useSelector } from "react-redux";
import { socket } from "Sockets/socket";
import SearchParamHook from "hooks/searchParams";

export default function UserJobPostNotes() {
  const notesV = useSelector(state => state.Notes?.notes);


  const [checkNotes, setCheckNotes] = useState([
    {
      subject: "Yamunda",
      id: uuid(),
      notes: `<p><span style="color: rgb(71,77,106);background-color: rgb(255,255,255);font-size: 14px;font-family: Satoshi, "Satoshi Fallback;">The</span> <strong>Senior</strong> <strong>Software</strong> <strong>Developer</strong> <span style="color: rgb(71,77,106);background-color: rgb(255,255,255);font-size: 14px;font-family: Satoshi, "Satoshi Fallback;">will be based in</span> <strong>Hyderabad</strong><span style="color: rgb(71,77,106);background-color: rgb(255,255,255);font-size: 14px;font-family: Satoshi, "Satoshi Fallback;">. In this position, you will report to the Vice President -Global Design Applications. Nextracker, the worldwide leader in solar trackers, is looking for an experienced full-stack software developer to support and contribute to the software development lifecycle through planning, programming, testing, and delivering quality software. In the Nextracker, the Software Development team is a part of the Engineering Department and is charged with developing and delivering quality software solutions by collaborating with cross-functional teams. We have hundreds of thousands of devices in the field, controlling our solar trackers and advanced energy storage systems. Our data and analytics infrastructure support our maintenance operations and informs new product design. When you work with us, you will be making a positive, material impact on the solar capacity of the planet and learning how to scale outdoor IoT deployments in some very harsh environments.</span>&nbsp;</p>`,
      is_active: "",
      createdAt: "",
      updatedAt:new Date(),
      created_by_id: "",
      updated_by_id: "",
      user_job_post_id: "",
      job_post_id: "",
      notes_updtby: {
            email: "d@syoft.com",
            first_name: "admin.",
            last_name: "shifyt",
            user_name: "admin Zepul",
            phone_number: null,
            image: "img_1692505186632.png"
        },
    },
    // {
    //   subject: "",
    //   id: "2",
    //   notes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    //   is_active: "",
    //   createdAt: "",
    //   updatedAt:new Date(),
    //   created_by_id: "",
    //   updated_by_id: "",
    //   user_job_post_id: "",
    //   job_post_id: "",
    //   notes_updtby:  {
    //     email: "d@syoft.com",
    //     first_name: "admin.",
    //     last_name: "shifyt",
    //     user_name: "admin Zepul",
    //     phone_number: null,
    //     image: "img_1692505186632.png"
    // },
    // },
    // {
    //   subject: "",
    //   id: "3",
    //   notes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    //   is_active: "",
    //   createdAt: "",
    //   updatedAt:new Date(),
    //   created_by_id: "",
    //   updated_by_id: "",
    //   user_job_post_id: "",
    //   job_post_id: "",
    //   notes_updtby:  {
    //     email: "d@syoft.com",
    //     first_name: "admin.",
    //     last_name: "shifyt",
    //     user_name: "admin Zepul",
    //     phone_number: null,
    //     image: "img_1692505186632.png"
    // },
    // },
  ]);
  const [editorText,setEditorText] = useState(null)

  return (
    <>
      <UserJobPostNotesHeader editorText={editorText} setEditorText={setEditorText} setCheckNotes={setCheckNotes} />
      <UserJobPostNoteSection editorText={editorText} setEditorText={setEditorText} checkNotes={notesV} setCheckNotes={setCheckNotes} />
    </>
  );
}
