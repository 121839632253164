import * as React from "react";
import { useState, useEffect } from "react";
import {
  getAllReferalParterns,
  getRefsByCompany,
} from "helpers/services/referalPartner";
import { imageURL } from "common/constants/commonURLS";
import {
  PrimaryButton,
  PrimaryButtonForTable,
  PrimaryDataGrid,
  PrimarySwitch,
  SmallPrimaryAvatar,
  SmallSizeChip,
  DownloadButton,
  StyledOutlinedInput,
  CompanyTypography,
  CompanySizeChip,
  PendingJobChip,
  SkillsChip,
} from "assets/Mui/globalTheme";
import { FaDownload } from "react-icons/fa";
import InputAdornment from "@mui/material/InputAdornment";
import JobSearchIcon from "assets/images/icons/JobSearchIcon";
import TablePopper from "components/table/header/popper";
import LoaderIcon from "utils/icons/loaderIcon";
import { GenderOperators } from "components/table/operators/genderOperators";
import { LocationOperators } from "components/table/operators/locationOperators";
import { KeySkillOperator } from "components/table/operators/keySkillOperator";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { PrimaryChip } from "assets/Mui/globalTheme";
import SkillViewModal from "helpers/Modals/SkillViewModal";
import { StyledGridOverlay } from "assets/Mui/globalTheme";
import CustomNoRowsOverlay from "common/data/NoRowsFoundDatagrid/NoRowsFound";
import { IconButton, Pagination, Stack } from "@mui/material";
import { getAllKeySkillsWithStatus } from "helpers/services/jobAttributes/keyskills";
import { CA, CS, ZA } from "common/constants/userModules";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import RPModel from "./addReferalModal";
import ClipBoardData from "helpers/dashboard/clipboard";
import { useLocation } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";
import { StageSearchInput } from "assets/Mui/globalTheme";
import { useDebounce } from "hooks/useDebounce";
import SearchParamHook from "hooks/searchParams";
import ClearIcon from "@mui/icons-material/Clear";

import DownloadIcon from "@mui/icons-material/Download";
import downloadCsv from "download-csv";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { parseNumberInput } from "hooks/customPhoneNumberParse";
import { socket } from "Sockets/socket";
import CompanySizeIcon from "assets/images/icons/companySize";
import ClosedIcon from "assets/images/icons/closedIcon";
import TickIcon from "assets/images/icons/Tick";
import DoubleTickIcon from "assets/images/icons/totalJobs";
import BagIcon from "assets/images/icons/bagIcon";
import RedTickIcon from "assets/images/icons/redTickIcon";
import GlobalTableTools from "hooks/GlobalTableTools";
export default function ReferalPartnerTable() {
  const [openSearch, setOpenSearch] = useState(true);
  const [searchText, setSearchText] = useState("");
  const searchParamsHook = SearchParamHook();

  const [referalPartners, setReferalPartners] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [openSkillModal, setOpenSkillModal] = React.useState(false);
  const [modalName, setModalName] = React.useState("");
  const currentPage = searchParamsHook.get("page");
  const searchTextParam = searchParamsHook.get("searchParam");
  const [page, setPage] = React.useState(currentPage ?? 1);
  const [totalCount, setTotalCount] = React.useState("");
  const rowsPerPage = 20;
  const offset = (page - 1) * rowsPerPage;
  const loginStore = JSON.parse(localStorage.getItem("authUser"));
  const [viewAddModal, setViewAddModal] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [refetch, setRefetch] = React.useState(false);

  const [totalRecords, setTotalRecords] = React.useState("");

  const { search } = useLocation();
  const history = useHistory();

  const dispatch = useDispatch();
  React.useEffect(() => {
    initialFetch(page, rowsPerPage, searchText);
  }, [update]);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(search);
    searchParams.set("page", page);
    history.push({
      search: searchParams.toString(),
    });
    initialFetch(page, rowsPerPage, searchTextParam);
  }, [page, searchParamsHook.get("searchParam")]);

  /* seacrh */

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    setSearchText(searchParams.get("searchParam") ?? "");
    setPage(searchParamsHook.get("page") ?? 1);
  }, []);

  const debouncedQuery = useDebounce(searchText, 500);

  useEffect(() => {
    if (debouncedQuery) {
      const searchParams = new URLSearchParams(search);
      searchParams.set("searchParam", debouncedQuery);
      setPage(1);
      history.push({
        search: searchParams.toString(),
      });
    } else {
      const searchParams = new URLSearchParams(search);
      searchParams.set("searchParam", debouncedQuery);
      // setPage(1)
      history.push({
        search: searchParams.toString(),
      });
    }
  }, [debouncedQuery]);

  /* seacrh */

  const handleSearchOpen = () => {
    setOpenSearch(!openSearch);
  };

  async function initialFetch(page, rowsPerPage, searchText) {
    setLoading(true);
    try {
      if (![CA, CS].includes(loginStore.role)) {
        const { data, status } = await getAllReferalParterns(
          "referral",
          page,
          rowsPerPage,
          searchText
        );
        // setTotalCount(Math.ceil(data?.count / rowsPerPage));
        setTotalCount(data?.total_page_size);
        setTotalRecords(data?.overall_count);
        const result =
          data?.data_payload?.length > 0 &&
          data?.data_payload?.map((item, index) => ({
            id: item?.id,
            phone_number: parseNumberInput(item?.phone_number),
            image: `${imageURL}${item?.image}`,
            email: item?.email,
            location: `${item?.location ? item.location : "-"}`,
            jobs_applied: item?.get_applied_job_post_counts?.count,
            resume: item?.resume,
            name: `${item?.first_name} ${item?.last_name}`,
            experience: `${item?.experience ? item.experience + "Yrs" : "N/A"}`,
            highest_education: "1d",
            skills: `${
              item?.skill_details?.length > 0
                ? item?.skill_details?.map(item => item?.name)?.join(",")
                : "N/A"
            }`,
            // skills: item?.skills?.map(item => item?.name)?.join(","),
            gender: item?.gender ? item.gender : "-",
            // candidate_referred: "1 d",
            // candidate_onboarded: "1d",
            // total_earnings: "1d",
            // available_earnings: "1d",
            // bank_account_details: "2d",
          }));
        if (status === 200) {
          setLoading(false);
        }
        setReferalPartners(result);
        return;
      }
      const { data, status } = await getRefsByCompany(
        loginStore.company_id,
        page,
        rowsPerPage,
        searchText
      );
      // setTotalCount(Math.ceil(res?.count / rowsPerPage));
      setTotalCount(data?.total_page_size);

      const result =
        data?.data_payload?.length > 0 &&
        data?.data_payload?.map((item, index) => ({
          // id: item?.candidate_details?.id,
          id: index,
          phone_number: item?.candidate_details?.phone_number,
          image: `${imageURL}${item?.candidate_details?.image}`,
          email: item?.candidate_details?.email,
          location: `${
            item?.candidate_details?.location
              ? item?.candidate_details?.location
              : "N/A"
          }`,
          jobs_applied: item?.candidate_applied_job_posts_count,
          resume: item?.candidate_details?.resume,
          name: `${item?.candidate_details?.first_name} ${item?.candidate_details?.last_name}`,
          experience: `${
            item?.candidate_details?.experience
              ? item?.candidate_details?.experience + "Yrs"
              : "N/A"
          }`,
          gender: item?.candidate_details?.gender || "N/A",
          skills: `${
            item?.candidate_details?.skill_details?.length > 0
              ? item?.candidate_details?.skill_details
                  ?.map(item => item?.name)
                  ?.join(",")
              : "N/A"
          }`,
          // skills: `${
          //   item?.skill_details?.length > 0
          //     ? item?.skill_details?.map(item => item?.name)?.join(",")
          //     : "N/A"
          // }`,
          // skills: item?.skills?.map(item => item?.name)?.join(","),
          // gender: item?.gender?item.gender:"N/A",
          // candidate_referred: "1 d",
          // candidate_onboarded: "1d",
          // total_earnings: "1d",
          // available_earnings: "1d",
          // bank_account_details: "2d",
        }));
      if (status === 200) {
        setLoading(false);
      }
      setReferalPartners(result);
    } catch (error) {
      setLoading(false);
    }
  }

  const handleCloseModal = () => {
    setOpenSkillModal(!openSkillModal);
  };
  const handleCellClick = params => {
    const field = params.field;
    if (field === "name") {
    }
    if (field === "skills") {
      if (params.row.skills !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.skills.split(","));
        setOpenSkillModal(!openSkillModal);
      }
    } else if (field === "Categories") {
      if (params.row.Categories !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.Categories.split(","));
        setOpenSkillModal(!openSkillModal);
      }
    }
  };
  const DownloadResume = async data => {
    let fileUrl = imageURL + data?.resume;
    try {
      // Fetch the file from the URL
      const response = await fetch(fileUrl);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Convert the response to a Blob
      const blob = await response.blob();

      // Create a new URL for the Blob
      const url = window.URL.createObjectURL(blob);
      let fileName =
        data?.name?.split(" ")[0] +
        "." +
        new Date().getTime() +
        ".resume." +
        data?.resume?.split(".")[1];
      // Create a new anchor element and set its attributes
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;

      // Append the anchor to the body (it needs to be in the DOM)
      document.body.appendChild(a);

      // Programmatically trigger a click on the anchor to start the download
      a.click();

      // Clean up the DOM and revoke the object URL
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const columns = [
    {
      field: "image",
      headerName: "Applicant",
      width: 250,
      filterable: false,
      headerClassName: "super-app-theme--header",
      disableColumnMenu: true,
      renderCell: params => {
        return (
          <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
            <SmallPrimaryAvatar
              imgProps={{ loading: "lazy" }}
              src={params?.formattedValue}
            >
              {params?.row?.name?.charAt(0)?.toUpperCase()}
            </SmallPrimaryAvatar>
            <p>{params?.row?.name}</p>
          </div>
        );
      },
    },
    // {
    //   field: "name",
    //   headerName: "Name",
    //   headerClassName: "super-app-theme--header",
    //   width: 200,
    //   renderCell: params => (
    //     <div className="d-flex gap-2 align-items-center">
    //       <span>{params.value}</span>
    //       <IconButton
    //         onClick={() =>
    //           ClipBoardData(
    //             `app.joinee.com/user-profile?user_id=${params.row.id}`,
    //             "Job link"
    //           )
    //         }
    //       >
    //         <FeatherIcon icon={"external-link"} size={10} />
    //       </IconButton>
    //     </div>
    //   ),
    // },
    {
      field: "phone_number",
      headerClassName: "super-app-theme--header",
      headerName: "Phone Number",
      width: 300,
    },
    {
      field: "email",
      headerClassName: "super-app-theme--header",
      headerName: "Email",
      width: 300,
    },
    {
      field: "resume",
      headerClassName: "super-app-theme--header",
      headerName: "Resume",
      width: 300,
      renderCell: params => {
        return params.row?.resume ? (
          <IconButton
            title="Download Resume"
            onClick={() => DownloadResume(params.row)}
          >
            <FaDownload size={22} style={{ color: "#024430" }} />
          </IconButton>
        ) : null;
      },
    },

    {
      field: "location",
      headerName: "Location",
      headerClassName: "super-app-theme--header",
      width: 400,
      filterOperators: LocationOperators,
    },
    {
      field: "experience",
      headerClassName: "super-app-theme--header",
      headerName: "Experience",
      width: 200,
      renderCell: params => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {params.value && params.value !== "N/A" ? (
            <>
              <PendingJobChip
                label={
                  <>
                    <BagIcon /> {params.value}
                  </>
                }
                style={{ margin: "1px" }}
                size="small"
              />
            </>
          ) : (
            <span>-</span>
          )}
        </div>
      ),
    },
    {
      field: "jobs_applied",
      headerClassName: "super-app-theme--header",
      headerName: "Jobs Applied Count",
      width: 200,

      renderCell: params => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            //  gap: "8px"
          }}
        >
          {params.value && params.value !== "N/A" ? (
            <>
              <CompanySizeChip
                label={
                  <>
                    <RedTickIcon /> {params.value} Applied
                  </>
                }
                sx={{ margin: "0.5px" }}
                size="small"
              />
            </>
          ) : (
            <span>-</span>
          )}
        </div>
      ),
    },
    // { field: "skills", width: 200, filterOperators: KeySkillOperator },
    {
      field: "skills",
      headerName: "Skills",
      headerClassName: "super-app-theme--header",
      width: 400,
      // renderCell: params => (
      //   <div style={{ display: "flex", alignItems: "center" }}>
      //     {params.value && params.value !== "N/A" ? (
      //       <>
      //         {params.value
      //           .split(",")
      //           .slice(0, 2)
      //           ?.map(each => (
      //             <SmallSizeChip
      //               label={each}
      //               style={{ margin: "1px" }}
      //               size="small"
      //             />
      //           ))}
      //       </>
      //     ) : (
      //       <span>N/A</span>
      //     )}
      //   </div>
      // ),

      renderCell: params => {
        const values =
          params.value && params.value !== "N/A" ? params.value.split(",") : [];
        const firstValue = values.slice(0, 1);
        const remainingCount = values.length - 1;

        return (
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            {firstValue.map((each, index) => (
              <SkillsChip
                key={index}
                label={each}
                style={{ margin: "1px" }}
                size="small"
              />
            ))}
            {remainingCount > 0 && (
              <SkillsChip
                label={`+${remainingCount}`}
                style={{ margin: "1px" }}
                size="small"
              />
            )}
            {values.length === 0 && <span>-</span>}
          </div>
        );
      },
    },
    {
      field: "gender",
      headerName: "Gender",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    // { field: "gender", width: 200, filterOperators: GenderOperators },
    // { field: "candidate_referred", width: 200 },
    // { field: "candidate_onboarded", width: 200 },
    // { field: "total_earnings", width: 200 },
    // { field: "available_earnings", width: 200 },
    // { field: "bank_account_details", width: 200 },
  ];

  const filteredColumns = columns.filter(Boolean);

  const handleDownload = async () => {
    const { data, status } = await getAllReferalParterns(
      "referral",
      page,
      totalRecords,
      searchText
    );

    const result =
      data?.data_payload?.length > 0 &&
      data?.data_payload?.map((item, index) => ({
        // id: item?.candidate_details?.id,
        id: index,
        phone_number: item?.candidate_details?.phone_number,
        image: `${imageURL}${item?.candidate_details?.image}`,
        email: item?.candidate_details?.email,
        location: `${
          item?.candidate_details?.location
            ? item?.candidate_details?.location
            : "N/A"
        }`,
        jobs_applied: item?.candidate_applied_job_posts_count,
        resume: item?.candidate_details?.resume,
        name: `${item?.candidate_details?.first_name} ${item?.candidate_details?.last_name}`,
        experience: `${
          item?.candidate_details?.experience
            ? item?.candidate_details?.experience + "Yrs"
            : "N/A"
        }`,
        gender: item?.candidate_details?.gender || "N/A",
        skills: `${
          item?.candidate_details?.skill_details?.length > 0
            ? item?.candidate_details?.skill_details
                ?.map(item => item?.name)
                ?.join(",")
            : "N/A"
        }`,
        // skills: `${
        //   item?.skill_details?.length > 0
        //     ? item?.skill_details?.map(item => item?.name)?.join(",")
        //     : "N/A"
        // }`,
        // skills: item?.skills?.map(item => item?.name)?.join(","),
        // gender: item?.gender?item.gender:"N/A",
        // candidate_referred: "1 d",
        // candidate_onboarded: "1d",
        // total_earnings: "1d",
        // available_earnings: "1d",
        // bank_account_details: "2d",
      }));

    downloadCsv(result, filteredColumns, "Jobseekers list");
  };

  function CustomToolbar() {
    const handleChange = e => {
      e.stopPropagation();
      setSearchText(e.target.value);
    };
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <CompanyTypography variant="h1" component="h1">
            Referral Partner
          </CompanyTypography>
          <div style={{ justifyContent: "flex-end", display: "flex" }}>
            <GridToolbarContainer>
              <StyledOutlinedInput
                placeholder="search..."
                value={searchText}
                onChange={e => handleChange(e)}
                // onChange={e => {
                //   e.stopPropagation();
                //   setSearchText(e.target.value);
                // }}
                startAdornment={
                  <InputAdornment position="start">
                    <JobSearchIcon />
                  </InputAdornment>
                }
              />
              {/* <GlobalSearch /> */}

              <GridToolbarColumnsButton sx={{ color: "#ffffff" }} />
              <GridToolbarFilterButton sx={{ color: "#ffffff" }} />

              <DownloadButton
                size="medium"
                startIcon={<DownloadIcon />}
                onClick={handleDownload}
                sx={{ color: "#ffffff" }}
              >
                Download
              </DownloadButton>
            </GridToolbarContainer>
          </div>
        </div>
      </>
    );
  }

  const handleListnerEvent = data => {
    if (data?.type === "REFERAL_PARTNER") {
      initialFetch(role, page, rowsPerPage, searchText);
    }
  };

  useEffect(() => {
    const handleReconnect = () => {
      // Re-register the event listener after reconnection if needed
      socket.on("listnerEvent", handleListnerEvent);
    };

    socket.on("listnerEvent", handleListnerEvent);

    // Attach a listener for socket reconnection
    socket.on("reconnect", handleReconnect);

    return () => {
      socket.off("listnerEvent", handleListnerEvent);
      socket.off("reconnect", handleReconnect);
    };
  }, []);

  return (
    <div
      style={{
        padding: "10px",
        height: "91vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* <Stack direction="row" alignItems="center" spacing={1.6}>
        {openSearch ? (
          <SearchIcon onClick={handleSearchOpen} />
        ) : (
          <StageSearchInput
            placeholder="search..."
            // onChange={(e) => handleSearch(e)}
            onChange={(e) => {
              e.stopPropagation();
              setSearchText(e.target.value);
            }}
            endAdornment={
              <IconButton
                edge="end"
                onClick={() => {
                  handleSearchOpen();
                  setSearchText("");
                }}
              >
                <ClearIcon />
              </IconButton>
            }
          />
        )}
        {[ZA].includes(loginStore.role) && (
          <PrimaryButton
            sx={{ width: "max-content !important", ml: "auto !important" }}
            onClick={() => {
              setViewAddModal(true);
            }}
          >
            Add
          </PrimaryButton>
        )}
      </Stack> */}
      {console.log("referalPartners :", { filteredColumns })}
      <GlobalTableTools
        handleDownload={handleDownload}
        tableHeading={"Referral partner"}
      />

      <PrimaryDataGrid
        // onCellDoubleClick={event => {
        //   history.push(`/common-user/${event?.row?.id}`);
        // }}
        onCellClick={handleCellClick}
        loading={loading}
        checkboxSelection={false}
        disableRowSelectionOnClick
        columns={filteredColumns}
        rows={referalPartners}
        slots={{
          loadIcon: LoaderIcon,
          baseButton: PrimaryButtonForTable,
          basePopper: TablePopper,
          baseSwitch: PrimarySwitch,

          // toolbar: CustomToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
          pagination: props => (
            <Pagination
              {...props}
              // color="primary"
              // count={Math.ceil(rowsData.length / rowsPerPage)}
              count={totalCount}
              page={page}
              rowsPerPage={rowsPerPage}
              onChange={(_, newPage) => setPage(newPage)}
              showFirstButton
              showLastButton
              sx={{
                "& .MuiPaginationItem-root": {
                  "&:hover": {
                    backgroundColor: "#cde2e7",
                    color: "#066a4c",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "#024430",
                    color: "#ffe7bb",
                    "&:hover": {
                      backgroundColor: "#cde2e7",
                      color: "#066a4c",
                    },
                  },
                },
              }}
            />
          ),
        }}
        // components={{
        //   noRowsOverlay: CustomNoRowsOverlay,
        // }}

        sx={{
          "& .super-app-theme--header": {
            backgroundColor: "rgba(250, 250, 250, 1)",
          },
        }}
      />
      {openSkillModal && (
        <SkillViewModal
          rowdata={selectedRowData}
          closeModalFunc={handleCloseModal}
          openModal={openSkillModal}
          headerName={modalName}
          dynamicStyles={{
            color: "rgba(24, 24, 27, 1)",
            bgColor: "rgba(18, 18, 18, 0.1)",
          }}
        />
      )}
      {viewAddModal && (
        <RPModel
          isOpen={viewAddModal}
          updated={() => setUpdate(pre => !pre)}
          toggle={() => setViewAddModal(false)}
        />
      )}
    </div>
  );
}
