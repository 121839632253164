import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  PrimaryButton,
  PrimaryOutlinedButton,
  SecondaryButton,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import ErrorHandlingToaster from "helpers/Toaster/error";
import { handleApproveJob } from "helpers/services/adminStaff/AccountManager.js/ApproveJobs";
import SocketConnection from "hooks/sockets";
import ViewMoreButton from "./ViewMoreButtons";
import { toast } from "react-hot-toast";
import CommissionModal from "./CommissionModal";
import { socket } from "Sockets/socket";
import EditCommissionModal from "./editCommissionModal";

export default function PendingCardButtons({
  tableData,
  // setTableData,
  item,
  handleViewMore,
  setUpdate,
  reCall
}) {
 
  // const { lastMessage, sendMessage: sendSocketMessage,connectionStatus } = SocketConnection();
  const [openModal, setOpenModal] = useState(false);
  const [approveStatus, setApproveStatus] = useState(false);

  function toggle() {
    setOpenModal(!openModal);
  }


  return (
    <div className="d-flex align-items-center justify-content-end gap-2 mt-2">
      {openModal && (
        <CommissionModal
          isOpen={openModal}
          tableData={item}
          title="Commissions"
          toggle={toggle}
          reCall={reCall}
        />
      )}

      

      {item?.is_commission_added ? (
        <PrimaryButton
          sx={{ width: "100px" }}
          startIcon={<FeatherIcon icon="check-circle" size="14" />}
          onClick={async () => {
            const { status, data: approvedData } = await handleApproveJob(
              item.id
            );
            if ([200, 201].includes(status)) {
              setUpdate(pre => !pre);
              toast.success("Successfully Approved!");
              // const index = tableData.findIndex(({ id }) => id === item.id);
              // const data = [...tableData];
              // data.splice(index, 1);
              // setTableData(data);
              // await sendSocketMessage(
              //   JSON.stringify({
              //     type: "verified_job",
              //     message: {
              //       data: "verified_job",
              //     },
              //     reciever_connectionId: "",
              //   })
              // )

           
              socket.emit("message",{
                type:"NEW_NOTIFICATION",
                message:"Approved"
               })
               
             
            } else {
              ErrorHandlingToaster({ status, data: approvedData });
            }
          }}
        >
          Approve
        </PrimaryButton>
      ) : (
        <PrimaryButton onClick={e => toggle()} sx={{ width: "100px" }}>
          Commissions
        </PrimaryButton>
      )}

      {/* <PrimaryButton  onClick={(e)=>toggle()} sx={{width:"100px"}}>
        Commissions
      </PrimaryButton>
      <PrimaryButton
        sx={{ width: "100px" }}
        startIcon={<FeatherIcon icon="check-circle" size="14" />}
        onClick={async () => {
          const { status, data: approvedData } = await handleApproveJob(
            item.id
          );
          if ([200,201].includes(status)) {
            setUpdate(pre=>!pre);
            toast.success("Successfully Approved!")
            // const index = tableData.findIndex(({ id }) => id === item.id);
            // const data = [...tableData];
            // data.splice(index, 1);
            // setTableData(data);
            // await sendSocketMessage(
            //   JSON.stringify({
            //     type: "verified_job",
            //     message: {
            //       data: "verified_job",
            //     },
            //     reciever_connectionId: "",
            //   })      
            // )       
          } else {
            ErrorHandlingToaster({ status, data: approvedData });
          }
        }}
      >
        Approve
      </PrimaryButton> */}
      <ViewMoreButton id={item?.id} />
    </div>
  );
}
