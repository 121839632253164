export default function JobSearchIcon() {
    return (
        <svg width="19" height="19" style={{ minWidth: "19px", minHeight: "19px" }} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1863_12402)">
                <path d="M17.3713 17L15.8714 15.5M2.37134 9.125C2.37134 5.18997 5.56131 2 9.49634 2C13.4314 2 16.6213 5.18997 16.6213 9.125C16.6213 13.06 13.4314 16.25 9.49634 16.25C5.56131 16.25 2.37134 13.06 2.37134 9.125Z" stroke="#596066" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_1863_12402">
                    <rect width="18" height="18" fill="white" transform="translate(0.871338 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
}