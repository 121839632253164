import { EVALUATED_COUNT } from "./actionTypes";

const initState = {
    pendingSkillCount:"",
    totalSkillCount:"",
    stageMove:false
}

 const pendingCountReducer = (state = initState, action)=>{
    switch(action.type){
        case EVALUATED_COUNT:
            if(action.payload?.pendingSkillCount == 0){
                return {
                    ...state, 
                    pendingSkillCount:action?.payload.pendingSkillCount,
                    totalSkillCount:action.payload.totalSkillCount,
                    stageMove:true
                }
            }else {
                return {
                    ...state, 
                    pendingSkillCount:action?.payload.pendingSkillCount,
                    totalSkillCount:action.payload.totalSkillCount,
                    stageMove:false
                }
            }
        default:
            return state
    }
}

export default pendingCountReducer;