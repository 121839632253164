import { DELETE, POST, PUT } from "common/constants/methods";
import callApi from "helpers/callApi";
import { updateAdminStaffModel } from "helpers/models/adminStaffModel";
// const companyId = JSON.parse(localStorage.getItem("authUser"))?.company_id;
async function companyId() {
  return await JSON.parse(localStorage.getItem("authUser"))?.company_id;
}
export const createAdminStaff = async ({
  email,
  first_name,
  last_name,
  user_password,
  usertype,
  isApprove,
  phone_number
}) => {
  const data = {
    email,
    first_name,
    last_name,
    user_password,
    is_active: true,
    usertype,
    phone_number: phone_number,
    user_approval:isApprove,
    admin_company_id: await companyId(),
  };
  return await callApi("admin_staff/register", data, POST);
};

// export const createSAStaff = async ({
//   email,
//   first_name,
//   last_name,
//   user_password,
//   usertype,
//   isApprove,
//   mobile
// }) => {
//   const data = {
//     email,
//     first_name,
//     last_name,
//     user_password,
//     // is_active: true,
//     usertype,
//     phone_number:mobile,
//     user_approval:isApprove,
//     admin_company_id: await companyId(),
//   };
//   return await callApi("admin_staff/register", data, POST);
// };


export const createSAStaff = async body =>{
  const reqBody = {
    ...body,
    email: body.email,
    first_name: body.first_name,
    last_name: body.last_name,
    phone_number: body.mobile,
    user_password: body.user_password,
    usertype: body.usertype,
    user_approval:body.isApprove,
    location:body.location,
    admin_company_id: await companyId(),
  };
  delete reqBody.isApprove
  delete reqBody.mobile
 

 
  return await callApi(
    "admin_staff/register",
    reqBody,
    POST
  );
}

export const updateAdminStaff = async (items, id) => {
  const data = await updateAdminStaffModel({
    ...items,
    company_id: await companyId(),
  });
  return await callApi(`admin_staff/${id}`, data, PUT);
};
//!only for CA admin

// export const getAllAdminStaffList = async () => {
//   const result = await callApi(
//     `admin_authorise/get_admin_staff_list`
//   );
//   return result.data;
// };

/*Uncomment this once pagination with search is implemented from backend */
export const getAllAdminStaffList = async (page,pageSize,search) => {
  const result = await callApi(
    `admin_authorise/get_admin_staff_list?&searchParam=${search}&pageNo=${page}&pageSize=${pageSize}`
  );
  return result.data;
};


export const getAllAdminStaffListWithStatus = async (role,page,rowsPerPage,searchText,userType) => {
  if(["ZA"].includes(role)){
    // return await callApi(
    //   `admin_authorise/get_admin_staff_list?offset={offset}&&limit={page}`
    // );
 
    return await callApi(
      `admin_authorise/get_admin_staff_list?pageNo=${page}&&pageSize=${rowsPerPage}&searchParam=${searchText}&type=${userType}`
    );
  }
  return await callApi(

    `admin_authorise/get_admin_staff_list?company_id=${await companyId()}&&pageNo=${page}&&pageSize=${rowsPerPage}&searchParam=${searchText}&type=${userType}`

  );

};
///!ADMIN ONLY
export const getSingleAdminDetails = async id => {
  const result = await callApi(`admin_authorise/${id}`);
  return result.data;
};
export const getSingleAdminStaffDetails = async id => {
  const result = await callApi(
    `admin_staff/${id}?company_id=${await companyId()}`
  );
  return result.data;
};

export const updateSingleAdminStaffDetails = async id => {
  const data = {
    email: "asdf@syoft.com",
    first_name: "nice",
    last_name: "yes",
    password: "12345",
    is_active: true,
    usertype: "ACCOUNT_MANAGER",
    company_id: await companyId(),
  };

  const result = await callApi(`admin_staff/${id}`, data, PUT);
  return result.data;
};
export const deleteSingleAdminStaff = async userId => {
  const data = await callApi(
    `admin_staff/${userId}?company_id=${await companyId()}`,
    {},
    DELETE
  );
  return data;
};
