import React, { useEffect, useState } from "react";
import { Row, Col, Input, Container } from "reactstrap";
import { connect } from "react-redux";
import { updateJob } from "store/jobs/actions";
import SalaryBudget from "../Employee/SalaryBudget";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

const ProjectCompletionTime = ({ error, project_duration, updateJob }) => {
  const [checked, setChecked] = useState("");
  const [timeSpans, setTimeSpan] = useState([
    {
      name: "Less Than 1 Month",
      data: "less_than_month",
    },
    {
      name: "1-3 Months",
      data: "1_3_months",
    },
    {
      name: "3-6 Months",
      data: "3_6_months",
    },
    {
      name: "more than 6 Months",
      data: "more_than_6",
    },
  ]);
  let Job_Post = JSON.parse(sessionStorage.getItem("job_post")) || [];

  const handleChange = item_data => {
    updateJob({ project_duration: item_data });
  };
  useEffect(() => {
    setChecked(project_duration);
  }, [project_duration]);

  return (
    <div id="projectc-omletion-time-main">
      <Container fluid id="project-completion-cont">
        <Row style={{ marginTop: "50px" }}>
          <Col xs={12} style={{ margin: "auto", marginBottom: "8px" }}>
            <h5>
              How long will this project take?{" "}
              <span className="red p-2">*</span>
            </h5>
          </Col>
          <Col xs={12}>
            <Row style={{ margin: "auto" }}>
              {/* {timeSpans.map(item => {
                return (
                  <div
                    key={item.data}
                    className="form-check form-check-inline mt-2"
                  >
                    <input
                      className="form-check-input custom-control-input custom-check"
                      type="radio"
                      name="inlineRadioOptions"
                      id={item.data}
                      value={checked}
                      checked={checked === item.data}
                      onChange={e => handleChange(item.data)}
                    />
                    <label className="form-check-label" htmlFor={item.data}>
                      {item.name}
                    </label>
                  </div>
                );
              })} */}
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={checked}
                onChange={e => {
                  setChecked(e.target.value);
                  handleChange(e.target.value)
                }}              >
                {timeSpans.map((item, id) => {
                  return (
                    <FormControlLabel
                      key={id}
                      value={item.data}
                      control={<Radio />}
                      label={item.name}
                    />
                  );
                })}
              </RadioGroup>
            </Row>
          </Col>
          {checked !== "" &&
          (Job_Post === "Part_Time" || Job_Post === "Freelance") &&
          Job_Post !== "Full_Time" ? (
            <SalaryBudget error={error} type="hourly" />
          ) : (
            ""
          )}
          {checked !== "" &&
          Job_Post === "Contract" &&
          Job_Post !== "Full_Time" ? (
            <SalaryBudget error={error} type="hourly" />
          ) : (
            ""
          )}
        </Row>
      </Container>
    </div>
  );
};
const mapStateToProps = ({ Jobs }) => ({
  project_duration: Jobs.project_duration,
});

const mapDispatchToProps = dispatch => ({
  updateJob: e => dispatch(updateJob(e)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectCompletionTime);
