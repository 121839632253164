import {
  Box,
  Grid,
  IconButton,
  Input,
  InputBase,
  Stack,
  Tabs,
  Chip,
  Divider,
} from "@mui/material";
import {
  BlackTypography,
  BorderCard,
  GreyTypography,
  successNotification,
  StageSearchInput,
} from "assets/Mui/globalTheme";
import ChatImages from "components/Chat/chatPage/Images";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import RecivedMessage from "components/Chat/chatPage/recivedMessages";
import SendMessages from "components/Chat/chatPage/sendMessages";
import ChatInput from "components/Chat/chatPage/input";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import {
  useCallback,
  useState,
  useEffect,
  useRef,
  Fragment,
  useLayoutEffect,
} from "react";
import ChatUserPopUp from "./chatUserPopUp";
import {
  getEvalauateMessages,
  sendEvaluateMessage,
} from "helpers/services/chats/evalautorCharts";
import { useSelector } from "react-redux";
import SearchParamHook from "hooks/searchParams";
import SocketConnection from "hooks/sockets";
import {
  get_chat_active_user_list,
  deleteChatActiveUser,
} from "helpers/services/sockets";
import moment from "moment";
import noChats from "../../assets/images/noChatsGreen.png";
import messageSent from "../../assets/alerts/messageSent.mp3";
import SnackbarCom from "hooks/snackBar";
import { socket } from "Sockets/socket";
import { debounce } from "lodash";

const ChatDetailedPage = ({ styleProps = {}, getImages }) => {
  const {
    lastMessage,
    sendMessage: sendSocketMessage,
    connectionStatus,
  } = SocketConnection();
  const searchParams = SearchParamHook();
  const jobPostId = searchParams.get("job_id");
  const userJobPostId = searchParams.get("user_job_post_id");
  const receiverId = searchParams.get("user_id");
  const { candidate } = useSelector(state => state.Candidates);
  const [userPopUp, setUserPopUp] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [msgs, setMsgs] = useState([]);
  const [searchMsgs, setSearchMsgs] = useState([]);
  const [filterSearch, setFilterSearch] = useState("");
  const [socketUsers, setSocketUsers] = useState([]);
  const [socketRecieverId, setSocketRecieverId] = useState(null);
  const [socketListen, setSocketListen] = useState(false);
  const [onlineUser, setOnlineUser] = useState(false);
  

  const chatContainerRef = useRef(null);
  const searchRef = useRef(null)

  const user = useSelector(state => state.Login);
  const rootUserId = user.id;
  const userId = JSON.parse(localStorage.getItem("authUser"))?.id;

  const [page, setPage] = useState(1); // Set the initial page
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  const [pageSize, setPageSize] = useState(40);
  const [hasMore, setHasMore] = useState(true);
  const search = "";

  const [openSearch, setOpenSearch] = useState(true);

  useEffect(() => {
    fetchActiveUsersList();
  }, []);

  /* Search toggle effect */
  const handleSearchOpen = () => {
    setOpenSearch(!openSearch);
    setPage(1)
    setFilterSearch("");
    
  };
  /* Search toggle effect end */

  const handleSearch = event => {
    const value = event.target.value;
    setFilterSearch(value.trim());
  };

 
  useEffect(()=>{
    setPage(1)
    const timerId = setTimeout(()=>{
      fetchEvaluateMessages();
    },500)
    return ()=>clearTimeout(timerId)

  },[filterSearch])

  const fetchActiveUsersList = async () => {
    fetchEvaluateMessages();
  };

  const handleListnerEvent = data => {
    if (data?.type === "chats") {
      fetchActiveUsersList();
    }
  };

  useEffect(() => {
    const handleReconnect = () => {
      // Re-register the event listener after reconnection if needed
      socket.on("listnerEvent", handleListnerEvent);
    };

    socket.on("listnerEvent", handleListnerEvent);

    // Attach a listener for socket reconnection
    socket.on("reconnect", handleReconnect);

    return () => {

      socket.off("listnerEvent", handleListnerEvent);
      socket.off("reconnect", handleReconnect);
    };
  }, []);

  //Uncomment this later
  // useEffect(() => {
  //   const scrollToBottom = () => {
  //     if ( chatContainerRef.current) {
  //        chatContainerRef.current.scrollTop =  chatContainerRef.current.scrollHeight;
  //     }
  //   };
  //   scrollToBottom();
  //   return(()=>{
  //     scrollToBottom()
  //   })
  // }, [msgs]);

  //Uncomment this later(block)

  useEffect(() => {
    if (page <= totalPages && page > 0) {
      fetchEvaluateMessages();
    }
  }, [page]);

  async function fetchEvaluateMessages() {
    const { data, status } = await getEvalauateMessages({
      jobPostId,
      userJobPostId,
      receiverId,
      page,
      pageSize,
      filterSearch,
    });
    //  const {data:messages} = data
    setHasMore(data?.data_payload?.length > 0);
    setTotalPages(data?.total_page_size);
    const modifiedData = data?.data_payload?.map(item => ({
      id: item.id,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
      message: item.message,
      senderType: item.sender_type,
      isSeen: item.is_seen,
      isActive: item.is_active,
      chatChannel_id: item.chat_channel_id,
      receiverId: item.receiver_id,
      senderId: item.sender_id,
      jobPostId: item.job_post_id,
      userJobPostId: item.user_job_post_id,
    }));
    const sortedData = modifiedData.sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );
    //  setMsgs(modifiedData.sort((a,b)=>new Date(a.createdAt) - new Date(b.createdAt)))
    // setMsgs((prevMsgs)=>[...sortedData,...prevMsgs])

    setMsgs([...sortedData]);

    // if (data?.pageNo > 1) {
    //   setPage(prevPage => prevPage - 1);
    // }

    const userSocketId = socketUsers[0];
  }

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
    setUserPopUp(true);
  }
  const handleClose = useCallback(() => {
    setUserPopUp(false);
    setAnchorEl(null);
  }, [userPopUp]);

  /* Message send alert sound starts here @seshu */
  const playMessageSentSound = () => {
    const audio = new Audio(messageSent);
    audio.play();
  };
  /*Message send alert sound ends here @seshu */

  async function handleSendMessage(message) {
    const reqObj = {
      receiverId,
      message,
      userJobPostId,
      jobPostId,
    };
    const userSocketId = socketUsers[0];

    if (message !== "") {
      let userSocketId;
      // const res = await get_chat_active_user_list();
      // if (res) {
      //   const socketUsers = res?.data?.filter(
      //     user => user.userId === receiverId
      //   );
      //   userSocketId = socketUsers[0];
      // }

      const response = await sendEvaluateMessage(reqObj);
      playMessageSentSound();
      if (receiverId && response?.status === 200) {
        /*socket emit start here */
        // socket.emit("message", {
        //   // connectionId: userSocketId?.connectionId,
        //   userId: [receiverId],
        //   message: message,
        //   type: "chats",
        // });

        /*socket emit end here */
      }
    }
    fetchEvaluateMessages();
    getImages();
  }

  /* Infinite scroll pagination */

  // let lastScrollTop = 0;

  // const handleScroll = () => {

  //   const container = chatContainerRef.current;
  //   if (!container) return;

  //   const scrollHeight = container.scrollHeight;
  //   const scrollTop = container.scrollTop;
  //   const clientHeight = container.clientHeight;

  //   if (scrollHeight > clientHeight && scrollTop > lastScrollTop && scrollTop + clientHeight >= scrollHeight - 100 && hasMore) {
  //     if (hasMore) {
  //       setPage((prevPage) => prevPage + 1);
  //     }
  //   }

  //   lastScrollTop = scrollTop;
  // };

  // useEffect(() => {

  //   const container = chatContainerRef.current;
  //   if (!container) return;

  //   container.addEventListener('scroll', handleScroll);

  //   return () => {
  //     container.removeEventListener('scroll', handleScroll);
  //   };
  // }, [msgs,hasMore]);

  useEffect(() => {
    // Scroll to the bottom when messages change
    const container = chatContainerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [msgs]);

  const handleScroll = () => {
    const container = chatContainerRef.current;
    if (!container || loading) return;

    const scrollTop = container.scrollTop;
    const clientHeight = container.clientHeight;
    const scrollHeight = container.scrollHeight;

   

    // Fetch previous page when scrolling to the top
    if (scrollTop <= 100 && page >= 1) {
      setPage(prevPage => prevPage + 1);
    }

    // const scrollHeight = container.scrollHeight;
    // Fetch next page when scrolling to the bottom
    if (
      scrollTop + clientHeight >= scrollHeight - 10 &&
      page >= 2 &&
      page !== 1 &&
      hasMore
    ) {
      setPage(prevPage => prevPage - 1);
    }
  };

  const debouncedHandleScroll = debounce(handleScroll, 200);

  useEffect(() => {
    setTimeout(() => {
      const container = chatContainerRef.current;
      if (container) {
        container.addEventListener("scroll", debouncedHandleScroll);

        return () => {
          container.removeEventListener("scroll", debouncedHandleScroll);
        };
      }
    }, 600);
  }, [loading, hasMore, page]);

  /* Infinite scroll pagination */

  useEffect(async () => {
    const { data, status } = await get_chat_active_user_list();

    if (data?.length > 0) {
      const activeUserId = data?.filter(each => each.userId === receiverId);

      setOnlineUser(activeUserId?.length === 1);
    }
  }, []);

  // Handle Event when user is closing the tab or window
  async function getOnlineUsersList() {
    if (document.visibilityState === "visible") {
      const { data, status } = await get_chat_active_user_list();
      if (data?.length > 0) {
        const activeUserId = data?.filter(each => each.userId === receiverId);
        setOnlineUser(activeUserId?.length === 1);
      }
    }
  }

  useEffect(() => {
    document.addEventListener("visibilitychange", getOnlineUsersList);

    return () => {
      document.removeEventListener("visibilitychange", getOnlineUsersList);
    };
  }, []);

  // Handle Event when user is cloding the tab or window

  return (
    <>
      <BorderCard
        sx={{
          ...styleProps,
          height: "59vh",
          padding: "10px 20px",
          position: "relative",
          overflow: "visible",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          sx={{
            background: "white",
            position: "absolute",
            padding: "10px 20px",
            height: "60px",
            width: "100%",
            left: "0px",
            top: "0px",
            zIndex: "2",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            borderBottom: "1px solid #ddd",
          }}
        >
          <Stack>
            <BlackTypography variant="h5" component="h5">
              {candidate?.first_name} {candidate?.last_name}
            </BlackTypography>
            <Stack direction="row" alignItems={"center"} spacing={1}>
              <Box
                sx={{
                  ...successNotification,
                  width: "5px",
                  height: "5px",
                  background: !onlineUser
                    ? "rgb(255 0 0 / 35%)"
                    : "rgba(39,174,96,0.36)",
                }}
              />
              <GreyTypography variant="h6" component="h6">
                {/* {socketUsers.length ?"Online" :'Offline'} */}
                {onlineUser ? "Online" : "Offline"}
              </GreyTypography>
            </Stack>
          </Stack>
          {/* <Stack direction="row" alignItems={"center"} spacing={1}>
            <Stack spacing={0} alignItems={"end"}>
              <BlackTypography  variant="h3" component="h3">
                {candidate?.job_post_in_user_job_post?.job_title}
              </BlackTypography>
              <GreyTypography variant="h6" component="h6">
                Syoft
              </GreyTypography>
            </Stack>
            <IconButton onClick={handleClick}>
              <FeatherIcon icon="more-vertical" size={"14"} />
            </IconButton>{" "}
          </Stack> */}

          {openSearch ? (
            <SearchIcon
              onClick={handleSearchOpen}
              // sx={{
              //   alignSelf: "end",
              //   marginTop: "8px",
              //   marginRight: "8px",
              // }}
            />
          ) : (
            <StageSearchInput
              placeholder="search..."
              onChange={handleSearch}
              ref={searchRef}
              endAdornment={
                <IconButton edge="end" onClick={handleSearchOpen}>
                  <ClearIcon />
                </IconButton>
              }
            />
          )}
        </Stack>
        {msgs.length ? (
          <div
            ref={chatContainerRef}
            style={{
              // height:"calc(100% - 120px)",
              height: "calc(100% - 80px)",
              overflowY: "scroll",
              overflowX: "hidden",
              paddingBlockStart: 55,
              paddingBlockEnd: 20,
              paddingInline: 8,
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <MessageChart msgs={msgs} />
          </div>
        ) : (
          <div
            style={{
              height: "calc(100% - 30px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{ width: "250px", height: "250px" }}
              src={noChats}
              alt="/"
            />
          </div>
        )}
        <ChatInput handleSendMessage={handleSendMessage} setMsgs={setMsgs} />
      </BorderCard>
      <ChatUserPopUp handleClose={handleClose} anchorEl={anchorEl} />
    </>
  );
};
export default trackWindowScroll(ChatDetailedPage);

function MessageChart({ msgs }) {
  const evaluatorId = JSON.parse(localStorage.getItem("authUser")).id;
  return (
    <>
      {msgs?.map((msgItem, index) => {
        const { createdAt, id, message, senderId } = msgItem;
        const isExtension = /\.(jpe?g|png|jpeg|gif|pdf|docx|json)$/i.test(
          message
        );
        const isSender = evaluatorId === senderId;
        const timePinger =
          msgs[index - 1]?.createdAt === undefined
            ? true
            : moment(msgs[index - 1]?.createdAt).format("L") !==
              moment(msgItem.createdAt).format("L");
        if (!isExtension) {
          if (isSender) {
            return (
              <Fragment key={index}>
                {Boolean(timePinger) && <TimeDivider time={createdAt} />}
                <SendMessages key={id} message={message} time={createdAt} />
              </Fragment>
            );
          }
          return (
            <Fragment key={index}>
              {Boolean(timePinger) && <TimeDivider time={createdAt} />}
              <RecivedMessage key={id} message={message} time={createdAt} />
            </Fragment>
          );
        }
        return (
          <Fragment key={index}>
            {Boolean(timePinger) && <TimeDivider time={createdAt} />}
            <ChatImages
              key={id}
              sender={isSender}
              message={message}
              time={createdAt}
            />
          </Fragment>
        );
      })}
    </>
  );
}

function TimeDivider({ time }) {
  return (
    <Divider sx={{ ":before,:after": { top: "0px !important" } }}>
      <Chip
        label={moment(time).calendar({
          sameDay: "[Today]",
          nextDay: "[Tomorrow]",
          nextWeek: "dddd",
          lastDay: "[Yesterday]",
          lastWeek: "DD/MM/YYYY",
          sameElse: "DD/MM/YYYY",
        })}
        size="small"
      />
    </Divider>
  );
}
