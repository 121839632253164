import { Col, Form, Row } from "reactstrap";
import {
  Button,
  CssVarsProvider,
  FormLabel,
  Input,
  Option,
  Select,
  Sheet,
  Typography,
  colors,
} from "@mui/joy";
import {
  addCommissions,
  getCommissions,
  updateCommission,
} from "helpers/services/commissions";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { deepPurple } from "@mui/material/colors";
import { toast } from "react-hot-toast";

export default function CommissionForms({ item, commissions }) {
  const {
    register,
    handleSubmit,
    watch,
    unregister,
    formState: { errors },
    setValue,
  } = useForm();
  useEffect(() => {
    const data = commissions?.find(
      ({ apply_type }) => apply_type === item?.value
    );
    setValue(
      "recruitment_company_commission",
      data?.recruitment_company_commission
    );
    setValue(
      "referral_candidate_commission",
      data?.referral_candidate_commission
    );
    setValue("referral_partner_commission", data?.referral_partner_commission);
    setValue(
      "screening_partner_commission",
      data?.screening_partner_commission
    );
    setValue("zepul_admin_commission", data?.zepul_admin_commission);
    //   if (data?.id !== "" && data?.id !== null)
    setValue("id", data?.id);
  }, [commissions]);
  const submitForm = data => {
    if (data?.id === "" || data?.id === null) {
      delete data.id;
      const { data: commissionData, status } = addCommissions(data);
      if (status === 200) {
        toast.success("Commission added successfully");
      }
    } else {
      const id = data.id;
      delete data.id;
      delete data?.apply_type;
      const { data: commissionData, status } = updateCommission(id, data);
      if (status === 200) {
        toast.success("Commission Updated successfully");
      }
    }
  };
  return (
    <Sheet style={{ padding: "20px", marginBottom: "20px" }}>
      <Form
        onSubmit={handleSubmit(data => {
          submitForm({
            ...data,
            apply_type: item?.value,
            commission_type: "percentage",
          });
        })}
      >
        <Typography
          level="h6"
          style={{
            textTransform: "capitalize",
            marginBottom: "20px",
          }}
          color={deepPurple[800]}
        >
          {item?.name} commission
        </Typography>
        <Row>
          <Col xs={12} md={6}>
            <FormLabel>Referral Partner</FormLabel>
            <Input
              type="number"
              min={"0"}
              error={errors?.referral_partner_commission}
              {...register("referral_partner_commission", {
                min: 0,
                max: 100,
                required: true,
              })}
            />
          </Col>
          <Col xs={12} md={6}>
            <FormLabel>Referral Candidate</FormLabel>
            <Input
              type="number"
              error={errors?.referral_candidate_commission}
              {...register("referral_candidate_commission", {
                min: 0,
                max: 100,
                // required: true,
              })}
            />
          </Col>
          <Col xs={12} md={6}>
            <FormLabel>Screening Partner Partner</FormLabel>
            <Input
              type="number"
              error={errors?.screening_partner_commission}
              {...register("screening_partner_commission", {
                min: 0,
                max: 100,
                // required: true,
              })}
            />
          </Col>{" "}
          <Col xs={12} md={6}>
            <FormLabel>Joinee Admin</FormLabel>
            <Input
              type="number"
              error={errors?.zepul_admin_commission}
              {...register("zepul_admin_commission", {
                min: 0,
                max: 100,
                // required: true,
              })}
            />
          </Col>{" "}
          <Col xs={12} md={6}>
            <FormLabel>Recrutement Company</FormLabel>
            <Input
              error={errors?.recruitment_company_commission}
              {...register("recruitment_company_commission", {
                min: 0,
                max: 100,
                // required: true,
              })}
            />
          </Col>
          <Col xs={10}></Col>
          <Col xs={2} className="mt-4">
            <Button type="submit">Submit</Button>
          </Col>
        </Row>
      </Form>
    </Sheet>
  );
}