import {
  NOTIFICATION_TAGS,
  GLOBAL_STATUS_APP,
  LOCAL_STATUS_APP,
  GLOBAL_STATUS_EMAIL,
  LOCAL_STATUS_EMAIL,
} from "./actionTypes";

const initState = {
  notificationsData: [],
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case NOTIFICATION_TAGS:
      return (notificationsData = action.payload);
    case GLOBAL_STATUS_APP:
      return {
        ...state,
        globalStatusApp: action.payload.globalStatusApp,
      };
    case LOCAL_STATUS_APP:
      return {
        ...state,
        localStatusApp: action.payload.localStatusApp,
      };
    case GLOBAL_STATUS_EMAIL:
      return {
        ...state,
        globalStatusEmail: action.payload.globalStatusEmail,
      };
    case LOCAL_STATUS_EMAIL:
      return {
        ...state,
        localStatusEmail: action.payload.localStatusEmail,
      };
    default:
      return state;
  }
}
