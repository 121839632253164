export default function JobUsersIcon() {
    return (
        <svg width="21" height="21" style={{ minWidth: "21px", minHeight: "21px"}} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="10.8713" cy="14.2498" rx="5" ry="2.08333" stroke="#404944" stroke-width="1.2" stroke-linejoin="round" />
            <circle cx="10.8713" cy="7.1665" r="2.5" stroke="#404944" stroke-width="1.2" stroke-linejoin="round" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.19294 11.5786C5.1827 11.6254 4.25638 11.8096 3.53554 12.0979C3.1167 12.2655 2.73748 12.48 2.45316 12.7488C2.16757 13.0189 1.93799 13.3853 1.93799 13.8335C1.93799 14.2818 2.16757 14.6481 2.45316 14.9182C2.73748 15.1871 3.1167 15.4016 3.53554 15.5691C3.94187 15.7316 4.41349 15.8611 4.92863 15.9515C4.54044 15.5233 4.29421 15.0476 4.22482 14.544C4.13982 14.5156 4.05853 14.4859 3.98121 14.4549C3.64604 14.3209 3.41506 14.1762 3.27769 14.0463C3.14159 13.9176 3.13799 13.8455 3.13799 13.8335C3.13799 13.8215 3.14159 13.7494 3.27769 13.6207C3.41506 13.4908 3.64604 13.3462 3.98121 13.2121C4.15823 13.1413 4.35606 13.0767 4.57164 13.02C4.91094 12.4702 5.47132 11.9785 6.19294 11.5786Z" fill="#404944" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5178 14.544C17.4484 15.0476 17.2022 15.5233 16.814 15.9515C17.3291 15.8611 17.8008 15.7316 18.2071 15.5691C18.6259 15.4016 19.0051 15.1871 19.2895 14.9182C19.5751 14.6481 19.8046 14.2818 19.8046 13.8335C19.8046 13.3853 19.5751 13.0189 19.2895 12.7488C19.0051 12.48 18.6259 12.2655 18.2071 12.0979C17.4862 11.8096 16.5599 11.6254 15.5497 11.5786C16.2713 11.9785 16.8317 12.4702 17.171 13.02C17.3866 13.0767 17.5844 13.1413 17.7614 13.2121C18.0966 13.3462 18.3276 13.4908 18.4649 13.6207C18.601 13.7494 18.6046 13.8215 18.6046 13.8335C18.6046 13.8455 18.601 13.9176 18.4649 14.0463C18.3276 14.1762 18.0966 14.3209 17.7614 14.4549C17.6841 14.4859 17.6028 14.5156 17.5178 14.544Z" fill="#404944" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6225 8.98277C14.4445 9.34986 14.2141 9.68687 13.9412 9.98413C14.2662 10.1642 14.6401 10.2667 15.038 10.2667C16.2899 10.2667 17.3047 9.25191 17.3047 8.00006C17.3047 6.74822 16.2899 5.7334 15.038 5.7334C14.9542 5.7334 14.8714 5.73795 14.7899 5.74683C14.9252 6.12039 15.0087 6.51883 15.0316 6.93342C15.0338 6.9334 15.0359 6.9334 15.038 6.9334C15.6271 6.9334 16.1047 7.41096 16.1047 8.00006C16.1047 8.58917 15.6271 9.06673 15.038 9.06673C14.8906 9.06673 14.7502 9.03683 14.6225 8.98277Z" fill="#404944" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.95285 5.74683C6.87134 5.73795 6.78853 5.7334 6.70465 5.7334C5.45281 5.7334 4.43799 6.74822 4.43799 8.00006C4.43799 9.25191 5.45281 10.2667 6.70465 10.2667C7.10255 10.2667 7.47651 10.1642 7.80154 9.98413C7.52858 9.68687 7.29823 9.34986 7.12018 8.98277C6.99248 9.03683 6.85206 9.06673 6.70465 9.06673C6.11555 9.06673 5.63799 8.58917 5.63799 8.00006C5.63799 7.41096 6.11555 6.9334 6.70465 6.9334C6.7068 6.9334 6.70894 6.9334 6.71108 6.93342C6.73396 6.51883 6.81745 6.12039 6.95285 5.74683Z" fill="#404944" />
        </svg>
    );
}
