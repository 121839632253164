import { withRouter } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import {
  MultipleSelectInputs,
  PrimaryAutoComplete,
  PrimaryButton,
  PrimaryNormalInputLabel,
  SecondaryFilledButton,
  SecondaryFilledChip,
  autocompleteProps,
} from "assets/Mui/globalTheme";
import {
  Checkbox,
  FormControl,
  TextField,
  createFilterOptions,
} from "@mui/material";
import { IoIosCloseCircle } from "react-icons/io";
import { FaCloudUploadAlt } from "react-icons/fa";
import { Modal } from "reactstrap";
import { v4 as uuid } from "uuid";
import { useForm, Controller } from "react-hook-form";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {
  createKeySkills,
  getAllKeySkillsWithStatus,
} from "helpers/services/jobAttributes/keyskills";
import { getPreSignedURL } from "helpers/services/pre_signed_urls/upload";
import { imageURL } from "common/constants/commonURLS";

function JobCategoryModel(props) {
  const inputRef = useRef(null);
  const [skillLoader, setSkillLoader] = useState(true);
  const [keySkills, setKeySkills] = useState([]);
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState("");
  const {
    headTag,
    isOpen,
    toggle,
    purpose,
    setViewAddModal,
    viewAddModal,
    rowData = "",
  } = props;
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    getValues,
    formState: { errors },
    reset,
  } = useForm();
  console.log("rowData ::", rowData);
  useEffect(() => {
    if (rowData) {
      setPreview(rowData.image?imageURL + rowData.image:"");
      let skills =
        rowData?.skills && Array.isArray(rowData.skills)
          ? keySkills?.filter(item=>rowData?.skills?.includes(item.name))
          : [];
      setValue("key_skills", skills);
    }
  }, [rowData]);
  const handleFileChange = e => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          setPreview(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const handleRemove = () => {
    setFile(null);
    setPreview("");
  };
  const onSubmit = async data => {
    const { key_skills } = data;
    let skills = key_skills.map(item => item.name).join(",") ?? "";
    let image;

    if (file) {
      image = await getPreSignedURL([file]);
    } else {
      if (rowData?.image) image = rowData.image;
    }
    props.update(
      {
        name: data.text,
        is_active: true,
        id: props?.id,
        skills,
        image: image ?? "",
      },
      props.purpose,
      props.index,
      props.id
    );
    toast.success(`${props.purpose}d`);
    toggle(!props.isOpen);
    reset({ text: "", key_skills: [] });
    setFile(null);
    setPreview("");
    props.setRowData("");
  };

  const handleInput = e => {
    setValue("text", e.target.value);
  };
  async function getKeySKills() {
    const currentPage = 1;
    const { data, status } = await getAllKeySkillsWithStatus(
      currentPage,
      1000,
      ""
    );
    setKeySkills(
      data?.data_payload?.map(item => ({
        name: item?.name,
        value: item?.id,
        isActive: item?.is_active,
        id: item?.id,
      }))
    );
    if (status === 200) {
      setSkillLoader(false);
    }
  }

  useEffect(() => {
    getKeySKills();
  }, []);

  useEffect(() => {
    if (purpose == "Edit") {
      setValue("text", props.title);
    } else {
      setValue("text", "");
    }
  }, [props.title, purpose]);
  const filter = createFilterOptions();

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static" keyboard={false}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {purpose} {headTag}
        </h5>
        <button
          type="button"
          onClick={() => {
            clearErrors();
            setViewAddModal(!viewAddModal);
            toggle(!isOpen);
            props.setRowData("");
            reset({ text: "", key_skills: []});
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <input
              type="file"
              id="file"
              ref={inputRef}
              onChange={handleFileChange}
              hidden
            />

            {preview ? (
              <div
                style={{
                  position: "relative",
                  width: "150px",
                  height: "150px",
                }}
              >
                <img
                  src={preview}
                  alt="Preview"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "top",
                    borderRadius: "15px",
                  }}
                />

                <IoIosCloseCircle
                  onClick={handleRemove}
                  size={22}
                  style={{
                    position: "absolute",
                    background: "transparent",
                    border: "none",
                    right: "1px",
                    top: "2px",
                    color: "red",
                    cursor: "pointer",
                  }}
                />
              </div>
            ) : (
              <label htmlFor="file">
                <span style={{ display: "block", fontSize: "16px" }}>
                  Image
                </span>
                <PrimaryButton
                  onClick={() => inputRef.current?.click()}
                  sx={{ width: "100px" }}
                >
                  <FaCloudUploadAlt size={25} />
                </PrimaryButton>
              </label>
            )}
          </div>
          <Controller
            name="text"
            control={control}
            defaultValue={props.title || ""}
            rules={{
              required: "This field is required",
              minLength: {
                value: 1,
                message: "Input must be at least 1 character long",
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                label={`${purpose} ${headTag}`}
                className="form-control"
                value={field.value}
                onChange={e => {
                  field.onChange(e);
                  handleInput(e);
                }}
                placeholder={`Enter ${headTag} Name`}
                type="text"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
              />
            )}
          />
          <div className="mt-2" />
          <Controller
            render={props => {
              return (
                <PrimaryAutoComplete
                  sx={{ width: "100%" }}
                  {...props}
                  disableCloseOnSelect
                  componentsProps={autocompleteProps}
                  multiple={true}
                  loading={skillLoader}
                  loaderText="Fetching Skills"
                  options={keySkills}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <SecondaryFilledChip
                        deleteIcon={<FeatherIcon icon={"x"} size="14" />}
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderOption={(props, option, { selected }) =>
                    option?.isActive ? (
                      <li {...props} key={props.id}>
                        <Checkbox
                          icon={<FeatherIcon icon="square" size="14" />}
                          checkedIcon={
                            <FeatherIcon icon="check-square" size="14" />
                          }
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    ) : null
                  }
                  getOptionLabel={option => option?.name || ""}
                  renderInput={params => (
                    <FormControl variant="standard" sx={{ width: "100%" }}>
                      <PrimaryNormalInputLabel
                        shrink
                        htmlFor="key-skills-outlined"
                        error={errors.key_skills}
                      >
                        Skills*
                      </PrimaryNormalInputLabel>
                      <MultipleSelectInputs
                        sx={{
                          ...(errors.key_skills && { borderColor: "red" }),
                        }}
                        variant="standard"
                        ref={params.InputProps.ref}
                        {...params}
                      />
                    </FormControl>
                  )}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some(
                      option => inputValue === option.name
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        isActive: true,
                        id: uuid(),
                        name: `${inputValue}`,
                      });
                    }
                    return filtered;
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={getValues("key_skills")}
                  onChange={async (_, data) => {
                    const lastIndex = data?.length - 1;
                    if (data && data[lastIndex]?.inputValue) {
                      const { data: skill } = await createKeySkills({
                        name: data[lastIndex]?.inputValue,
                        is_active: true,
                      });
                      await getKeySKills();
                      props.field.onChange([
                        ...getValues("key_skills"),
                        {
                          name: skill.key_skill_res.name,
                          value: skill.key_skill_res.id,
                        },
                      ]);
                    } else {
                      props.field.onChange(data);
                    }
                  }}
                />
              );
            }}
            name="key_skills"
            control={control}
            rules={{ required: true }}
          />
        </form>
      </div>
      {console.log("getValues(key_skills) :", getValues("key_skills"))}
      <div className="modal-footer">
        <SecondaryFilledButton
          sx={{ width: "100px", marginRight: "10px" }}
          type="button"
          onClick={() => {
            clearErrors();
            setViewAddModal(!viewAddModal);
            toggle(!isOpen);
            props.setRowData("");
            reset({ text: "", key_skills: [] });
          }}
        >
          Close
        </SecondaryFilledButton>
        <PrimaryButton
          sx={{ width: "150px" }}
          type="button"
          onClick={handleSubmit(onSubmit)}
        >
          Save changes
        </PrimaryButton>
      </div>
    </Modal>
  );
}

export default withRouter(JobCategoryModel);
