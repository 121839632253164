import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  Checkbox,
  FormControl,
  Grid,
  createFilterOptions,
} from "@mui/material";
import {
  GreyTypography,
  MultipleSelectInputs,
  PrimaryAutoComplete,
  PrimaryButton,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  PrimaryTypography,
  SecondaryFilledButton,
  SecondaryFilledChip,
  autocompleteProps,
} from "assets/Mui/globalTheme";
import { Controller, useForm } from "react-hook-form";
import {
  createJobLevelForEveryone,
  getAllJobLevlsWithoutFunctionalArea,
} from "helpers/services/jobAttributes/jobLevels";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { getAllFunctionalArea } from "helpers/services/jobAttributes/functionalArea";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorHandlingToaster from "helpers/Toaster/error";

const style = {
  position: "absolute",
  top: "50%",
  borderRadius: "8px",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function JobRoleCustomModel({ open, handleClose, values }) {
  const validationSchema = yup.object({
    job_role: yup.string().required("Required"),
    functional_area: yup.object().shape({
      name: yup.string().required(),
      value: yup.string().required(),
    }),
  });
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...values,
    },
    resolver: yupResolver(validationSchema),
  });
  React.useEffect(() => {
    setValue("job_role", values?.job_role);
    if (values?.functional_area?.[0])
      setValue("functional_area", values?.functional_area[0]);
  }, [values]);
  async function onSubmit(data) {
    const { data: createdJobLevel, status } = await createJobLevelForEveryone({
      name: data.job_role,
      functional_area_id: data.functional_area.value,
    });
    ErrorHandlingToaster({ data, status });
    dispatch({
      type: "ADDED_JOB_LEVEL",
      payload: {
        name: createdJobLevel?.job_level_res?.name,
        value: createdJobLevel?.job_level_res?.id,
      },
    });
    getAllJobLevels();
  }
  const [functionalAreas, setFunctionalAreas] = React.useState([]);

  React.useEffect(() => {
    getFunctionalAreas();
   
  }, [values]);
  const categoryFilter = createFilterOptions();
  async function getAllJobLevels() {
    const data = await getAllJobLevlsWithoutFunctionalArea();

    dispatch({
      type: "ADD_JOB_LEVEL",
      payload: data?.map(item => ({ name: item.name, value: item.id })),
    });
    handleClose();
  }
  async function getFunctionalAreas() {
    setFunctionalAreas(values?.functional_area);
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <PrimaryTypography variant="h2">Create A Job Role</PrimaryTypography>
          <GreyTypography variant="h6">
            Creating a job role will be reflected in the entire system
          </GreyTypography>
          <br />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12} md={12}>
                <Controller
                  render={props => {
                    return (
                      <PrimaryAutoComplete
                        sx={{ width: "100%" }}
                        {...props}
                        disableCloseOnSelect
                        componentsProps={autocompleteProps}
                        multiple={false}
                        options={functionalAreas}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <SecondaryFilledChip
                              deleteIcon={<FeatherIcon icon={"x"} size="14" />}
                              label={option.name}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={<FeatherIcon icon="square" size="14" />}
                              checkedIcon={
                                <FeatherIcon icon="check-square" size="14" />
                              }
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </li>
                        )}
                        getOptionLabel={option => option?.name}
                        renderInput={params => (
                          <FormControl
                            variant="standard"
                            sx={{ width: "100%" }}
                          >
                            <PrimaryNormalInputLabel
                              shrink
                              htmlFor="functional-outlined"
                              error={errors.functional_area}
                            >
                              Categories
                            </PrimaryNormalInputLabel>
                            <MultipleSelectInputs
                              sx={{
                                ...(errors.functional_area && {
                                  borderColor: "red",
                                }),
                              }}
                              variant="standard"
                              ref={params.InputProps.ref}
                              {...params}
                            />
                          </FormControl>
                        )}
                        filterOptions={(options, params) => {
                          const filtered = categoryFilter(options, params);
                          const { inputValue } = params;
                          const isExisting = options.some(
                            option => inputValue === option.name
                          );
                          if (inputValue !== "" && !isExisting) {
                            filtered.push({
                              inputValue,
                              name: `${inputValue}`,
                            });
                          }

                          return filtered;
                        }}
                        value={getValues("functional_area")}
                        onChange={async (_, data) => {
                          props.field.onChange(data);
                        }}
                      />
                    );
                  }}
                  name="functional_area"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="login-job_role-outlined"
                    error={errors.job_role}
                  >
                    job_role
                  </PrimaryNormalInputLabel>
                  <PrimaryNormalInputs
                    fullWidth
                    placeholder="Enter your job_role"
                    {...register("job_role", { required: true })}
                    id="login-job_role-outlined"
                    helperText={errors?.job_role ? "job_role is required" : ""}
                    autoFocus
                    label="job_role address"
                    type={"text"}
                    name="job_role"
                    error={errors.job_role}
                    variant="outlined"
                  />
                  <PrimaryNormalFormHelperText error={errors.job_role}>
                    {errors.job_role ? "job_role is required Field" : ""}
                  </PrimaryNormalFormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={8}></Grid>
              <Grid item xs={2}>
                <SecondaryFilledButton onClick={handleClose}>
                  Cancel
                </SecondaryFilledButton>
              </Grid>
              <Grid item xs={2}>
                <PrimaryButton type="submit">Submit</PrimaryButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
