import callApi from "helpers/callApi";

export async function getAllActualJobLevel(page=1, rowsPerPage=20,searchText) {
  return await callApi(`actual_job_levels?pageSize=${rowsPerPage}&pageNo=${page}&searchParam=${searchText}`);
}

export async function createActualJobLevels(data) {
  const result = { name: data?.name };
  return await callApi("actual_job_levels", result, "POST");
}

export async function updateActualJobLevel(data, id) {
  // return await callApi("actual_job_levels/" + id, data, "PUT");
  return await callApi(`actual_job_levels/${id}`, data, "PUT");
}
