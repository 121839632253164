import React, { useState, useEffect } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useHistory,useLocation } from "react-router-dom";
import SearchParamHook from "hooks/searchParams";

function CustomPagination({count,page,setPage,setPages}) {
  const query = new URLSearchParams(location.search);
  const searchParamsHook = SearchParamHook()
  // const [page, setPage] = React.useState(1);
//   const page = parseInt(query.get("page") || "1", 10);
const history = useHistory()
const {search} = useLocation()

const handleChange = (event, value) => {
    setPage(value);
   
    if(setPages){
      setPages(value)
    }
    setTimeout(()=>{
      const searchParams = new URLSearchParams(search)
      searchParams.set("page", value)
      history.push({
          search: searchParams.toString()
      })
    },800)
 
    
  };

  return (
    <>
     <Stack spacing={2} direction="row" justifyContent="end" sx={{paddingTop:"5px"}}>
      <Pagination
        count={count}
        page={Number(page) ?? 1}
        showFirstButton
        showLastButton
        onChange={handleChange} 
        sx={{
            "& .MuiPaginationItem-root": {
              "&:hover": {
                backgroundColor: "#cde2e7",
                color: "#066a4c",
              },
              "&.Mui-selected": {
                backgroundColor: "#024430",
                color: "#ffe7bb",
                "&:hover": {
                  backgroundColor: "#cde2e7",
                  color: "#066a4c",
                },
              },
            },
          }}
      />
      </Stack>
    </>
  );
}

export default CustomPagination;
