import {
  Divider,
  Grid,
  InputLabel,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { InputAdornment, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useState } from "react";
import { useSelector } from "react-redux";
import { updateCompanies } from "helpers/services/companies/company";
import {
  BlackTypography,
  GrayDivider,
  GrayLiniearDivider,
  GreyTypography,
  PrimaryButton,
  PrimaryDivider,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputs,
  PrimaryTypography,
  SecondaryFilledButton,
} from "assets/Mui/globalTheme";
import { updateCommonUser, updateCommonUserPassword } from "helpers/services/common";
import { toast } from "react-hot-toast";
import { Watch } from "feather-icons-react/build/IconComponents";

export default function PasswordChange() {



  const user = useSelector((state) => state.Login);
  const [showPassword, setShowPassword] = useState(false);
  const [cShowPassword, setCShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
    watch
  } = useForm();

  function handleShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleShowCurrentPassword() {
    setShowCurrentPassword(!showCurrentPassword);
  }

  function handleShowCPassword() {
    setCShowPassword(!cShowPassword);
  }

  const handleChangePassword = async formData => {
    const res = await updateCommonUserPassword({
      old_password: formData.current_password,
      password: formData.password,
    });
    if (res?.status === 200) {
      toast.success(res?.data?.return_message);
      reset();
    } 
  };
  

  return (
    <Paper elevation={"0"} sx={{ padding: "20px" }}>
      <BlackTypography className="mb-2" variant="h2">
        Password
      </BlackTypography>
      <GreyTypography variant="h6" className="mb-4">
        Please Enter Your Current Password to Change Your Password
      </GreyTypography>
      {/* <GrayLiniearDivider className="mb-2" /> */}
      <Form onSubmit={handleSubmit(handleChangePassword)}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <InputLabel>Current Password</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <PrimaryNormalInputs
              placeholder="Current Password"
              {...register('current_password', {
                required: true,
              })}
              type={showCurrentPassword ? 'text' : 'password'}
              error={Boolean(errors?.current_password)}
              helperText={errors?.current_password?.message}
              endAdornment={
                <InputAdornment
                  sx={{
                    position: "absolute",
                    right: "10px",
                  }}
                  position="end"
                  onClick={handleShowCurrentPassword}
                >
                  {showCurrentPassword ? (
                    <FeatherIcon icon="eye" size={"14"} />
                  ) : (
                    <FeatherIcon icon="eye-off" size={"14"} />
                  )}{" "}
                </InputAdornment>
              }
              variant="outlined"
              fullWidth
            />
            <PrimaryNormalFormHelperText error={errors?.current_password}>
              {errors?.current_password?.type === "required" ? "Current Password is required" : errors?.current_password?.message}
            </PrimaryNormalFormHelperText>
          </Grid>
          <Grid item xs={6}>
            <InputLabel>New Password</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <PrimaryNormalInputs
              placeholder="New Password"
              {...register('password', {
                required: true,
                pattern: {
                  value:
                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{5,10}$/,
                  message:
                    'Password must contain at least one letter, one number, and one special character',
                },

                validate: (value) => {
                  if (/\s/.test(value)) {
                    return 'Password should not contain spaces';
                  }
                  if (value === getValues('current_password')) {
                    return 'New password should not match with the current password';
                  }
                  return undefined;
                },
              })}
              type={showPassword ? 'text' : 'password'}
              error={Boolean(errors?.password)}
              helperText={errors?.password?.message}
              endAdornment={
                <InputAdornment
                  sx={{
                    position: "absolute",
                    right: "10px",
                  }}
                  position="end"
                  onClick={handleShowPassword}
                >
                  {showPassword ? (
                    <FeatherIcon icon="eye" size={"14"} />
                  ) : (
                    <FeatherIcon icon="eye-off" size={"14"} />
                  )}{" "}
                </InputAdornment>
              }
              variant="outlined"
              fullWidth
            />

            <PrimaryNormalFormHelperText error={errors?.password}>
              {errors?.password?.type === "required" ? 'New Password is required' : errors?.password?.message}
            </PrimaryNormalFormHelperText>

          </Grid>{" "}
          <Grid item xs={6}>
            <InputLabel>Confirm New Password</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <PrimaryNormalInputs
              placeholder="Confirm New Password"
              {...register('cpassword', {
                required: true,
                validate: (value) => value !== "" ? value === watch("password") : false 
              })}
              type={cShowPassword ? 'text' : 'password'}
              error={Boolean(errors?.cpassword?.type === "required" || errors?.cpassword?.type === "validate" ? true : false)}
              endAdornment={
                <InputAdornment
                  sx={{
                    position: "absolute",
                    right: "10px",
                  }}
                  position="end"
                  onClick={handleShowCPassword}
                >
                  {cShowPassword ? (
                    <FeatherIcon icon="eye" size={"14"} />
                  ) : (
                    <FeatherIcon icon="eye-off" size={"14"} />
                  )}
                </InputAdornment>
              }

              variant="outlined"
              fullWidth
            />
            <PrimaryNormalFormHelperText error={errors?.cpassword}>
              {errors?.cpassword?.type === "required" ? "Confirm Password is required" : errors?.cpassword?.type === "validate" ? "New Password and Confirm Password should match" : errors?.cpassword?.message}
            </PrimaryNormalFormHelperText>
          </Grid>
          <Stack
            sx={{ width: "100%", marginTop: "20px" }}
            alignItems="center"
            direction="row"
            justifyContent={"flex-end"}
            spacing={2}
          >
            <PrimaryButton type="submit" sx={{ width: "200px" }}>
              Save Changes
            </PrimaryButton>
          </Stack>
        </Grid>
      </Form>
    </Paper>
  );
}