import { toast } from "react-hot-toast";
import React, { useState, useEffect } from "react";
import JobCategoryModel from "../../components/Common/models/JobCategoryModal";
import {
  createAndEditFunctionalArea,
  deleteFunctionalArea,
  getAllFunctionalArea,
} from "../../helpers/services/jobAttributes/functionalArea";
import { PrimaryButton, PrimarySwitch } from "assets/Mui/globalTheme";
import { Pagination } from "@mui/material";
import { PrimaryDataGrid } from "assets/Mui/globalTheme";
import SearchParamHook from "hooks/searchParams";

import { useLocation } from "react-router-dom";
import { useDebounce } from "hooks/useDebounce";
import CustomNoRowsOverlay from "common/data/NoRowsFoundDatagrid/NoRowsFound";
import { useHistory } from "react-router-dom";
import GlobalTableTools from "hooks/GlobalTableTools";

import LoaderIcon from "utils/icons/loaderIcon";
import TablePopper from "components/table/header/popper";
import { imageURL } from "common/constants/commonURLS";

const FunctionalArea = () => {
  const searchParamsHook = SearchParamHook();
  const { search } = useLocation();
  const history = useHistory();
  const [viewAddModal, setViewAddModal] = React.useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState(false);
  const [index, setIndex] = useState(-1);
  const [defaultVal, setDefaultVal] = useState(false);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [rowData, setRowData] = useState("");
  const [purpose, setPurpose] = useState(""); // Initialize purpose state
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openSearch, setOpenSearch] = useState(true);
  const [searchRole, setSearchRole] = useState("");
  const searchParams = SearchParamHook();

  const searchTextParam = searchParamsHook.get("searchParam");
  const currentPage = searchParamsHook.get("page");
  const [page, setPage] = React.useState(currentPage ?? 1);
  const [searchText, setSearchText] = useState(searchTextParam ?? "");
  const [totalCount, setTotalCount] = React.useState("");
  const rowsPerPage = 20;

  const fetchData = async () => {
    setIsLoading(true);
    const currentPage = searchParamsHook.get("page") ?? page;
    const response = await getAllFunctionalArea(
      currentPage,
      rowsPerPage,
      searchText
    );

    const result =
      response?.data?.data_payload?.length > 0 &&
      response?.data?.data_payload?.map((item, index) => ({
        id: item?.id,

        industries: `${item?.name}`,

        is_active: item.is_active,
        image: item.image,
        skills: item.skills,
      }));

    setData(result);
    setTotalCount(response?.data?.total_page_size);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggle = (
    purpose = "",
    id = "",
    name = "",
    defaultValue = "",
    active = "",
    index
  ) => {
    setPurpose(purpose);
    setName(name);
    setIndex(index);
    setId(id);
    setDefaultVal(defaultValue);
    setActive(active);
    setIsOpen(!isOpen);
    setViewAddModal(!viewAddModal);
  };

  const handleDelete = (index, id) => {
    deleteFunctionalArea(id);
    const result = data.map(each =>
      each.id === id ? { ...each, is_active: !each.is_active } : each
    );
    setData(result);
    toast.error("Disabled");
  };

  const handleRestore = async (item, index) => {
    const sample = {
      name: item.industries,
      active: !item.is_active,
      image: item.image ?? "",
      skills: item.skills ? item.skills?.join(",") : "",
    };
    let response = await createAndEditFunctionalArea(
      sample,
      "Edit",
      index,
      item.id
    );
    const result = response
      ? data.map(each =>
          each.id === item.id ? { ...each, is_active: !each.is_active } : each
        )
      : data;
    setData(result);
    if (response) {
      toast.success("Enabled");
    }
  };

  const handleAction = (item, index) => {
    item.is_active ? handleDelete(index, item.id) : handleRestore(item, index);
  };

  const updateFunc = (sample, type, index, id) => {
    const editedId = id || sample?.id;
    createAndEditFunctionalArea(sample, type, index, editedId);

    if (type === "Create") {
      setData([...data, sample]);
      fetchData();
    } else {
      const result = data.map(each =>
        each.id === sample.id
          ? { ...each, name: sample.name, is_active: sample.is_active }
          : each
      );
      setData(result);
      fetchData();
    }
  };

  const handleSearchOpen = () => {
    setOpenSearch(!openSearch);
    // setSearchRole('');
  };

  const handleSearch = e => {
    setSearchRole(e.target.value);
  };

  const searchFilterData = data => {
    const searchWord = searchRole?.toLowerCase();
    const searchPattern = data?.filter(item => {
      const roleName = item?.name?.toLowerCase();

      if (roleName?.includes(searchWord)) {
        return item;
      }
    });
    return searchPattern;
  };

  /* For pagination purpose in progress */

  // useEffect(()=>{
  //   getAllFunctionalArea(1, 20, "")
  // },[searchParams.get("page")])

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    searchParams.set("searchParam", searchText);
    history.push({
      search: searchParams.toString(),
    });

    const fetchData = async () => {
      setIsLoading(true);
      const currentPage = searchParamsHook.get("page") ?? page;
      const response = await getAllFunctionalArea(
        currentPage,
        rowsPerPage,
        searchText
      );

      const items = response?.data?.data_payload?.map(item => {
        return {
          id: item.id,
          name: item.name,
          default: true,
          country: item.country,
          is_active: item.is_active,
          image: item.image,
          skills: item.skills,
        };
      });
      setData(items);
      setTotalCount(response?.data?.total_page_size);
      setIsLoading(false);
    };

    fetchData();
  }, [searchParams.get("page"), searchParamsHook.get("searchParam")]);

  const debouncedQuery = useDebounce(searchText, 500);
  // useEffect(() => {

  //   const searchParams = new URLSearchParams(search);
  //   searchParams.set("searchParam", debouncedQuery);
  //   searchParams.set("page",1)
  //   history.push({
  //     search: searchParams.toString(),
  //   });
  // }, [debouncedQuery]);

  useEffect(() => {
    if (debouncedQuery) {
      const searchParams = new URLSearchParams(search);
      searchParams.set("searchParam", debouncedQuery);
      searchParams.set("page", 1);
      history.push({
        search: searchParams.toString(),
      });
    } else {
      const searchParams = new URLSearchParams(search);
      searchParams.set("searchParam", debouncedQuery);
      // setPage(1)
      history.push({
        search: searchParams.toString(),
      });
    }
  }, [debouncedQuery]);

  /* For pagination purpose in progress */

  /*Columns Creation@@ */

  React.useEffect(() => {
    const searchParams = new URLSearchParams(search);
    searchParams.set("page", page);
    history.push({
      search: searchParams.toString(),
    });

    fetchData();
  }, [page, searchParamsHook.get("searchParam")]);

  const columns = [
    {
      field: "image",
      headerName: "Image",
      headerClassName: "super-app-theme--header",
      width: 300,
      renderCell: params => {
        console.log(params.image);
        if (params.row.image) {
          return (
            <img
              src={imageURL + params.row.image}
              className="category-table-image"
              alt=""
            />
          );
        }

        return "";
      },
    },
    {
      field: "industries",
      headerName: "Categories",
      headerClassName: "super-app-theme--header",
      width: 300,
    },
    {
      field: "skills",
      headerName: "Skills",
      headerClassName: "super-app-theme--header",
      width: 300,
    },
    {
      field: "is_active",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: params => {
        const rowIndex = data.findIndex(row => row.id === params.row.id);

        return (
          <PrimarySwitch
            sx={{ m: 1 }}
            checked={params.row.is_active}
            onChange={async e => {
              handleAction(params.row, rowIndex);
            }}
          />
        );
      },
    },

    {
      field: "Action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      width: 600,

      renderCell: params => {
        const index = data.findIndex(row => row.id === params.row.id);
        return (
          <PrimaryButton
            sx={{ width: "100px" }}
            onClick={() => {
              toggle(
                "Edit",
                params.row.id,
                params.row.industries,
                params.row.default,
                params.row.is_active,
                index
              );
              setRowData(params.row);
            }}
          >
            Edit
          </PrimaryButton>
        );
      },
    },
  ];

  const filteredColumns = columns.filter(Boolean);

  // const filteredData = searchFilterData(data);

  // return (
  //   <Container fluid>
  //     <CommonModel
  //       isOpen={isOpen}
  //       toggle={toggle}
  //       headTag={'Category'}
  //       purpose={purpose}
  //       index={index}
  //       title={name}
  //       defaultValue={defaultVal}
  //       active={active}
  //       update={updateFunc}
  //       id={id}
  //     />
  //     <div md={6} style={{ padding: '40px' }} className="header_jobs_sticky">
  //       <PrimaryButton sx={{ width: '200px' }} onClick={() => toggle('Create')}>
  //         Create Category
  //       </PrimaryButton>
  //       <Stack alignItems={'center'} direction={'row'} spacing={1.6}>
  //         {openSearch ? (
  //           <SearchIcon onClick={handleSearchOpen} />
  //         ) : (
  //           <StageSearchInput
  //             placeholder="search..."
  //             // onChange={(e) => handleSearch(e)}
  //             value={searchText}
  //             onChange={(e) => {
  //               e.stopPropagation();
  //               setSearchText(e.target.value);
  //             }}
  //             endAdornment={
  //               <IconButton edge="end"
  //               // onClick={handleSearchOpen}
  //               onClick={() => {
  //                 handleSearchOpen();
  //                 setSearchText("");
  //               }}
  //               >
  //                 <ClearIcon />
  //               </IconButton>
  //             }
  //           />
  //         )}
  //       </Stack>
  //     </div>
  //     <Row style={{ marginTop: '10px', marginLeft: '20px' }}>
  //       <Row className="align-items-center "></Row>
  //       <div className="table_cont">
  //         <div className="table-responsive">
  //           <Card>
  //             <CardBody style={{ height: 'calc(100vh - 270px)', overflowY: 'scroll' }}>
  //               <Table className="table mb-0">
  //                 <TableHead>
  //                   <TableRow>
  //                     <TableCell>Categories</TableCell>
  //                     <TableCell>Status</TableCell>
  //                     <TableCell>Action</TableCell>
  //                   </TableRow>
  //                 </TableHead>
  //                 <tbody>
  //                   {isLoading ? (
  //                     <BubbleLoader />
  //                   ) : (

  //                     data?.length > 0?
  //                     data?.map((item, index) => (
  //                       <TableRow key={item.id}>
  //                         <TableCell>{item.name}</TableCell>

  //                         <TableCell className="active_td">
  //                           <FormGroup>
  //                             <FormControlLabel
  //                               control={
  //                                 <PrimarySwitch
  //                                   sx={{ m: 1 }}
  //                                   checked={item.is_active}
  //                                   onChange={async (e) => {
  //                                     handleAction(item, index);
  //                                   }}
  //                                 />
  //                               }
  //                             />
  //                           </FormGroup>
  //                         </TableCell>
  //                         <TableCell className="col_btn">
  //                           <PrimaryButton
  //                             style={{ width: '100px' }}
  //                             onClick={() => toggle('Edit', item.id, item.name, item.default, item.is_active, index)}
  //                           >
  //                             Edit
  //                           </PrimaryButton>
  //                         </TableCell>
  //                       </TableRow>
  //                     ))
  //                     :     (<TableRow>
  //                       <TableCell colSpan={5} align="center">
  //                         <CustomNoRowsOverlay />
  //                       </TableCell>
  //                     </TableRow>)

  //                   )}
  //                 </tbody>
  //               </Table>
  //             </CardBody>
  //           </Card>
  //         </div>
  //       </div>

  //       <CustomPagination count={totalCount} page={page} setPage={setPage} />
  //     </Row>
  //   </Container>
  // );

  return (
    <>
      <JobCategoryModel
        // isOpen={isOpen}
        toggle={toggle}
        isOpen={viewAddModal}
        viewAddModal={viewAddModal}
        setViewAddModal={setViewAddModal}
        headTag={"Categories"}
        purpose={purpose}
        index={index}
        id={id}
        title={name}
        active={active}
        update={updateFunc}
        rowData={rowData}
        setRowData={setRowData}
      />
      <div
        style={{
          padding: "10px",
          height: "91vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <GlobalTableTools
          tableHeading={"Categories"}
          toggel={true}
          toggleFunc={toggle}
          isJobAttributes={true}
          setViewAddModal={setViewAddModal}
          downloadOption={false}
        />
        <PrimaryDataGrid
          fileName="Industries"
          // onCellClick={handleCellClick}
          loading={isLoading}
          columns={filteredColumns}
          rows={data}
          slots={{
            loadIcon: LoaderIcon,
            baseButton: PrimaryButton,
            basePopper: TablePopper,
            baseSwitch: PrimarySwitch,

            // toolbar:CustomToolbar,
            noRowsOverlay: CustomNoRowsOverlay,
            pagination: props => (
              <Pagination
                {...props}
                // color="primary"
                // count={Math.ceil(rowsData.length / rowsPerPage)}
                count={totalCount}
                page={page}
                rowsPerPage={rowsPerPage}
                onChange={(_, newPage) => setPage(newPage)}
                showFirstButton
                showLastButton
                sx={{
                  "& .MuiPaginationItem-root": {
                    "&:hover": {
                      backgroundColor: "#cde2e7",
                      color: "#066a4c",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "#024430",
                      color: "#ffe7bb",
                      "&:hover": {
                        backgroundColor: "#cde2e7",
                        color: "#066a4c",
                      },
                    },
                  },
                }}
              />
            ),
          }}
          slotProps={{
            baseButton: { style: { width: "100px" } },
            panel: {
              style: {
                right: "0px !important",
                transform: "none !important",
                inset: "none !important",
                top: "138px !important",
              },
            },
            filterPanel: {
              style: {
                right: "0px !important",
                transform: "none !important",
                inset: "none !important",
                top: "138px !important",
              },
            },
            toolbar: { style: {} },
            preferencesPanel: {
              style: {
                right: "0px !important",
                transform: "none !important",
                inset: "none !important",
                top: "138px !important",
              },
            },
          }}
          sx={{
            "& .super-app-theme--header": {
              backgroundColor: "rgba(250, 250, 250, 1)",
            },
          }}
        />
      </div>
    </>
  );
};

export default FunctionalArea;
