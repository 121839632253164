import { parsePhoneNumberFromString } from 'libphonenumber-js';


function ParseCountryCode(phoneNumber){

    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
    
    if (parsedPhoneNumber) {
        const countryCode = parsedPhoneNumber.country;
        return `+${countryCode}`
    } else {
       return "IN"
    }
    
}

export default ParseCountryCode;