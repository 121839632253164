const { default: callApi } = require("helpers/callApi");

export const getAllDashboardDetails = async()=>{
    return await callApi(`admin_dashboard/get_admin_dashboard_details`)
}



/* Company dashboard api's */

export const getTotalJobPosts = async()=>{
    return await callApi(`company_dashboard/get_total_job_posts`)
}

export const getTotalApplicants = async()=>{
    return await callApi(`company_dashboard/get_applicants`)
}

export const getTotalHiredCount = async()=>{
    return await callApi(`company_dashboard/get_hired_count`)
}

export const getJobViewsCount = async()=>{
    return await callApi(`company_dashboard/get_job_views_count`)
}

export const getPendingJobsCountForCompany = async()=>{
    return await callApi(`company_dashboard/get_pending_job_posts`)
}

export const getClosedJobsCountForCompany = async()=>{
    return await callApi(`company_dashboard/get_closed_job_posts`)
}

export const getOfferedJobsCountForCompany = async()=>{
    return await callApi(`company_dashboard/get_offered_job_posts`)
}

export const getSubmittedProfileCountForCompany = async()=>{
    return await callApi(`company_dashboard/get_submitted_job_posts`)
}


/* Partners dashboard api */

export const getActiveRoles = async()=>{
    return await callApi(`dashboard/get_active_roles`)
}

export const getEvaluatedApplicants = async()=>{
    return await callApi(`dashboard/get_evaluated_applicants`)
}

export const getOnBoardedCount = async()=>{
    return await callApi(`dashboard/get_onboarded_applicants`)
}

export const getEarningsCountForPartners = async()=>{
    return await callApi(`dashboard/get_earnings_count`)
}


/* Recruitment company api's */

export const getSourcedProfiles = async()=>{
    return await callApi(`rc_dashboard/get_profile_sourced`)
}

export const getHiredCountRc = async()=>{
    return await callApi(`rc_dashboard/hired`)
}

export const getRcSourceCount = async()=>{
    return await callApi(`company_dashboard/get_rc_submitted_job_posts`)
}

export const getRcOnBoardedCount = async()=>{
    return await callApi(`dashboard/get_offered_job_posts`)
}



/*For Admin user type api's */

export const getNoOfCompanies = async()=>{
    return await callApi(`dashboard/get_no_of_companies?searchParam=`)
}

export const getActiveJobs = async()=>{
    return await callApi(`dashboard/get_admin_active_jobs_count`)
}

export const getNoOfEvaluators = async()=>{
    return await callApi(`dashboard/get_evaluators_count`)
}

export const getBackgroundCount = async()=>{
    return await callApi(`dashboard/get_bgv_partners_count`)
}

export const getRecruitmentCount = async()=>{
    return await callApi(`dashboard/get_recruitment_partners_count`)
}


export const getOverallHiredCount = async()=>{
    return await callApi(`dashboard/get_admin_hired_count`)
}


/*Account manager user type api's */

export const getActiveJobsCount = async()=>{
    return await callApi(`dashboard/get_active_jobs_count`)
}

export const getAssociatedPartners = async()=>{
    return await callApi(`dashboard/get_partners_associated_with_company`)
}

export const getHiredCandidates = async()=>{
    return await callApi(`dashboard/get_hired`)
}

export const getPendingJobsCount = async()=>{
    return await callApi(`dashboard/get_pending_job_posts`)
}

export const getClosedJobsCount = async()=>{
    return await callApi(`dashboard/get_closed_job_posts`)
}


/*Bgv partner api's */

export const getEvalCandByBgv = async()=>{
    return await callApi(`dashboard/get_evaluated_applicants`)
}

export const getOnBoardedCandByBgv = async()=>{
    return await callApi(`dashboard/get_onboarded_applicants`)
}