import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useFormContext } from "react-hook-form";
import { useForm, Controller } from "react-hook-form";
import { handleApproveJob } from "helpers/services/adminStaff/AccountManager.js/ApproveJobs";
import getSymbolFromCurrency from "currency-symbol-map";
import ErrorHandlingToaster from "helpers/Toaster/error";
import { toast } from "react-hot-toast";
import {
  PrimaryNormalInputs,
  PrimaryTypography,
  BlackTypography,
  MiddleDivider,
  CommissionTypography,
  GreyTypography,
  PrimaryCommissionInputs,
  PrimaryNormalInputLabel,
  MenuProps,
  PrimaryNormalFormHelperText,
  PrimaryButton,
  PrimarySwitch,
} from "assets/Mui/globalTheme";
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
  TextField,
  makeStyles,
  Card,
  CardContent,
  Paper,
} from "@mui/material";
import { updateCommissions } from "helpers/services/CommissionsApi/commissionUpdate";
import { getCommissionById } from "helpers/services/CommissionsApi/commissionUpdate";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function CommissionModal({
  isOpen,
  title,
  toggle,
  tableData,
  jobpostId,
  reCall,
}) {
  const {
    control,
    handleSubmit,
    register,
    getValues,
    setValue,
    watch,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      selfReferPayoutType: "",
      referredPayoutType: "",
      recruitmentCompanyPayoutTypePerProfile: "",
      recruitmentCompanyPayoutTypeOnboard: "",
      screeningPerProfile: false,
      screeningOnboard: false,
      technicalPerProfile: false,
      technicalOnboard: false,
      moderatorPerProfile: false,
      moderatorOnboard: false,
      backgroundPerProfile: false,
      backgroundOnboard: false,
      recruitmentCompanyPerProfile: false,
      recruitmentCompanyOnboard: false,
    },
  });

  const [showCommission, setShowCommission] = useState(true);

  const [showJoineeCommission, setShowJoineeCommission] = useState(true);
  const [joineeCommissionRate, setJoineeCommissionRate] = useState(
    tableData?.commission_rate !== (null || 0)
      ? tableData?.commission_rate?.toFixed(2)
      : 8.3
  );
  const [showFinalFlatRate, setShowFinalFlatRate] = useState("");

  const [loading, setLoading] = useState(false);

  const [jobpostIdCom, setJobPostIdCom] = useState("");

  const payouts = [
    { name: "Per Month", value: "per_month" },
    { name: "Per Year", value: "per_year" },
    { name: "Per Quarterly", value: "per_quarterly" },
    { name: "One Time", value: "one_time" },
  ];

  const contractPayouts = [
    { name: "Bi Weekly", value: "Bi_Weekly" },
    // { name: "Annual", value: "Annual" },
    { name: "Monthly", value: "Monthly" },
    // { name: "Hourly", value: "Hourly" },
  ];

  const [open, setOpen] = React.useState(false);
  const [showFlatRate, setShowFlatRate] = useState({
    referredFlatRate: "",
    selfReferralFlatRate: "",
    screeningFlatRatePerProfile: "",
    screeningFlatRateOnboard: "",
    technicalFlatRatePerProfile: "",
    technicalFlatRateOnboard: "",
    moderatorFlatRatePerProfile: "",
    moderatorFlatRateOnboard: "",
    recruitmentCompanyFlatRatePerProfile: "",
    recruitmentCompanyFlatRate: "",
    backgroundFlatRatePerProfile: "",
    backgroundFlatRateOnboard: "",
    joineeCommissionRate: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    toggle();
  };

  // React.useEffect(()=>{
  //   setJobPostIdCom(jobpostId)
  // },[jobpostId])

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const fetchCommissionById = async id => {
    setLoading(true);

    if (id) {
      try {
        const { status, data } = await getCommissionById(id);

        const selfReferPayoutType = data?.data_payload
          ?.refer_self_commission?.[0]?.payout
          ? data?.data_payload?.refer_self_commission?.[0]?.payout
          : "";

        if (data?.data_payload) {
          // const selfReferPayoutType = data?.data_payload?.refer_self_commission?.[0]?.payout?data?.data_payload?.refer_self_commission?.[0]?.payout:""

          setValue("selfReferPayoutType", selfReferPayoutType);
          // setValue("selfReferPayoutType", payoutValue.trim());

          setValue(
            "selfReferralFlatRate",
            data?.data_payload?.refer_self_commission?.[0]?.flat_rate
          );
          setValue(
            "referredPayoutType",
            data?.data_payload?.refer_now_commission?.[0]?.payout
          );
          setValue(
            "referredFlatRate",
            data?.data_payload?.refer_now_commission?.[0]?.flat_rate
          );
          setValue(
            "screeningPerProfile",

            data?.data_payload?.sp_commission?.[0]?.flat_rate !== null ||
              undefined
          );

          setValue(
            "screeningOnboard",

            data?.data_payload?.sp_commission?.[1]?.flat_rate !== null ||
              undefined
          );
          setValue(
            "technicalPerProfile",

            data?.data_payload?.te_commission?.[0]?.flat_rate !== null ||
              undefined
          );
          setValue(
            "technicalOnboard",

            data?.data_payload?.te_commission?.[1]?.flat_rate !== null ||
              undefined
          );
          setValue(
            "moderatorPerProfile",

            data?.data_payload?.me_commission?.[0]?.flat_rate !== null ||
              undefined
          );
          setValue(
            "moderatorOnboard",

            data?.data_payload?.me_commission?.[1]?.flat_rate !== null ||
              undefined
          );

          setValue(
            "backgroundPerProfile",

            data?.data_payload?.bgv_commission?.[0]?.flat_rate !== null ||
              undefined
          );
          setValue(
            "backgroundOnboard",
            data?.data_payload?.bgv_commission?.[1]?.flat_rate !== null ||
              undefined
          );

          setValue(
            "recruitmentCompanyPerProfile",

            data?.data_payload?.rc_commission?.[0]?.flat_rate !== null ||
              undefined
          );
          setValue(
            "recruitmentCompanyOnboard",
            data?.data_payload?.rc_commission?.[1]?.flat_rate !== null ||
              undefined
          );

          // setValue(
          //   "recruitmentCompanyPayoutType",
          //   data?.data_payload?.rc_commission?.[0]?.payout
          // );
          // setValue(
          //   "recruitmentCompanyFlatRate",
          //   data?.data_payload?.rc_commission?.[0]?.flat_rate
          // );

          setValue(
            "recruitmentCompanyPayoutTypePerProfile",
            data?.data_payload?.rc_commission?.[0]?.payout
          );
          setValue(
            "recruitmentCompanyFlatRatePerProfile",
            data?.data_payload?.rc_commission?.[0]?.flat_rate
          );

          setValue(
            "recruitmentCompanyPayoutTypeOnboard",
            data?.data_payload?.rc_commission?.[1]?.payout
          );
          setValue(
            "recruitmentCompanyFlatRate",
            data?.data_payload?.rc_commission?.[1]?.flat_rate
          );

          setValue(
            "screeningFlatRatePerProfile",
            data?.data_payload?.sp_commission?.[0]?.flat_rate
          );
          setValue(
            "screeningFlatRateOnboard",
            data?.data_payload?.sp_commission?.[1]?.flat_rate
          );
          setValue(
            "technicalFlatRatePerProfile",
            data?.data_payload?.te_commission?.[0]?.flat_rate
          );
          setValue(
            "technicalFlatRateOnboard",
            data?.data_payload?.te_commission?.[1]?.flat_rate
          );
          setValue(
            "moderatorFlatRatePerProfile",
            data?.data_payload?.me_commission?.[0]?.flat_rate
          );
          setValue(
            "moderatorFlatRateOnboard",
            data?.data_payload?.me_commission?.[1]?.flat_rate
          );
          setValue(
            "bgvFlatRate",
            data?.data_payload?.bgv_commission?.[0]?.flat_rate
          );

          setValue(
            "backgroundFlatRatePerProfile",
            data?.data_payload?.bgv_commission?.[0]?.flat_rate
          );
          setValue(
            "backgroundFlatRateOnboard",
            data?.data_payload?.bgv_commission?.[1]?.flat_rate
          );

          setValue("minSalary", data?.data_payload?.min_sal_by_am);
          setValue("maxSalary", data?.data_payload?.max_sal_by_am);
          setValue("minSalary", data?.data_payload?.min_sal_by_am);
        }

        if (status === 200) {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching commission data:", error);
      } finally {
        console.log("");
      }
    }
  };

  const commissionRate =
    tableData?.commission_rate !== (null || 0)
      ? tableData?.commission_rate?.toFixed(2)
      : 8.3;
      
  // console.log(tableData,"tableData")

  React.useEffect(() => {
    if (tableData) {
      setValue("minSalary", tableData?.min_sal);
      setValue("maxSalary", tableData?.max_sal);
      setValue("joineeCommissionRate", commissionRate);
    }
  }, [tableData]);
  React.useEffect(() => {
    fetchCommissionById(jobpostId);
  }, [jobpostId !== undefined]);

  const onSubmit = async data => {
    // Handle form submission logic here

    /*commissions payload */

    delete data?.showJoineeCommission;

    const commissionDataFormat = {
      min_sal_by_am: data?.minSalary !== undefined ? data?.minSalary : 0,
      max_sal_by_am: data?.maxSalary !== undefined ? data?.maxSalary : 0,
      is_commission_visible_to_js: showCommission,

      // is_joinee_commission: true,

      joinee_commission_rate: data?.joineeCommissionRate
        ? Number(data?.joineeCommissionRate)
        : Number(commissionRate),
      joinee_payout: data?.joinee_payout,
      joinee_commission_rate_type: "Percentage",

      // refer_self_commission: [
      //   {

      //     payout: showCommission ? data?.selfReferPayoutType || "" : "",
      //     flat_rate: showCommission ? data?.selfReferralFlatRate || "" : "",
      //     commission_type: "flat",
      //     is_refer_self: showCommission,
      //   },
      // ],

      refer_self_commission:
        showCommission && data?.selfReferralFlatRate >= 0
          ? [
              {
                payout: showCommission ? data?.selfReferPayoutType || "" : "",
                flat_rate: showCommission
                  ? data?.selfReferralFlatRate || ""
                  : "",
                commission_type: "flat",
                is_refer_self: showCommission,
              },
            ]
          : [],

      refer_now_commission: [
        {
          payout: data?.referredPayoutType ?? "",
          flat_rate: data?.referredFlatRate ?? "",
          commission_type: "flat",
        },
      ],
      sp_commission: [
        {
          payout: "per_profile",
          flat_rate: data?.screeningFlatRatePerProfile ?? "",
          commission_type: "flat",
        },
        {
          payout: "on_board",
          flat_rate: data?.screeningFlatRateOnboard ?? "",
          commission_type: "flat",
        },
      ],
      te_commission: [
        {
          payout: "per_profile",
          flat_rate: data?.technicalFlatRatePerProfile ?? "",
          commission_type: "flat",
        },
        {
          payout: "on_board",
          flat_rate: data?.technicalFlatRateOnboard ?? "",
          commission_type: "flat",
        },
      ],
      me_commission: [
        {
          payout: "per_profile",
          flat_rate: data?.moderatorFlatRatePerProfile ?? "",
          commission_type: "flat",
        },
        {
          payout: "on_board",
          flat_rate: data?.moderatorFlatRateOnboard ?? "",
          commission_type: "flat",
        },
      ],
      bgv_commission: [
        // {
        //   payout: "one_time",
        //   flat_rate: data?.bgvFlatRate ?? "",
        //   commission_type: "flat",
        // },

        {
          payout: "per_profile",
          flat_rate: data?.backgroundFlatRatePerProfile ?? "",
          commission_type: "flat",
        },
        {
          payout: "on_board",
          flat_rate: data?.backgroundFlatRateOnboard ?? "",
          commission_type: "flat",
        },
      ],
      rc_commission: [
        {
          payout: data?.recruitmentCompanyPayoutTypePerProfile ?? "",
          flat_rate: data?.recruitmentCompanyFlatRatePerProfile ?? "",
          commission_type: "flat",
        },
        {
          payout: data?.recruitmentCompanyPayoutTypeOnboard ?? "",
          flat_rate: data?.recruitmentCompanyFlatRate ?? "",
          commission_type: "flat",
        },
      ],
    };

    const updateCommissionsData = await updateCommissions(
      commissionDataFormat,
      tableData?.id
    );
    const { status } = updateCommissionsData;
    if (status === 200) {
      toast.success("Submitted successfully");
    } else {
      toast.error("Something went wrong");
    }
    if (!jobpostId) {
      reCall();
    }

    handleClose();
  };

  const jobtype = tableData?.job_type?.replace("_", "");

  /*Note for contract job we are using 5% commission rate as default */

  const currentFlatRate = Math.ceil(
    // tableData?.max_sal *
    //   (commissionRate / 100)

    tableData?.max_sal *
      ((watch("joineeCommissionRate")
        ? watch("joineeCommissionRate")
        : commissionRate) /
        100)
  );

  const handleChange = data => {
    if (Number(data.value) > data.availFaltRate) {
      // setError(data.type, {
      //   type: "manual",
      //   message: "Limit exceeded",
      // });
      return
    } else if (Number(data.value) <= Number(data.availFaltRate)) {
      setShowFlatRate(prev => ({
        ...prev,
        [data.type]: data.availFaltRate,
      }));

      clearErrors(data.type);
    } else {
      clearErrors(data.type);
    }
  };

  /*Contrcat based jobs min & max salary on change */

  const handleSalChange = data => {
    if (Number(data.value) > data.availFaltRate) {
      setError(data.type, {
        type: "manual",
        message: "Limit exceeded",
      });
    } else if (Number(data.value) < 0 && data.type === "minSalary") {
      setError(data.type, {
        type: "manual",
        message: `Salary should not be less than ${0}`,
      });
    } else if (Number(data.value) < data.minSalary) {
      setError(data.type, {
        type: "manual",
        message: `Salary should not be less than ${data.minSalary}`,
      });
    } else {
      clearErrors(data.type);
    }
  };

  const handleCommissionChange = data => {
    setJoineeCommissionRate(data?.value);
    if (Number(data.value) > 99) {
      setError(data.type, {
        type: "manual",
        message: "Limit exceeded",
      });
    } else if (Number(data.value) < 0) {
      setError(data.type, {
        type: "manual",
        message: `Commission should not be less than ${0}`,
      });
    } else {
      clearErrors(data.type);
    }
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{ marginLeft: "100px" }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {/* Job Description Section */}
          <CommissionTypography variant="h3">
            Job Description
          </CommissionTypography>
          <BlackTypography variant="body1">
            Job Title: {tableData?.job_title}
            <br />
            Company: {tableData?.company?.company_name}
            <br />
            Min Salary: {getSymbolFromCurrency(tableData?.currency)}{" "}
            {tableData?.min_sal}
            {tableData?.job_type == "Contract" ? "/per hour" : ""}
            <br />
            Max Salary: {getSymbolFromCurrency(tableData?.currency)}{" "}
            {tableData?.max_sal}
            {tableData?.job_type == "Contract" ? "/per hour" : ""}
            <br />
            {/* Commission: {tableData?.commission_rate?.toFixed(2)}%<br /> */}
            Commission:{" "}
            {tableData?.job_type == "Contract"
              ? `${8.3}% (Default for contract job)`
              : `${tableData?.commission_rate?.toFixed(2)}%`}
            <br />
            Joinee Commission: {/* {watch('joineeCommissionRate') ?? "-"} */}
            {/* {joineeCommissionRate} */}
            {`${joineeCommissionRate}%`}{" "}

            {`(${getSymbolFromCurrency(tableData?.currency)} ${Math.ceil(
              tableData?.max_sal *
                ((joineeCommissionRate
                  ? joineeCommissionRate
                  : tableData?.commission_rate
                  ? tableData?.commission_rate
                  : 8.3) /
                  100)
            )})`}

            <br />
      

            {/* Flat Rate:
            {getSymbolFromCurrency(tableData?.currency)}{" "}
            {Math.ceil(
              tableData?.max_sal *
                ((joineeCommissionRate
                  ? joineeCommissionRate
                  : tableData?.commission_rate
                  ? tableData?.commission_rate
                  : 8.3) /
                  100)
            )}{" "} */}
            {/* <br /> */}
            Job Type:{tableData?.job_type?.replace("_", "")}
          </BlackTypography>
          <MiddleDivider />

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              {/* Joinee commission set up Section */}
              <Grid item xs={12} sx={{ marginTop: "10px" }}>
                <CommissionTypography variant="h1" sx={{ color: "black" }}>
                  Joinee Commission{" "}
                  {/* <PrimarySwitch
                    // checked={showCommission || getValues("referredFlatRate") !== null}
                    checked={showJoineeCommission}
                    {...register("showJoineeCommission", {
                      required: true,
                    })}
                    // onChange={() => setShowJoineeCommission(!showJoineeCommission)}
                    onChange={() => {
                      // Toggle referCommission state
                      setShowJoineeCommission(
                        prevState => !showJoineeCommission
                      );

                      // Update refer_commission based on referCommission state
                      setValue("showJoineeCommission", !showJoineeCommission);
                    }}
                  /> */}
                </CommissionTypography>
              </Grid>

              {showJoineeCommission && (
                <>
                  {" "}
                  <Grid item xs={12} lg={6} md={12}>
                    <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                      Commission rate*
                    </PrimaryNormalInputLabel>
                    <PrimaryCommissionInputs
                      name="joineeCommissionRate"
                      step=".01"
                      sx={{ marginTop: "-20px" }}
                      fullWidth
                      placeholder="Commission Rate %"
                      label="Flat Rate"
                      type="number"
                      // min={1}
                      // disable={true}
                      // value={watch('joinee_commission_rate')}
                      {...register("joineeCommissionRate", {
                        required: true,

                        validate: value => {
                          const inputValue = Number(value);

                          if (inputValue > 99) {
                            return `Commission rate should be less than ${commissionRate}.`;
                          } else if (inputValue <= 0) {
                            return `Commission rate should be greater than ${1}.`;
                          }
                        },
                      })}
                      onChange={e =>
                        handleCommissionChange({
                          value: e.target.value,
                          type: "joineeCommissionRate",
                        })
                      }
                      error={!!errors.joineeCommissionRate}
                    />

                    <PrimaryNormalFormHelperText
                      error={errors.joineeCommissionRate}
                    >
                      {/* {errors?.joineeCommissionRate
                        ?  errors?.joineeCommissionRate?.message
                          // "Flat rate is required"
                        : showFlatRate?.joineeCommissionRate !== ""
                        ? `Available Flat Rate: ${showFlatRate?.joineeCommissionRate}`
                        : ""} */}
                      {errors?.joineeCommissionRate &&
                        errors?.joineeCommissionRate?.message}
                    </PrimaryNormalFormHelperText>
                  </Grid>
                </>
              )}

              {/*Salary section */}

              {jobtype === "Contract" ? (
                <>
                  <Grid item xs={12} sx={{ marginTop: "10px" }}>
                    <CommissionTypography variant="h1" sx={{ color: "black" }}>
                      Salary
                    </CommissionTypography>
                  </Grid>

                  <Grid item xs={12} lg={6} md={12}>
                    <FormControl sx={{ width: "100%" }} variant="standard">
                      <PrimaryNormalInputLabel
                        shrink
                        htmlFor="folloups-job-type"
                        error={errors.job_type}
                      >
                        Payout*
                      </PrimaryNormalInputLabel>

                      <Select
                        labelId="folloups-job-type"
                        id="folloups-job-type"
                        // value={getValues("selfReferPayoutType") || ""}
                        // value={getValues("selfReferPayoutType")}
                        {...register("joinee_payout", { required: true })}
                        input={
                          <PrimaryNormalInputs
                            placeholder="Payout"
                            label="folloups-job-type"
                            error={errors?.joinee_commission_rate_type}
                            {...register("joinee_payout", {
                              required: true,
                            })}
                          />
                        }
                        MenuProps={MenuProps}
                      >
                        {contractPayouts?.map(item => {
                          return (
                            <MenuItem
                              key={item?.value}
                              value={item?.value} // style={getStyles(name, personName, theme)}
                            >
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <PrimaryNormalFormHelperText error={errors.joinee_payout}>
                        {errors?.joinee_payout && watch("joinee_payout") == ""
                          ? "Payout type is a required field"
                          : ""}
                      </PrimaryNormalFormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} lg={6} md={12}>
                    <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                      Joinee Min Salary
                    </PrimaryNormalInputLabel>
                    <PrimaryCommissionInputs
                      sx={{ marginTop: "-20px" }}
                      fullWidth
                      type="number"
                      label="Min Salary"
                      // {...register("minSalary", { required: false })}
                      error={errors?.minSalary}
                      {...register("minSalary", {
                        required: false,
                        validate: value => {
                          const inputValue =
                            Number(value) || getValues("minSalary");
                          const minSalaryByCompany = tableData?.min_sal;
                          if (inputValue < 0) {
                            return `Minimum salary should not be less than ${0}`;
                          } else if (inputValue > minSalaryByCompany) {
                            return `Minimum salary should not be greater than ${minSalaryByCompany}`;
                          }
                        },
                      })}
                      onChange={e =>
                        handleSalChange({
                          value: e.target.value,
                          availFaltRate: tableData?.min_sal,
                          type: "minSalary",
                        })
                      }
                    />

                    <PrimaryNormalFormHelperText error={errors.minSalary}>
                      {errors?.minSalary ? errors?.minSalary?.message : ""}
                    </PrimaryNormalFormHelperText>
                  </Grid>

                  <Grid item xs={12} lg={6} md={12}>
                    <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                      Joinee Max Salary
                    </PrimaryNormalInputLabel>
                    <PrimaryCommissionInputs
                      sx={{ marginTop: "-20px" }}
                      fullWidth
                      label="Max Salary"
                      type="number"
                      error={errors.maxSalary}
                      {...register("maxSalary", {
                        required: false,

                        validate: value => {
                          const inputValue =
                            Number(value) || getValues("maxSalary");
                          const maxSalaryByCompany = tableData?.max_sal;
                          // const minSalaryByCompany = tableData?.min_sal;
                          const minSalaryByCompany =
                            watch("minSalary") || getValues("minSalary");
                          if (inputValue > maxSalaryByCompany) {
                            return `Maximum salary should  be less than ${
                              maxSalaryByCompany + 1
                            }`;
                          } else if (inputValue < minSalaryByCompany) {
                            return `Maximum salary should not be less than ${minSalaryByCompany}`;
                          }
                        },
                      })}
                      onChange={e =>
                        handleSalChange({
                          value: e.target.value,
                          availFaltRate: tableData?.max_sal,
                          minSalary:
                            watch("minSalary") || getValues("minSalary"),
                          type: "maxSalary",
                        })
                      }
                    />

                    <PrimaryNormalFormHelperText error={errors.maxSalary}>
                      {errors?.maxSalary ? errors?.maxSalary?.message : ""}
                    </PrimaryNormalFormHelperText>
                  </Grid>
                </>
              ) : (
                ""
              )}

              {/* Job  seeker Section */}
              <Grid item xs={12} sx={{ marginTop: "10px" }}>
                <CommissionTypography variant="h1" sx={{ color: "black" }}>
                  Refer Self{" "}
                  <PrimarySwitch
                    // checked={showCommission || getValues("referredFlatRate") !== null}
                    checked={showCommission}
                    onChange={() => setShowCommission(!showCommission)}
                  />
                </CommissionTypography>
              </Grid>

              {showCommission && (
                <>
                  {" "}
                  <Grid item xs={12} lg={6} md={12}>
                    <FormControl sx={{ width: "100%" }} variant="standard">
                      <PrimaryNormalInputLabel
                        shrink
                        htmlFor="folloups-job-type"
                        error={errors.job_type}
                      >
                        Payout*
                      </PrimaryNormalInputLabel>

                      <Select
                        labelId="folloups-job-type"
                        id="folloups-job-type"
                        // value={getValues("selfReferPayoutType") || ""}
                        // value={getValues("selfReferPayoutType")}
                        {...register("selfReferPayoutType", { required: true })}
                        input={
                          <PrimaryNormalInputs
                            placeholder="Payout"
                            label="folloups-job-type"
                            error={errors?.selfReferPayoutType}
                            {...register("selfReferPayoutType", {
                              required: true,
                            })}
                          />
                        }
                        MenuProps={MenuProps}
                      >
                        {payouts?.map(item => {
                          return (
                            <MenuItem
                              key={item?.value}
                              value={item?.value.toLowerCase()} // style={getStyles(name, personName, theme)}
                            >
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <PrimaryNormalFormHelperText
                        error={errors.selfReferPayoutType}
                      >
                        {errors?.selfReferPayoutType &&
                        watch("selfReferPayoutType") == ""
                          ? "Payout type is a required field"
                          : ""}
                      </PrimaryNormalFormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6} md={12}>
                    <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                      Flat rate*
                    </PrimaryNormalInputLabel>
                    <PrimaryCommissionInputs
                      sx={{ marginTop: "-20px" }}
                      fullWidth
                      placeholder="Flat Rate"
                      label="Flat Rate"
                      type="number"
                      // min={1}
                      // disable={true}
                      {...register("selfReferralFlatRate", {
                        required: true,

                        // validate: value => {
                        //   const inputValue = Number(value);
                        //   const availableFlatRate =
                        //     currentFlatRate -
                        //     (watch("referredFlatRate") ?? 0) -
                        //     (watch("screeningFlatRatePerProfile") || 0) -
                        //     (watch("screeningFlatRateOnboard") || 0) -
                        //     (watch("technicalFlatRatePerProfile") || 0) -
                        //     (watch("technicalFlatRateOnboard") || 0) -
                        //     (watch("moderatorFlatRatePerProfile") || 0) -
                        //     (watch("moderatorFlatRateOnboard") || 0) -
                        //     (watch("recruitmentCompanyFlatRatePerProfile") ||
                        //       0) -
                        //     (watch("recruitmentCompanyFlatRate") || 0) -
                        //     (watch("backgroundFlatRatePerProfile") || 0) -
                        //     (watch("backgroundFlatRateOnboard") || 0);

                        //   if (
                        //     inputValue > availableFlatRate ||
                        //     availableFlatRate <= 0
                        //   ) {
                        //     return `Flat Rate should be less than ${
                        //       availableFlatRate > 0
                        //         ? availableFlatRate
                        //         : "the available flat rate is less than or equal to 0"
                        //     }.`;
                        //   }
                        // },

                      })}
                      onChange={e =>
                        handleChange({
                          value: e.target.value,
                          availFaltRate:
                            currentFlatRate -
                            (watch("referredFlatRate") ?? 0) -
                            (watch("screeningFlatRatePerProfile") || 0) -
                            (watch("screeningFlatRateOnboard") || 0) -
                            (watch("technicalFlatRatePerProfile") || 0) -
                            (watch("technicalFlatRateOnboard") || 0) -
                            (watch("moderatorFlatRatePerProfile") || 0) -
                            (watch("moderatorFlatRateOnboard") || 0) -
                            (watch("recruitmentCompanyFlatRatePerProfile") ||
                              0) -
                            (watch("recruitmentCompanyFlatRate") || 0) -
                            (watch("backgroundFlatRatePerProfile") || 0) -
                            (watch("backgroundFlatRateOnboard") || 0),
                          type: "selfReferralFlatRate",
                        })
                      }
                      error={!!errors.selfReferralFlatRate}
                      // helperText={
                      //   errors.selfReferralFlatRate && "Flat Rate is required"
                      // }
                    />

                    <PrimaryNormalFormHelperText
                      error={errors.selfReferralFlatRate}
                    >
                      {errors?.selfReferralFlatRate
                        ? errors?.selfReferralFlatRate?.message
                        : // "Flat rate is required"
                        showFlatRate?.selfReferralFlatRate !== ""
                        ? 
                        // `Available Flat Rate: ${showFlatRate?.selfReferralFlatRate}`
                        ""
                        : ""}
                    </PrimaryNormalFormHelperText>
                  </Grid>
                </>
              )}

              {/* Referby candidate Section */}
              <Grid item xs={12}>
                <CommissionTypography variant="h1">
                  Refer Now
                </CommissionTypography>
              </Grid>

              <Grid item xs={12} lg={6} md={12}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="folloups-job-type"
                    error={errors.job_type}
                  >
                    Payout*
                  </PrimaryNormalInputLabel>
                  <Select
                    labelId="folloups-job-type"
                    id="folloups-job-type"
                    // value={getValues("referredPayoutType")}
                    {...register("referredPayoutType", { required: true })}
                    input={
                      <PrimaryNormalInputs
                        placeholder="Select A Job Type"
                        label="folloups-job-type"
                        error={errors?.referredPayoutType}
                        {...register("referredPayoutType", { required: true })}
                      />
                    }
                    MenuProps={MenuProps}
                  >
                    {payouts?.map(item => {
                      return (
                        <MenuItem
                          key={item?.value}
                          value={item?.value} // style={getStyles(name, personName, theme)}
                        >
                          {item?.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <PrimaryNormalFormHelperText
                    error={errors.referredPayoutType}
                  >
                    {errors?.referredPayoutType &&
                    watch("referredPayoutType") == ""
                      ? "Payout type is a required field"
                      : ""}
                  </PrimaryNormalFormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} lg={6} md={12}>
                <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                  Flat rate*
                </PrimaryNormalInputLabel>
                <PrimaryCommissionInputs
                  sx={{ marginTop: "22px" }}
                  fullWidth
                  placeholder="Flat Rate"
                  label="Flat Rate"
                  type="number"
                  min={1}
                  {...register("referredFlatRate", {
                    required: true,
                 

                    // validate: value => {
                    //   const inputValue = Number(value);
                    //   const availableFlatRate =
                    //     currentFlatRate -
                    //     (watch("selfReferralFlatRate") || 0) -
                    //     (watch("screeningFlatRatePerProfile") || 0) -
                    //     (watch("screeningFlatRateOnboard") || 0) -
                    //     (watch("technicalFlatRatePerProfile") || 0) -
                    //     (watch("technicalFlatRateOnboard") || 0) -
                    //     (watch("moderatorFlatRatePerProfile") || 0) -
                    //     (watch("moderatorFlatRateOnboard") || 0) -
                    //     (watch("recruitmentCompanyFlatRatePerProfile") || 0) -
                    //     (watch("recruitmentCompanyFlatRate") || 0) -
                    //     (watch("backgroundFlatRatePerProfile") || 0) -
                    //     (watch("backgroundFlatRateOnboard") || 0);

                    //   if (
                    //     inputValue > availableFlatRate ||
                    //     availableFlatRate <= 0
                    //   ) {
                    //     return `Flat Rate should be less than ${
                    //       availableFlatRate > 0
                    //         ? availableFlatRate
                    //         : "the available flat rate is less than or equal to 0"
                    //     }.`;
                    //   }
                    // },

                  })}
                  onChange={e =>
                    handleChange({
                      value: e.target.value,
                      availFaltRate:
                        currentFlatRate -
                        (watch("selfReferralFlatRate") || 0) -
                        (watch("screeningFlatRatePerProfile") || 0) -
                        (watch("screeningFlatRateOnboard") || 0) -
                        (watch("technicalFlatRatePerProfile") || 0) -
                        (watch("technicalFlatRateOnboard") || 0) -
                        (watch("moderatorFlatRatePerProfile") || 0) -
                        (watch("moderatorFlatRateOnboard") || 0) -
                        (watch("recruitmentCompanyFlatRatePerProfile") || 0) -
                        (watch("recruitmentCompanyFlatRate") || 0) -
                        (watch("backgroundFlatRatePerProfile") || 0) -
                        (watch("backgroundFlatRateOnboard") || 0),
                      type: "referredFlatRate",
                    })
                  }
                  error={!!errors.referredFlatRate}
                  helperText={
                    errors.referredFlatRate && "Flat Rate is required"
                  }
                />
                <PrimaryNormalFormHelperText error={errors.referredFlatRate}>
                  {errors?.referredFlatRate
                    ? errors?.referredFlatRate?.message
                    : // "Flat rate is required"
                    showFlatRate.referredFlatRate !== ""
                    ?
                    //  `Available Flat Rate: ${showFlatRate.referredFlatRate}`
                    ""
                    : ""}
                </PrimaryNormalFormHelperText>
              </Grid>

              {/* Screening Partner Section */}
              <Grid item xs={12}>
                <CommissionTypography variant="h1">
                  Screening Partner
                </CommissionTypography>
              </Grid>

              <Grid item xs={12} lg={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={getValues("screeningPerProfile")}
                      {...register("screeningPerProfile", { required: false })}
                      color="primary"
                    />
                  }
                  label="Per Profile"
                  sx={{
                    color: errors.screeningPerProfile ? "red" : "inherit",
                  }}
                />
                {errors.screeningPerProfile && (
                  <Typography variant="body2" color="red">
                    This field is required.
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} lg={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={getValues("screeningOnboard")}
                      {...register("screeningOnboard", { required: false })}
                      color="primary"
                    />
                  }
                  label="Onboard"
                  sx={{
                    color: errors.screeningOnboard ? "red" : "inherit",
                  }}
                />
                {errors.screeningOnboard && (
                  <Typography variant="body2" color="red">
                    This field is required.
                  </Typography>
                )}
              </Grid>

              {watch("screeningPerProfile") && (
                <Grid item xs={12} lg={6}>
                  <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                    Flat rate
                  </PrimaryNormalInputLabel>
                  <PrimaryCommissionInputs
                    fullWidth
                    placeholder="Flat Rate (Per Profile)"
                    label="Flat Rate (Per Profile)"
                    type="number"
                    // min={1}
                    {...register("screeningFlatRatePerProfile", {
                      required: false,
                  
                      // validate: value => {
                      //   const inputValue = Number(value);
                      //   const availableFlatRate =
                      //     currentFlatRate -
                      //     (watch("referredFlatRate") || 0) -
                      //     (watch("selfReferralFlatRate") || 0) -
                      //     (watch("screeningFlatRateOnboard") || 0) -
                      //     (watch("technicalFlatRatePerProfile") || 0) -
                      //     (watch("technicalFlatRateOnboard") || 0) -
                      //     (watch("moderatorFlatRatePerProfile") || 0) -
                      //     (watch("moderatorFlatRateOnboard") || 0) -
                      //     (watch("recruitmentCompanyFlatRatePerProfile") || 0) -
                      //     (watch("recruitmentCompanyFlatRate") || 0) -
                      //     (watch("backgroundFlatRatePerProfile") || 0) -
                      //     (watch("backgroundFlatRateOnboard") || 0);

                      //   if (
                      //     inputValue > availableFlatRate ||
                      //     availableFlatRate <= 0
                      //   ) {
                      //     return `Flat Rate should be less than ${
                      //       availableFlatRate > 0
                      //         ? availableFlatRate
                      //         : "the available flat rate is less than or equal to 0"
                      //     }.`;
                      //   }
                      // },

                    })}
                    onChange={e =>
                      handleChange({
                        value: e.target.value,
                        availFaltRate:
                          currentFlatRate -
                          (watch("referredFlatRate") || 0) -
                          (watch("selfReferralFlatRate") || 0) -
                          (watch("screeningFlatRateOnboard") || 0) -
                          (watch("technicalFlatRatePerProfile") || 0) -
                          (watch("technicalFlatRateOnboard") || 0) -
                          (watch("moderatorFlatRatePerProfile") || 0) -
                          (watch("moderatorFlatRateOnboard") || 0) -
                          (watch("recruitmentCompanyFlatRatePerProfile") || 0) -
                          (watch("recruitmentCompanyFlatRate") || 0) -
                          (watch("backgroundFlatRatePerProfile") || 0) -
                          (watch("backgroundFlatRateOnboard") || 0),
                        type: "screeningFlatRatePerProfile",
                      })
                    }
                    error={!!errors.screeningFlatRatePerProfile}
                    helperText={
                      errors.screeningFlatRatePerProfile &&
                      "Flat Rate is required"
                    }
                  />

                  <PrimaryNormalFormHelperText
                    error={errors.screeningFlatRatePerProfile}
                  >
                    {errors?.screeningFlatRatePerProfile
                      ? errors?.screeningFlatRatePerProfile?.message
                      : // getValues("screeningFlatRatePerProfile") ||
                      //   watch("screeningFlatRatePerProfile")
                      // ? `Available Flat Rate: ${
                      //     currentFlatRate -
                      //     (watch("referredFlatRate") || 0) -
                      //     (getValues("screeningFlatRatePerProfile") ||
                      //       watch("screeningFlatRatePerProfile") ||
                      //       0) -
                      //     (watch("selfReferralFlatRate") || 0) -
                      //     (watch("screeningFlatRateOnboard") || 0) -
                      //     (watch("technicalFlatRatePerProfile") || 0) -
                      //     (watch("technicalFlatRateOnboard") || 0) -
                      //     (watch("moderatorFlatRatePerProfile") || 0) -
                      //     (watch("moderatorFlatRateOnboard") || 0) -
                      //     (watch("recruitmentCompanyFlatRatePerProfile") || 0) -
                      //     (watch("recruitmentCompanyFlatRate") || 0) -
                      //     (watch("backgroundFlatRatePerProfile") || 0) -
                      //     (watch("backgroundFlatRateOnboard") || 0)
                      //   }`
                      showFlatRate.screeningFlatRatePerProfile !== ""
                      ? 
                      // `Available Flat Rate: ${showFlatRate.screeningFlatRatePerProfile}`
                      ""
                      : ""}
                  </PrimaryNormalFormHelperText>
                </Grid>
              )}

              {watch("screeningOnboard") && (
                <Grid item xs={12} lg={6}>
                  <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                    Flat rate
                  </PrimaryNormalInputLabel>
                  <PrimaryCommissionInputs
                    fullWidth
                    placeholder="Flat Rate (Onboard)"
                    label="Flat Rate (Onboard)"
                    type="number"
                    // min={1}
                    {...register("screeningFlatRateOnboard", {
                      required: false,
                   

                      // validate: value => {
                      //   const inputValue = Number(value);
                      //   const availableFlatRate =
                      //     currentFlatRate -
                      //     (watch("referredFlatRate") ?? 0) -
                      //     (watch("screeningFlatRatePerProfile") || 0) -
                      //     (watch("selfReferralFlatRate") || 0) -
                      //     (watch("technicalFlatRatePerProfile") || 0) -
                      //     (watch("technicalFlatRateOnboard") || 0) -
                      //     (watch("moderatorFlatRatePerProfile") || 0) -
                      //     (watch("moderatorFlatRateOnboard") || 0) -
                      //     (watch("recruitmentCompanyFlatRatePerProfile") || 0) -
                      //     (watch("recruitmentCompanyFlatRate") || 0) -
                      //     (watch("backgroundFlatRatePerProfile") || 0) -
                      //     (watch("backgroundFlatRateOnboard") || 0);
                      //   if (
                      //     inputValue > availableFlatRate ||
                      //     availableFlatRate <= 0
                      //   ) {
                      //     return `Flat Rate should be less than ${
                      //       availableFlatRate > 0
                      //         ? availableFlatRate
                      //         : "the available flat rate is less than or equal to 0"
                      //     }.`;
                      //   }
                      // },

                    })}

                    onChange={e =>
                      handleChange({
                        value: e.target.value,
                        availFaltRate:
                          currentFlatRate -
                          (watch("referredFlatRate") ?? 0) -
                          (watch("screeningFlatRatePerProfile") || 0) -
                          (watch("selfReferralFlatRate") || 0) -
                          (watch("technicalFlatRatePerProfile") || 0) -
                          (watch("technicalFlatRateOnboard") || 0) -
                          (watch("moderatorFlatRatePerProfile") || 0) -
                          (watch("moderatorFlatRateOnboard") || 0) -
                          (watch("recruitmentCompanyFlatRatePerProfile") || 0) -
                          (watch("recruitmentCompanyFlatRate") || 0) -
                          (watch("backgroundFlatRatePerProfile") || 0) -
                          (watch("backgroundFlatRateOnboard") || 0),
                        type: "screeningFlatRateOnboard",
                      })
                    }
                    error={!!errors.screeningFlatRateOnboard}
                    helperText={
                      errors.screeningFlatRateOnboard && "Flat Rate is required"
                    }
                  />
                  <PrimaryNormalFormHelperText
                    error={errors.screeningFlatRateOnboard}
                  >
                    {errors?.screeningFlatRateOnboard
                      ? errors?.screeningFlatRateOnboard?.message
                      : // getValues("screeningFlatRateOnboard") ||
                      //   watch("screeningFlatRateOnboard")
                      // ? `Available Flat Rate: ${
                      //     currentFlatRate -
                      //     (watch("referredFlatRate") ?? 0) -
                      //     (getValues("screeningFlatRateOnboard") ||
                      //       watch("screeningFlatRateOnboard") ||
                      //       0) -
                      //     (watch("screeningFlatRatePerProfile") || 0) -
                      //     (watch("selfReferralFlatRate") || 0) -
                      //     (watch("technicalFlatRatePerProfile") || 0) -
                      //     (watch("technicalFlatRateOnboard") || 0) -
                      //     (watch("moderatorFlatRatePerProfile") || 0) -
                      //     (watch("moderatorFlatRateOnboard") || 0) -
                      //     (watch("recruitmentCompanyFlatRatePerProfile") || 0) -
                      //     (watch("recruitmentCompanyFlatRate") || 0) -
                      //     (watch("backgroundFlatRatePerProfile") || 0) -
                      //     (watch("backgroundFlatRateOnboard") || 0)
                      //   }`

                      showFlatRate.screeningFlatRateOnboard !== ""
                      ?
                      //  `Available Flat Rate: ${showFlatRate.screeningFlatRateOnboard}`
                      ""
                      : ""}
                  </PrimaryNormalFormHelperText>
                </Grid>
              )}

              {/* Technical Evaluator Section */}
              <Grid item xs={12}>
                <CommissionTypography variant="h1">
                  Technical Evaluator
                </CommissionTypography>
              </Grid>

              <Grid item xs={12} lg={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={getValues("technicalPerProfile")}
                      {...register("technicalPerProfile", { required: false })}
                      color="primary"
                    />
                  }
                  label="Per Profile"
                  sx={{
                    color: errors.technicalPerProfile ? "red" : "inherit",
                  }}
                />
                {errors.technicalPerProfile && (
                  <Typography variant="body2" color="red">
                    This field is required.
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} lg={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={getValues("technicalOnboard")}
                      {...register("technicalOnboard", { required: false })}
                      color="primary"
                    />
                  }
                  label="Onboard"
                  sx={{
                    color: errors.technicalOnboard ? "red" : "inherit",
                  }}
                />
                {errors.technicalOnboard && (
                  <Typography variant="body2" color="red">
                    This field is required.
                  </Typography>
                )}
              </Grid>

              {watch("technicalPerProfile") && (
                <Grid item xs={12} lg={6}>
                  <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                    Flat rate
                  </PrimaryNormalInputLabel>
                  <PrimaryCommissionInputs
                    fullWidth
                    placeholder="Flat Rate (Per Profile)"
                    label="Flat Rate (Per Profile)"
                    type="number"
                    // min={1}
                    {...register("technicalFlatRatePerProfile", {
                      required: false,
                  

                      // validate: value => {
                      //   const inputValue = Number(value);
                      //   const availableFlatRate =
                      //     currentFlatRate -
                      //     (watch("referredFlatRate") || 0) -
                      //     (watch("screeningFlatRatePerProfile") || 0) -
                      //     (watch("screeningFlatRateOnboard") || 0) -
                      //     (watch("selfReferralFlatRate") || 0) -
                      //     (watch("technicalFlatRateOnboard") || 0) -
                      //     (watch("moderatorFlatRatePerProfile") || 0) -
                      //     (watch("moderatorFlatRateOnboard") || 0) -
                      //     (watch("recruitmentCompanyFlatRatePerProfile") || 0) -
                      //     (watch("recruitmentCompanyFlatRate") || 0) -
                      //     (watch("backgroundFlatRatePerProfile") || 0) -
                      //     (watch("backgroundFlatRateOnboard") || 0);
                      //   if (
                      //     inputValue > availableFlatRate ||
                      //     availableFlatRate <= 0
                      //   ) {
                      //     return `Flat Rate should be less than ${
                      //       availableFlatRate > 0
                      //         ? availableFlatRate
                      //         : "the available flat rate is less than or equal to 0"
                      //     }.`;
                      //   }
                      // },

                    })}
                    onChange={e =>
                      handleChange({
                        value: e.target.value,
                        availFaltRate:
                          currentFlatRate -
                          (watch("referredFlatRate") || 0) -
                          (watch("screeningFlatRatePerProfile") || 0) -
                          (watch("screeningFlatRateOnboard") || 0) -
                          (watch("selfReferralFlatRate") || 0) -
                          (watch("technicalFlatRateOnboard") || 0) -
                          (watch("moderatorFlatRatePerProfile") || 0) -
                          (watch("moderatorFlatRateOnboard") || 0) -
                          (watch("recruitmentCompanyFlatRatePerProfile") || 0) -
                          (watch("recruitmentCompanyFlatRate") || 0) -
                          (watch("backgroundFlatRatePerProfile") || 0) -
                          (watch("backgroundFlatRateOnboard") || 0),
                        type: "technicalFlatRatePerProfile",
                      })
                    }
                    error={!!errors.technicalFlatRatePerProfile}
                    helperText={
                      errors.technicalFlatRatePerProfile &&
                      "Flat Rate is required"
                    }
                  />
                  <PrimaryNormalFormHelperText
                    error={errors.technicalFlatRatePerProfile}
                  >
                    {errors?.technicalFlatRatePerProfile
                      ? errors?.technicalFlatRatePerProfile?.message
                      : // getValues("technicalFlatRatePerProfile") ||
                      //   watch("technicalFlatRatePerProfile")
                      // ? `Available Flat Rate: ${
                      //     currentFlatRate -
                      //     (watch("referredFlatRate") || 0) -
                      //     (getValues("technicalFlatRatePerProfile") ||
                      //       watch("technicalFlatRatePerProfile") ||
                      //       0) -
                      //     (watch("screeningFlatRatePerProfile") || 0) -
                      //     (watch("screeningFlatRateOnboard") || 0) -
                      //     (watch("selfReferralFlatRate") || 0) -
                      //     (watch("technicalFlatRateOnboard") || 0) -
                      //     (watch("moderatorFlatRatePerProfile") || 0) -
                      //     (watch("moderatorFlatRateOnboard") || 0) -
                      //     (watch("recruitmentCompanyFlatRatePerProfile") || 0) -
                      //     (watch("recruitmentCompanyFlatRate") || 0) -
                      //     (watch("backgroundFlatRatePerProfile") || 0) -
                      //     (watch("backgroundFlatRateOnboard") || 0)
                      //   }`
                      showFlatRate.technicalFlatRatePerProfile !== ""
                      ? 
                      // `Available Flat Rate: ${showFlatRate.technicalFlatRatePerProfile}`
                      ""
                      : ""}
                  </PrimaryNormalFormHelperText>
                </Grid>
              )}

              {watch("technicalOnboard") && (
                <Grid item xs={12} lg={6}>
                  <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                    Flat rate
                  </PrimaryNormalInputLabel>
                  <PrimaryCommissionInputs
                    fullWidth
                    placeholder="Flat Rate (Onboard)"
                    label="Flat Rate (Onboard)"
                    type="number"
                    // min={1}
                    {...register("technicalFlatRateOnboard", {
                      required: false,
                  

                      // validate: value => {
                      //   const inputValue = Number(value);
                      //   const availableFlatRate =
                      //     currentFlatRate -
                      //     (watch("referredFlatRate") || 0) -
                      //     (watch("screeningFlatRatePerProfile") || 0) -
                      //     (watch("screeningFlatRateOnboard") || 0) -
                      //     (watch("technicalFlatRatePerProfile") || 0) -
                      //     (watch("selfReferralFlatRate") || 0) -
                      //     (watch("moderatorFlatRatePerProfile") || 0) -
                      //     (watch("moderatorFlatRateOnboard") || 0) -
                      //     (watch("recruitmentCompanyFlatRatePerProfile") || 0) -
                      //     (watch("recruitmentCompanyFlatRate") || 0) -
                      //     (watch("backgroundFlatRatePerProfile") || 0) -
                      //     (watch("backgroundFlatRateOnboard") || 0);
                      //   if (
                      //     inputValue > availableFlatRate ||
                      //     availableFlatRate <= 0
                      //   ) {
                      //     return `Flat Rate should be less than ${
                      //       availableFlatRate > 0
                      //         ? availableFlatRate
                      //         : "the available flat rate is less than or equal to 0"
                      //     }.`;
                      //   }
                      // },

                    })}
                    onChange={e =>
                      handleChange({
                        value: e.target.value,
                        availFaltRate:
                          currentFlatRate -
                          (watch("referredFlatRate") || 0) -
                          (watch("screeningFlatRatePerProfile") || 0) -
                          (watch("screeningFlatRateOnboard") || 0) -
                          (watch("technicalFlatRatePerProfile") || 0) -
                          (watch("selfReferralFlatRate") || 0) -
                          (watch("moderatorFlatRatePerProfile") || 0) -
                          (watch("moderatorFlatRateOnboard") || 0) -
                          (watch("recruitmentCompanyFlatRatePerProfile") || 0) -
                          (watch("recruitmentCompanyFlatRate") || 0) -
                          (watch("backgroundFlatRatePerProfile") || 0) -
                          (watch("backgroundFlatRateOnboard") || 0),
                        type: "technicalFlatRateOnboard",
                      })
                    }
                    error={!!errors.technicalFlatRateOnboard}
                    helperText={
                      errors.technicalFlatRateOnboard && "Flat Rate is required"
                    }
                  />
                  <PrimaryNormalFormHelperText
                    error={errors.technicalFlatRateOnboard}
                  >
                    {errors?.technicalFlatRateOnboard
                      ? errors?.technicalFlatRateOnboard?.message
                      : // getValues("technicalFlatRateOnboard") ||
                      //   watch("technicalFlatRateOnboard")
                      // ? `Available Flat Rate: ${
                      //     currentFlatRate -
                      //     (watch("referredFlatRate") || 0) -
                      //     (getValues("technicalFlatRateOnboard") ||
                      //       watch("technicalFlatRateOnboard") ||
                      //       0) -
                      //     (watch("screeningFlatRatePerProfile") || 0) -
                      //     (watch("screeningFlatRateOnboard") || 0) -
                      //     (watch("technicalFlatRatePerProfile") || 0) -
                      //     (watch("selfReferralFlatRate") || 0) -
                      //     (watch("moderatorFlatRatePerProfile") || 0) -
                      //     (watch("moderatorFlatRateOnboard") || 0) -
                      //     (watch("recruitmentCompanyFlatRatePerProfile") || 0) -
                      //     (watch("recruitmentCompanyFlatRate") || 0) -
                      //     (watch("backgroundFlatRatePerProfile") || 0) -
                      //     (watch("backgroundFlatRateOnboard") || 0)
                      //   }`
                      showFlatRate.technicalFlatRateOnboard !== ""
                      ? 
                      // `Available Flat Rate: ${showFlatRate.technicalFlatRateOnboard}`
                      ""
                      : ""}
                  </PrimaryNormalFormHelperText>
                </Grid>
              )}

              {/*Moderator section */}

              <Grid item xs={12}>
                <CommissionTypography variant="h1">
                  Moderator
                </CommissionTypography>
              </Grid>

              <Grid item xs={12} lg={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={getValues("moderatorPerProfile")}
                      {...register("moderatorPerProfile", { required: false })}
                      color="primary"
                    />
                  }
                  label="Per Profile"
                  sx={{
                    color: errors.moderatorPerProfile ? "red" : "inherit",
                  }}
                />
                {errors.moderatorPerProfile && (
                  <Typography variant="body2" color="red">
                    This field is required.
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} lg={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={getValues("moderatorOnboard")}
                      {...register("moderatorOnboard", { required: false })}
                      color="primary"
                    />
                  }
                  label="Onboard"
                  sx={{
                    color: errors.moderatorOnboard ? "red" : "inherit",
                  }}
                />
                {errors.moderatorOnboard && (
                  <Typography variant="body2" color="red">
                    This field is required.
                  </Typography>
                )}
              </Grid>

              {watch("moderatorPerProfile") && (
                <Grid item xs={12} lg={6}>
                  <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                    Flat rate
                  </PrimaryNormalInputLabel>
                  <PrimaryCommissionInputs
                    fullWidth
                    placeholder="Flat Rate (Per Profile)"
                    label="Flat Rate (Per Profile)"
                    type="number"
                    // min={1}
                    {...register("moderatorFlatRatePerProfile", {
                      required: false,
                  

                      // validate: value => {
                      //   const inputValue = Number(value);
                      //   const availableFlatRate =
                      //     currentFlatRate -
                      //     (watch("referredFlatRate") || 0) -
                      //     (watch("screeningFlatRatePerProfile") || 0) -
                      //     (watch("screeningFlatRateOnboard") || 0) -
                      //     (watch("technicalFlatRatePerProfile") || 0) -
                      //     (watch("technicalFlatRateOnboard") || 0) -
                      //     (watch("selfReferralFlatRate") || 0) -
                      //     (watch("moderatorFlatRateOnboard") || 0) -
                      //     (watch("recruitmentCompanyFlatRatePerProfile") || 0) -
                      //     (watch("recruitmentCompanyFlatRate") || 0) -
                      //     (watch("backgroundFlatRatePerProfile") || 0) -
                      //     (watch("backgroundFlatRateOnboard") || 0);
                      //   if (
                      //     inputValue > availableFlatRate ||
                      //     availableFlatRate <= 0
                      //   ) {
                      //     return `Flat Rate should be less than ${
                      //       availableFlatRate > 0
                      //         ? availableFlatRate
                      //         : "the available flat rate is less than or equal to 0"
                      //     }.`;
                      //   }
                      // },

                    })}
                    onChange={e =>
                      handleChange({
                        value: e.target.value,
                        availFaltRate:
                          currentFlatRate -
                          (watch("referredFlatRate") || 0) -
                          (watch("screeningFlatRatePerProfile") || 0) -
                          (watch("screeningFlatRateOnboard") || 0) -
                          (watch("technicalFlatRatePerProfile") || 0) -
                          (watch("technicalFlatRateOnboard") || 0) -
                          (watch("selfReferralFlatRate") || 0) -
                          (watch("moderatorFlatRateOnboard") || 0) -
                          (watch("recruitmentCompanyFlatRatePerProfile") || 0) -
                          (watch("recruitmentCompanyFlatRate") || 0) -
                          (watch("backgroundFlatRatePerProfile") || 0) -
                          (watch("backgroundFlatRateOnboard") || 0),
                        type: "moderatorFlatRatePerProfile",
                      })
                    }
                    error={!!errors.moderatorFlatRatePerProfile}
                    helperText={
                      errors.moderatorFlatRatePerProfile &&
                      "Flat Rate is required"
                    }
                  />
                  <PrimaryNormalFormHelperText
                    error={errors.moderatorFlatRatePerProfile}
                  >
                    {errors?.moderatorFlatRatePerProfile
                      ? errors?.moderatorFlatRatePerProfile?.message
                      : // getValues("moderatorFlatRatePerProfile") ||
                      //   watch("moderatorFlatRatePerProfile")
                      // ? `Available Flat Rate: ${
                      //     currentFlatRate -
                      //     (watch("referredFlatRate") || 0) -
                      //     (getValues("moderatorFlatRatePerProfile") ||
                      //       watch("moderatorFlatRatePerProfile") ||
                      //       0) -
                      //     (watch("screeningFlatRatePerProfile") || 0) -
                      //     (watch("screeningFlatRateOnboard") || 0) -
                      //     (watch("technicalFlatRatePerProfile") || 0) -
                      //     (watch("technicalFlatRateOnboard") || 0) -
                      //     (watch("selfReferralFlatRate") || 0) -
                      //     (watch("moderatorFlatRateOnboard") || 0) -
                      //     (watch("recruitmentCompanyFlatRatePerProfile") || 0) -
                      //     (watch("recruitmentCompanyFlatRate") || 0) -
                      //     (watch("backgroundFlatRatePerProfile") || 0) -
                      //     (watch("backgroundFlatRateOnboard") || 0)
                      //   }`
                      showFlatRate.moderatorFlatRatePerProfile !== ""
                      ? 
                      // `Available Flat Rate: ${showFlatRate.moderatorFlatRatePerProfile}`
                      ""
                      : ""}
                  </PrimaryNormalFormHelperText>
                </Grid>
              )}

              {watch("moderatorOnboard") && (
                <Grid item xs={12} lg={6}>
                  <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                    Flat rate
                  </PrimaryNormalInputLabel>
                  <PrimaryCommissionInputs
                    fullWidth
                    placeholder="Flat Rate (Onboard)"
                    label="Flat Rate (Onboard)"
                    type="number"
                    // min={1}
                    {...register("moderatorFlatRateOnboard", {
                      required: false,
                    

                      // validate: value => {
                      //   const inputValue = Number(value);
                      //   const availableFlatRate =
                      //     currentFlatRate -
                      //     (watch("referredFlatRate") || 0) -
                      //     (watch("screeningFlatRatePerProfile") || 0) -
                      //     (watch("screeningFlatRateOnboard") || 0) -
                      //     (watch("technicalFlatRatePerProfile") || 0) -
                      //     (watch("technicalFlatRateOnboard") || 0) -
                      //     (watch("moderatorFlatRatePerProfile") || 0) -
                      //     (watch("selfReferralFlatRate") || 0) -
                      //     (watch("recruitmentCompanyFlatRatePerProfile") || 0) -
                      //     (watch("recruitmentCompanyFlatRate") || 0) -
                      //     (watch("backgroundFlatRatePerProfile") || 0) -
                      //     (watch("backgroundFlatRateOnboard") || 0);
                      //   if (
                      //     inputValue > availableFlatRate ||
                      //     availableFlatRate <= 0
                      //   ) {
                      //     return `Flat Rate should be less than ${
                      //       availableFlatRate > 0
                      //         ? availableFlatRate
                      //         : "the available flat rate is less than or equal to 0"
                      //     }.`;
                      //   }
                      // },

                    })}
                    onChange={e =>
                      handleChange({
                        value: e.target.value,
                        availFaltRate:
                          currentFlatRate -
                          (watch("referredFlatRate") || 0) -
                          (watch("screeningFlatRatePerProfile") || 0) -
                          (watch("screeningFlatRateOnboard") || 0) -
                          (watch("technicalFlatRatePerProfile") || 0) -
                          (watch("technicalFlatRateOnboard") || 0) -
                          (watch("moderatorFlatRatePerProfile") || 0) -
                          (watch("selfReferralFlatRate") || 0) -
                          (watch("recruitmentCompanyFlatRatePerProfile") || 0) -
                          (watch("recruitmentCompanyFlatRate") || 0) -
                          (watch("backgroundFlatRatePerProfile") || 0) -
                          (watch("backgroundFlatRateOnboard") || 0),
                        type: "moderatorFlatRateOnboard",
                      })
                    }
                    error={!!errors.moderatorFlatRateOnboard}
                    helperText={
                      errors.moderatorFlatRateOnboard && "Flat Rate is required"
                    }
                  />
                  <PrimaryNormalFormHelperText
                    error={errors.moderatorFlatRateOnboard}
                  >
                    {errors?.moderatorFlatRateOnboard
                      ? errors?.moderatorFlatRateOnboard?.message
                      : // getValues("moderatorFlatRateOnboard") ||
                      //   watch("moderatorFlatRateOnboard")
                      // ? `Available Flat Rate: ${
                      //     currentFlatRate -
                      //     (watch("referredFlatRate") || 0) -
                      //     (getValues("moderatorFlatRateOnboard") ||
                      //       watch("moderatorFlatRateOnboard") ||
                      //       0) -
                      //     (watch("screeningFlatRatePerProfile") || 0) -
                      //     (watch("screeningFlatRateOnboard") || 0) -
                      //     (watch("technicalFlatRatePerProfile") || 0) -
                      //     (watch("technicalFlatRateOnboard") || 0) -
                      //     (watch("moderatorFlatRatePerProfile") || 0) -
                      //     (watch("selfReferralFlatRate") || 0) -
                      //     (watch("recruitmentCompanyFlatRatePerProfile") || 0) -
                      //     (watch("recruitmentCompanyFlatRate") || 0) -
                      //     (watch("backgroundFlatRatePerProfile") || 0) -
                      //     (watch("backgroundFlatRateOnboard") || 0)
                      //   }`
                      showFlatRate.moderatorFlatRateOnboard !== ""
                      ? 
                      // `Available Flat Rate: ${showFlatRate.moderatorFlatRateOnboard}`
                      ""
                      : ""}
                  </PrimaryNormalFormHelperText>
                </Grid>
              )}

              {/* Recrutment company Section */}
              <Grid item xs={12}>
                <CommissionTypography variant="h1">
                  Recruitment Company
                </CommissionTypography>
              </Grid>

              <Grid item xs={12} lg={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={getValues("recruitmentCompanyPerProfile")}
                      {...register("recruitmentCompanyPerProfile", {
                        required: false,
                      })}
                      color="primary"
                    />
                  }
                  label="Per Profile"
                  sx={{
                    color: errors.backgroundPerProfile ? "red" : "inherit",
                  }}
                />
                {errors.recruitmentCompanyPerProfile && (
                  <Typography variant="body2" color="red">
                    This field is required.
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} lg={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={getValues("recruitmentCompanyOnboard")}
                      {...register("recruitmentCompanyOnboard", {
                        required: false,
                      })}
                      color="primary"
                    />
                  }
                  label="Onboard"
                  sx={{
                    color: errors.recruitmentCompanyOnboard ? "red" : "inherit",
                  }}
                />
                {errors.recruitmentCompanyOnboard && (
                  <Typography variant="body2" color="red">
                    This field is required.
                  </Typography>
                )}
              </Grid>

              {watch("recruitmentCompanyPerProfile") && (
                <>
                  <Grid item xs={12} lg={6} md={12}>
                    <FormControl sx={{ width: "100%" }} variant="standard">
                      <PrimaryNormalInputLabel
                        shrink
                        htmlFor="folloups-job-type"
                        error={errors.job_type}
                      >
                        Payout*
                      </PrimaryNormalInputLabel>
                      <Select
                        labelId="folloups-job-type"
                        id="folloups-job-type"
                        // value={getValues("recruitmentCompanyPayoutTypePerProfile")}
                        {...register("recruitmentCompanyPayoutTypePerProfile", {
                          required: true,
                        })}
                        input={
                          <PrimaryNormalInputs
                            placeholder="Select A Job Type"
                            label="folloups-job-type"
                            error={
                              errors?.recruitmentCompanyPayoutTypePerProfile
                            }
                            {...register(
                              "recruitmentCompanyPayoutTypePerProfile",
                              {
                                required: true,
                              }
                            )}
                          />
                        }
                        MenuProps={MenuProps}
                      >
                        {payouts?.map(item => {
                          return (
                            <MenuItem
                              key={item?.value}
                              value={item?.value} // style={getStyles(name, personName, theme)}
                            >
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <PrimaryNormalFormHelperText
                        error={errors.recruitmentCompanyPayoutTypePerProfile}
                      >
                        {errors?.recruitmentCompanyPayoutTypePerProfile &&
                        watch("recruitmentCompanyPayoutTypePerProfile") == ""
                          ? "Payout type is a required field"
                          : ""}
                      </PrimaryNormalFormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} lg={6} md={12}>
                    <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                      Flat rate*
                    </PrimaryNormalInputLabel>
                    <PrimaryCommissionInputs
                      fullWidth
                      placeholder="Flat Rate(Per Profile)"
                      label="Flat Rate"
                      type="number"
                      // min={1}
                      {...register("recruitmentCompanyFlatRatePerProfile", {
                        required: true,
                     
                        // validate: value => {
                        //   const inputValue = Number(value);
                        //   const availableFlatRate =
                        //     currentFlatRate -
                        //     (watch("referredFlatRate") || 0) -
                        //     (watch("screeningFlatRatePerProfile") || 0) -
                        //     (watch("screeningFlatRateOnboard") || 0) -
                        //     (watch("technicalFlatRatePerProfile") || 0) -
                        //     (watch("technicalFlatRateOnboard") || 0) -
                        //     (watch("moderatorFlatRatePerProfile") || 0) -
                        //     (watch("moderatorFlatRateOnboard") || 0) -
                        //     (watch("selfReferralFlatRate") || 0) -
                        //     (watch("recruitmentCompanyFlatRate") || 0) -
                        //     (watch("backgroundFlatRatePerProfile") || 0) -
                        //     (watch("backgroundFlatRateOnboard") || 0);
                        //   if (
                        //     inputValue > availableFlatRate ||
                        //     availableFlatRate <= 0
                        //   ) {
                        //     return `Flat Rate should be less than ${
                        //       availableFlatRate > 0
                        //         ? availableFlatRate
                        //         : "the available flat rate is less than or equal to 0"
                        //     }.`;
                        //   }
                        // },

                      })}
                      onChange={e =>
                        handleChange({
                          value: e.target.value,
                          availFaltRate:
                            currentFlatRate -
                            (watch("referredFlatRate") || 0) -
                            (watch("screeningFlatRatePerProfile") || 0) -
                            (watch("screeningFlatRateOnboard") || 0) -
                            (watch("technicalFlatRatePerProfile") || 0) -
                            (watch("technicalFlatRateOnboard") || 0) -
                            (watch("moderatorFlatRatePerProfile") || 0) -
                            (watch("moderatorFlatRateOnboard") || 0) -
                            (watch("selfReferralFlatRate") || 0) -
                            (watch("recruitmentCompanyFlatRate") || 0) -
                            (watch("backgroundFlatRatePerProfile") || 0) -
                            (watch("backgroundFlatRateOnboard") || 0),
                          type: "recruitmentCompanyFlatRatePerProfile",
                        })
                      }
                      error={!!errors.recruitmentCompanyFlatRatePerProfile}
                      helperText={
                        errors.recruitmentCompanyFlatRatePerProfile &&
                        "Flat Rate is required"
                      }
                    />
                    <PrimaryNormalFormHelperText
                      error={errors.recruitmentCompanyFlatRatePerProfile}
                    >
                      {errors?.recruitmentCompanyFlatRatePerProfile
                        ? // errors?.recruitmentCompanyFlatRatePerProfile?.message
                          "Flat rate is required"
                        : showFlatRate.recruitmentCompanyFlatRatePerProfile !==
                          ""
                        ? 
                        // `Available Flat Rate: ${showFlatRate.recruitmentCompanyFlatRatePerProfile}`
                        ""
                        : ""}
                    </PrimaryNormalFormHelperText>
                  </Grid>
                </>
              )}

              {watch("recruitmentCompanyOnboard") && (
                <>
                  <Grid item xs={12} lg={6} md={12}>
                    <FormControl sx={{ width: "100%" }} variant="standard">
                      <PrimaryNormalInputLabel
                        shrink
                        htmlFor="folloups-job-type"
                        error={errors.job_type}
                      >
                        Payout*
                      </PrimaryNormalInputLabel>
                      <Select
                        labelId="folloups-job-type"
                        id="folloups-job-type"
                        // value={getValues("recruitmentCompanyPayoutTypeOnboard")}
                        {...register("recruitmentCompanyPayoutTypeOnboard", {
                          required: true,
                        })}
                        input={
                          <PrimaryNormalInputs
                            placeholder="Select A Job Type"
                            label="folloups-job-type"
                            error={errors?.recruitmentCompanyPayoutTypeOnboard}
                            {...register(
                              "recruitmentCompanyPayoutTypeOnboard",
                              {
                                required: true,
                              }
                            )}
                          />
                        }
                        MenuProps={MenuProps}
                      >
                        {payouts?.map(item => {
                          return (
                            <MenuItem
                              key={item?.value}
                              value={item?.value} // style={getStyles(name, personName, theme)}
                            >
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <PrimaryNormalFormHelperText
                        error={errors.recruitmentCompanyPayoutTypeOnboard}
                      >
                        {errors?.recruitmentCompanyPayoutTypeOnboard &&
                        watch("recruitmentCompanyPayoutTypeOnboard") == ""
                          ? "Payout type is a required field"
                          : ""}
                      </PrimaryNormalFormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} lg={6} md={12}>
                    <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                      Flat rate*
                    </PrimaryNormalInputLabel>
                    <PrimaryCommissionInputs
                      fullWidth
                      placeholder="Flat Rate(Onboard)"
                      label="Flat Rate"
                      type="number"
                      // min={1}
                      {...register("recruitmentCompanyFlatRate", {
                        required: true,
                      

                        // validate: value => {
                        //   const inputValue = Number(value);
                        //   const availableFlatRate =
                        //     currentFlatRate -
                        //     (watch("referredFlatRate") || 0) -
                        //     (watch("screeningFlatRatePerProfile") || 0) -
                        //     (watch("screeningFlatRateOnboard") || 0) -
                        //     (watch("technicalFlatRatePerProfile") || 0) -
                        //     (watch("technicalFlatRateOnboard") || 0) -
                        //     (watch("moderatorFlatRatePerProfile") || 0) -
                        //     (watch("moderatorFlatRateOnboard") || 0) -
                        //     (watch("recruitmentCompanyFlatRatePerProfile") ||
                        //       0) -
                        //     (watch("selfReferralFlatRate") || 0) -
                        //     (watch("backgroundFlatRatePerProfile") || 0) -
                        //     (watch("backgroundFlatRateOnboard") || 0);
                        //   if (
                        //     inputValue > availableFlatRate ||
                        //     availableFlatRate <= 0
                        //   ) {
                        //     return `Flat Rate should be less than ${
                        //       availableFlatRate > 0
                        //         ? availableFlatRate
                        //         : "the available flat rate is less than or equal to 0"
                        //     }.`;
                        //   }
                        // },

                      })}
                      onChange={e =>
                        handleChange({
                          value: e.target.value,
                          availFaltRate:
                            currentFlatRate -
                            (watch("referredFlatRate") || 0) -
                            (watch("screeningFlatRatePerProfile") || 0) -
                            (watch("screeningFlatRateOnboard") || 0) -
                            (watch("technicalFlatRatePerProfile") || 0) -
                            (watch("technicalFlatRateOnboard") || 0) -
                            (watch("moderatorFlatRatePerProfile") || 0) -
                            (watch("moderatorFlatRateOnboard") || 0) -
                            (watch("recruitmentCompanyFlatRatePerProfile") ||
                              0) -
                            (watch("selfReferralFlatRate") || 0) -
                            (watch("backgroundFlatRatePerProfile") || 0) -
                            (watch("backgroundFlatRateOnboard") || 0),
                          type: "recruitmentCompanyFlatRate",
                        })
                      }
                      error={!!errors.recruitmentCompanyFlatRate}
                      helperText={
                        errors.recruitmentCompanyFlatRate &&
                        "Flat Rate is required"
                      }
                    />
                    <PrimaryNormalFormHelperText
                      error={errors.recruitmentCompanyFlatRate}
                    >
                      {errors?.recruitmentCompanyFlatRate
                        ? // errors?.recruitmentCompanyFlatRate?.message
                          "Flat rate is required"
                        : showFlatRate.recruitmentCompanyFlatRate !== ""
                        ?
                        //  `Available Flat Rate: ${showFlatRate.recruitmentCompanyFlatRate}`
                        ""
                        : ""}
                    </PrimaryNormalFormHelperText>
                  </Grid>
                </>
              )}

              {/* Background company Section */}

              <Grid item xs={12}>
                <CommissionTypography variant="h1">
                  Background Verification
                </CommissionTypography>
              </Grid>

              {/* <Grid item xs={12}>
                <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                  Flat rate
                </PrimaryNormalInputLabel>
                <PrimaryCommissionInputs
                  sx={{ marginTop: "22px" }}
                  fullWidth
                  label="Flat Rate"
                  {...register("bgvFlatRate", { required: true })}
                  error={!!errors.bgvFlatRate}
                  helperText={errors.bgvFlatRate && "Flat Rate is required"}
                />
              </Grid> */}

              <Grid item xs={12} lg={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={getValues("backgroundPerProfile")}
                      {...register("backgroundPerProfile", { required: false })}
                      color="primary"
                    />
                  }
                  label="Per Profile"
                  sx={{
                    color: errors.backgroundPerProfile ? "red" : "inherit",
                  }}
                />
                {errors.screeningPerProfile && (
                  <Typography variant="body2" color="red">
                    This field is required.
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} lg={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={getValues("backgroundOnboard")}
                      {...register("backgroundOnboard", { required: false })}
                      color="primary"
                    />
                  }
                  label="Onboard"
                  sx={{
                    color: errors.screeningOnboard ? "red" : "inherit",
                  }}
                />
                {errors.screeningOnboard && (
                  <Typography variant="body2" color="red">
                    This field is required.
                  </Typography>
                )}
              </Grid>

              {watch("backgroundPerProfile") && (
                <Grid item xs={12} lg={6}>
                  <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                    Flat rate
                  </PrimaryNormalInputLabel>
                  <PrimaryCommissionInputs
                    fullWidth
                    placeholder="Flat Rate (Per Profile)"
                    label="Flat Rate (Per Profile)"
                    type="number"
                    // min={1}
                    {...register("backgroundFlatRatePerProfile", {
                      required: false,
                  

                      // validate: value => {
                      //   const inputValue = Number(value);
                      //   const availableFlatRate =
                      //     currentFlatRate -
                      //     (watch("referredFlatRate") || 0) -
                      //     (watch("screeningFlatRatePerProfile") || 0) -
                      //     (watch("screeningFlatRateOnboard") || 0) -
                      //     (watch("technicalFlatRatePerProfile") || 0) -
                      //     (watch("technicalFlatRateOnboard") || 0) -
                      //     (watch("moderatorFlatRatePerProfile") || 0) -
                      //     (watch("moderatorFlatRateOnboard") || 0) -
                      //     (watch("recruitmentCompanyFlatRatePerProfile") || 0) -
                      //     (watch("recruitmentCompanyFlatRate") || 0) -
                      //     (watch("selfReferralFlatRate") || 0) -
                      //     (watch("backgroundFlatRateOnboard") || 0);
                      //   if (
                      //     inputValue > availableFlatRate ||
                      //     availableFlatRate <= 0
                      //   ) {
                      //     return `Flat Rate should be less than ${
                      //       availableFlatRate > 0
                      //         ? availableFlatRate
                      //         : "the available flat rate is less than or equal to 0"
                      //     }.`;
                      //   }
                      // },

                    })}
                    onChange={e =>
                      handleChange({
                        value: e.target.value,
                        availFaltRate:
                          currentFlatRate -
                          (watch("referredFlatRate") || 0) -
                          (watch("screeningFlatRatePerProfile") || 0) -
                          (watch("screeningFlatRateOnboard") || 0) -
                          (watch("technicalFlatRatePerProfile") || 0) -
                          (watch("technicalFlatRateOnboard") || 0) -
                          (watch("moderatorFlatRatePerProfile") || 0) -
                          (watch("moderatorFlatRateOnboard") || 0) -
                          (watch("recruitmentCompanyFlatRatePerProfile") || 0) -
                          (watch("recruitmentCompanyFlatRate") || 0) -
                          (watch("selfReferralFlatRate") || 0) -
                          (watch("backgroundFlatRateOnboard") || 0),
                        type: "backgroundFlatRatePerProfile",
                      })
                    }
                    error={!!errors.backgroundFlatRatePerProfile}
                    helperText={
                      errors.backgroundFlatRatePerProfile &&
                      "Flat Rate is required"
                    }
                  />

                  <PrimaryNormalFormHelperText
                    error={errors.backgroundFlatRatePerProfile}
                  >
                    {errors?.backgroundFlatRatePerProfile
                      ? errors?.backgroundFlatRatePerProfile?.message
                      : // getValues("backgroundFlatRatePerProfile") ||
                      //   watch("backgroundFlatRatePerProfile")
                      // ? `Available Flat Rate: ${
                      //     currentFlatRate -
                      //     (watch("referredFlatRate") || 0) -
                      //     (getValues("backgroundFlatRatePerProfile") ||
                      //       watch("backgroundFlatRatePerProfile") ||
                      //       0) -
                      //     (watch("screeningFlatRatePerProfile") || 0) -
                      //     (watch("screeningFlatRateOnboard") || 0) -
                      //     (watch("technicalFlatRatePerProfile") || 0) -
                      //     (watch("technicalFlatRateOnboard") || 0) -
                      //     (watch("moderatorFlatRatePerProfile") || 0) -
                      //     (watch("moderatorFlatRateOnboard") || 0) -
                      //     (watch("recruitmentCompanyFlatRatePerProfile") || 0) -
                      //     (watch("recruitmentCompanyFlatRate") || 0) -
                      //     (watch("selfReferralFlatRate") || 0) -
                      //     (watch("backgroundFlatRateOnboard") || 0)
                      //   }`
                      showFlatRate.backgroundFlatRatePerProfile !== ""
                      ? 
                      // `Available Flat Rate: ${showFlatRate.backgroundFlatRatePerProfile}`
                      ""
                      : ""}
                  </PrimaryNormalFormHelperText>
                </Grid>
              )}

              {watch("backgroundOnboard") && (
                <Grid item xs={12} lg={6}>
                  <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                    Flat rate
                  </PrimaryNormalInputLabel>
                  <PrimaryCommissionInputs
                    fullWidth
                    placeholder="Flat Rate (Onboard)"
                    label="Flat Rate (Onboard)"
                    type="number"
                    // min={1}
                    {...register("backgroundFlatRateOnboard", {
                      required: false,
                  
                      // validate: value => {
                      //   const inputValue = Number(value);
                      //   const availableFlatRate =
                      //     currentFlatRate -
                      //     (watch("referredFlatRate") || 0) -
                      //     (watch("screeningFlatRatePerProfile") || 0) -
                      //     (watch("screeningFlatRateOnboard") || 0) -
                      //     (watch("technicalFlatRatePerProfile") || 0) -
                      //     (watch("technicalFlatRateOnboard") || 0) -
                      //     (watch("moderatorFlatRatePerProfile") || 0) -
                      //     (watch("moderatorFlatRateOnboard") || 0) -
                      //     (watch("recruitmentCompanyFlatRatePerProfile") || 0) -
                      //     (watch("recruitmentCompanyFlatRate") || 0) -
                      //     (watch("backgroundFlatRatePerProfile") || 0) -
                      //     (watch("selfReferralFlatRate") || 0);
                      //   if (
                      //     inputValue > availableFlatRate ||
                      //     availableFlatRate <= 0
                      //   ) {
                      //     return `Flat Rate should be less than ${
                      //       availableFlatRate > 0
                      //         ? availableFlatRate
                      //         : "the available flat rate is less than or equal to 0"
                      //     }.`;
                      //   }
                      // },

                    })}
                    onChange={e =>
                      handleChange({
                        value: e.target.value,
                        availFaltRate:
                          currentFlatRate -
                          (watch("referredFlatRate") || 0) -
                          (watch("screeningFlatRatePerProfile") || 0) -
                          (watch("screeningFlatRateOnboard") || 0) -
                          (watch("technicalFlatRatePerProfile") || 0) -
                          (watch("technicalFlatRateOnboard") || 0) -
                          (watch("moderatorFlatRatePerProfile") || 0) -
                          (watch("moderatorFlatRateOnboard") || 0) -
                          (watch("recruitmentCompanyFlatRatePerProfile") || 0) -
                          (watch("recruitmentCompanyFlatRate") || 0) -
                          (watch("backgroundFlatRatePerProfile") || 0) -
                          (watch("selfReferralFlatRate") || 0),
                        type: "backgroundFlatRateOnboard",
                      })
                    }
                    error={!!errors.backgroundFlatRateOnboard}
                    helperText={
                      errors.backgroundFlatRateOnboard &&
                      "Flat Rate is required"
                    }
                  />
                  <PrimaryNormalFormHelperText
                    error={errors.backgroundFlatRateOnboard}
                  >
                    {errors?.backgroundFlatRateOnboard
                      ? errors?.backgroundFlatRateOnboard?.message
                      : // getValues("backgroundFlatRateOnboard") ||
                      //   watch("backgroundFlatRateOnboard")
                      // ? `Available Flat Rate: ${
                      //     currentFlatRate -
                      //     (watch("referredFlatRate") || 0) -
                      //     (getValues("backgroundFlatRateOnboard") ||
                      //       watch("backgroundFlatRateOnboard") ||
                      //       0) -
                      //     (watch("screeningFlatRatePerProfile") || 0) -
                      //     (watch("screeningFlatRateOnboard") || 0) -
                      //     (watch("technicalFlatRatePerProfile") || 0) -
                      //     (watch("technicalFlatRateOnboard") || 0) -
                      //     (watch("moderatorFlatRatePerProfile") || 0) -
                      //     (watch("moderatorFlatRateOnboard") || 0) -
                      //     (watch("recruitmentCompanyFlatRatePerProfile") || 0) -
                      //     (watch("recruitmentCompanyFlatRate") || 0) -
                      //     (watch("backgroundFlatRatePerProfile") || 0) -
                      //     (watch("selfReferralFlatRate") || 0)
                      //   }`
                      showFlatRate.backgroundFlatRateOnboard !== ""
                      ? 
                      // `Available Flat Rate: ${showFlatRate.backgroundFlatRateOnboard}`
                      ""
                      : ""}
                  </PrimaryNormalFormHelperText>
                </Grid>
              )}

              {/* Similar sections for other partner types */}
            </Grid>

            <PrimaryButton
              type="submit"
              sx={{ marginTop: "8px", width: "100px" }}
            >
              Submit
            </PrimaryButton>
          </form>
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose}>
            Save changes
          </Button> */}
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
