// import { withRouter } from "react-router-dom";
// import React from "react";
// import { AvField, AvForm } from "availity-reactstrap-validation";
// import {
//   getAllFunctionalArea,
//   getSingleFunctionalArea,
// } from "helpers/services/jobAttributes/functionalArea";
// import ReactSelect from "react-select";
// const { Modal, Label } = require("reactstrap");
// import PropTypes from "prop-types";
// import { PrimaryButton, SecondaryFilledButton } from "assets/Mui/globalTheme";


// class CommonModel extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       input: "",
//       active: false,
//       options: [],
//       functional_area: {},
//     };
//     this.handleSubmit = this.handleSubmit.bind(this);
//     this.handleInput = this.handleInput.bind(this);
//   }
//   async componentDidMount() {
//     this.mounted = true;
//     const items = await getAllFunctionalArea(page=1,rowsPerPage=20,searchText="");
//     const data = items
//     .filter(iterator => iterator?.is_active === true)
//     .map(iterator => ({
//       value: iterator.id,
//       label: iterator.name,
//     }));
  

//     if (this.mounted) this.setState({ options: data });
//   }
//   componentWillUnmount() {
//     this.mounted = false;
//   }
//   async componentDidUpdate(prevProps) {
//     if (
//       this.props.purpose === "Create" &&
//       this.props.purpose !== prevProps.purpose
//     ) {
//       this.setState({ input: "", functional_area: {} });
//     }
//     if (this.props.fn_id !== prevProps.fn_id && this.props.purpose === "Edit") {
//       const defaultFnArea = this.props.fn_id;
//       if (defaultFnArea !== "") {
//         const data = await getSingleFunctionalArea(defaultFnArea);
//         this.setState({
//           functional_area: {
//             value: data.id,
//             label: data.name,
//           },
//         });
//       }
//     }
//     if (this.props.title !== prevProps.title) {
//       this.setState({
//         input: this.props.title,
//       });
//     }
//   }
//   handleInput(e) {
//     this.setState({ input: e.target.value });
//   }
//   handleSubmit() {
//     if (this.state.input !== "" || this.state.functional_area.value !== "") {
//       this.props.update(
//         {
//           name: this.state.input,
//           is_active: true,
//           functional_area_id: this.state.functional_area?.value,
//         },
//         this.props.purpose,
//         this.props.index
//       );
//     }
//   }

//   render() {
//     const {
//       headTag,
//       isOpen,
//       toggle,
//       purpose,
//       // default: defaulValue,
//     } = this.props;
//     return (
//       <Modal isOpen={isOpen} toggle={this.toggle}>
//         <div className="modal-header">
//           <h5 className="modal-title mt-0" id="myModalLabel">
//             {purpose} {headTag}
//           </h5>
//           <button
//             type="button"
//             onClick={() => toggle(!isOpen)}
//             className="close"
//             data-dismiss="modal"
//             aria-label="Close"
//           >
//             <span aria-hidden="true">&times;</span>
//           </button>
//         </div>
//         <div className="modal-body">
//           <AvForm>
//             <AvField
//               name="text"
//               label={`Create ${headTag}`}
//               className="form-control"
//               value={this.state.input}
//               onChange={this.handleInput}
//               placeholder={`Enter ${headTag} Name`}
//               type="text"
//               required
//             />{" "}
//           </AvForm>
//         </div>
//         <div className="modal-body">
//           <Label>Category</Label>

//           <ReactSelect
//             style={{ zIndex: "999" }}
//             value={this.state.functional_area}
//             isMulti={false}
//             onChange={e => {
//               this.setState({
//                 functional_area: { value: e.value, label: e.label },
//               });
//             }}
//             options={this.state.options}
//             classNamePrefix="select2-selection"
//           />
//         </div>

//         <div className="modal-footer">
//           <SecondaryFilledButton
//             type="button"
//             sx={{ width: "100px", marginRight: "10px" }}
//             onClick={() => {
//               toggle(!isOpen);
//             }}
//             data-dismiss="modal"
//           >
//             Close
//           </SecondaryFilledButton>
//           <PrimaryButton
//             type="button"
//             sx={{ width: "150px" }}
//             className="btn btn-primary"
//             onClick={this.handleSubmit}
//           >
//             Save changes
//           </PrimaryButton>
//         </div>
//       </Modal>
//     );
//   }
// }
// CommonModel.propTypes = {
//   headTag: PropTypes.string,
//   isOpen: PropTypes.bool,
//   toggle: PropTypes.func,
//   purpose: PropTypes.string,
//   fn_id: PropTypes.string,
//   title: PropTypes.string,
//   update: PropTypes.func,
//   index: PropTypes.number,
// };
// export default withRouter(CommonModel);





import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { getAllFunctionalArea, getSingleFunctionalArea } from "helpers/services/jobAttributes/functionalArea";
import ReactSelect from "react-select";
import { Modal, Label } from "reactstrap";
import PropTypes from "prop-types";
import { PrimaryButton, SecondaryFilledButton } from "assets/Mui/globalTheme";

const CommonModel = (props) => {
  const [input, setInput] = useState("");
  const [active, setActive] = useState(false);
  const [options, setOptions] = useState([]);
  const [functionalArea, setFunctionalArea] = useState({});
  const [page, setPage] = useState(1);

  useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      try {
        const items = await getAllFunctionalArea(page, 20, "");

        const data = items?.data?.data_payload
          .filter((iterator) => iterator?.is_active === true)
          .map((iterator) => ({
            value: iterator.id,
            label: iterator.name,
          }));

        if (mounted) setOptions(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, []);


  useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      try {
        const items = await getAllFunctionalArea(page, 20, "");

        const data = items?.data?.data_payload
          .filter((iterator) => iterator?.is_active === true)
          .map((iterator) => ({
            value: iterator.id,
            label: iterator.name,
          }));

        if (mounted) setOptions((prevOptions) => [...prevOptions, ...data]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;

      if (scrollTop + clientHeight >= scrollHeight - 10) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);

    fetchData();

    return () => {
      mounted = false;
      window.removeEventListener("scroll", handleScroll);
    };
  }, [page]);


  useEffect(() => {
    if (props.purpose === "Create") {
      setInput("");
      setFunctionalArea({});
    }

    if (props.fn_id && props.purpose === "Edit") {
      const defaultFnArea = props.fn_id;
      if (defaultFnArea !== "") {
        const fetchData = async () => {
          try {
            const data = await getSingleFunctionalArea(defaultFnArea);
            setFunctionalArea({
              value: data?.id,
              label: data.name,
            });
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };

        fetchData();
      }
    }

    if (props.title) {
      setInput(props.title);
    }
  }, [props]);

  const handleInput = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = () => {

    if (input !== "" || functionalArea.value !== "") {
      props.update(
        {
          name: input,
          is_active: true,
          functional_area_id: functionalArea?.value,
          roleId:props.id
        },
        props.purpose,
        props.id
      );
    }
    props.toggle(!props.isOpen)
    props.setViewAddModal(!props.viewAddModal)
  };
// console.log(props.fn_id,"balu")
  return (
    <Modal isOpen={props.isOpen} toggle={() => props.toggle(!props.isOpen)}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {props.purpose} {props.headTag}
        </h5>
        <button
          type="button"
          // onClick={() => props.toggle(!props.isOpen)}
          onClick={()=>{
            props.toggle(!props.isOpen)
            props.setViewAddModal(!props.viewAddModal)
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <AvForm>
          <AvField
            name="text"
            label={`Create ${props.headTag}`}
            className="form-control"
            value={input}
            onChange={handleInput}
            placeholder={`Enter ${props.headTag} Name`}
            type="text"
            required
          />
        </AvForm>
      </div>
      <div className="modal-body">
        <Label>Category</Label>
        <ReactSelect
          style={{ zIndex: "999" }}
          value={functionalArea}
          isMulti={false}
          onChange={(e) => {
            setFunctionalArea({ value: e.value, label: e.label });
          }}
          options={options}
          classNamePrefix="select2-selection"
        />
      </div>
      <div className="modal-footer">
        <SecondaryFilledButton
          type="button"
          sx={{ width: "100px", marginRight: "10px" }}
          onClick={() => {
            props.toggle(!props.isOpen);
            props.setViewAddModal(!props.viewAddModal)
          }}
          data-dismiss="modal"
        >
          Close
        </SecondaryFilledButton>
        <PrimaryButton
          type="button"
          sx={{ width: "150px" }}
          className="btn btn-primary"
          onClick={handleSubmit}
        >
          Save changes
        </PrimaryButton>
      </div>
    </Modal>
  );
};

CommonModel.propTypes = {
  headTag: PropTypes.string,
  isOpen: PropTypes.bool,
  setViewAddModal:PropTypes.bool,
  viewAddModal:PropTypes.bool,
  toggle: PropTypes.func,
  purpose: PropTypes.string,
  fn_id: PropTypes.string,
  title: PropTypes.string,
  update: PropTypes.func,
  index: PropTypes.number,
};

export default withRouter(CommonModel);


