import { POST, PUT } from "common/constants/methods";
// !getting company related things
async function companyId() {
  // return await JSON.parse(localStorage.getItem("authUser"))?.company_id;
  let id 
  let userId = await JSON.parse(localStorage.getItem("authUser"))?.company_id
  if(userId){
    id = userId
  }else {
    id = await JSON.parse(localStorage.getItem("authUser"))?.recrutement_company_id
  }
  return id
}

//!getting company details
async function companyUserID() {
  return await JSON.parse(localStorage.getItem("authUser"))?.id;
}
const { default: callApi } = require("helpers/callApi");

//!only for company crud

export const handleCompanyDelete = async key => {
  await callApi(`admin_authorise/delete_company/${key}`, {}, "DELETE");
  return data.data;
};
export const getAllCompanies = async () => {
  const data = await callApi("admin_authorise/get_companies_list");
  return data.data;
};
export const getAllCompaniesWithStatus = async (page,rowsPerPage,searchText) => {
  return await callApi(`admin_authorise/get_companies_list?pageSize=${rowsPerPage}&pageNo=${page}&searchParam=${searchText}`);
};

export const getAllCompaniesWithStatu = async (page=1,rowsPerPage=500,searchText="") => {

  return await callApi(`admin_authorise/get_companies_list?pageSize=${500}&pageNo=${1}&searchParam=${searchText}`);
};

//!may hit errors
export const getSingleCompanies = async key => {
  const data = await callApi(`company_authorise/${key}`);
  return data.data;
};

//!only for getting loggedin company details
export const getLoggedInCompanyDetails = async () => {
  const data = await callApi(
    `company_authorise/${await companyUserID()}`,
    {},
    "GET"
  );
  return data?.data?.data_payload;
};
export const viewSingleCompanyDetails = async key => {
  const data = await callApi(`/company_authorise/${key}`);

  return data.data;
};
export const createCompanies = async body => {
  const reqBody = {
    email: body.email,
    first_name: body.first_name,
    last_name: body.last_name,
    phone_number: body.phone_number,
    user_password: body.password,
    is_active: true,
    usertype: body.userType,
    company_name: body.company_name,
  };
  const data = await callApi("/company_authorise/register", reqBody, POST);
  return data;
};
export const updateCompanies = async data => {
  return await callApi(`/company_authorise/${await companyId()}`, data, PUT);
};

//!only for company staff crud

export const createCompanyStaff = async ({
  email,
  first_name,
  last_name,
  user_password,
  usertype,
  phone_number
}) => {
  const data = {
    email,
    first_name,
    last_name,
    user_password,
    is_active: true,
    phone_number: phone_number,
    usertype,
    company_id: await companyId(),
  };
  return await callApi("company_staff/register", data, POST);
};


export const createRcCompanyStaff = async ({
  email,
  first_name,
  last_name,
  user_password,
  usertype,
  phone_number
}) => {
  const data = {
    email,
    first_name,
    last_name,
    user_password,
    is_active: true,
    phone_number:phone_number,
    usertype,
    company_id: await companyId(),
  };
  return await callApi("company_staff/register", data, POST);
};

// export const getAllCompanyStaffList = async () => {
//   const result = await callApi(
//     `company_authorise/get_company_staff_list?company_id=${await companyId()}`
//   );
//   return result.data;
// };

/*Uncomment this once pagination with search implemented from backend */

export const getAllCompanyStaffList = async (page,pageSize,search) => {
  const result = await callApi(
    `company_authorise/get_company_staff_list?company_id=${await companyId()}&searchParam=${search}&pageNo=${page}&pageSize=${pageSize}`
  );
  return result.data;
};


export const getSingleCompanyStaffDetails = async id => {
  const result = await callApi(
    `company_staff/${id}?company_id=${await companyId()}`
  );
  return result.data;
};

export const deleteCompanyStaff = async id => {
  const data = await callApi(`/company_staff/${id}`, {}, "DELETE");
  return data;
};

export const updateCompanyStaff = async (items, id) => {
  const data = {
    ...items,
    phone_number:items.mobile,
    company_id: await companyId(),
  };  delete data.mobile;

  return await callApi(`company_staff/${id}`, data, PUT);
};


/*Get Otp testing API */

export const getOtpFunction = async (phoneNumber)=>{
const data = {
  phone_number:phoneNumber
}
return await callApi(``,data,POST)
}
