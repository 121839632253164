import { Avatar, IconButton, Stack, Box } from "@mui/material";
import { blue } from "@mui/material/colors";
import {
  GrayDivider,
  GreyTypography,
  StyledSecondaryTypography,
  BlackTypography,
  MediumPrimaryAvatar,
} from "assets/Mui/globalTheme";
import { imageURL } from "common/constants/commonURLS";
import { CA, ZA } from "common/constants/userModules";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import ClipBoardData from "helpers/dashboard/clipboard";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import JobCardPopUp from "./jobCardPopUp";
import { useCallback, useState } from "react";
import Rating from "@mui/material/Rating";
import { StyledRating } from "assets/Mui/globalTheme";

export default function JobPostedCompanyAvatar({ item }) {
  const user = useSelector(state => state.Login);
  const userId = user?.id;
  const history = useHistory();
  const [showPopover, setShowPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
    setShowPopover(true);
  }
  const handleClose = useCallback(() => {
    setShowPopover(false);
    setAnchorEl(null);
  }, [showPopover]);
  const path = window.location.pathname;
  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <div className="image-box">
        <MediumPrimaryAvatar
          alt="Company Logo"
          imgProps={{ loading: "lazy" }}
          src={`${imageURL}${item?.company?.company_image}`}
        >
          {item?.company?.company_name.charAt(0)?.toUpperCase()}
        </MediumPrimaryAvatar>
        <aside>
          {item?.is_job_post_verified ? (
            <Link to={`../joblist/jobs/${item.id}?user_id=${userId}`}>
              <Box
                display="flex"
                alignItems="center" // Align items vertically in the center
                justifyContent="center"
                gap={1} // Adjust the gap between job title and rating
              >
                <BlackTypography variant="h5" component="h5">
                  {item.job_title}
                </BlackTypography>
                {path.includes("/company") && (
                  <StyledRating name="hover-feedback" value={1} max={1} />
                )}
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                gap={1}
              >
                <span
                  style={{
                    // color: "gray",
                    color:"#000000",
                    fontSize: "14px",
                    opacity:"70%"
                  }}
                >
                  {item?.company?.company_name}
                </span>
              </Box>
            </Link>
          ) : (
            <Box>
              <BlackTypography variant="h5" component="h5">
                {item.job_title}
              </BlackTypography>
              <span
                style={{
                  color: "gray",
                  fontSize: "11px",
                }}
              >
                {item?.company?.company_name}
              </span>
            </Box>
          )}
          <Link
            className="h5 mt-2 mb-2"
            // to={`../joblist/jobs/${item.id}`}
            to={`../company/${item?.created_by_user_details?.id}`}
          >
            <GreyTypography variant="h6" component="h6">
              {item?.company_details?.company_name}
            </GreyTypography>
          </Link>
        </aside>
      </div>
      {path !== "/joblist/closed" ? (
        <IconButton style={{ alignSelf: "flex-start" }} onClick={handleClick}>
          {/* <FeatherIcon icon="more-horizontal" size="14"  /> */}
          <button
            style={{
              background: "#ffffff",
              border: "0.8px solid grey",
              borderRadius: "29px",
              height: "27.2px",
              textAlign: "center",
            }}
          >
            <FeatherIcon
              icon="more-horizontal"
              size="14"
              style={{ marginTop: "-15px" }}
            />
          </button>
        </IconButton>
      ) : (
        ""
      )}
      <JobCardPopUp
        handleClose={handleClose}
        id={item?.id}
        anchorEl={anchorEl}
        tableData={item}
      />
    </Stack>
  );
}
