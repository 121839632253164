import { Grid, Stack, Typography, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  PrimaryButton,
  PrimaryPaperComponent,
  PrimaryTypography,
  SecondaryCard,
} from "assets/Mui/globalTheme";
import CallMadeIcon from "@mui/icons-material/CallMade";
import BubbleLoader from "common/data/NoRowsFoundDatagrid/BubbleLoader";
import {
  getAllDashboardDetails,
  getTotalJobPosts,
  getTotalApplicants,
  getTotalHiredCount,
  getActiveRoles,
  getEvaluatedApplicants,
  getOnBoardedCount,
  getSourcedProfiles,
  getHiredCountRc,
  getNoOfCompanies,
  getActiveJobs,
  getNoOfEvaluators,
  getActiveJobsCount,
  getRecruitmentCount,
  getBackgroundCount,
  getOverallHiredCount,
  getAssociatedPartners,
  getHiredCandidates,
  getJobViewsCount,
  getEarningsCountForPartners,
  getEvalCandByBgv,
  getOnBoardedCandByBgv,
  // getRecruitmentCount,
  getRcSourceCount,
  getRcOnBoardedCount,
  getPendingJobsCount,
  getClosedJobsCount,
  getPendingJobsCountForCompany,
  getClosedJobsCountForCompany,
  getOfferedJobsCountForCompany,
  getSubmittedProfileCountForCompany,
} from "helpers/services/Dashboard/Dashboard";
import { socket } from "Sockets/socket";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from "chart.js";
import { Line } from "react-chartjs-2";
import SocketConnection from "hooks/sockets";
import {
  ACM,
  BVC,
  BVS,
  CA,
  CS,
  MODERATOR,
  SP,
  TE,
  ZA,
  RC,
  RS,
} from "common/constants/userModules";
import dataNotFound from "../../assets/images/noDataGreen.png";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const linecartData = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => -595),
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Dataset 2",
      data: labels.map(() => -234),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

export const data = {
  labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

export default function Dashboard() {
  const role = JSON.parse(localStorage.getItem("authUser")).role;
  const [loading, setLoading] = useState(true);
  const [ScreenedApplicants, setScreenedApplicants] = useState({});

  const [dashboardData, setDashboardData] = useState([]);

  useEffect(() => {
    // initialFetch();
  }, []);

  async function initialFetch() {
    const { data, status } = await getAllDashboardDetails();
    if (data) {
      setLoading(false);
    }
    setScreenedApplicants(data?.message);
  }

  /*Note :New dashboard api integration based on backend by logged in usertype(Based on backend FE integrated) */

  useEffect(() => {
    initialDashFetch(role);
  }, [role]);

  /*Fetch data reusable */

  const fetchData = async (apiFunction, tag) => {
    try {
      const res = await apiFunction();
      if (res.status === 200) {
        return {
          tag: res?.data?.tag || tag, // Use API tag if available, otherwise use the provided tag
          count: res?.data?.overall_count || "0",
        };
      }
    } catch (error) {
      console.error(`Error fetching data for ${tag}:`, error);
      return {
        tag,
        count: "-",
      };
    }
  };

  /*Company user type Api's */

  const TotalJobPosts = () => fetchData(getTotalJobPosts, "Total Job Posts");

  const TotalApplicants = () =>
    fetchData(getTotalApplicants, "Total Applicants");

  const HiredCount = () => fetchData(getTotalHiredCount, "Hired Count");

  const JobViewsCount = () => fetchData(getJobViewsCount, "Job Views Count");

  const PendingJobsCountForCompany = () =>
    fetchData(getPendingJobsCountForCompany, "Pending Jobs");

  const ClosedJobsCountForCompany = () =>
    fetchData(getClosedJobsCountForCompany, "Closed Jobs");

  const OfferedJobsCountForCompany = () =>
    fetchData(getOfferedJobsCountForCompany, "Offered Count");

  const SubmittedProfileCountForCompany = () =>
    fetchData(getSubmittedProfileCountForCompany, "Shortlisted Profiles");

  /*Company user type Api's */

  /*Partners userType api's */

  const GetActiveRoles = () => fetchData(getActiveRoles, "Active Roles");

  const EvaluatedApplicants = () =>
    fetchData(getEvaluatedApplicants, "EvaluatedApplicants");

  const OnBoardedCount = () => fetchData(getOnBoardedCount, "OnBoarded Count");

  const GetEarningsCount = () =>
    fetchData(getEarningsCountForPartners, "Earnings Count");

  const GetEvalCandByBgv = () =>
    fetchData(getEvalCandByBgv, "Evaluated Candidates");

  const GetOnBoardedCandByBgv = () =>
    fetchData(getOnBoardedCandByBgv, "Onboarded Candidates");

  /*Partners userType api's */

  /* Recruitment company user type api's */

  const SourcedProfiles = () =>
    fetchData(getSourcedProfiles, "No Of sourced profiles");

  const HiredCountForRc = () => fetchData(getHiredCountRc, "Hired count");

  const SourcedJobCountForRc = () =>
    fetchData(getRcSourceCount, "Total Profiles");

  const OnBoardedCountForRc = () =>
    fetchData(getRcOnBoardedCount, "Onboarded Profiles");

  /* Recruitment company user type api's */

  /*Admin user type api's */
  // const GetNoOfCompanies = async () => {
  //   const res = await getNoOfCompanies();
  //   if (res.status === 200) {
  //     //  return res.data?.overall_count
  //     return {
  //       tag: res?.data?.tag,
  //       count: res?.data?.overall_count,
  //     };
  //   }
  // };

  const GetNoOfCompanies = () => fetchData(getNoOfCompanies, "No Of Companies");

  const GetActiveJobs = () => fetchData(getActiveJobs, "Active Jobs");

  const GetNoOfEvalutions = () =>
    fetchData(getNoOfEvaluators, "No Of Evaluations");

  const GetBackgroundCount = () =>
    fetchData(getBackgroundCount, "BackgroundCount");

  const GetRecruitmentCount = () =>
    fetchData(getRecruitmentCount, "RecruitmentCount");

  const GetOverallHiredCount = () =>
    fetchData(getOverallHiredCount, "OverallHiredCount");

  /*Admin user type api's */

  /*Account manager user type api's */

  const GetActiveJobsCount = () =>
    fetchData(getActiveJobsCount, "Active Jobs Count");

  const GetAssociatedPartnersCount = () =>
    fetchData(getAssociatedPartners, "Associated Partners Count");

  const GetHiredCandidates = () =>
    fetchData(getHiredCandidates, "Hired Candidates");

  const GetPendingJobsCount = () =>
    fetchData(getPendingJobsCount, "Pending Jobs");

  const GetClosedJobsCount = () => fetchData(getClosedJobsCount, "Closed Jobs");

  /*Account manager user type api's */

  async function initialDashFetch(role) {
    // let result
    switch (role) {
      case CA:
        // let companyData = await Promise.all([
        //   TotalJobPosts(),
        //   TotalApplicants(),
        //   HiredCount(),
        //   JobViewsCount(),
        // ]);

        // setLoading(false);
        // setDashboardData(companyData);

        try {
          const promises = [
            TotalJobPosts(),
            TotalApplicants(),
            HiredCount(),
            JobViewsCount(),
            PendingJobsCountForCompany(),
            ClosedJobsCountForCompany(),
            OfferedJobsCountForCompany(),
            SubmittedProfileCountForCompany(),
          ];

          const results = await Promise.allSettled(promises);

          setLoading(false);

          const adminData = results.map((result, index) => {
            return result.status === "fulfilled"
              ? result.value
              : promises[index]();
          });

          setDashboardData(adminData);

          // Continue with other actions if needed
        } catch (error) {
          // Handle errors
          console.error("Error fetching data:", error);
        }

        break;

      case CS:
        try {
          const promises = [
            TotalJobPosts(),
            TotalApplicants(),
            HiredCount(),
            JobViewsCount(),
            PendingJobsCountForCompany(),
            ClosedJobsCountForCompany(),
            OfferedJobsCountForCompany(),
            SubmittedProfileCountForCompany(),
          ];

          const results = await Promise.allSettled(promises);

          setLoading(false);

          const adminData = results.map((result, index) => {
            return result.status === "fulfilled"
              ? result.value
              : promises[index]();
          });

          setDashboardData(adminData);

          // Continue with other actions if needed
        } catch (error) {
          // Handle errors
          console.error("Error fetching data:", error);
        }

        break;

      case SP:
        try {
          const promises = [
            GetActiveRoles(),
            EvaluatedApplicants(),
            OnBoardedCount(),
            GetEarningsCount(),
            OnBoardedCountForRc(),
          ];

          const results = await Promise.allSettled(promises);

          setLoading(false);

          const partnersData = results.map((result, index) => {
            return result.status === "fulfilled"
              ? result.value
              : promises[index]();
          });

          setDashboardData(partnersData);

          // Continue with other actions if needed
        } catch (error) {
          // Handle errors
          console.error("Error fetching partners data:", error);
        }

        break;

      case TE:
        try {
          const promises = [
            GetActiveRoles(),
            EvaluatedApplicants(),
            OnBoardedCount(),
            GetEarningsCount(),
            OnBoardedCountForRc(),
          ];

          const results = await Promise.allSettled(promises);

          setLoading(false);

          const partnersData = results.map((result, index) => {
            return result.status === "fulfilled"
              ? result.value
              : promises[index]();
          });

          setDashboardData(partnersData);

          // Continue with other actions if needed
        } catch (error) {
          // Handle errors
          console.error("Error fetching partners data:", error);
        }

        break;

      case MODERATOR:
        try {
          const promises = [
            GetActiveRoles(),
            EvaluatedApplicants(),
            OnBoardedCount(),
            GetEarningsCount(),
            OnBoardedCountForRc(),
          ];

          const results = await Promise.allSettled(promises);

          setLoading(false);

          const partnersData = results.map((result, index) => {
            return result.status === "fulfilled"
              ? result.value
              : promises[index]();
          });

          setDashboardData(partnersData);

          // Continue with other actions if needed
        } catch (error) {
          // Handle errors
          console.error("Error fetching partners data:", error);
        }

        break;

      case RC:
        try {
          const promises = [
            // GetActiveRoles(),
            SourcedProfiles(),
            HiredCountForRc(),
            SourcedJobCountForRc(),
            OnBoardedCountForRc(),
          ];

          const results = await Promise.allSettled(promises);

          setLoading(false);

          const recruitData = results.map((result, index) => {
            return result.status === "fulfilled"
              ? result.value
              : promises[index]();
          });

          setDashboardData(recruitData);

          // Continue with other actions if needed
        } catch (error) {
          // Handle errors
          console.error("Error fetching data:", error);
        }

        break;

      case RS:
        try {
          const promises = [
            // GetActiveRoles(),
            SourcedProfiles(),
            HiredCountForRc(),
            SourcedJobCountForRc(),
            OnBoardedCountForRc(),
          ];

          const results = await Promise.allSettled(promises);

          setLoading(false);

          const recruitData = results.map((result, index) => {
            return result.status === "fulfilled"
              ? result.value
              : promises[index]();
          });

          setDashboardData(recruitData);

          // Continue with other actions if needed
        } catch (error) {
          // Handle errors
          console.error("Error fetching data:", error);
        }
        break;

      case BVC:
        try {
          const promises = [
            GetActiveRoles(),
            GetEvalCandByBgv(),
            GetOnBoardedCandByBgv(),
          ];

          const results = await Promise.allSettled(promises);

          setLoading(false);

          const backgroundCompData = results.map((result, index) => {
            return result.status === "fulfilled"
              ? result.value
              : promises[index]();
          });

          setDashboardData(backgroundCompData);

          // Continue with other actions if needed
        } catch (error) {
          // Handle errors
          console.error("Error fetching background company data:", error);
        }

        break;

      case BVS:
        try {
          const promises = [
            GetActiveRoles(),
            GetEvalCandByBgv(),
            GetOnBoardedCandByBgv(),
          ];

          const results = await Promise.allSettled(promises);

          setLoading(false);

          const backgroundCompData = results.map((result, index) => {
            return result.status === "fulfilled"
              ? result.value
              : promises[index]();
          });

          setDashboardData(backgroundCompData);

          // Continue with other actions if needed
        } catch (error) {
          // Handle errors
          console.error("Error fetching background company data:", error);
        }
        break;

      case ZA:
        try {
          const promises = [
            GetNoOfCompanies(),
            GetActiveJobs(),
            GetNoOfEvalutions(),
            GetBackgroundCount(),
            GetRecruitmentCount(),
            GetOverallHiredCount(),
          ];

          const results = await Promise.allSettled(promises);

          setLoading(false);

          const adminData = results.map((result, index) => {
            return result.status === "fulfilled"
              ? result.value
              : promises[index]();
          });

          setDashboardData(adminData);

          // Continue with other actions if needed
        } catch (error) {
          // Handle errors
          console.error("Error fetching data:", error);
        }

        break;

      case ACM:
        try {
          const promises = [
            GetNoOfCompanies(),
            GetActiveJobsCount(),
            GetAssociatedPartnersCount(),
            GetHiredCandidates(),
            OnBoardedCountForRc(),
            GetPendingJobsCount(),
            GetClosedJobsCount(),
          ];

          const results = await Promise.allSettled(promises);

          setLoading(false);

          const accountMData = results.map((result, index) => {
            return result.status === "fulfilled"
              ? result.value
              : promises[index]();
          });

          setDashboardData(accountMData);

          // Continue with other actions if needed
        } catch (error) {
          // Handle errors
          console.error("Error fetching account management data:", error);
        }

        break;

      default:
        return null;
    }
  }

  /*Note :New dashboard api integration based on backend by logged in usertype(Based on backend FE integrated) */

  /*Page refresh based on socket listener */
  const handleListnerEvent = data => {
    if (data?.type === "dashboard") {
      initialDashFetch(role);
    }
  };

  useEffect(() => {
    const handleReconnect = () => {
      // Re-register the event listener after reconnection if needed
      socket.on("listnerEvent", handleListnerEvent);
    };

    socket.on("listnerEvent", handleListnerEvent);

    // Attach a listener for socket reconnection
    socket.on("reconnect", handleReconnect);

    return () => {
      socket.off("listnerEvent", handleListnerEvent);
      socket.off("reconnect", handleReconnect);
    };
  }, []);

  return (
    <>
      {loading ? (
        <>
          <div
            className="loader_container"
            style={{
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="loader"></div>
          </div>
        </>
      ) : (
        <>
          {dashboardData?.length > 0 ? (
            <>
              <Grid
                container
                mt={"20px"}
                ml={"3px"}
                spacing={2}
                sx={{ width: "98%" }}
              >
                {dashboardData &&
                  dashboardData?.map(each => (
                    <>
                      <Grid item xs={12} md={4}>
                        {/* <SecondaryCard sx={{ height: "100%" }}>
                          <Stack
                            sx={{ height: "100%" }}
                            spacing={1}
                            direction={"column"}
                            justifyContent={"center"}
                          >
                            <PrimaryTypography variant="h5">
                              {each?.tag}
                            </PrimaryTypography>
                            <PrimaryTypography variant="h1">
                              {each?.count ?? "0"}
                            </PrimaryTypography>
                          </Stack>
                        </SecondaryCard> */}

                        <Stack
                          direction="column"
                          justifyContent="space-between"
                          // key={index}
                          sx={{
                            gap: "10px",
                            padding: "10px",
                            // width: "fit-content",
                            minWidth: "250px",
                            borderRadius: "16px",
                            flex: 1,
                            border: "1px solid #E9E9E9",
                            boxShadow: "2px 2px 64px -44px #000000A6",
                            backgroundImage:
                              "linear-gradient(0deg, #30242400, #E9E9E9),linear-gradient(180deg, rgb(0 247 176 / 24%) 0%, rgba(252, 250, 243, 0) 100%)",
                          }}
                        >
                          <Stack>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: "500",
                                textTransform: "capitalize",
                              }}
                            >
                              {each?.tag}
                            </Typography>
                          </Stack>
                          <Stack>
                            <Typography
                              sx={{
                                fontSize: "25px",
                                fontWeight: "600",
                                color: "#066A4C",
                              }}
                            >
                              {each?.count ?? "0"}
                            </Typography>
                          </Stack>
                          <Stack direction="row" justifyContent="flex-end">
                            <IconButton
                              // onClick={() => navigate(`${each?.link}`)}
                              size="small"
                            >
                              <CallMadeIcon fontSize="inherit" />
                            </IconButton>
                          </Stack>
                        </Stack>
                      </Grid>{" "}
                    </>
                  ))}
              </Grid>
            </>
          ) : (
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  width: "350px",
                  height: "350px",
                  marginTop: 0,
                }}
                src={dataNotFound}
                alt="/not-found"
              />
            </div>
          )}
        </>
      )}
    </>
  );
}
