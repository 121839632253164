import {
    ACCOUNT_MANAGER,
    BVC,
    CA,
    MODERATOR,
    ZA,
  } from "common/constants/userModules";
  import {
    deleteSingleAdminStaff,
    getAllAdminStaffList,
    updateAdminStaff,
  } from "helpers/services/adminStaff/adminStaff";
  import {
    getAllCompanyStaffList,
    updateCompanyStaff,
  } from "helpers/services/companies/company";
  import empty from "../../assets/images/zepul/shiyftEmpty.png";
  import findUserType from "helpers/functions/findUserType";
  import React, {useState,useEffect} from "react";
  import { Alert, Button, Col, Row } from "reactstrap";
  import {
    Card,
    FormControlLabel,
    FormGroup,
    CardContent,
    Stack,
    Typography,
    Skeleton,
    IconButton,
  } from "@mui/material";
  import "../../assets/css/settings.css";
  import StaffModel from "./StaffModel/StaffModel";
  import { IOSSwitch } from "components/Common/iosswitch";
  import {
    createRecrutementCompanyStaff,
    getAllRecrutementCompanyStaffList,
    updateRecrutementCompanyStaff,
  } from "helpers/services/RecrutementCompany/recrutement";
  import {
    getAllBackgroundVerificationCompanyStaffList,
    updateBackgroundVerificationCompanyStaff,
  } from "helpers/services/BackgroundVerifications/backgroundVerifications";
  import {
    GreyTypography,
    PrimaryAvatar,
    PrimaryButton,
    PrimaryDivider,
    PrimarySwitch,
    PrimaryTypography,
    StageSearchInput,
  } from "assets/Mui/globalTheme";
  import FeatherIcon from "feather-icons-react/build/FeatherIcon";
  import { toast } from "react-hot-toast";
  import CustomPagination from "components/Pagination/globalPagination";
  import SearchParamHook from "hooks/searchParams";
  import { useLocation } from "react-router-dom";
import { useDebounce } from "hooks/useDebounce";
import { useParams,useHistory } from "react-router-dom";

export default function Staff() {
    const moderatorValue = 'moderatorSearchValue';
    const acmValue = 'acmSearchValue';
    const csValue = 'csSearchValue';
    const rcValue = 'rcSearchValue';
    const bvcValue = 'bvcSearchValue';
  
    const [state, setState] = useState({
      isOpen: false,
      active: false,
      headTag: '',
      RStaff: [],
      backendData: '',
      purpose: '',
      id: '',
      accountManager: [],
      moderator: [],
      update: false,
      BVCStaff: [],
      role: 'SP',
      staff: [],
      loading: true,
      isApprove: '',
      [moderatorValue]: '',
      [acmValue]: '',
      [csValue]: '',
      [rcValue]: '',
      [bvcValue]: '',
    });

    const searchParamsHook = SearchParamHook();
    const { search } = useLocation();
    const history = useHistory();

    const currentPage = searchParamsHook.get('page')
    const searchTextParam = searchParamsHook.get('searchParam')
    const [page, setPage] = React.useState(currentPage ?? 1);
    const [searchText, setSearchText] = useState(searchTextParam ?? "");
    const [totalCount, setTotalCount] = useState()
    const rowsPerPage = 20;
  
    const { moderatorSearchValue, acmSearchValue, csSearchValue, rcSearchValue, bvcSearchValue } = state;
  
    const toggle = (headTag = '', backendData = '', purpose = '', id = '', isApprove = 'Approve') => {
      setState((prevState) => ({
        ...prevState,
        headTag,
        backendData,
        isOpen: !prevState.isOpen,
        purpose,
        id,
        isApprove,
      }));
    };
  
    const update = () => {
      setState((prevState) => ({
        ...prevState,
        update: !prevState.update,
      }));
    };
  
    const handleSearchValue = (type, e) => {
      setSearchText(e.target.value)

      // setState((prevState) => ({
      //   ...prevState,
      //   [type]: e.target.value,
      // }));
    };
  
    const clearSearchValue = (type) => {
      setState((prevState) => ({
        ...prevState,
        [type]: '',
      }));
    };
  


    const fetchData = async () => {
      const user = await findUserType();
      setState((prevState) => ({ ...prevState, role: user }));

      if (user === 'ZA') {
        // const data = await getAllAdminStaffList();
         const  data  = await getAllAdminStaffList(page,rowsPerPage,searchText);
         if(data){
          setTotalCount(data?.total_page_size)
         }
        const sortDate = [...data?.data?.data_payload].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        sortDate.forEach((item) => {
          if (item.usertype === 'MODERATOR') {
            setState((prevState) => ({
              ...prevState,
              moderator: [...prevState.moderator, item],
            }));
          }
          if (item.usertype === 'ACCOUNT_MANAGER') {
            setState((prevState) => ({
              ...prevState,
              accountManager: [...prevState.accountManager, item],
            }));
          }
        });
      } else if (user === 'CA') {
        // const data = await getAllCompanyStaffList();

         const  data  = await getAllCompanyStaffList(page,rowsPerPage,searchText);
         if(data){
          setTotalCount(data?.total_page_size)
         }
        const sortData = [...data?.data_payload].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        setState((prevState) => ({ ...prevState, staff: sortData }));
      }
       else if (user === 'BVC') {
        
        
         const data  = await getAllBackgroundVerificationCompanyStaffList(page,rowsPerPage,searchText);
     
        // const sortData =[...data?.data?.data_payload]?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        // setState((prevState) => ({ ...prevState, BVCStaff: sortData}));

        if(data){
          setTotalCount(data?.total_page_size)
         }
        const sortData = [...data?.data?.data_payload].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        setState((prevState) => ({ ...prevState, BVCStaff: sortData }));

      }
       else if(user === 'RC') {
        
        // const { data } = await getAllRecrutementCompanyStaffList();
        const  data  = await getAllRecrutementCompanyStaffList(page,rowsPerPage,searchText);
        if(data){
          setTotalCount(data?.total_page_size)
         }
        const sortData = [...data?.data?.data_payload].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        setState((prevState) => ({ ...prevState, RStaff: sortData }));
      }

      setState((prevState) => ({ ...prevState, loading: false }));
    };


    // useEffect(() => {
    //   fetchData();
    // }, [state.update]);

    useEffect(() => {
      fetchData();
    }, []);

  
    // useEffect(() => {
    //   if (state.role === 'ZA') {
    //     const fetchData = async () => {
    //       const data = await getAllAdminStaffList(page,rowsPerPage,searchText);
    //       if(data){
    //         setTotalCount(data?.total_page_size)
    //        }
    //       const sortDate = [...data?.data_payload].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    //       setState((prevState) => ({ ...prevState, accountManager: [], moderator: [] }));
  
    //       sortDate.forEach((item) => {
    //         if (item.usertype === 'MODERATOR') {
    //           setState((prevState) => ({
    //             ...prevState,
    //             moderator: [...prevState.moderator, item],
    //           }));
    //         }
    //         if (item.usertype === 'ACCOUNT_MANAGER') {
    //           setState((prevState) => ({
    //             ...prevState,
    //             accountManager: [...prevState.accountManager, item],
    //           }));
    //         }
    //       });
    //     };
  
    //     fetchData();
    //   } else if (state.role === 'CA') {
    //     const fetchData = async () => {
    //       const data = await getAllCompanyStaffList(page,rowsPerPage,searchText);
    //       if(data){
    //         setTotalCount(data?.total_page_size)
    //        }
    //       const sortData = [...data?.data_payload].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    //       setState((prevState) => ({ ...prevState, staff: sortData }));
    //     };
  
    //     fetchData();
    //   } else if (state.role === 'BVC') {
    //     const fetchData = async () => {
    //       const data  = await getAllBackgroundVerificationCompanyStaffList(page,rowsPerPage,searchText);
    //       if(data){
    //         setTotalCount(data?.total_page_size)
    //        }
    //       const sortData = [...data?.data?.data_payload].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    //       // setState((prevState) => ({ ...prevState, BVCStaff: sortData }));
    //     };
  
    //     fetchData();
    //   } else if(state.role === 'RC') {
        
    //     const fetchData = async () => {
    //       const { data } = await getAllRecrutementCompanyStaffList(page,rowsPerPage,searchText);
    //       if(data){
    //         setTotalCount(data?.total_page_size)
    //        }
    //       const sortData = [...data?.data_payload].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    //       setState((prevState) => ({ ...prevState, RStaff: sortData }));
    //     };
  
    //     fetchData();
    //   }
    // }, [state.role, state.update]);
  
    const sortedModerator = state.moderator.filter((staff) => {
      const email = staff.email.toLowerCase();
      const user = staff.user_name.toLowerCase();
      return user.includes(moderatorSearchValue.toLowerCase().trim()) || email.includes(moderatorSearchValue.toLowerCase().trim());
    });
  
    const sortedACM = state.accountManager.filter((staff) => {
      const email = staff.email.toLowerCase();
      const user = staff.user_name.toLowerCase();
      return user.includes(acmSearchValue.toLowerCase().trim()) || email.includes(acmSearchValue.toLowerCase().trim());
    });
  
    const sortedCStaff = state.staff.filter((staff) => {
      const email = staff.email.toLowerCase();
      const user = staff.user_name.toLowerCase();
      return user.includes(csSearchValue.toLowerCase().trim()) || email.includes(csSearchValue.toLowerCase().trim());
    });
  
    const sortedRCStaff = state.RStaff.filter((staff) => {
      const email = staff.email.toLowerCase();
      const user = staff.user_name.toLowerCase();
      return user.includes(rcSearchValue.toLowerCase().trim()) || email.includes(rcSearchValue.toLowerCase().trim());
    });
  
    const sortedBGVStaff = state.BVCStaff.filter((staff) => {
      const email = staff.email.toLowerCase();
      const user = staff.user_name.toLowerCase();
      return user.includes(bvcSearchValue.toLowerCase().trim()) || email.includes(bvcSearchValue.toLowerCase().trim());
    });



/*search with pagination */
  useEffect(()=>{

    const searchParams = new URLSearchParams(search);
    searchParams.set("searchParam", searchText);
    history.push({
      search: searchParams.toString(),
    });

    // const fetchData = async () => {
    //   setIsLoading(true);
    //   const currentPage = searchParamsHook.get("page") ?? page
    //   const data = await getAllIndustriesList(currentPage, rowsPerPage,searchText);
    //   setData(data?.data_payload);
    //   setTotalCount(data?.total_page_size)
    //   setIsLoading(false);
    // };

    fetchData();
  },[searchParamsHook.get("page"), searchParamsHook.get("searchParam")])


  const debouncedQuery = useDebounce(searchText, 500);


  useEffect(() => {
    if(debouncedQuery){
      const searchParams = new URLSearchParams(search);
      searchParams.set("searchParam", debouncedQuery);
      searchParams.set("page",1)
      history.push({
        search: searchParams.toString(),
      });
    }
    else {
      const searchParams = new URLSearchParams(search);
      searchParams.set("searchParam", debouncedQuery);
      // setPage(1)
      history.push({
        search: searchParams.toString(),
      });
    }
 

  }, [debouncedQuery]);

/*search with pagination */


    return (
        <>
        {state.loading ? (
  <>
    <Typography component="div" variant={"h3"}>
      <Skeleton />
    </Typography>
    <Typography component="div" variant={"h3"}>
      <Skeleton />
    </Typography>{" "}
    <Typography component="div" variant={"h3"}>
      <Skeleton />
    </Typography>
  </>
) : (
  <React.Fragment>
    <Row>
      {state.isOpen ? (
        <StaffModel
          isOpen={state.isOpen}
          toggle={toggle}
          headTag={state.headTag}
          backendData={state.backendData}
          purpose={state.purpose}
          id={state.id}
          isApprove={state.isApprove}
          updated={update}
          fetchData={fetchData}
          role={state.role}
        />
      ) : null}

      <Row>
        {state.role === ZA ? (
          <>
          <Col xs={12} md={6}>
                      <div
                        className="email_brand_div"
                        style={{
                          marginBottom: "10px",
                          border: "none",
                          borderRadius: "4px",
                        }}
                      >
                        <div className="company_grid">
                          <div className="alert_small">
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems={"center"}
                            >
                              <PrimaryDivider
                                sx={{
                                  borderTopRightRadius: `5px`,
                                  borderBottomRightRadius: `5px`,
                                  height: "30px",
                                  paddingLeft: "4px",
                                }}
                                orientation="vertical"
                                flexItem
                              />
                              <PrimaryTypography component="h4" variant="h4">
                                Moderator
                              </PrimaryTypography>
                            </Stack>
                          </div>
                          {/* <PrimaryButton
                            sx={{ width: "100px" }}
                            color="primary"
                            onClick={() =>
                              this.toggle(
                                "Add Moderator",
                                "MODERATOR",
                                "CREATE"
                              )
                            }
                            startIcon={<FeatherIcon icon="plus" size="14" />}
                          >
                            Add
                          </PrimaryButton> */}
                        </div>
                      </div>                      
                        <StageSearchInput
                          placeholder="search..."
                          fullWidth
                          onChange={(e) => handleSearchValue(moderatorValue, e)} 
                          value={searchText}
                          endAdornment={
                            <IconButton
                              edge="end"
                              className={state.moderatorSearchValue ? "d-inline":"d-none"}
                              onClick={() => clearSearchValue(moderatorValue)}
                            >
                              <FeatherIcon icon={"x"} size={18} />
                            </IconButton>
                          }
                        />
                       <div style={{height:"calc(100vh - 300px)",marginTop:"10px", overflowY:"scroll"}}> 
                        {sortedModerator.length > 0 ? (
                          sortedModerator?.map((item, index) => (
                            <Card key={item?.id} style={{ marginBottom: "10px" }}>
                              <Row
                                className="email_brands email_brand_div"
                                key={index}
                              >
                                <Col xs={6} className="brand_wrapper">
                                  <PrimaryAvatar>
                                    <span>{item.email.charAt(0)}</span>
                                  </PrimaryAvatar>
                                  <div>
                                    <h5>{item.first_name}{" "}{item.last_name}</h5>
                                    <h6 style={{ color: "gray" }}>
                                      {item.email}
                                    </h6>
                                  </div>
                                </Col>
                                <Col xs={3}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <PrimarySwitch
                                          sx={{ m: 1 }}
                                          defaultChecked={item.is_active}
                                          onChange={async (_,val) => {
                                            if (!val){
                                              await deleteSingleAdminStaff(
                                                item.id
                                              );
                                              toast.error("disabled")
                                            }
                                            else {
                                              const data =
                                                await updateAdminStaff(
                                                  {
                                                    ...item,
                                                    is_active: true,
                                                    usertype: MODERATOR,
                                                  },
                                                  item.id
                                                );
                                              toast.success("enabled")
                                            }
                                          }}
                                        />
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs={3} className="edit_del_btn">
                                  <Button
                                    color="success"
                                    onClick={() => {
                                      toggle(
                                        "Edit Moderator",
                                        "MODERATOR",
                                        "EDIT",
                                        item.id
                                      );
                                    }}
                                  >
                                    <i className="bx bx-edit"></i>
                                  </Button>
                                </Col>
                              </Row>
                            </Card>
                          ))
                        ) : (
                          <div className="no_data_staff">
                            <img className="lg_img" src={empty} />
                            <GreyTypography variant="h6">
                              No Moderators
                            </GreyTypography>{" "}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div
                        className="email_brand_div"
                        style={{
                          marginBottom: "10px",
                          border: "none",
                          borderRadius: "4px",
                        }}
                      >
                        <div className="company_grid">
                          <div className="alert_small">
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems={"center"}
                            >
                              <PrimaryDivider
                                sx={{
                                  borderTopRightRadius: `5px`,
                                  borderBottomRightRadius: `5px`,
                                  height: "30px",
                                  paddingLeft: "4px",
                                }}
                                orientation="vertical"
                                flexItem
                              />
                              <PrimaryTypography component="h4" variant="h4">
                                Account Manger
                              </PrimaryTypography>
                            </Stack>
                          </div>{" "}
                          {/* <PrimaryButton
                            sx={{ width: "100px" }}
                            onClick={() =>
                              this.toggle(
                                "Add Account Manager",
                                "ACCOUNT_MANAGER",
                                "CREATE"
                              )
                            }
                            startIcon={<FeatherIcon icon="plus" size="14" />}
                          >
                            Add
                          </PrimaryButton> */}
                        </div>
                        </div>
                        <StageSearchInput
                          placeholder="search..."
                          fullWidth
                       
                          // onChange={this.handleSearchValue.bind(null,this.acmValue)}
                          onChange={(e) => handleSearchValue(acmValue, e)} 
                          value={searchText}
                          endAdornment={
                            <IconButton
                              edge="end"
                              className={state.acmSearchValue ? "d-inline":"d-none"}
                              onClick={clearSearchValue(acmValue)}
                            >
                              <FeatherIcon icon={"x"} size={18} />
                            </IconButton>
                          }
                        />
                      <div style={{height:"calc(100vh - 300px)",marginTop:"10px", overflowY:"scroll"}}>
                        {sortedACM.length > 0 ? (
                          sortedACM?.map((item, index) => (
                            <Card key={item?.id} style={{ marginBottom: "10px" }}>
                              <Row
                                className="email_brands email_brand_div"
                                key={index}
                              >
                                <Col xs={6} className="brand_wrapper">
                                  <PrimaryAvatar>
                                    <span>{item.email.charAt(0)}</span>
                                  </PrimaryAvatar>
                                  <div>
                                    <h5>{item.user_name}</h5>
                                    <h6 style={{ color: "gray" }}>
                                      {item.email}
                                    </h6>
                                  </div>
                                </Col>
                                <Col xs={3}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <PrimarySwitch
                                          sx={{ m: 1 }}
                                          defaultChecked={item.is_active}
                                          onChange={async (e,val) => {
                                         
                                            if (!val) {
                                              await deleteSingleAdminStaff(
                                                item.id
                                              );
                                              toast.error("disabled")
                                            }
                                            else {
                                              const data =
                                                await updateAdminStaff(
                                                  {
                                                    ...item,
                                                    is_active: true,
                                                    usertype: ACCOUNT_MANAGER,
                                                  },
                                                  item.id
                                                );
                                              toast.success("enabled")
                                            }
                                          }}
                                        />
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs={3} className="edit_del_btn">
                                  <Button
                                    color="success"
                                    onClick={() => {
                                      toggle(
                                        "Edit Account Manger",
                                        "ACCOUNT_MANAGER",
                                        "EDIT",
                                        item.id
                                      );
                                    }}
                                  >
                                    <i className="bx bx-edit"></i>
                                  </Button>
                                </Col>
                              </Row>
                            </Card>
                          ))
                        ) : (
                          <div className="no_data_staff">
                            <img className="lg_img" src={empty} />
                            <GreyTypography variant="h6">
                              No Account Managers
                            </GreyTypography>{" "}
                          </div>
                        )}
                      </div>
                    </Col>
          </>
        ) : (
          ""
        )}

        {state.role === 'CA' ? (
         <Col xs={12} md={12}>
         <div
           className="email_brand_div"
           style={{
             marginBottom: "10px",
             border: "none",
             borderRadius: "4px",
           }}
         >
           <div className="company_grid">
             <div className="alert_small">
               <Stack
                 direction="row"
                 spacing={1}
                 alignItems={"center"}
               >
                 <PrimaryDivider
                   sx={{
                     borderTopRightRadius: `5px`,
                     borderBottomRightRadius: `5px`,
                     height: "30px",
                     paddingLeft: "4px",
                   }}
                   orientation="vertical"
                   flexItem
                 />
                 <PrimaryTypography component="h4" variant="h4">
                   Staff
                 </PrimaryTypography>
               </Stack>
             </div>{" "}
             <PrimaryButton
               sx={{ width: "100px" }}
               onClick={() =>
                 toggle("Add Staff", "COMPANY_STAFF", "CREATE")
               }
               startIcon={<FeatherIcon icon="plus" size="14" />}
             >
               Add
             </PrimaryButton>
           </div>
           </div>
           <StageSearchInput
               placeholder="search..."
               fullWidth
        
              //  onChange={this.handleSearchValue.bind(null,this.csValue)}
              onChange={(e) => handleSearchValue(csValue, e)}
               value={searchText}
               endAdornment={
                 <IconButton
                   edge="end"
                   className={state.csSearchValue ? "d-inline":"d-none"}
                   onClick={() => clearSearchValue(csValue)}
                 >
                   <FeatherIcon icon={"x"} size={18} />
                 </IconButton>
               }
             />
         <div style={{height:"calc(100vh - 300px)",marginTop:"10px", overflowY:"scroll"}}>
           {sortedCStaff.length > 0 ? (
             sortedCStaff?.map((item, index) => (
               <Card key={item?.id} style={{ marginBottom: "10px" }}>
                 <Row
                   className="email_brands email_brand_div"
                   key={index}
                 >
                   <Col xs={6} className="brand_wrapper">
                     <PrimaryAvatar>
                       {item.email.charAt(0)}
                     </PrimaryAvatar>
                     <div>
                       <h5>
                         {item.first_name} {item.last_name}
                       </h5>
                       <h6 style={{ color: "gray" }}>
                         {item.email}
                       </h6>
                     </div>
                   </Col>
                   <Col xs={3}>
                     {" "}
                     <FormGroup>
                       <FormControlLabel
                         control={
                           <PrimarySwitch
                             sx={{ m: 1 }}
                             defaultChecked={item.is_active}
                             onChange={async (e,value) => {
                               await updateCompanyStaff(
                                 {
                                   email: item.email,
                                   first_name: item.first_name,
                                   last_name: item.last_name,
                                   is_active: value,
                                 },
                                 item.id
                               );
                               if (value)
                                 toast.success('enabled')
                               else
                                 toast.error('disabled')
                             }}
                           />
                         }
                       />
                     </FormGroup>
                   </Col>
                   <Col xs={3} className="edit_del_btn">
                     <Button
                       color="success"
                       onClick={() => {
                         toggle(
                           "Edit Staff",
                           "COMPANY_STAFF",
                           "EDIT",
                           item.id
                         );
                       }}
                     >
                       <i className="bx bx-edit"></i>
                     </Button>
                   </Col>
                 </Row>
               </Card>
             ))
           ) : (
             <div className="no_data_staff">
               <img className="lg_img" src={empty} />
               <GreyTypography variant="h6">
                 No Company Staff
               </GreyTypography>{" "}
             </div>
           )}{" "}
         </div>
       </Col>
        ) : (
          ''
        )}




      {state.role === 'RC' ? (
           <Col xs={12} md={12}>
           <div
             className="email_brand_div"
             style={{
               marginBottom: "10px",
               border: "none",
               borderRadius: "4px",
             }}
           >
             <div className="company_grid">
               <div className="alert_small">
               <Stack
                   direction="row"
                   spacing={1}
                   alignItems={"center"}
                 >
                   <PrimaryDivider
                     sx={{
                       borderTopRightRadius: `5px`,
                       borderBottomRightRadius: `5px`,
                       height: "30px",
                       paddingLeft: "4px",
                     }}
                     orientation="vertical"
                     flexItem
                   />
                   <PrimaryTypography component="h4" variant="h4">
                     Staff
                   </PrimaryTypography>
                 </Stack>
               </div>{" "}
               <PrimaryButton
                 sx={{ width: "100px" }}
                 onClick={() =>
                   toggle("Add Staff", "RECRUITMENT_COMPANY_STAFF", "CREATE")
                 }
                 startIcon={<FeatherIcon icon="plus" size="14" />}
               >
                 Add
               </PrimaryButton>
             </div>
             </div>
             <StageSearchInput
                 placeholder="search..."
                 fullWidth
           
                //  onChange={(rcValue)=>handleSearchValue(null,rcValue)}
                //  value={state.rcSearchValue}

                onChange={(e) => handleSearchValue(rcValue, e)} // Pass rcValue as type and e as event
                // value={state.rcSearchValue}
                value={searchText}

                 endAdornment={
                   <IconButton
                     edge="end"
                     className={state.rcSearchValue ? "d-inline":"d-none"}
                     onClick={() => clearSearchValue(rcValue)}
                   >
                     <FeatherIcon icon={"x"} size={18} />
                   </IconButton>
                 }
               />
           <div style={{height:"calc(100vh - 300px)",marginTop:"10px", overflowY:"scroll"}}>
             {sortedRCStaff.length > 0 ? (
               sortedRCStaff?.map((item, index) => (
                 <Card style={{ marginBottom: "10px" }}>
                   <Row
                     className="email_brands email_brand_div"
                     key={index}
                   >
                     <Col xs={6} className="brand_wrapper">
                       <PrimaryAvatar>
                         {item.email.charAt(0)}
                       </PrimaryAvatar>
                       <div>
                         <h5>{item.first_name}{" "}{item.last_name}</h5>
                         <h6 style={{ color: "gray" }}>
                           {item.email}
                         </h6>
                       </div>
                     </Col>
                     <Col xs={3}>
                       {" "}
                       <FormGroup>
                         <FormControlLabel
                           control={
                             <PrimarySwitch
                               sx={{ m: 1 }}
                               defaultChecked={item.is_active}
                               onChange={async (e,value) => {
                                 await updateRecrutementCompanyStaff(
                                   {
                                     email: item.email,
                                     first_name: item.first_name,
                                     last_name: item.last_name,
                                     is_active: value,
                                   },
                                   item.id
                                 );
                                 if (value)
                                 toast.success('enabled')
                               else
                                 toast.error('disabled')
                               }}
                             />
                           }
                         />
                       </FormGroup>
                     </Col>
                     <Col xs={3} className="edit_del_btn">
                       <Button
                         color="success"
                         onClick={() => {
                           toggle(
                             "Edit Staff",
                             "RECRUITMENT_COMPANY_STAFF",
                             "EDIT",
                             item.id
                           );
                         }}
                       >
                         <i className="bx bx-edit"></i>
                       </Button>
                     </Col>
                   </Row>
                 </Card>
               ))
             ) : (
               <div className="no_data_staff">
                 <img className="lg_img" src={empty} />
                 <GreyTypography variant="h6">
                   No Company Staff
                 </GreyTypography>{" "}
               </div>
             )}{" "}
           </div>
         </Col>
        ) : (
          ''
        )}



       {state.role === 'BVC' ? (
          <Col xs={12} md={12}>
          <div
            className="email_brand_div"
            style={{
              marginBottom: "10px",
              border: "none",
              borderRadius: "4px",
            }}
          >
            <div className="company_grid">
              <div className="alert_small">
              <Stack
                  direction="row"
                  spacing={1}
                  alignItems={"center"}
                >
                  <PrimaryDivider
                    sx={{
                      borderTopRightRadius: `5px`,
                      borderBottomRightRadius: `5px`,
                      height: "30px",
                      paddingLeft: "4px",
                    }}
                    orientation="vertical"
                    flexItem
                  />
                  <PrimaryTypography component="h4" variant="h4">
                    Staff
                  </PrimaryTypography>
                </Stack>
              </div>{" "}
              <PrimaryButton
                sx={{ width: "100px" }}
                onClick={() =>
                  toggle("Add Staff", "BACKGROUND_VERIFICATION_STAFF", "CREATE")
                }
                startIcon={<FeatherIcon icon="plus" size="14" />}
              >
                Add
              </PrimaryButton>
            </div>
            </div>
            <StageSearchInput
                placeholder="search..."
                fullWidth
               
                // onChange={this.handleSearchValue.bind(null,this.bvcValue)}
                onChange={(e) => handleSearchValue(bvcValue, e)} 
                value={searchText}
                endAdornment={
                  <IconButton
                    edge="end"
                    // className={state.bvcSearchValue ? "d-inline":"d-none"}
                    // onClick={clearSearchValue(bvcValue)}
                  >
                    {/* <FeatherIcon icon={"x"} size={18} /> */}
                  </IconButton>
                }
              />
          <div style={{height:"calc(100vh - 300px)",marginTop:"10px", overflowY:"scroll"}}>
            {sortedBGVStaff.length > 0 ? (
              sortedBGVStaff?.map((item, index) => (
                <Card style={{ marginBottom: "10px" }}>
                  <Row
                    className="email_brands email_brand_div"
                    key={index}
                  >
                    <Col xs={6} className="brand_wrapper">
                      <div className="pofile_banner">
                        <span style={{ textTransform: "uppercase" }}>
                          {item?.email?.charAt(0)}
                        </span>
                      </div>
                      <div>
                        <h5>{item?.first_name}{" "}{item?.last_name}</h5>
                        <h6 style={{ color: "gray" }}>
                          {item?.email}
                        </h6>
                      </div>
                    </Col>
                    <Col xs={3}>
                      {" "}
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <PrimarySwitch
                              sx={{ m: 1 }}
                              defaultChecked={item.is_active}
                              onChange={async (_,value) => {
                                await updateBackgroundVerificationCompanyStaff(
                                  {
                                    email: item?.email,
                                    first_name: item?.first_name,
                                    last_name: item?.last_name,
                                    is_active: value,
                                  },
                                  item?.id
                                );
                                if (value)
                                  toast.success('enabled')
                                else
                                  toast.error('disabled')
                              }}
                            />
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={3} className="edit_del_btn">
                      <Button
                        color="success"
                        onClick={() => {
                          toggle(
                            "Edit Staff",
                            "BACKGROUND_VERIFICATION_STAFF",
                            "EDIT",
                            item.id
                          );
                        }}
                      >
                        <i className="bx bx-edit"></i>
                      </Button>
                    </Col>
                  </Row>
                </Card>
              ))
            ) : (
              <div className="no_data_staff">
                <img className="lg_img" src={empty} />
                <GreyTypography variant="h6">
                  No Company Staff
                </GreyTypography>{" "}
              </div>
            )}{" "}
          </div>
        </Col>
        ) : (
          ''
        )} 



      {/* {this.state.role === 'CS' ? (
          <Col xs={12}>
          
          </Col>
        ) : (
          ''
        )} */}


      </Row>
    </Row>
    <CustomPagination count={totalCount} page={page} setPage={setPage} />
  </React.Fragment>
)}

        </>
    )
}