import { SCREENING_ID, START_SCREENING } from "./actionTypes";

const initState = {
  type: false,
  id: "",
  // timer:10000,
  timer:900000,
  intervalId:'',
  partnerDetails:{}
};
export default function reducer(state = initState, action) {
  switch (action.type) {
    case START_SCREENING:
      return {
        ...state,
        timer: state.timer - 1000
      };
    case "SET":
      return{
        ...state,
        timer:action.payload.value
      }
    case SCREENING_ID:
      return {
        ...state,
        id: action.payload?.id,
        type: action?.payload?.type,
        partnerDetails:action?.payload?.partnerDetails
      };
    default:
      return state;
  }
}
