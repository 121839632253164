import {
  Avatar,
  Box,
  FormControl,
  Grid,
  ImageList,
  ImageListItem,
  Stack,
} from "@mui/material";
import {
  LargeChips,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  PrimaryTypography,
  SecondaryFilledButton,
} from "assets/Mui/globalTheme";
import { imageURL } from "common/constants/commonURLS";
import { perks } from "common/constants/perks";
import UploadModel from "components/Common/models/uploadModel";
import UploadMultipleModel from "components/Common/models/uploadMultipleImages";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { getPreSignedURL } from "helpers/services/pre_signed_urls/upload";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
export default function ThirdFollowUps() {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const [perkState, setPerkState] = useState([]);
  const [modelOpen, setModelOpen] = useState(false);
  function handleUpload() {
    setModelOpen(!modelOpen);
  }
  const handleImageUpload = async data => {
    let urls = [];
    const totalUploadPromise = new Promise((resolve, reject) => {
      data?.forEach(async file => {
        const uploadPromise = new Promise(async (resolve, reject) => {
          const url = await getPreSignedURL([file]);
          resolve(url);
        });
        uploadPromise.then(imageUrls => {
          urls.push(imageUrls);
          if (data?.length === urls?.length) resolve(urls);
        });
      });
    });

    totalUploadPromise.then(urls => {
      const culture_media_images = watch("culture_media_images");
      setValue("culture_media_images", [...culture_media_images, ...urls]);
      handleUpload();
    });
  };
  useEffect(() => {
    setPerkState(perks);
  }, [perks]);

  function handleClick(idx) {
    const duplicate = perkState;
    const updating = perkState?.findIndex((_, index) => index === idx);
    duplicate[updating].selected = !duplicate[updating]?.selected;
    setPerkState(duplicate);
    const resultant = duplicate
      .filter(({ name, selected }) => selected === true)
      ?.map(item => item?.name);
    setValue("perks", resultant);
  }
  return (
    <Stack spacing={3}>
      <Grid container spacing={3}>
        {perkState.map(({ image, name, selected }, index) => {
          return (
            <Grid item xs={2}>
              <div
                style={{
                  transition: "0.4s ease-in-out",
                }}
                className={`perks_selection ${
                  watch("perks")?.includes(name) ? "selected_perk" : " "
                }`}
                onClick={() => {
                  handleClick(index);
                }}
              >
                <img src={image} className="small_img" />
                <p>{name}</p>
              </div>
            </Grid>
          );
        })}
      </Grid>
      <FormControl sx={{ width: "100%" }} variant="standard">
        <PrimaryNormalInputLabel shrink htmlFor="folloups-about-outlined">
          Describe your company culture
        </PrimaryNormalInputLabel>
        <PrimaryNormalInputs
          fullWidth
          placeholder="Enter your company culture"
          {...register("describe_your_culture", { required: true,minLength:"150",maxLength:"2000"})}
          id="login-culture-outlined"
          autoFocus
          label="culture address"
          type={"text"}
          multiline
          error={errors.describe_your_culture}
          variant="outlined"
          rows={4}
        />
        <PrimaryNormalFormHelperText error={errors.describe_your_culture}>
          Enter min 150 characters and max upto 2000
        </PrimaryNormalFormHelperText>
      </FormControl>
      <Stack>
        <PrimaryTypography variant="h5" component="h5">
          Culture Media
        </PrimaryTypography>
      </Stack>
      <SecondaryFilledButton
        onClick={handleUpload}
        startIcon={<FeatherIcon icon="plus" size="14" />}
        sx={{ width: "200px" }}
      >
        Add More Images
      </SecondaryFilledButton>
      <ImageList
        sx={{ width: 1200, 
          // height: 450 
        }}
        cols={6}
        rowHeight={100}
        spacing={3}
      >
        {watch("culture_media_images").map((item, index) => {
          return (
            <ImageListItem spacing={2} key={item}>
              <img
                style={{
                  borderRadius: "8px",
                }}
                src={`${imageURL}${item}?w=164&h=164&fit=crop&auto=format`}
                srcSet={`${imageURL}${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                loading="lazy"
              />
            </ImageListItem>
          );
        })}
      </ImageList>

      <UploadMultipleModel
        isOpen={modelOpen}
        toggle={handleUpload}
        handleImage={handleImageUpload}
      />
    </Stack>
  );
}
