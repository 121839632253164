import { DELETE, POST, PUT } from "common/constants/methods";
import callApi from "helpers/callApi";
import { toast } from "react-hot-toast";

export async function getAllFunctionalArea(
  page = 0,
  rowsPerPage = 20,
  searchText
) {
  const data = await callApi(
    `job_attributes/functional_area?pageSize=${rowsPerPage}&pageNo=${page}&searchParam=${searchText}`
  );
  // const items = data?.data_payload?.map(item => {
  //   return {
  //     id: item.id,
  //     name: item.name,
  //     default: true,
  //     country: item.country,
  //     is_active: item.is_active,
  //   };
  // });
  // return items;
  return data;
}

export async function deleteFunctionalArea(id) {
  if (id === undefined) return;

  const data = await callApi(
    `job_attributes/functional_area/${id}`,
    "",
    DELETE
  );
  return;
}
export async function createAndEditFunctionalArea(
  incomingData,
  type,
  index,
  id = ""
) {
  if (type === "Create") {
    const changeData = {
      name: incomingData?.name,
      is_active: incomingData?.active,
      country: "INDIA",
      image: incomingData?.image ?? "",
      skills: incomingData?.skills ?? "",
    };

    const data = await callApi(
      "job_attributes/functional_area",
      changeData,
      POST
    );
    // this.setState({
    //   data: [...this.state?.data, incomingData],
    // });
    return data;
  } else {
    const changeData = {
      name: incomingData.name,
      is_active: incomingData?.active,
      country: "INDIA",
      image: incomingData?.image ?? "",
      skills: incomingData?.skills ?? "",
    };

    const data = await callApi(
      `job_attributes/functional_area/${id}`,
      changeData,
      PUT
    );
    // let result = this.state.data;
    // // result[index] = { ...changeData, id };

    // // const filterOne = result?.map(each => {
    // //   if (each.id == incomingData.id) {
    // //     return {
    // //       ...each,
    // //       name: incomingData?.name,
    // //       is_active: incomingData?.is_active,
    // //     };
    // //   } else {
    // //     return {
    // //       ...each,
    // //     };
    // //   }
    // // });

    // // this.setState({
    // //   data: filterOne,
    // // });
    return data;
  }
}
export async function getSingleFunctionalArea(id) {
  if (id === undefined) return;

  const data = await callApi(`job_attributes/functional_area/${id}`);
  return data.data;
}
