import { FormControl, Grid, IconButton, Stack, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  BlackTypography,
  CustomSlider,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  ScreenQuestionsBox,
  ScoreCardHeader,
  PrimarySwitch,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useEffect, useState } from "react";
import { useFieldArray, useFormContext, Controller } from "react-hook-form";
import ScreenQuestions from "./ScreenQuestions";

export default function HireInDay({ jobsData }) {
  const {
    register,
    getValues,
    watch,
    setValue,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useFormContext();

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "question",
    }
  );

  const [referCommission, setReferCommission] = useState(true);

  useEffect(() => {
    const maxSal = watch("max_sal").replace(/,/g, ""); // Remove commas
    const referPercentage = Number(watch("refer_percentage"));

    if (maxSal && referPercentage) {
      const num1 = parseInt(maxSal);
      const num2 = referPercentage;
      const result = Math.round((num1 * num2) / 100);

      const referAmount = result;
      setValue("refer_amount", referAmount);
    }
  }, [watch("max_sal"), watch("refer_percentage")]);

  /*Scroll to top */
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  /*Scroll to top */

  const jobType = jobsData?.job_type.trim() === "Contract";

  // useEffect(() => {
  //   if (!referCommission) {
  //     setValue("refer_amount", "");
  //     setValue("refer_percentage", "");
  //   }
  // }, [referCommission]);



  return (
    <Stack spacing={2} sx={{ margin: "20px 0px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="question-outlined"
              error={errors.question}
            >
              Screening Questions
            </PrimaryNormalInputLabel>
            <Stack spacing={2} sx={{ marginTop: "20px" }}>
              {fields.map((field, index) => (
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems={"center"}
                  key={field.id}
                >
                  {/* <PrimaryNormalInputs
                    autoFocus
                    fullWidth
                    placeholder="Enter your question"
                    id="question-outlined"
                    multiline
                    label="question address"
                    type={"text"}
                    error={errors.question}
                    variant="outlined"
                    {...register(`question.${index}.value`, { required: true })}
                  /> */}

                  <ScreenQuestionsBox>
                    <ScoreCardHeader>
                      <Grid container alignItems="center">
                        <Grid xs={8} lg={10} sx={{ padding: "8px" }}>
                          <BlackTypography varient="h4">
                            Add Screening Questions
                          </BlackTypography>
                        </Grid>
                        <Grid xs={8} lg={2} sx={{ padding: "8px" }}>
                          <Stack
                            direction={"row"}
                            justifyContent={"end"}
                            alignItems={"end"}
                          >
                            <CloseIcon
                              onClick={() => {
                                if (index !== 0) remove(index);
                              }}
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    </ScoreCardHeader>

                    <Box sx={{ padding: "16px" }}>
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          paddingTop: "12px",
                          // height: "200px",
                          overflowY: "scroll",
                          "&::-webkit-scrollbar": {
                            display: "none", // Hide the scrollbar for WebKit browsers (e.g., Chrome, Safari)
                          },
                          scrollbarWidth: "none",
                        }}
                      >
                        <PrimaryNormalInputs
                          autoFocus
                          fullWidth
                          placeholder="Enter your question"
                          id="question-outlined"
                          multiline
                          label="question address"
                          type={"text"}
                          error={errors.question}
                          variant="outlined"
                          {...register(`question.${index}.value`, {
                            required: false,
                            pattern: /\S/,
                          })}
                        />
                      </Grid>
                    </Box>
                  </ScreenQuestionsBox>

                  <IconButton onClick={() => append()}>
                    <FeatherIcon icon="plus" size="14" />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      if (index !== 0) remove(index);
                    }}
                  >
                    <FeatherIcon icon="minus" size="14" />
                  </IconButton>
                </Stack>
              ))}
            </Stack>

            <PrimaryNormalFormHelperText error={errors.question}>
              {errors?.question ? "Screening question is a required field" : ""}
            </PrimaryNormalFormHelperText>
          </FormControl>
        </Grid>

        {!jobType && (
          <>
            <Grid item xs={12}>
              <BlackTypography
                sx={{ fontWeight: "bold" }}
                variant="h3"
                component="h3"
              >
                {/* {referCommission ? "Referral Commission" : "Flat Commission"} */}
                Referral Commission{" "}
                {/* <PrimarySwitch
                  {...register("refer_commission", {
                    required: false,
                  })}
                  checked={referCommission}
                  onChange={() => {
                    // Toggle referCommission state
                    setReferCommission(prevState => !prevState);

                    // Update refer_commission based on referCommission state
                    setValue("refer_commission", !referCommission);
                  }}
                /> */}
              </BlackTypography>
            </Grid>

            <Grid item xs={12} md={3}>
              {/* <PrimaryNormalInputs
            type="number"
            placeholder="refer amount"
            error={errors?.refer_amount}
            step=".01"
            {...register("refer_amount", { required: true })}
            value={watch("refer_amount") || ''}
            onChange={e => {
              let value = e.target.value;
              setValue("refer_amount", value);
              // Calculate and update the refer percentage
              // const referPercentage = (Number(value) / (Number(watch("max_sal")) || Number(getValues('max_sal')))) * 100;
              const referPercentage = (Number(value) / (Number(watch('max_sal')) || Number(getValues('max_sal')))) * 100
              setValue("refer_percentage", referPercentage || '');
            }}
            aria-aria-label="refer-amount"
          ></PrimaryNormalInputs> */}

              <PrimaryNormalInputs
                step=".01"
                error={errors?.refer_percentage}
                type="number"
                placeholder="refer amount"
                {...register("refer_amount", {
                  required: true,
                })}
                value={watch("refer_amount") || ""}
                onChange={e => {
                  let value = e.target.value;
                  setValue("refer_amount", value);
                  // Calculate and update the refer amount
                  const maxSal = watch("max_sal").replace(/,/g, "");
                  const referalAmount = value;

                  if (maxSal && referalAmount && maxSal !== 0) {
                    const referAmount = Number(referalAmount * 100) / maxSal;
                    setValue("refer_percentage", referAmount);
                  } else {
                    setValue("refer_percentage", "");
                    // console.log("Invalid input or maxSal is zero");
                  }
                  // setValue("refer_percentage", 5);
                }}
                aria-aria-label="refer-amount"
              ></PrimaryNormalInputs>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack spacing={2}>
                <Stack direction="row" spacing={2} alignItems={"center"}>
                  {/* <PrimaryNormalInputs
                    step=".01"
                    error={errors?.refer_percentage}
                    type="number"
                    placeholder="refer percentage"
                    {...register("refer_percentage", {
                      required: true,
                      min: 8.3,
                      max: 100,
                    })}
                    value={watch("refer_percentage") || ""}
                    onChange={e => {
                      let value = e.target.value;
                      setValue("refer_percentage", value);
                      // Calculate and update the refer amount
                      const referAmount =
                        (Number(value) *
                          (Number(watch("max_sal")) ||
                            Number(getValues("max_sal")))) /
                          100 || "";
                      setValue("refer_amount", referAmount);
                    }}
                    aria-aria-label="refer-percentage"
                  ></PrimaryNormalInputs> */}

                  {/* <PrimaryNormalInputs
                      step=".01"
                      error={errors?.refer_percentage}
                      type="number"
                      placeholder="refer percentage"
                      {...register("refer_percentage", {
                        required: true, // Make it required only if referCommission is false
                        max: 100,
                        // ...(referCommission ? { min: 8.3 } : {}), // Apply min validation if referCommission is true
                      })}
                      value={watch("refer_percentage") || ""}
                      onChange={e => {
                        let value = e.target.value;
                        setValue("refer_percentage", value);
                        // Calculate and update the refer amount
                        if (!referCommission) {
                          // No need to validate if referCommission is false
                          const referAmount =
                            (Number(value) *
                              (Number(watch("max_sal")) ||
                                Number(getValues("max_sal")))) /
                              100 || "";
                          setValue("refer_amount", referAmount);
                        }
                      }}
                      aria-aria-label="refer-percentage"
                    ></PrimaryNormalInputs> */}

                  {/* <PrimaryNormalInputs
                    step=".01"
                    error={errors?.refer_percentage}
                    type="number"
                    placeholder="refer percentage"
                    {...register("refer_percentage", {
                      required: true, // Make it required
                      // ...(referCommission ? { min: 8.3 } : null),
                      // min: referCommission ? 8.3 : 0.1,
                      min:8.3,
                      max: 100,
                    })}
                    value={watch("refer_percentage") || ""}
                    onChange={e => {
                      let value = e.target.value;
                      setValue("refer_percentage", value);
                      // Calculate and update the refer amount
                      if (!referCommission) {
                        // No need to validate if referCommission is false
                        const referAmount =
                          (Number(value) *
                            (Number(watch("max_sal")) ||
                              Number(getValues("max_sal")))) /
                            100 || "";
                        setValue("refer_amount", referAmount);
                      }
                    }}
                    aria-aria-label="refer-percentage"
                  ></PrimaryNormalInputs> */}


                  <PrimaryNormalInputs
                    step=".01"
                    error={errors?.refer_percentage}
                    type="number"
                    placeholder="refer percentage"
                    {...register("refer_percentage", {
                      required: true, // Make it required
                      // min: 8.3,
                      // min: referCommission ? 8.3 : 0.1,
                      min: {
                        value: 8,
                        message: "Refer percentage must be more than or equal to 8",
                      },
                      max: {
                        value: 100,
                        message: "Refer percentage must be less than or equal to 100",
                      },
                    })}
                    value={watch("refer_percentage") || ""}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (value > 100) {
                        // setError('refer_percentage',"Limit exceeded")
                        setError('refer_percentage', {
                          type: "manual",
                          message: `Percentage must be less than or equal to 100`,
                        });
                      } else if (value < 5) {
                        // setError('refer_percentage',"Limit exceeded")
                        setError('refer_percentage', {
                          type: "manual",
                          message: `Percentage must be more than or equal to 5`,
                        });
                      }
                      else {
                        clearErrors('refer_percentage')
                      }
                      setValue("refer_percentage", value);
                      // Calculate and update the refer amount
                      if (!referCommission) {
                        // No need to validate if referCommission is false
                        const referAmount =
                          (Number(value) *
                            (Number(watch("max_sal")) || Number(getValues("max_sal")))) /
                          100 || "";
                        setValue("refer_amount", referAmount);
                      }
                    }}
                    aria-aria-label="refer-percentage"
                  ></PrimaryNormalInputs>



                  <div
                    style={{
                      background: "rgba(0,0,0,0.1)",
                      borderRadius: "8px",
                      display: "grid",
                      placeItems: "center",
                      width: "48px",
                      height: "48px",
                    }}
                  >
                    %
                  </div>
                  <BlackTypography variant="h5" component="h5">
                    of Annual Salary
                  </BlackTypography>
                </Stack>
                <PrimaryNormalFormHelperText error={errors?.refer_percentage}>
                  {/* {errors?.refer_percentage
                    ? "refer percentage should be greater than 8.3"
                    : ""} */}
                  {errors?.refer_percentage?.message
                    ? errors?.refer_percentage?.message
                    : errors?.refer_percentage ? "Refer percentage should be greater than 8" : ""}

                </PrimaryNormalFormHelperText>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomSlider
                step={0.01}
                // {...register("refer_percentage", {
                //   required: true,
                //   min: 8.3,
                //   max: 100,
                // })}

                {...register("refer_percentage", {
                  required: true, // Make it required only if referCommission is false
                  // min: referCommission ? 8.3 : 0.1,
                  // min:8.3,
                  min: 8,
                  max: 100,
                  // ...(referCommission ? { min: 8.3 } : {}), // Apply min validation if referCommission is true
                })}
                value={watch("refer_percentage")}
                // onChange={(e, value) => {
                //   setValue("refer_percentage", value);
                // }}

                onChange={e => {
                  let value = e.target.value;
                  setValue("refer_percentage", value);
                  // Calculate and update the refer amount
                  if (!referCommission) {
                    // No need to validate if referCommission is false
                    const referAmount =
                      (Number(value) *
                        (Number(watch("max_sal")) ||
                          Number(getValues("max_sal")))) /
                      100 || "";
                    setValue("refer_amount", referAmount);
                  }
                }}
                defaultValue={8}
                //   min={8.8}
                max={100}
                thumb__hover_color={`${errors?.refer_percentage
                    ? "rgba(255,0,0,0.2)"
                    : "rgba(79,195,247,0.5)"
                  }`}
                backgrounds={`${errors?.refer_percentage ? "red" : "#4FC3F7"}`}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Stack>
  );
}
