import { Box, Modal, Typography, Button, Backdrop, Stack } from "@mui/material";
import {
  PrimaryButton,
  PrimaryTy,
  PrimaryTypography,
  PrimaryTypographypography,
} from "assets/Mui/globalTheme";
import React from "react";
const CloseJobModel = React.memo(function ChilComponent({
  open,
  handleClose,
  onSubmit,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    borderRadius: "10px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <React.Fragment>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Stack spacing={4}>
            <PrimaryTypography
              id="keep-mounted-modal-title"
              variant="h2"
              component="h2"
              sx={[
                { mt: 2, fontWeight: "bolder" },
                theme => ({
                  color: theme.palette.error.main,
                }),
              ]}
            >
              Are you sure to close this Job?
            </PrimaryTypography>
            <Typography
              variant="body2"
              component="span"
              id="keep-mounted-modal-description"
              sx={[
                { mt: 2 },
                theme => ({
                  // color: theme.palette.error.main,
                }),
              ]}
            >
              This action cannot be undone.
              <br /> If you delete this job, all the data related to this job
              will be deleted.{" "}
            </Typography>
            <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
              <Button color="primary" variant="text" onClick={handleClose}>
                cancel
              </Button>
              <PrimaryButton
                sx={{ width: "100px" }}
                onClick={() => {
                  handleClose();
                  onSubmit();
                }}
              >
                Close
              </PrimaryButton>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </React.Fragment>
  );
});

export default CloseJobModel;
