import callApi from "helpers/callApi";
const getId = async () => {
  return await JSON.parse(localStorage.getItem("authUser"))?.id;
};
export const updateCommonPhnUser = async data => {
  const phnNumData =  {phone_number:data.number}
  return await callApi(`/common_user_update/${await getId()}`, phnNumData, "PUT");
  // return await callApi(`/common/changepassword`, data, "PATCH");
};

export const updateCommonUser = async data => {
  return await callApi(`/common_user_update/${await getId()}`, data, "PUT");
  // return await callApi(`/common/changepassword`, data, "PATCH");
};

export const updateCommonUserPassword = async data => {
  // return await callApi(`/common_user_update/${await getId()}`, data, "PUT");
  return await callApi(`/common/changepassword`, data, "PATCH");
};

export const updateCommonUserWithId = async (id, data) => {
  return await callApi(`/common_user_update/` + id, data, "PUT");
};
export const getCommonUser = async id => {
  return await callApi(`/common_user_update/user/${id}`, {});
};

export const deleteImage = async(image)=> {

const payload = {
  "culture_media_images":image
}
  return await callApi(`/common_user_update/${await getId()}`,payload,"PUT")
}
