import { useState, useEffect } from "react";
import EvaluationReport from "./Report";
import UserJobPostScoreEvalutions from "./UserJobPostScoreEvalutions";
import { getScreeningQuestions, getSkillRecords,getProfileRating } from "helpers/services/scorecard";
import SearchParamHook from "hooks/searchParams";
import dataNotFound from "../../../../assets/images/noDataGreen.png";
import { PrimaryTypography } from "assets/Mui/globalTheme";
import { CircularProgress, Rating } from "@mui/material";
import ReportTable from "./reportTable";
import { useSelector } from "react-redux";
import { MODERATOR, SP, TE } from "common/constants/userModules";
import {
  getScreeningSkills,
  getModeratorSkills,
  getTechnicalSkills,
} from "helpers/services/scorecard";
import { getSingleJobDetails } from "helpers/services/jobs/jobs";
import { makeStyles } from "@mui/styles";
import ProfileRatingGraph from "./ProfileRatingGraph";
import EvaluationCards from "./EvaluationCards";
import SkillQuestionCards from "./SkillQuestionsCards";
import ModeratorSkillCards from "./ModeratorSkillCards";
import TechnicalSkillCards from "./TechnicalSkillCards";

const useStyles = makeStyles({
  table: {
    width: "100%",
    borderCollapse: "collapse",
    border: "1px solid",
    "& td,& th": {
      border: "1px solid",
      padding: "4px",
    },
  },
});

export default function ScoreCard() {

  const [viewPrint, setViewPrint] = useState(false);
  const [skillRecord, setSkillRecord] = useState({});
  const [jobPostInfo, setJobPostInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const search = SearchParamHook();
  const userJobPostId = search.get("user_job_post_id");
  const jobPostId = search.get("job_id");
  const type = search.get("type")
  const evalDetails = JSON.parse(localStorage.getItem("evaluation_info"));

  const [screeningSkills, setScreeningSkills] = useState([]);
  const [moderatorSkills, setModeratorSkills] = useState([]);
  const [evaluatorSkills, setEvaluatorSkills] = useState([]);

  const [profileRating, setProfileRating] = useState([])

  const [pdfSpSkills,setPdfSpSkills] = useState([])
  const [pdfSpQuestions,setPdfSpQuestions] = useState([])

  const [pdfTeSkills,setPdfTeSkills] = useState([])
  const [pdfTeQuestions,setPdfTeQuestions] = useState([])

  const [pdfModSkills,setPdfModSkills] = useState([])
  const [pdfModQuestions,setPdfModQuestions] = useState([])

  const [details,setDetails] = useState({type:"skill",userType:""})
  const [skillsType,setSkillsType] = useState('skill')
  const [sliderLoading,setSliderLoading] = useState(false)
  
  const [spLoading,setSpLoading] = useState(false)
  const [spSkillType,setSpSkillType] = useState('skill')

  const [mdLoading,setMdLoading] = useState(false)
  const [modSkillsType,setModSkillsType] = useState('skill')

  const [teLoading,setTeLoading] = useState(false)
  const [teSkillsType, setTeSkillsType] = useState('skill')

  const classes = useStyles();
  useEffect(() => {
    (async function () {
      setLoading(true);
      // const res = await getSkillRecords(userJobPostId);
      const res = await getSkillRecords(userJobPostId,details.type);
    
      const { data } = res;

      const profileRatingDetails = await getProfileRating(userJobPostId)

      setProfileRating(profileRatingDetails?.data?.data_payload)

      setSkillRecord(data?.data_payload);
      screening(data?.data_payload?.screening_partner_evalutation?.sp_details?.id || null);
      technical(data?.data_payload?.technical_evalutation?.evaluator_details?.id || null);
      moderator(data?.data_payload?.moderator_evalutation?.moderator_details?.id || null);
      setTimeout(() => setLoading(false), 1000);
    })();
  }, []);


  useEffect(() => {
    (async function () {
      setSliderLoading(true);
      // const res = await getSkillRecords(userJobPostId);
      const res = await getSkillRecords(userJobPostId,skillsType);
      const { data } = res;
      setSkillRecord(data?.data_payload);
   
      setTimeout(() => setSliderLoading(false), 1000);
    })();
  }, [skillsType]);



  useEffect(()=>{
    (async function () {
      setSpLoading(true);
      const res = await getSkillRecords(userJobPostId,spSkillType);
      const { data } = res;

      // setSkillRecord(data);

      screening(data?.data_payload?.screening_partner_evalutation?.sp_details?.id || null,spSkillType);
      setTimeout(() => setSpLoading(false), 1000);
    })();
    
  },[spSkillType])

  useEffect(()=>{
    (async function () {
      setMdLoading(true);
      const res = await getSkillRecords(userJobPostId,modSkillsType);
      const { data } = res;
    
      setSkillRecord(data?.data_payload);
        

      moderator(data?.data_payload?.moderator_evalutation?.moderator_details?.id || null, modSkillsType);
      setTimeout(() => setMdLoading(false), 1000);
    })();
    
  },[modSkillsType])

  useEffect(()=>{
    (async function () {
      setTeLoading(true);
      const res = await getSkillRecords(userJobPostId,teSkillsType);
      const { data } = res;
   
      setSkillRecord(data?.data_payload);
      technical(data?.data_payload?.technical_evalutation?.evaluator_details?.id || null,teSkillsType);
      setTimeout(() => setTeLoading(false), 1000);
    })();
    
  },[teSkillsType])




  useEffect(() => {
    (async function () {
      const res = await getSingleJobDetails(jobPostId);
      const { data } = res;
      setJobPostInfo(data?.data_payload);
    
    })();
  }, []);

  async function screening(id,spSkillType) {
    const obj = {
      userJobPostId,
      evalId: id,
      evType: SP,
    };
  
    if (Boolean(id) || spSkillType !== "") {
      if(spSkillType == "skill"){
      const res = await getScreeningSkills(obj);
      const queRes = await getScreeningQuestions(obj);
      const { data } = res;
      setScreeningSkills(data?.data_payload);
      setPdfSpSkills(data?.data_payload)
      setPdfSpQuestions(queRes?.data?.data_payload);
      

      
      }else if(spSkillType == "question") {
        const res = await getScreeningQuestions(obj);
        const skillRes = await getScreeningSkills(obj);
      const { data } = res;
      setScreeningSkills(data?.data_payload);
      setPdfSpQuestions(data?.data_payload);
      // setPdfSpSkills(skillRes?.data?.rows)
      setPdfSpSkills(skillRes?.data?.data_payload)
    
      }
      
    } else {
      setScreeningSkills([]);
    }
  }


  async function technical(id,teSkillsType) {
    const obj = {
      userJobPostId,
      evalId: id,
      evType: TE,
    };
    if (Boolean(id) || teSkillsType !== "") {

      if(teSkillsType == "skill"){
        const res = await getTechnicalSkills(obj);
        const queRes = await getScreeningQuestions(obj);
        const { data } = res;
        setEvaluatorSkills(data?.data_payload);
        setPdfTeSkills(data?.data_payload)
        setPdfTeQuestions(queRes.data?.data_payload);
        }else if(teSkillsType == "question") {
          const res = await getScreeningQuestions(obj);
          const SkillRes = await getTechnicalSkills(obj);
        const { data } = res;
        setEvaluatorSkills(data?.data_payload);

        setPdfTeSkills(SkillRes?.data?.data_payload)
        setPdfTeQuestions(data?.data_payload);
        }

      // const res = await getTechnicalSkills(obj);
      // const { data } = res;
      // setEvaluatorSkills(data.rows);
    } else {
      setEvaluatorSkills([]);
    }
  }

  async function moderator(id,modSkillsType) {
    const obj = {
      userJobPostId,
      evalId: id,
      evType: MODERATOR,
    };

    if (Boolean(id) || modSkillsType !== "") {
      if(modSkillsType == "skill"){
         const res = await getModeratorSkills(obj);
        const queRes = await getScreeningQuestions(obj);
        const { data } = res;

        setModeratorSkills(data?.data_payload);
        setPdfModSkills(data?.data_payload)
        setPdfModQuestions(queRes.data?.data_payload);
        }else if(modSkillsType == "question"){
          const res = await getScreeningQuestions(obj);
          const modRes = await getModeratorSkills(obj);
        const { data } = res;
        setModeratorSkills(data?.data_payload);

        setPdfModSkills(modRes?.data?.data_payload)
        setPdfModQuestions(data?.data_payload);

        }

      // const res = await getModeratorSkills(obj,modSkillsType);
      // const { data } = res;
      // setModeratorSkills(data.rows);

    } else {
      setModeratorSkills([]);
    }
  }

  function getAllFieldValues(obj) {
    const values = [];

    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        // Recursively call the function for nested objects
        const nestedValues = getAllFieldValues(obj[key]);
        values.push(...nestedValues);
      } else {
        // Add the value to the array
        values.push(obj[key]);
      }
    }

    return values;
  }

  const fieldValues = [...getAllFieldValues(skillRecord)].reduce(
    (pre, curr) => pre + curr,
    0
  );


  return (
    <div>
      {loading ? (
        <div style={{ display: "grid", placeItems: "center" }}>
          <CircularProgress />
        </div>
      ) : fieldValues ? (
        <>
          
          <>
          
          </> 

          <ProfileRatingGraph 
            openPrintView={() => setViewPrint(true)}
            scoreData={skillRecord}
            screeningSkills={pdfSpSkills}
            moderatorSkills={pdfModSkills}
            evaluatorSkills={pdfTeSkills}

            screeningQues={pdfSpQuestions}
            moderatorQues={pdfModQuestions}
            evaluatorQues={pdfTeQuestions}

            jobPostInfo={jobPostInfo}

            profileRating={profileRating}
          />
          {sliderLoading?<CircularProgress />:
          <EvaluationCards
            openPrintView={() => setViewPrint(true)}
            scoreData={skillRecord}
          
            // jobPostInfo={jobPostInfo}
            setDetails={setDetails}
            setSkillsType={setSkillsType}
            skillsType={skillsType}
          />
        }
          {spLoading?<CircularProgress />:<SkillQuestionCards
            skills={screeningSkills}
            evType={SP}
            jobPostInfo={jobPostInfo}
            heading={"Screening"}
            setDetails={setDetails}
            setSpSkillType={setSpSkillType}
            spSkillType={spSkillType}
          />}

        {teLoading?<CircularProgress />:
          <TechnicalSkillCards
           skills={evaluatorSkills}
           evType={TE}
           jobPostInfo={jobPostInfo}
           heading={"Technical"}
           setDetails={setDetails}
           setTeSkillsType={setTeSkillsType}
           teSkillsType={teSkillsType}
          />
        }
       
            {mdLoading?<CircularProgress />:
           <ModeratorSkillCards
            skills={moderatorSkills}
            evType={MODERATOR}
            jobPostInfo={jobPostInfo}
            heading={"Moderator"}
            setDetails={setDetails}
            setModSkillsType={setModSkillsType}
            modSkillsType={modSkillsType}
          />
          }

        

       
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <PrimaryTypography textAlign={"left"} className="mb-4" variant="h2">
            Evaluation Report
          </PrimaryTypography>
          <img
            style={{
              width: "350px",
              height: "350px",
              marginTop: 0,
            }}
            src={dataNotFound}
            alt="/not-found"
          />
        </div>
      )}
      {/* <UserJobPostScoreEvalutions /> */}
    </div>
  );
}
