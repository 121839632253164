import { useState } from "react";
import { useForm } from "react-hook-form";
import { makeStyles } from '@mui/styles';
import { Modal,Box,Stack,IconButton,TextField, Input, Rating,Tooltip } from "@mui/material";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

import {
    BlackTypography,
    GreyTypography,
    PrimaryButton,
    PrimaryNormalInputs,
    PrimaryTypography,
    SuccessTextButton,
  } from "assets/Mui/globalTheme";
const useStyles = makeStyles({
    label:{
        "& label":{
          marginBottom:"0px !important"
        },
  }
  })

  const feedbacks=[
    "Poor",
    "Not Good",
    "Below Average",
    "Average",
    "Good",
    "Excellent"
 ]
 
 const  popperProps = {
   modifiers: [
     {
       name: 'offset',
       options: {
         offset: [1,-10], // Adjust the values to reduce the distance
       },
     },
   ],
 };

export default function ActionModal({open,readOnly,handleClose,question,updateQuestionHandler}){
    const {
      register,
      setValue,
      setError,
      handleSubmit,
      clearErrors,
      getValues,
      watch,
      formState: { errors },
    } = useForm({
      defaultValues: {
        readComment: question?.comment|| "",
        commentReadOnly:question?.comment || "",
        answer:question?.answer || ""
      },
    });
    const [comments, setcomments] = useState(false);
    const [rating, setRating] = useState({
      value: question?.rating || 0,
      hover: 0,
    });
    const onSubmit = (data) => {
      const { readComment,answer } = data;
      setValue('commentReadOnly',readComment)
      const {questionName,evaluateId} = question
      if (Object.values(data) === null || Object.values(data) === "") return;
      const reqObj={
        answer,
        questionName,
        comment:readComment,
        rating:rating.value,
        evaluateId
      }

      updateQuestionHandler(reqObj)
      setcomments(false);
    };
    function handleStars(e,val){
      const comment = getValues("commentReadOnly") || question?.comment
      if(!Boolean(comment)){
        setError('commentReadOnly')
     
        return;
      }
      setRating((pre)=>({
        ...pre,
        value:val
      }))
      // const {answer,questionName,evaluateId} = question
      // const reqObj={
      //   answer,
      //   questionName,
      //   comment,
      //   rating:val,
      //   evaluateId
      // }
      // updateQuestionHandler(reqObj)
    }
    const classes = useStyles()
    const editText = watch('readComment') || ""

    const isValidInput = (value) => /^[A-Za-z\s]+$/.test(value);

    const isValidComment = (value) => {
      // Check if the value contains only letters (both uppercase and lowercase)
      return /^[a-zA-Z]+$/.test(value);
    };
    


    return (
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "white",
            boxShadow: 24,
            borderRadius:2,
            p: 4,
          }}
        >
          <Stack spacing={2}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} >
            <BlackTypography
              variant="h3"
            >
              {question?.questionName}
            </BlackTypography>
            <IconButton onClick={handleClose}>
              <FeatherIcon icon={'x-circle'} size={14} />
            </IconButton>
            </Stack>
            <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              sx={{ width: "100%",mb:2 }}
              label="Answer"
              {...register("answer", { required: true,
                // pattern: /\S/,
                // validate: (value) => isValidInput(value) || 'Please enter a valid answer.',
               })}
              defaultValue={question?.answer}
              placeholder="update"
              multiline
              error={errors.answer}
              rows={2.5}
            />
              {!comments ? (
                <TextField
                  {...register("commentReadOnly", { required: true,
                    // pattern: /\S/,
                    // validate: (value) => isValidInput(value) || 'Please enter a valid comment',
                  })}
                  InputProps={{
                    style: {
                      padding: "8px",
                    },
                  }}
                  value={getValues("readComment")}
                  // defaultValue={skill?.comment || ""}
                  placeholder="Enter your comments here"
                  label="Comments"
                  error={errors.commentReadOnly}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!readOnly) {
                      setcomments(true);
                      setValue(
                        "readComment",
                        getValues("commentReadOnly") || question?.comment
                      );
                    }
                  }}
                  multiline
                  rows={2.5}
                  readOnly={readOnly}
                />
              ) : (
                <Stack direction="row" spacing={"1"}>
                  <Input
                    fullWidth
                    placeholder={"Enter Comment"}
                    {...register("readComment", { required: true,
                      // pattern: /\S/,
                    
                        // validate: (value) => isValidComment(value) || 'Please enter a valid comment without spaces',
                     
                    })}
                    id="login-first-name-outlined"
                    autoFocus
                    error={errors.readComment}
                    helperText={errors.readComment && "Required"}
                    variant="outlined"
                    // value={getValues("readComment")}
                    // defaultValue={question?.comment ||""}
                    defaultValue={question?.comment}
                  />
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation()
                      clearErrors("readComment");
                      setcomments(false);
                    }}
                    color="error"
                  >
                    <FeatherIcon icon="x" size="14" />
                  </IconButton>
                  <IconButton 
                    onClick={()=>{
                     
                      setValue('commentReadOnly',editText);
                      if(!getValues("commentReadOnly")){
                        setError('commentReadOnly')
                      }
                      else if(Boolean(editText)){
                        setValue('commentReadOnly',editText);
                        clearErrors('commentReadOnly')
                      }else{
                        setError('commentReadOnly')
                      }
                      setcomments(false);
                    }}
                    color="success">
                    <FeatherIcon icon="check" size="14" />
                  </IconButton>
                </Stack>
              )}
              <Tooltip 
                sx={{alignSelf:'flex-start',mt:'16px'}}
                PopperProps={popperProps}
                title={feedbacks[rating.hover] || ""}
                placement="right-start"
              >
                <Rating
                  className={classes.label}
                  onChange={handleStars}
                  onChangeActive={(_, hover) =>
                    setRating(pre => ({
                      ...pre,
                      hover,
                    }))
                  }
                  getLabelText={value => {
                    return `${value} Star${value !== 1 ? "s" : ""}, ${
                      feedbacks[value]
                    }`;
                  }}
                  // precision={0.5}
                  value={rating.value}
                  size="medium"
                  readOnly={readOnly}
                  onClick={(e)=>e.stopPropagation()}
                />
              </Tooltip>
              <PrimaryButton sx={{marginBlockStart:"12px"}} type="submit">Submit</PrimaryButton>
            </form>
            {/* <Tooltip 
            sx={{alignSelf:'flex-start'}}
              PopperProps={popperProps}
              title={feedbacks[rating.hover] || ""}
            >
              <Rating
                className={classes.label}
                onChange={handleStars}
                onChangeActive={(_, hover) =>
                  setRating(pre => ({
                    ...pre,
                    hover,
                  }))
                }
                getLabelText={value => {
                  return `${value} Star${value !== 1 ? "s" : ""}, ${
                    feedbacks[value]
                  }`;
                }}
                // precision={0.5}
                value={rating.value}
                size="medium"
                readOnly={readOnly}
                onClick={(e)=>e.stopPropagation()}
              />
            </Tooltip> */}
          </Stack>
        </Box>
      </Modal>
    );
  }