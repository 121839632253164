import * as React from "react";
import { useLocation } from "react-router-dom";
export default function SearchParamHook() {
  const { search } = useLocation();
  const searchParams = React.useMemo(() => {
    const data = new URLSearchParams(search);

    return data;
  }, [search]);
  return searchParams;
}
