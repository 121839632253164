import * as React from "react";
import {useState, useEffect} from 'react';
import { GridToolbar } from "@mui/x-data-grid";
import { getAllReferalParterns } from "helpers/services/referalPartner";
import { imageURL } from "common/constants/commonURLS";
import {
  HoldChip,
  PrimaryButton,
  PrimaryButtonForTable,
  PrimaryChip,
  PrimaryDataGrid,
  PrimarySwitch,
  SmallPrimaryAvatar,
  SmallSizeChip,
  DownloadButton,
  CompanyTypography,
  StyledOutlinedInput,
  CompanySizeChip,
  ApproveChip,
  SkillsChip
} from "assets/Mui/globalTheme";
import InputAdornment from "@mui/material/InputAdornment";
import JobSearchIcon from "assets/images/icons/JobSearchIcon";
import AddIcon from '@mui/icons-material/Add';
import { Pagination } from "@mui/material";
import TablePopper from "components/table/header/popper";
import LoaderIcon from "utils/icons/loaderIcon";
import { GenderOperators } from "components/table/operators/genderOperators";
import { LocationOperators } from "components/table/operators/locationOperators";
import { KeySkillOperator } from "components/table/operators/keySkillOperator";
import { getAllAdminStaffListWithStatus } from "helpers/services/adminStaff/adminStaff";
import { useHistory, useLocation } from "react-router-dom";
import { CA, CS, MODERATOR, ZA } from "common/constants/userModules";
import { IndustryOperators } from "components/table/operators/industryOperators";
import AdminStaffModel from "./addStaff";
import CustomNoRowsOverlay from "common/data/NoRowsFoundDatagrid/NoRowsFound";
import SkillViewModal from "helpers/Modals/SkillViewModal";
import DownloadIcon from "@mui/icons-material/Download";

import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { StageSearchInput } from "assets/Mui/globalTheme";
import { useDebounce } from "hooks/useDebounce";
import SearchParamHook from "hooks/searchParams";

import downloadCsv from 'download-csv';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import { parseNumberInput } from "hooks/customPhoneNumberParse";
import { socket } from "Sockets/socket";
import GlobalTableTools from "hooks/GlobalTableTools";


export default function AdminStaffTable() {
  const [openSearch, setOpenSearch] = useState(true)

  const [accountManagers, setAccountManagers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const loginStore = JSON.parse(localStorage.getItem("authUser"));
  const role = loginStore?.role;
  const searchParamsHook = SearchParamHook()

  const [totalRecords, setTotalRecords] = React.useState('')

  const [viewAddModal, setViewAddModal] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [openSkillModal, setOpenSkillModal] = React.useState(false);
  const [modalName, setModalName] = React.useState("");
  const [refetch, setRefetch] = React.useState(false);

  const currentPage = searchParamsHook.get('page')
  const searchTextParam = searchParamsHook.get('searchParam')
  const [page, setPage] = React.useState(currentPage ?? 1);
  const [searchText, setSearchText] = useState(searchTextParam ?? "");
  const [totalCount, setTotalCount] = React.useState("");
  const rowsPerPage = 20;
  const offset = (page - 1) * rowsPerPage;
  const { search } = useLocation();
  const history = useHistory();

  const locationCurrent = useLocation();

  const title = ["/account-manager-list"].includes(locationCurrent.pathname)?"Account Manager":"Moderator"

  let userType 
  if(["/account-manager-list"].includes(locationCurrent.pathname)){
    userType = "account_manager"
  }else if (["/moderator-list"].includes(locationCurrent.pathname)){
    userType= "moderator"
  }


  
  React.useEffect(() => {
    initialFetch(role, page, rowsPerPage,searchText,userType);
  }, [update]);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(search);
    searchParams.set("page", page);
    history.push({
      search: searchParams.toString(),
    });
    initialFetch(role, page, rowsPerPage,searchTextParam,userType);
  }, [page,searchParamsHook.get("searchParam")]);


  useEffect(() => {
 
    const searchParams = new URLSearchParams(search);
    setSearchText(searchParams.get("searchParam") ?? "");
    setPage(searchParamsHook.get("page") ?? 1);
  }, []);

  const debouncedQuery = useDebounce(searchText, 500);

  useEffect(() => {
    if(debouncedQuery){
      const searchParams = new URLSearchParams(search);
      searchParams.set("searchParam", debouncedQuery);
      setPage(1)
      history.push({
        search: searchParams.toString(),
      });
    }
    else {
      const searchParams = new URLSearchParams(search);
      searchParams.set("searchParam", debouncedQuery);
      // setPage(1)
      history.push({
        search: searchParams.toString(),
      });
    }
 

  }, [debouncedQuery]);



  // const loginStore = JSON.parse(localStorage.getItem("authUser"));


  const handleSearchOpen = ()=>{
    setOpenSearch(!openSearch)
  }

  async function initialFetch(role, page, rowsPerPage,searchText,userType) {

    setLoading(true);
    const { data, status } = await getAllAdminStaffListWithStatus(
      role,
      page,
      rowsPerPage,
      searchText,
      userType
    );
    
    // setTotalCount(Math.ceil(data?.count / rowsPerPage));
    setTotalCount(data?.total_page_size)
    setTotalRecords(data?.overall_count)
    if (!["/account-manager-list"].includes(locationCurrent.pathname)) {
      // setTotalCount(Math.ceil(data?.count))
      const result =
        data?.data_payload?.length > 0 &&
        data?.data_payload
          ?.filter(e => e.usertype === "MODERATOR")
          .map((item, index) => ({
            id: item?.id,
            email: item?.email || "-",
            image: `${imageURL}${item?.image}`,
            phone_number: parseNumberInput(item?.phone_number) || "-",
            location: item?.location || "-",
            experience: item?.experience || "-",
            active_jobs_assigned: item?.active_jobs_assigned || "-",
            total_jobs_assigned: item?.total_jobs_assigned || "-",
            highest_education: item?.highest_education || "-",
            resume: item?.resume,
            name: `${item?.first_name} ${item?.last_name}`,
            companies_mapped: "-",
            status: item?.user_approval,
            industry: `${
              item?.industry_details?.length > 0
                ? item?.industry_details?.map(item => item?.name)?.join(",")
                : "-"
            }`,
            categories: `${
              item?.functional_area_details?.length > 0
                ? item?.functional_area_details
                    ?.map(item => item?.name)
                    ?.join(",")
                : "N/A"
            }`,
            skills: `${
              item?.skill_details?.length > 0
                ? item?.skill_details?.map(item => item?.name)?.join(",")
                : "N/A"
            }`,
            companies_assigned:
              item?.assigned_company_details?.length > 0
                ? item?.assigned_company_details
                    ?.map(item => item?.company_name)
                    ?.join(",")
                : "N/A",

            moderated_candidates: `${
              item?.candidate_screened ? item.candidate_screened : "0"
            }`,
            candidate_onboarded: `${
              item?.candidate_onboarded ? item.candidate_onboarded : "0"
            }`,
          }));
      if (status === 200) {
        setLoading(false);
      }
      setAccountManagers(result);
      return;
    }
    const result =
      data?.data_payload?.length > 0 &&
      data?.data_payload
        ?.filter(e => e.usertype === "ACCOUNT_MANAGER")
        ?.map((item, index) => ({
          id: item?.id,
          email: item?.email || "-",
          image: `${imageURL}${item?.image}`,
          phone_number: parseNumberInput(item?.phone_number) || "-",
          location: item?.location || "-",
          experience: item?.experience || "-",
          active_jobs_assigned: item?.active_jobs_assigned || "-",
          total_jobs_assigned: item?.total_jobs_assigned || "-",
          highest_education: item?.highest_education || "-",
          resume: item?.resume,
          name: `${item?.first_name} ${item?.last_name}`,
          companies_mapped: "-",
          status: item?.user_approval,
          industry: `${
            item?.industry_details?.length > 0
              ? item?.industry_details?.map(item => item?.name)?.join(",")
              : "-"
          }`,
          categories: `${
            item?.functional_area_details?.length > 0
              ? item?.functional_area_details
                  ?.map(item => item?.name)
                  ?.join(",")
              : "N/A"
          }`,
          skills: `${
            item?.skill_details?.length > 0
              ? item?.skill_details?.map(item => item?.name)?.join(",")
              : "N/A"
          }`,
          companies_assigned:
            item?.assigned_company_details?.length > 0
              ? item?.assigned_company_details
                  ?.map(item => item?.company_name)
                  ?.join(",")
              : "N/A",
        }));
    if (status === 200) {
      setLoading(false);
    }
    setAccountManagers(result);
  }
  const handleCloseModal = () => {
    setOpenSkillModal(!openSkillModal);
  };

  const handleCellClick = params => {
    const field = params.field;

    if (field === "skills") {
      if (params.row.skills !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.skills.split(","));
        setOpenSkillModal(!openSkillModal);
      }
    } else if (field === "Categories") {
      if (params.row.Categories !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.Categories.split(","));
        setOpenSkillModal(!openSkillModal);
      }
    } else if (field === "companies_assigned") {
      if (params.row.companies_assigned !== "N/A") {
        setModalName(params.field.replace("_", " "));
        setSelectedRowData(params.row.companies_assigned.split(","));
        setOpenSkillModal(!openSkillModal);
      }
    } else {
      if (
        !["skills", "Categories"].includes(field) &&
        ![CA, CS].includes(loginStore.role)
      )
        history.push(`/common-user/${params.row.id}`);

      // history.push(`/common-user/${params.row.id}`);
    }
  };



  const handleDownload = async()=>{
    const { data, status } = await getAllAdminStaffListWithStatus(
      role,
      page,
      totalRecords,
      searchText,
      userType
    );
let rows
let fileName 
const columns=[
  {
    field: "image",
    headerName:"Image",
    filterable: false,
    disableColumnMenu:true,
    renderCell: params => {
      return (
        <SmallPrimaryAvatar
          imgProps={{ loading: "lazy" }}
          src={params?.formattedValue}
        >
          {params?.row?.name?.charAt(0)?.toUpperCase()}
        </SmallPrimaryAvatar>
      );
    },
  },
  { field: "name",
  headerName:"Name",
  width: 200 },

  { field: "phone_number",
  headerName:"Phone Number",
  width: 300 },
  { field: "email",
  headerName:"Email",
   width: 300 },

  { field: "location",
  headerName:"Location",
  width: 400, filterOperators: LocationOperators },
  {
    field: "status",
    headerName: "Status",
    width: 200,
    renderCell: params => {
      if (params.formattedValue !== "Approve") {
        return <HoldChip label={params?.formattedValue} size="small" />;
      }
      return (
        <PrimaryChip label={params?.formattedValue} size="small" />
      );
    },
  },
  { field: "companies_mapped",
  headerName:"Companies Mapped",
  width: 200 },
  // { field: "active_jobs_assigned", width: 200 },
  // { field: "candidate_onboarded", width: 200 },
  // { field: "total_jobs_assigned", width: 200 },
  // { field: "available_earnings", width: 200 },
  {
    field: "industry",
    headerName: "Industry",
    filterOperators: IndustryOperators,
    width: 400,
  },
  {
    field: "categories",
    headerName: "Categories",
    width: 400,
    renderCell: paramss => (
      <div style={{ display: "flex", alignItems: "center" }}>
        {paramss.value && paramss.value !== "N/A" ? (
          <>
            {/* <span>
              {paramss.value.split(",").slice(0, 2).join(",")}
            </span>
            {paramss.value.split(",").length > 1 && (
              <InfoOutlinedIcon
                style={{
                  marginLeft: 5,
                  cursor: "pointer",
                  opacity: "50%",
                }}
              />
            )} */}
            {paramss.value
              .split(",")
              .slice(0, 2)
              ?.map(each => (
                <SmallSizeChip
                  label={each}
                  style={{ margin: "1px" }}
                  size="small"
                />
              ))}
          </>
        ) : (
          <span>N/A</span>
        )}
      </div>
    ),
  },
  {
    field: "skills",
    headerName:"Skills",
    width: 400,
    renderCell: params => (
      <div style={{ display: "flex", alignItems: "center" }}>
        {params.value && params.value !== "N/A" ? (
          <>
            {/* <span>{params.value.split(",").slice(0, 2).join(",")}</span>
            {params.value.split(",").length > 1 && (
              <InfoOutlinedIcon
                style={{
                  marginLeft: 5,
                  cursor: "pointer",
                  opacity: "50%",
                }}
              />
            )} */}
            {params.value
              .split(",")
              .slice(0, 2)
              ?.map(each => (
                <SmallSizeChip
                  label={each}
                  style={{ margin: "1px" }}
                  size="small"
                />
              ))}
          </>
        ) : (
          <span>N/A</span>
        )}
      </div>
    ),
  },
  ["ZA"].includes(role)
    ? {
        field: "companies_assigned",
        width: 400,
        headerName: "Companies Assigned",
        renderCell: params => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {params.value && params.value !== "N/A" ? (
              <>
                {/* <span>{params.value.split(",").slice(0, 2).join(",")}</span>
            {params.value.split(",").length > 1 && (
              <InfoOutlinedIcon
                style={{
                  marginLeft: 5,
                  cursor: "pointer",
                  opacity: "50%",
                }}
              />
            )} */}
                {params.value
                  .split(",")
                  .slice(0, 2)
                  ?.map(each => (
                    <SmallSizeChip
                      label={each}
                      style={{ margin: "1px" }}
                      size="small"
                    />
                  ))}
              </>
            ) : (
              <span>N/A</span>
            )}
          </div>
        ),
      }
    : "",
  !["/account-manager-list"].includes(locationCurrent.pathname)
    ? {
        field: "moderated_candidates",
        headerName: "Moderated Candidates",
        width: 200,
      }
    : "",

  !["/account-manager-list"].includes(locationCurrent.pathname)
    ? {
        field: "candidate_onboarded",
        headerName: "Onboarded Candidates",
        width: 200,
      }
    : "",
]
    if (!["/account-manager-list"].includes(locationCurrent.pathname)) {
       rows =
      data?.data_payload?.length > 0 &&
      data?.data_payload
        ?.filter(e => e.usertype === "MODERATOR")
        .map((item, index) => ({
          id: item?.id,
          email: item?.email || "N/A",
          image: `${imageURL}${item?.image}`,
          phone_number: item?.phone_number || "N/A",
          location: item?.location || "N/A",
          experience: item?.experience || "N/A",
          active_jobs_assigned: item?.active_jobs_assigned || "N/A",
          total_jobs_assigned: item?.total_jobs_assigned || "N/A",
          highest_education: item?.highest_education || "N/A",
          resume: item?.resume,
          name: `${item?.first_name} ${item?.last_name}`,
          companies_mapped: "N/A",
          status: item?.user_approval,
          industry: `${
            item?.industry_details?.length > 0
              ? item?.industry_details?.map(item => item?.name)?.join(",")
              : "N/A"
          }`,
          categories: `${
            item?.functional_area_details?.length > 0
              ? item?.functional_area_details
                  ?.map(item => item?.name)
                  ?.join(",")
              : "N/A"
          }`,
          skills: `${
            item?.skill_details?.length > 0
              ? item?.skill_details?.map(item => item?.name)?.join(",")
              : "N/A"
          }`,
          companies_assigned:
            item?.assigned_company_details?.length > 0
              ? item?.assigned_company_details
                  ?.map(item => item?.company_name)
                  ?.join(",")
              : "N/A",

          moderated_candidates: `${
            item?.candidate_screened ? item.candidate_screened : "0"
          }`,
          candidate_onboarded: `${
            item?.candidate_onboarded ? item.candidate_onboarded : "0"
          }`,
        }));

        fileName = "Moderators list"
    }else {
       rows =
      data?.data_payload?.length > 0 &&
      data?.data_payload
        ?.filter(e => e.usertype === "ACCOUNT_MANAGER")
        ?.map((item, index) => ({
          id: item?.id,
          email: item?.email || "N/A",
          image: `${imageURL}${item?.image}`,
          phone_number: item?.phone_number || "N/A",
          location: item?.location || "N/A",
          experience: item?.experience || "N/A",
          active_jobs_assigned: item?.active_jobs_assigned || "N/A",
          total_jobs_assigned: item?.total_jobs_assigned || "N/A",
          highest_education: item?.highest_education || "N/A",
          resume: item?.resume,
          name: `${item?.first_name} ${item?.last_name}`,
          companies_mapped: "N/A",
          status: item?.user_approval,
          industry: `${
            item?.industry_details?.length > 0
              ? item?.industry_details?.map(item => item?.name)?.join(",")
              : "N/A"
          }`,
          categories: `${
            item?.functional_area_details?.length > 0
              ? item?.functional_area_details
                  ?.map(item => item?.name)
                  ?.join(",")
              : "N/A"
          }`,
          skills: `${
            item?.skill_details?.length > 0
              ? item?.skill_details?.map(item => item?.name)?.join(",")
              : "N/A"
          }`,
          companies_assigned:
            item?.assigned_company_details?.length > 0
              ? item?.assigned_company_details
                  ?.map(item => item?.company_name)
                  ?.join(",")
              : "N/A",
        }));

        fileName = "Account managers list"
    }


    downloadCsv(rows, columns,fileName);
  }



  function CustomToolbar(){
    return (
      <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <CompanyTypography variant="h1" component="h1">
         {["/account-manager-list"].includes(locationCurrent.pathname)?"Account Manager":"Moderator"}
        </CompanyTypography>
        <div style={{ justifyContent: "flex-end", display: "flex" }}>
          <GridToolbarContainer>

          <StyledOutlinedInput
              placeholder="search..."
              value={searchText}
              onChange={e => handleChange(e)}
              // onChange={e => {
              //   e.stopPropagation();
              //   setSearchText(e.target.value);
              // }}
              startAdornment={
                <InputAdornment position="start">
                  <JobSearchIcon />
                </InputAdornment>
              }
            />

          {[ZA].includes(loginStore.role) && (
          <PrimaryButton
          startIcon={<AddIcon />}
            sx={{ width: "max-content !important", ml: "auto !important" }}
            onClick={() => {
              setViewAddModal(true);
            }}
          >
            Add New
          </PrimaryButton>
        )}

           
            {/* <GlobalSearch /> */}

            <GridToolbarColumnsButton sx={{ color: "#ffffff" }} />
            <GridToolbarFilterButton  sx={{ color: "#ffffff" }} />

            <DownloadButton
              size="medium"
              startIcon={<DownloadIcon />}
              onClick={handleDownload}
              sx={{ color: "#ffffff" }}
            >
              Download
            </DownloadButton>
          </GridToolbarContainer>
        </div>
      </div>
    </>
    )
}


const handleListnerEvent = data => {
  if (['ACCOUNT_MANAGER','MODERATOR'].includes(data?.type)) {
    initialFetch(role, page, rowsPerPage, searchText);
  }
};

useEffect(() => {
  const handleReconnect = () => {
    // Re-register the event listener after reconnection if needed
    socket.on("listnerEvent", handleListnerEvent);
  };

  socket.on("listnerEvent", handleListnerEvent);

  // Attach a listener for socket reconnection
  socket.on("reconnect", handleReconnect);

  return () => {

    socket.off("listnerEvent", handleListnerEvent);
    socket.off("reconnect", handleReconnect);
  };
}, []);

  return (
    <div
      style={{
        padding: "10px",
        height: "91vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
  


    {/* <Stack direction="row" alignItems="center" spacing={1.6}>
      {openSearch?( <SearchIcon onClick={handleSearchOpen} />):
     
      (<StageSearchInput
        placeholder="search..."
        // onChange={(e) => handleSearch(e)}
        value={searchText}
        onChange={(e) => {
          e.stopPropagation();
          setSearchText(e.target.value);
        }}
        endAdornment={
          <IconButton edge="end"
          //  onClick={handleSearchOpen}
          onClick={()=>{
            handleSearchOpen()
            setSearchText("")
          }}
           >
            <ClearIcon />
          </IconButton>
        }
      />)
}
      {[ZA].includes(loginStore.role) && (
        <PrimaryButton
          sx={{ width: "max-content !important", ml: "auto !important" }}
          onClick={() => {
            setViewAddModal(true);
          }}
        >
          Add
        </PrimaryButton>
      )}
    </Stack> */}

<GlobalTableTools handleDownload={handleDownload} tableHeading={title} toggel={true} setViewAddModal={setViewAddModal}  />

      <PrimaryDataGrid
        onCellClick={handleCellClick}
        loading={loading}
        // checkboxSelection={true}
        columns={[
          {
            field: "image",
            headerName:"Image",
            width:200,
            filterable: false,
            headerClassName: "super-app-theme--header",
            disableColumnMenu:true,
            renderCell: params => {
              return (
                <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                  <SmallPrimaryAvatar
                    imgProps={{ loading: "lazy" }}
                    src={params?.formattedValue}
                  >
                    {params?.row?.name?.charAt(0)?.toUpperCase()}
                  </SmallPrimaryAvatar>
                  <p>{params?.row?.name}</p>
                </div>
              );
            },
          },
          // { field: "name",
          // headerName:"Name",
          // width: 200 },

          { field: "phone_number",
          headerName:"Phone Number",
          headerClassName: "super-app-theme--header",
          width: 300 },
          { field: "email",
          headerName:"Email",
          headerClassName: "super-app-theme--header",
           width: 300 },

          { field: "location",
          headerName:"Location",
          headerClassName: "super-app-theme--header",
          width: 400, filterOperators: LocationOperators },
          {
            field: "status",
            headerName: "Status",
            headerClassName: "super-app-theme--header",
            width: 200,
            renderCell: params => {
              if (params.formattedValue !== "Approve") {
                return <HoldChip label={params?.formattedValue} size="small" />;
              }
              return (
                <ApproveChip label={params?.formattedValue} size="small" />
              );
            },
          },
          { field: "companies_mapped",
          headerName:"Companies Mapped",
          headerClassName: "super-app-theme--header",
          width: 200 },
          // { field: "active_jobs_assigned", width: 200 },
          // { field: "candidate_onboarded", width: 200 },
          // { field: "total_jobs_assigned", width: 200 },
          // { field: "available_earnings", width: 200 },
          {
            field: "industry",
            headerName: "Industry",
            headerClassName: "super-app-theme--header",
            filterOperators: IndustryOperators,
            width: 400,
          },
          {
            field: "categories",
            headerName: "Categories",
            headerClassName: "super-app-theme--header",
            width: 400,
            // renderCell: paramss => (
            //   <div style={{ display: "flex", alignItems: "center" }}>
            //     {paramss.value && paramss.value !== "N/A" ? (
            //       <>
                 
            //         {paramss.value
            //           .split(",")
            //           .slice(0, 2)
            //           ?.map(each => (
            //             <SmallSizeChip
            //               label={each}
            //               style={{ margin: "1px" }}
            //               size="small"
            //             />
            //           ))}
            //       </>
            //     ) : (
            //       <span>N/A</span>
            //     )}
            //   </div>
            // ),

            renderCell: params => {
              const values =
                params.value && params.value !== "N/A"
                  ? params.value.split(",")
                  : [];
              const firstValue = values.slice(0, 1);
              const remainingCount = values.length - 1;
      
              return (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  {firstValue.map((each, index) => (
                    <CompanySizeChip
                      key={index}
                      label={each}
                      style={{ margin: "1px" }}
                      size="small"
                    />
                  ))}
                  {remainingCount > 0 && (
                    <CompanySizeChip
                      label={`+${remainingCount}`}
                      style={{ margin: "1px" }}
                      size="small"
                    />
                  )}
                  {values.length === 0 && <span>-</span>}
                </div>
              );
            },

          },
          {
            field: "skills",
            headerName:"Skills",
            headerClassName: "super-app-theme--header",
            width: 400,
            // renderCell: params => (
            //   <div style={{ display: "flex", alignItems: "center" }}>
            //     {params.value && params.value !== "N/A" ? (
            //       <>
            //         {params.value
            //           .split(",")
            //           .slice(0, 2)
            //           ?.map(each => (
            //             <SmallSizeChip
            //               label={each}
            //               style={{ margin: "1px" }}
            //               size="small"
            //             />
            //           ))}
            //       </>
            //     ) : (
            //       <span>N/A</span>
            //     )}
            //   </div>
            // ),
            renderCell: params => {
              const values =
                params.value && params.value !== "N/A"
                  ? params.value.split(",")
                  : [];
              const firstValue = values.slice(0, 1);
              const remainingCount = values.length - 1;
        
              return (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  {firstValue.map((each, index) => (
                    <SkillsChip
                      key={index}
                      label={each}
                      style={{ margin: "1px" }}
                      size="small"
                    />
                  ))}
                  {remainingCount > 0 && (
                    <SkillsChip
                      label={`+${remainingCount}`}
                      style={{ margin: "1px" }}
                      size="small"
                    />
                  )}
                  {values.length === 0 && <span>-</span>}
                </div>
              );
            },

          },
          ["ZA"].includes(role)
            ? {
                field: "companies_assigned",
                width: 400,
                headerName: "Companies Assigned",
                headerClassName: "super-app-theme--header",
                // renderCell: params => (
                //   <div
                //     style={{
                //       display: "flex",
                //       alignItems: "center",
                //       gap: "8px",
                //     }}
                //   >
                //     {params.value && params.value !== "N/A" ? (
                //       <>
                    
                //         {params.value
                //           .split(",")
                //           .slice(0, 2)
                //           ?.map(each => (
                //             <SmallSizeChip
                //               label={each}
                //               style={{ margin: "1px" }}
                //               size="small"
                //             />
                //           ))}
                //       </>
                //     ) : (
                //       <span>N/A</span>
                //     )}
                //   </div>
                // ),

                renderCell: params => {
                  const values =
                    params.value && params.value !== "N/A"
                      ? params.value.split(",")
                      : [];
                  const firstValue = values.slice(0, 1);
                  const remainingCount = values.length - 1;
          
                  return (
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "8px" }}
                    >
                      {firstValue.map((each, index) => (
                        <SmallSizeChip
                          key={index}
                          label={each}
                          style={{ margin: "1px" }}
                          size="small"
                        />
                      ))}
                      {remainingCount > 0 && (
                        <SmallSizeChip
                          label={`+${remainingCount}`}
                          style={{ margin: "1px" }}
                          size="small"
                        />
                      )}
                      {values.length === 0 && <span>-</span>}
                    </div>
                  );
                },
              }
            : "",
          !["/account-manager-list"].includes(locationCurrent.pathname)
            ? {
                field: "moderated_candidates",
                headerName: "Moderated Candidates",
                headerClassName: "super-app-theme--header",
                width: 200,
              }
            : "",

          !["/account-manager-list"].includes(locationCurrent.pathname)
            ? {
                field: "candidate_onboarded",
                headerName: "Onboarded Candidates",
                headerClassName: "super-app-theme--header",
                width: 200,
              }
            : "",
        ]}
        rows={accountManagers}
        slots={{
          loadIcon: LoaderIcon,
          baseButton: PrimaryButtonForTable,
          basePopper: TablePopper,
          baseSwitch: PrimarySwitch,
          // toolbar:CustomToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
          pagination: props => (
            <Pagination
              {...props}
              // color="primary"
              // count={Math.ceil(rowsData.length / rowsPerPage)}
              count={totalCount}
              page={page}
              rowsPerPage={rowsPerPage}
              onChange={(_, newPage) => setPage(newPage)}
              showFirstButton
              showLastButton
              sx={{
                "& .MuiPaginationItem-root": {
                  "&:hover": {
                    backgroundColor: "#cde2e7",
                    color: "#066a4c",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "#024430",
                    color: "#ffe7bb",
                    "&:hover": {
                      backgroundColor: "#cde2e7",
                      color: "#066a4c",
                    },
                  },
                },
              }}
            />
          ),
        }}

        sx={{
          "& .super-app-theme--header": {
            backgroundColor: "rgba(250, 250, 250, 1)",
          },
        }}
      />
      {openSkillModal && (
        <SkillViewModal
          rowdata={selectedRowData}
          closeModalFunc={handleCloseModal}
          openModal={openSkillModal}
          headerName={modalName}
          chipColor={"primarychip"}
        />
      )}
      {viewAddModal && (
        <AdminStaffModel
          isOpen={viewAddModal}
          updated={() => setUpdate(pre => !pre)}
          toggle={() => setViewAddModal(false)}
        />
      )}
    </div>
  );
}
