import  React,{useState} from "react";
import { ListItemButton, ListItemText } from "@mui/material";
import {
  ExtraSmallTypography,
  PrimaryPopUp,
  GrayDivider,
  StyledListItemButton,
  StyledProfilePopText,
  BlackTypography,
} from "assets/Mui/globalTheme";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ClipBoardData from "helpers/dashboard/clipboard";
import { CA, ZA,ACCOUNT_MANAGER,CS,ACM } from "common/constants/userModules";
import CommissionModal from "./CommissionModal";
import EditCommissionModal from "./editCommissionModal";
const JobCardPopUp = React.memo(function JobCard({
  handleClose,
  anchorEl,
  id: jobId,
  tableData
}) {

  const {NODE_ENV} = process.env
  let url 
  if(NODE_ENV === "development"){
    url = 'qa.joinee.com'
  }else if (NODE_ENV === "production"){
    url = 'joinee.com'
  }else {
    url = 'qa.joinee.com'
  }
  const user = useSelector(state => state?.Login);

  const [openModal, setOpenModal] = useState(false)
  const [read,setRead] = useState(false)
const [approveStatus, setApproveStatus] = useState(false)


function toggle(){
  const path = window.location.pathname
  if(path == "/joblist/approved"){
    setRead(true)
  }
  setOpenModal(!openModal)
}

  
  const open = Boolean(anchorEl);
  const id = open ? "profile-popover" : undefined;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    style: {
      maxHeight: ITEM_HEIGHT * 5.7 + ITEM_PADDING_TOP,
      width: 250,
      background: "white",
      marginTop: "10px",
      fontSize: "14px",
      padding: "10px 0px",
      borderRadius: "10px",
      boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
    },
  };

  const path = window.location.pathname
  const jobStatus = tableData?.is_job_post_verified
  
  return (
    <>
          {openModal && <EditCommissionModal isOpen={openModal} read={read} tableData={tableData} jobpostId={jobId} state="edit" title="Edit Commissions" toggle={toggle}/>}
      <PrimaryPopUp
        id={id}
        open={open}
        PaperProps={MenuProps}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {path == "/joblist/approved" && (
        <StyledListItemButton
          onClick={() =>
          
            ClipBoardData(`qa.joinee.com/job?id=${jobId}&userId=`, "Job link")
          }   
        >
          <StyledProfilePopText>
            <BlackTypography variant="h6" component="h6">
              Share
              {/* {(path == "/joblist/approved") ?"Share":""} */}
            </BlackTypography>
          </StyledProfilePopText>
        </StyledListItemButton>
        )}

        {(user?.role === CA || user?.role === CS || user?.role === ACCOUNT_MANAGER || user?.role === ZA) && path !== "/joblist/approved" && !jobStatus ? (
        // {(user?.role === CA || user?.role === CS || user?.role === ACCOUNT_MANAGER || user?.role === ZA) ? (
          <StyledListItemButton  component={Link} to={`/job-edit/${jobId}?user_id=${user?.id}`}>
            <StyledProfilePopText>
              <BlackTypography variant="h6" component="h6">
                Edit
              </BlackTypography>
            </StyledProfilePopText>
          </StyledListItemButton>
        ) : (
          <></>
        )}

          {tableData?.is_commission_added && (user?.role === ACM || user?.role === ZA)  && (
            <StyledListItemButton onClick={(e) => toggle()}>
              <StyledProfilePopText>
                <BlackTypography variant="h6" component="h6">
                  Edit Commissions
                </BlackTypography>
              </StyledProfilePopText>
            </StyledListItemButton>
          )}


      </PrimaryPopUp>
    </>
  );
});
export default JobCardPopUp