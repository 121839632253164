import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  handleCollapse,
  setDownSide,
  setSidebarContent,
  defaultSidebar,
} from "store/actions";
import "../../assets/css/layout/sidebar.css";
import Zepul from "../../assets/images/zepul/shiyftGreenIcon.png";
import ZepulLarge from "../../assets/images/zepul/shifyt1.png";
import joineeLogo from "../../assets/images/joineeLogo.png";
import joineeIcon from "../../assets/images/JoineeIcon.png";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import { StyledTootlTip } from "assets/Mui/globalTheme";

import { contentFinder } from "./items";
import { Tooltip } from "@mui/material";
import { blue, grey, indigo } from "@mui/material/colors";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

import LogoutModel from "./logout";
import { imageURL } from "common/constants/commonURLS";

const popperProps = {
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [2, 7], // Adjust the values to reduce the distance
      },
    },
  ],
};
class Secondary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: true,
      hover: true,
      profilePoP: false,
      //   downSide: false,
      logout: false,
      location: "Sidebar",
      sidebar: [],
      windowWidth: 0,
    };
    this.onResize = this.onResize.bind(this);
    this.handleProfilePopup = this.handleProfilePopup.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }
  handleLogout() {
    this.setState({ logout: !this.state.logout });
  }
  onResize() {
    this.setState({ windowWidth: window.innerWidth });
  }
  handleProfilePopup() {
    this.setState({ profilePoP: !this.state.profilePoP });
  }
  componentDidMount() {
    this.props.defaultSidebar();
    this.mounted = true;
    const role = JSON.parse(localStorage.getItem("authUser")).role;
    if (this.mounted)
      this.setState({
        sidebar: contentFinder(role),
        location: this.props.history.location.pathname,
      });
    if (typeof Window !== undefined)
      this.setState({ windowWidth: window.innerWidth });
    window.addEventListener("resize", this.onResize, false);

  }
  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onResize, false);
  }
  componentDidUpdate(_, prevState) {
    if (prevState.sidebar.length !== this.state.sidebar.length) {
      this.state.sidebar.map(({ name, link }) => {
        if (this.state.location.includes(link)) {
          this.props.setSideItem(name);

     


          return;
        }
      });
      // }
    }
  }
  viewModal = () => {
    this.setState({
      logout: true,
    });
  };






  render() {
    const pathCheck = window.location.pathname;

    return (
      <React.Fragment>
        <div
          className={`sidebar sidebar_ab ${
            this.props.collapse ? "width_f overXhide" : "width_n"
          } `}
          style={{
            transition: "0.5s ease-in-out",
          }}
        >
          <div
            style={{
              transition: "0.5s ease-in-out",
            }}
            className={`logo_sidebar  d-flex align-items-center justify-content-between ${
              this.props.collapse ? "w_img_f" : "w_img_m"
            }`}
          >
            <Link
              to={"/"}
              style={
                this.props.collapse
                  ? { paddingLeft: "45px" }
                  : { paddingLeft: "20px" }
              }
            >
              <img
                // src={ShifytLarge}
                src={this.props.collapse ? joineeLogo : joineeIcon}
                className={this.props.collapse ? "logo_full" : "logo_img"}
              />
            </Link>

            <div
              className={`sidebar_collapse_handler`}
              onClick={() => {
                this.props.collpaseHanlder(this.props.collapse);
              }}
            >
              <FeatherIcon
                size="14"
                icon={`${
                  this.props.collapse ? "chevron-left" : "chevron-right"
                }`}
              />
            </div>
          </div>

          <ul className={`sidebar_list ${this.props.collapse ? "w_full" : ""}`}>
            {this.state.sidebar?.map((item, idx) => {
              return item.icon ? (
                !this.props.collapse ? (
                  <Tooltip
                    PopperProps={popperProps}
                    placement="right-start"
                    title={item.name}
                  >
                    {item.name !== "logout" ? (
                      <Link
                        to={item.link}
                        key={idx}
                        data-label={item.name}
                        className={`list_icons ${
                          this.props.clickedItem === item.name
                            ? "list_click"
                            : ""
                        }`}
                        onClick={() => {
                          this.props.setSideItem(item.name);
                        }}
                      >
                        {typeof item.icon === "string" ? (
                          <i
                            className={item.icon}
                            style={
                              item.icon === "mdi mdi-lightbulb-on"
                                ? { fontSize: "13.5px", marginLeft: "3px" }
                                : {}
                            }
                          ></i>
                        ) : (
                          item.icon
                        )}
                        {this.props.collapse ? (
                          <span className="text_sidebar">{item.name}</span>
                        ) : (
                          ""
                        )}
                      </Link>
                    ) : (
                      <li
                        key={idx}
                        data-label={item.name}
                        className={`list_icons ${
                          this.props.clickedItem === item.name
                            ? "list_click"
                            : ""
                        }`}
                        onClick={() => {
                          this.props.setSideItem(item.name);
                          this.viewModal();
                        }}
                      >
                        {typeof item.icon === "string" ? (
                          <i
                            className={item.icon}
                            style={
                              item.icon === "mdi mdi-lightbulb-on"
                                ? { fontSize: "13.5px", marginLeft: "3px" }
                                : {}
                            }
                          ></i>
                        ) : (
                          item.icon
                        )}
                        {this.props.collapse ? (
                          <span className="text_sidebar">{item.name}</span>
                        ) : (
                          ""
                        )}
                      </li>
                    )}
                  </Tooltip>
                ) : item.name !== "logout" ? (
                  <Link
                    to={item.link}
                    key={idx}
                    data-label={item.name}
                    className={`list_icons ${
                      this.props.clickedItem === item.name ? "list_click" : ""
                    }`}

                

                    // className={`list_icons ${
                    //   pathCheck == "/joblist/pending" && item.name == "Jobs"
                    //     ? "list_click"
                    //     : this.props.clickedItem === item.name ?"list_click":""
                    // }`}

                    onClick={() => {
                      this.props.setSideItem(item.name);
                    }}
                  >
                    {typeof item.icon === "string" ? (
                      <i
                        className={item.icon}
                        style={
                          item.icon === "mdi mdi-lightbulb-on"
                            ? { fontSize: "13.5px", marginLeft: "3px" }
                            : {}
                        }
                      ></i>
                    ) : (
                      item.icon
                    )}
                    {this.props.collapse ? (
                      <span className="text_sidebar">{item.name}</span>
                    ) : (
                      ""
                    )}
                  </Link>
                ) : (
                  <li
                    key={idx}
                    data-label={item.name}
                    className={`list_icons ${
                      this.props.clickedItem === item.name ? "list_click" : ""
                    }`}
                    onClick={() => {
                      this.props.setSideItem(item.name);
                      this.viewModal();
                    }}
                  >
                    {typeof item.icon === "string" ? (
                      <i
                        className={item.icon}
                        style={
                          item.icon === "mdi mdi-lightbulb-on"
                            ? { fontSize: "13.5px", marginLeft: "3px" }
                            : {}
                        }
                      ></i>
                    ) : (
                      item.icon
                    )}
                    {this.props.collapse ? (
                      <span className="text_sidebar">{item.name}</span>
                    ) : (
                      ""
                    )}
                  </li>
                )
              ) : (
                <React.Fragment key={idx}>
                  {!this.props.collapse ? (
                    <Tooltip
                      title={item.name}
                      PopperProps={popperProps}
                      placement="right-start"
                    >
                      <li
                        className="arrow_icon"
                        data-label={item.name}
                        onClick={() => {
                          if (this.props.downSide === idx) {
                            this.props.setDownSide(-1);
                            return;
                          }
                          this.props.setDownSide(idx);
                        }}
                      >
                        {item.logo}
                        {this.props.collapse ? (
                          <>
                            <span className="text_sidebar">{item.name}</span>
                            <span>
                              <FeatherIcon
                                icon="chevron-down"
                                className={`${
                                  this.props.downSide === idx ? "rot_full2" : ""
                                }`}
                                style={{
                                  transition: "0.5s ease-in-out",
                                  transformOrigin: "center center",
                                  height: "18px",
                                  width: "18px",
                                }}
                              ></FeatherIcon>
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </li>
                    </Tooltip>
                  ) : (
                    <li
                      className="arrow_icon"
                      data-label={item.name}
                      onClick={() => {
                        if (this.props.downSide === idx) {
                          this.props.setDownSide(-1);
                          return;
                        }
                        this.props.setDownSide(idx);
                      }}
                    >
                      {item.logo}
                      {this.props.collapse ? (
                        <>
                          <span className="text_sidebar">{item.name}</span>
                          <span>
                            <FeatherIcon
                              icon="chevron-down"
                              className={`${
                                this.props.downSide === idx ? "rot_full2" : ""
                              }`}
                              style={{
                                transition: "0.5s ease-in-out",
                                transformOrigin: "center center",
                                height: "18px",
                                width: "18px",
                              }}
                            ></FeatherIcon>
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </li>
                  )}
                  <div
                    className={`drop_cont ${
                      this.props.downSide === idx ? "down_s" : "up_s"
                    }`}
                    style={{
                      transition: "0.5s ease-in-out",
                    }}
                  >
                    {item?.items.map((data, id) =>
                      !this.props.collapse ? (
                        <Tooltip
                          key={id * 98}
                          PopperProps={popperProps}
                          title={data.name}
                          placement="right-start"
                        >
                          <li
                            key={id * 98}
                            data-label={data.name}
                            id="sidebar_dropdowns"
                            style={{ transition: "0.3s ease-out" }}
                            className={`list_icons only_items ${
                              this.props.clickedItem === data.name
                                ? "list_click"
                                : ""
                            } ${this.props.collapse ? "item_exp" : ""}`}
                            onClick={() => {
                              this.props.setSideItem(data.name);

                              this.props.history.push(data.link);
                            }}
                          >
                            {data?.icon ? (
                              <i className={data?.icon}></i>
                            ) : data?.logo ? (
                              data.logo
                            ) : (
                              data.img
                            )}
                            {this.props.collapse ? (
                              <span className="text_sidebar">{data.name}</span>
                            ) : (
                              ""
                            )}
                          </li>
                        </Tooltip>
                      ) : (
                        <StyledTootlTip
                          placement="right-start"
                          title={data.name}
                        >
                          <li
                            key={id * 98}
                            data-label={data.name}
                            id="sidebar_dropdowns"
                            style={{ transition: "0.3s ease-out" }}
                            className={`list_icons only_items ${
                              this.props.clickedItem === data.name
                                ? "list_click"
                                : ""
                            } ${this.props.collapse ? "item_exp" : ""}`}
                            onClick={() => {
                              this.props.setSideItem(data.name);

                              this.props.history.push(data.link);
                            }}
                          >
                            {data?.icon ? (
                              <i className={data?.icon}></i>
                            ) : data?.logo ? (
                              data.logo
                            ) : (
                              data.img
                            )}
                            {this.props.collapse ? (
                              <span className="text_sidebar">{data.name}</span>
                            ) : (
                              ""
                            )}
                          </li>
                        </StyledTootlTip>
                      )
                    )}
                  </div>
                </React.Fragment>
              );
            })}
          </ul>
   
          <div
            style={
              this.props.collapse
                ? {
                    width: "208px",
                  }
                : { width: "70px" }
            }
            className={`sidebar_user_details ${this.props.collapse ? "" : ""}`}
          >
          </div>
        </div>
        {this.state.logout ? (
          <LogoutModel
            logout={this.state.logout}
            handleLogout={this.handleLogout}
          />
        ) : null}
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  clickedItem: state.Layout.sidetab,
  collapse: state.Layout.collapse,
  downSide: state.Layout.downside,
  user: state.Login,
});
const mapDispatchToProps = dispatch => ({
  setSideItem: name => dispatch(setSidebarContent(name)),
  setDownSide: index => dispatch(setDownSide(index)),
  collpaseHanlder: () => dispatch(handleCollapse()),
  defaultSidebar: () => dispatch(defaultSidebar()),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Secondary)
);


/*Code refractoring */

// import React, { useState, useEffect } from "react";
// import { connect } from "react-redux";
// import { Link, withRouter } from "react-router-dom";
// import {
//   handleCollapse,
//   setDownSide,
//   setSidebarContent,
//   defaultSidebar,
// } from "store/actions";
// import "../../assets/css/layout/sidebar.css";
// import Zepul from "../../assets/images/zepul/shiyftGreenIcon.png";
// import ZepulLarge from "../../assets/images/zepul/shifyt1.png";
// import joineeLogo from "../../assets/images/joineeLogo.png";
// import joineeIcon from "../../assets/images/JoineeIcon.png";
// import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
// import { StyledTootlTip } from "assets/Mui/globalTheme";
// import { contentFinder } from "./items";
// import { Tooltip } from "@mui/material";
// import { blue, grey, indigo } from "@mui/material/colors";
// import FeatherIcon from "feather-icons-react/build/FeatherIcon";
// import LogoutModel from "./logout";
// import { imageURL } from "common/constants/commonURLS";

// const popperProps = {
//   modifiers: [
//     {
//       name: "offset",
//       options: {
//         offset: [2, 7], // Adjust the values to reduce the distance
//       },
//     },
//   ],
// };

// const Secondary = ({
//   clickedItem,
//   collapse,
//   downSide,
//   setSideItem,
//   setDownSide,
//   collpaseHanlder,
//   defaultSidebar,
//   history,
// }) => {
//   const [profilePoP, setProfilePoP] = useState(false);
//   const [logout, setLogout] = useState(false);
//   const [location, setLocation] = useState("Sidebar");
//   const [sidebar, setSidebar] = useState([]);
//   const [windowWidth, setWindowWidth] = useState(0);

//   const handleLogout = () => {
//     setLogout(!logout);
//   };

//   const onResize = () => {
//     setWindowWidth(window.innerWidth);
//   };

//   const handleProfilePopup = () => {
//     setProfilePoP(!profilePoP);
//   };

//   useEffect(() => {
//     defaultSidebar();
//     const role = JSON.parse(localStorage.getItem("authUser")).role;
//     setSidebar(contentFinder(role));
//     setLocation(history.location.pathname);

//     setWindowWidth(window.innerWidth);
//     window.addEventListener("resize", onResize, false);

//     return () => {
//       window.removeEventListener("resize", onResize, false);
//     };
//   }, [history.location.pathname]);

//   useEffect(() => {
//     sidebar.forEach(({ name, link }) => {
//       if (location.includes(link)) {
//         setSideItem(name);
//       }
//     });
//   }, [sidebar]);

//   const viewModal = () => {
//     setLogout(true);
//   };

//   return (
//     <React.Fragment>
//       <div
//         className={`sidebar sidebar_ab ${
//           collapse ? "width_f overXhide" : "width_n"
//         } `}
//         style={{
//           transition: "0.5s ease-in-out",
//         }}
//       >
//         <div
//           style={{
//             transition: "0.5s ease-in-out",
//           }}
//           className={`logo_sidebar  d-flex align-items-center justify-content-between ${
//             collapse ? "w_img_f" : "w_img_m"
//           }`}
//         >
//           <Link
//             to={"/"}
//             style={
//               collapse
//                 ? { paddingLeft: "45px" }
//                 : { paddingLeft: "20px" }
//             }
//           >
//             <img
//               src={collapse ? joineeLogo : joineeIcon}
//               className={collapse ? "logo_full" : "logo_img"}
//             />
//           </Link>

//           <div
//             className={`sidebar_collapse_handler`}
//             onClick={() => {
//               collpaseHanlder(collapse);
//             }}
//           >
//             <FeatherIcon
//               size="14"
//               icon={`${
//                 collapse ? "chevron-left" : "chevron-right"
//               }`}
//             />
//           </div>
//         </div>

//         <ul className={`sidebar_list ${collapse ? "w_full" : ""}`}>
//           {sidebar?.map((item, idx) => {
//             return item.icon ? (
//               !collapse ? (
//                 <Tooltip
//                   PopperProps={popperProps}
//                   placement="right-start"
//                   title={item.name}
//                 >
//                   {item.name !== "logout" ? (
//                     <Link
//                       to={item.link}
//                       key={idx}
//                       data-label={item.name}
//                       className={`list_icons ${
//                         clickedItem === item.name
//                           ? "list_click"
//                           : ""
//                       }`}
//                       onClick={() => {
//                         setSideItem(item.name);
//                       }}
//                     >
//                       {typeof item.icon === "string" ? (
//                         <i
//                           className={item.icon}
//                           style={
//                             item.icon === "mdi mdi-lightbulb-on"
//                               ? { fontSize: "13.5px", marginLeft: "3px" }
//                               : {}
//                           }
//                         ></i>
//                       ) : (
//                         item.icon
//                       )}
//                       {collapse ? (
//                         <span className="text_sidebar">{item.name}</span>
//                       ) : (
//                         ""
//                       )}
//                     </Link>
//                   ) : (
//                     <li
//                       key={idx}
//                       data-label={item.name}
//                       className={`list_icons ${
//                         clickedItem === item.name
//                           ? "list_click"
//                           : ""
//                       }`}
//                       onClick={() => {
//                         setSideItem(item.name);
//                         viewModal();
//                       }}
//                     >
//                       {typeof item.icon === "string" ? (
//                         <i
//                           className={item.icon}
//                           style={
//                             item.icon === "mdi mdi-lightbulb-on"
//                               ? { fontSize: "13.5px", marginLeft: "3px" }
//                               : {}
//                           }
//                         ></i>
//                       ) : (
//                         item.icon
//                       )}
//                       {collapse ? (
//                         <span className="text_sidebar">{item.name}</span>
//                       ) : (
//                         ""
//                       )}
//                     </li>
//                   )}
//                 </Tooltip>
//               ) : item.name !== "logout" ? (
//                 <Link
//                   to={item.link}
//                   key={idx}
//                   data-label={item.name}
//                   className={`list_icons ${
//                     clickedItem === item.name ? "list_click" : ""
//                   }`}
//                   onClick={() => {
//                     setSideItem(item.name);
//                   }}
//                 >
//                   {typeof item.icon === "string" ? (
//                     <i
//                       className={item.icon}
//                       style={
//                         item.icon === "mdi mdi-lightbulb-on"
//                           ? { fontSize: "13.5px", marginLeft: "3px" }
//                           : {}
//                       }
//                     ></i>
//                   ) : (
//                     item.icon
//                   )}
//                   {collapse ? (
//                     <span className="text_sidebar">{item.name}</span>
//                   ) : (
//                     ""
//                   )}
//                 </Link>
//               ) : (
//                 <li
//                   key={idx}
//                   data-label={item.name}
//                   className={`list_icons ${
//                     clickedItem === item.name ? "list_click" : ""
//                   }`}
//                   onClick={() => {
//                     setSideItem(item.name);
//                     viewModal();
//                   }}
//                 >
//                   {typeof item.icon === "string" ? (
//                     <i
//                       className={item.icon}
//                       style={
//                         item.icon === "mdi mdi-lightbulb-on"
//                           ? { fontSize: "13.5px", marginLeft: "3px" }
//                           : {}
//                       }
//                     ></i>
//                   ) : (
//                     item.icon
//                   )}
//                   {collapse ? (
//                     <span className="text_sidebar">{item.name}</span>
//                   ) : (
//                     ""
//                   )}
//                 </li>
//               )
//             ) : (
//               <React.Fragment key={idx}>
//                 {!collapse ? (
//                   <Tooltip
//                     title={item.name}
//                     PopperProps={popperProps}
//                     placement="right-start"
//                   >
//                     <li
//                       className="arrow_icon"
//                       data-label={item.name}
//                       onClick={() => {
//                         if (downSide === idx) {
//                           setDownSide(-1);
//                           return;
//                         }
//                         setDownSide(idx);
//                       }}
//                     >
//                       {item.logo}
//                       {collapse ? (
//                         <>
//                           <span className="text_sidebar">{item.name}</span>
//                           <span>
//                             <FeatherIcon
//                               icon="chevron-down"
//                               className={`${
//                                 downSide === idx ? "rot_full2" : ""
//                               }`}
//                               style={{
//                                 transition: "0.5s ease-in-out",
//                                 transformOrigin: "center center",
//                                 height: "18px",
//                                 width: "18px",
//                               }}
//                             ></FeatherIcon>
//                           </span>
//                         </>
//                       ) : (
//                         ""
//                       )}
//                     </li>
//                   </Tooltip>
//                 ) : (
//                   <li
//                     className="arrow_icon"
//                     data-label={item.name}
//                     onClick={() => {
//                       if (downSide === idx) {
//                         setDownSide(-1);
//                         return;
//                       }
//                       setDownSide(idx);
//                     }}
//                   >
//                     {item.logo}
//                     {collapse ? (
//                       <>
//                         <span className="text_sidebar">{item.name}</span>
//                         <span>
//                           <FeatherIcon
//                             icon="chevron-down"
//                             className={`${
//                               downSide === idx ? "rot_full2" : ""
//                             }`}
//                             style={{
//                               transition: "0.5s ease-in-out",
//                               transformOrigin: "center center",
//                               height: "18px",
//                               width: "18px",
//                             }}
//                           ></FeatherIcon>
//                         </span>
//                       </>
//                     ) : (
//                       ""
//                     )}
//                   </li>
//                 )}
//                 <div
//                   className={`drop_cont ${
//                     downSide === idx ? "down_s" : "up_s"
//                   }`}
//                   style={{
//                     transition: "0.5s ease-in-out",
//                   }}
//                 >
//                   {item?.items.map((data, id) =>
//                     !collapse ? (
//                       <Tooltip
//                         key={id * 98}
//                         PopperProps={popperProps}
//                         title={data.name}
//                         placement="right-start"
//                       >
//                         <li
//                           key={id * 98}
//                           data-label={data.name}
//                           id="sidebar_dropdowns"
//                           style={{ transition: "0.3s ease-out" }}
//                           className={`list_icons only_items ${
//                             clickedItem === data.name
//                               ? "list_click"
//                               : ""
//                           } ${collapse ? "item_exp" : ""}`}
//                           onClick={() => {
//                             setSideItem(data.name);

//                             history.push(data.link);
//                           }}
//                         >
//                           {data?.icon ? (
//                             <i className={data?.icon}></i>
//                           ) : data?.logo ? (
//                             data.logo
//                           ) : (
//                             data.img
//                           )}
//                           {collapse ? (
//                             <span className="text_sidebar">{data.name}</span>
//                           ) : (
//                             ""
//                           )}
//                         </li>
//                       </Tooltip>
//                     ) : (
//                       <StyledTootlTip
//                         placement="right-start"
//                         title={data.name}
//                       >
//                         <li
//                           key={id * 98}
//                           data-label={data.name}
//                           id="sidebar_dropdowns"
//                           style={{ transition: "0.3s ease-out" }}
//                           className={`list_icons only_items ${
//                             clickedItem === data.name
//                               ? "list_click"
//                               : ""
//                           } ${collapse ? "item_exp" : ""}`}
//                           onClick={() => {
//                             setSideItem(data.name);

//                             history.push(data.link);
//                           }}
//                         >
//                           {data?.icon ? (
//                             <i className={data?.icon}></i>
//                           ) : data?.logo ? (
//                             data.logo
//                           ) : (
//                             data.img
//                           )}
//                           {collapse ? (
//                             <span className="text_sidebar">{data.name}</span>
//                           ) : (
//                             ""
//                           )}
//                         </li>
//                       </StyledTootlTip>
//                     )
//                   )}
//                 </div>
//               </React.Fragment>
//             );
//           })}
//         </ul>

//         <div
//           style={
//             collapse
//               ? {
//                   width: "208px",
//                 }
//               : { width: "70px" }
//           }
//           className={`sidebar_user_details ${collapse ? "" : ""}`}
//         ></div>
//       </div>
//       {logout ? (
//         <LogoutModel logout={logout} handleLogout={handleLogout} />
//       ) : null}
//     </React.Fragment>
//   );
// };

// const mapStateToProps = (state) => ({
//   clickedItem: state.Layout.sidetab,
//   collapse: state.Layout.collapse,
//   downSide: state.Layout.downside,
//   user: state.Login,
// });

// const mapDispatchToProps = (dispatch) => ({
//   setSideItem: (name) => dispatch(setSidebarContent(name)),
//   setDownSide: (index) => dispatch(setDownSide(index)),
//   collpaseHanlder: () => dispatch(handleCollapse()),
//   defaultSidebar: () => dispatch(defaultSidebar()),
// });

// export default withRouter(
//   connect(mapStateToProps, mapDispatchToProps)(Secondary)
// );

