// @flow
import {
  SET_SIDEBAR_CONTENT,
  SET_DOWN_SIDEBAR,
  HANDLE_COLLAPSE,
  DEFAULT_SIDEBAR
} from "./actionTypes";

const INIT_STATE = {
  downside: false,
  collapse: false,
  sidetab: "Dashboard",
  showSidebar: true,
  leftMenu: false,
};

const Layout = (state = INIT_STATE, action) => {

  switch (action.type) {
    case HANDLE_COLLAPSE:
      return {
        ...state,
        collapse: !state.collapse,
      };
    case SET_DOWN_SIDEBAR:
      return {
        ...state,
        downside: action.payload,
      };
    case SET_SIDEBAR_CONTENT:
      return {
        ...state,
        sidetab: action.payload,
      };
    
      case DEFAULT_SIDEBAR:
        return {
          ...state,
          collapse: true,
        };

    default:
      return state;
  }
};

export default Layout;
