import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  BlackTypography,
  PrimaryAutoComplete,
  PrimaryButton,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  PrimaryTypography,
  SecondaryFilledButton,
  autocompleteProps,
} from "assets/Mui/globalTheme";
import JobDetailsDescription from "./JobDetailsDescription";
import BubbleLoader from "common/data/NoRowsFoundDatagrid/BubbleLoader";

import {
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height:500,
  overflowY:"scroll",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 2,
};

const gender = [
  { name: "Male", value: "male" },
  { name: "Female", value: "female" },
  { name: "prefer Not To say", value: "prefer_not_to_say" },
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350,
      borderRadius: "10px",
      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
    },
  },
};



export default function JobViewModal({
  isOpen,
  toggle,
  jobDetails
//   updated,
 
}) {

  


  return (
    <div>
      <Modal
    //   sx={style}
        open={isOpen}
        onClose={() => {
          toggle();
          reset();
        //   setLoad(false);
        }}
        aria-labelledby="edit-profile-modal-first_name"
        aria-describedby="edit-profile-modal-description"
      >
       
        <Box sx={style}>
          {jobDetails ? (
            // If jobDetails is available, render the content
            <Grid container spacing={2}>
              <JobDetailsDescription jobDetails={jobDetails} />
              <Grid alignItems={"flex-end"} item xs={7}>
                <Stack spacing={1} direction="row">
                  <SecondaryFilledButton
                    sx={{ width: "150px" }}
                    onClick={() => {
                      toggle();
                      reset();
                    }}
                  >
                    close
                  </SecondaryFilledButton>
                </Stack>{" "}
              </Grid>
            </Grid>
          ) : (
            // If jobDetails is not available, show the loading indicator
            <BubbleLoader />
          )}
        </Box>


      </Modal>
   
    </div>
  );
}
