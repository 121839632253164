const { Row, Container, Col, Card, CardBody } = require("reactstrap");
import React, { useState, useEffect } from "react";
import { IOSSwitch } from "components/Common/iosswitch";
import JobModel from "../../components/Common/models/jobRole";
import SearchIcon from "@mui/icons-material/Search";
import {
  createJobLevel,
  deleteJobLevel,
  getAllJobLevlsWithoutFunctionalArea,
  updateJobLevel,
} from "../../helpers/services/jobAttributes/jobLevels";
import { toast } from "react-hot-toast";
import { FormControlLabel, FormGroup } from "@mui/material";
import { PrimaryButton, PrimarySwitch,PrimaryDataGrid } from "assets/Mui/globalTheme";
import { Table, TableCell, TableHead, TableRow ,Pagination} from "@mui/material";
import BubbleLoader from "common/data/NoRowsFoundDatagrid/BubbleLoader";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress, Stack } from "@mui/material";
import { StageSearchInput, GreyTypography } from "assets/Mui/globalTheme";

import CustomPagination from "components/Pagination/globalPagination";
import SearchParamHook from "hooks/searchParams";
import { useParams, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDebounce } from "hooks/useDebounce";
import CustomNoRowsOverlay from "common/data/NoRowsFoundDatagrid/NoRowsFound";

import GlobalTableTools from "hooks/GlobalTableTools";

import LoaderIcon from "utils/icons/loaderIcon";
import TablePopper from "components/table/header/popper";

const JobRole = () => {
 
  const searchParamsHook = SearchParamHook();
  const { search } = useLocation();
  const history = useHistory();
  const [viewAddModal, setViewAddModal] = React.useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState(false);
  const [index, setIndex] = useState(-1);
  const [defaultVal, setDefaultVal] = useState(false);
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [data, setData] = useState([]);
  const [purpose, setPurpose] = useState("");
  const [functionalAreaId, setFunctionalAreaId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [openSearch, setOpenSearch] = useState(true);
  const [searchRole, setSearchRole] = useState("");
  const searchParams = SearchParamHook();

  const searchTextParam = searchParamsHook.get('searchParam')
  const currentPage = searchParamsHook.get('page')
  const [page, setPage] = React.useState(currentPage ?? 1);
  const [searchText, setSearchText] = useState(searchTextParam ?? "");
  const [totalCount, setTotalCount] = React.useState("");
  const rowsPerPage = 20;

  /* For pagination purpose in progress */

  

 


  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    searchParams.set("searchParam", searchText);
    history.push({
      search: searchParams.toString(),
    });

    // const fetchData = async () => {
    //   setIsLoading(true);
    //   const currentPage = searchParamsHook.get("page") ?? page;
    //   const data = await getAllJobLevlsWithoutFunctionalArea(
    //     currentPage,
    //     rowsPerPage,
    //     searchParamsHook.get("searchParam")
    //   );
    //   setData(data?.data_payload);
    //   setTotalCount(data?.total_page_size);
    //   setIsLoading(false);
    // };

    fetchData();
  }, [searchParams.get("page"), searchParamsHook.get("searchParam")]);

  /* For pagination purpose in progress */



  const handleSearchOpen = () => {
    setOpenSearch(!openSearch);
    setSearchRole("");
  };

  const handleSearch = e => {
    setSearchRole(e.target.value);
  };



  const toggle = (
    purpose = "",
    name = "",
    active = "",
    index = "",
    id = "",
    functionalAreaId = ""
  ) => {
    setPurpose(purpose);
    setName(name);
    setIndex(index);
    setFunctionalAreaId(functionalAreaId);
    setActive(active);
    setId(id);
    setIsOpen(!isOpen);
    setViewAddModal(!viewAddModal);
  };

  const handleDelete = (index, id) => {
    deleteJobLevel(id);
    const result = data.map(each =>
      each.id === id ? { ...each, is_active: !each.is_active } : each
    );
    setData(result);
    toast.error("Disabled");
  };

  const updateFunc = async (sample, type, index, id) => {

    let editedId = id;
    if (!id) {
      editedId = id;
    }

    if (type === "Create") {
      await createJobLevel(sample);
      setIsOpen(false);
      // setData([...data, sample]);
      toast.success("Created");

      setIsLoading(true);
      const currentPage = searchParamsHook.get("page") ?? page;
      
      const jobLevels = await getAllJobLevlsWithoutFunctionalArea(currentPage,rowsPerPage,searchParamsHook.get("searchParam"));
     
      const result =
      jobLevels?.data_payload?.length > 0 &&
      jobLevels?.data_payload?.map((item, index) => ({
        id: item?.id,
      
        industries: `${
          item?.name
        }`,
        categories: `${
          item?.job_attribute_functional_area?.name
        }`,
  
        functional_area_id:`${
          item?.job_attribute_functional_area?.id
        }`,
        is_active: item.is_active,
      }));

      
      setData(result);
      setIsLoading(false);
      setViewAddModal(false)
      // fetchData()
    } else {
      await updateJobLevel(sample, sample.roleId, sample?.functional_area_id);
      toast.success("Updated");
      // const result = data.map(each =>
      //   each.id === editedId
      //     ? { ...each, name: sample.name, is_active: sample.is_active }
      //     : each
      // );
      // setData(result);
      const currentPage = searchParamsHook.get("page") ?? page;
      const jobLevels = await getAllJobLevlsWithoutFunctionalArea(currentPage,rowsPerPage,searchParamsHook.get("searchParam"));
     
      const result =
      jobLevels?.data_payload?.length > 0 &&
      jobLevels?.data_payload?.map((item, index) => ({
        id: item?.id,
      
        industries: `${
          item?.name
        }`,
        categories: `${
          item?.job_attribute_functional_area?.name
        }`,
  
        functional_area_id:`${
          item?.job_attribute_functional_area?.id
        }`,
        is_active: item.is_active,
      }));
     
      setData(result);

      setIsOpen(false);
      setViewAddModal(false)
      // fetchData()
    }
  };

  const handleRestore = (item, index, fnId) => {
    const sample = {
      name: item.name,
      is_active: !item.is_active,
    };
    updateJobLevel(sample, item.id, fnId);
    const result = data.map(each =>
      each.id === item.id
        ? { ...each, name: item?.name, is_active: !each.is_active }
        : each
    );
    setData(result);
    toast.success("Enabled");
  };

  const handleAction = (item, index) => {
    item.is_active
      ? handleDelete(index, item.id)
      : handleRestore(item, index, item.functional_area_id);
  };

 

  const debouncedQuery = useDebounce(searchText, 500);

  useEffect(() => {
    if(debouncedQuery){
      const searchParams = new URLSearchParams(search);
      searchParams.set("searchParam", debouncedQuery);
      searchParams.set("page",1)
      history.push({
        search: searchParams.toString(),
      });
    }
    else {
      const searchParams = new URLSearchParams(search);
      searchParams.set("searchParam", debouncedQuery);
      // setPage(1)
      history.push({
        search: searchParams.toString(),
      });
    }
 

  }, [debouncedQuery]);




  const fetchData = async (page, rowsPerPage, searchText) => {
    setIsLoading(true);
    const currentPage = searchParamsHook.get("page") ?? page;
    const jobLevels = await getAllJobLevlsWithoutFunctionalArea(
      currentPage,
      rowsPerPage,
      searchTextParam
    );
   
    const result =
    jobLevels?.data_payload?.length > 0 &&
    jobLevels?.data_payload?.map((item, index) => ({
      id: item?.id,
    
      industries: `${
        item?.name
      }`,
      categories: `${
        item?.job_attribute_functional_area?.name
      }`,

      functional_area_id:`${
        item?.job_attribute_functional_area?.id
      }`,
      is_active: item.is_active,
    }));

    // setData(jobLevels?.data_payload);
    setData(result);
    setTotalCount(jobLevels?.total_page_size);
    setIsLoading(false);
  };


  useEffect(() => {
   

    fetchData();
  }, []);


    /*Columns Creation@@ */


    React.useEffect(() => {
      const searchParams = new URLSearchParams(search);
      searchParams.set("page", page);
      history.push({
        search: searchParams.toString(),
      });
    
      fetchData();
    }, [page, searchParamsHook.get("searchParam")]);
  
    const columns = [
    
      { field: "industries", headerName: "Job Roles",  headerClassName: "super-app-theme--header", width: 300 },
      { field: "categories", headerName: "Categories",  headerClassName: "super-app-theme--header", width: 300 },
      {
        field: "is_active",
        headerName: "Status",
        headerClassName: "super-app-theme--header",
        width: 200,
        renderCell: (params) => {
          const rowIndex = data.findIndex(row => row.id === params.row.id);
  
          return(
            <PrimarySwitch
            sx={{ m: 1 }}
            checked={params.row.is_active}
            onChange={async (e) => {
              handleAction(params.row, rowIndex);
            }}
          />
          )
        
        },
      },
   
     
  
      {
        field: "Action",
        headerName: "Action",
        headerClassName: "super-app-theme--header",
        width: 600,
      
        renderCell: params => {
        
        
          const index = data.findIndex(row => row.id === params.row.id);
          return (
            <PrimaryButton
            sx={{ width: '100px' }}
            onClick={() => toggle( "Edit",
                                            params.row.industries,
                                            params.row.is_active,
                                            index,
                                            params.row.id,
                                            params.row.functional_area_id
            )}
          >
            Edit
          </PrimaryButton>
          );
        },
      },
  
  
  
      
    ];
  
  
    const filteredColumns = columns.filter(Boolean);


  // return (
  //   <Container fluid>
  //     <JobModel
  //       isOpen={isOpen}
  //       toggle={toggle}
  //       headTag={"Job Role"}
  //       purpose={purpose}
  //       index={index}
  //       fn_id={functionalAreaId}
  //       title={name}
  //       active={active}
  //       update={updateFunc}
  //       id={id}
  //     />
  //     <div className="header_jobs_sticky" md={6} style={{ padding: "40px" }}>
  //       <PrimaryButton sx={{ width: "150px" }} onClick={() => toggle("Create")}>
  //         Create Job Role
  //       </PrimaryButton>
  //       <Stack alignItems={"center"} direction={"row"} spacing={1.6}>
  //         {openSearch ? (
  //           <SearchIcon onClick={handleSearchOpen} />
  //         ) : (
  //           <StageSearchInput
  //             placeholder="search..."
  //             // onChange={e => handleSearch(e)}
  //             value={searchText}
  //             onChange={(e) => {
  //               e.stopPropagation();
  //               setSearchText(e.target.value);
  //             }}
  //             endAdornment={
  //               <IconButton edge="end" 
  //               // onClick={handleSearchOpen}
  //               onClick={() => {
  //                 handleSearchOpen();
  //                 setSearchText("");
  //               }}
  //               >
  //                 <ClearIcon />
  //               </IconButton>
  //             }
  //           />
  //         )}
  //       </Stack>
  //     </div>
  //     <Row style={{ marginLeft: "20px",marginTop:"10px" }}>
  //       <div className="table_cont">
  //         <div className="table-responsive">
  //           <Card>
  //             <CardBody
  //               style={{ height: "calc(100vh - 270px)", overflowY: "scroll" }}
  //             >
  //               <Table className="table mb-0">
  //                 <TableHead>
  //                   <TableRow>
  //                     <TableCell>Job Role</TableCell>
  //                     <TableCell>Category</TableCell>
  //                     <TableCell>Status</TableCell>
  //                     <TableCell>Action</TableCell>
  //                   </TableRow>
  //                 </TableHead>
  //                 <tbody>
  //                   {isLoading ? (
  //                     <BubbleLoader />
  //                   ) : // data?.map((item, index) => (
  //                   //   <TableRow key={item.name + index}>
  //                   //     <TableCell>{item.name}</TableCell>
  //                   //     <TableCell>{item?.job_attribute_functional_area?.name}</TableCell>
  //                   //     <TableCell className="active_td">
  //                   //       <FormGroup>
  //                   //         <FormControlLabel
  //                   //           control={
  //                   //             <PrimarySwitch
  //                   //               sx={{ m: 1 }}
  //                   //               checked={item.is_active}
  //                   //               onChange={async (e) => {
  //                   //                 handleAction(item, index);
  //                   //               }}
  //                   //             />
  //                   //           }
  //                   //         />
  //                   //       </FormGroup>
  //                   //     </TableCell>
  //                   //     <TableCell className="col_btn">
  //                   //       <PrimaryButton
  //                   //         sx={{ width: '100px' }}
  //                   //         onClick={() =>
  //                   //           toggle('Edit', item.name, item.is_active, index, item.id, item.functional_area_id)
  //                   //         }
  //                   //       >
  //                   //         Edit
  //                   //       </PrimaryButton>
  //                   //     </TableCell>
  //                   //   </TableRow>
  //                   // ))

  //                   data?.length > 0 ? (
  //                     data?.map((item, index) => (
  //                       <TableRow key={item.name + index}>
  //                         <TableCell>{item.name}</TableCell>
  //                         <TableCell>
  //                           {item?.job_attribute_functional_area?.name}
  //                         </TableCell>
  //                         <TableCell className="active_td">
  //                           <FormGroup>
  //                             <FormControlLabel
  //                               control={
  //                                 <PrimarySwitch
  //                                   sx={{ m: 1 }}
  //                                   checked={item.is_active}
  //                                   onChange={async e => {
  //                                     handleAction(item, index);
  //                                   }}
  //                                 />
  //                               }
  //                             />
  //                           </FormGroup>
  //                         </TableCell>
  //                         <TableCell className="col_btn">
  //                           <PrimaryButton
  //                             sx={{ width: "100px" }}
  //                             onClick={() =>
  //                               toggle(
  //                                 "Edit",
  //                                 item.name,
  //                                 item.is_active,
  //                                 index,
  //                                 item.id,
  //                                 item.functional_area_id
  //                               )
  //                             }
  //                           >
  //                             Edit
  //                           </PrimaryButton>
  //                         </TableCell>
  //                       </TableRow>
  //                     ))
  //                   ) : (
  //                     <TableRow>
  //                       <TableCell colSpan={5} align="center">
  //                         <CustomNoRowsOverlay />
  //                       </TableCell>
  //                     </TableRow>
  //                   )}
  //                 </tbody>
  //               </Table>
  //             </CardBody>
  //           </Card>
  //         </div>
  //       </div>
  //       <CustomPagination count={totalCount} page={page} setPage={setPage} />
  //     </Row>
  //   </Container>
  // );




  return (
    <>     <JobModel
        // isOpen={isOpen}
        toggle={toggle}
        isOpen={viewAddModal}
        viewAddModal={viewAddModal}
        setViewAddModal={setViewAddModal}
        headTag={'Job Roles'}
        purpose={purpose}
        index={index}
        id={id}
        title={name}
        active={active}
        update={updateFunc}
        fn_id={functionalAreaId}
    
      />

    <div
    style={{
      padding: "10px",
      height: "91vh",
      display: "flex",
      flexDirection: "column",
    }}
  >
    
      <GlobalTableTools  tableHeading={"Job Roles"}
       toggel={true}
       toggleFunc={toggle}
       isJobAttributes={true}
       setViewAddModal={setViewAddModal} 
       downloadOption={false}
        />
    <PrimaryDataGrid
     
      fileName="Job Roles"
      // onCellClick={handleCellClick}
      loading={isLoading}
     

      columns={filteredColumns}
      rows={data}
      slots={{
        loadIcon: LoaderIcon,
        baseButton: PrimaryButton,
        basePopper: TablePopper,
        baseSwitch: PrimarySwitch,
    
        // toolbar:CustomToolbar,
        noRowsOverlay: CustomNoRowsOverlay,
        pagination: props => (
          <Pagination
            {...props}
            // color="primary"
            // count={Math.ceil(rowsData.length / rowsPerPage)}
            count={totalCount}
            page={page}
            rowsPerPage={rowsPerPage}
            onChange={(_, newPage) => setPage(newPage)}
            showFirstButton
            showLastButton
            sx={{
              "& .MuiPaginationItem-root": {
                "&:hover": {
                  backgroundColor: "#cde2e7",
                  color: "#066a4c",
                },
                "&.Mui-selected": {
                  backgroundColor: "#024430",
                  color: "#ffe7bb",
                  "&:hover": {
                    backgroundColor: "#cde2e7",
                    color: "#066a4c",
                  },
                },
              },
            }}
          />
        ),
      }}
      slotProps={{
        baseButton: { style: { width: "100px" } },
        panel: {
          style: {
            right: "0px !important",
            transform: "none !important",
            inset: "none !important",
            top: "138px !important",
          },
        },
        filterPanel: {
          style: {
            right: "0px !important",
            transform: "none !important",
            inset: "none !important",
            top: "138px !important",
          },
        },
        toolbar: { style: {} },
        preferencesPanel: {
          style: {
            right: "0px !important",
            transform: "none !important",
            inset: "none !important",
            top: "138px !important",
          },
        },
      }}
      sx={{
        "& .super-app-theme--header": {
          backgroundColor: "rgba(250, 250, 250, 1)",
        },
      }}
    />
   
  </div>


  </>
  )
};



export default JobRole;
