import React, { useState, useEffect } from "react";
import { GridToolbar } from "@mui/x-data-grid";
import { imageURL } from "common/constants/commonURLS";
import {
  MenuProps,
  PrimaryButton,
  PrimaryButtonForTable,
  PrimaryDataGrid,
  PrimaryNormalInputs,
  PrimarySwitch,
  SmallPrimaryAvatar,
  PrimaryChip,
  StyledTootlTip,
  DownloadButton,
  CompanyTypography,
  StyledOutlinedInput,
  CompanySizeChip,
  ApproveChip,
  SkillsChip
} from "assets/Mui/globalTheme";
import InputAdornment from "@mui/material/InputAdornment";
import JobSearchIcon from "assets/images/icons/JobSearchIcon";
import AddIcon from '@mui/icons-material/Add';
import TablePopper from "components/table/header/popper";
import {
  getAllScreenedApplicantsForUser,
  getAllScreeningPartnerWithStatus,
  getBVGParnters,
} from "helpers/services/screeningPartner/screeningPartner";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Pagination,
  IconButton,
  Stack,
} from "@mui/material";
import { getAllIndustries } from "helpers/services/jobAttributes/industries";
import IndustryInput from "components/table/header/industrySelect";
import { IndustryOperators } from "components/table/operators/industryOperators";
import { LocationOperators } from "components/table/operators/locationOperators";
import LoaderIcon from "utils/icons/loaderIcon";
import { GenderOperators } from "components/table/operators/genderOperators";
import { WorkModeOperators } from "components/table/operators/worModeOperators";
import { useDispatch, useSelector } from "react-redux";
import CustomNoRowsOverlay from "common/data/NoRowsFoundDatagrid/NoRowsFound";
import { BVC } from "common/constants/userModules";
import SearchParamHook from "hooks/searchParams";
import { useLocation, useHistory } from "react-router-dom";
import { useDebounce } from "hooks/useDebounce";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { StageSearchInput } from "assets/Mui/globalTheme";
import downloadCsv from "download-csv";
import DownloadIcon from "@mui/icons-material/Download";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { parseNumberInput } from "hooks/customPhoneNumberParse";
// import moment from "moment";
import GlobalTableTools from "hooks/GlobalTableTools";

export default function ScreenedApplicantsTable() {
  const searchParamsHook = SearchParamHook();
  const { search } = useLocation();
  const [openSearch, setOpenSearch] = useState(true);
  const history = useHistory();
  const [ScreenedApplicants, setScreenedApplicants] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const selector = useSelector(state => state?.Login);
  const role = selector?.role;
  const userId = selector?.id;
  const searchTextParam = searchParamsHook.get("searchParam");
  const [totalRecords, setTotalRecords] = React.useState("");

  const currentPage = searchParamsHook.get("page");
  const [page, setPage] = React.useState(currentPage ?? 1);
  const [searchText, setSearchText] = useState(searchTextParam ?? "");
  const [totalCount, setTotalCount] = React.useState("");
  const rowsPerPage = 20;
  const offset = (page - 1) * rowsPerPage;

  let type;
  if (selector?.usertype == "EVALUATOR") {
    type = "Evaluator";
  } else if (selector?.usertype == "SCREENING_PARTNER") {
    type = "Sp";
  } else {
    type = "Moderator";
  }
  React.useEffect(() => {
    if (role !== BVC) initialFetch();
    else {
      fetchBVG();
    }
  }, []);

  React.useEffect(() => {
    initialFetch(page, rowsPerPage, searchText);
  }, []);

  async function fetchBVG() {
    // const userId = "f63f5afb-81d7-4b39-9cc6-4975ed033844"
    setLoading(true);
    const { data, status } = await getBVGParnters(
      userId,
      page,
      rowsPerPage,
      searchText
    );
    setTotalCount(data?.total_page_size);
    setTotalRecords(data?.overall_count);
    // const {rows,count} = data?.message
    // const list = count ? rows : []
    // const tweakList = list?.map((item)=>({
    const tweakList = data?.data_payload?.map(item => ({
      id: item?.id,
      name: item?.first_name + item?.last_name,
      image: `${item?.job_applicant?.image ? item?.job_applicant?.image : ""}`,
      phone_number: `${item?.phone_number ? parseNumberInput(item?.phone_number) : "N/A"}`,
      email: `${item?.email}`,
      jobs_screened: item?.get_screened_job_post_counts?.count,
      resume: item?.resume,
      company_image: `${imageURL}${item?.company_details?.company?.company_image}`,
      company_name: item?.company_details?.company?.company_name,
      status: `${item?.job_post_pipeline_stage?.stage_name}`,
      date_screened: new Date(item?.updatedAt).toLocaleDateString(),
      job_post_id: item?.job_post_id,
      job_title: item?.job_post_in_user_job_post?.job_title ?? "N/A",
    }));

    if (data?.data_payload) {
      setLoading(false);
    }

    setScreenedApplicants(tweakList);
  
  }

  async function initialFetch(page, rowsPerPage, searchText) {
    setLoading(true);
    const { data, status } = await getAllScreenedApplicantsForUser(
      selector.id,
      type,
      page,
      rowsPerPage,
      searchText
    );
    setTotalCount(data?.total_page_size);
    setTotalRecords(data?.overall_count);
    // setTotalCount(Math.ceil((data?.message?.count)/rowsPerPage))
    const result = data?.data_payload?.map((item, index) => ({
      id: item?.id,
      name: `${item?.first_name} ${item?.last_name}`,
      image: `${item?.job_applicant?.image ? item?.job_applicant?.image : ""}`,
      phone_number: `${item?.phone_number ? parseNumberInput(item?.phone_number) : "N/A"}`,
      email: `${item?.email}`,
      jobs_screened: item?.get_screened_job_post_counts?.count,
      resume: item?.resume,
      company_image: `${imageURL}${item?.company_details?.company?.company_image}`,
      company_name: item?.company_details?.company?.company_name,
      status: `${item?.job_post_pipeline_stage?.stage_name}`,
      date_screened: new Date(item?.updatedAt).toLocaleDateString(),
      job_post_id: item?.job_post_in_user_job_post?.id,
      job_title: item?.job_post_in_user_job_post?.job_title ?? "N/A",
    }));
    if (data?.data_payload) {
      setLoading(false);
    }
    setScreenedApplicants(result);
  }

  const handleDownload = async () => {
    if (role !== BVC) {
      setLoading(true);
      const { data, status } = await getAllScreenedApplicantsForUser(
        selector.id,
        type,
        page,
        totalRecords,
        searchText
      );
      setTotalCount(data?.total_page_size);
      setTotalRecords(data?.overall_count);
      // setTotalCount(Math.ceil((data?.message?.count)/rowsPerPage))
      const result = data?.data_payload?.map((item, index) => ({
        id: index + 1,
        name: `${item?.first_name} ${item?.last_name}`,
        image: `${
          item?.job_applicant?.image ? item?.job_applicant?.image : ""
        }`,
        phone_number: `${item?.phone_number ? item?.phone_number : "N/A"}`,
        email: `${item?.email}`,
        jobs_screened: item?.get_screened_job_post_counts?.count,
        resume: item?.resume,
        company_image: `${imageURL}${item?.company_details?.company?.company_image}`,
        company_name: item?.company_details?.company?.company_name,
        status: `${item?.job_post_pipeline_stage?.stage_name}`,
        date_screened: new Date(item?.updatedAt).toLocaleDateString(),
        job_post_id: item?.job_post_in_user_job_post?.id,
        job_title: item?.job_post_in_user_job_post?.job_title ?? "N/A",
      }));
      if (data?.data_payload) {
        setLoading(false);
      }
      setScreenedApplicants(result);

      downloadCsv(result, filteredColumns, "Evaluated candidates");
    } else {
      setLoading(true);
      const { data, status } = await getBVGParnters(
        userId,
        page,
        totalRecords,
        searchText
      );
      setTotalCount(data?.total_page_size);
      setTotalRecords(data?.overall_count);
      // const {rows,count} = data?.message
      // const list = count ? rows : []
      // const tweakList = list?.map((item)=>({
      const tweakList = data?.data_payload?.map(item => ({
        id: item?.id,
        name: item?.first_name + item?.last_name,
        image: `${
          item?.job_applicant?.image ? item?.job_applicant?.image : ""
        }`,
        phone_number: `${item?.phone_number ? item?.phone_number : "N/A"}`,
        email: `${item?.email}`,
        jobs_screened: item?.get_screened_job_post_counts?.count,
        resume: item?.resume,
        company_image: `${imageURL}${item?.company_details?.company?.company_image}`,
        company_name: item?.company_details?.company?.company_name,
        status: `${item?.job_post_pipeline_stage?.stage_name}`,
        date_screened: new Date(item?.updatedAt).toLocaleDateString(),
        job_post_id: item?.job_post_id,
        job_title: item?.job_post_in_user_job_post?.job_title ?? "N/A",
      }));

      if (data?.data_payload) {
        setLoading(false);
      }

      setScreenedApplicants(tweakList);
    }
  };

  const handleSearchOpen = () => {
    setOpenSearch(!openSearch);
  };

  React.useEffect(() => {
    const searchParams = new URLSearchParams(search);
    searchParams.set("page", page);
    history.push({
      search: searchParams.toString(),
    });

    if (role !== BVC) initialFetch(page, rowsPerPage, searchTextParam);
    else {
      fetchBVG();
    }

    // initialFetch(page, rowsPerPage, searchText);
  }, [page, searchParamsHook.get("searchParam")]);

  /* seacrh */

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    setSearchText(searchParams.get("searchParam") ?? "");
    setPage(searchParamsHook.get("page") ?? 1);
  }, []);

  const debouncedQuery = useDebounce(searchText, 500);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    searchParams.set("searchParam", debouncedQuery);
    searchParams.set("page", 1);
    setPage(1);
    history.push({
      search: searchParams.toString(),
    });
  }, [debouncedQuery]);

  /* seacrh */

  function CustomToolbar() {
    return (
      <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <CompanyTypography variant="h1" component="h1">
          Evaluated Applicants
        </CompanyTypography>
        <div style={{ justifyContent: "flex-end", display: "flex" }}>
          <GridToolbarContainer>

          <StyledOutlinedInput
              placeholder="search..."
              value={searchText}
              onChange={e => handleChange(e)}
              startAdornment={
                <InputAdornment position="start">
                  <JobSearchIcon />
                </InputAdornment>
              }
            />

        

           
            {/* <GlobalSearch /> */}

            <GridToolbarColumnsButton sx={{ color: "#ffffff" }} />
            <GridToolbarFilterButton  sx={{ color: "#ffffff" }} />

            <DownloadButton
              size="medium"
              startIcon={<DownloadIcon />}
              onClick={handleDownload}
              sx={{ color: "#ffffff" }}
            >
              Download
            </DownloadButton>
          </GridToolbarContainer>
        </div>
      </div>
    </>
    );
  }

  const columns = [
    {
      field: "image",
      headerName: "Name",
      filterable: false,
      width:200,
      headerClassName: "super-app-theme--header",
      renderCell: params => {
        return (
          <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
            <SmallPrimaryAvatar
              imgProps={{ loading: "lazy" }}
              src={params?.formattedValue}
            >
              {params?.row?.name?.charAt(0)?.toUpperCase()}
            </SmallPrimaryAvatar>
            <p>{params?.row?.name}</p>
          </div>
        );
      },
    },
    // { field: "name", headerName: "Name", width: 200 },
    { field: "phone_number", headerName: "Phone Number",headerClassName: "super-app-theme--header", width: 300 },
    { field: "email", headerName: "Email",headerClassName: "super-app-theme--header", width: 300 },
    { field: "job_title", headerName: "Job Title",headerClassName: "super-app-theme--header", width: 300 },
    {
      field: "date_screened",
      headerName: "Date Screened",
      headerClassName: "super-app-theme--header",
      width: 400,
    },
    // { field: "status", width: 400 },
    {
      field: "Status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: params => {
        return (
          <StyledTootlTip title={params?.row?.status} placement="top">
            <PrimaryChip label={params?.row?.status} size="small" />
          </StyledTootlTip>
        );
      },
    },
  ];

  const filteredColumns = columns.filter(Boolean);

  return (
    <div style={{ padding: "10px", height: "91vh" }}>
      {/* <Stack
        direction="row"
        alignItems="center"
        spacing={1.6}
        sx={{ display: "flex", justifyContent: "flex-end" }}
      >
        {openSearch ? (
          <SearchIcon onClick={handleSearchOpen} />
        ) : (
          <StageSearchInput
            placeholder="search..."
            // onChange={(e) => handleSearch(e)}
            value={searchText}
            onChange={e => {
              e.stopPropagation();
              setSearchText(e.target.value);
            }}
            endAdornment={
              <IconButton
                edge="end"
                onClick={() => {
                  handleSearchOpen();
                  setSearchText("");
                }}
              >
                <ClearIcon />
              </IconButton>
            }
          />
        )}
      </Stack> */}
   <GlobalTableTools handleDownload={handleDownload} tableHeading={"Screening Partner"}  />
      <PrimaryDataGrid
        loading={loading}
        checkboxSelection={false}
     
        columns={filteredColumns}
        rows={ScreenedApplicants}
        slots={{
          loadIcon: LoaderIcon,
          baseButton: PrimaryButton,
          basePopper: TablePopper,
          baseSwitch: PrimarySwitch,
          // toolbar: CustomToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
          pagination: props => (
            <Pagination
              {...props}
              // color="primary"
              // count={Math.ceil(rowsData.length / rowsPerPage)}
              count={totalCount}
              page={page}
              rowsPerPage={rowsPerPage}
              onChange={(_, newPage) => setPage(newPage)}
              showFirstButton
              showLastButton
              sx={{
                "& .MuiPaginationItem-root": {
                  "&:hover": {
                    backgroundColor: "#cde2e7",
                    color: "#066a4c",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "#024430",
                    color: "#ffe7bb",
                    "&:hover": {
                      backgroundColor: "#cde2e7",
                      color: "#066a4c",
                    },
                  },
                },
              }}
            />
          ),
        }}
        slotProps={{
          baseButton: { style: { width: "100px" } },
          panel: {
            style: {
              right: "0px !important",
              transform: "none !important",
              inset: "none !important",
              top: "138px !important",
            },
          },
          filterPanel: {
            style: {
              right: "0px !important",
              transform: "none !important",
              inset: "none !important",
              top: "138px !important",
            },
          },
          toolbar: { style: {} },
          preferencesPanel: {
            style: {
              right: "0px !important",
              transform: "none !important",
              inset: "none !important",
              top: "138px !important",
            },
          },
        }}
        sx={{
          "& .super-app-theme--header": {
            backgroundColor: "rgba(250, 250, 250, 1)",
          },
        }}
      />
    </div>
  );
}
