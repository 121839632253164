import { POST, PUT } from "common/constants/methods";
import callApi from "helpers/callApi";
async function companyUserID() {
  return await JSON.parse(localStorage.getItem("authUser"))
    ?.recrutement_company_id;
  // return await JSON.parse(localStorage.getItem("authUser"))
  // ?.id;
}

async function companyId() {
  return await JSON.parse(localStorage.getItem("authUser"))?.company_id;
}

export const creteRecrutementCompany = async body => {
  const reqBody = {
    email: body.email,
    first_name: body.first_name,
    last_name: body.last_name,
    phone_number: body.phone_number,
    user_password: body.password,
    usertype: body.userType,
    company_name: body.company_name,
  };
  const data = await callApi("/recruitment_authorise/register", reqBody, POST);
  return data;
};
export const createRC = async body => {
  const reqBody = {
    ...body,
    email: body.email,
    first_name: body.first_name,
    last_name: body.last_name,
    phone_number: body.phone_number,
    user_password: body.user_password,
    usertype: body.userType,
    company_name: body.company_name,
  };
  delete reqBody.userType
  const data = await callApi("/recruitment_authorise/register", reqBody, POST);
  return data;
};
export const createRecrutementCompanyStaff = async ({
  email,
  first_name,
  last_name,
  user_password,
  usertype,
}) => {
  return await callApi(
    "recruitment_company_staff/register",
    {
      email,
      first_name,
      last_name,
      user_password,
      usertype: "RECRUITMENT_COMPANY_STAFF",
      company_id: await companyUserID(),
      is_active: true,
    },
    POST
  );
};


// export const getAllRecrutementCompanyStaffList = async () => {
//   return await callApi(
//     `recruitment_authorise/get_recruitment_company_staff_list?company_id=${await companyUserID()}`
//   );
// };

/* Uncomment this function once pagination with search implemented from backend */
export const getAllRecrutementCompanyStaffList = async (page,pageSize,search) => {
  return await callApi(
    `recruitment_authorise/get_recruitment_company_staff_list?company_id=${await companyUserID()}&searchParam=${search}&pageNo=${page}&pageSize=${pageSize}`
  );
};


export const updateRecrutementCompanyStaff = async (data, id) => {
  return await callApi(
    `recruitment_company_staff/${id}`,
    { ...data, company_id: await companyUserID() },
    PUT
  );
};

export const getSingleRecrutementStaffDetails = async id => {
  return await callApi(
    `recruitment_company_staff/${id}?company_id=${await companyUserID()}`
  );
};


export const getAllRecruitmentUsersList = async (role,page,rowsPerPage,searchText) => {
  if(["ZA"].includes(role)){
    // return await callApi(`referral_partner_authorise/rc_list?offset={offset}&&limit={page}`);
    return await callApi(`referral_partner_authorise/rc_list?pageNo=${page}&&pageSize=${rowsPerPage}&searchParam=${searchText}`);
  }
  return await callApi(`referral_partner_authorise/rc_list?company_id=${await companyId()}&&pageNo=${page}&&pageSize=${rowsPerPage}&searchParam=${searchText}`);
};