const { Container, Col, Row } = require("reactstrap");
import React, { useEffect, useState } from "react";
import "../../../assets/css/list.css";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import noData from "../../../assets/images/zepul/shiyftEmpty.png";
import { CloseJobModel } from "../JobList/CloseJobModel";
import { ViewDetailsModal } from "./ViewDetailsModels";
import { ViewCandidateDetailsModal } from "./CandidatesDetails";
import "./jobslist.css";
import {
  deleteSingleJob,
  getAllJobs,
  getAllJobsBasedOnCompany,
} from "helpers/services/jobs/jobs";
import { formatDistance } from "date-fns";
import getSymbolFromCurrency from "currency-symbol-map";
import { connect } from "react-redux";
import { ACM, CA, ZA } from "common/constants/userModules";
import { handleApproveJob } from "helpers/services/adminStaff/AccountManager.js/ApproveJobs";
import { findJobType } from "helpers/functions/findJobType";
import findWorkMode from "helpers/functions/findWorkMode";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CompanyDefaulImage from "../../../assets/images/zepul/company_default_img.svg";

function CompanyJobList({ role }) {
  const { id } = useParams();

  let history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [viewDetails, setViewDetails] = useState(tableData[0]);

  const [modelData, setModelData] = useState({
    modal: false,
    details: {},
  });

  const [viewDetailsModal, setViewDetailsModal] = useState({
    modal: false,
    details: {},
  });

  const [viewCandidateDetailsModal, setCandidateDetailsModal] = useState({
    modal: false,
    details: {},
  });

  const handleClose = confirm => {};

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const searchItems = event => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const results = tableData.filter(item =>
      item.job_title.toLowerCase().includes(searchTerm)
    );
    setSearchResults(results);
  }, [searchTerm]);

  useEffect(() => {
    async function dataCaller() {
      const items = await getAllJobsBasedOnCompany(id);
      const activeData = items.filter(
        data => data.is_job_post_verified === true && data.is_active === true
      );
      setTableData(activeData);
    }
    dataCaller();
  }, []);

  useEffect(() => {
    setViewDetails(tableData[0]);
  }, [tableData]);
  function randcolor() {
    function c() {
      return Math.floor(Math.random() * 220 + 36).toString(16);
    }
    return "#" + c() + c() + c();
  }
  return (
    <div className="page-content">
      <Container fluid style={{ paddingLeft: "15px", paddingRight: "15px" }}>
        <div className="row row-gap-4">
          {tableData.length > 0 ? (
            tableData.map(item => (
              <Col xs={6} md={6} lg={4} className="mb-4" key={item.id}>
                <div onClick={() => setViewDetails(item)}>
                  <div className={`job_card`}>
                    <div className="d-flex align-center justify-content-between">
                      <div>
                        <div className="image-box">
                          {item.company_details.company_image !== null ? (
                            <img
                              onError={e => {
                                e.target.src = CompanyDefaulImage;
                              }}
                              src={item.company_details.company_image}
                              alt="logo"
                            />
                          ) : (
                            <div
                              className="profile_alternative"
                              style={{
                                background: randcolor(),
                                color: "white",
                              }}
                            >
                              {item.company_details.company_name.charAt(0)}
                            </div>
                          )}
                          <aside>
                            <Link className="h5 mt-2 mb-2" to="/jobs/id">
                              {item?.company_details?.company_name}
                            </Link>
                            <div className="location mt-1">
                              {item.work_mode !== "Fully_remote"
                                ? item.location
                                : item.time_zone[2]}
                            </div>
                          </aside>
                        </div>
                      </div>
                      <div>
                        <div>
                          {item.key_skill_details.slice(0, 1).map(data => (
                            <span className="mx-2 key_skills" key={data.id}>
                              {data.name}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>

                    {role === CA || role === ZA ? (
                      <i
                        className="bx bx-edit cursor-pointer job-edit-icon"
                        onClick={() => history.push(`/job-edit/${item.id}`)}
                      />
                    ) : (
                      ""
                    )}

                    <div className="card-block-info">
                      <div className="d-flex align-items-center justify-content-between">
                        <aside>
                          <span className="header_3">
                            {" "}
                            {formatDistance(
                              new Date(item.createdAt),
                              new Date(),
                              {
                                addSuffix: true,
                              }
                            )}
                          </span>
                        </aside>
                        <h5 className="job-title">
                          <Link to="/job-details">{item.job_title}</Link>
                        </h5>
                      </div>
                      <div className="my-2 d-flex align-items-center gap-2 flex-wrap job_contents">
                        <div className="job_details">
                          <span className="job_details_header header_1">
                            {findJobType(item.job_type)}{" "}
                          </span>
                        </div>
                        <div className="job_details">
                          <span className="job_details_header header_2">
                            {" "}
                            {item.commission_rate}{" "}
                            {item.commission_rate_type === "Percentage"
                              ? "%"
                              : ""}
                            {item.commission_rate_type === "Flat"
                              ? item.commission_rate
                              : ""}
                          </span>
                        </div>

                        <div className="job_details">
                          <span className="job_details_header header_4">
                            {formatDistance(
                              new Date(item.application_deadline),
                              new Date(),
                              {
                                addSuffix: true,
                              }
                            )}
                          </span>
                        </div>
                        <div className="job_details">
                          <span className="job_details_header header_5">
                            {" "}
                            {findWorkMode(item.work_mode)}
                          </span>
                        </div>
                      </div>
                      <p className="font-sm job_description mt-10">
                        {item.job_desc.substring(0, 80)}
                      </p>
                      <div className="footer_job_list">
                        <div className="col-lg-7 col-7">
                          <div
                            className="d-flex align-items-center"
                            onClick={() =>
                              setCandidateDetailsModal({
                                modal: true,
                                details: {},
                              })
                            }
                          >
                            <span className="currency-text">
                              {getSymbolFromCurrency(item.currency)}{" "}
                              {item.min_sal} - {item.max_sal} /
                            </span>
                            <span className="currency-time">
                              {item.job_type === "Full_Time" ? "Yr" : "Hr"}
                            </span>
                          </div>
                        </div>

                        {role === ZA ? (
                          <div
                            className="delete_btn_job"
                            onClick={() =>
                              setModelData({
                                modal: true,
                                details: {
                                  id: item.id,
                                  modelhed: "Delete This job",
                                  modelText: "Are you Sure to close this Job?",
                                },
                              })
                            }
                          >
                            <span>close</span>
                            <i className="bx bx-trash del_icon_job"></i>
                          </div>
                        ) : (
                          ""
                        )}
                        {role === ACM ? (
                          <>
                            <div
                              className="delete_btn_job"
                              onClick={() =>
                                setModelData({
                                  modal: true,
                                  details: {
                                    id: item.id,
                                    modelhed: "Delete This job",
                                    modelText:
                                      "Are you Sure to close this Job?",
                                  },
                                })
                              }
                            >
                              <span>close</span>
                              <i className="bx bx-trash del_icon_job"></i>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <div className="no_data_show">
              <div>
                <img src={noData} className="lg_img"></img>
                <h4>No Active Jobs Found</h4>
              </div>
            </div>
          )}
        </div>

        {modelData ? (
          <CloseJobModel
            setModelData={doc => setModelData(doc)}
            show={modelData.modal}
            onHide={() => {
              setModelData({ modal: false, details: {} });
            }}
            params={modelData}
            onSubmit={async () => {
              const { status } = await deleteSingleJob(modelData.details.id);
              if (status === 200) {
                const index = tableData.findIndex(
                  ({ id }) => id === modelData.details.id
                );
                const data = [...tableData];
                const items = data.splice(index, 1);
                setTableData(data);
              }
              handleClose();
            }}
          />
        ) : null}

        {viewDetailsModal ? (
          <ViewDetailsModal
            setModelData={doc => setViewDetailsModal(doc)}
            show={viewDetailsModal.modal}
            onHide={() => {
              setViewDetailsModal({ modal: false, details: {} });
            }}
            params={viewDetailsModal}
            onSubmit={handleClose}
          />
        ) : null}

        {viewCandidateDetailsModal ? (
          <ViewCandidateDetailsModal
            setModelData={doc => setCandidateDetailsModal(doc)}
            show={viewCandidateDetailsModal.modal}
            onHide={() => {
              setCandidateDetailsModal({ modal: false, details: {} });
            }}
            params={viewCandidateDetailsModal}
            onSubmit={handleClose}
          />
        ) : null}
      </Container>
    </div>
  );
}
const mapStateToProps = state => ({
  role: state.Login.role,
});
export default connect(mapStateToProps, null)(CompanyJobList);
