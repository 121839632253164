import axios from "axios";
import callApi from "./callApi";


function listner() {
  return JSON.parse(localStorage.getItem("authUser"))?.token;
  return store.getState()?.Login?.data?.access;
}

export const resumeParser = async (file, token) => {
  const formData = new FormData();

  formData.append("resumecontent", file);
  formData.append("Authorization", token);

  return await axios({
    method: "POST",
    url: "https://zepul-resume-parser.codeace.us/home/resume_parser_details",
    data: formData,
  });
};


export const appsumResumeParser = async (resumeFile, token) => {
  const formData = new FormData();
  formData.append("file", resumeFile);


  // return await callApi('/resume_parser',formData,"POST")

  // Set the Authorization header
  const config = {
    headers: {
      Authorization: "Bearer " + listner(),
    },
  };

  

  try {
    const response = await axios.post(
      "https://qaservices.joinee.com/resume_parser",
      formData,
      config
    );

   

    return response; // Assuming you want to return the data from the response
  } catch (error) {
    // Handle errors
    console.error("Error in resume parsing:", error);
    throw error; // Re-throw the error if needed
  }
};
