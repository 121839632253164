import "../../../assets/css/dashboard.css";
import React, { useEffect, useReducer, useState } from "react";
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import Icon from '@mui/material/Icon';

import { CardBody, Container, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import JobList from "../../../components/Jobs/list";
import ClosedJobs from "../../../components/Jobs/closedJobs";
import Pending from "../../../components/Jobs/pending";
import { ACM, CA, CS, ZA } from "common/constants/userModules";
import JobsBasedOnCompanies from "./jobsBasedOnCompanies";
import check from '../../../assets/images/check.svg'
import {
  getAllJobs,
  getAllJobsBasedOnCompany,
  getSingleJobDetails,
} from "helpers/services/jobs/jobs";
import ErrorHandlingToaster from "helpers/Toaster/error";
import SearchParamHook from "hooks/searchParams";
import DetailsDrawer from "components/JobDetails/DetailDrawer";
import { CircularProgress, Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import {
  PrimaryButton,
  PrimaryTextButton,
  SecondaryButton,
  SolidTab,
  StyledLoginTabsObject,
  StageSearchInput,
  PrimaryPopUp,
  PrimaryAvatar,
  GreyTypography,
  JobSearchInput,
  FilterSearchInput,
  FilterButton,
  JobSearchOutlinedButton
} from "assets/Mui/globalTheme";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { Tabs } from "@mui/material";
import searchFilter from "components/Common/filters/searchFilter";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { makeStyles } from "@mui/styles";
import { getAllCompaniesWithStatu } from "helpers/services/companies/company";
import { toast } from "react-hot-toast";
import SocketConnection from "hooks/sockets";
import CustomPagination from "components/Pagination/globalPagination";
import { useDebounce } from "hooks/useDebounce";
import JobFilterIcon from "assets/images/icons/FilterIcon";
import JobSearchIcon from "assets/images/icons/JobSearchIcon";
import CheckIcon from "assets/images/icons/checkIcon";
import MarkIcon from "assets/images/icons/markIcon";
import CrossIcon from "assets/images/icons/crossIcon";
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import JobViewModal from "components/JobDetails/JobViewModal";

const useStyles = makeStyles({
  label: {
    display: "flex",
    padding: "12px",
    alignItems: "center",
    gap: "16px",
    cursor: "pointer",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.04)",
    },
    // "& >svg":{
    //     marginLeft:'12px',
    // }
  },
  activeLabel: {
    display: "flex",
    padding: "12px",
    alignItems: "center",
    gap: "16px",
    cursor: "pointer",
    background: "#cde2e7",
    color: "#024430",
  },
});
const MenuProps = {
  style: {
    maxHeight: 400,
    // width: 350,
    background: "white",
    marginRight: "20px",
    marginTop: "20px",
    fontSize: "14px",
    padding: "10px",
    borderRadius: "10px",
    boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
  },
};
function WholeJobs({ history, match }) {
  const {
    lastMessage,
    sendMessage: sendSocketMessage,
    connectionStatus,
  } = SocketConnection();
  const userData = JSON.parse(localStorage.getItem("authUser"));
  const userId = userData?.id;

  const searchParams = SearchParamHook();
  const [openSearch, setOpenSearch] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const [jobDetails, setJobDetails] = useState({
    application_deadline: new Date(),
    createdAt: new Date(),
  });
  const [filterValue, setFilterValue] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const login = {
    ...JSON.parse(localStorage.getItem("authUser")),
  };

  const searchTextParam = searchParams.get('searchParam')
  const companyTextParam = searchParams.get('companyParam')
  const companyIdParam = searchParams.get('companyId')
 
  const [selectedCompany, setSelectedCompany] = useState(companyIdParam ?? null);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const id = open ? "transition-popper" : undefined;
  // const [path, setPath] = useState("/joblist/pending");
  const defaultPath = window.location.pathname;
  // const [path, setPath] = useState("/joblist/approved");
  const [path, setPath] = useState(defaultPath);
  const [jobs, setJobs] = useState([]);
  // const [filteredJobs,setFilteredJobs] = useState([])
  const [load, setLoad] = useState(false);
  const [filterSearch, setFilterSearch] = useState("");
  const [update, setUpdate] = useState(false);
  const [listLoad, setListLoad] = useState(false);

  const [fetchCompanyJobs, setFetchCompanyJobs] = useState()
  const [filterCompanyJobs, setFilterCompanyJobs] = useState(false)
  const [page, setPage] = React.useState(searchParams.get("page") ?? 1)
  const [totalCount, setTotalCount] = React.useState(10);
  const rowsPerPage = 21;

  

  const [searchText, setSearchText] = useState(searchTextParam ?? "");

  const { search } = useLocation();

  






  useEffect(() => {
    const data = searchParams.get("id");
    if (data) {
      setShowDetails(true);
      findJobDetails(data);
    }
  }, [searchParams]);
 


  async function findJobDetails(id) {
    const { status, data } = await getSingleJobDetails(id);
    ErrorHandlingToaster({ data, status });
    setJobDetails(data?.data_payload);
  }
  const toggleDrawer = React.useCallback(() => {
    setShowDetails(!showDetails);
    searchParams.delete("id");
    history.replace({
      search: searchParams.toString(),
    });
  }, [showDetails]);


  async function dataCaller() {
    setLoad(true);
    try {
      // const { data, status } = [CS].includes(login.role)
      //   ? await getAllJobsBasedOnCompany(login.company_id, page, rowsPerPage,searchText,searchParams.get('companyParam'))
      //   : await getAllJobs(page, 12,searchText);

      // const {data,status} = await getAllJobs(page, 12,searchText,searchParams.get('companyParam'))

      //   let companyData = await getAllCompaniesWithStatu();
      // const companies = companyData?.data?.data_payload?.filter(item => {
      //   return item.company_user_details.usertype === "COMPANY";
      // });
     
      let companyData = ![CA,CS].includes(login.role)? await getAllCompaniesWithStatu():""
         const companies = companyData?.data?.data_payload?.filter(item => {
        return item.company_user_details.usertype === "COMPANY";
      });

      // if (status === 200) {
        if(companyData?.status === 200){
      
        // setJobs(data?.data_payload);
        setCompanyList(companies)
        setLoad(false);

      }
      // ErrorHandlingToaster({ data, status });
    } catch (error) {
      setLoad(false);
    }
  }

  useEffect(() => {
    dataCaller();

  }, [update]);
  useEffect(() => {
    if(selectedCompany){
      dataCaller()
    }
    // if (!Boolean(selectedCompany)) dataCaller();
    // setUpdate((pre)=>!pre);
  }, [selectedCompany]);

  /*Testing Search functionality code start here*/
  const handleSearch = event => {
    const value = event.target.value;
    setFilterSearch(value.trim());
  };
  /*Testing Search functionality code End here*/
  const fiteredCompanies = companyList?.filter(each =>
    each.company_name.toLowerCase().includes(filterValue.toLocaleLowerCase())
  );
  const filteredJobs = jobs?.filter(item =>
    item?.job_title?.toLowerCase().includes(filterSearch?.toLowerCase())
  );





  

  function NavSelection(data) {
    switch (data) {
      case "/joblist/approved":
        return (
          <JobList
            setUpdates={setUpdate}
            jobsData={jobs}
            loader={load}
            count={totalCount}
            pages={page}
            setPages={setPage}
            fetchCompanyJobs={fetchCompanyJobs}
            filterCompanyJobs={filterCompanyJobs}
          />
        );
      case "/joblist/pending":
        return (
          <Pending
            update={update}
            setUpdate={setUpdate}
            jobs={jobs}
            load={load}
            fetchCompanyJobs={fetchCompanyJobs}
            filterCompanyJobs={filterCompanyJobs}
            setPages={setPage}
          />
        );
      case "/joblist/closed":
        return (
          <ClosedJobs setUpdate={setUpdate} jobs={jobs} load={load}
          fetchCompanyJobs={fetchCompanyJobs}
          filterCompanyJobs={filterCompanyJobs}
          setPages={setPage}
          />
        );
      case "/joblist/companies":
        return <JobsBasedOnCompanies />;
    }
  }
  let navBar = [
    {
      name: "Active",
      to: "/joblist/approved",
      logo:<CheckIcon />
    },
    {
      name: "Pending",
      to: "/joblist/pending",
      logo:<MarkIcon />
    },
    {
      name: "Closed",
      to: "/joblist/closed",
      logo:<CrossIcon />
    },
  ];
  if (login.role === ACM) {
    navBar = [
      {
        name: "Active",
        to: "/joblist/approved",
        logo:<CheckIcon />
      },
      {
        name: "Pending",
        to: "/joblist/pending",
        logo:<MarkIcon />
      },

      {
        name: "Closed",
        to: "/joblist/closed",
        logo:<CrossIcon />
      },
    ];
  }
  if (login.role === ZA) {
    navBar = [
      {
        name: "Active",
        to: "/joblist/approved",
        logo:<CheckIcon />
      },
      {
        name: "Pending",
        to: "/joblist/pending",
        logo:<MarkIcon />
      },

      {
        name: "Closed",
        to: "/joblist/closed",
        logo:<CrossIcon />
      },
      // {
      //   name: "Company",
      //   to: "/joblist/companies",
      // },
    ];
  }

  /* Search toggle effect */
  const handleSearchOpen = () => {
    setOpenSearch(!openSearch);
    setFilterSearch("");
  };
  /* Search toggle effect end */

  


  async function handleCompanyJobs({company_name,company_id}) {
 
    const currentPath = window.location.pathname;
   
   
    let pathStatus 
    if(currentPath === "/joblist/approved"){
      pathStatus = "active"
    }else if (currentPath === "/joblist/pending"){
      pathStatus = "pending"
    }else if (currentPath === "/joblist/closed"){
      pathStatus = "closed"
    }

   

    const searchParams = new URLSearchParams(search);



    // if(selectedCompany !== null){
    //   searchParams.set("companyParam","")
    //   searchParams.set("companyId","")
    //   history.push({
    //     search: searchParams.toString(),
    //   });
    // }else {
    //   searchParams.set("companyParam",company_name)
    //   searchParams.set("companyId",company_id)
    //   history.push({
    //     search: searchParams.toString(),
    //   });
    // }

    if(company_id !== selectedCompany){
      searchParams.set("companyParam",company_name)
      searchParams.set("companyId",company_id)
      history.push({
        search: searchParams.toString(),
      });
    }else {
      searchParams.set("companyParam","")
      searchParams.set("companyId","")
      history.push({
        search: searchParams.toString(),
      });
    }

    setSelectedCompany(pre => (pre === company_id ? null : company_id));

   
    setAnchorEl(null);
    setLoad(true);

    setFilterCompanyJobs(!filterCompanyJobs)
/* in progerss */
setFetchCompanyJobs({companyId:company_name,activePath:pathStatus})
/* in progress */


    // try {
    //   const { data } = await getAllJobsBasedOnCompany(company_id,pathStatus,page,10);
    //   setJobs(data?.job_post_details);
    //   setLoad(false);
    // } catch (error) {
    //   toast.error(error.message, "Something went wrong");
    //   setLoad(false);
    // }

  }





  const debouncedQuery = useDebounce(searchText, 500);  
  useEffect(() => {
      const searchParams = new URLSearchParams(search);
      searchParams.set("searchParam", debouncedQuery);
      searchParams.set("page",1)
      history.push({
        search: searchParams.toString(),
      }); 
  }, [debouncedQuery]);


  useEffect(()=>{
  
  const searchParams = new URLSearchParams(search);
  searchParams.set("searchParam", debouncedQuery);
  searchParams.set("page",page)
  history.push({
    search: searchParams.toString(),
  });
},[page])

  useEffect(()=>{
setPath(defaultPath)
  },[defaultPath])

  return (
    // <div className="dashboard_wrapper">
    <React.Fragment style={{marginLeft:"0px"}}>
      <div className="page-content position-sticky top-0" style={{width:"98%"}}>
        <Container fluid>
          {login.role !== "ZM" && login.role !== "SP" && login.role !== "TE"  && login.role !== "MODERATOR" && login.role !== "RC" &&  login.role !== "RS" ? (
            <Row className="header_jobs_sticky" style={{backgroundColor:"rgba(252, 254, 255, 1)",top:55}}>
              <div className="tab_bar">
                <Tabs
                  sx={{
                    // height: "20px",
                    // borderRadius: "8px",
                 
                  }}
                  value={path}
                  // TabIndicatorProps={StyledLoginTabsObject}
                  onChange={(_, to) => {
                    setPath(to);
                    setFetchCompanyJobs({})
                    setSearchText('')
                    history.push(to);
                  }}
                  centered
                >
                  {navBar.map(({ name, to,logo }, index) => {
                    return (
                      <SolidTab
                    //  icon={logo}
                      iconPosition="start"
                        value={to}
                        label={name}
                        key={index.toString()}
                      />
                    );
                  })}
                </Tabs>
                {/* {navBar.map(({ name, to }, index) => {
                    return (
                      <React.Fragment key={index.toString()}>
                        {path === to ? (
                          <PrimaryButton
                            sx={{ width: "100px" }}
                            onClick={() => {
                              history.push(to);
                            }}
                            key={index.toString()}
                          >
                            {name}
                          </PrimaryButton>
                        ) : (
                          <PrimaryTextButton
                            sx={{ width: "100px" }}
                            onClick={() => {
                              history.push(to);
                            }}
                            key={index.toString()}
                          >
                            {name}
                          </PrimaryTextButton>
                        )}
                      </React.Fragment>
                    );
                  })} */}


              

                <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <OutlinedInput 
                   sx={{
                    borderRadius: "8px",
                    position: "relative",
                    height:"45px",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    border: `0.8px solid rgba(221, 221, 221, 1)`,
                    fontSize: 16,
                    minWidth: "250px",
                    padding: "7px 12px",
                    fontWeight:400,
                    "&:focus": {
                      border: `0.8px solid rgba(221, 221, 221, 1)`,
                    },
                   }}

                   onChange={(e) => {
                    e.stopPropagation();
                    setSearchText(e.target.value);
                  }}

                      placeholder="Search for jobs.."
                      id="outlined-adornment-weight"
                      startAdornment={<InputAdornment position="start"><JobSearchIcon /></InputAdornment>}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                      value={searchText}
                      defaultValue={searchParams.get('searchParam') ?? searchText}
                    />

               {![CS,CA].includes(login?.role)?
                  <FilterButton 
                  endIcon={
                    selectedCompany !== null || companyTextParam?(<Badge color="success" badgeContent={1} showZero>
                      <JobFilterIcon />
                     </Badge>):  
                      <JobFilterIcon />
                   
                  }
                   onClick={e => setAnchorEl(e.currentTarget)}>
                   Filter By Company  
                  </FilterButton> 
              :""}
                  
                </Stack>


              </div>
            </Row>
          ) : (
            <Stack
              alignItems={"center"}
              justifyContent={"flex-end"}
              direction={"row"}
              spacing={1.6}
            >
              {![CA, CS].includes(login.role) ? (
                // <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
                //   {/* <FeatherIcon icon={"filter"} size={15} /> */}
                // </IconButton>


                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <OutlinedInput 
                     sx={{
                      borderRadius: "8px",
                      position: "relative",
                      height:"45px",
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      border: `0.8px solid rgba(221, 221, 221, 1)`,
                      fontSize: 16,
                      minWidth: "250px",
                      padding: "7px 12px",
                      fontWeight:400,
                      "&:focus": {
                        border: `0.8px solid rgba(221, 221, 221, 1)`,
                      },
                     }}
  
                     onChange={(e) => {
                      e.stopPropagation();
                      setSearchText(e.target.value);
                    }}
  
                        placeholder="Search for jobs.."
                        id="outlined-adornment-weight"
                        startAdornment={<InputAdornment position="start"><JobSearchIcon /></InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          'aria-label': 'weight',
                        }}
                      />
  
                    <FilterButton 
                    endIcon={
                  
                      selectedCompany !== null || companyTextParam?(<Badge color="success" badgeContent={1} showZero>
                        <JobFilterIcon />
                       </Badge>):  
                        <JobFilterIcon />
                     
                    }
                    
  
                     onClick={e => setAnchorEl(e.currentTarget)}>
                     Filter By Company  
                    </FilterButton> 
             
                    
                  </Stack>

              ) : null}
            </Stack>
          )}
          <Row
            style={{
              marginTop: "12px",
            }}
          >
            {NavSelection(path)}
          </Row>
        </Container>
      </div>
      {/* <DetailsDrawer
        jobDetails={jobDetails}
        toggleDrawer={toggleDrawer}
        open={showDetails}
      /> */}
      <JobViewModal 
         jobDetails={jobDetails}
         toggle={toggleDrawer}
         isOpen={showDetails}
      />




<PrimaryPopUp
  id={id}
  open={open}
  PaperProps={MenuProps}
  anchorEl={anchorEl}
  onClose={() => setAnchorEl(null)}
  anchorOrigin={{
    vertical: "top",
    horizontal: "center",
  }}
>
  {/* Fixed Input Field */}
  <StageSearchInput
    placeholder="search..."
    onChange={(e) => setFilterValue(e.target.value)}
    value={filterValue}
    sx={{ position: "sticky", top: 0, zIndex: 4 }}
    endAdornment={
      Boolean(filterValue) && (
        <IconButton
          style={{ marginLeft: 8 }}
          edge="end"
          onClick={() => setFilterValue("")}
        >
          <ClearIcon />
        </IconButton>
      )
    }
  />

  {/* Scrollable List */}
  <div
    style={{
      maxHeight: '200px', // Adjust the height based on your needs
      overflowY: 'auto',
    }}
  >
    {!listLoad ? (
      fiteredCompanies?.map((item) => (
        <div
          key={item?.id}
          className={
            selectedCompany !== item?.id
              ? classes.label
              : classes.activeLabel
          }
          // onClick={() => {
          //   // handleCompanyJobs(item?.company_name);
          //   handleCompanyJobs({
          //     company_name: item?.company_name ,
          //     company_id: item?.company_id 
          //   });
            
          // }}

          onClick={() => {
            handleCompanyJobs({
                company_name: item?.company_name,
                company_id: item?.id
            });
        }}
        
        >
          <PrimaryAvatar
            src={item?.company_image_details}
            sx={{ width: "24px !important", height: "24px !important" }}
          />
          <GreyTypography>{item?.company_name}</GreyTypography>
        </div>
      ))
    ) : (
      <CircularProgress sx={{ ml: "45%", mt: "16px" }} size={16} />
    )}
  </div>
</PrimaryPopUp>


    
    </React.Fragment>
  );
}
const mapStateToProps = state => {
  return {
    login: state.Login,
  };
};
export default withRouter(connect(mapStateToProps, null)(WholeJobs));
