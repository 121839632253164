import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  BorderCard,
  BlackTypography,
  GreyTypography,
  PrimaryButton,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  SecondaryFilledButton,
  MiddleDivider,
  PrimaryAvatar,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useForm } from "react-hook-form";
import { FormControl, Grid, Stack, Card, CardContent } from "@mui/material";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import dataNotFound from "../../../../assets/images/noDataGreen.png";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //   width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
  width: "60%",
  height:400,
  overflow:"auto"
};
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function WholeEditEducation({
  type,
  open,
  data,
  editToggleModel,
  toggleModel,
  setUpdateType,
  setUpdateIndex,
  deleteEduExperience
}) {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  //   React.useEffect(() => {
  //     if (type?.includes("update")) {
  //       setValue("title", currentData.title);
  //       setValue("employer", currentData.employer);
  //       setValue("start_date", currentData.start_date);
  //       setValue("end_date", currentData.end_date);
  //     }
  //   }, [type]);

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          editToggleModel();
          reset();
        }}
        aria-labelledby="edit-profile-modal-title"
        aria-describedby="edit-profile-modal-description"
      >
        <Box sx={style}>
          <BlackTypography variant="h2" sx={{ marginBottom: "10px" }}>
            {/* {type === "create" ? "create" : "wholeEdit"} Work Experiences */}
            Edit Education
          </BlackTypography>
          <BorderCard>
            <CardContent
              sx={{ height: "calc(100% -40px)", overflowY: "scroll",}}
            >
              <Stack spacing={3}>
                {data?.length ?data?.map(
                  ({ certificate, institute, start_date, end_date,desc,institute_image }, EdIndex) => {
                    const isLastItem = EdIndex === data.length - 1;
                    return (
                      <>
                        <Stack spacing={1} direction="row" sx={{ "&: hover": { background: "transparent" } }}>
                        <PrimaryAvatar
                            variant="square"
                            sx={{ border: "none" }}
                            src={`${institute_image}`}
                            alt={`${institute?.charAt(0, 2)}`}
                          />
                          <Stack
                            style={{ whiteSpace: "normal", width: "90%" }}
                            spacing={0.8}
                          >
                            <BlackTypography variant="h4" component="h4">
                            {certificate}
                            </BlackTypography>
                            <BlackTypography variant="h5" component="h5">
                            {institute}{" "}
                            </BlackTypography>
                            <GreyTypography variant="h6" component="h6">
                              {start_date} - {end_date}
                            </GreyTypography>
                            <BlackTypography>Description:</BlackTypography>
                    
                            <GreyTypography>{ desc}</GreyTypography>
                          
                            {!isLastItem && <MiddleDivider />}
                          </Stack>
                          {/* <PrimaryButton
                        sx={{ width: "100px" }}
                        onClick={() => {
                          toggleModel();
                          setUpdateType(`update${workExpIndex}`);
                          setUpdateIndex(workExpIndex);
                        }}
                      >
                        Edit
                      </PrimaryButton> */}
                          <Stack
                            direction={"row"}
                            spacing={1}
                            // justifyContent="flex-start"
                            // marginLeft="auto !important"
                            // alignItems="end"
                          >
                            <FeatherIcon
                              icon="edit"
                              size="16"
                              onClick={() => {
                                toggleModel();
                                setUpdateType(`update${EdIndex}`);
                                setUpdateIndex(EdIndex);
                                
                              }}
                            />
                            <FeatherIcon
                                icon="trash"
                                size="16"
                                onClick={() => {
                                  // toggleModel();
                                  deleteEduExperience(EdIndex);
                                }}
                              />
                          </Stack>
                        </Stack>
                      </>
                    );
                  }
                ) : (
                  <div style={{ textAlign: "center" }}>
                  <img
                    style={{
                      width: "350px",
                      height: "350px",
                      marginTop: 0,
                    }}
                    src={dataNotFound}
                    alt="/not-found"
                  />
                </div>
                )}
              </Stack>
            </CardContent>
           
          </BorderCard> 
          <SecondaryFilledButton
            onClick={() => {
              editToggleModel();
            }}
            sx={{width:"auto",marginLeft:"88%",marginTop:"5px"}}
          >
            Close
          </SecondaryFilledButton>
        </Box>
     
      </Modal>
    </div>
  );
}
