import React, {useState, useEffect} from 'react';
import { useHistory,useLocation } from "react-router-dom";
import CustomNoRowsOverlay from "common/data/NoRowsFoundDatagrid/NoRowsFound";
import { getAllScreeningPartnerWithStatus } from "helpers/services/screeningPartner/screeningPartner";
import getSymbolFromCurrency from "currency-symbol-map";
import LoaderIcon from "utils/icons/loaderIcon";
import { GridToolbar } from "@mui/x-data-grid";
import {Pagination } from "@mui/material";
import { imageURL } from 'common/constants/commonURLS';
import {
    MenuProps,
    PrimaryButton,
    PrimaryButtonForTable,
    PrimaryDataGrid,
    PrimaryNormalInputs,
    PrimarySwitch,
    SmallPrimaryAvatar,
    PrimaryChip,
    SmallSizeChip,
    HoldChip
  } from "assets/Mui/globalTheme";
  import TablePopper from "components/table/header/popper";
  import { getAllIndividualEarnings } from 'helpers/services/Earnings/MyEarnings';
  import SearchParamHook from "hooks/searchParams";
  import { useDebounce } from 'hooks/useDebounce';
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { StageSearchInput } from "assets/Mui/globalTheme";
import { parseNumberInput } from 'hooks/customPhoneNumberParse';

function PartnerEarnings(){
  const searchParam = SearchParamHook()
    const [earningsData, setEarningsData] = React.useState([]);
    const loginStore = JSON.parse(localStorage.getItem("authUser"));
    // const [update,setUpdate] = React.useState(false)
    // const [refetch,setRefetch] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [openSearch, setOpenSearch] = useState(true);
    const currentPage = searchParam.get('page')
    const searchTextParam = searchParam.get('searchParam')

    const [page, setPage] = React.useState(currentPage ?? 1);
    const [searchText, setSearchText] = useState(searchTextParam ?? "");
    const [totalCount,setTotalCount] = React.useState("")
  
    const rowsPerPage = 20;
    const offset = (page - 1) * rowsPerPage;
  
    const role = loginStore?.role
    
    const {search} = useLocation()
    const history = useHistory();

    React.useEffect(()=>{
      initialFetch(role,page,rowsPerPage)
    },[])
  
    // React.useEffect(() => {
    //   initialFetch(role,page,rowsPerPage);
    // }, [update]);
  
    //  React.useEffect(()=>{
    //   const searchParams = new URLSearchParams(search)
    //   searchParams.set("page",page)
    //   history.push({
    //     search: searchParams.toString(),
    //   });
    // //   initialFetch(role,page,rowsPerPage);
    // },[page])
    
    async function initialFetch(role,page,rowsPerPage,searchText) {
      setLoading(true);
      const currentPage = searchParam.get('page') ?? 1
      const searchTextParam = searchParam.get('searchParam') ?? ""
      const { data, status } = await getAllIndividualEarnings(role,currentPage,rowsPerPage,searchTextParam);
      setTotalCount(data?.total_page_size)

      const result = data?.data_payload?.length > 0 && data?.data_payload?.map((item, index) => ({
        id: item?.id,
        email: `${item?.earnings_user_job_post?.email}`,
        Image: `${imageURL}${item?.image ?? "N/A"}`,
        phone_number: `${parseNumberInput(item?.earnings_user_job_post?.phone_number) ?? "N/A"}`,
        job_title: `${item?.earnings_user_job_post?.job_post_in_user_job_post?.job_title}`,
        name: `${item?.earnings_user_job_post?.first_name} ${item?.earnings_user_job_post?.last_name}`,
        company_name:`${item?.earnings_user_job_post?.company_details?.company_name}`,
        amount: `${getSymbolFromCurrency(item?.currency)}-${item.amount}`,
      }));


      if (status === 200) {
        setLoading(false);
      }
      setEarningsData(result);
    }


    const columns=
    [
    {
      field: "image",
      headerName: "Image",
      filterable: false,
      disableColumnMenu:true,
      renderCell: params => {
        return (
          <SmallPrimaryAvatar
            imgProps={{ loading: "lazy" }}
            src={params?.formattedValue}
          >
            {params?.row?.name?.charAt(0)?.toUpperCase()}
          </SmallPrimaryAvatar>
        );
      },
    },
    { field: "name",headerName: "Name", width: 200 },
    { field: "job_title",headerName: "Job Title", width: 200 },
    { field: "phone_number",headerName: "Phone Number", width: 300 },
    { field: "email", headerName: "Email",width: 300 },
    { field: "company_name",headerName: "Company", width: 200 },
    // {
    //   field: "Status",
    //   headerName: "Status",
    //   width: 200,
    //   renderCell: params => {
    //     if (params.formattedValue !== "Approve") {
    //       return <HoldChip label={params?.formattedValue} size="small"/>;
    //     }
    //     return <PrimaryChip label={params?.formattedValue} size="small"/>;
    //   },
    // },    

   
    { field: "amount",headerName: "Earnings", width: 200 },
]

const rows = [
    {
      id: 1,
      image: "https://example.com/image1.jpg",
      name: "Job Seeker1",
      job_title:"React Developer",
      phone_number: "+1234567890",
      email: "john.doe@example.com",
      Status: "Approve",
      company_name: "ABC Inc.",
      amount: "$5000",
    },
    {
      id: 2,
      image: "https://example.com/image2.jpg",
      name: "Job Seeker2",
      job_title:"Python Developer",
      phone_number: "+9876543210",
      email: "jane.smith@example.com",
      Status: "Screened",
      company_name: "XYZ Corp.",
      amount: "$3000",
    },
    {
        id: 3,
        image: "https://example.com/image2.jpg",
        name: "Job Seeker3",
        job_title:"NodeJs Developer",
        phone_number: "+9876543210",
        email: "jane.smith@example.com",
        Status: "Onboarded",
        company_name: "XYZ Corp.",
        amount: "$3000",
      },
      {
        id: 4,
        image: "https://example.com/image2.jpg",
        name: "Job Seeker4",
        job_title:"SQL Administrator",
        phone_number: "+9876543210",
        email: "jane.smith@example.com",
        Status: "Moderated",
        company_name: "XYZ Corp.",
        amount: "$3000",
      },
      {
        id: 5,
        image: "https://example.com/image2.jpg",
        name: "Job Seeker5",
        job_title:"AWS Cloud Architect",
        phone_number: "+9876543210",
        email: "jane.smith@example.com",
        Status: "Submitted",
        company_name: "XYZ Corp.",
        amount: "$3000",
      },
   
  ];
  


  const handleSearchOpen = () => {
    setOpenSearch(!openSearch);
  };


  React.useEffect(() => {
    const searchParams = new URLSearchParams(search);
    searchParams.set("page", page);
    history.push({
      search: searchParams.toString(),
    });
   initialFetch(role,page,rowsPerPage,searchText)
  }, [page, searchParam.get("searchParam")]);

    /* seacrh */

    useEffect(() => {
      const searchParams = new URLSearchParams(search);
      setSearchText(searchParams.get("searchParam") ?? "");
      setPage(searchParams.get("page") ?? 1);
    }, []);
  
    const debouncedQuery = useDebounce(searchText, 500);
  
    useEffect(() => {
      if(debouncedQuery){
        const searchParams = new URLSearchParams(search);
        searchParams.set("searchParam", debouncedQuery);
        setPage(1)
        history.push({
          search: searchParams.toString(),
        });
      }
      else {
        const searchParams = new URLSearchParams(search);
        searchParams.set("searchParam", debouncedQuery);
        // setPage(1)
        history.push({
          search: searchParams.toString(),
        });
      }
   
  
    }, [debouncedQuery]);
  
    /* seacrh */

  
    return (
        <>
         <div style={{ padding: "10px", height: "91vh",display:'flex',flexDirection:'column'  }}> 


 <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={1.6}>
        {openSearch ? (
          <SearchIcon onClick={handleSearchOpen} />
        ) : (
          <StageSearchInput
            placeholder="search..."
            value={searchText}
            onChange={(e) => {
              e.stopPropagation();
              setSearchText(e.target.value);
            }}
            endAdornment={
              <IconButton
                edge="end"
                onClick={() => {
                  handleSearchOpen();
                  setSearchText("");
                }}
              >
                <ClearIcon />
              </IconButton>
            }
          />
        )}
       
      </Stack>

      <PrimaryDataGrid
       
        // onCellClick={handleCellClick}
        // loading={loading}
       

        columns={columns}
        rows={earningsData}
        slots={{
          loadIcon: LoaderIcon,
          baseButton: PrimaryButton,
          basePopper: TablePopper,
          baseSwitch: PrimarySwitch,
          toolbar: GridToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
          pagination: props => (
            <Pagination
              {...props}
              // color="primary"
              // count={Math.ceil(rowsData.length / rowsPerPage)}
              count={totalCount}
              page={page}
              rowsPerPage={20}
              onChange={(_, newPage) => setPage(newPage)}
              showFirstButton
              showLastButton
              sx={{
                "& .MuiPaginationItem-root": {
                  "&:hover": {
                    backgroundColor: "#cde2e7",
                    color: "#066a4c",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "#024430",
                    color: "#ffe7bb",
                    "&:hover": {
                      backgroundColor: "#cde2e7",
                      color: "#066a4c",
                    },
                  },
                },
              }}
            />
          ),
        }}
        slotProps={{
          baseButton: { style: { width: "100px" } },
          panel: {
            style: {
              right: "0px !important",
              transform: "none !important",
              inset: "none !important",
              top: "138px !important",
            },
          },
          filterPanel: {
            style: {
              right: "0px !important",
              transform: "none !important",
              inset: "none !important",
              top: "138px !important",
            },
          },
          toolbar: { style: {} },
          preferencesPanel: {
            style: {
              right: "0px !important",
              transform: "none !important",
              inset: "none !important",
              top: "138px !important",
            },
          },
        }}
      />
     
    </div>
        </>
    )
}

export default PartnerEarnings;