import React, { useEffect, useState } from "react";
import { Link, useParams, withRouter } from "react-router-dom";
import { viewSingleCompanyDetails } from "helpers/services/companies/company";
import { imageURL } from "common/constants/commonURLS";
import { Avatar, Box, CardActionArea, CardContent, Stack } from "@mui/material";
import { blue } from "@mui/material/colors";
import { BorderCard, GreyTypography } from "assets/Mui/globalTheme";
import { perks } from "common/constants/perks";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import TextDivider from "utils/textDividerPrimary";
export default function ScreeningPartnerProfile({ userInfo, setUserInfo }) {
  const [company, setCompany] = useState({});
  const [selectedPerks, setSelectedPerks] = useState([]);
  const [model, setModel] = useState(false);
  useEffect(() => {
    setCompany(userInfo);
    const data = perks?.filter(item => {
      return userInfo?.perks?.includes(item?.name);
    });
    setSelectedPerks(data);
  }, [userInfo]);
  function toggleModel() {
    setModel(!model);
  }
  return (
    <Stack spacing={2} sx={{ padding: "10px 0px" }}>
      <TextDivider type="gender" />
      <BorderCard>
        <CardActionArea>
          <CardContent>
            <GreyTypography variant="h6" component="h6">
              {company?.gender ?? "N/A"}
            </GreyTypography>
          </CardContent>
        </CardActionArea>
      </BorderCard>

      <TextDivider type="About" />
      <BorderCard>
        <CardActionArea>
          <CardContent>
            <GreyTypography variant="h6" component="h6">
              {company?.about ?? "N/A"}
            </GreyTypography>
          </CardContent>
        </CardActionArea>
      </BorderCard>

      {userInfo?.work_mode &&
      <>   
      <TextDivider type="Work Mode" />
      <BorderCard>
        <CardActionArea>
          <CardContent>
            <GreyTypography variant="h6" component="h6">
              {userInfo?.work_mode?.replace("_", " ")}
            </GreyTypography>
          </CardContent>
        </CardActionArea>
      </BorderCard></>
      }

      {company?.location && 
      <>
      <TextDivider type="Location" />
      <BorderCard>
        <CardActionArea>
          <CardContent>
            <GreyTypography variant="h6" component="h6">
              {company?.location}
            </GreyTypography>
          </CardContent>
        </CardActionArea>
      </BorderCard>
      </>
}

      <TextDivider type="Category" />
      <BorderCard>
        <CardActionArea>
          <CardContent>
            {userInfo?.functional_area_details?.map(item => (
              <GreyTypography variant="h6" component="h6">
                {item?.name ?? "N/A"}
              </GreyTypography>
            ))}
          </CardContent>
        </CardActionArea>
      </BorderCard>
      
      {userInfo?.industry_details?.length > 0?
      <>
      <TextDivider type="Industry" />
      <BorderCard>
        <CardActionArea>
          <CardContent>
            {userInfo?.industry_details?.map(item => (
              <GreyTypography variant="h6" component="h6">
                {item?.name}
              </GreyTypography>
            ))}
          </CardContent>
        </CardActionArea>
      </BorderCard>
      </>
      :""}

      {/* <TextDivider type="Job Role" />
      <BorderCard>
        <CardActionArea>
          <CardContent>
            {userInfo?.job_roles_details?.map(item => (
              <GreyTypography variant="h6" component="h6">
                {item?.name ?? "N/A"}
              </GreyTypography>
            ))}
          </CardContent>
        </CardActionArea>
      </BorderCard> */}

    </Stack>
  );
}
