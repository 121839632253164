import {
  FormControl, Stack,
  Grid,
  InputLabel,
  Paper,
  Box
} from "@mui/material";
import React, { useEffect, useState } from 'react';
import { GreyTypography, PrimaryButton, PrimaryNormalFormHelperText, PrimaryNormalInputLabel, PrimaryOutlinedOpacitedButton, PrimaryTypography } from "assets/Mui/globalTheme";
import {
  BlackTypography,
  GrayDivider,
  GrayLiniearDivider,
  PrimaryDivider,
  SecondaryFilledButton,
} from "assets/Mui/globalTheme";
import { Form } from "reactstrap";
import PhoneInput from "react-phone-number-input";
// import "react-phone-number-input/style.css";
import "../../assets/css/auth/signup.css"
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import BasicModal from "components/Common/models/otpModel";
import MobileOtp from "./mobileOtp";
import { sendOtp } from "helpers/services/otpVerifiy/verify";
import { toast } from "react-hot-toast";
import { parseNumberInput } from "hooks/customPhoneNumberParse";
import { getSectionValueNumericBoundaries } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
const useStyles = makeStyles({
  inputField: {
    display: "flex",
    justifyContent: 'space-between',
    alignItems: "center",
    marginBlock: "16px"
  },
  error: {
    color: "red"
  }
})
export default function MobileNumber({ reUpdate, currentNumber }) {
  const classes = useStyles()


  const [viewOtp, setViewOtp] = useState(false)
  const { register, setValue, setError, watch, handleSubmit, getValues, clearErrors, reset, formState: { errors } } = useForm({ shouldUnregister: true })

  const handleForm = async data => {

    if (!data?.cur_mobile?.split(" ")[1] || data?.cur_mobile?.split(" ")[1] === "") {
      setError("cur_mobile", {
        type: "custom",
        message: "Current Phone Number is required",
      });
      return;
    }
    if (!data?.new_mobile?.split(" ")[1] || data?.new_mobile?.split(" ")[1] === "") {
      setError("new_mobile", {
        type: "custom",
        message: "New Phone Number is required",
      });
      return;
    }
    if (!data?.confirm_mobile?.split(" ")[1] || data?.confirm_mobile?.split(" ")[1] === "") {
      setError("confirm_mobile", {
        type: "custom",
        message: "Confirm Phone Number is required",
      });
      return;
    }
    if (data?.confirm_mobile !== data?.new_mobile) {
      setError("confirm_mobile", {
        type: "validate",
        message: "New Phone Number and Confirm Phone Number should match",
      });
      return;
    }

    // const phone_number = parseNumberInput(`${data?.new_mobile}`)
    // const old_phone_number = parseNumberInput(`${data?.cur_mobile}`)
    
    const phone_number = data?.new_mobile?.replaceAll(" ","")
    const old_phone_number = data?.cur_mobile?.replaceAll(" ","")
    const res = await sendOtp("mobile", phone_number, old_phone_number)

    if (res?.status === 200) {
      setViewOtp(true)
      toast.success(res?.data?.return_message ? res?.data?.return_message : "Successfully  sent");
    }

  }


  /*Mobile Number Extraction with zero spaces start*/
  const handleChangeNum = newPhone => {

    setValue("new_mobile", newPhone);
    clearErrors("new_mobile");

  };

  const handleChangeOldNum = oldNum => {

    setValue("cur_mobile", oldNum);
    clearErrors("cur_mobile");

  };

  const handleChangeConfNum = confNum => {

    setValue("confirm_mobile", confNum);
    clearErrors("confirm_mobile");

  };
  /*Mobile Number Extraction with zero spaces End*/





  return (
    <>
      <Paper elevation={"0"} sx={{ padding: "20px" }}>
        <BlackTypography className="mb-2" variant="h2">
          Update Phone Number
        </BlackTypography>
        <GreyTypography variant="h6" className="mb-4">
          Please enter your current number to change your number
        </GreyTypography>
        <form
          onSubmit={handleSubmit(data => handleForm(data))}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <InputLabel> Current Number</InputLabel>
            </Grid>
            <Grid item xs={6}>
              <PhoneInput
                id="cur_mobile_signup-phone-number"
                labelId="cur_mobile_signup-phone-number-label"
                international
                countryCallingCodeEditable={false}
                required 
                {...register("cur_mobile", { required: "Current phone number is required" })}
                className={`input_phone ${errors?.cur_mobile ? "error_phone" : ""}`}
                style={{ marginTop: "20px" }}
                name="cur_mobile"
                onChange={(e) => handleChangeOldNum(e)}
                value={getValues("cur_mobile")}
                error={errors.cur_mobile}
                defaultCountry="IN"
                placeholder="Current Phone number"
              />

              <PrimaryNormalFormHelperText error={errors?.cur_mobile}>
                {errors?.cur_mobile?.type === "custom" ? "Current Phone Number is required" : errors?.cur_mobile?.message}
              </PrimaryNormalFormHelperText>
            </Grid>
            <Grid item xs={6}>
              <InputLabel>New Number</InputLabel>
            </Grid>
            <Grid item xs={6}>
              <PhoneInput
                id="new_mobile_signup-phone-number"
                labelId="new_mobile_signup-phone-number-label"
                international
                countryCallingCodeEditable={false}
                required
                {...register("new_mobile", { required: "Phone number is required" })}
                className={`input_phone ${errors?.new_mobile ? "error_phone" : ""}`}
                style={{ marginTop: "20px" }}
                name="new_mobile"
                error={errors.new_mobile}
                onChange={(e) => handleChangeNum(e)} 
                value={getValues("new_mobile")}
                defaultCountry="IN"
                placeholder="New Phone number"
              />

              <PrimaryNormalFormHelperText error={errors?.new_mobile}>
                {errors?.new_mobile?.type === "custom" ? "New Phone Number is required" : errors?.new_mobile?.message}
              </PrimaryNormalFormHelperText>

            </Grid>
            <Grid item xs={6}>
              <InputLabel>Confirm New Number</InputLabel>
            </Grid>
            <Grid item xs={6}>
              <PhoneInput
                id="confirm_mobile_signup-phone-number"
                labelId="confirm_mobile_signup-phone-number-label"
                international
                countryCallingCodeEditable={false}
                required 
                {...register("confirm_mobile", { required: "Confirm phone number is required" })}
                className={`input_phone ${errors?.confirm_mobile ? "error_phone" : ""}`}
                style={{ marginTop: "20px" }}
                name="confirm_mobile"
                error={errors.confirm_mobile}
                onChange={(e) => handleChangeConfNum(e)} 
                value={getValues("confirm_mobile")}
                defaultCountry="IN"
                placeholder="Confirm Phone number"
              />

              <PrimaryNormalFormHelperText error={errors?.confirm_mobile}>
                {errors?.confirm_mobile?.type === "custom" ? "Confirm Phone Number is required" : errors?.confirm_mobile?.type === "validate" ? "New Password and Confirm Password should match" : errors?.cpassword?.message}
              </PrimaryNormalFormHelperText>
            </Grid>
            <Stack
              sx={{ width: "100%", marginTop: "20px" }}
              alignItems="center"
              direction="row"
              justifyContent={"flex-end"}
              spacing={2}
            >
              <PrimaryButton type="submit" sx={{ width: "200px" }}>
                Save Changes
              </PrimaryButton>
            </Stack>
          </Grid>
        </form>
      </Paper>
      {viewOtp ?
        <MobileOtp reUpdate={reUpdate} isOpen={viewOtp} phone_number={watch("new_mobile")} toggle={() => {setViewOtp(false), reset()}} /> : null
      }
    </>
  )
}