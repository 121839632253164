import React from "react";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import "./JobList.css";
export const CloseJobModel = props => {
  const handleSubmit = () => {
    props.onSubmit && props.onSubmit(true);
  };

  const toggles = () => {
    props.onHide();
  };
  return (
    <div>
      <Modal
        isOpen={props.params.modal}
        toggle={props.onHide}
        className="models-main"
      >
        <ModalHeader
          toggle={props.onHide}
          style={{ fontSize: "12px" }}
          className="models-header"
        >
          <h4 style={{ textAlign: "center" }}>
            Are you sure? Do you want to close this job?
          </h4>
        </ModalHeader>
        <ModalBody>
          <div className="models-body-main">
            <div id="job-close-model-image-cont">
              <img
                src="https://cdn4.iconfinder.com/data/icons/basic-ui-2-line/32/exclamation-mark-triangle-sign-caution-512.png"
                alt=""
              />
            </div>
            <div id="job-close-model-btton-cont">
              <Button
                color="success"
                onClick={() => {
                  toggles(); handleSubmit();
                }}
              >
                Yes
              </Button>
              <Button color="danger" onClick={toggles}>
                No
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
