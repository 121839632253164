import React, { Component, lazy, useEffect, useState } from "react";
import titleCaller from "components/Common/title";
import "../../assets/css/auth/forget_password.css";
import {
  FormControl,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import ResetPasswordImage from "../../assets/images/zepul/reset_password.svg";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { resetPassword } from "helpers/services/auth";
// import {useNavigate} from 'react-router-dom';
import { Link, useLocation } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {
  PrimaryButton,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
} from "assets/Mui/globalTheme";
import { toast } from "react-hot-toast";
export default function ResetPassword() {
  const [email, setEmail] = useState("");


  useEffect(() => {
    titleCaller("Joinee");
  }, []);
  const [showPassword, setShowPassword] = useState(false);
  const [cShowPasswrod, setCShowPassword] = useState(false);
  const [cpassworError, setCpasswordError] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    unregister,
    setError,
    formState: { errors },
    setValue,
  } = useForm();
  const { search } = useLocation();
  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const token = urlParams.get("token");
    setValue("token", token);
  }, [search]);
  async function handleData(data) {
    if (data.password !== data.cpassword) {
      setError('cpassword', { type: 'custom', message: 'confirm password should match with new password' })
      return
    }
      const res = await resetPassword({
        token: data?.token,
        password: data?.password,
      });
      if (res?.status === 200) {
        toast.success("password is updated");
        navigate("/login")
        history.push("/login");
      }  
  }
  function handleShowCPassword() {
    setCShowPassword(!cShowPasswrod);
  }
  function handleShowPassword() {
    setShowPassword(!showPassword);
  }
  return (
    <React.Fragment>
      <div className="forget_password_container">
        <Form
          className="forget_password_elem_container"
          onSubmit={handleSubmit(handleData)}
        >
          <img
            src={ResetPasswordImage}
            alt="forget_password"
            className="forget_password_image"
          />
          <Typography variant="h1" component="h1">
            Reset your password?
          </Typography>
          <Typography color="neutral" level="h6">
            Please enter the email address associated with your account and We
            will email you a link to reset your password.
          </Typography>
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="login-last-name-outlined"
              error={errors.password}
            >
             New Password
            </PrimaryNormalInputLabel>
            <PrimaryNormalInputs
              {...register("password", { required: true,
                validate: (value) => !/\s/.test(value),
                minLength: {
                    value: 5,
                    message: "Password must be at least 5 characters",
                },
                maxLength: {
                    value: 15,
                    message: "Password must not exceed 15 characters",
                },
                pattern: {
                    value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{5,10}$/,
                    message: "Password must contain at least one letter, one number, and one special character. No spaces allowed.",
                },

              })}
              id="password-outlined"
              autoFocus
              label="Password"
              type={showPassword ? "text" : "password"}
              error={errors.password}
              endAdornment={
                <InputAdornment
                  sx={{
                    position: "absolute",
                    right: "10px",
                  }}
                  position="end"
                  onClick={handleShowPassword}
                >
                  {showPassword ? (
                    <FeatherIcon icon="eye" size={"14"} />
                  ) : (
                    <FeatherIcon icon="eye-off" size={"14"} />
                  )}{" "}
                </InputAdornment>
              }
            />
            <PrimaryNormalFormHelperText error={errors.password}>
              {errors.password ? errors.password.message : ""}
            </PrimaryNormalFormHelperText>
          </FormControl>
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="login-last-name-outlined"
              error={errors.cpassword}
            >
              Confirm Password
            </PrimaryNormalInputLabel>
            <PrimaryNormalInputs
              {...register("cpassword", { required: true })}
              id="cpassword-outlined"
              label="confirm password"
              type={cShowPasswrod ? "text" : "password"}
              error={errors.cpassword || cpassworError}
              onChange={e => {
                if (e.target.value !== getValues("password")) {
                  setCpasswordError(true);
                } else {
                  setCpasswordError(false);
                }
              }}
              variant="outlined"
              endAdornment={
                <InputAdornment
                  sx={{
                    position: "absolute",
                    right: "10px",
                  }}
                  position="end"
                  onClick={handleShowCPassword}
                >
                  {cShowPasswrod ? (
                    <FeatherIcon icon="eye" size={"14"} />
                  ) : (
                    <FeatherIcon icon="eye-off" size={"14"} />
                  )}
                </InputAdornment>
              }
            />
            {errors.cpassword && <PrimaryNormalFormHelperText error={errors.cpassword}>
              {errors.cpassword.message || "confirm your new password"}
            </PrimaryNormalFormHelperText>}
          </FormControl>{" "}
          <PrimaryButton type="submit">Change Password</PrimaryButton>
          <Stack direction="row" alignItems="center">
            <FeatherIcon icon="chevron-left" size="14" />
            <Link className="link_navigation" to="/login">
              Return to Sign In
            </Link>
          </Stack>
        </Form>
      </div>
    </React.Fragment>
  );
}
