import { Badge, Box, Grid, Modal, Stack } from "@mui/material";
import {
  GreyTypography,
  PrimaryBadge,
  PrimaryButton,
  PrimaryTypography,
  SuccessBadge,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { getPreSignedURL } from "helpers/services/pre_signed_urls/upload";
import { useState} from "react";
export default function AttachmentPreview({
  attachmentModel,
  handleAttachmentModel,
  attachment,
  handleSendMessage,
  imagePreviews
}) {
  const [index, setIndex] = useState(0);
  const [at,setAt] = useState([])
  async function handleImage(){
    // const imageMsg = await getPreSignedURL(attachment)
    const imageMsg = await getPreSignedURL(imagePreviews)
    handleSendMessage(imageMsg)
    handleAttachmentModel()

  }
  return (
    <>
      {attachment?.length > 0 ? (
        <Modal open={attachmentModel} onClose={handleAttachmentModel}>
          <Box style={{ ...style }}>
            <PrimaryTypography
              sx={{ alignSelf: "flex-start", padding: "10px 0px 0px 10px" }}
              variant="h4"
              component="h4"
            >
              {attachment[index]?.name}
            </PrimaryTypography>
            {attachment[index]?.preview !== null ? (
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{ height: "70%" }}
              >
                <img
                  style={{
                    maxWidth: "600px",
                    maxHeight: "300px",
                  }}
                  loading="lazy"
                  src={`${attachment[index]?.preview}`}
                />
                <GreyTypography
                  sx={{ marginTop: "20px" }}
                  vaiant="h4"
                  component="h4"
                >
                  {attachment[index]?.size}
                </GreyTypography>
              </Stack>
            ) : (
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{ height: "70%" }}
              >
                <Box
                  sx={{
                    background: "rgba(3,27,87,0.1)",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <FeatherIcon icon="file-text" size="48" />
                </Box>
                <PrimaryTypography
                  sx={{ marginTop: "20px" }}
                  vaiant="h4"
                  component="h4"
                >
                  No Preview Available
                </PrimaryTypography>
                <GreyTypography
                  sx={{ marginTop: "20px" }}
                  vaiant="h4"
                  component="h4"
                >
                  {attachment[index]?.size}
                </GreyTypography>
              </Stack>
            )}
            <Grid container>
              <Grid
                item
                xs={10}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent={"center"}
                  sx={{ marginBottom: "10px" }}
                >
                  {attachment?.map((item, attachmentIndex) => {
                    return (
                      <>
                        {item?.preview !== null ? (
                          <Box
                            onClick={() => setIndex(attachmentIndex)}
                            sx={{
                              width: "50px",
                              height: "50px",
                              overflow: "hidden",
                              border: "0.5px solid rgba(0,0,0,0.5)",
                              borderRadius: "8px",
                              ...(attachmentIndex === index && {
                                border: "2px solid rgb(3,23,83) !important",
                              }),
                            }}
                          >
                            <img
                              style={{
                                width: "50px",
                                borderRadius: "8px",
                                height: "50px",
                                objectFit: "cover",
                              }}
                              loading="lazy"
                              src={`${item?.preview}`}
                            />
                          </Box>
                        ) : (
                          <>
                            <Box
                              onClick={() => setIndex(attachmentIndex)}
                              sx={{
                                width: "50px",

                                height: "50px",
                                display: "grid",
                                placeItems: "center",
                                border: "0.5px solid rgba(0,0,0,0.5)",
                                borderRadius: "8px",
                                ...(attachmentIndex === index && {
                                  border: "2px solid rgb(3,23,83) !important",
                                }),
                              }}
                            >
                              <FeatherIcon icon="file-text" size="24" />
                            </Box>{" "}
                          </>
                        )}
                      </>
                    );
                  })}
                </Stack>
              </Grid>
              <Grid item xs={2}>
                <PrimaryBadge badgeContent={attachment?.length}>
                  <PrimaryButton onClick={handleImage} sx={{ width: "50px" }}>
                    <FeatherIcon icon="send" size="24" />
                  </PrimaryButton>
                </PrimaryBadge>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  display: "flex",
  alignItems: "center",
  marginBottom: "10px",
  justifyContent: "space-between",
  flexDirection: "column",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: "80vh",
  borderRadius: "8px",
  background: "white",
  bgcolor: "white",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
