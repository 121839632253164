import { withRouter } from "react-router-dom";
import React from "react";
import { toast } from "react-hot-toast";
import { Button, Label, FormGroup } from 'reactstrap';
import { PrimaryButton, SecondaryFilledButton } from "assets/Mui/globalTheme";
import {
  TextField,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import { Modal } from "reactstrap";
import { useForm, Controller } from "react-hook-form";

function CommonModel(props) {

  const { headTag, isOpen, toggle, purpose,setViewAddModal,viewAddModal } = props;
  const { control, handleSubmit, setValue,setError,clearErrors } = useForm();
  const onSubmit = (data) => {
    props.update(
      {
        name: data.text,
        is_active: true,
        id:props?.id
      },
      props.purpose,
      props.index,
      props.id
    );
    toast.success(`${props.purpose}d`);
    toggle(!props.isOpen);
  };

  const handleInput = (e) => {
    setValue("text", e.target.value);
  };

  React.useEffect(() => {
    if(purpose == "Edit"){
      setValue("text", props.title);
    }else{
      setValue("text","")
    }
 
  }, [props.title,purpose]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static" keyboard={false}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {purpose} {headTag}
        </h5>
        <button
          type="button"
          onClick={() => {
            clearErrors()
            setViewAddModal(!viewAddModal)
            toggle(!isOpen);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="text"
            control={control}
            defaultValue={props.title || ""}
            rules={{
              required: "This field is required",
              minLength: { value: 1, message: "Input must be at least 1 character long" },
            }}
            render={({ field, fieldState }) => (
              <TextField
                label={`${purpose} ${headTag}`}
                className="form-control"
                value={field.value}
                onChange={(e) => {
                  field.onChange(e);
                  handleInput(e);
                }}
                placeholder={`Enter ${headTag} Name`}
                type="text"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
              />
            )}
          />
        </form>
      </div>
      <div className="modal-footer">
        <SecondaryFilledButton
          sx={{ width: "100px", marginRight: "10px" }}
          type="button"
          onClick={() => {
            clearErrors()
            setViewAddModal(!viewAddModal)
            toggle(!isOpen);
          }}
        >
          Close
        </SecondaryFilledButton>
        <PrimaryButton
          sx={{ width: "150px" }}
          type="button"
          onClick={handleSubmit(onSubmit)}
        >
          Save changes
        </PrimaryButton>
      </div>
    </Modal>
  );
}

export default withRouter(CommonModel);
