import React, { useState, useEffect } from "react";
import { Col, Label, Row } from "reactstrap";

import "../../../../assets/css/jobpost.css";
import { connect } from "react-redux";
import { updateJob, updateJobType } from "store/jobs/actions";
import { JobTypeInfo } from "./JobTypesInfo/JobTypeInfo";
import SalaryBudget from "./Employee/SalaryBudget";
import ContractTimeInWeek from "./Contractor/ContractTimeInWeek";
import { jobModes } from "common/constants/options";
import { FormControl, MenuItem, Select } from "@mui/material";
import {
  MenuProps,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
} from "assets/Mui/globalTheme";
const exp = [
  { name: "1", value: "1" },
  { name: "2", value: "2" },
  { name: "3", value: "3" },
  { name: "4", value: "4" },
  { name: "5", value: "5" },
  { name: "6", value: "6" },
  { name: "7", value: "7" },
  { name: "8", value: "8" },
  { name: "9", value: "9" },
  { name: "10+", value: "10+" },
];

const min_exp_months_val = [
  { name: "0", value: "0" },
  { name: "1", value: "1" },
  { name: "2", value: "2" },
  { name: "3", value: "3" },
  { name: "4", value: "4" },
  { name: "5", value: "5" },
  { name: "6", value: "6" },
  { name: "7", value: "7" },
  { name: "8", value: "8" },
  { name: "9", value: "9" },
  { name: "10", value: "10" },
  { name: "11", value: "11" },
];

function SelectJobType({ job_type, updateJob, error, min_exp, max_exp,min_exp_months,max_exp_months }) {
  const [modelData, setModelData] = useState({
    modal: false,
    details: {},
  });
  function setAdditionalData(data) {
    switch (data) {
      case "Full_Time":
        return <SalaryBudget error={error} type="Annual" />;
      default:
        return <ContractTimeInWeek error={error} />;
    }
  }

  const handleSelect = (id, data) => {
    updateJob({ job_type: data });
    if (data == "Full_Time") {
      updateJob({ hours_per_week: "less_than_30" });
    }
    sessionStorage.setItem("job_post", JSON.stringify(data));
  };
  return (
    <div>
      {modelData ? (
        <JobTypeInfo
          setModelData={doc => setModelData(doc)}
          show={modelData.modal}
          onHide={() => {
            setModelData({ modal: false, details: {} });
          }}
          params={modelData}
        />
      ) : null}
      <Row>
        <Col xs={12}>
          <Label sm={2}>
            Select Your Job Type <span className="red">*</span>
          </Label>
        </Col>
        {jobModes.map(
          ({ img, name, infotext, modelText, modelhed, data }, index) => {
            return (
              <Col lg={3} md={4} xs={12} sm={4} className="" key={index}>
                <div
                  style={{
                    transition: "0.4s ease-in-out",
                  }}
                  className={`job_types_selection ${
                    job_type === data ? "selected_job_type" : " "
                  }`}
                  onClick={() => {
                    handleSelect(index, data);
                  }}
                >
                  <img src={img} className="md_img" />
                  <h5>{name}</h5>
                  <label style={{ color: "gray", fontSize: "10px" }}>
                    {infotext}
                  </label>
                  <button
                    className={`info_job_type`}
                    onClick={() =>
                      setModelData({
                        modal: true,
                        details: { modelhed: modelhed, modelText: modelText },
                      })
                    }
                  >
                    Learn More
                  </button>
                  <button
                    className={`select_for_job_type  ${
                      job_type === data ? "selected_for_job_type" : " "
                    }`}
                  >
                    {job_type === data ? "Selected" : "Select"}
                  </button>
                </div>
              </Col>
            );
          }
        )}
        <Col md={12}>{setAdditionalData(job_type)}</Col>
        <Col md={6} lg={6} spacing={1}>
          <FormControl sx={{ width: "100%" }}>
            <PrimaryNormalInputLabel
              shrink
              sx={{
                left: "-12px",
                top: "10px",
              }}
              id="signup-user-type"
            >
              Minimum Exp(years)
            </PrimaryNormalInputLabel>
            <Select
              labelId="min_exp"
              name="min_exp"
              value={min_exp}
              onChange={e => {
                updateJob({ min_exp: e.target.value });
              }}
              id="demo-multiple-name"
              input={<PrimaryNormalInputs label="min_exp-label" />}
              MenuProps={MenuProps}
            >
              {exp?.map(item => {
                return (
                  <MenuItem
                    key={item?.value}
                    value={item?.value} // style={getStyles(name, personName, theme)}
                  >
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Col>

        <Col md={6} lg={6}>
          <FormControl sx={{ width: "100%" }}>
            <PrimaryNormalInputLabel
              shrink
              sx={{
                left: "-12px",
                top: "10px",
              }}
              id="signup-user-type"
            >
              Minimum Exp(months)
            </PrimaryNormalInputLabel>
            <Select
              labelId="min_exp_months"
              name="min_exp_months"
              value={min_exp_months}
              onChange={e => {
                updateJob({ min_exp_months: e.target.value });
              }}
              id="demo-multiple-name"
              input={<PrimaryNormalInputs label="min_exp-label" />}
              MenuProps={MenuProps}
            >
              {min_exp_months_val?.map(item => {
                return (
                  <MenuItem
                    key={item?.value}
                    value={item?.value} // style={getStyles(name, personName, theme)}
                  >
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Col>


        <Col md={6} lg={6}>
          <FormControl sx={{ width: "100%" }}>
            <PrimaryNormalInputLabel
              shrink
              sx={{
                left: "-12px",
                top: "10px",
              }}
              id="signup-user-type"
            >
              Maximum Exp(years)
            </PrimaryNormalInputLabel>
            <Select
              labelId="max_exp"
              name="max_exp"
              value={max_exp}
              onChange={e => {
                updateJob({ max_exp: e.target.value });
              }}
              // labelId="selct-user-type"
              id="demo-multiple-name"
              input={<PrimaryNormalInputs label="min_exp-label" />}
              MenuProps={MenuProps}
            >
              {exp?.map(item => {
                return (
                  <MenuItem
                    key={item?.value}
                    value={item?.value} // style={getStyles(name, personName, theme)}
                  >
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Col>

        <Col md={6} lg={6}>
          <FormControl sx={{ width: "100%" }}>
            <PrimaryNormalInputLabel
              shrink
              sx={{
                left: "-12px",
                top: "10px",
              }}
              id="signup-user-type"
            >
              Maximum Exp(months)
            </PrimaryNormalInputLabel>
            <Select
              labelId="max_exp_months"
              name="max_exp_months"
              value={max_exp_months}
              onChange={e => {
                updateJob({ max_exp_months: e.target.value });
              }}
              // labelId="selct-user-type"
              id="demo-multiple-name"
              input={<PrimaryNormalInputs label="min_exp-label" />}
              MenuProps={MenuProps}
            >
              {min_exp_months_val?.map(item => {
                return (
                  <MenuItem
                    key={item?.value}
                    value={item?.value} // style={getStyles(name, personName, theme)}
                  >
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = ({ Jobs }) => ({
  job_type: Jobs.job_type,
  min_exp: Jobs.min_exp,
  min_exp_months: Jobs.min_exp_months,
  max_exp: Jobs.max_exp,
  max_exp_months: Jobs.max_exp_months,
});

const mapDispatchToProps = dispatch => ({
  updateJobType: e => dispatch(updateJobType(e)),
  updateJob: e => dispatch(updateJob(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectJobType);
