import callApi from "helpers/callApi";

const updateUserJobPost = async (id, data,jobPostId ,type) => {
  let user_job_post_id = jobPostId?jobPostId:id
  console.log(data,"data")

  const url = `/user_job_post_update/${id}${type ? `?userJobPostId=${jobPostId}&updateType=${type}`:`?userJobPostId=${user_job_post_id}`}`
  return await callApi(url, data, "PUT");
};
export { updateUserJobPost };
