import { FormControl, MenuItem, Select, Stack } from "@mui/material";
import {
  PrimaryAvatar,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  PrimaryTypography,
  SecondaryFilledButton,
} from "assets/Mui/globalTheme";
import { imageURL } from "common/constants/commonURLS";
import GoogleLocation from "components/Common/Forms/location";
import UploadModel from "components/Common/models/uploadModel";
import { getAllIndustries } from "helpers/services/jobAttributes/industries";
import { getPreSignedURL } from "helpers/services/pre_signed_urls/upload";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { toast } from "react-hot-toast";
const company_name =
  JSON.parse(localStorage.getItem("authUser"))?.company_name || "";
export default function FirstCompanyFollowUps({ setMoveStatus }) {
  const [industries, setIndustries] = useState([]);
  useEffect(() => {
    initialFetch();
  }, []);
  async function initialFetch() {
    const data = await getAllIndustries(1, 30, "");

    const activeData = data?.data_payload.filter(item => item.is_active);
    setIndustries(activeData);
  }
  const [modelOpen, setModelOpen] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const {
    register,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const sizes = [
    // { name: "1-50", value: "1-50" },
    // { name: "50-100", value: "50-100" },
    // { name: "100-200", value: "100-200" },

    { name: "1-50", value: "1-50" },
    { name: "51-200", value: "51-200" },
    { name: "201-500", value: "201-500" },
    { name: "501-1000", value: "501-1000" },
    { name: "1001-5000", value: "1001-5000" },
    { name: "5001-10000", value: "5001-10000" },
    { name: "10000+", value: "10000+" },
  ];

  const companytypes = [
    { name: 'Startup', value: 'Startup' },
    { name: 'MSME', value: 'MSME' },
    { name: 'SME', value: 'SME' },
    { name: 'Enterprise', value: 'Enterprise' },
    { name: 'INC', value: 'INC' },
    { name: 'LLC', value: 'LLC' },
    { name: 'S Corp', value: 'S Corp' },
    { name: 'C Corp', value: 'C Corp' },
    { name: 'Public', value: 'Public' },
    { name: 'Private Limited', value: 'Private Limited' },
    { name: 'Seed', value: 'Seed' },
    { name: 'Angel', value: 'Angel' },
    { name: 'Venture', value: 'Venture' },
    { name: 'Series A', value: 'Series A' },
    { name: 'Series B', value: 'Series B' },
    { name: 'Series C', value: 'Series C' },
    { name: 'Series D', value: 'Series D' },
    { name: 'Series E', value: 'Series E' },
    { name: 'Series F', value: 'Series F' },
    { name: 'Debt Financing', value: 'Debt Financing' },
    { name: 'Equity Crowdfunding', value: 'Equity Crowdfunding' },
    { name: 'Convertible Note', value: 'Convertible Note' },
    { name: 'Private Equity', value: 'Private Equity' },
    { name: 'Other', value: 'Other' }
  ];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        borderRadius: "10px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
      },
    },
  };
  function handleUpload() {
    setModelOpen(!modelOpen);
  }
  function handleUserUpload() {
    setUserModal(pre => !pre);
  }
  const handleImageUpload = async data => {
    const url = await getPreSignedURL(data);
    setValue("company_image", `${url}`);
    handleUpload();
  };
  const handleUserImageUpload = async data => {
    const url = await getPreSignedURL(data);
    setValue("image", `${url}`);
    handleUserUpload();
  };
  function handleIndustryChange(e) {
    setValue(
      "industry",
      typeof e.target.value === "string" ? value?.split(",") : value
    );
  }
  function handleCompanyTypeChange(e) {
    setValue(
      "companytype",
      typeof e.target.value === "string" ? value?.split(",") : value
    );
  }



  const hasErrors = Object.keys(errors).length > 0;

  if (hasErrors) {
    // Display a toast message for errors
    // toast.error('Please fill in all required fields correctly.');

    // Scroll to the top of the page

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
   
  }


  return (
    <>
      <UploadModel
        isOpen={modelOpen}
        toggle={handleUpload}
        handleImage={handleImageUpload}
      />
      <UploadModel
        isOpen={userModal}
        toggle={handleUserUpload}
        handleImage={handleUserImageUpload}
      />
      <Stack spacing={2}>
        <input
          type="text"
          value={watch("company_image")}
          style={{ display: "none" }}
          {...register("company_image", { required: true })}
        />
        <input
          type="text"
          value={watch("image")}
          style={{ display: "none" }}
          {...register("image", { required: true })}
        />
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack direction="row" alignItems={"center"} spacing={2}>
            <PrimaryAvatar
              src={`${imageURL}${watch("company_image")}`}
              sx={{
                ...(errors?.company_image &&  watch('company_image') == "" && {
                  border: "1px solid red",
                }),
                width: "58px !important",
                height: "58px !important",
              }}
            >
              {company_name?.charAt("0")}
            </PrimaryAvatar>
            <Stack>
              <PrimaryTypography variant="h5">Company</PrimaryTypography>
              <SecondaryFilledButton onClick={handleUpload}>
                Upload New Logo
              </SecondaryFilledButton>
              <PrimaryNormalFormHelperText error={errors.company_image}>
            {errors?.company_image && watch('company_image') == ""
              ? "company image is a required field"
              : ""}
          </PrimaryNormalFormHelperText>
            </Stack>
          
          </Stack>
          <Stack direction="row" alignItems={"center"} spacing={2}>
            <PrimaryAvatar
              src={`${imageURL}${watch("image")}`}
              sx={{
                ...(errors?.image && watch('image') == "" && {
                  border: "1px solid red",
                }),
                width: "58px !important",
                height: "58px !important",
              }}
            >
              {company_name?.charAt("0")}
            </PrimaryAvatar>
            <Stack>
              <PrimaryTypography variant="h5">User</PrimaryTypography>
              <SecondaryFilledButton onClick={handleUserUpload}>
                Upload New Logo
              </SecondaryFilledButton>
              <PrimaryNormalFormHelperText error={errors.image}>
            {errors?.image && watch('image') == ""
              ? "user image is a required field"
              : ""}
          </PrimaryNormalFormHelperText>
            </Stack>
          
          </Stack>
        </Stack>
        <FormControl sx={{ width: "100%" }} variant="standard">
          <PrimaryNormalInputLabel
            shrink
            htmlFor="login-tagline-outlined"
            error={errors.tagline}
          >
            tagline
          </PrimaryNormalInputLabel>
          <PrimaryNormalInputs
            fullWidth
            placeholder="Enter your tagline"
            {...register("tagline", { required: true })}
            id="login-tagline-outlined"
            autoFocus
            label="tagline address"
            type={"text"}
            error={errors.tagline}
            variant="outlined"
          />
          <PrimaryNormalFormHelperText error={errors.tagline}>
            {errors?.tagline
              ? "tagline is a required field"
              : "Enter words Max Upto 500 Only."}
          </PrimaryNormalFormHelperText>
        </FormControl>
        <Stack direction="row" spacing={4} justifyContent={"space-between"}>
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="folloups-company-size-outlined"
              error={errors.size_of_company}
            >
              Company Size
            </PrimaryNormalInputLabel>
            <Select
              labelId="folloups-company-size-outlined"
              id="folloups-company-size-outlined"
              displayEmpty
              value={getValues("size_of_company")}
              {...register("size_of_company", { required: true })}
              input={
                <PrimaryNormalInputs
                  label="folloups-company-size-outlined"
                  error={errors?.size_of_company && watch("size_of_company") == ""}
                  {...register("size_of_company", { required: true })}
                />
              }
              MenuProps={MenuProps}
            >
              {sizes?.map(item => {
                return (
                  <MenuItem
                    key={item?.value}
                    value={item?.value} // style={getStyles(name, personName, theme)}
                  >
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
            <PrimaryNormalFormHelperText error={errors.size_of_company}>
              {errors?.size_of_company && watch("size_of_company") == ""
                ? "company size is a required field"
                : ""}
            </PrimaryNormalFormHelperText>
          </FormControl>
          <FormControl sx={{ width: "100%" }} variant="standard">
            <Stack spacing={2}>
              <PrimaryNormalInputLabel
                shrink
                htmlFor="folloups-location-outlined"
                error={errors.location}
              >
                Location
              </PrimaryNormalInputLabel>
              <GoogleLocation
                updatingContent={"location"}
                setAdditionals={true}
                
              />
            </Stack>
            <PrimaryNormalFormHelperText error={errors.location}>
              {errors?.location && watch("location") == ""
                ? "location is a required field"
                : ""}
            </PrimaryNormalFormHelperText>
          </FormControl>
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="folloups-industry-outlined"
            >
              industry
            </PrimaryNormalInputLabel>
            <Select
              // multiple
              labelId="folloups-industry-outlined"
              id="folloups-industry-outlined"
              value={watch("industry")}
              displayEmpty
              onChange={handleIndustryChange}
              {...register("industry", { required: true })}
              input={
                <PrimaryNormalInputs
                  label="folloups-industry-outlined"
                  error={errors?.industry && watch("industry") == ""}
                  {...register("industry", { required: true })}
                />
              }
              MenuProps={MenuProps}
            >
              {industries?.length ? (
                industries?.map(item => {
                  return (
                    <MenuItem
                      key={item?.value}
                      value={item?.id} // style={getStyles(name, personName, theme)}
                    >
                      {item?.name}
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem
                  disabled
                  // style={getStyles(name, personName, theme)}
                >
                  No Data Found
                </MenuItem>
              )}
            </Select>
            <PrimaryNormalFormHelperText error={errors.industry}>
              {errors?.industry && watch("industry") == ""
                ? "industry is a required field"
                : ""}
            </PrimaryNormalFormHelperText>
          </FormControl>

          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="folloups-industry-outlined"
            >
              Company type*
            </PrimaryNormalInputLabel>
            <Select
              labelId="folloups-company-size-outlined"
              id="folloups-company-size-outlined"
              value={getValues("company_type")}
              displayEmpty
              {...register("company_type", { required: true })}
              input={
                <PrimaryNormalInputs
                  label="folloups-company-size-outlined"
                  error={errors?.company_type && (watch("company_type") == "")}
                  {...register("company_type", { required: true })}
                />
              }
              MenuProps={MenuProps}
            >
              {companytypes?.map(item => {
                return (
                  <MenuItem
                    key={item?.value}
                    value={item?.value} // style={getStyles(name, personName, theme)}
                  >
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
            <PrimaryNormalFormHelperText error={errors.company_type}>
              {errors?.company_type && watch("company_type") == ""
                ? "company type is a required field"
                : ""}
            </PrimaryNormalFormHelperText>
          </FormControl>
        </Stack>
        <FormControl sx={{ width: "100%" }} variant="standard">
          <PrimaryNormalInputLabel
            shrink
            htmlFor="folloups-about-outlined"
            error={errors.about}
          >
            About
          </PrimaryNormalInputLabel>
          <PrimaryNormalInputs
            fullWidth
            multiline
            placeholder="Enter about your company"
            {...register("about", {
              required: true,
              minLength: "150",
              maxLength: "2000",
            })}
            id="login-about-outlined"
            autoFocus
            label="about address"
            type={"text"}
            error={errors.about}
            variant="outlined"
            rows={4}
          />
          <PrimaryNormalFormHelperText error={errors.about}>
            {errors?.about
              ? "about is a required field with minimun 150 characters"
              : "Describe about your company so that we can find better profiles for you."}
          </PrimaryNormalFormHelperText>
        </FormControl>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
          spacing={4}
        >
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="folloups-websiteUrl-outlined"
              error={errors.website}
            >
              websiteUrl
            </PrimaryNormalInputLabel>
            {/* <PrimaryNormalInputs
              fullWidth
              placeholder="Enter websiteurl of your company"
              {...register("website", {
                required: true,
                pattern: {
                  value: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
                  message: "Invalid URL format",
                },
              })}
              id="login-websiteUrl-outlined"
              autoFocus
              label="websiteUrl address"
              type={"text"}
              error={errors.website}
              variant="outlined"
              rows={4}
            />
            <PrimaryNormalFormHelperText error={errors.website}>
              {errors?.website
                ? "websiteUrl is a required field"
                : "Enter websiteUrl"}
            </PrimaryNormalFormHelperText> */}

            <PrimaryNormalInputs
              fullWidth
              placeholder="Enter website URL of your company"
              {...register("website", {
                required: "Website URL is required",
                pattern: {
                  value:
                    /^(https?:\/\/)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(:[0-9]+)?(\/[^\s]*)?$/,
                  message: "Invalid URL format",
                },
                maxLength: {
                  value: 75,
                  message: "Website URL must not exceed 75 characters",
                },
              })}
              id="login-websiteUrl-outlined"
              autoFocus
              label="Website URL address"
              type="text"
              error={errors.website}
              variant="outlined"
              rows={4}
            />
            <PrimaryNormalFormHelperText error={errors.website}>
              {errors?.website?.message || "Enter website URL"}
            </PrimaryNormalFormHelperText>
          </FormControl>
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="folloups-linkedinUrl-outlined"
              error={errors.linkedin_url}
            >
              linkedinUrl
            </PrimaryNormalInputLabel>
            <PrimaryNormalInputs
              fullWidth
              placeholder="Enter linkedinurl of your company"
              {...register("linkedin_url", { required: false,
                pattern: {
                  value:
                    /^(https?:\/\/)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(:[0-9]+)?(\/[^\s]*)?$/,
                  message: "Invalid URL format",
                }, })}
              id="login-linkedinUrl-outlined"
              autoFocus
              label="linkedinUrl address"
              type={"text"}
              error={errors.linkedin_url}
              variant="outlined"
              rows={4}
            />
            <PrimaryNormalFormHelperText error={errors.linkedin_url}>
              LinkedinUrl is an optional field
              {/* {errors?.website?.message || "LinkedinUrl is an optional field"} */}
            </PrimaryNormalFormHelperText>
          </FormControl>
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="folloups-facebookUrl-outlined"
              error={errors.facebook_url}
            >
              facebookUrl
            </PrimaryNormalInputLabel>
            <PrimaryNormalInputs
              fullWidth
              placeholder="Enter facebookurl of your company"
              {...register("facebook_url", { required: false,
                pattern: {
                  value:
                    /^(https?:\/\/)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(:[0-9]+)?(\/[^\s]*)?$/,
                  message: "Invalid URL format",
                }, })}
              id="login-facebookUrl-outlined"
              autoFocus
              label="facebookUrl address"
              type={"text"}
              error={errors.facebook_url}
              variant="outlined"
              rows={4}
            />
            <PrimaryNormalFormHelperText error={errors.facebook_url}>
              FacebookUrl is an optional field
              {/* {errors?.website?.message || "FacebookUrl is an optional field"} */}
            </PrimaryNormalFormHelperText>
          </FormControl>
        </Stack>
      </Stack>
    </>
  );
}
