import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import {
  BorderCard,
  GreyTypography,
  PrimaryAvatar,
  PrimaryButton,
  PrimaryTypography,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { getNotes } from "helpers/services/notes/getNotes";
import SearchParamHook from "hooks/searchParams";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import AddEditNotes from "./addNotes";
import { createNotes } from "helpers/services/notes/create";
import { updateNote } from "helpers/services/notes/update";
import dataNotFound from "../../../../assets/images/noDataGreen.png";
import { toast } from "react-hot-toast";
import {v4 as uuid} from "uuid"
// import EmailEditNotes from "./emailNotes";
export default function UserJobPostNoteSection({checkNotes,setCheckNotes,editorText,setEditorText}) {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.Loading.loading);
  const [userJobPostId, setUserJobPostId] = useState(null);
  const notes = useSelector(state => state.Notes.notes);
  const [jobPostId, setJobPostId] = useState(null);
  const [notesModel, setNotesModel] = useState(false);
  const searchParams = SearchParamHook();
  const profileReadStatus = searchParams.get('readOnlyStatus')
  const [activeIndex, setActiveIndex] = useState(null);
  // const [checkNotes, setCheckNotes] = useState([
  //   {
  //     subject: "Yamunda",
  //     id: uuid(),
  //     notes: `<p><span style="color: rgb(71,77,106);background-color: rgb(255,255,255);font-size: 14px;font-family: Satoshi, "Satoshi Fallback;">The</span> <strong>Senior</strong> <strong>Software</strong> <strong>Developer</strong> <span style="color: rgb(71,77,106);background-color: rgb(255,255,255);font-size: 14px;font-family: Satoshi, "Satoshi Fallback;">will be based in</span> <strong>Hyderabad</strong><span style="color: rgb(71,77,106);background-color: rgb(255,255,255);font-size: 14px;font-family: Satoshi, "Satoshi Fallback;">. In this position, you will report to the Vice President -Global Design Applications. Nextracker, the worldwide leader in solar trackers, is looking for an experienced full-stack software developer to support and contribute to the software development lifecycle through planning, programming, testing, and delivering quality software. In the Nextracker, the Software Development team is a part of the Engineering Department and is charged with developing and delivering quality software solutions by collaborating with cross-functional teams. We have hundreds of thousands of devices in the field, controlling our solar trackers and advanced energy storage systems. Our data and analytics infrastructure support our maintenance operations and informs new product design. When you work with us, you will be making a positive, material impact on the solar capacity of the planet and learning how to scale outdoor IoT deployments in some very harsh environments.</span>&nbsp;</p>`,
  //     is_active: "",
  //     createdAt: "",
  //     updatedAt:new Date(),
  //     created_by_id: "",
  //     updated_by_id: "",
  //     user_job_post_id: "",
  //     job_post_id: "",
  //     notes_updtby: {
  //           email: "d@syoft.com",
  //           first_name: "admin.",
  //           last_name: "shifyt",
  //           user_name: "admin Zepul",
  //           phone_number: null,
  //           image: "img_1692505186632.png"
  //       },
  //   },
  //   // {
  //   //   subject: "",
  //   //   id: "2",
  //   //   notes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  //   //   is_active: "",
  //   //   createdAt: "",
  //   //   updatedAt:new Date(),
  //   //   created_by_id: "",
  //   //   updated_by_id: "",
  //   //   user_job_post_id: "",
  //   //   job_post_id: "",
  //   //   notes_updtby:  {
  //   //     email: "d@syoft.com",
  //   //     first_name: "admin.",
  //   //     last_name: "shifyt",
  //   //     user_name: "admin Zepul",
  //   //     phone_number: null,
  //   //     image: "img_1692505186632.png"
  //   // },
  //   // },
  //   // {
  //   //   subject: "",
  //   //   id: "3",
  //   //   notes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  //   //   is_active: "",
  //   //   createdAt: "",
  //   //   updatedAt:new Date(),
  //   //   created_by_id: "",
  //   //   updated_by_id: "",
  //   //   user_job_post_id: "",
  //   //   job_post_id: "",
  //   //   notes_updtby:  {
  //   //     email: "d@syoft.com",
  //   //     first_name: "admin.",
  //   //     last_name: "shifyt",
  //   //     user_name: "admin Zepul",
  //   //     phone_number: null,
  //   //     image: "img_1692505186632.png"
  //   // },
  //   // },
  // ]);
  const [activeId,setActiveId] = useState(null)
  



  function toggleModel() {
    setNotesModel(!notesModel);
  }
  async function updateNotes(note) {
    toggleModel();
    const result =  await updateNote(note?.id, {
      user_job_post_id: searchParams.get("user_job_post_id"),
      job_post_id: searchParams.get("job_id"),
      notes: note?.notes,
    });
    if(result.status === 200){
      toast.success("Successfully updated!")
    }

    dispatch({ type: "START_LOADING" });
    const { data, status } = await getNotes(searchParams.get("user_job_post_id"));
    // dispatch({ type: "STOP_LOADING" });
    if (status === 200) {
      dispatch({
        type: "ADD_NOTES",
        payload: data?.data_payload,
      });
    }
    dispatch({ type: "STOP_LOADING" });
  }
  if (loading) {
    return (
      <div style={{ display: "grid", placeItems: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  function updateCheckNotes(data) {

    const tweak = {
      subject: data.subject,
      id: uuid(),
      notes: data.note,
      is_active: "",
      createdAt: "",
      updatedAt: new Date(),
      created_by_id: "",
      updated_by_id: "",
      user_job_post_id: "",
      job_post_id: "",
      notes_updtby: {
        email: "d@syoft.com",
        first_name: "admin.",
        last_name: "shifyt",
        user_name: "admin Zepul",
        phone_number: null,
        image: "img_1692505186632.png"
      },
    };
    toggleModel();
    setCheckNotes((pre)=>[tweak,...pre])
  }

  // const currentNotes = checkNotes.find(e => e.id === activeId)
  return (
    <Stack spacing={2}>
      {//notes?.length ? notes?.map
        checkNotes?.length ?
        checkNotes?.map((item, noteIndex) => (
        <Grid container alignItems={"center"} key={item.id}>
          <Grid item xs={12} md={1}>
            <PrimaryAvatar src={item?.notes_updtby?.image}>
              {item?.notes_updtby?.user_name?.charAt("0")}
            </PrimaryAvatar>
            <PrimaryTypography variant="h5">
              {item?.notes_updtby?.user_name}
            </PrimaryTypography>
          </Grid>

          <Grid item xs={12} md={11}>
            <Stack>
              <BorderCard
                sx={{
                  padding: "10px",
                  borderBottomLeftRadius: "0px !important",
                  borderBottomRightRadius: "0px !important",
                }}
              >
                <Card elevation={0}>
                  <CardContent>
                    {/* <PrimaryTypography
                      sx={{ maxWidth: "100% !important" }}
                      variant="h5"
                      component="h5"
                    >
                      {item?.notes}{" "}
                    </PrimaryTypography> */}
                      <div dangerouslySetInnerHTML={{__html:item?.notes}}></div>
                  </CardContent>
                </Card>
              </BorderCard>
              <BorderCard
                sx={{
                  borderTopLeftRadius: "0px !important",
                  borderTopRightRadius: "0px !important",
                  padding: "2px",
                }}
              >
                <Stack direction="row" justifyContent={"space-between"}>
                  <GreyTypography variant="h6" component="h6">
                    {moment(item?.updatedAt)?.format(
                      "dddd, MMMM Do YYYY, h:mm:ss a"
                    )}{" "}
                  </GreyTypography>
                  {/* <IconButton>
                    <FeatherIcon icon="more-vertical" size={14} />
                  </IconButton> */}
                  <PrimaryButton
                    sx={{ maxWidth: "100px" }}
                    disabled={profileReadStatus === "false"?false:true}
                    onClick={() => {
                      toggleModel();
                      // setActiveId(item.id);
                      setActiveId({index:noteIndex,id:item.id})
                    }}
                  >
                    Edit
                  </PrimaryButton>
                </Stack>
              </BorderCard>
            </Stack>
          </Grid>
        </Grid>
      )):
      <div style={{textAlign:"center"}}>
        <img
          style={{
            width: "350px",
            height: "350px",
            marginTop: 0,
          }}
          src={dataNotFound}
          alt="/not-found"
        />
      </div>
      }
      {notesModel ?
        <AddEditNotes
        open={notesModel}
          type="update"
          editorText={editorText}
        // currentData={notes[activeIndex]}
       currentData={notes[activeId.index]}
        toggleModel={toggleModel}
        updateOrEditNotes={updateNotes}
          // updateOrEditNotes={updateCheckNotes}
          setEditorText={setEditorText}
        // updateCheckNotes={updateCheckNotes}
      /> : null}
      {/* {notesModel ?
        <EmailEditNotes // AddEditNotes
        open={notesModel}
          type="update"
          editorText={editorText}
        // currentData={notes[activeIndex]}
        currentData={currentNotes}
        toggleModel={toggleModel}
        // updateOrEditNotes={updateNotes}
          updateOrEditNotes={updateCheckNotes}
          setEditorText={setEditorText}
      /> : null} */}
    </Stack>
  );
}
