import React, { useEffect, useState } from "react";
import { Input, Col, Button, Label } from "reactstrap";
import { connect } from "react-redux";
import { updateJob } from "store/jobs/actions";

const JobPostQuestion = ({ screening_questions, updateJob }) => {
  const [inputList, setInputList] = useState([]);
  useEffect(() => {
    setInputList(() => {
      const items = screening_questions.map(item => {
        return { question: item };
      });
      setInputList(items);
    });
  }, [screening_questions]);

  const handleInputChange = (e, index) => {
    const list = [...inputList];
    list[index] = { question: e.target.value };
    setInputList([...list]);
    const data = list?.map(item => item?.question);
    updateJob({ screening_questions: [...data] });
  };

  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { question: "" }]);
  };

  return (
    <div>
      <Col xs={12} className="my-2" style={{ minHeight: "40vh" }}>
        <Label style={{fontSize:"20px",fontWeight:500}}>
          Ask Screening Questions{" "}
          <span style={{ fontSize: "10px", color: "gray" }}>(Optional)</span>{" "}
          <span className="red p-2">*</span>
        </Label>
        {inputList?.map((x, i) => {
          return (
            <React.Fragment key={i}>
              <div className=" my-2 d-flex align-items-center justify-content-between">
                <div className="d-flex" style={{ width: "90%" }}>
                  <Input
                    className="mt-2"
                    name="question"
                    placeholder="Type Your Question"
                    value={x.question}
                    onChange={e => handleInputChange(e, i)}
                    maxLength={250}
                    // className="p-2"
                    size="large"
                  />
                </div>
                <div className="d-flex">
                  {inputList.length !== 1 && (
                    <Button
                      className="mr10"
                      color="link"
                      onClick={() => handleRemoveClick(i)}
                    >
                      <span
                        className="mdi mdi-close"
                        style={{ fontSize: "30px", color: "#3d82f5" }}
                      ></span>
                    </Button>
                  )}
                </div>
              </div>

              {inputList.length - 1 === i && (
                <Button
                  onClick={handleAddClick}
                  className="mt-4"
                  style={{
                    backgroundColor: "#cde2e7",
                    color: "#024430",
                    border: "none",
                  }}
                  // color="success"
                >
                  <i className="mdi mdi-plus"></i>
                  <span> Add Questions</span>
                </Button>
              )}
            </React.Fragment>
          );
        })}
      </Col>
    </div>
  );
};
const mapStateToProps = ({ Jobs }) => ({
  screening_questions: Jobs.screening_questions,
});

const mapDispatchToProps = dispatch => ({
  updateJob: e => dispatch(updateJob(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobPostQuestion);
