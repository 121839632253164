import { Stack } from "@mui/material";
import {
  BlackTypography,
  PrimaryButton,
  SecondaryFilledButton,
} from "assets/Mui/globalTheme";
import JobPostingStepper from "components/Posting/stepper";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import BasicDetails from "components/Posting/steps/BasicDetails";
import HireInDay from "components/Posting/steps/HireInDay";
import JobDescriptionDetails from "components/Posting/steps/JobDescription";
import {
  getAllEducationalSpecialization,
  getEducationalSpecializationWithQualification,
  updateEducationalSpecialization,
} from "helpers/services/jobAttributes/educationalQualifications";
import { CreateJobPost } from "helpers/services/jobs/jobs";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-hot-toast";
import SocketConnection from "hooks/sockets";
import React, { useEffect } from 'react';
import { get_chat_active_user_list } from "helpers/services/sockets";

export default function JobPosting() {

  const { lastMessage, sendMessage: sendSocketMessage, connectionStatus } = SocketConnection();
  const [emitMessage, setEmitMessage] = useState('')
  const userData = JSON.parse(localStorage.getItem("authUser"));
  const [jobsBasicData, setJobsBasicData] = useState()
  const userId = userData?.id
  const [isPublished, setIsPublished] = useState(false); 



  const [activeStep, setActiveStep] = useState(0);
  function findstep(active) {
    switch (active) {
      case 0:
        return <BasicDetails />;
      case 1:
        return <JobDescriptionDetails />;
      case 2:
        return <HireInDay jobsData={jobsBasicData} />;
    }
  }
  const history = useHistory();

  /*socket implementation start here */
  useEffect(() => {
    // Example: Emit a socket event when something happens in the company page
    sendSocketMessage('company-action', { data: 'Company data' });
  }, [emitMessage]);

  /*socket implementation start here */


  const onSubmit = async data => {


    let commission_rate_by_job_type = data?.job_type === "Contract" ? 0 : data?.refer_percentage





   
    if (activeStep === 2) {
      setIsPublished(true)

      // if (data?.job_type !== "Contract" && Number(data.refer_percentage) < 8.3 && data.refer_commission === true) {
      //   toast.error("Refer percentage should be greater than 8.3%");
      // }

      data.refer_commission = true;
      data.commission_rate_type = "Percentage";
      data.job_level_id = data.job_level_id.value;
      // data.commission_rate = data.refer_percentage;
      data.commission_rate = commission_rate_by_job_type;
      // data.state = data?.state !== undefined?data?.state:null,
      // data.country = data?.country !== undefined?data.country:null,
      // data.city = data?.city !== undefined?data.city:null,
      data.functional_areas = data.functional_area;

      // data.min_sal = Number(data.min_sal);
      // data.max_sal = Number(data.max_sal);
      data.min_sal = Number(data.min_sal.replace(/,/g, ""))
      data.max_sal = Number(data.max_sal.replace(/,/g, ""))
      data.screening_questions = data.question?.map(item => item?.value);
      data.application_deadline = new Date(data.application_deadline);
      data.hours_per_week = "less_than_30";
      // data.project_duration = "less_than_month";
      data.project_duration = data.project_duration ? data.project_duration : "0";
      data.job_roles = [data.job_role?.value];
      data.location = data?.location ? data.location : "Remote"
      data.payout = data.payout
      data.max_exp = data.min_exp === "10+" ? "0" : data.max_exp


      if (data?.location === "") {
        delete data.location;
      }
      if (data?.location === "Remote") {
        delete data.state
        delete data.country
        delete data.city
      }

      // delete data.refer_commission

      delete data.job_description;
      // delete data.payout;
      delete data.refer_amount;

      delete data.refer_percentage;
      delete data.question;
      delete data.job_role;
      delete data.functional_area;
      delete data.job_requirement;
      // const educational_qualifications = data?.educational_qualifications?.map(
      //   async item => {
      //     const [specialization_data] =
      //       await getEducationalSpecializationWithQualification(item?.value);

      //     return {
      //       education_qualification_id:
      //         specialization_data?.length > 0
      //           ? specialization_data[0]?.educational_qualification_id
      //           : item?.value,
      //       education_qualification_spec_id:
      //         specialization_data?.length > 0 ? specialization_data[0]?.id : "",
      //     };
      //   }
      // );
      const educational_qualifications = data?.educational_qualifications?.map(
        item => {
          const [education_qualification_id, education_qualification_spec_id] =
            item;
          return {
            education_qualification_id,
            education_qualification_spec_id,
          };
        }
      );
      // const ed_full = await Promise.all(educational_qualifications);
      const ed_full = (educational_qualifications);
      const functional_areas = [data?.functional_areas?.value];
      const key_skills = data?.key_skills?.map(item => item?.value);

      const { status } = await CreateJobPost({
        ...data,
        functional_areas,
        educational_qualifications: ed_full,
        key_skills,
      });
      if ([200, 201].includes(status)) {

        setEmitMessage(true)

        toast.success("The job is posted successfully")
        history.push("/joblist/pending");
      }
      return;

    }
    setActiveStep(activeStep + 1);
    setJobsBasicData(data)
  };


  const methods = useForm({
    // resolver: yupResolver(schema),
    defaultValues: {
      time_zone: [],
      functional_area: [],
      currency: "INR",
      key_skills: [],
      educational_qualifications: [],
      refer_percentage: "0.1",
      question: [" "],
      max_exp_months: "0",
      min_exp_months: "0",
      job_type: [],
      work_mode: [],
      payout: [],
      citizenship_status: [],
      hours_per_week: [],
      // project_duration:'',
      min_sal: "",
      max_sal: "",
      min_exp: "",
      max_exp: "",
      location: ""
    },
  });
  function handleBack() {
    if (activeStep > 0) setActiveStep(activeStep - 1);
    else history.goBack();
  }

  return (
    <>
      <JobPostingStepper activeStep={activeStep} />
      <Stack spacing={2} sx={{ width: "98%" }}>
        {/* <JobPostingStepper activeStep={activeStep} /> */}
        <FormProvider {...methods}>
          <form
            style={{ padding: "0px 36px 0px 12px", height: "72vh" }}
            // style={{ padding: "0px 50px", height: "72vh" }}
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            {findstep(activeStep)}
            <Stack
              sx={{

                marginBlock: "16px",
                background: "white",
              }}
              direction="row"
              // alignItems={"center"}
              // justifyContent={"flex-end"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              spacing={2}
              ml={2.5}
            >

              <PrimaryButton sx={{ width: "200px" }}
                endIcon={<FeatherIcon icon="arrow-right" size="14" />}
                type="submit"
                disabled={isPublished && activeStep === 2}
                >
                {activeStep === 2 ? "Publish this Job" : "Proceed To Next"}
              </PrimaryButton>

              <SecondaryFilledButton
                endIcon={<FeatherIcon icon="x" size="14" />}
                sx={{
                  width: "100px",
                  //  color: "#D60D0D"
                  color: "rgba(26, 29, 31, 1)",

                }}
                type="button"
                // // onClick={handleBack}
                // onClick={()=>methods.reset()}
                onClick={() => {
                  if (activeStep === 0) {
                    methods.reset()
                  } else {
                    handleBack()
                  }
                }}
              >
                {activeStep === 0 ? "clear All" : "back"}
              </SecondaryFilledButton>


            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </>
  );
}
